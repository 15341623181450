import { Component, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'input-tokenizer',
  templateUrl: './input-tokenizer.component.html',
  styleUrls: ['./input-tokenizer.component.scss']
})
export class InputTokenizerComponent implements OnChanges {
  @Input() watchTokens: string = '';
  @Input() maxTokens: number = 0;
  @Input() separator: string = ',';
  @Input() readOnly: boolean = false;
  @Input() watch: boolean = true;

  @Output() tokensDetected: EventEmitter<number> = new EventEmitter<number>();
  @Output() tokensChange: EventEmitter<string[]> = new EventEmitter<string[]>();

  allTokens: string[] = [];
  tokens: string[] = [];
  hiddenTokens: number = 0;

  addingTokens: boolean = false;

  constructor() {}

  @HostListener('keyup.enter')
  confirm(): void {
    this.addTokens();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.watchTokens?.currentValue?.includes(this.separator)) {
      this.addTokens();
    }
  }

  addTokens(): void {
    if (this.watchTokens && this.watchTokens.length && !this.addingTokens) {
      this.addingTokens = true;

      if (this.watch) {
        this.watchTokens.split(this.separator).forEach((token: string) => {
          if (token && token.length) {
            this.allTokens.push(token.trim());
          }
        });
      } else {
        this.allTokens = this.watchTokens
          .split(this.separator)
          .map((token: string) => token.trim())
          .filter((token: string) => token && token.length);
      }

      this.limitTokens();

      setTimeout(() => {
        this.tokensDetected.emit(Date.now());
        this.tokensChange.emit(this.allTokens);
        this.addingTokens = false;
      }, 0);
    }
  }

  removeToken(token: string): void {
    this.allTokens = this.allTokens.filter((t: string) => t !== token);
    this.limitTokens();
    this.tokensChange.emit(this.allTokens);
  }

  limitTokens(): void {
    if (this.maxTokens) {
      this.tokens = this.allTokens.slice(0, this.maxTokens);
      this.hiddenTokens = this.allTokens.length - this.tokens.length;
    } else {
      this.tokens = [...this.allTokens];
      this.hiddenTokens = 0;
    }
  }

  clearAll(): void {
    this.allTokens = [];
    this.tokens = [];
    this.hiddenTokens = 0;
    this.tokensDetected.emit(Date.now());
    this.tokensChange.emit(this.allTokens);
  }
}
