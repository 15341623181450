<ng-container *ngIf="linkVisual.options.medium === 'vpn'">
  <svg:line
    [ngClass]="linkVisual.options.radio"
    [attr.x1]="linkVisual.source.x"
    [attr.y1]="linkVisual.source.y"
    [attr.x2]="linkVisual.target.x"
    [attr.y2]="linkVisual.target.y"
  ></svg:line>
</ng-container>
<ng-container *ngIf="linkVisual.options.medium !== 'vpn'">
  <ng-container *ngIf="ui !== 'noc' || linkVisual.options.radio === 'ethernet'">
    <svg:g *ngFor="let line of linkVisual.options.lines">
      <svg:line
        [class.dimm]="linkVisual?.source?.options?.metadata?.dimm || linkVisual?.target?.options?.metadata?.dimm"
        [ngClass]="line.class"
        [attr.x1]="line.x1"
        [attr.y1]="line.y1"
        [attr.x2]="line.x2"
        [attr.y2]="line.y2"
      ></svg:line>
    </svg:g>
    <svg:circle
      *ngIf="linkVisual.source.options.type == 'device'"
      [class.dimm]="linkVisual?.source?.options?.metadata?.dimm || linkVisual?.target?.options?.metadata?.dimm"
      [ngClass]="linkVisual.options.radio"
      [attr.cx]="calculate('sourcex')"
      [attr.cy]="calculate('sourcey')"
      r="5"
    ></svg:circle>
    <svg:circle
      *ngIf="linkVisual.target.options.type == 'device' && !linkVisual.options?.isMlo"
      [class.dimm]="linkVisual?.source?.options?.metadata?.dimm || linkVisual?.target?.options?.metadata?.dimm"
      [ngClass]="linkVisual.options.radio"
      [attr.cx]="calculate('targetx')"
      [attr.cy]="calculate('targety')"
      r="5"
    ></svg:circle>
    <svg:g
      *ngIf="showChannel && linkVisual.options?.metadata?.channel && linkVisual.options.medium == 'wifi'"
      [class.dimm]="linkVisual?.source?.options?.metadata?.dimm || linkVisual?.target?.options?.metadata?.dimm"
      [attr.transform]="calculateChannel()"
    >
      <svg:circle
        r="12"
        class="channel"
        [ngClass]="linkVisual.options?.isMlo ? 'mlo' : linkVisual.options?.radio"
      ></svg:circle>
      <svg:text x="0" y="4" text-anchor="middle" (tooltipState)="showTooltip()">
        <ng-container *ngIf="!linkVisual.options?.isMlo">{{ linkVisual.options.metadata.channel }}</ng-container>
        <ng-container *ngIf="linkVisual.options?.isMlo">{{ 'MLO' }}</ng-container>
      </svg:text>
    </svg:g>
  </ng-container>

  <ng-container *ngIf="ui === 'noc' && linkVisual.options.radio !== 'ethernet'">
    <svg:g *ngFor="let wave of linkVisual.options.waves; let i = index">
      <svg:path
        [class.dimm]="linkVisual?.source?.options?.metadata?.dimm || linkVisual?.target?.options?.metadata?.dimm"
        [attr.d]="wave.d"
        [ngClass]="wave.radio"
        [ngStyle]="{ stroke: getChannelColor(wave) }"
      ></svg:path>
    </svg:g>
    <svg:g
      *ngIf="showChannel && linkVisual.options?.metadata?.channel && linkVisual.options.medium == 'wifi'"
      [class.dimm]="linkVisual?.source?.options?.metadata?.dimm || linkVisual?.target?.options?.metadata?.dimm"
      [attr.transform]="calculateChannel()"
    >
      <svg:circle
        r="12"
        [ngClass]="linkVisual.options.radio"
        [ngStyle]="{
          stroke: getCircleColor(),
          fill: 'var(--background-default)',
          animation: getOnboardingEffect()
        }"
      ></svg:circle>
      <svg:text x="0" y="4" text-anchor="middle" (tooltipState)="showTooltip()">
        <ng-container *ngIf="!linkVisual.options?.isMlo">{{ linkVisual.options.metadata.channel }}</ng-container>
        <ng-container *ngIf="linkVisual.options?.isMlo">{{ 'MLO' }}</ng-container>
      </svg:text>
    </svg:g>
    <svg:g
      *ngIf="
        showBandwidth &&
        linkVisual.options?.metadata?.parentVapType === 'backhaul' &&
        linkVisual.options.medium == 'wifi' &&
        showBandwidthCircle
      "
      [class.dimm]="linkVisual?.source?.options?.metadata?.dimm || linkVisual?.target?.options?.metadata?.dimm"
      [attr.transform]="calculateChannel()"
    >
      <svg:circle r="12" [ngStyle]="{ stroke: getCircleColor(), fill: 'var(--background-default)' }"></svg:circle>
      <ng-container *ngIf="!linkVisual.options?.isMlo">
        <svg:text x="0" y="4" text-anchor="middle" (tooltipState)="showTooltip()">
          {{ linkVisual.options.metadata.channelWidth }}
        </svg:text>
      </ng-container>
      <ng-container *ngIf="linkVisual.options?.isMlo">
        <svg:text x="0" y="4" text-anchor="middle" (tooltipState)="showTooltip()">
          {{ 'MLO' }}
        </svg:text>
      </ng-container>
    </svg:g>
  </ng-container>
</ng-container>
