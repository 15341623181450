import { SimulationLinkDatum } from 'd3';
import { Node } from './node';

export class Link implements SimulationLinkDatum<Node> {
  index?: number;

  source: Node | any;
  target: Node | any;
  options: any;

  constructor(source: Node | any, target: Node | any, options: any) {
    this.source = source;
    this.target = target;
    this.options = options;
  }
}
