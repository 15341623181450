import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { selectBaseUrl, selectCustomerIdentification } from 'src/app/store/customer/customer.selectors';
import {
  IAppFacadeOptimization,
  IFirmware,
  IFirmwareBatchReport,
  IFirmwareReports,
  IFirmwareUpgrade,
  IFirmwareUpgradeCriteria,
  IFirmwareUpgradeReport,
  IFirmwareUpgradeReportsCriteria,
  IVersionMatrices
} from '../interfaces/interface';
import { ApiService } from './api.service';
import { LoggingService } from './logging.service';
import { NodeService } from './nodes.service';
import { PlumeService } from './plume.service';

@Injectable()
export class TroubleshootingService {
  debouncerTimeout: any;
  pulllog: Subject<any> = new Subject<any>();

  constructor(
    private api: ApiService,
    private nodeService: NodeService,
    private plume: PlumeService,
    private logging: LoggingService,
    private store: Store
  ) {}

  debounce(fn: () => void, delay: number): void {
    clearTimeout(this.debouncerTimeout);
    this.debouncerTimeout = setTimeout(fn, delay);
  }

  initPullLog(message?: string): any {
    if (!message) {
      message = 'Automatic log pull from Plume Central';
    }
    this.debounce(() => {
      this.api
        .post('/locations/' + this.plume.locationid + '/logPull', { comment: message }, 'awlan')
        .subscribe((response: any) => {
          this.pulllog.next(response);
        });
    }, 500);
  }

  resetNetwork(): any {
    return this.api.put('/Customers/' + this.plume.customerid + '/locations/' + this.plume.locationid + '/reboot', {
      delay: 10
    });
  }

  checkNetworkStatus(): any {
    // TODO --> Check to make sure network is back online
  }

  resetNode(nodeId: string): any {
    return this.nodeService.rebootNode$(nodeId, 1);
  }

  resetDevice(deviceMac: string, parentNodeId: string, freqBand: string): any {
    return this.api.post(
      '/locations/' + this.plume.locationid + '/device/' + deviceMac + '/steer/',
      {
        steerFrom: { nodeId: parentNodeId, freqBand }
      },
      'awlan'
    );
  }

  resetLinkFailures$(): Observable<undefined> {
    return this.store.select(selectCustomerIdentification).pipe(
      take(1),
      switchMap(({ locationid }) => this.api.delete(`/locations/${locationid}/allLinkFailureCounts`, 'awlan'))
    );
  }

  steerDevice(
    deviceMac: string,
    FromParentNodeId: string,
    FromFreqBand: string,
    ToParentNodeId: string,
    ToFreqBand: string
  ): any {
    return this.api.post(
      '/locations/' + this.plume.locationid + '/device/' + deviceMac + '/steer/',
      {
        steerFrom: { nodeId: FromParentNodeId, freqBand: FromFreqBand },
        steerTo: [{ nodeId: ToParentNodeId, freqBands: [ToFreqBand] }]
      },
      'awlan'
    );
  }

  steerClientDevice(deviceMac: string, deviceType: string): any {
    return this.api.patch(
      '/Customers/' +
        this.plume.customerid +
        '/locations/' +
        this.plume.locationid +
        '/devices/' +
        deviceMac +
        '/clientSteering',
      {
        steeringClass: deviceType
      }
    );
  }

  triggerOptimization(): Observable<{ createdAt: string }> {
    this.logging.debug('<triggerOptimization>');
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.post(`${baseUrl}/optimize`, {}))
    );
  }

  triggerNodeSpeedTest(nodeId: string): any {
    return this.nodeService.triggerSpeedTest$(nodeId, 'OOKLA');
  }

  checkSpeedSeries(): any {
    return this.api.get(
      '/Customers/' + this.plume.customerid + '/locations/' + this.plume.locationid + '/appFacade/dashboard'
    );
  }

  checkRSSIHistory(macId: string): any {
    return this.api.get(
      '/Customers/' +
        this.plume.customerid +
        '/locations/' +
        this.plume.locationid +
        '/devices/' +
        macId +
        '/rssi?granularity=hours&limit=24',
      'reports'
    );
  }

  checkSpeedTestStatus(nodeId: string): any {
    return this.nodeService.speedTestResults$(nodeId, 'hours', 1);
  }

  optimizeNetwork(): any {
    this.logging.debug('<troubleShootingService.optimizeNetwork>');
  }

  getOptimized(): Observable<IAppFacadeOptimization> {
    this.logging.debug('<troubleShootingService.getOptimized>');
    return this.api.get(
      '/Customers/' +
        this.plume.customerid +
        '/locations/' +
        this.plume.locationid +
        '/appFacade/home?filters=optimization'
    );
  }

  getAppSummary(): any {
    this.logging.debug('<troubleShootingService.getAppSummary>');
    return this.api.get(
      '/Customers/' + this.plume.customerid + '/locations/' + this.plume.locationid + '/appFacade/home?filters=summary'
    );
  }

  getFirmware$(): Observable<IFirmware> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.get(`${baseUrl}/firmware`))
    );
  }

  upgradeFirmware$(): Observable<unknown> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.put(`${baseUrl}/firmware`, {}))
    );
  }

  upgradeReport$(): Observable<any[]> {
    return this.store.select(selectCustomerIdentification).pipe(
      take(1),
      switchMap(({ locationid }) =>
        this.api.get(
          `/locations/${locationid}/upgradeReports/?filter={%22order%22:%22created%20DESC%22,%22limit%22:1}`,
          'awlan'
        )
      )
    );
  }

  getVersionMatrices$(): Observable<IVersionMatrices[]> {
    return this.api.get('/VersionMatrices', 'awlan');
  }

  matrixRequest$(versionMatrix: string, force: boolean, preventDowngrade: boolean): Observable<unknown> {
    return this.store.select(selectCustomerIdentification).pipe(
      take(1),
      switchMap(({ locationid }) =>
        this.api.put(`/locations/${locationid}/versionMatrix`, { versionMatrix, force, preventDowngrade }, 'awlan')
      )
    );
  }

  setOnboarding(status: any): any {
    const postData = { checkpoint: status };

    return this.api.post(
      '/Customers/' + this.plume.customerid + '/locations/' + this.plume.locationid + '/onboardingCheckpoint',
      postData
    );
  }

  deleteOnboarding(): any {
    return this.api.delete(
      '/Customers/' +
        this.plume.customerid +
        '/locations/' +
        this.plume.locationid +
        '/configs?onboardingCheckpoints=true'
    );
  }

  getSpeedTest(id: string): any {
    this.logging.debug('<troubleShootingService.getSpeedTest>', id);
  }

  getModifiedData(): Observable<any> {
    return this.api.get('/Customers/' + this.plume.customerid + '/locations/' + this.plume.locationid).pipe(
      map((response: any) => {
        this.logging.log('Response sould contain property gregor with hell yea value');
        response.gregor = 'Hell yea!!';
        return response;
      }),
      catchError((error: any) => {
        this.logging.log('Here we can catch errors and sort them by status or anything else');
        return of(error);
      })
    );
  }

  getModifiedDataWithError(): Observable<any> {
    return this.api.get('/Customers' + this.plume.customerid + '/locations/' + this.plume.locationid).pipe(
      map((response: any) => {
        this.logging.log('Response sould contain property gregor with hell yea value');
        response.gregor = 'Hell yea!!';
        return response;
      }),
      catchError((error: any) => {
        this.logging.log('Here we can catch errors and sort them by status or anything else');
        return of(error);
      })
    );
  }

  orderExtenders(): any {
    this.logging.log('Order Extenders');
    window.open('https://www.plume.com', '_blank');
  }

  getShardIDs$(): Observable<{ shardIds: string[] }> {
    return this.api.get('/locations/shardIds', 'awlan');
  }

  firmwareUpgradeReportsPreview$(dataObject: IFirmwareUpgradeReportsCriteria): Observable<IFirmwareUpgrade> {
    return this.api.post('/BatchFirmwareUpgrades/upgradeLocationsByCriteria/preview', dataObject, 'reports');
  }

  firmwareUpgradeReportsCountries$(): Observable<string[]> {
    return this.api.get('/BatchFirmwareUpgrades/upgradeLocationsByCriteria/preview/countries', 'reports');
  }

  firmwareUpgradeReportsCities$(countries: string[] = []): Observable<string[]> {
    const queryParams = countries.length ? '?countries=["' + countries.join('", "') + '"]' : '';
    return this.api.get('/BatchFirmwareUpgrades/upgradeLocationsByCriteria/preview/cities' + queryParams, 'reports');
  }

  firmwareUpgradePreview$(dataObject: IFirmwareUpgradeReportsCriteria): Observable<IFirmwareUpgrade> {
    return this.api.post('/BatchFirmwareUpgrades/upgradeLocationsByCriteria/preview', dataObject, 'awlan');
  }

  firmwareUpgrade$(dataObject: IFirmwareUpgradeCriteria): Observable<IFirmwareUpgrade> {
    return this.api.post('/BatchFirmwareUpgrades/upgradeLocationsByCriteria', dataObject, 'awlan');
  }

  firmwareUpgradeReport$(upgradeId: string): Observable<{ batchFirmwareUpgrade: IFirmwareUpgradeReport }> {
    return this.api.get(`/BatchFirmwareUpgrades/${upgradeId}`, 'awlan');
  }

  firmwareReports$(filter: {
    order: 'id DESC';
    limit: number;
    offset: number;
    where?: { userIdOfRequestor: { neq: 'system:onboardingNightlyUpgrade' } };
  }): Observable<IFirmwareReports> {
    return this.api.get(`/BatchFirmwareUpgrades/?filter=${JSON.stringify(filter)}`, 'awlan');
  }

  firmwareReportsCounts$(excludeOnboarding: boolean): Observable<{ count: number }> {
    const excludeOnboardingQuery = '?where[userIdOfRequestor][neq]=system:onboardingNightlyUpgrade';
    return this.api.get(`/BatchFirmwareUpgrades/count${excludeOnboarding ? excludeOnboardingQuery : ''}`, 'awlan');
  }

  firmwareBatchReports$(batchId: string, start: string, end: string): Observable<IFirmwareBatchReport> {
    return this.api.get(`/BatchFirmwareUpgrades/${batchId}?startAt=${start}&endAt=${end}`, 'reports');
  }

  firmwareBatchClear$(batchId: string): Observable<IFirmwareUpgrade['batchFirmwareUpgrade']> {
    return this.api.post(`/BatchFirmwareUpgrades/${batchId}/stop`, {}, 'awlan');
  }

  firmwareBatchApprove$(batchId: string): Observable<IFirmwareUpgrade> {
    return this.api.post(`/BatchFirmwareUpgrades/${batchId}/approve`, {}, 'awlan');
  }
}
