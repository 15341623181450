import { createReducer, on } from '@ngrx/store';
import { addMessage, clearAllMessages } from './guidedTroubleshooting.actions';

export interface GuidedTroubleshootingState {
  sessionInProgress: boolean;
  messagesWithSelections: { message: any; selections: any; selectedOption?: string; responseType?: any }[];
}

export const initialState: GuidedTroubleshootingState = {
  sessionInProgress: false,
  messagesWithSelections: [] // Initialize messages array to an empty array
};

export const guidedTroubleshootingReducer = createReducer(
  initialState,
  on(addMessage, (state, { message, selections, selectedOption, responseType }) => {
    const existingIndex = state.messagesWithSelections.findIndex((item) => item.message === message.message);
    if (existingIndex !== -1) {
      // Update the selected option for an existing message
      return {
        ...state,
        messagesWithSelections: state.messagesWithSelections.map((item, index) =>
          index === existingIndex ? { ...item, selectedOption } : item
        )
      };
    } else {
      // Add a new message with selections and selected option
      const sessionInProgress = responseType === 'sessionEnd' ? false : true;

      return {
        ...state,
        sessionInProgress,
        messagesWithSelections: [...state.messagesWithSelections, { message, selections, selectedOption, responseType }]
      };
    }
  }),
  on(clearAllMessages, (state) => ({
    ...state,
    messagesWithSelections: []
  }))
);
