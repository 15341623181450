import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GraphComponent } from './graph.component';
import { GraphBarComponent } from './graph-bar/graph-bar.component';
import { GraphBarContainerComponent } from './graph-bar-container/graph-bar-container.component';
import { GraphLineContainerComponent } from './graph-line-container/graph-line-container.component';
import { GraphLineComponent } from './graph-line/graph-line.component';
import { GraphAxisXComponent } from './graph-axis-x/graph-axis-x.component';
import { GraphAxisYComponent } from './graph-axis-y/graph-axis-y.component';
import { GraphToolTipComponent } from './graph-tool-tip/graph-tool-tip.component';
import { GraphToolTipBarComponent } from './graph-tool-tip-bar/graph-tool-tip-bar.component';
import { GraphLabeledStackedBarComponent } from './graph-labeled-stacked-bar/graph-labeled-stacked-bar.component';
import { GraphLegendComponent } from './graph-legend/graph-legend.component';
import { GraphIntervalBarContainerComponent } from './graph-interval-bar-container/graph-interval-bar-container.component';
import { GraphIntervalBarComponent } from './graph-interval-bar/graph-interval-bar.component';
import { GraphSegmentedIntervalBarContainerComponent } from './graph-segmented-interval-bar-container/graph-segmented-interval-bar-container.component';
import { GraphSegmentedIntervalBarComponent } from './graph-segmented-interval-bar/graph-segmented-interval-bar.component';

// this module uses global colors and global fonts (colors.scss, typography.scss)
@NgModule({
  declarations: [
    GraphComponent,
    GraphBarComponent,
    GraphBarContainerComponent,
    GraphLineContainerComponent,
    GraphLineComponent,
    GraphAxisXComponent,
    GraphAxisYComponent,
    GraphToolTipComponent,
    GraphToolTipBarComponent,
    GraphLabeledStackedBarComponent,
    GraphLegendComponent,
    GraphIntervalBarContainerComponent,
    GraphIntervalBarComponent,
    GraphSegmentedIntervalBarContainerComponent,
    GraphSegmentedIntervalBarComponent,
  ],
  imports: [CommonModule],
  exports: [
    GraphComponent,
    GraphBarComponent,
    GraphBarContainerComponent,
    GraphLineContainerComponent,
    GraphLineComponent,
    GraphAxisXComponent,
    GraphAxisYComponent,
    GraphToolTipComponent,
    GraphToolTipBarComponent,
    GraphLabeledStackedBarComponent,
    GraphLegendComponent,
  ],
})
export class GraphModule {}
