import { Directive, OnInit, OnDestroy, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[countdown]'
})
export class CountdownDirective implements OnInit, OnDestroy {
  @Input()
  countdown: number = 0;

  interval: any;

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    this.el.nativeElement.innerHTML = this.countdown + 's';
    this.interval = setInterval(() => {
      if (this.countdown > 0) {
        this.countdown--;
        this.el.nativeElement.innerHTML = this.countdown + 's';
      } else {
        clearInterval(this.interval);
      }
    }, 1000);
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }
}
