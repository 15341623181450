<div class="row" id="conf-onboarding" *ngIf="location$ | async as location">
  <div class="col-xs-12">
    <collapsiblebox [show]="expand" [collapsible]="true" (toggle)="toggleExpand()">
      <div header>{{ 'configurations.onboarding.title' | translate }}</div>
      <div container *ngIf="expand">
        <div class="columns">
          <div class="column">
            <div class="row" *ngIf="!(capabilities$ | async)?.backhaul.capable">
              <div class="key">{{ 'configurations.onboarding.wds' | translate }}</div>
              <div class="value">{{ 'configurations.onboarding.locationNotCapable' | translate }}</div>
            </div>
            <div class="row" *ngIf="(capabilities$ | async)?.backhaul.capable">
              <div class="key">{{ 'configurations.onboarding.wds' | translate }}</div>
              <div class="value">
                <slider-toggle-mobile-aware
                  [options]="wdsItems$ | async"
                  [editable]="plume.isSomeAdminRole()"
                  (toggle)="action('wds', $event, backhaulResponse)"
                ></slider-toggle-mobile-aware>
              </div>
            </div>
            <div class="row">
              <div class="key">{{ 'configurations.onboarding.profile' | translate }}</div>
              <div class="value">
                <ng-container *ngIf="location.profile === 'property'">
                  {{ 'configurations.onboarding.property' | translate }}
                </ng-container>
                <slider-toggle-mobile-aware
                  *ngIf="location.profile !== 'property'"
                  [options]="profileItems$ | async"
                  [editable]="plume.isSomeAdminRole() && (isUprise$ | async) !== true && network"
                  [confirmChange]="confirmProfileChange"
                  (toggle)="action('profile', $event, backhaulResponse)"
                ></slider-toggle-mobile-aware>
              </div>
            </div>

            <div class="row">
              <div class="key">{{ 'configurations.onboarding.dynamicbeacon' | translate }}</div>
              <div class="value" *ngIf="(capabilities$ | async)?.dynamicBeacon?.capable">
                <slider-toggle-mobile-aware
                  [options]="dynamicbeaconItems$ | async"
                  [editable]="plume.isSomeAdminRole()"
                  (toggle)="action('dynamicbeacon', $event, backhaulResponse)"
                ></slider-toggle-mobile-aware>
              </div>
              <div class="value" *ngIf="!(capabilities$ | async)?.dynamicBeacon?.capable">
                {{ 'configurations.onboarding.locationNotCapable' | translate }}
              </div>
            </div>

            <div class="row">
              <div class="key">{{ 'configurations.onboarding.onboardnetwork' | translate }}</div>
              <div class="value" *ngIf="(capabilities$ | async)?.onboardNetwork?.capable">
                <slider-toggle-mobile-aware
                  [options]="onboardingItems$ | async"
                  [editable]="plume.isSomeAdminRole()"
                  (toggle)="action('onboardnetwork', $event, backhaulResponse)"
                ></slider-toggle-mobile-aware>
              </div>
              <div class="value" *ngIf="!(capabilities$ | async)?.onboardNetwork?.capable">
                {{ 'configurations.onboarding.locationNotCapable' | translate }}
              </div>
            </div>

            <div class="row" *ngIf="wpa3capable$ | async">
              <div class="key">{{ 'configurations.onboarding.backhaulWpaMode' | translate }}</div>
              <div class="value">
                <slider-toggle-mobile-aware
                  [options]="backhaulWpaMode"
                  [editable]="plume.isSomeAdminRole()"
                  (toggle)="action('backhaulWpaMode', $event, backhaulResponse)"
                ></slider-toggle-mobile-aware>
              </div>
            </div>

            <div class="row" *ngIf="(capabilities$ | async)?.hitlessTopology.capable">
              <div class="key">{{ 'configurations.onboarding.hitlessTopology' | translate }}</div>
              <div class="value">
                <slider-toggle-mobile-aware
                  [options]="hitlessTopologyItems$ | async"
                  [editable]="plume.isSomeAdminRole()"
                  (toggle)="action('hitlessTopology', $event, backhaulResponse)"
                ></slider-toggle-mobile-aware>
              </div>
            </div>

            <div class="row">
              <div class="key">{{ 'configurations.onboarding.bleMode' | translate }}</div>
              <div class="value">
                <drop-down class="selector medium" [disabled]="nodes?.length === 0">
                  {{ bleSelection.currentNode | translate }}
                  <ng-container dropdown>
                    <li
                      (click)="selectNodebleMode('configurations.onboarding.allNodes')"
                      *ngIf="bleSelection.currentNode !== 'configurations.onboarding.allNodes'"
                    >
                      {{ 'configurations.onboarding.allNodes' | translate }}
                    </li>
                    <li (click)="selectNodebleMode(node.id)" *ngFor="let node of nodes">
                      {{ node.id }}
                    </li>
                  </ng-container>
                </drop-down>
                <drop-down class="selector medium" [disabled]="nodes?.length === 0">
                  {{ bleSelection.current }}
                  <ng-container dropdown>
                    <li (click)="selectbleMode(blemode)" *ngFor="let blemode of bleSelection.bleModes">
                      {{ blemode }}
                    </li>
                  </ng-container>
                </drop-down>
                <button
                  class="action medium"
                  [class.disabled]="!plume.isSomeAdminRole() || nodes?.length === 0"
                  (click)="setbleMode()"
                >
                  {{ 'configurations.onboarding.set' | translate }}
                </button>
              </div>
            </div>
            <div class="row" *ngIf="!(capabilities$ | async)?.wps.capable">
              <div class="key">{{ 'configurations.onboarding.wps' | translate }}</div>
              <div class="value">{{ 'configurations.onboarding.locationNotCapable' | translate }}</div>
            </div>
            <div class="row" *ngIf="(capabilities$ | async)?.wps.capable">
              <div class="key">{{ 'configurations.onboarding.wps' | translate }}</div>
              <div class="value">
                <drop-down class="selector medium">
                  {{ wpsSelection.currentNode | translate }}
                  <ng-container dropdown>
                    <li (click)="selectNodeWps(node.id)" *ngFor="let node of nodes">
                      {{ node.id }}
                    </li>
                  </ng-container>
                </drop-down>
                <ng-container *ngIf="fronthaul$ | async | cache as fronthaul">
                  <ng-container *ngIf="fronthaul.length > 1">
                    <drop-down-select
                      class="selector medium"
                      [emptyText]="'configurations.onboarding.defaultNetwork' | translate"
                      [values]="fronthaul"
                      [(ownModel)]="wpsSelection.networkId"
                    >
                    </drop-down-select>
                  </ng-container>
                </ng-container>
                <drop-down class="selector medium">
                  {{ wpsSelection.currentKeyString | translate }}
                  <ng-container dropdown>
                    <li
                      (click)="selectWpsKey('configurations.onboarding.selectKeyOrDefault')"
                      *ngIf="wpsSelection.currentKey !== 'configurations.onboarding.selectKeyOrDefault'"
                    >
                      {{ 'configurations.onboarding.selectKeyOrDefault' | translate }}
                    </li>
                    <li
                      (click)="selectWpsKey(key.id, key.accessZone, key.accessZoneId)"
                      *ngFor="let key of wpsSelection.keys"
                    >
                      {{ key.id }} - {{ 'health.networkInformation.modal.' + key.accessZone | translate }} :
                      {{ key.accessZoneId }}
                    </li>
                  </ng-container>
                </drop-down>
                <button class="action medium" (click)="wpsStart()" [class.disabled]="!plume.isSomeAdminRole()">
                  {{ 'configurations.onboarding.wpsStart' | translate }}
                </button>
              </div>
            </div>
            <div class="row" *ngIf="!(capabilities$ | async)?.dpp.capable">
              <div class="key">{{ 'configurations.onboarding.dpp' | translate }}</div>
              <div class="value">{{ 'configurations.onboarding.locationNotCapable' | translate }}</div>
            </div>
            <div class="row" *ngIf="(capabilities$ | async)?.dpp.capable">
              <div class="key">{{ 'configurations.onboarding.dpp' | translate }}</div>
              <div class="value">
                <slider-toggle-mobile-aware
                  [options]="dppItems$ | async"
                  [editable]="plume.isSomeAdminRole()"
                  (toggle)="action('dpp', $event, backhaulResponse)"
                ></slider-toggle-mobile-aware>
              </div>
            </div>
            <div class="row" *ngIf="internet">
              <div class="key">{{ 'configurations.onboarding.onboardingState' | translate }}</div>
              <div class="value state">
                <ng-container *ngIf="internet.summary?.onboardingStatus === 'OnboardingComplete'">
                  {{ 'onboardingStatus.onboardingComplete' | translate }}
                  <icon name="icon-info" class="info" [tooltip]="internet.summary?.onboardedAt | date: 'L LT'"></icon>
                  <button
                    *ngIf="plume.isSomeAdminRole()"
                    class="action medium"
                    [class.disabled]="onboardingEditLoading"
                    (click)="resetOnboarding()"
                  >
                    {{ 'configurations.onboarding.resetOnboarding' | translate }}
                  </button>
                </ng-container>
                <ng-container *ngIf="internet.summary?.onboardingStatus !== 'OnboardingComplete'">
                  <ng-container *ngIf="internet.summary?.onboardingStatus === 'PodsAdded'">
                    {{ 'onboardingStatus.onboardingPodsAdded' | translate }}
                  </ng-container>
                  <ng-container *ngIf="internet.summary?.onboardingStatus !== 'PodsAdded'">
                    {{ 'onboardingStatus.onboardingNotComplete' | translate }}
                  </ng-container>
                  <button
                    *ngIf="plume.isSomeAdminRole()"
                    class="action medium"
                    [class.disabled]="onboardingEditLoading"
                    (click)="completeOnboarding(location.ssid, location.profile)"
                  >
                    {{ 'configurations.onboarding.completeOnboarding' | translate }}
                  </button>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </collapsiblebox>
  </div>
</div>
