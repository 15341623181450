<div class="container-fluid">
  <ng-container *ngIf="onboarded == 'uncomplete' && ui != 'noc'">
    <spacer>{{ 'completeOnboarding' | translate }}</spacer>
  </ng-container>
  <ng-container *ngIf="onboarded == 'complete' || ui == 'noc'">
    <div *ngIf="!allNodes.length">
      <spacer>{{ message | translate }}</spacer>
    </div>
    <div *ngIf="allNodes.length">
      <spacer>
        <div class="info">
          <div class="column" [ngPlural]="allNodes.length">
            <ng-template ngPluralCase="=1">{{ allNodes.length }} {{ 'nodes.nodeAccount' | translate }}</ng-template>
            <ng-template ngPluralCase="other">{{ allNodes.length }} {{ 'nodes.nodesAccount' | translate }}</ng-template>
          </div>
          <div class="column">
            <span class="stats">
              <i>{{ online?.length }} {{ 'nodes.online' | translate }},</i>
              <i>{{ offline?.length }} {{ 'nodes.offline' | translate }},</i>
              <i>{{ alarms?.length }} {{ 'nodes.alarms' | translate }}</i>
            </span>
            <span class="search">
              <input
                #filter
                type="text"
                placeholder="{{ 'nodes.search' | translate }}"
                (keyup)="search(filter.value)"
              />
            </span>
          </div>
        </div>
      </spacer>

      <div *ngIf="nodes?.length">
        <node
          [attr.id]="node.id"
          [node]="node"
          (delete)="deleteNode($event)"
          (openHardwareInfoModal)="openModal('hardwareInfo', node)"
          *ngFor="let node of nodes; trackBy: track"
        ></node>
      </div>

      <div *ngIf="!nodes?.length">
        <spacer>{{ message | translate }}</spacer>
      </div>
    </div>
    <div class="footer" *ngIf="message !== 'nodes.messageLoading' && !plume.isFlexRole()">
      <addnode (nodeAdded)="nodeAdded($event)"></addnode>
    </div>
  </ng-container>
</div>

<hardware-info-modal
  [node]="selectedNode"
  (closeHardwareInfoModal)="closeModal('hardwareInfo')"
  *ngIf="hardwareInfoModalOpen"
></hardware-info-modal>
