import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { Line } from 'src/app/lib/d3/models/objects/line';
import { Point } from 'src/app/lib/d3/models/objects/point';
import { Series } from 'src/app/lib/d3/models/objects/series';
import { ILteQoeItem, IMetricsNodeData } from 'src/app/lib/interfaces/lte.interface';
import { LteService } from 'src/app/lib/services/lte.service';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { DataSizeUnits, SpeedConverterService } from 'src/app/lib/services/speed-converter.service';
import * as moment from 'moment';

interface ILineMapperObj {
  dataMapper: string;
  nameMapper: string;
  valueMapper: (num: number, graph: string) => number;
}

type LineMapper = string | ILineMapperObj[];

@Component({
  selector: 'qoelte',
  templateUrl: './qoelte.component.html',
  styleUrls: ['./qoelte.component.scss']
})
export class QoelteComponent implements OnChanges, OnDestroy {
  @Input() live = false;
  @Input() superLiveInterval: number = undefined; // super live mode update period. In falsy/0 then super live mode is disabled

  linegraphs: {
    [key: string]: {
      graph: Line[];
      series: Series[];
      mapper: LineMapper;
      ignore?: boolean;
      minMax?: {
        compute: boolean;
        min?: number;
        max?: number;
      };
      unit?: string;
      unitMapper?: (graph: string) => string;
    };
  } = {
    rssi: {
      graph: [],
      series: [],
      mapper: 'weighted_rssi'
    },
    rsrp: {
      graph: [],
      series: [],
      mapper: 'weighted_rsrp'
    },
    rsrq: {
      graph: [],
      series: [],
      mapper: 'weighted_rsrq'
    },
    sinr: {
      graph: [],
      series: [],
      mapper: 'weighted_sinr'
    },
    signalBar: {
      graph: [],
      series: [],
      mapper: [
        {
          dataMapper: 'signal_bars',
          nameMapper: 'signalBar',
          valueMapper: (value) => (value === undefined ? 0 : value)
        }
      ]
    },
    freqBand: {
      graph: [],
      series: [],
      mapper: 'freq_band'
    },
    totalTxRxBytes: {
      graph: [],
      series: [],
      minMax: {
        compute: true
      },
      unitMapper: (graph) => this.rxTxBytesToUnit(graph),
      mapper: [
        {
          dataMapper: 'total_tx_bytes',
          nameMapper: 'totalTxBytes',
          valueMapper: (value, graph) => (value === undefined ? null : this.rxTxBytesToUsage(value, graph))
        },
        {
          dataMapper: 'total_rx_bytes',
          nameMapper: 'totalRxBytes',
          valueMapper: (value, graph) => (value === undefined ? null : this.rxTxBytesToUsage(value, graph))
        }
      ]
    },
    lteUplinkBandwidth: {
      graph: [],
      series: [],
      mapper: 'lte_uplink_bandwidth',
      ignore: !this.plume.cloudVersionAbove1_93()
    },
    lteDownlinkBandwidth: {
      graph: [],
      series: [],
      mapper: 'lte_downlink_bandwidth',
      ignore: !this.plume.cloudVersionAbove1_93()
    }
  };

  liveGraphs: {
    data: Line[];
    series: Series[];
    minValue: number;
    maxValue: number;
    unit?: string;
  }[] = [];

  chartMode = '24h';

  chartModes = [
    { value: '24h' as const, translation: '24h', selected: this.chartMode === '24h' ? true : false },
    { value: '7d' as const, translation: '7d', selected: this.chartMode === '7d' ? true : false }
  ];

  intervalCleaner = null;
  loading = true;

  constructor(private lte: LteService, public plume: PlumeService, private speedConvertor: SpeedConverterService) {}

  ngOnChanges(): void {
    clearInterval(this.intervalCleaner);

    this.loading = true;

    if (this.live) {
      if (this.plume.cloudVersionAbove1_93()) {
        this.clearAllLineGraph();

        this.intervalCleaner = setInterval(() => {
          this.loadLineLiveGraphs();
        }, (this.superLiveInterval ?? 30) * 1000);

        this.loadLineLiveGraphs();
      } else {
        this.loading = false;
      }
    } else {
      this.liveGraphs = [];

      this.toggleChartMode('24h');

      this.intervalCleaner = setInterval(() => {
        this.loadLineGraphs();
      }, 60 * 1000);
    }
  }

  toggleChartMode(mode: '24h' | '7d'): void {
    this.chartMode = mode;
    this.loadLineGraphs();
  }

  liveGraphTrack(
    index: number,
    item: {
      series: Series[];
      data: Line[];
      minValue: number;
      maxValue: number;
      unit?: string;
    }
  ): string {
    return item?.series?.[0]?.text;
  }

  private loadLineGraphs(): void {
    this.lte.qoe$(this.chartMode === '24h' ? 1 : 7).subscribe((response: Partial<IMetricsNodeData>) => {
      this.loading = false;

      this.clearAllLineGraph();

      if (!response) {
        response = {};
      }

      for (const graph in this.linegraphs) {
        if (this.linegraphs.hasOwnProperty(graph) && !this.linegraphs[graph].ignore) {
          this.getMinMax(graph, response);

          if (this.linegraphs[graph].unitMapper) {
            this.linegraphs[graph].unit = this.linegraphs[graph].unitMapper(graph);
          }

          this.linegraphs[graph].graph = this.generateLines(graph, response);
          this.linegraphs[graph].series = this.linegraphs[graph].graph.map((dataObj: any) => dataObj.series);
        }
      }
    });
  }

  private rxTxBytesToUsage(value: number, graph: string): number {
    if (value === null) {
      return null;
    }

    const kbs = (value * 8) / (900 * 1000);

    if (
      !this.linegraphs[graph]?.minMax?.compute ||
      this.linegraphs[graph]?.minMax?.min === undefined ||
      this.linegraphs[graph]?.minMax?.max === undefined
    ) {
      return kbs;
    }

    return this.speedConvertor.convertFromBits(
      this.speedConvertor.convertToBits(kbs, 'Kb'),
      this.linegraphs[graph].unit.split('/')[0] as any,
      2
    );
  }

  private getMinMax(graph: string, data: Partial<IMetricsNodeData>): void {
    if (!this.linegraphs[graph]?.minMax?.compute) {
      return;
    }

    this.linegraphs[graph].minMax = { min: undefined, max: undefined, compute: true };

    const mapper = this.linegraphs[graph].mapper;
    const lineMapperObjArray: ILineMapperObj[] =
      typeof mapper === 'string'
        ? [{ dataMapper: mapper, nameMapper: graph, valueMapper: (val) => (val === undefined ? null : val) }]
        : mapper;

    this.linegraphs[graph].minMax = lineMapperObjArray
      .map(
        (mapValue: ILineMapperObj) =>
          data[mapValue.dataMapper]?.map((obj: ILteQoeItem) => mapValue.valueMapper(obj.value, graph)) || []
      )
      .reduce((acc, val) => [...acc, ...val], [] as number[])
      .reduce(
        (acc, val) => ({
          min: acc.min === undefined ? val : Math.min(val, acc.min),
          max: acc.max === undefined ? val : Math.max(val, acc.max),
          compute: true
        }),
        { min: undefined, max: undefined, compute: true }
      );
  }

  private rxTxBytesToUnit(graph: string): string {
    return (
      this.speedConvertor.convertToOptimalUnit(this.linegraphs[graph]?.minMax?.max, 'Kb', true, 2, false).unit + '/s'
    );
  }

  private generateLines(graph: string, data: Partial<IMetricsNodeData>): Line[] {
    const date = moment();
    const roundedDown = Math.floor(date.minute() / 15) * 15;
    const start = date.minute(roundedDown).startOf('minute');
    const dates = {};

    for (let i = 0; i < (this.chartMode === '24h' ? 96 : 672); i++) {
      dates[start.subtract(15, 'minutes').toISOString()] = null;
    }

    const mapper = this.linegraphs[graph].mapper;
    const lineMapperObjArray: ILineMapperObj[] =
      typeof mapper === 'string'
        ? [{ dataMapper: mapper, nameMapper: graph, valueMapper: (val) => (val === undefined ? null : val) }]
        : mapper;

    return lineMapperObjArray.map((mapValue: ILineMapperObj) => {
      const series = new Series(
        'rgb(0, 156, 223)',
        mapValue.nameMapper,
        'qoe.lteGraphs.tooltips.' + mapValue.nameMapper
      );

      if (data[mapValue.dataMapper]) {
        data[mapValue.dataMapper].forEach((datapoint) => {
          dates[datapoint.timestamp] = datapoint.value;
        });

        const converted = Object.keys(dates).map((key) => ({ timestamp: key, value: dates[key] }));

        return new Line(
          series,
          'left',
          converted.map((obj) => new Point(new Date(obj.timestamp).toString(), mapValue.valueMapper(obj.value, graph)))
        );
      } else {
        return new Line(series, 'left', []);
      }
    });
  }

  private clearAllLineGraph(): void {
    for (const graph in this.linegraphs) {
      if (this.linegraphs.hasOwnProperty(graph)) {
        this.clearLineGraph(this.linegraphs[graph]);
      }
    }
  }

  private clearLineGraph(graph: { graph: object[]; series: object[] }): void {
    graph.graph = [
      {
        series: {
          color: 'var(--chart-green)',
          text: '',
          translation: ''
        },
        axis: 'left',
        data: []
      }
    ];

    graph.series = graph.graph.map((dataObj: any) => dataObj.series);
  }

  private loadLineLiveGraphs(): void {
    this.lte.qoeLive$(!!this.superLiveInterval).subscribe((response) => {
      this.loading = false;

      const metrics = response && response.length > 0 ? response[0] : null;

      if (!metrics) {
        this.liveGraphs = [];
        return;
      }

      const points = metrics.samples
        .sort((a, b) => (a.timestamp < b.timestamp ? -1 : a.timestamp > b.timestamp ? 1 : 0))
        .reduce((acc, item) => {
          Object.keys(item)
            .filter((key) => key !== 'timestamp')
            .forEach((key) => {
              acc[key] = [...(acc[key] ?? []), new Point(new Date(item.timestamp).toString(), item[key])];
            });
          return acc;
        }, {} as { [key: string]: Point[] });

      this.liveGraphs = Object.keys(points)
        .map((key) => ({
          key,
          maxValue: key === 'signalBar' ? 5 : Math.max(...points[key].map((item) => item.value)),
          minValue: key === 'signalBar' ? 0 : Math.min(...points[key].map((item) => item.value))
        }))
        .map((value) => ({ ...this.liveGraphsValues(value, points), key: value.key }))
        .map((value) => ({
          ...value,
          data: [
            new Line(
              new Series('rgb(0, 156, 223)', value.key, 'qoe.lteGraphs.labels.' + value.key),
              'left',
              value.points
            )
          ]
        }))
        .map((graph) => ({
          ...graph,
          maxValue: graph.maxValue >= 0 ? Math.max(1, graph.maxValue) : 0,
          minValue: graph.minValue < 0 ? graph.minValue : 0,
          series: graph.data.map((dataObj: any) => dataObj.series)
        }))
        .reverse();
    });
  }

  private liveGraphsValues(
    value: { key: string; maxValue: number; minValue: number },
    points: { [key: string]: Point[] }
  ): { points: Point[]; maxValue: number; minValue: number; unit?: DataSizeUnits } {
    if (value.key === 'txBytes' || value.key === 'rxBytes') {
      const idealUnit = this.speedConvertor.convertToOptimalUnit(value.maxValue, 'B', true, 2, true).unit;

      return {
        points: points[value.key].map((point) => ({
          ...point,
          value: this.speedConvertor.convertFromBits(this.speedConvertor.convertToBits(point.value, 'B'), idealUnit, 2)
        })),
        unit: idealUnit,
        maxValue: this.speedConvertor.convertFromBits(
          this.speedConvertor.convertToBits(value.maxValue, 'B'),
          idealUnit,
          2
        ),
        minValue: 0
      };
    }

    return { points: points[value.key], maxValue: value.maxValue, minValue: value.minValue };
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalCleaner);
  }
}
