<ng-container *ngrxLet="location$ as location">
  <div class="container-fluid desktop" cdk-scrollable>
    <ng-container *ngIf="permissions?.centralAccess && locationList$ | async | cache as locationList">
      <div class="row locations" *ngIf="locationList?.length > 1">
        <drop-down class="selector medium">
          {{ location?.name }}
          <ng-container dropdown>
            <ng-container *ngFor="let dropDownLocation of locationList">
              <li (click)="changeLocation(dropDownLocation)" *ngIf="location?.id !== dropDownLocation?.id">
                {{ dropDownLocation?.name }}
              </li>
            </ng-container>
          </ng-container>
        </drop-down>
      </div>
    </ng-container>
    <div class="group" *ngIf="!isFlexAdmin">
      <div
        class="row middle-xs menu unlocked"
        routerLink="health"
        routerLinkActive="active"
        *ngIf="permissions?.centralAccess || permissions?.healthAccess"
      >
        <icon name="icon-health-check" class="size-1_5"></icon>
        {{ 'sidemenu.healthCheck' | translate }}
      </div>
      <div
        class="row middle-xs menu unlocked"
        routerLink="profiler"
        routerLinkActive="active"
        *ngIf="permissions?.centralAccess && permissions?.profilerAccess && ui === 'noc' && location.profile === 'auto'"
      >
        <icon name="icon-profiler" class="size-1_5"></icon>
        {{ 'sidemenu.profiler' | translate }}
      </div>
    </div>
    <div class="group">
      <div
        class="row middle-xs menu"
        [ngClass]="{ unlocked: onboarded || ui === 'noc', locked: !onboarded && ui !== 'noc' }"
        routerLink="nodes"
        routerLinkActive="active"
        *ngIf="permissions?.centralAccess"
      >
        <icon name="icon-pod-menu" class="size-1_5"></icon>
        {{ 'sidemenu.podsAndNodes' | translate }}
      </div>
      <div
        class="row middle-xs menu"
        [ngClass]="{ unlocked: onboarded || ui === 'noc', locked: !onboarded && ui !== 'noc' }"
        routerLink="devices"
        routerLinkActive="active"
        *ngIf="permissions?.centralAccess"
      >
        <icon name="icon-device" class="size-1_5"></icon>
        {{ 'sidemenu.devices' | translate }}
      </div>
      <div
        class="row middle-xs menu"
        [ngClass]="{ unlocked: onboarded || ui === 'noc', locked: !onboarded && ui !== 'noc' }"
        routerLink="topology"
        routerLinkActive="active"
        *ngIf="permissions?.centralAccess"
      >
        <icon name="icon-topology" class="size-1_5"></icon>
        {{ 'sidemenu.topology' | translate }}
      </div>
      <div
        class="row middle-xs menu"
        [ngClass]="{ unlocked: onboarded || ui === 'noc', locked: !onboarded && ui !== 'noc' }"
        routerLink="qoe"
        routerLinkActive="active"
        *ngIf="permissions?.centralAccess && permissions?.qoeAccess"
      >
        <icon name="icon-qoe" class="size-1_5"></icon>
        {{ 'sidemenu.qoe' | translate }}
      </div>
      <div
        class="row middle-xs menu"
        [ngClass]="{ unlocked: onboarded, locked: !onboarded }"
        routerLink="thrive"
        routerLinkActive="active"
        *ngIf="permissions?.centralAccess && permissions?.thriveAccess"
      >
        <icon name="icon-thrive" class="size-1_5"></icon>
        {{ 'sidemenu.thrive' | translate }}
      </div>
      <div
        class="row middle-xs menu"
        [ngClass]="{
          unlocked: onboarded || ui === 'noc',
          locked:
            (!onboarded && ui !== 'noc') ||
            !!!(nodes$ | async)?.length ||
            (!(capabilities$ | async)?.AppPrioritization?.capable && !(capabilities$ | async)?.AppQoE?.capable)
        }"
        routerLink="traffic"
        routerLinkActive="active"
        *ngIf="
          permissions?.centralAccess &&
          permissions?.trafficClassAccess &&
          location?.profile !== 'property' &&
          !isFlexAdmin
        "
      >
        <icon name="icon-traffic" class="size-1_5"></icon>
        {{ 'sidemenu.trafficClass' | translate }}
      </div>
      <div
        class="row middle-xs menu"
        [ngClass]="{ unlocked: onboarded || ui === 'noc', locked: !onboarded && ui !== 'noc' }"
        routerLink="flex"
        routerLinkActive="active"
        *ngIf="permissions?.centralAccess && location?.flex"
      >
        <icon name="icon-building" class="size-1_5"></icon>
        {{ 'sidemenu.flex' | translate }}
      </div>
      <div
        class="row middle-xs menu"
        [ngClass]="{ unlocked: onboarded || ui === 'noc', locked: !onboarded && ui !== 'noc' }"
        routerLink="lte"
        routerLinkActive="active"
        *ngIf="permissions?.centralAccess && !isFlexAdmin && (capabilities$ | async)?.lte?.capable"
      >
        <icon name="lte-menu" class="size-1_5"></icon>
        {{ 'sidemenu.lte' | translate }}
      </div>
    </div>
    <div class="group" *ngIf="!isFlexAdmin">
      <div
        class="row middle-xs menu"
        [ngClass]="{ unlocked: onboarded || ui === 'noc', locked: !onboarded && ui !== 'noc' }"
        routerLink="timelines"
        routerLinkActive="active"
        *ngIf="permissions?.centralAccess && permissions?.timelinesAccess"
      >
        <icon name="icon-timeline" class="size-1_5"></icon>
        {{ 'sidemenu.timelines' | translate }}
      </div>
      <div
        class="row middle-xs menu"
        [ngClass]="{
          unlocked: onboarded || ui === 'noc',
          locked: (!onboarded && ui !== 'noc') || (hasSecurity$ | async) !== true
        }"
        routerLink="security"
        routerLinkActive="active"
        *ngIf="permissions?.centralAccess && permissions?.aiSecurityAccess"
      >
        <icon name="icon-shield" class="size-1_5"></icon>
        <ng-container *ngIf="location?.profile !== 'smallbusiness'">
          {{ 'sidemenu.guard' | translate }}
        </ng-container>
        <ng-container *ngIf="location?.profile === 'smallbusiness'">
          {{ 'sidemenu.shield' | translate }}
        </ng-container>
      </div>
      <div
        class="row middle-xs menu"
        [ngClass]="{
          unlocked: onboarded || ui === 'noc',
          locked: (!onboarded && ui !== 'noc') || (capabilities$ | async)?.wifiMotion?.capable === false
        }"
        routerLink="motion"
        routerLinkActive="active"
        *ngIf="permissions?.centralAccess && permissions?.plumeMotionAccess"
      >
        <icon name="icon-sense" class="size-1_5"></icon>
        <ng-container *ngIf="location?.profile !== 'smallbusiness'">
          {{ 'sidemenu.sense' | translate }}
        </ng-container>
        <ng-container *ngIf="location?.profile === 'smallbusiness'">
          {{ 'sidemenu.flow' | translate }}
        </ng-container>
      </div>
      <div
        class="row middle-xs menu"
        [ngClass]="{ unlocked: onboarded || ui === 'noc', locked: !onboarded && ui !== 'noc' }"
        routerLink="speedtests"
        routerLinkActive="active"
        *ngIf="permissions?.centralAccess && permissions?.speedTestsAccess"
      >
        <icon name="icon-speed-test" class="size-1_5"></icon>
        {{ 'sidemenu.speedTests' | translate }}
      </div>
      <div
        class="row middle-xs menu unlocked"
        routerLink="technician"
        routerLinkActive="active"
        *ngIf="permissions?.technicianDashboardAccess"
      >
        <icon name="icon-controls" class="size-1_5"></icon>
        {{ 'sidemenu.techDashboard' | translate }}
      </div>
    </div>
    <ng-container *ngIf="ui === 'noc' && !isFlexAdmin && !isUpriseProperyManager && permissions?.configurationsAccess">
      <div class="group">
        <div class="row middle-xs menu unlocked" routerLink="configuration" routerLinkActive="active">
          <icon name="icon-configuration" class="size-1_5"></icon>
          {{ 'sidemenu.configuration' | translate }}
        </div>
      </div>
    </ng-container>
  </div>

  <div class="container-fluid mobile">
    <div
      class="menu unlocked"
      routerLink="health"
      routerLinkActive="active"
      *ngIf="(permissions?.centralAccess || permissions?.healthAccess) && !isFlexAdmin"
    >
      <icon name="icon-health-check" class="size-1_5"></icon>
    </div>
    <div
      class="menu"
      [ngClass]="{ unlocked: onboarded || ui === 'noc', locked: !onboarded && ui !== 'noc' }"
      routerLink="nodes"
      routerLinkActive="active"
      *ngIf="permissions?.centralAccess"
    >
      <icon name="icon-pod" class="size-1_5"></icon>
    </div>
    <div
      class="menu"
      [ngClass]="{ unlocked: onboarded || ui === 'noc', locked: !onboarded && ui !== 'noc' }"
      routerLink="devices"
      routerLinkActive="active"
      *ngIf="permissions?.centralAccess"
    >
      <icon name="icon-device" class="size-1_5"></icon>
    </div>
    <div
      class="menu"
      [ngClass]="{ unlocked: onboarded || ui === 'noc', locked: !onboarded && ui !== 'noc' }"
      routerLink="topology"
      routerLinkActive="active"
      *ngIf="permissions?.centralAccess"
    >
      <icon name="icon-topology" class="size-1_5"></icon>
    </div>
    <div
      class="menu"
      [ngClass]="{ unlocked: onboarded || ui === 'noc', locked: !onboarded && ui !== 'noc' }"
      routerLink="qoe"
      routerLinkActive="active"
      *ngIf="permissions?.centralAccess && permissions?.qoeAccess"
    >
      <icon name="icon-qoe" class="size-1_5"></icon>
    </div>
    <div
      class="menu"
      [ngClass]="{ unlocked: onboarded, locked: !onboarded }"
      routerLink="thrive"
      routerLinkActive="active"
      *ngIf="permissions?.centralAccess && permissions?.thriveAccess"
    >
      <icon name="icon-thrive" class="size-1_5"></icon>
    </div>
    <div
      class="menu"
      [ngClass]="{
        unlocked: onboarded || ui === 'noc',
        locked:
          (!onboarded && ui !== 'noc') ||
          !!!(nodes$ | async)?.length ||
          (!(capabilities$ | async)?.AppPrioritization?.capable && !(capabilities$ | async)?.AppQoE?.capable)
      }"
      routerLink="traffic"
      routerLinkActive="active"
      *ngIf="
        permissions?.centralAccess &&
        permissions?.trafficClassAccess &&
        location?.profile !== 'smallbusiness' &&
        location?.profile !== 'property' &&
        !isFlexAdmin
      "
    >
      <icon name="icon-traffic" class="size-1_5"></icon>
    </div>
    <div
      class="menu"
      [ngClass]="{ unlocked: onboarded || ui === 'noc', locked: !onboarded && ui !== 'noc' }"
      routerLink="flex"
      routerLinkActive="active"
      *ngIf="permissions?.centralAccess && location?.flex"
    >
      <icon name="icon-building" class="size-1_5"></icon>
    </div>
    <div
      class="menu"
      [ngClass]="{ unlocked: onboarded || ui === 'noc', locked: !onboarded && ui !== 'noc' }"
      routerLink="timelines"
      routerLinkActive="active"
      *ngIf="permissions?.centralAccess && permissions?.timelinesAccess && !isFlexAdmin"
    >
      <icon name="icon-timeline" class="size-1_5"></icon>
    </div>
    <div
      class="menu"
      [ngClass]="{
        unlocked: onboarded || ui === 'noc',
        locked: (!onboarded && ui !== 'noc') || (hasSecurity$ | async) !== true
      }"
      routerLink="security"
      routerLinkActive="active"
      *ngIf="permissions?.centralAccess && permissions?.aiSecurityAccess && !isFlexAdmin"
    >
      <icon name="icon-shield" class="size-1_5"></icon>
    </div>
    <div
      class="menu"
      [ngClass]="{
        unlocked: onboarded || ui === 'noc',
        locked: (!onboarded && ui !== 'noc') || (capabilities$ | async)?.wifiMotion?.capable === false
      }"
      routerLink="motion"
      routerLinkActive="active"
      *ngIf="permissions?.centralAccess && permissions?.plumeMotionAccess && !isFlexAdmin"
    >
      <icon name="icon-sense" class="size-1_5"></icon>
    </div>
    <div
      class="menu"
      [ngClass]="{ unlocked: onboarded || ui === 'noc', locked: !onboarded && ui !== 'noc' }"
      routerLink="speedtests"
      routerLinkActive="active"
      *ngIf="permissions?.centralAccess && permissions?.speedTestsAccess && !isFlexAdmin"
    >
      <icon name="icon-speed-test" class="size-1_5"></icon>
    </div>
    <div
      class="menu unlocked"
      routerLink="technician"
      routerLinkActive="active"
      *ngIf="permissions?.technicianDashboardAccess && !isFlexAdmin"
    >
      <icon name="icon-controls" class="size-1_5"></icon>
    </div>
    <ng-container *ngIf="ui === 'noc' && !isFlexAdmin && !isUpriseProperyManager && permissions?.configurationsAccess">
      <div class="menu unlocked" routerLink="configuration" routerLinkActive="active">
        <icon name="icon-configuration" class="size-1_5"></icon>
      </div>
    </ng-container>
  </div>
</ng-container>
