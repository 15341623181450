import { Component, Input, OnInit } from '@angular/core';
import { QoeHelper } from 'src/app/lib/helpers/qoe.helper';

@Component({
  selector: 'grid-charts-details',
  templateUrl: './grid-charts-details.component.html',
  styleUrls: ['./grid-charts-details.component.scss']
})
export class GridChartsDetailsComponent implements OnInit {
  @Input() data: { color: string; title: string; totalUsage?: string; percentage?: number; qoeScore?: number };
  @Input() titleColor = false;
  helper = new QoeHelper();

  constructor() {}

  ngOnInit(): void {}

  colorByScore(score: number): string {
    const index = Math.floor(score) - 1 < 1 ? 0 : Math.floor(score) - 1;
    return this.helper.gradeColors[index];
  }
}
