import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { IFlexStatIpSec, IFlexUptime } from '../interfaces/flex.interface';
import { map, switchMap, take } from 'rxjs/operators';
import { PlumeService } from './plume.service';
import { Store } from '@ngrx/store';
import { selectCustomerIdentification } from 'src/app/store/customer/customer.selectors';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class FlexService {
  fdqn = this.plume.getEnv()?.flexUrl;

  get baseUrl$(): Observable<string> {
    if (!this.fdqn) {
      return throwError('no FLEX set - FLEX not supported');
    }

    return this.store.select(selectCustomerIdentification).pipe(
      map(({ locationid }) => `${this.fdqn}/v1/locations/${locationid}`),
      take(1)
    );
  }

  constructor(private plume: PlumeService, private store: Store, private api: ApiService, private auth: AuthService) {}

  getIpSec(startTime: string, endTime: string): Observable<IFlexStatIpSec> {
    return this.baseUrl$.pipe(
      switchMap((baseUrl) =>
        this.api.raw(
          'get',
          `${baseUrl}/statistics/ipsec?startTime=${startTime}&endTime=${endTime}`,
          {},
          { headers: this.auth.getFrontlineHeader() }
        )
      ),
      map((data: IFlexStatIpSec) => this.sliceGraphStatisticByRange(data))
    );
  }

  getRadiusServer(startTime: string, endTime: string): Observable<IFlexStatIpSec> {
    return this.baseUrl$.pipe(
      switchMap((baseUrl) =>
        this.api.raw(
          'get',
          `${baseUrl}/statistics/radius?startTime=${startTime}&endTime=${endTime}`,
          {},
          { headers: this.auth.getFrontlineHeader() }
        )
      ),
      map((data: IFlexStatIpSec) => this.sliceGraphStatisticByRange(data))
    );
  }

  getUptime$(startTime: string, endTime: string): Observable<IFlexUptime> {
    return this.baseUrl$.pipe(
      switchMap((baseUrl) =>
        this.api.raw(
          'get',
          `${baseUrl}/statistics/uptime?startTime=${startTime}&endTime=${endTime}`,
          {},
          { headers: this.auth.getFrontlineHeader() }
        )
      )
    );
  }

  private sliceGraphStatisticByRange(data: IFlexStatIpSec): IFlexStatIpSec {
    return {
      range: data.range,
      data: {
        events: data.data.events.map((event) => ({
          value: event.value,
          startTime: event.startTime < data.range.startTime ? data.range.startTime : event.startTime,
          endTime: !event.endTime || event.endTime > data.range.endTime ? data.range.endTime : event.endTime
        }))
      }
    };
  }
}
