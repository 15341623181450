<ng-container *ngIf="onboarded == 'uncomplete' && !(ui == 'noc' && nodes.length)">
  <div class="container-fluid">
    <spacer>{{ 'completeOnboarding' | translate }}</spacer>
  </div>
</ng-container>
<ng-container *ngIf="onboarded == 'complete' || (ui == 'noc' && nodes.length)">
  <div id="topology-chart-area">
    <forcechart
      [loading]="loading"
      [nodesList]="nodes"
      [linksList]="links"
      [checkQoe]="checkQoe"
      [checkTopology]="checkTopology"
      [ttm]="permissions.uiFeatures.topologyTimeMachine"
      [update]="locationChange"
      [vpn]="vpn"
      [enableRealtime]="permissions.bandwidthAccess"
    ></forcechart>
  </div>
</ng-container>
