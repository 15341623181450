import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { ApiService } from 'src/app/lib/services/api.service';
import { DeviceIconRefService } from 'src/app/lib/services/deviceIconRef.service';
import { IconService } from 'src/app/lib/services/icon.service';
import { LoggingService } from 'src/app/lib/services/logging.service';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { ToastService } from 'src/app/lib/services/toast.service';
import { TroubleshootingService } from 'src/app/lib/services/troubleshooting.service';
import { selectDevices } from 'src/app/store/polling/polling.selector';

@Component({
  selector: 'devicehealthalarm',
  templateUrl: './devicehealthalarm.component.html',
  styleUrls: ['./devicehealthalarm.component.scss']
})
export class DeviceHealthAlarmComponent implements OnChanges, OnDestroy {
  @Input()
  device: any = {};

  @Input()
  nodes: any = {};

  @Input()
  selected: any;

  @Input()
  extender: any;

  @Output()
  section: EventEmitter<string> = new EventEmitter<string>();

  axis24: string;
  axis12: string;
  axisNow: string;
  expand: boolean = false;
  troubleshoot: boolean = false;
  showProgress: boolean = false;
  devicesPool: any = [];
  resetTime: any;
  testInterval: any;
  resetTimeout: number = 120000; // seconds for timeout
  deviceSubscription: any;
  step: string = '';
  reset: string = 'init';
  resetActive: boolean = false;
  deviceHistory: boolean = false;

  constructor(
    private api: ApiService,
    private plume: PlumeService,
    private logging: LoggingService,
    private troubleShoot: TroubleshootingService,
    private store: Store,
    private iconRef: DeviceIconRefService,
    private toast: ToastService,
    private icons: IconService
  ) {
    this.axisNow = 'health.networkPerformance.deviceHealthAlarm.now';
    this.axis12 = moment().subtract(12, 'hours').format('h a');
    this.axis24 = moment().subtract(24, 'hours').format('h a');
  }

  ngOnChanges(changes: any): void {
    if (changes && changes.selected && changes.selected.currentValue !== this.device.id) {
      this.expand = false;
    }
    this.logging.debug('<deviceHealthAlarm> ', this.device);
  }

  select(type: string): void {
    this.section.emit(type);
  }

  handleQuestion(answer: string): void {
    if (this.step === answer) {
      this.step = '';
    } else {
      this.step = answer;
    }
  }

  getIconPath(): string {
    return this.icons.getPath(this.device.kind?.type?.iconV2 || this.device.iconV2);
  }

  getIconV3Path(): string {
    return this.icons.getV3Path( this.device.iconV3, 'small');
  }

  findDeviceInPool(): void {
    this.devicesPool.forEach((dev: any) => {
      if (dev.mac === this.device.mac) {
        this.logging.log(' connectionState: ' + dev.mac, dev.connectionState);
        const isBefore = moment(this.resetTime).isBefore(dev.connectionStateChangeAt);
        this.logging.log(
          ' connectionStateChangeAt: ' + moment(dev.connectionStateChangeAt).format('HH:mm:ss:ms') + '  isBefore? ',
          isBefore
        );

        if (dev.connectionState === 'connected' && isBefore) {
          this.resetSuccess();
        }
      }
    });
  }

  startPoolDevices(): void {
    this.logging.log('<startPoolDevices>');

    this.deviceSubscription = this.store.select(selectDevices).subscribe((devices) => {
      if (devices) {
        devices.forEach((device: any) => {
          device.deviceIcon = this.iconRef.get(device.icon) + '.svg';
        });

        this.devicesPool = devices;
        this.logging.log('<deviceHealth> devicePool: ', this.devicesPool);
        this.findDeviceInPool();
      }
    });
  }

  doReset(): void {
    this.resetActive = true;
    this.logging.log('      doReset.device: ', this.device);
    this.logging.log('      doReset.deviceMac: ', this.device.mac);
    this.logging.log('      doReset.parentNodeId: ', this.device.leafToRoot[0].id);
    this.logging.log('      doReset.freqBand: ', this.device.freqBand);

    this.troubleShoot
      .resetDevice(this.device.mac, this.device.leafToRoot[0].id, this.device.freqBand)
      .subscribe((resetResponse: any) => {
        this.logging.log('<deviceHealth> RESET DEVICE', resetResponse);
        this.resetTime = moment();

        this.startPoolDevices();
      });

    this.showProgress = true;

    if (this.testInterval) {
      clearInterval(this.testInterval);
    }

    this.testInterval = setInterval(() => {
      this.logging.log('<devicehealthalarm> reset timeout', this.resetTimeout);
      this.resetComplete();
    }, this.resetTimeout);
  }

  checkResetResult(): void {
    if (this.device.health.status === 'poor') {
      this.reset = 'resetPoor';
    } else {
      this.reset = 'resetOk';
    }
  }

  resetComplete(): void {
    this.toast.warning('toast.deviceHealthAlarm.warningMessage', 'toast.deviceHealthAlarm.warningTitle', {
      disableTimeOut: true,
      params: {
        mac: this.device.mac
      }
    });

    this.resetActive = false;

    if (this.deviceSubscription) {
      this.deviceSubscription.unsubscribe();
    }
    if (this.testInterval) {
      clearInterval(this.testInterval);
    }

    this.logging.log('<deviceHealth> RESET COMPLETE via TIMEOUT');
    this.showProgress = false;
    this.checkResetResult();
  }

  resetSuccess(): void {
    this.resetActive = false;

    if (this.deviceSubscription) {
      this.deviceSubscription.unsubscribe();
    }

    if (this.testInterval) {
      clearInterval(this.testInterval);
    }

    this.logging.log('<deviceHealth> RESET SUCCESS');
    this.checkResetResult();
    this.showProgress = false;
  }

  handleButton(button: string): void {
    switch (button) {
      case 'Reset Device Connection': {
        if (!this.resetActive) {
          this.logging.log('Reset initiated from deviceHealthAlarm');
          this.doReset();
        }
        break;
      }
      case 'Relocate Extender or Gateway': {
        break;
      }

      case 'Order Extenders': {
        this.troubleShoot.orderExtenders();
        this.logging.debug('<deviceHealth> ORDER EXTENDERS');
        break;
      }

      case 'Finish': {
        this.step = '';
        this.reset = 'init';
        this.troubleshoot = false;
        this.expand = false;
      }
    }
  }

  startTroubleshooting(): void {
    this.troubleshoot = true;
    this.expand = true;
  }

  toggleDevice(): void {
    this.logging.debug('devices.toggleDevice: ', this.device);
    this.device.expand = !this.device.expand;
  }

  toggleHistory(): void {
    this.deviceHistory = !this.deviceHistory;

    if (this.deviceHistory === true && !this.device.historyDevice) {
      this.getHistory();
      this.getBusyness();
    }
  }

  getHistory(): void {
    const encMac = encodeURIComponent(this.device.mac);
    this.api
      .get(
        '/Customers/' +
          this.plume.customerid +
          '/locations/' +
          this.plume.locationid +
          '/devices/' +
          encMac +
          '/rssi?granularity=hours&limit=25',
        'reports'
      )
      .subscribe((rssi: any) => {
        this.device.historyDevice = this.calculateHistory(rssi);
        this.device.nodeConnections = this.calculateNodeConnections(rssi);
      });
  }

  getBusyness(): void {
    const encMac = encodeURIComponent(this.device.mac);
    this.api
      .get(
        '/Customers/' +
          this.plume.customerid +
          '/locations/' +
          this.plume.locationid +
          '/devices/' +
          encMac +
          '/bandwidth?granularity=hours&limit=25',
        'reports'
      )
      .subscribe((bandwidth: any) => {
        this.device.busyness = this.calculateBusyness(bandwidth);
      });
  }

  getColor(index: number): string {
    const colors = [
      'rgb(94, 189, 62)',
      'rgb(255, 185, 0)',
      'rgb(247, 130, 0)',
      'rgb(226, 56, 56)',
      'rgb(151, 57, 153)',
      'rgb(0, 156, 223)',
      'rgb(153, 204, 51)',
      'rgb(204, 153, 102)',
      'rgb(153, 102, 51)',
      'rgb(153, 0, 0)',
      'rgb(102, 51, 102)',
      'rgb(51, 102, 153)'
    ];

    return colors[index - colors.length * Math.floor(index / colors.length)];
  }

  calculateHistory(rssi: any): any[] {
    const data = this.convertRssi(rssi);

    const round = (interval: number, moment: any) => {
      const roundedMinutes = Math.floor(moment.minute() / interval) * interval;
      return moment.clone().minute(roundedMinutes).second(0);
    };

    const start = round(15, moment());

    const history = [];

    for (let i = 0; i < 96; i++) {
      start.subtract(15, 'minutes');

      const tick = {
        time: moment.utc(start).local().format('h:mm a'),
        color: 'rgb(255, 255, 255)',
        border: 'rgb(238, 238, 236)',
        state: 'Offline'
      };

      if (
        data[moment.utc(start).format('YYYY-MM-DD[T]HH:mm:ss.[000Z]')] &&
        data[moment.utc(start).format('YYYY-MM-DD[T]HH:mm:ss.[000Z]')].rssi !== null
      ) {
        if (data[moment.utc(start).format('YYYY-MM-DD[T]HH:mm:ss.[000Z]')].rssi > -65) {
          tick.color = 'rgb(216, 216, 216)';
          tick.border = 'rgb(216, 216, 216)';
          tick.state = 'Online';
        }
        if (
          data[moment.utc(start).format('YYYY-MM-DD[T]HH:mm:ss.[000Z]')].rssi <= -65 &&
          data[moment.utc(start).format('YYYY-MM-DD[T]HH:mm:ss.[000Z]')].rssi >= -70
        ) {
          tick.color = 'rgb(255, 197, 0)';
          tick.border = 'rgb(255, 197, 0)';
          tick.state = 'Warning';
        }
        if (data[moment.utc(start).format('YYYY-MM-DD[T]HH:mm:ss.[000Z]')].rssi < -70) {
          tick.color = 'rgb(237, 30, 121)';
          tick.border = 'rgb(237, 30, 121)';
          tick.state = 'Alarm';
        }
      }

      history.unshift(tick);
    }

    return history;
  }

  convertRssi(rssi: any): any {
    const rssi2g = {};
    const rssi5g = {};

    rssi['2g']['perc25'].forEach((tick: any) => {
      if (tick.value != null) {
        if (rssi2g[tick.timestamp] && rssi2g[tick.timestamp].rssi != null) {
          rssi2g[tick.timestamp].rssi = 0.33 * rssi2g[tick.timestamp].rssi + 0.67 * tick.value;
        } else {
          rssi2g[tick.timestamp] = {
            rssi: tick.value,
            node: tick.node_id
          };
        }
      }
    });

    rssi['2g']['perc75'].forEach((tick: any) => {
      if (tick.value != null) {
        if (rssi2g[tick.timestamp] && rssi2g[tick.timestamp].rssi != null) {
          rssi2g[tick.timestamp].rssi = 0.33 * tick.value + 0.67 * rssi2g[tick.timestamp].rssi;
        } else {
          rssi2g[tick.timestamp] = {
            rssi: tick.value,
            node: tick.node_id
          };
        }
      }
    });

    rssi['5g']['perc25'].forEach((tick: any) => {
      if (tick.value != null) {
        if (rssi5g[tick.timestamp] && rssi5g[tick.timestamp].rssi != null) {
          rssi5g[tick.timestamp].rssi = 0.33 * rssi5g[tick.timestamp].rssi + 0.67 * tick.value;
        } else {
          rssi5g[tick.timestamp] = {
            rssi: tick.value,
            node: tick.node_id
          };
        }
      }
    });

    rssi['5g']['perc75'].forEach((tick: any) => {
      if (tick.value != null) {
        if (rssi5g[tick.timestamp] && rssi5g[tick.timestamp].rssi != null) {
          rssi5g[tick.timestamp].rssi = 0.33 * tick.value + 0.67 * rssi5g[tick.timestamp].rssi;
        } else {
          rssi5g[tick.timestamp] = {
            rssi: tick.value,
            node: tick.node_id
          };
        }
      }
    });

    return { ...rssi2g, ...rssi5g };
  }

  convertBandwidth(bandwidth: any): any {
    const rxtx = {};

    bandwidth.transmitted.forEach((bw: any) => {
      if (rxtx[bw.timestamp]) {
        rxtx[bw.timestamp] = rxtx[bw.timestamp] + bw.value;
      } else {
        rxtx[bw.timestamp] = bw.value;
      }
    });

    bandwidth.received.forEach((bw: any) => {
      if (rxtx[bw.timestamp]) {
        rxtx[bw.timestamp] = rxtx[bw.timestamp] + bw.value;
      } else {
        rxtx[bw.timestamp] = bw.value;
      }
    });

    return rxtx;
  }

  calculateNodeConnections(rssi: any): any[] {
    const data = this.convertRssi(rssi);
    const nodes = {};

    this.nodes.forEach((node: any, index: number) => {
      nodes[node.id] = {
        name: node.nickname || node.defaultName,
        color: this.getColor(index)
      };
    });

    const round = (interval: number, moment: any) => {
      const roundedMinutes = Math.floor(moment.minute() / interval) * interval;
      return moment.clone().minute(roundedMinutes).second(0);
    };

    const start = round(15, moment());

    const history = [];

    for (let i = 0; i < 96; i++) {
      start.subtract(15, 'minutes');

      const tick = {
        time: moment.utc(start).local().format('h:mm a'),
        color: 'rgb(255, 255, 255)',
        border: 'rgb(238, 238, 236)',
        name: 'Offline'
      };

      if (
        data[moment.utc(start).format('YYYY-MM-DD[T]HH:mm:ss.[000Z]')] &&
        data[moment.utc(start).format('YYYY-MM-DD[T]HH:mm:ss.[000Z]')].node !== null
      ) {
        tick.name = nodes[data[moment.utc(start).format('YYYY-MM-DD[T]HH:mm:ss.[000Z]')].node].name;
        tick.color = nodes[data[moment.utc(start).format('YYYY-MM-DD[T]HH:mm:ss.[000Z]')].node].color;
        tick.border = nodes[data[moment.utc(start).format('YYYY-MM-DD[T]HH:mm:ss.[000Z]')].node].color;
      }

      history.unshift(tick);
    }

    return history;
  }

  calculateBusyness(bandwidth: any): any[] {
    const data = this.convertBandwidth(bandwidth);

    const round = (interval: number, moment: any) => {
      const roundedMinutes = Math.floor(moment.minute() / interval) * interval;
      return moment.clone().minute(roundedMinutes).second(0);
    };

    const start = round(15, moment());

    const busyness = [];

    for (let i = 0; i < 96; i++) {
      start.subtract(15, 'minutes');

      const tick = {
        time: moment.utc(start).local().format('h:mm a'),
        color: 'rgb(255, 255, 255)',
        border: 'rgb(238, 238, 236)',
        bw: '0 Mb'
      };

      if (
        data[moment.utc(start).format('YYYY-MM-DD[T]HH:mm:ss.[000Z]')] &&
        data[moment.utc(start).format('YYYY-MM-DD[T]HH:mm:ss.[000Z]')] > 0
      ) {
        const value = Math.round(data[moment.utc(start).format('YYYY-MM-DD[T]HH:mm:ss.[000Z]')] * 100) / 100;
        const color = Math.floor(255 - (value * 255) / 337);

        tick.bw = value + ' Mb';
        tick.color = 'rgb(' + [color, color, color].join(',') + ')';
      }

      busyness.unshift(tick);
    }

    return busyness;
  }

  ngOnDestroy(): void {
    if (this.deviceSubscription) {
      this.deviceSubscription.unsubscribe();
    }

    if (this.testInterval) {
      clearInterval(this.testInterval);
    }
  }
}
