import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { selectBaseUrl } from 'src/app/store/customer/customer.selectors';
import { IDhcp, IDhcpReservation, IDns, INetworkConfiguration } from '../interfaces/interface';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class NetworkConfigurationService {
  constructor(private api: ApiService, private store: Store) {}

  dhcp$(): Observable<IDhcp> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.get(`${baseUrl}/networkConfiguration/dhcp`))
    );
  }

  setDhcp$(data: IDhcp): Observable<IDhcp> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.put(`${baseUrl}/networkConfiguration/dhcp`, data))
    );
  }

  dhcpReservations$(): Observable<IDhcpReservation[]> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.get(`${baseUrl}/networkConfiguration/dhcpReservations`))
    );
  }

  addOrUpdateDhcpReservation$(data: { mac: string; ip: string }): Observable<IDhcpReservation> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.put(`${baseUrl}/networkConfiguration/dhcpReservations/${data.mac}`, data))
    );
  }

  deleteDhcpReservation$(mac: string): Observable<undefined> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.delete(`${baseUrl}/networkConfiguration/dhcpReservations/${mac}`))
    );
  }

  addDhcpPortForwards$(
    mac: string,
    data: IDhcpReservation['portForwards'][0]
  ): Observable<IDhcpReservation['portForwards'][0]> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.post(`${baseUrl}/networkConfiguration/dhcpReservations/${mac}/portForward`, data))
    );
  }

  deleteDhcpPortForwards$(mac: string, externalPort: string): Observable<undefined> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) =>
        this.api.delete(`${baseUrl}/networkConfiguration/dhcpReservations/${mac}/portForward/${externalPort}`)
      )
    );
  }

  updateDhcpPortForwards$(
    mac: string,
    data: IDhcpReservation['portForwards'][0]
  ): Observable<IDhcpReservation['portForwards'][0]> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) =>
        this.api.put(`${baseUrl}/networkConfiguration/dhcpReservations/${mac}/portForward/${data.externalPort}`, data)
      )
    );
  }

  setEthernetLanMode$(mode: 'auto' | 'enable' | 'disable'): Observable<{ mode: 'auto' | 'enable' | 'disable' }> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.patch(`${baseUrl}/networkConfiguration/ethernetLan`, { mode }))
    );
  }

  setUpnpMode$(mode: 'auto' | 'enable' | 'disable'): Observable<{ mode: 'auto' | 'enable' | 'disable'; eba }> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.put(`${baseUrl}/networkConfiguration/upnp`, { mode }))
    );
  }

  setPersistConfigurationOnGatewayMode$(
    mode: 'auto' | 'enable' | 'disable'
  ): Observable<{ mode: 'auto' | 'enable' | 'disable' }> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.put(`${baseUrl}/networkConfiguration/persistConfigurationOnGateway`, { mode }))
    );
  }

  networkConfiguration$(): Observable<INetworkConfiguration> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.get(`${baseUrl}/networkConfiguration/home`))
    );
  }

  upnp$(): Observable<{ enabled: true; mode: 'auto'; modeRealized: true }> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.get(`${baseUrl}/networkConfiguration/upnp`))
    );
  }

  dnsServers$(): Observable<IDns> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.get(`${baseUrl}/networkConfiguration/dnsServers`))
    );
  }

  updateDnsServers$(data: Partial<IDns>): Observable<IDns> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.put(`${baseUrl}/networkConfiguration/dnsServers`, data))
    );
  }

  updateMulticast$(data: Partial<INetworkConfiguration['multicast']>): Observable<INetworkConfiguration['multicast']> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.patch(`${baseUrl}/networkConfiguration/multicast`, data))
    );
  }
}
