import { createReducer, on } from '@ngrx/store';
import {
  ILteSupported,
  ILteSignalStrength,
  ILteCurrentState,
  ILteHwInfo,
  ILteNetworkConfiguration,
  ILteConfiguration
} from 'src/app/lib/interfaces/lte.interface';
import { setErrorState, setInitialState, setLoadedState, setLoadingState } from '../state-interfaces';
import {
  lteConfigurationLoaded,
  lteConfigurationLoadedError,
  lteConfigurationOpened,
  lteCurrentStateLoaded,
  lteCurrentStateLoadedError,
  lteHWInfoLoaded,
  lteHWInfoLoadedError,
  lteNetworkConfigurationLoaded,
  lteNetworkConfigurationLoadedError,
  lteSignalStrengthLoaded,
  lteSignalStrengthLoadedError,
  lteSupportedLoaded,
  lteSupportedLoadedError
} from './lte.actions';

export const initialState = {
  supported: setInitialState<ILteSupported, string>(null),
  signalStrength: setInitialState<ILteSignalStrength, string>(null),
  currentState: setInitialState<ILteCurrentState, string>(null),
  hwInfo: setInitialState<ILteHwInfo, string>(null),
  networkConfiguration: setInitialState<ILteNetworkConfiguration, string>(null),
  configuration: setInitialState<ILteConfiguration, string>(null)
};

export const lteReducer = createReducer(
  initialState,
  on(lteConfigurationOpened, (state) => ({
    ...state,
    supported: setLoadingState(state.supported),
    signalStrength: setLoadingState(state.signalStrength),
    currentState: setLoadingState(state.currentState)
  })),
  on(lteSupportedLoaded, (state, { supported }) => ({
    ...state,
    supported: setLoadedState(supported)
  })),
  on(lteSupportedLoadedError, (state, { error }) => ({
    ...state,
    supported: setErrorState(state.supported, error)
  })),
  on(lteSignalStrengthLoaded, (state, { signalStrength }) => ({
    ...state,
    signalStrength: setLoadedState(signalStrength)
  })),
  on(lteSignalStrengthLoadedError, (state, { error }) => ({
    ...state,
    signalStrength: setErrorState(state.signalStrength, error)
  })),
  on(lteCurrentStateLoaded, (state, { currentState }) => ({
    ...state,
    currentState: setLoadedState(currentState)
  })),
  on(lteCurrentStateLoadedError, (state, { error }) => ({
    ...state,
    currentState: setErrorState(state.currentState, error)
  })),
  on(lteHWInfoLoaded, (state, { hwInfo }) => ({
    ...state,
    hwInfo: setLoadedState(hwInfo)
  })),
  on(lteHWInfoLoadedError, (state, { error }) => ({
    ...state,
    hwInfo: setErrorState(state.hwInfo, error)
  })),
  on(lteNetworkConfigurationLoaded, (state, { networkConfiguration }) => ({
    ...state,
    networkConfiguration: setLoadedState(networkConfiguration)
  })),
  on(lteNetworkConfigurationLoadedError, (state, { error }) => ({
    ...state,
    networkConfiguration: setErrorState(state.networkConfiguration, error)
  })),
  on(lteConfigurationLoaded, (state, { configuration }) => ({
    ...state,
    configuration: setLoadedState(configuration)
  })),
  on(lteConfigurationLoadedError, (state, { error }) => ({
    ...state,
    configuration: setErrorState(state.configuration, error)
  }))
);
