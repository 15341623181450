<ng-container *ngFor="let data of dataSet?.data">
  <svg:g
    class="container"
    *ngFor="let intervalItem of data.yInterval; index as index; first as first; last as last;"
    (mouseenter)="hover(data, index)"
    (mouseleave)="hover(undefined)"
  >
  <path [attr.d]="path(data, first, last, intervalItem)" [attr.fill]="intervalItem.color"/>
  </svg:g>
</ng-container>
