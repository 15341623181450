<svg:g class="pod" [ngClass]="status()" transform="translate(-16, -16)" *ngIf="nodeVisual.options.type === 'pod'">
  <svg:circle cx="16" cy="16" r="30" [attr.class]="ui"></svg:circle>
  <svg:image [attr.xlink:href]="icon()" height="42" width="42" transform="translate(-5, -6)"></svg:image>

  <ng-container *ngIf="nodeVisual?.options?.isGateway && vpn">
    <svg:g class="vpn" transform="translate(-24, -28)">
      <svg:rect x="0" y="-2" width="80" height="20" rx="10" ry="10"></svg:rect>
      <svg:g
        [ngClass]="vpn.internet?.status"
        [inline]="true"
        icon="/assets/icons/icon-vpn-small-globe.svg"
        [width]="16"
        [height]="16"
        transform="translate(8, 0)"
      ></svg:g>
      <svg:g
        [ngClass]="vpn.ipsec?.status"
        [inline]="true"
        icon="/assets/icons/icon-vpn-small-servers.svg"
        [width]="16"
        [height]="16"
        transform="translate(32, 0)"
      ></svg:g>
      <svg:g
        [ngClass]="vpn.radius?.status"
        [inline]="true"
        icon="/assets/icons/icon-vpn-small-authentication.svg"
        [width]="16"
        [height]="16"
        transform="translate(56, 0)"
      ></svg:g>
    </svg:g>
  </ng-container>
  <ng-container *ngIf="!vpn">
    <svg:g
      class="appendix"
      [ngClass]="appendix.class"
      [attr.transform]="position(appendix.vertical, appendix.horizontal)"
      *ngFor="let appendix of nodeVisual.appendixes"
    >
      <svg:circle cx="8" cy="8" r="12"></svg:circle>
      <svg:image [attr.xlink:href]="'/assets/icons/' + appendix.icon + '.svg'" height="16" width="16"></svg:image>
    </svg:g>
  </ng-container>
  <svg:g class="label" transform="translate(16, 38)">
    <svg:rect
      [attr.x]="-(labelWidth / 2 + 10)"
      y="0"
      [attr.width]="labelWidth + 20"
      height="21"
      rx="10"
      ry="10"
    ></svg:rect>
    <svg:text x="0" y="15" *ngIf="showSerial">{{ nodeVisual.options.id }}</svg:text>
    <svg:text x="0" y="15" *ngIf="!showSerial">{{ nodeVisual.options.label }}</svg:text>
  </svg:g>

  <svg:g
    class="radios"
    transform="translate(16, 62)"
    *ngIf="showRadios && nodeVisual.options.radios && nodeVisual.options.radios.length === 1"
  >
    <svg:circle
      class="hasClientNetwork"
      r="3"
      transform="translate(0, 17)"
      *ngIf="nodeVisual.options.radios[0].hasClientNetwork"
    ></svg:circle>

    <svg:path
      [ngClass]="'label' + nodeVisual.options.radios[0].mode"
      [ngStyle]="getChannelColor(nodeVisual.options.radios[0].channel)"
      d="M 10 0 L -10 0 A 1 1 0 0 0 -10 17 L10 17 A 1 1 0 0 0 10 0"
      transform="translate(0, 0)"
    ></svg:path>
    <svg:text x="0" y="12">{{ nodeVisual.options.radios[0].channel }}</svg:text>
  </svg:g>

  <svg:g
    class="radios"
    transform="translate(16, 62)"
    *ngIf="showRadios && nodeVisual.options.radios && nodeVisual.options.radios.length === 2"
  >
    <svg:circle
      class="hasClientNetwork"
      r="3"
      transform="translate(-13, 17)"
      *ngIf="nodeVisual.options.radios[0].hasClientNetwork"
    ></svg:circle>
    <svg:circle
      class="hasClientNetwork"
      r="3"
      transform="translate(13, 17)"
      *ngIf="nodeVisual.options.radios[1].hasClientNetwork"
    ></svg:circle>

    <svg:path
      [ngClass]="'label' + nodeVisual.options.radios[0].mode"
      [ngStyle]="getChannelColor(nodeVisual.options.radios[0].channel)"
      d="M 13 0 L -13 0 A 1 1 0 0 0 -13 17 L7 17 L13 0"
      transform="translate(-10, 0)"
    ></svg:path>
    <svg:text x="-13" y="12">{{ nodeVisual.options.radios[0].channel }}</svg:text>

    <svg:path
      [ngClass]="'label' + nodeVisual.options.radios[1].mode"
      [ngStyle]="getChannelColor(nodeVisual.options.radios[1].channel)"
      d="M -7 0 L 13 0 A 1 1 0 0 1 13 17 L-13 17 L-7 0"
      transform="translate(10, 0)"
    ></svg:path>
    <svg:text x="13" y="12">{{ nodeVisual.options.radios[1].channel }}</svg:text>
  </svg:g>

  <svg:g
    class="radios"
    transform="translate(16, 62)"
    *ngIf="showRadios && nodeVisual.options.radios && nodeVisual.options.radios.length === 3"
  >
    <svg:circle
      class="hasClientNetwork"
      r="3"
      transform="translate(-27, 17)"
      *ngIf="nodeVisual.options.radios[0].hasClientNetwork"
    ></svg:circle>
    <svg:circle
      class="hasClientNetwork"
      r="3"
      transform="translate(0, 17)"
      *ngIf="nodeVisual.options.radios[1].hasClientNetwork"
    ></svg:circle>
    <svg:circle
      class="hasClientNetwork"
      r="3"
      transform="translate(27, 17)"
      *ngIf="nodeVisual.options.radios[2].hasClientNetwork"
    ></svg:circle>

    <svg:path
      [ngClass]="'label' + nodeVisual.options.radios[0].mode"
      [ngStyle]="getChannelColor(nodeVisual.options.radios[0].channel)"
      d="M 13 0 L -13 0 A 1 1 0 0 0 -13 17 L7 17 L13 0"
      transform="translate(-23, 0)"
    ></svg:path>
    <svg:text x="-27" y="12">{{ nodeVisual.options.radios[0].channel }}</svg:text>

    <svg:path
      [ngClass]="'label' + nodeVisual.options.radios[1].mode"
      [ngStyle]="getChannelColor(nodeVisual.options.radios[1].channel)"
      d="M -10 0 L 16 0 L10 17 L-16 17 L-10 0"
      transform="translate(0, 0)"
    ></svg:path>
    <svg:text x="0" y="12">{{ nodeVisual.options.radios[1].channel }}</svg:text>

    <svg:path
      [ngClass]="'label' + nodeVisual.options.radios[2].mode"
      [ngStyle]="getChannelColor(nodeVisual.options.radios[2].channel)"
      d="M -7 0 L 13 0 A 1 1 0 0 1 13 17 L-13 17 L-7 0"
      transform="translate(23, 0)"
    ></svg:path>
    <svg:text x="27" y="12">{{ nodeVisual.options.radios[2].channel }}</svg:text>
  </svg:g>
</svg:g>
<svg:g class="device" [ngClass]="status()" transform="translate(-45, -45)" *ngIf="nodeVisual.options.type === 'device'">
  <svg:circle cx="45" cy="45" r="30"></svg:circle>
  <svg:g
    class="topoicon"
    [class.dimm]="nodeVisual?.options?.metadata?.dimm"
    [icon]="icon()"
    transform="scale(1.5) translate(10,10)"
  ></svg:g>
  <svg:g
    class="appendix"
    [ngClass]="appendix.class"
    [attr.transform]="position(appendix.vertical, appendix.horizontal)"
    *ngFor="let appendix of nodeVisual.appendixes"
  >
    <svg:circle cx="8" cy="8" r="12"></svg:circle>
    <svg:image [attr.xlink:href]="'/assets/icons/' + appendix.icon + '.svg'" height="16" width="16"></svg:image>
  </svg:g>
  <svg:g class="label" [class.dimm]="nodeVisual?.options?.metadata?.dimm" transform="translate(45, 90)">
    <svg:text x="0" y="0">{{ nodeVisual.options.label | macAddr : true }}</svg:text>
  </svg:g>
</svg:g>
<svg:g class="overlay" [class.warning]="nodeVisual.overlays.overlayWarning" *ngIf="nodeVisual?.overlays?.overlay">
  <svg:rect
    [attr.x]="-(nodeVisual.overlays.overlayWidth / 2)"
    y="-7"
    [attr.width]="nodeVisual.overlays.overlayWidth"
    height="17"
    rx="10"
    ry="10"
  ></svg:rect>
  <svg:text x="0" y="6">{{ nodeVisual.overlays.overlay | translate }}</svg:text>
  <svg:g class="badge" *ngIf="nodeVisual.overlays.overlayBadge">
    <svg:rect x="-15" y="-17" width="30" height="12"></svg:rect>
    <svg:text x="0" y="-7">{{ nodeVisual.overlays.overlayBadge | translate }}</svg:text>
  </svg:g>
</svg:g>

<ng-container *ngIf="nodeVisual?.overlays?.mloRssiOverlays?.length">
  <svg:g class="overlay" [class.warning]="nodeVisual.overlays.overlayWarning">
    <svg:rect
      [attr.x]="-(nodeVisual.overlays.overlayWidth / 2)"
      [attr.y]="calculateMloRssiOverlayYPosition(nodeVisual.overlays.mloRssiOverlays.length)"
      [attr.width]="nodeVisual.overlays.overlayWidth"
      [attr.height]="17 * nodeVisual.overlays.mloRssiOverlays.length"
      rx="10"
      ry="10"
    ></svg:rect>
    <svg:text
      *ngFor="let overlay of nodeVisual.overlays.mloRssiOverlays; index as i"
      x="0"
      [attr.y]="calculateMloRssiOverlayTextYPosition(i, nodeVisual.overlays.mloRssiOverlays.length)"
    >
      {{ overlay }}
    </svg:text>
  </svg:g>
</ng-container>
