import { Component, ElementRef, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IconService } from 'src/app/lib/services/icon.service';

@UntilDestroy()
@Component({
  selector: 'icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class IconComponent implements OnChanges {
  @Input() name?: string;
  @Input() path?: string;
  @Input() iconV2?: string;

  constructor(private el: ElementRef, private icons: IconService) {}

  ngOnChanges(): void {
    let realPath = '';

    if (this.path) {
      realPath = this.path;
    } else if (this.iconV2) {
      realPath = this.icons.getPath(this.iconV2);
    } else {
      realPath = `/assets/icons/${this.name}.svg`;
    }

    this.icons
      .get(realPath)
      .pipe(untilDestroyed(this))
      .subscribe((response: string) => {
        if (response) {
          this.el.nativeElement.innerHTML = response;
        }
      });
  }
}
