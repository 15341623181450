import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import * as erdm from 'element-resize-detector';
import { LoggingService } from 'src/app/lib/services/logging.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { ToolbarService } from 'src/app/lib/services/toolbar.service';

@Component({
  selector: 'toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input()
  menuitems: any = [];

  @Output()
  toggle: any = new EventEmitter<any>();

  @ViewChildren('panes')
  panes: QueryList<any>;

  @ViewChild('toolbar')
  toolbar: any;

  toolbarObj: any = {};
  erd: any = erdm();
  windowPad = 5;

  constructor(
    private logging: LoggingService,
    private toolbarEvents: ToolbarService,
    private mixpanel: MixpanelService
  ) {}

  ngOnInit(): void {
    this.menuitems.forEach((item: any) => {
      item.id = 'toolbar-' + item.action;
    });
  }

  ngAfterViewInit(): void {
    this.toolbarObj = this.panes.toArray();

    this.resize();

    this.erd.listenTo(this.toolbar.nativeElement, () => {
      this.resize();
    });
  }

  closeAll(): void {
    this.menuitems.forEach((item: any) => {
      item.selected = false;
      this.toggle.emit(item);
    });

    this.toolbarEvents.dock();
    this.mixpanel.storeEvent('TOOLBAR_DOCKALL', {});
  }

  anySelected(): boolean {
    const found = this.menuitems.find((item: any) => item.selected === true);

    if (found) {
      return true;
    } else {
      return false;
    }
  }

  resize(): void {
    this.menuitems.forEach((item: any) => {
      this.setElementPosition(item);
    });
  }

  itemSelect(item: any): void {
    item.selected = !item.selected;
    this.toggle.emit(item);
    this.mixpanel.storeEvent('TOOLBAR_SELECT', { ITEM: item.action });
  }

  setElementPosition(item: any): void {
    const found = this.toolbarObj.find((obj: ElementRef) => obj.nativeElement.id === item.id);

    if (found) {
      item.offsetHeight = found.nativeElement.offsetHeight + 30;
      item.offsetLeft = found.nativeElement.offsetLeft;
      item.width = found.nativeElement.offsetWidth - this.windowPad + 3;
    }
  }

  getElementPosition(action: string): any {
    const found = this.menuitems.find((obj: any) => obj.action === action);

    if (found) {
      if (this.toolbarObj.length) {
        const element = this.toolbarObj.find((obj: ElementRef) => obj.nativeElement.id === found.id);
        const { top, left, height } = element.nativeElement.getBoundingClientRect();

        return {
          action: found.action,
          selected: found.selected,
          xpos: left,
          ypos: top + height + 15,
          width: found.width
        };
      } else {
        return {
          action: found.action,
          selected: found.selected,
          xpos: 0,
          ypos: 0,
          width: found.width
        };
      }
    } else {
      this.logging.error('getElementPosition can not find ', action);
      return {};
    }
  }

  ngOnDestroy(): void {
    if (this.toolbar) {
      this.erd.uninstall(this.toolbar.nativeElement);
    }
  }
}
