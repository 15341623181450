import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable()
export class LoggingService {
  toShow: any = {
    log: true,
    warn: true,
    info: false,
    error: true,
    debug: false
  };

  constructor() {}

  log(str: string, ...data: any[]): void {
    if (this.toShow.log && console && console.log) {
      console.log('LOG >> (', moment(new Date()).format('YYYY-MM-DD hh:mm:ss'), ')', str, '   ', data);
    }
  }

  warn(str: string, ...data: any[]): void {
    if (this.toShow.warn && console && console.warn) {
      console.warn('WARN >> (', moment(new Date()).format('YYYY-MM-DD hh:mm:ss'), ')', str, '   ', data);
    }
  }

  info(str: string, ...data: any[]): void {
    if (this.toShow.info && console && console.info) {
      console.info('INFO >> (', moment(new Date()).format('YYYY-MM-DD hh:mm:ss'), ')', str, '   ', data);
    }
  }

  error(str: string, ...data: any[]): void {
    if (this.toShow.error && console && console.error) {
      console.error('ERROR >> (', moment(new Date()).format('YYYY-MM-DD hh:mm:ss'), ')', str, '   ', data);
    }
  }

  debug(str: string, ...data: any[]): void {
    if (this.toShow.debug && console && console.debug) {
      console.debug('DEBUG >> (', moment(new Date()).format('YYYY-MM-DD hh:mm:ss'), ')', str, '   ', data);
    }
  }
}
