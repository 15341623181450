<spacer>
  <h1>{{ 'traffic.title' | translate }}</h1>
  <icon name="icon-info" class="size-1_5" tooltip="{{ 'traffic.tooltip' | translate }}"></icon>
</spacer>

<ng-container *ngrxLet="device$ as device">
  <breadcrumb>
    <div breadcrumbItem class="pointer" (click)="openPieMonitoring()">{{ 'traffic.monitoring' | translate }}</div>
    <div breadcrumbItem>{{ device?.nickname || device?.name || device?.mac | macAddr : true }}</div>
  </breadcrumb>

  <drop-down-device
    [devices]="devices$ | async"
    [selectedMac]="device?.mac"
    (selectedMacChange)="changeDevice($event)"
  ></drop-down-device>
</ng-container>
<div class="sliderContainer">
  <slidetoggler [options]="timeItems$ | async" (toggle)="changeTime($event)"></slidetoggler>
</div>

<ng-container *ngIf="dataUsageDS$ | async as dataUsageDS">
  <h2>{{ 'traffic.dataUsage' | translate }}</h2>
  <flm-graph
    [dataSet]="dataUsageDS"
    [borders]="{ top: 18, right: 20, bottom: 40, left: 70 }"
    (hover)="dataUsageHover($event)"
  ></flm-graph>
  <div class="legend">
    <div *ngFor="let legend of legends" class="item" [style.color]="legend.color">{{ legend.label | translate }}</div>
  </div>
  <div class="hoverTable">
    <div class="border" [style.background-color]="dataUsageHoveredData?.color"></div>
    <div class="item main">
      <div class="tableRow title">{{ (dataUsageHoveredData?.title | translate) || '--' }}</div>
      <div class="tableRow">{{ dataUsageHoveredData?.time || '--' }}</div>
      <div class="tableRow">{{ 'traffic.dataUsage' | translate }}: {{ dataUsageHoveredData?.usage || '--' }}</div>
    </div>
    <div class="item">
      <div class="tableRow">{{ 'traffic.latency' | translate }}</div>
      <div class="tableRow">
        <div>{{ 'traffic.min' | translate }}</div>
        <div>{{ dataUsageHoveredData?.latencyMin || '--' | translate }}</div>
      </div>
      <div class="tableRow">
        <div>{{ 'traffic.avg' | translate }}</div>
        <div>{{ dataUsageHoveredData?.latencyAvg || '--' | translate }}</div>
      </div>
      <div class="tableRow">
        <div>{{ 'traffic.max' | translate }}</div>
        <div>{{ dataUsageHoveredData?.latencyMax || '--' | translate }}</div>
      </div>
    </div>
    <div class="item">
      <div class="tableRow">{{ 'traffic.throughput' | translate }}</div>
      <div class="tableRow">
        <div>{{ 'traffic.min' | translate }}</div>
        <div>{{ dataUsageHoveredData?.throughputMin || '--' }}</div>
      </div>
      <div class="tableRow">
        <div>{{ 'traffic.max' | translate }}</div>
        <div>{{ dataUsageHoveredData?.throughputMax || '--' }}</div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="(dataUsageDS$ | async) === undefined">
  <h2>{{ 'traffic.noData' | translate }}</h2>
</ng-container>

<ng-container *ngFor="let qoeDs of qoeDS$ | async">
  <h2>{{ qoeDs.name | translate }}</h2>
  <flm-graph [dataSet]="qoeDs.dataSet" [borders]="{ top: 18, right: 20, bottom: 40, left: 70 }"></flm-graph>
  <div class="legend">
    <div class="item good">{{ 'traffic.good' | translate }}</div>
    <div class="item moderate">{{ 'traffic.moderate' | translate }}</div>
    <div class="item bad">{{ 'traffic.bad' | translate }}</div>
  </div>
</ng-container>
