<div class="wrapper">
  <div class="series" *ngFor="let serie of series">
    <ng-container *ngIf="serie">
      <div class="circle" [style.backgroundColor]="serie.color"></div>
      <span *ngIf="!serie.translation">{{ serie.text }}</span>
      <span *ngIf="serie.translation">{{ serie.translation | translate }}</span>
    </ng-container>
  </div>
  <div class="threshold" *ngIf="threshold">
    <div class="line"></div>
    <span>{{ threshold | translate }}</span>
  </div>
  <div class="alarm" *ngIf="alarm">
    <div class="circle stroke"></div>
    <span>{{ alarm | translate }}</span>
  </div>
</div>
