<drop-down-select
  [values]="selectableDevices"
  [ownModel]="selectedMac"
  [multiSelect]="false"
  (ownModelChange)="selectedMacChange.emit($event)"
  [rowTemplate]="valueTemplate"
  [headerTemplate]="header"
>
</drop-down-select>
<ng-template #header let-opened>
  <button class="header" role="listbox" [class.disabled]="disabled">
    <div class="content">
      <div class="nameContainer" *ngIf="selectedMac">
        <icon class="size-2" [iconV2]="cachedDevices[selectedMac]?.iconV2 || 'unknown'"></icon>
        <div>
          <div>
            {{
              cachedDevices[selectedMac]?.nickname || cachedDevices[selectedMac]?.name || selectedMac | macAddr: true
            }}
          </div>
          <div class="mac">{{ 'mac' | translate }}: {{ selectedMac | macAddr }}</div>
        </div>
      </div>
      <div *ngIf="!selectedMac">Please select device</div>
    </div>
    <icon name="icon-arrow-down" [class.rotate180]="opened"></icon>
  </button>
</ng-template>

<ng-template #valueTemplate let-value>
  <div class="nameContainer">
    <icon class="size-2" [iconV2]="value.object?.iconV2 || 'unknown'"></icon>
    <div class="overflowWithEllipsis">
      <div class="overflowWithEllipsis">{{ value.text | macAddr: true }}</div>
      <div class="mac">{{ 'mac' | translate }}: {{ value.value | macAddr }}</div>
    </div>
  </div>
</ng-template>
