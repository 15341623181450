<div class="content">
  <div *ngIf="qoe5gReports.data && qoe5gReports.data.length">
    <div class="headers noLive">
      <div
        *ngFor="let header of qoe5gReports.min15.headers"
        class="header {{ header.key }}"
        [class.selected]="header.key === qoe5gReports.min15.sort"
      >
        <div class="title" *ngIf="header.key === 'space'">&nbsp;</div>
        <div class="value" *ngIf="header.key !== 'space'" (click)="qoeChangeSort(qoe5gReports, 'min15', header.key)">
          {{ header.value | translate }}
        </div>
        <icon
          name="icon-triangle-down"
          class="size-0_7"
          [class.rotate180]="header.desc"
          *ngIf="header.key === qoe5gReports.min15.sort"
        ></icon>
      </div>
    </div>
    <ng-container
      *ngIf="
        qoe5gReports.data
          | filterArrayBy: { filtertext: searchValue, properties: ['name', 'qoe.id'] } as filteredQoe5gReports
      "
    >
      <qoestrip
        *ngFor="let report of filteredQoe5gReports; trackBy: track"
        [attr.id]="report.id"
        [data]="report"
        [mode]="mode"
      ></qoestrip>
      <div *ngIf="!filteredQoe5gReports.length">
        {{ 'devices.messageAllFiltered' | translate }}
      </div>
    </ng-container>
  </div>
  <div *ngIf="!qoe5gReports.loading && qoe5gReports.data && !qoe5gReports.data.length">
    {{ 'qoe.no5gReports' | translate }}
  </div>
  <div *ngIf="qoe5gReports.loading">
    {{ 'qoe.loading' | translate }}
  </div>
</div>
