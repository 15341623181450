import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, map, switchMap, take } from 'rxjs/operators';
import {
  IConfigAndState,
  ICustomer,
  IDevice,
  ILocationLog,
  IMobilizeLog,
  LogDestination,
  logModes
} from 'src/app/lib/interfaces/interface';
import { EmailPipe } from 'src/app/lib/pipes/email.pipe';
import { CustomerService } from 'src/app/lib/services/customer.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { ModalService } from 'src/app/lib/services/modal.service';
import { NodeService } from 'src/app/lib/services/nodes.service';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { ToastService } from 'src/app/lib/services/toast.service';
import { TroubleshootingService } from 'src/app/lib/services/troubleshooting.service';
import { utilitiesInit } from 'src/app/store/configview/config.actions';
import {
  customerSetFromUtilities,
  getPrimarySecondaryNetworks,
  updateLogpullHistory,
  updateMobilizeProblemsHistory
} from 'src/app/store/customer/customer.actions';
import {
  selectConfigAndState,
  selectCustomer,
  selectIsHomePass,
  selectLogpullHistory,
  selectMobilizeProblemsHistory,
  selectPipeLocationOnChange
} from 'src/app/store/customer/customer.selectors';
import { selectDevices } from 'src/app/store/polling/polling.selector';
import { environments } from 'src/environments/shared/environments';

@UntilDestroy()
@Component({
  selector: 'utilities',
  templateUrl: './utilities.component.html',
  styleUrls: ['./utilities.component.scss']
})
export class UtilitiesComponent implements OnInit, OnChanges, OnDestroy {
  expand: boolean = false;
  details: any = null;
  logpullHistory: ILocationLog[] = null;
  mobilizeProblemsHistory: IMobilizeLog[] = null;
  permissions: any;
  permissionsSubscription: any;
  logSubscription: any;
  messageFormControl: FormControl = new FormControl();
  rebootPodsDelay: number = 10;
  migrate: { migrated: boolean; targetCloud: string; cloud: unknown } = null;

  showTaskStatusesModal: boolean = false;
  showAuditLogsModal: boolean = false;
  showNeighborsReportsModal: boolean = false;
  showCustomerGroupsModal: boolean = false;
  showAccountMigrateOption: boolean = false;

  shardInfo: {
    httpUrlBase?: string;
    shard?: string;
  } = {};
  preservePackIDs: boolean = false;
  retainWiFiNetwork: boolean = false;
  isAdmin: boolean = false;
  isSupportOrGroupSupport: boolean = false;
  isUprise$ = this.store.pipe(selectPipeLocationOnChange).pipe(map((location) => location.uprise));
  isHomePass$ = this.store.select(selectIsHomePass);
  customer$ = this.store.select(selectCustomer);
  configAndState: IConfigAndState;

  logDestinationItems = [];
  flashLoggingRealized: string = '';
  flashLoggingPending: boolean = false;
  flashLoggingTimeout: any;

  devices: IDevice[] = [];
  deviceSubscription: Subscription;

  ui: string = '';

  @Input()
  open: number = 0;

  @Output()
  toggle = new EventEmitter();

  @Output()
  filter = new EventEmitter();

  @Output()
  clearFilter = new EventEmitter<{ section: string }>();

  constructor(
    public plume: PlumeService,
    private customerService: CustomerService,
    private mixpanel: MixpanelService,
    private troubleshooting: TroubleshootingService,
    private toast: ToastService,
    private translate: TranslateService,
    private nodeService: NodeService,
    private modal: ModalService,
    private store: Store,
    private emailPipe: EmailPipe
  ) {}

  ngOnInit(): void {
    this.isAdmin = this.plume.isStrictAdminRole();
    this.isSupportOrGroupSupport =
      this.plume.cloudVersionAbove1_119() &&
      (this.plume.isStrictGroupSupportRole() || this.plume.isStrictSupportRole());

    this.init();
    this.updateData();
    this.registerFilter();

    this.ui = this.plume.getUI();
  }

  ngOnChanges(changes: any): void {
    this.expand = changes.open.currentValue;

    if (this.expand) {
      this.getLogPullHistory();

      if (this.isAdmin) {
        this.getMobilizeProblemsHistory();
      }
    }
  }

  init(): void {
    this.store.dispatch(utilitiesInit());
    this.store
      .select(selectConfigAndState)
      .pipe(untilDestroyed(this))
      .subscribe((configAndState) => {
        this.configAndState = configAndState;

        this.initFlashLogging();
      });
    this.customer$
      .pipe(
        distinctUntilChanged((oldCustomer, newCustomer) => oldCustomer?.partnerId === newCustomer?.partnerId),
        untilDestroyed(this)
      )
      .subscribe((customer) => {
        this.showAccountMigrateOption = this.checkAccountMigrateOption(customer);
      });

    this.deviceSubscription = this.store.select(selectDevices).subscribe((devices) => {
      if (devices) {
        this.devices = devices;
      }
    });

    this.store
      .select(selectLogpullHistory)
      .pipe(untilDestroyed(this))
      .subscribe((response) => {
        this.logpullHistory = response.map((historyEntry) => ({
          ...historyEntry,
          createdAt: moment.utc(historyEntry.createdAt).local().format('L LT')
        }));
      });

    if (this.isAdmin) {
      this.store
        .select(selectMobilizeProblemsHistory)
        .pipe(untilDestroyed(this))
        .subscribe((response) => {
          this.mobilizeProblemsHistory = response
            .map((historyEntry) => ({
              ...historyEntry,
              LastModified: moment.utc(historyEntry.LastModified).local().format('L LT')
            }))
            .sort((a, b) => moment(b.LastModified).valueOf() - moment(a.LastModified).valueOf());
        });
    }
  }

  initFlashLogging(): void {
    this.flashLoggingRealized = this.configAndState.config?.nodeLogging?.forceDestination;

    this.logDestinationItems = [
      {
        value: LogDestination.NODE_LOGGING_DESTINATION_DEFAULT,
        translation: 'configurations.utilities.nodelogging.default',
        selected: this.flashLoggingRealized === LogDestination.NODE_LOGGING_DESTINATION_DEFAULT
      },
      {
        value: LogDestination.NODE_LOGGING_DESTINATION_FLASH,
        translation: 'configurations.utilities.nodelogging.flash',
        selected: this.flashLoggingRealized === LogDestination.NODE_LOGGING_DESTINATION_FLASH
      },
      {
        value: LogDestination.NODE_LOGGING_DESTINATION_FLASH_RAMOOPS,
        translation: 'configurations.utilities.nodelogging.flash_ramoops',
        selected: this.flashLoggingRealized === LogDestination.NODE_LOGGING_DESTINATION_FLASH_RAMOOPS
      },
      {
        value: LogDestination.NODE_LOGGING_DESTINATION_RAMOOPS,
        selected: this.flashLoggingRealized === LogDestination.NODE_LOGGING_DESTINATION_RAMOOPS,
        translation: 'configurations.utilities.nodelogging.ramoops'
      },
      {
        value: LogDestination.NODE_LOGGING_DESTINATION_OFF,
        selected: this.flashLoggingRealized === LogDestination.NODE_LOGGING_DESTINATION_OFF,
        translation: 'configurations.utilities.nodelogging.off'
      }
    ];
  }

  action(property: string, value: string): void {
    if (property === 'logDestination') {
      this.flashLoggingRealized = value;
      this.flashLoggingPending = true;

      this.flashLoggingTimeout = setTimeout(() => {
        this.mixpanel.storeEvent('NODE_LOGGING_TIMEOUT', { mode: value });
        this.toast.error(
          'configurations.utilities.nodelogging.timeoutTitle',
          'configurations.utilities.nodelogging.timeoutContent'
        );
        this.flashLoggingPending = false;
      }, 10000);

      this.customerService
        .setFlashLogging(value as logModes)
        .pipe(untilDestroyed(this))
        .subscribe(
          () => {
            clearTimeout(this.flashLoggingTimeout);
            this.mixpanel.storeEvent('NODE_LOGGING_SET', { mode: value });
            this.toast.success(
              'configurations.utilities.nodelogging.successTitle',
              'configurations.utilities.nodelogging.successContent'
            );
            this.flashLoggingPending = false;
          },
          (error: any) => {
            clearTimeout(this.flashLoggingTimeout);
            this.mixpanel.storeEvent('NODE_LOGGING_ERROR', { mode: value });
            this.toast.error(error.error.error.message, error.error.error.name);
            this.flashLoggingPending = false;
          }
        );
    }
  }

  updateData(): void {
    this.getShardData();

    this.permissionsSubscription = this.plume.permissions.subscribe((data: any) => {
      this.permissions = data;
    });
  }

  checkAccountMigrateOption(customer: ICustomer): boolean {
    const env = this.plume.getEnv();
    const enabledClouds = ['dogfood', 'gamma'];
    const destinationCloud = { dogfood: 'opensync', gamma: 'iota' };
    const enabledPartnerIds = ['5f90e3abb858b93d61eddf67', '40QfyUjTVFUcwkpcx4pverdnik'];

    if (env?.id && enabledClouds.indexOf(env.id) >= 0) {
      if (this.plume.isSomeAdminRole()) {
        if (customer && enabledPartnerIds.indexOf(customer.partnerId) >= 0) {
          this.customerService.cloud$().subscribe((response) => {
            this.migrate = {
              cloud: environments.find((env) => env.url === response.cloudUrl),
              migrated: response.cloudUrl !== env.hostname,
              targetCloud: response.cloudUrl !== env.hostname ? env.id : destinationCloud[env.id]
            };
          });
          return true;
        }
      }
    }
    return false;
  }

  migrateAccount(): void {
    if (this.migrate.migrated) {
      this.customerService
        .rollback$()
        .pipe(
          switchMap(() => this.customer$),
          take(1)
        )
        .subscribe(
          (customer) => {
            this.mixpanel.storeEvent('ROLLBACK_SUCCESS');
            this.toast.success('configurations.utilities.rollbackSuccess', 'header.success');
            this.showAccountMigrateOption = this.checkAccountMigrateOption(customer);
          },
          (error: any) => {
            this.mixpanel.storeEvent('ROLLBACK_FAILURE');
            this.toast.error(error.error.error.message, 'header.failed');
          }
        );
    } else {
      this.customerService
        .migrate$(this.migrate.targetCloud, 'Frontline Manual Migration')
        .pipe(
          switchMap(() => this.customer$),
          take(1)
        )
        .subscribe(
          (customer) => {
            this.mixpanel.storeEvent('MIGRATION_SUCCESS');
            this.toast.success('configurations.utilities.migrationSuccess', 'header.success');
            this.showAccountMigrateOption = this.checkAccountMigrateOption(customer);
          },
          (error: any) => {
            this.mixpanel.storeEvent('MIGRATION_FAILURE');
            this.toast.error(error.error.error.message, 'header.failed');
          }
        );
    }
  }

  getShardData(): void {
    this.customerService.shard$().subscribe(
      (response) => {
        this.shardInfo = response;
      },
      () => {
        this.shardInfo = {};
      }
    );
  }

  registerFilter(): void {
    this.clearFilter.emit({ section: 'utilities' });

    this.translate
      .get('configurations.utilities.flashLogging')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'utilities', property: 'flashLogging', translation: translated })
      );

    this.translate
      .get('configurations.utilities.generateTitle')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'utilities', property: 'generateTitle', translation: translated })
      );

    this.translate
      .get('configurations.utilities.historyList')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'utilities', property: 'historyList', translation: translated })
      );

    this.translate
      .get('configurations.utilities.auditLogs')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'utilities', property: 'auditLog', translation: translated })
      );

    this.translate
      .get('configurations.utilities.rebootLocation')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'utilities', property: 'rebootLocation', translation: translated })
      );

    this.translate
      .get('configurations.utilities.factoryReset')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'utilities', property: 'factoryReset', translation: translated })
      );

    this.translate
      .get('configurations.utilities.unclaimAllNodes')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'utilities', property: 'unclaimAllNodes', translation: translated })
      );

    this.translate
      .get('configurations.utilities.neighborsReport')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'utilities', property: 'neighborsReport', translation: translated })
      );

    this.translate
      .get('configurations.utilities.customerGroups')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'utilities', property: 'customerGroups', translation: translated })
      );

    this.translate
      .get('configurations.utilities.emailVerified')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'utilities', property: 'emailVerified', translation: translated })
      );

    this.translate
      .get('configurations.utilities.passwordReset')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'utilities', property: 'passwordReset', translation: translated })
      );

    this.translate
      .get('configurations.utilities.sendVerification')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'utilities', property: 'emailVerification', translation: translated })
      );

    this.translate
      .get('configurations.utilities.shard')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'utilities', property: 'shard', translation: translated })
      );

    this.translate
      .get('configurations.utilities.shardUrl')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'utilities', property: 'shardUrl', translation: translated })
      );

    this.translate
      .get('configurations.wifiradio.resyncController')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'utilities', property: 'resyncController', translation: translated })
      );

    if (this.isAdmin) {
      this.translate
        .get('configurations.utilities.mobilizeProblems')
        .subscribe((translated: string) =>
          this.filter.emit({ section: 'utilities', property: 'mobilizeProblems', translation: translated })
        );
    }
  }

  toggleExpand(): void {
    this.toggle.emit(!this.expand);
  }

  openGlobalInventoryModal(): void {
    this.modal.showGlobalInventory.next(true);
  }

  openNeighborsReportsModal(): void {
    this.showNeighborsReportsModal = !this.showNeighborsReportsModal;
  }

  getLogPullHistory(): void {
    this.logpullHistory = null;
    this.store.dispatch(updateLogpullHistory());
  }

  getMobilizeProblemsHistory(): void {
    this.mobilizeProblemsHistory = null;
    this.store.dispatch(updateMobilizeProblemsHistory());
  }

  downloadLogpull(logpullUrl: string): void {
    this.mixpanel.storeEvent('LOGPULL_DOWNLOAD');
    this.customerService.downloadLogPull$(logpullUrl).subscribe();
  }

  downloadMobilizeProblem(url: string): void {
    this.mixpanel.storeEvent('MOBILIZE_PROBLEM_DOWNLOAD');
    window.open(url, '_blank');
  }

  openCustomerGroupsModal(): void {
    this.showCustomerGroupsModal = !this.showCustomerGroupsModal;
  }

  copiedLogPull(url: string): void {
    this.mixpanel.storeEvent('LOGPULL_HISTORY_COPY');
    try {
      window.navigator.clipboard.writeText(url);
      this.toast.success(
        'configurations.utilities.history.copyUrlToastMessage',
        'configurations.utilities.history.copyUrlToastTitle'
      );
    } catch (error) {}
  }

  copiedMobilizeProblem(url: string): void {
    this.mixpanel.storeEvent('MOBILIZE_PROBLEM_HISTORY_COPY');
    try {
      window.navigator.clipboard.writeText(url);
      this.toast.success(
        'configurations.utilities.history.copyUrlMobilizeToastMessage',
        'configurations.utilities.history.copyUrlToastTitle'
      );
    } catch (error) {}
  }

  performLogpull(): void {
    this.logSubscription = this.troubleshooting.pulllog.subscribe(() => {
      this.getLogPullHistory();
    });

    const message = this.messageFormControl.value ? this.messageFormControl.value : 'PlumeCentral - Manual';
    this.mixpanel.storeEvent('LOGPULL_MANUAL_REQUEST', { MESSAGE: message });
    this.troubleshooting.initPullLog(message);
    this.messageFormControl.reset();
    this.toast.success('configurations.utilities.logpullRequested', 'header.success');
  }

  showUnclaimModal(): void {
    this.modal
      .showDialog('configurations.utilities.modal.modalUnclaim', 'configurations.utilities.modal.warning', {
        buttons: [
          { style: 'tertiary light', value: 'configurations.utilities.modal.cancel' },
          { style: 'super-primary', value: 'configurations.utilities.modal.confirm' }
        ]
      })
      .subscribe((response: any) => {
        if (response.item.value === 'configurations.utilities.modal.confirm') {
          this.confirmUnclaimAll();
        }
      });
  }

  resync(): void {
    this.customerService.resyncLocation$().subscribe(
      () => {
        this.mixpanel.storeEvent('CONFIGURATION_RESYNC_CONTROLLER');
        this.toast.success('configurations.utilities.resyncInitiated', 'header.success');
      },
      (error: any) => {
        this.mixpanel.storeEvent('CONFIGURATION_RESYNC_CONTROLLER_ERROR');
        this.toast.error(error.error.error.message, 'header.failed');
      }
    );
  }

  confirmUnclaimAll(): void {
    this.nodeService.delete$(this.preservePackIDs).subscribe(
      () => {
        this.mixpanel.storeEvent('UNCLAIM_ALL_SUCCESS');
        this.toast.success(
          'configurations.utilities.unclaimAllSuccessMessage',
          'configurations.utilities.unclaimAllSuccessTitle'
        );
      },
      (error: any) => {
        this.mixpanel.storeEvent('UNCLAIM_ALL_FAILURE');
        this.toast.error(error.error.error.message, 'header.failed');
      }
    );
  }

  showRebootModal(): void {
    this.modal
      .showDialog('configurations.utilities.modal.modalReboot', 'configurations.utilities.modal.pleaseConfirm', {
        buttons: [
          { style: 'tertiary light', value: 'configurations.utilities.modal.cancel' },
          { style: 'super-primary', value: 'configurations.utilities.modal.confirm' }
        ]
      })
      .subscribe((response: any) => {
        if (response.item.value === 'configurations.utilities.modal.confirm') {
          this.confirmRebootLocation();
        }
      });
  }

  confirmRebootLocation(): void {
    this.customerService.rebootLocation$(this.rebootPodsDelay).subscribe(
      () => {
        this.mixpanel.storeEvent('CONFIGURATION_UTILITIES_REBOOT_LOCATION');
      },
      (error: any) => {
        this.mixpanel.storeEvent('CONFIGURATION_UTILITIES_REBOOT_LOCATION');
        this.toast.error(error.error.error.message, 'header.failed');
      }
    );
  }

  showFactoryResetModal(): void {
    this.modal
      .showDialog('configurations.utilities.modal.modalFactoryReset', 'configurations.utilities.modal.pleaseConfirm', {
        buttons: [
          { style: 'tertiary light', value: 'configurations.utilities.modal.cancel' },
          { style: 'super-primary', value: 'configurations.utilities.modal.confirm' }
        ]
      })
      .subscribe((response: any) => {
        if (response.item.value === 'configurations.utilities.modal.confirm') {
          this.confirmFactoryReset();
        }
      });
  }

  confirmFactoryReset(): void {
    this.customerService.factoryReset$(!this.retainWiFiNetwork).subscribe(
      () => {
        this.mixpanel.storeEvent('CONFIGURATION_UTILITIES_FACTORY_RESET');
        this.toast.success('configurations.utilities.factoryResetSuccessMessage', 'header.success');
        this.store.dispatch(getPrimarySecondaryNetworks());
      },
      (error: any) => {
        this.mixpanel.storeEvent('CONFIGURATION_UTILITIES_FACTORY_RESET_ERROR');
        this.toast.error(error.error.error.message, 'header.failed');
      }
    );
  }

  changeEmailVerified(): void {
    this.customer$
      .pipe(
        take(1),
        switchMap((customer) =>
          this.customerService.setCustomerProperties$(customer.id, { emailVerified: !customer.emailVerified })
        )
      )
      .subscribe((customer) => {
        this.mixpanel.storeEvent('CHANGE_EMAIL_VERIFIED', { IS_VERIFIED: customer.emailVerified });
        this.store.dispatch(customerSetFromUtilities({ customer }));
      });
  }

  sendPasswordReset(): void {
    this.customerService.resetPassword$().subscribe((email) => {
      this.mixpanel.storeEvent('SEND_PASSWORD_RESET', { EMAIL: email });
      this.toast.success('toast.customerinfo.passwordMsg', 'toast.customerinfo.passwordTitle', {
        params: { email: this.emailPipe.transform(email) }
      });
    });
  }

  resendEmailVerified(): void {
    this.customerService.resendEmailVerification$().subscribe((email) => {
      this.mixpanel.storeEvent('SEND_EMAIL_VERIFICATION', { EMAIL: email });
      this.toast.success('toast.customerinfo.verificationMsg', 'toast.customerinfo.verificationTitle', {
        params: { email }
      });
    });
  }

  ngOnDestroy(): void {
    if (this.logSubscription) {
      this.logSubscription.unsubscribe();
    }

    if (this.permissionsSubscription) {
      this.permissionsSubscription.unsubscribe();
    }

    if (this.deviceSubscription) {
      this.deviceSubscription.unsubscribe();
    }
  }
}
