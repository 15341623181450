<modal mode="compact" (overlayAction)="closeModal()">
  <ng-container title>{{ 'devices.device.steer.header' | translate }}</ng-container>
  <ng-container content>
    <div class="list steer">
      <div class="key">{{ 'devices.device.steer.deviceTypeLabel' | translate }}</div>
      <div class="value">
        <drop-down class="selector medium">
          {{ 'devices.device.steer.deviceTypes.' + steer.current?.deviceType | translate }}
          <ng-container dropdown>
            <li (click)="selectDeviceType(deviceType)" *ngFor="let deviceType of steer.deviceTypes">
              {{ 'devices.device.steer.deviceTypes.' + deviceType | translate }}
            </li>
          </ng-container>
        </drop-down>
      </div>
    </div>
    <div class="list steer">
      <div class="key">{{ 'devices.device.steer.targetLabel' | translate }}</div>
      <div class="value">
        <slidetoggler [options]="steer.targetOptions" [editable]="true" (toggle)="setSteerTarget($event)">
        </slidetoggler>
      </div>
    </div>
    <ng-container *ngIf="steer.current.target">
      <div class="list steer">
        <div class="key">{{ 'devices.device.steer.nodeLabel' | translate }}</div>
        <div class="value">
          <drop-down class="selector medium">
            {{
              (steer.current?.node.nickname || steer.current?.node.defaultName | macAddr: true) ||
                ('devices.device.steer.selectOne' | translate)
            }}
            <ng-container dropdown>
              <li (click)="selectSteerOption('node', node)" *ngFor="let node of this.steer.nodes$ | async">
                {{ node.nickname || node.defaultName | macAddr: true | translate }}
              </li>
            </ng-container>
          </drop-down>
        </div>
      </div>
      <div class="list steer">
        <div class="key">{{ 'devices.device.steer.bandLabel' | translate }}</div>
        <div class="value">
          <drop-down class="selector medium">
            {{ steer.current?.band || 'devices.device.steer.selectOne' | translate }}
            <ng-container dropdown>
              <li (click)="selectSteerOption('band', band)" *ngFor="let band of this.steer.bands">
                {{ band | translate }}
              </li>
            </ng-container>
          </drop-down>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <ng-container controls>
    <button class="tertiary light" (click)="closeModal()">
      {{ 'health.networkInformation.modal.close' | translate }}
    </button>
    <button class="super-primary" [class.disabled]="isSteerDisabled()" (click)="steerDevice()">
      {{ 'devices.device.steer.button' | translate }}
    </button>
  </ng-container>
</modal>
