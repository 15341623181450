import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  ViewChild
} from '@angular/core';
import { NetworkStatusDataSet, NetworkStatusGroupedData } from './graph-network-status.interface';
import * as moment from 'moment';
import { GeneralHelper } from '../helpers/general.helper';

/*  in this graph last label is renamed to 'now' and
      if would overflow then it is moved so right side in on the end of svg.
 */

@Component({
  selector: 'flm-graph-network-status',
  templateUrl: './graph-network-status.component.html',
  styleUrls: ['./graph-network-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GraphNetworkStatusComponent {
  @Input()
  public set dataSet(value: NetworkStatusDataSet) {
    if (!value || value.length === 0) {
      this.groupedDataSet = [];
      return;
    }
    const sorted = value.sort((a, b) => a.time - b.time);
    this.setAxisLabelList(sorted[sorted.length - 1].time, sorted[0].time);
    this.timeToPxConstantCalc(sorted[sorted.length - 1].time, sorted[0].time);
    this.groupedDataSet = this.groupDataSetData(sorted);

    setTimeout(() => {
      this.cdRef.markForCheck();
    }, 0);
  }
  @Input() public set axisType(value: '24hr' | '7d' | '30d') {
    this.axisTypeValue = value ?? '24hr';
    if (this.groupedDataSet.length > 0) {
      this.setAxisLabelList(
        this.groupedDataSet[this.groupedDataSet.length - 1].timeEnd,
        this.groupedDataSet[0].timeStart
      );
    }
  }
  public get axisType(): '24hr' | '7d' | '30d' {
    return this.axisTypeValue;
  }
  axisTypeValue: '24hr' | '7d' | '30d' = '24hr';
  helper = new GeneralHelper();
  partialGradientUniqueId = `partialGradient-${this.helper.generateRandomString(8)}`;

  @ViewChild('tooltipElm') tooltipElm!: ElementRef<HTMLElement>;
  groupedDataSet: NetworkStatusGroupedData[] = [];
  timeToPxCalcConstants = { min: 0, max: 0, coefficient: 0 };
  resizeTimeOut?: number;
  mousemoveTimeOut?: number;
  axisLabelData: { time: number; label: string }[] = [];
  tooltip = {
    visible: false,
    top: 0,
    left: 0,
    shiftRight: false,
    data: {
      timeFrom: '',
      timeTo: '',
      state: 'ok' as 'online' | 'partial' | 'offline' | 'null'
    }
  };

  constructor(private elm: ElementRef<HTMLElement>, private cdRef: ChangeDetectorRef) {}

  timeToPx(time: number): number {
    return (time - this.timeToPxCalcConstants.min) * this.timeToPxCalcConstants.coefficient;
  }

  timeToPxNoOverflowRight(time: number, elm: HTMLElement): number {
    const val = this.timeToPx(time);
    const width = this.elm.nativeElement.getBoundingClientRect().width;
    const elmWidth = elm.getBoundingClientRect().width;
    return Math.min(val, width - elmWidth / 2);
  }

  widthOfBlock(start: number, end: number, nextData?: NetworkStatusGroupedData): number {
    if (nextData && (nextData.state === 'online' || !nextData.state)) {
      end = end + 3;
    }
    return Math.max(end - start, 0);
  }

  mouseMove(event: { offsetX: number; offsetY: number }): void {
    // firefox need copy of event in requestAnimationFrame function
    event = { offsetX: event.offsetX, offsetY: event.offsetY };
    if (this.mousemoveTimeOut) {
      window.cancelAnimationFrame(this.mousemoveTimeOut);
    }
    this.mousemoveTimeOut = window.requestAnimationFrame(() => {
      this.tooltip.left = event.offsetX;
      this.tooltip.top = event.offsetY;
      this.tooltip.shiftRight = event.offsetX < this.tooltipElm?.nativeElement?.offsetWidth;
    });
  }

  hover(data: NetworkStatusGroupedData | null, event: MouseEvent): void {
    this.tooltip.visible = !!data;
    if (data) {
      this.tooltip.data.state = data.state ?? 'null';
      this.tooltip.data.timeFrom = moment(data.timeStart).format('L LT');
      this.tooltip.data.timeTo = moment(data.timeEnd).format('L LT');
      this.mouseMove(event);
    }
  }

  @HostListener('window:resize')
  onResize(): void {
    if (this.resizeTimeOut) {
      window.cancelAnimationFrame(this.resizeTimeOut);
    }
    this.resizeTimeOut = window.requestAnimationFrame(() => {
      this.timeToPxConstantCalc(this.timeToPxCalcConstants.max, this.timeToPxCalcConstants.min);
      this.cdRef.markForCheck();
    });
  }

  private groupDataSetData(data: NetworkStatusDataSet): NetworkStatusGroupedData[] {
    if (data.length === 0) {
      return [];
    }
    const firstItem = {
      state: data[0].state,
      timeStart: data[0].time,
      timeEnd: data[0].time
    };
    return data.reduce(
      (acc, val) => {
        if (acc[acc.length - 1].state === val.state) {
          acc[acc.length - 1].timeEnd = val.time;
        } else {
          acc.push({
            state: val.state,
            timeStart: acc[acc.length - 1]?.timeEnd ?? val.time,
            timeEnd: val.time
          });
        }
        return acc;
      },
      [firstItem] as NetworkStatusGroupedData[]
    );
  }

  private timeToPxConstantCalc(max: number, min: number): void {
    this.timeToPxCalcConstants = {
      min,
      max,
      coefficient: this.elm.nativeElement.getBoundingClientRect().width / (max - min)
    };
  }

  private setAxisLabelList(max: number, min: number): void {
    this.axisLabelData = [];
    const lastLabelStep = this.lastLabelStep(max);
    this.axisLabelData.push(this.axisLabel(lastLabelStep.time));
    while (this.axisLabelData[this.axisLabelData.length - 1].time > min) {
      this.axisLabelData.push(
        this.axisLabel(this.axisLabelData[this.axisLabelData.length - 1].time - lastLabelStep.step)
      );
    }
  }

  private lastLabelStep(max: number): { time: number; step: number } {
    const date = new Date(max);
    switch (this.axisType) {
      case '24hr':
        date.setMinutes(0);
        date.setHours(Math.floor(date.getHours() / 4) * 4);
        return {
          time: date.getTime(),
          step: 1000 * 60 * 60 * 4
        };
      case '7d':
        date.setMinutes(0);
        date.setHours(0);
        return {
          time: date.getTime(),
          step: 1000 * 60 * 60 * 24
        };
      default:
        date.setMinutes(0);
        date.setHours(0);
        return {
          time: date.getTime(),
          step: 1000 * 60 * 60 * 24 * 7
        };
    }
  }

  private axisLabel(time: number): { time: number; label: string } {
    switch (this.axisType) {
      case '24hr':
        return {
          time,
          label: moment(time).format('HH')
        };
      case '7d':
        return {
          time,
          label: moment(time).format('ddd')
        };
      default:
        return {
          time,
          label: moment(time).format('MMM DD')
        };
    }
  }
}
