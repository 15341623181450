import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { combineLatest, of } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { Line } from 'src/app/lib/d3/models/objects/line';
import { Point } from 'src/app/lib/d3/models/objects/point';
import { Series } from 'src/app/lib/d3/models/objects/series';
import { GeneralHelper } from 'src/app/lib/helpers/general.helper';
import { QoeHelper } from 'src/app/lib/helpers/qoe.helper';
import {
  DeepReadonly,
  IClientSteeringTriggers,
  IDevice,
  IDeviceAlarm,
  IDeviceFeatureBandwidthData,
  IDeviceFeatureChartsData,
  ILocation,
  IMacStitching
} from 'src/app/lib/interfaces/interface';
import { MacAddrPipe } from 'src/app/lib/pipes/mac-addr.pipe';
import { AlarmedService } from 'src/app/lib/services/alarmed.service';
import { CustomerService } from 'src/app/lib/services/customer.service';
import { DeviceService } from 'src/app/lib/services/device.service';
import { IconService } from 'src/app/lib/services/icon.service';
import { LoggingService } from 'src/app/lib/services/logging.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { ModalService } from 'src/app/lib/services/modal.service';
import { NetworkAccessService } from 'src/app/lib/services/network-access.service';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { QoeService } from 'src/app/lib/services/qoe.service';
import { SecondaryNetworksService } from 'src/app/lib/services/secondary-networks.service';
import { SpeedConverterService } from 'src/app/lib/services/speed-converter.service';
import { ToastService } from 'src/app/lib/services/toast.service';
import { TroubleshootingService } from 'src/app/lib/services/troubleshooting.service';
import { configRefreshAppTime } from 'src/app/store/configview/config.actions';
import { selectBandSteeringEditable } from 'src/app/store/configview/config.selectors';
import { selectCapabilities } from 'src/app/store/customer/capabilities.selector';
import { selectPipeLocationOnChangeOptimistic } from 'src/app/store/customer/customer.selectors';
import { pollingPull } from 'src/app/store/polling/polling.actions';
import { selectNodes } from 'src/app/store/polling/polling.selector';

@UntilDestroy()
@Component({
  selector: 'device',
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.scss']
})
export class DeviceComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  device: IDevice & any = {} as IDevice;

  @Input()
  smallbusinessFilter: string;

  @Output()
  update: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  openFocusModalEmitter = new EventEmitter<void>();

  @Output()
  openIpv6ModalEmitter = new EventEmitter<void>();

  @Output()
  openDeviceDetailsModalEmitter = new EventEmitter<void>();

  @Output()
  openCloudSteeringModalEmitter = new EventEmitter<void>();

  @Output()
  openSteerModalEmitter = new EventEmitter<void>();

  @Output()
  openQoeModalEmitter = new EventEmitter<void>();

  @Output()
  openPriorityModalEmitter = new EventEmitter<void>();

  @ViewChild('input')
  input: ElementRef;

  @ViewChild('clientSteering')
  clientSteering: ElementRef;

  capabilities$ = this.store.select(selectCapabilities);
  permissions: any;
  subscription: any;
  themeSubscription: any;
  ui: string = '';

  axis: any[];
  axis24: string;
  axis12: string;
  axisNow: string;
  qoeAlarm: boolean;
  helper: GeneralHelper = new GeneralHelper();
  name: FormControl = new FormControl();
  startTime: FormControl = new FormControl();
  endTime: FormControl = new FormControl();
  deviceFreeze: boolean;
  clientSteeringItems: any[] = [];
  appTimeItems: any[] = [];
  chartModes: any[] = [];
  rssiModeItems: any[] = [];
  rssiMode: string = '';
  chartMode: string = '';
  iconURL: string = '';

  showQoeModal: boolean = false;
  showPriorityModal: boolean = false;
  showFreezeModal: boolean = false;
  showMacStitchingModal: boolean = false;
  showTemplateModal: boolean = false;
  editTime: boolean = false;
  residentialGwManaged: boolean = false;
  forceSteerEnabled: boolean = false;

  rssi: any = {
    dynamic: {
      graph: [],
      series: [],
      maxValue: -40
    },
    static: {
      graph: [],
      series: [],
      maxValue: -40
    }
  };

  dataConsumption: any = {
    graph: [],
    series: [],
    maxValue: 100
  };

  steeringHeaders: any = [
    'dateTime',
    'kickType',
    'sourceNode',
    'sourceBand',
    'currentSNR',
    'currentRate',
    'targetNode',
    'targetBand',
    'expectedSNR',
    'expectedRate'
  ];
  loadingSteerings = false;
  steerings: IClientSteeringTriggers[] = null;
  steeringTMStartTime: number;
  steeringTMEndTime: number;
  steeringDefaultStartDate: string;
  steeringTimeMachineLoading = false;
  selectBandSteeringEditable$ = this.store.select(selectBandSteeringEditable({ plume: this.plume }));
  endAfterStart = false;
  dateFormat: string = 'YYYY-MM-DDTHH:mm';

  location: DeepReadonly<ILocation> = {} as any;
  consumptionStats: any = null;

  coverage15minAlarm = false;
  coverageLoading = true;
  macStitchingList: IMacStitching[] = [];
  macStitchingSort: string = '';
  freezeAutoExpireInterval: any = null;
  freezeAutoExpireDuration = 0;
  customFreezeTemplates: any[] = [];
  freezeSchedule: any = {};
  focusSchedule: any = {};

  selectedDays: any[] = [];
  isFlexRole: boolean = false;
  hidePersonalDetails: boolean = false;
  nodes: any = [];
  isUtilizingFocus: boolean = false;
  focusReady: boolean = false;
  focusAddPending: boolean = false;
  observer;

  constructor(
    private logging: LoggingService,
    private icons: IconService,
    private toast: ToastService,
    public plume: PlumeService,
    private alarmed: AlarmedService,
    private troubleshoot: TroubleshootingService,
    private qoe: QoeService,
    private mixpanel: MixpanelService,
    private modal: ModalService,
    private translate: TranslateService,
    private router: Router,
    private store: Store,
    private deviceService: DeviceService,
    private secondaryNetworks: SecondaryNetworksService,
    private speedConverter: SpeedConverterService,
    public macAddrPipe: MacAddrPipe,
    private customerService: CustomerService,
    private networkAccessService: NetworkAccessService
  ) {
    this.initHistoryScale();
  }

  ngOnInit(): void {
    this.store
      .pipe(selectPipeLocationOnChangeOptimistic)
      .pipe(untilDestroyed(this))
      .subscribe((location) => (this.location = location));
    this.ui = this.plume.getUI();
    this.logging.debug('devices.init: ', this.device);
    this.qoeAlarm = this.alarmed.getDeviceQoe(this.device);

    if (this.device.iconV3) {
      this.iconURL = this.icons.getV3Path(this.device.iconV3, 'small');
    } else {
      this.iconURL = this.icons.getPath(this.device.iconV2);
    }

    this.subscription = this.plume.permissions.subscribe((data: any) => {
      this.permissions = data;
    });

    if (this.plume.cloudVersionAbove1_85()) {
      this.forceSteerEnabled = true;
    }

    this.isFlexRole = this.plume.isFlexRole();
    this.hidePersonalDetails = this.plume.hidePersonalDetails();

    this.setRssiMode('static');
    this.setMode('24h');
    this.setClientSteeringItems();
    this.checkCoverageAlarm();

    this.setAppTimeItems();

    this.getDeviceState();

    this.initSteeringTimeMachine();
    this.observeClientSteering();

    if (this.device.expand) {
      this.loadStitchingSupport();
    }

    this.store
      .select(selectNodes)
      .pipe(untilDestroyed(this))
      .subscribe((nodes) => {
        if (nodes) {
          this.nodes = nodes;
        }
      });
    this.isUtilizingFocus = this.location.isUtilizingFocuses;
  }

  ngOnChanges(): void {
    if (this.location.isUtilizingSharedLocationFreezeSchedules && this.customFreezeTemplates.length) {
      this.device.freeze.timeTemplates = this.customFreezeTemplates;
    }
  }

  openIpv6Modal(): void {
    this.openIpv6ModalEmitter.emit();
  }

  openCloudSteeringModal() {
    this.openCloudSteeringModalEmitter.emit();
  }

  getHMS(secs: number): { h: string; m: string; s: string } {
    const time = moment.utc(secs * 1000);

    return {
      h: `${time.format('HH')}`,
      m: `${time.format('mm')}`,
      s: `${time.format('ss')}`
    };
  }

  initSteeringTimeMachine(): void {
    this.steeringDefaultStartDate = moment().subtract(7, 'days').format(this.dateFormat);
  }

  initHistoryScale(): void {
    if (this.chartMode === '24h') {
      this.axis = [
        moment().subtract(24, 'hours').format('h a'),
        moment().subtract(12, 'hours').format('h a'),
        'devices.device.now'
      ];
    } else {
      this.axis = [
        moment().subtract(6, 'days').format('MMM D, h:mm a'),
        moment().subtract(3, 'days').format('MMM D, h:mm a'),
        'devices.device.now'
      ];
    }
  }

  copyLink(event: any): void {
    event.stopPropagation();

    const deviceURL =
      location.host +
      '/customer/' +
      this.plume.customerid +
      '/location/' +
      this.plume.locationid +
      '/devices/' +
      this.device.mac;

    try {
      navigator.clipboard.writeText(deviceURL);
      this.toast.success('devices.device.linkCopied', 'devices.device.success', {
        params: {
          device: this.device.name
        }
      });
    } catch (ignore) {}
  }

  authorizeGuestDevice(mode: boolean): void {
    if (mode) {
      this.setAuthorizedGuestDevice(mode);
    } else {
      this.modal
        .showDialog('devices.device.authorizeMsg', 'devices.device.authorizeTitle', {
          buttons: [
            { style: 'tertiary light', value: 'devices.device.cancel' },
            { style: 'super-primary', value: 'devices.device.continue' }
          ]
        })
        .subscribe((response) => {
          if (response.item.style === 'super-primary') {
            this.setAuthorizedGuestDevice(mode);
          }
        });
    }
  }

  setAuthorizedGuestDevice(mode: boolean): void {
    this.secondaryNetworks.setCaptivePortalAuthorizedGuestDevice$(this.device.mac, mode).subscribe(
      () => {
        if (mode) {
          this.update.emit();
        }
      },
      (error) => {
        if (error.status === 422) {
          this.toast.error('devices.device.authorizationStateChangeError', 'devices.device.error');
        } else {
          this.toast.error(error.error.error.message, 'devices.device.error');
        }
      }
    );
  }

  gotoNode(id: string): void {
    this.router.navigate(['customer', this.plume.customerid, 'location', this.plume.locationid, 'nodes', id]);
  }

  forceSteer(): void {
    this.deviceService.setForceSteer$(this.device.mac).subscribe(
      () => {
        this.mixpanel.storeEvent('DEVICES_TEMPORARY_2G_STEER', { DEVICE_MAC: this.device.mac, SUCCESS: true });
        this.toast.success('devices.device.steer.forceSuccessMsg', 'devices.device.steer.forceSuccessTitle');
        this.store.dispatch(pollingPull({ debugSource: 'device - force steer' }));
      },
      (error: any) => {
        this.mixpanel.storeEvent('DEVICES_TEMPORARY_2G_STEER', {
          DEVICE_MAC: this.device.mac,
          ERROR: error.error.error
        });
        this.toast.error('devices.device.steer.forceErrorMsg', 'devices.device.steer.forceErrorTitle');
      }
    );
  }

  cancelForceSteer(): void {
    this.deviceService.cancelForceSteer$(this.device.mac).subscribe(
      () => {
        this.mixpanel.storeEvent('DEVICES_CANCEL_TEMPORARY_2G_STEER', { DEVICE_MAC: this.device.mac, SUCCESS: true });
        this.toast.success(
          'devices.device.steer.cancelForceSuccessMsg',
          'devices.device.steer.cancelForceSuccessTitle'
        );
        this.store.dispatch(pollingPull({ debugSource: 'device - cancel force steer' }));
      },
      (error: any) => {
        this.mixpanel.storeEvent('DEVICES_CANCEL_TEMPORARY_2G_STEER', {
          DEVICE_MAC: this.device.mac,
          ERROR: error.error.error
        });
        this.toast.error('devices.device.steer.cancelForceErrorMsg', 'devices.device.steer.cancelForceErrorTitle');
      }
    );
  }

  getSteeringTimeMachine(): void {
    if (moment(this.steeringTMStartTime).diff(moment(this.steeringTMEndTime), 'minutes') > 0) {
      this.endAfterStart = true;
      this.steerings = [];
      return;
    } else {
      this.endAfterStart = false;
    }

    this.steeringTimeMachineLoading = true;
    this.deviceService
      .steeringTimeMachine$(
        this.device.mac,
        moment(this.steeringTMStartTime).toISOString(),
        moment(this.steeringTMEndTime).toISOString()
      )
      .subscribe(
        (steerings) => {
          this.steeringTimeMachineLoading = false;
          this.steerings = steerings;
        },
        (error: any) => {
          this.steeringTimeMachineLoading = false;
          this.toast.error('devices.device.errorSteeringTimeMachine', 'devices.device.error', {
            params: {
              error: error.error.error.message
            }
          });
        }
      );
  }

  toggleSteeringTimeMachine(): void {
    this.device.steeringTimeMachine = !this.device.steeringTimeMachine;
  }

  setClientSteeringItems(): void {
    this.clientSteeringItems = [
      { value: 'auto', translation: 'auto', selected: this.device.clientSteering.auto },
      { value: 'off', translation: 'off', selected: !this.device.clientSteering.auto }
    ];
  }

  setAppTimeItems(): void {
    this.appTimeItems = [
      { value: true, translation: 'enable', selected: this.device.appTime },
      { value: false, translation: 'disable', selected: !this.device.appTime }
    ];
  }

  setRssiMode(mode: any): void {
    if (mode) {
      this.rssiMode = mode;
    }

    this.rssiModeItems = [
      {
        value: 'static',
        translation: 'devices.device.staticNoiseFloor',
        selected: this.rssiMode === 'static' ? true : false
      },
      {
        value: 'dynamic',
        translation: 'devices.device.dynamicNoiseFloor',
        selected: this.rssiMode === 'dynamic' ? true : false
      }
    ];
  }

  setMode(mode: any): void {
    this.chartMode = mode;

    this.chartModes = [
      { value: '24h', translation: '24h', selected: this.chartMode === '24h' ? true : false },
      { value: '7d', translation: '7d', selected: this.chartMode === '7d' ? true : false }
    ];

    if (this.device.expand) {
      this.toggleViewHistory();
    }
  }

  setAppTime(mode: boolean): void {
    this.deviceService.setAppTime$(this.device.mac, mode).subscribe(
      () => {
        this.mixpanel.storeEvent('DEVICES_SET_APP_TIME', {
          DEVICE_MAC: this.device.mac,
          APP_TIME: mode
        });
        this.toast.success('devices.device.successAppTimeSet', 'devices.device.success', {
          params: {
            device: this.device.name
          }
        });
      },
      (error: any) => {
        this.toast.error('devices.device.errorAppTimeSet', 'devices.device.error', {
          params: {
            error: error.error.error.message
          }
        });
        this.device.appTime = !mode;
        this.setAppTimeItems();
      },
      () => {
        this.store.dispatch(configRefreshAppTime());
      }
    );
  }

  setClientSteering(mode: 'auto' | 'off'): void {
    this.deviceService.setClientSteering$(this.device.mac, mode).subscribe(
      () => {
        this.mixpanel.storeEvent('DEVICES_SET_CLIENT_STEERING', {
          DEVICE_MAC: this.device.mac,
          STEERING: mode
        });
        this.toast.success('devices.device.successClientSteeringSet', 'devices.device.success', {
          params: {
            device: this.device.name
          }
        });
      },
      (error: any) => {
        this.toast.error('devices.device.errorClientSteeringSet', 'devices.device.error', {
          params: {
            error: error.error.error.message
          }
        });
      }
    );
  }

  observeClientSteering(): void {
    if (this.device.expand) {
      setTimeout(() => {
        this.observer = new (window as any).ResizeObserver((entries) => {
          const clientSteering = this.clientSteering?.nativeElement?.getBoundingClientRect();
          if (!clientSteering) return;

          entries.forEach((entry) => {
            let count = 0;

            for (const node of entry.target.children) {
              if (node.getBoundingClientRect().top - clientSteering.top > 10) {
                node.classList.toggle('wrapped', true);
                count++;
              } else {
                node.classList.toggle('wrapped', false);
              }
            }

            if (entry.target.children.length - count === 1) {
              this.clientSteering.nativeElement.classList.toggle('multi', true);
            } else {
              this.clientSteering.nativeElement.classList.toggle('multi', false);
            }
          });
        });
        if (this.clientSteering?.nativeElement) {
          this.observer.observe(this.clientSteering.nativeElement);
        }
      }, 10);
    } else {
      if (this.observer?.nativeElement) {
        this.observer.unobserve(this.clientSteering.nativeElement);
      }
    }
  }

  checkCoverageAlarm(): void {
    if (this.device.expand && this.device.coverageAlarm) {
      this.deviceService.coverage15minAlarm$(this.device.mac).subscribe((response) => {
        this.coverage15minAlarm = this.calculateAlarms(response);
        this.coverageLoading = false;
      });
    } else {
      this.coverageLoading = false;
    }
  }

  calculateAlarms(alarms: IDeviceAlarm): boolean {
    let lastDate = 0;
    let inAlarm = false;

    alarms['2g'].forEach((tick: any) => {
      if (moment(tick.timestamp).valueOf() > lastDate) {
        lastDate = moment(tick.timestamp).valueOf();
        inAlarm = tick.value === 1 ? true : false;
      }
    });

    alarms['5g'].forEach((tick: any) => {
      if (moment(tick.timestamp).valueOf() > lastDate) {
        lastDate = moment(tick.timestamp).valueOf();
        inAlarm = tick.value === 1 ? true : false;
      }
    });

    alarms['5gl'].forEach((tick: any) => {
      if (moment(tick.timestamp).valueOf() > lastDate) {
        lastDate = moment(tick.timestamp).valueOf();
        inAlarm = tick.value === 1 ? true : false;
      }
    });

    alarms['5gu'].forEach((tick: any) => {
      if (moment(tick.timestamp).valueOf() > lastDate) {
        lastDate = moment(tick.timestamp).valueOf();
        inAlarm = tick.value === 1 ? true : false;
      }
    });

    const round = (interval: number, moment: any) => {
      const roundedMinutes = Math.floor(moment.minute() / interval) * interval;
      return moment.clone().minute(roundedMinutes).second(0).millisecond(0);
    };

    if (inAlarm && lastDate >= round(15, moment()).valueOf()) {
      return true;
    } else {
      return false;
    }
  }

  toggleDevice(): void {
    if (!this.device.expand) {
      this.mixpanel.storeEvent('DEVICES_EXPAND', { DEVICE_MAC: this.device.mac, EXPANDED: true });
      this.device.expand = true;
      this.coverageLoading = true;
      this.checkCoverageAlarm();
      this.loadStitchingSupport();
    } else {
      this.mixpanel.storeEvent('DEVICES_EXPAND', { DEVICE_MAC: this.device.mac, EXPANDED: false });
      this.device.expand = false;
    }

    this.observeClientSteering();
  }

  private loadStitchingSupport(): void {
    this.deviceService
      .getMacStitching$(this.device.mac)
      .pipe(catchError(() => of([])))
      .subscribe((stitching) => {
        this.macStitchingList = stitching;
        this.sortMacStitching('timestamp', 'desc');
      });
  }

  sortMacStitching(field: string, mode: string): void {
    this.macStitchingSort = field + '/' + mode;

    if (mode === 'desc') {
      this.macStitchingList.sort((a, b) => (a[field] > b[field] ? -1 : 1));
    } else {
      this.macStitchingList.sort((a, b) => (a[field] < b[field] ? -1 : 1));
    }
  }

  getMacDeviceDetails(stitch: IMacStitching): void {
    if (!stitch.details) {
      this.deviceService.typeDetail$(stitch.mac).subscribe((response) => {
        if (response.kind) {
          stitch.details = response.kind.category + ' - ' + response.kind.brand + ' ' + response.kind.name;
        } else {
          stitch.details = 'No data';
        }
      });
    } else {
      stitch.details = null;
    }
  }

  toggleViewHistory(): void {
    this.mixpanel.storeEvent('DEVICES_VIEW_HISTORY', {
      DEVICE_MAC: this.device.mac,
      EXPANDED: this.device.deviceHistory,
      VIEW_HISTORY_MODE: this.chartMode
    });

    delete this.device.history;
    delete this.device.busyness;
    delete this.device.nodeConnections;
    delete this.device.bandsteering;
    delete this.device.clientsteering;

    this.initHistoryScale();
    if (this.device.connectionState !== 'connected' || this.device.medium !== 'ethernet') {
      this.getBandSteeringHistory();
      this.getClientSteeringHistory();
      this.getRssiData();
    }

    if (this.ui === 'noc') {
      this.getDataConsumptionGraphData();
    } else {
      this.getBusyness();
    }
  }

  toggleHistory(): void {
    this.device.deviceHistory = !this.device.deviceHistory;

    if (this.device.deviceHistory) {
      this.toggleViewHistory();
    }
  }

  getRssiData(): void {
    this.rssi.dynamic.graph = [{ series: [], data: [] }];
    this.rssi.static.graph = [{ series: [], data: [] }];

    const granularity = this.chartMode === '24h' ? 'days&limit=1' : 'days&limit=7';

    combineLatest([
      this.qoe.deviceMetrics$(this.device.mac, granularity),
      this.store.select(selectNodes).pipe(take(1))
    ]).subscribe(([response, nodes]) => {
      const qoe = new QoeHelper();
      qoe.setLocale(moment.locale());

      const metrics = qoe.convertQoeMetrics(response, true);
      const charts = qoe.horizontalCharts(metrics, nodes, this.chartMode);

      if (charts) {
        const translations = {
          p25Rssi: this.translate.instant('charts.qoe.p25Rssi'),
          p75Rssi: this.translate.instant('charts.qoe.p75Rssi'),
          p25Snr: this.translate.instant('charts.qoe.p25Snr'),
          p75Snr: this.translate.instant('charts.qoe.p75Snr')
        };

        const dynamicChart = qoe.nocCharts(
          { p25Rssi: charts.p25Rssi, p75Rssi: charts.p75Rssi },
          this.chartMode,
          { p25Rssi: 'rgb(255, 197, 0)', p75Rssi: 'rgb(94, 189, 62)' },
          translations
        );
        const staticChart = qoe.nocCharts(
          { p25Snr: charts.p25Snr, p75Snr: charts.p75Snr },
          this.chartMode,
          { p25Snr: 'rgb(255, 197, 0)', p75Snr: 'rgb(94, 189, 62)' },
          translations
        );

        this.rssi.dynamic.graph = [...dynamicChart.p25Rssi.graph, ...dynamicChart.p75Rssi.graph];
        this.rssi.dynamic.maxValue =
          Math.max(dynamicChart.p25Rssi.maxValue, dynamicChart.p75Rssi.maxValue) > -40 ? 0 : -40;

        this.rssi.static.graph = [...staticChart.p25Snr.graph, ...staticChart.p75Snr.graph];
        this.rssi.static.maxValue = Math.max(staticChart.p25Snr.maxValue, staticChart.p75Snr.maxValue) > -40 ? 0 : -40;

        this.device.history = charts.rssi.ticks;
        this.device.nodeConnections = charts.online.ticks;
      } else {
        this.rssi.dynamic.graph = [];
        this.rssi.static.graph = [];

        this.device.history = qoe.fillEmpty({}, '', [], this.chartMode);
        this.device.nodeConnections = qoe.fillEmpty({}, '', [], this.chartMode);
      }
    });
  }

  getDataConsumptionGraphData(): void {
    this.dataConsumption.graph = [
      {
        series: {
          color: 'var(--chart-green)',
          text: '',
          translation: ''
        },
        axis: 'left',
        data: []
      }
    ];

    this.dataConsumption.series = this.dataConsumption.graph.map((dataObj: any) => dataObj.series);
    this.consumptionStats = null;

    const chartType = this.chartMode === '24h' ? 'dailyChart' : 'weeklyChart';

    this.deviceService
      .device$(this.device.mac, ['bandwidthData', 'chartsData'])
      .subscribe((response: IDevice & IDeviceFeatureChartsData & IDeviceFeatureBandwidthData) => {
        this.dataConsumption.graph = [
          this.dataConsumptionLine(response[chartType], 'total'),
          this.dataConsumptionLine(response[chartType], 'download'),
          this.dataConsumptionLine(response[chartType], 'upload')
        ];

        if (this.chartMode === '24h') {
          this.setConsumptionStats(response.bandwidth.daily);
        } else {
          this.setConsumptionStats(response.bandwidth.weekly);
        }

        this.dataConsumption.series = this.dataConsumption.graph.map((dataObj: any) => dataObj.series);
        this.dataConsumption.maxValue = this.findMaxValue(response[chartType]) / 1000000 || 100;
      });
  }

  setConsumptionStats(data: any): void {
    const upload = this.helper.formatBytes(data.upload, data.uploadUnits);
    const download = this.helper.formatBytes(data.download, data.downloadUnits);
    const total = this.helper.formatBytes(data.upload + data.download, data.downloadUnits);

    this.consumptionStats = {
      upload: upload.value,
      uploadUnit: upload.unit,
      download: download.value,
      downloadUnit: download.unit,
      total: total.value,
      totalUnit: total.unit
    };
  }

  getBusyness(): void {
    this.deviceService.bandwidth$(this.device.mac, this.chartMode === '24h' ? 25 : 175).subscribe((bandwidth) => {
      this.device.busyness = this.qoe.calculateBusyness(bandwidth, this.chartMode);
    });
  }

  dataConsumptionLine(
    dataArray: { timestamp: string | number; total: number; download: number; upload: number }[],
    key: 'total' | 'download' | 'upload'
  ): Line {
    const points = dataArray.map(
      (obj) =>
        new Point(
          new Date(obj.timestamp).toString(),
          this.speedConverter.convertFromBits(this.speedConverter.convertToBits(obj[key], 'B'), 'MiB', 2)
        )
    );

    return new Line(
      new Series(
        key === 'download' ? 'var(--chart-green)' : key === 'upload' ? 'var(--chart-yellow)' : 'var(--chart-purple)',
        key,
        'qoe.dataConsumption.' + key
      ),
      'left',
      points
    );
  }

  findMaxValue(array: any[]): number {
    return Math.max.apply(
      Math,
      array.map((o: any) => Math.max(o.download, o.upload, o.total))
    );
  }

  launchRename(): void {
    if (!this.device.isRename) {
      this.mixpanel.storeEvent('DEVICES_RENAME_ENABLE', { DEVICE_MAC: this.device.mac });
      this.device.isRename = true;

      setTimeout(() => {
        this.input.nativeElement?.focus();
      }, 100);
    } else {
      this.device.isRename = false;
    }
  }

  openQoeModal(): void {
    this.mixpanel.storeEvent('DEVICES_QOE_DIALOG', { DEVICE_MAC: this.device.mac });
    this.openQoeModalEmitter.emit();
  }

  openDeviceDetailsModal(): void {
    this.mixpanel.storeEvent('DEVICES_TYPE_DIALOG', { DEVICE_MAC: this.device.mac });
    this.openDeviceDetailsModalEmitter.emit();
  }

  toggleFreezeModal(state: boolean): void {
    if (state) {
      this.mixpanel.storeEvent('DEVICE_FREEZE_DIALOG', { DEVICE_MAC: this.device.mac });
    }

    this.showFreezeModal = state;

    if (this.showFreezeModal) {
      this.freezeAutoExpireTimer();

      if (this.location.isUtilizingSharedLocationFreezeSchedules) {
        this.getCustomFreezeTemplates();
      }
    } else {
      clearInterval(this.freezeAutoExpireInterval);
      this.freezeAutoExpireDuration = 0;
      this.customFreezeTemplates = [];
    }
  }

  getCustomFreezeTemplates(timeTemplate: any = null, callback: any = null): void {
    if (this.location.isUtilizingSharedLocationFreezeSchedules) {
      this.customerService.schedules$().subscribe((response) => {
        response.forEach((template: any) => {
          const schedule = timeTemplate
            ? timeTemplate.schedules.find((schedule: any) => schedule.id === template.id)
            : this.device.freeze.schedules.find((schedule: any) => schedule.id === template.id);

          if (schedule) {
            template.enable = true;
          }
        });

        if (timeTemplate) {
          this.device.freeze = timeTemplate;
        }

        this.device.freeze.timeTemplates = response;
        this.customFreezeTemplates = response;

        if (callback) {
          callback();
        }
      });
    }
  }

  toggleFreezeTemplate(state: boolean): void {
    if (state) {
      this.freezeSchedule = {
        name: new FormControl(''),
        schedules: [
          {
            startTime: new FormControl(''),
            endTime: new FormControl(''),
            selectedDays: []
          }
        ],
        mode: 'add'
      };
    } else {
      this.freezeSchedule = {};
    }

    this.showTemplateModal = state;
  }

  toggleSelectedDays(day: number, i: number): void {
    const index = this.freezeSchedule.schedules[i].selectedDays.indexOf(day);

    if (index >= 0) {
      this.freezeSchedule.schedules[i].selectedDays.splice(index, 1);
    } else {
      this.freezeSchedule.schedules[i].selectedDays.push(day);
    }
  }

  removeSchedule(index: number): void {
    this.freezeSchedule.schedules.splice(index, 1);
  }

  addSchedule(): void {
    this.freezeSchedule.schedules.push({
      startTime: new FormControl(''),
      endTime: new FormControl(''),
      selectedDays: []
    });
  }

  validateFreezeSchedules(): boolean {
    let valid = true;

    if (!this.freezeSchedule.name.value) {
      this.toast.warning('devices.device.nameIsRequired', 'devices.device.error');
      valid = false;
    }

    this.freezeSchedule.schedules.forEach((schedule: any) => {
      const regex = '^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$';
      const validStartTime = new RegExp(regex).test(schedule.startTime.value);
      const validEndTime = new RegExp(regex).test(schedule.endTime.value);

      if (
        !validStartTime ||
        (!validEndTime && !!schedule.endTime.value && this.location.isUtilizingSharedLocationFreezeSchedules) ||
        (!validEndTime && !this.location.isUtilizingSharedLocationFreezeSchedules)
      ) {
        this.toast.warning('devices.device.timeFormatMessage', 'devices.device.timeFormatTitle');
        valid = false;
      }

      if (
        !this.location.isUtilizingSharedLocationFreezeSchedules &&
        parseFloat(schedule.startTime.value.split(':')[0]) < 13
      ) {
        this.toast.warning('devices.device.min13Message', 'devices.device.min13Title');
        valid = false;
      }

      if (
        !this.location.isUtilizingSharedLocationFreezeSchedules &&
        parseFloat(schedule.endTime.value.split(':')[0]) > 11
      ) {
        this.toast.warning('devices.device.max12Message', 'devices.device.max12Title');
        valid = false;
      }

      if (schedule.startTime.value === schedule.endTime.value) {
        this.toast.warning('devices.device.sameTimeMessage', 'devices.device.sameTimeTitle');
        valid = false;
      }

      if (!schedule.selectedDays.length) {
        this.toast.warning('devices.device.selectedDaysMessage', 'devices.device.selectedDaysTitle');
        valid = false;
      }
    });

    return valid;
  }

  openFocusModal(): void {
    this.mixpanel.storeEvent('DEVICE_FOCUS_DIALOG', { DEVICE_MAC: this.device.mac });
    this.openFocusModalEmitter.emit();
  }

  addTimeTemplate(): void {
    if (!this.validateFreezeSchedules()) {
      return;
    }

    const params = {
      type: 'custom',
      name: this.freezeSchedule.name.value,
      schedules: this.freezeSchedule.schedules.map((schedule: any) => {
        if (schedule.endTime.value === '00:00' || schedule.endTime.value === '') {
          schedule.endTime.setValue(null);
        }

        return {
          startTime: schedule.startTime.value,
          endTime: schedule.endTime.value,
          daysOfWeek: schedule.selectedDays
        };
      })
    };

    this.customerService.addSchedule$(params).subscribe(
      () => {
        this.getCustomFreezeTemplates();
        this.toggleFreezeTemplate(false);
      },
      (error: any) => {
        this.toast.error(error.error.error.message, error.error.error.name);
      }
    );
  }

  updateTimeTemplate(): void {
    if (!this.validateFreezeSchedules()) {
      return;
    }

    if (this.location.isUtilizingSharedLocationFreezeSchedules) {
      const params = {
        type: this.freezeSchedule.type,
        name: this.freezeSchedule.name.value,
        schedules: this.freezeSchedule.schedules.map((schedule: any) => {
          if (schedule.endTime.value === '00:00' || schedule.endTime.value === '') {
            schedule.endTime.setValue(null);
          }

          return {
            startTime: schedule.startTime.value,
            endTime: schedule.endTime.value,
            daysOfWeek: schedule.selectedDays
          };
        })
      };
      this.customerService.updateSchedule$(this.freezeSchedule.id, params).subscribe(
        () => {
          this.getCustomFreezeTemplates();
          this.toggleFreezeTemplate(false);
        },
        (error: any) => {
          this.toast.error(error.error.error.message, error.error.error.name);
        }
      );
    } else {
      const params = {
        schedules: this.freezeSchedule.schedules.map((schedule) => ({
          daysOfWeek: schedule.selectedDays,
          times: [
            {
              start: schedule.startTime.value,
              end: schedule.endTime.value
            }
          ]
        }))
      };

      this.deviceService.updateSchedule$(this.device.mac, this.freezeSchedule.id, params).subscribe(
        (response) => {
          this.device.freeze = response;
          this.getDeviceState();
          this.toggleFreezeTemplate(false);
        },
        (error: any) => {
          this.toast.error(error.error.error.message, error.error.error.name);
        }
      );
    }
  }

  deviceUrl(mac: string): string {
    if (this.plume.isFlexRole()) {
      return '/Customers/' + this.plume.customerid + '/locations/' + this.plume.locationid + '/flex/devices/' + mac;
    } else {
      return '/Customers/' + this.plume.customerid + '/locations/' + this.plume.locationid + '/devices/' + mac;
    }
  }

  round(num: number): number {
    return Math.round(num);
  }

  openSteerModal(): void {
    this.mixpanel.storeEvent('STEER_DIALOG', { DEVICE_MAC: this.device.mac });
    this.openSteerModalEmitter.emit();
  }

  openPriorityModal(): void {
    this.mixpanel.storeEvent('DEVICES_PRIORITY_DIALOG', { DEVICE_MAC: this.device.mac });
    this.openPriorityModalEmitter.emit();
  }

  editTimeTemplate(timetemplate: any): void {
    this.freezeSchedule = {
      id: timetemplate.id,
      type: 'legacy',
      name: new FormControl({ value: timetemplate.name, disabled: true }),
      schedules: timetemplate.schedules.map((schedule: any) => ({
        startTime: new FormControl(schedule.times[0].start),
        endTime: new FormControl(schedule.times[0].end),
        selectedDays: [...schedule.daysOfWeek]
      })),
      mode: 'update'
    };

    this.showTemplateModal = true;
  }

  editCustomTimeTemplate(timetemplate: any): void {
    this.freezeSchedule = {
      id: timetemplate.id,
      type: 'custom',
      name: new FormControl(timetemplate.name),
      schedules: timetemplate.schedules.map((schedule: any) => ({
        startTime: new FormControl(schedule.startTime),
        endTime: new FormControl(schedule.endTime),
        selectedDays: [...schedule.daysOfWeek]
      })),
      mode: 'update'
    };

    this.showTemplateModal = true;
  }

  removeTimeTemplate(timetemplate: any): void {
    this.customerService.deleteSchedule$(timetemplate.id).subscribe(() => {
      this.getCustomFreezeTemplates();
    });
  }

  refreshDeviceFromFreezeTemplate(): void {
    this.deviceService.device$(this.device.mac).subscribe((response: any) => {
      if (this.location.isUtilizingSharedLocationFreezeSchedules) {
        this.getCustomFreezeTemplates(response.freeze);
      } else {
        this.device.freeze = response.freeze;
        this.checkResidentialGwManaged();
      }
    });
  }

  getDeviceState(): void {
    if (this.device.freeze) {
      this.checkResidentialGwManaged();
    }
  }

  checkResidentialGwManaged(): void {
    const residentialGwTemplate = this.device.freeze.timeTemplates.find(
      (template: any) => template.id === 'residentialGwManaged'
    );

    this.residentialGwManaged = residentialGwTemplate ? (residentialGwTemplate.enabled ? true : false) : false;
  }

  processDay(day: any): string {
    return moment().isoWeekday(day).format('ddd');
  }

  toggleFreeze(timetemplate: any): void {
    if (this.plume.isSomeAdminRole()) {
      if (timetemplate.id === 'residentialGwManaged') {
        this.residentialGwManaged = !timetemplate.enable;
      }

      this.deviceService.toggleFreeze$(this.device.mac, timetemplate).subscribe(
        (response) => {
          timetemplate.enable = !timetemplate.enable;

          if (timetemplate.enable) {
            if (this.location.isUtilizingSharedLocationFreezeSchedules) {
              this.getCustomFreezeTemplates(response);
            } else {
              this.device.freeze = response;
              this.getDeviceState();
            }
          } else {
            this.refreshDeviceFromFreezeTemplate();
          }

          this.mixpanel.storeEvent('DEVICE_FREEZE', {
            DEVICE_MAC: this.device.mac,
            TEMPLATE: timetemplate.name,
            ENABLE: timetemplate.enable
          });
          this.store.dispatch(pollingPull({ debugSource: 'device - toggle freeze' }));
        },
        (error) => {
          if (error.status === 422) {
            this.failedTimeTemplate(timetemplate);
          }

          this.store.dispatch(pollingPull({ debugSource: 'device - toggle freeze error' }));
        }
      );
    }
  }

  failedTimeTemplate(timetemplate: any): void {
    this.modal
      .showDialog('devices.device.freezeSetToPerson', 'devices.device.freezeSetToPersonTitle', {
        buttons: [
          { style: 'tertiary light', value: 'devices.device.reject' },
          { style: 'super-primary', value: 'devices.device.accept' }
        ]
      })
      .subscribe((response: any) => {
        if (response.item.value === 'devices.device.accept') {
          this.toggleFreezePerson(timetemplate);
        }
      });
  }

  freezeAutoExpireTimer(): void {
    clearInterval(this.freezeAutoExpireInterval);

    const expireAt = this.device?.freeze?.autoExpire?.enable && this.device?.freeze?.autoExpire?.expiresAt;

    if (!expireAt) {
      this.freezeAutoExpireDuration = 0;
      return;
    }

    this.freezeAutoExpireDuration = new Date(expireAt).getTime() - Date.now();

    if (this.freezeAutoExpireDuration > 0) {
      this.freezeAutoExpireInterval = setInterval(() => {
        this.freezeAutoExpireDuration = new Date(expireAt).getTime() - Date.now();

        if (this.freezeAutoExpireDuration <= 0) {
          this.freezeAutoExpireDuration = 0;
          clearInterval(this.freezeAutoExpireInterval);
        }
      }, 1000);
    }
  }

  updateAutoExpire(): void {
    let nextFreezeAutoExpireDuration = 0;

    if (!this.freezeAutoExpireDuration) {
      nextFreezeAutoExpireDuration = 15 * 60 * 1000;
    } else if (this.freezeAutoExpireDuration < 15 * 60 * 1000) {
      nextFreezeAutoExpireDuration = 30 * 60 * 1000;
    }

    this.deviceService.freezeAutoExpire$(this.device.mac, nextFreezeAutoExpireDuration).subscribe((response) => {
      if (this.location.isUtilizingSharedLocationFreezeSchedules) {
        this.getCustomFreezeTemplates(response, () => {
          this.getDeviceState();
          this.freezeAutoExpireTimer();
        });
      } else {
        this.device.freeze = response;
        this.getDeviceState();
        this.freezeAutoExpireTimer();
      }

      this.store.dispatch(pollingPull({ debugSource: 'device - update auto expire' }));
      this.mixpanel.storeEvent('DEVICE_FREEZE_SET_AUTO_EXPIRE', {
        DEVICE_MAC: this.device.mac,
        VALUE: nextFreezeAutoExpireDuration
      });
    });
  }

  toggleSuspend(): void {
    this.deviceService.suspend$(this.device.mac, !this.device.freeze.suspended.enable).subscribe((freeze) => {
      if (this.location.isUtilizingSharedLocationFreezeSchedules) {
        this.getCustomFreezeTemplates(freeze);
      } else {
        this.device.freeze = freeze;
      }
      this.store.dispatch(pollingPull({ debugSource: 'device - toggle suspend' }));
    });
  }

  toggleFreezePerson(timetemplate: any): void {
    if (this.plume.isSomeAdminRole()) {
      this.deviceService
        .freezePerson$(this.device.personId, !timetemplate.enable, timetemplate.id)
        .subscribe((freeze) => {
          timetemplate.enable = !timetemplate.enable;
          if (timetemplate.enable) {
            this.device.freeze = freeze;
            this.getDeviceState();
          } else {
            this.refreshDeviceFromFreezeTemplate();
            timetemplate.enable = false;
          }
          this.store.dispatch(pollingPull({ debugSource: 'device - toggle freeze person' }));
          this.mixpanel.storeEvent('PERSON_FREEZE', { PERSON: this.device.person, ENABLED: timetemplate.enable });
        });
    }
  }

  confirmRename(): void {
    const currentName = this.device.nickname || this.device.name;

    if (currentName !== this.name.value) {
      this.deviceService.rename$(this.device.mac, this.name.value).subscribe(
        () => {
          this.mixpanel.storeEvent('DEVICES_RENAME_SUCCESS', {
            DEVICE_MAC: this.device.mac,
            OLD_NAME: currentName,
            NEW_NAME: this.name.value
          });

          this.device.nickname = this.name.value;
          this.device.isRename = false;
          this.name.reset();

          this.store.dispatch(pollingPull({ debugSource: 'device - rename' }));

          this.toast.success('devices.device.successNameMessage', 'devices.device.successNameTitle');
        },
        (error: any) => {
          this.mixpanel.storeEvent('DEVICES_RENAME_ERROR', {
            DEVICE_MAC: this.device.mac,
            OLD_NAME: currentName,
            NEW_NAME: this.name.value,
            ERROR: error.error.error.message
          });

          this.device.isRename = false;
          this.name.reset();

          this.toast.error('devices.device.errorNameMessage', 'devices.device.errorNameTitle', {
            params: {
              error: error.error.error.message
            }
          });
        }
      );
    } else {
      setTimeout(() => {
        this.device.isRename = false;
        this.name.reset();
      }, 100);
    }
  }

  cancelRename(): void {
    this.mixpanel.storeEvent('DEVICES_RENAME_DISABLE', { DEVICE_MAC: this.device.mac });

    setTimeout(() => {
      this.device.isRename = false;
      this.name.reset();
    }, 100);
  }

  resetDevice(): void {
    this.troubleshoot.resetDevice(this.device.mac, this.device.leafToRoot[0].id, this.device.freqBand).subscribe(() => {
      this.toast.success('toast.deviceStrip.deviceResetMessage', 'toast.deviceStrip.deviceResetTitle');
      this.mixpanel.storeEvent('DEVICES_RESET_CONFIRM', {
        DEVICE_MAC: this.device.mac,
        PARENT_NODE: this.device.leafToRoot[0].id,
        DEVICE_FREQ_BAND: this.device.freqBand
      });
    });
  }

  getBandSteeringHistory(): void {
    this.deviceService.bandSteeringHistory$(this.device.mac).subscribe((bandsteering) => {
      this.device.bandsteering = this.qoe.calculateSteering(bandsteering, this.chartMode, 'bandsteering');
    });
  }

  getClientSteeringHistory(): void {
    this.deviceService.clientSteeringHistory$(this.device.mac).subscribe((clientsteering) => {
      this.device.clientsteering = this.qoe.calculateSteering(clientsteering, this.chartMode, 'clientsteering');
    });
  }

  blockDevice(mac: any): void {
    this.networkAccessService.block$(mac).subscribe(
      () => {
        this.store.dispatch(pollingPull({ debugSource: 'device - block device' }));
        this.mixpanel.storeEvent('SMALL_BUSINESS_DEVICE_BLOCK');
      },
      (error: any) => {
        this.mixpanel.storeEvent('SMALL_BUSINESS_DEVICE_BLOCK_FAILURE');
        this.toast.error(error.error.error.message, 'devices.device.failed');
      }
    );
  }

  unBlockDevice(mac: any): void {
    this.networkAccessService.unblock$(mac).subscribe(
      () => {
        this.store.dispatch(pollingPull({ debugSource: 'device - unblock device' }));
        this.mixpanel.storeEvent('SMALL_BUSINESS_DEVICE_UNBLOCK');
      },
      (error: any) => {
        this.mixpanel.storeEvent('SMALL_BUSINESS_DEVICE_UNBLOCK_FAILURE');
        this.toast.error(error.error.error.message, 'devices.device.failed');
      }
    );
  }

  approveDevice(): void {
    this.networkAccessService.approve$(this.device.mac, this.device.networkId).subscribe(
      () => {
        this.store.dispatch(pollingPull({ debugSource: 'device - approve device' }));
        this.mixpanel.storeEvent('SMALL_BUSINESS_DEVICE_APPROVE');
      },
      (error: any) => {
        this.mixpanel.storeEvent('SMALL_BUSINESS_DEVICE_APPROVE_FAILURE');
        this.toast.error(error.error.error.message, 'devices.device.failed');
      }
    );
  }
  unApproveDevice(): void {
    this.networkAccessService.unApprove$(this.device.mac, this.device.networkId).subscribe(
      () => {
        this.store.dispatch(pollingPull({ debugSource: 'device - unapprove device' }));
        this.mixpanel.storeEvent('SMALL_BUSINESS_DEVICE_UNAPPROVE');
      },
      (error: any) => {
        this.mixpanel.storeEvent('SMALL_BUSINESS_DEVICE_UNAPPROVE_FAILURE');
        this.toast.error(error.error.error.message, 'devices.device.failed');
      }
    );
  }

  deleteDevice(device: any): void {
    this.deviceService.delete$(device.mac).subscribe(
      () => {
        this.store.dispatch(pollingPull({ debugSource: 'device - delete device' }));
        this.toast.success('devices.device.deletedMessage', 'devices.device.deletedTitle', {
          params: {
            device: device.name
          }
        });
        this.mixpanel.storeEvent('DEVICE_DELETED', { DEVICE_MAC: this.device.mac });
      },
      (error: any) => {
        this.mixpanel.storeEvent('DEVICE_DELETED_FAILURE', { DEVICE_MAC: this.device.mac });
        this.toast.error(error.error.error.message, 'devices.device.deleteErrorTitle');
      }
    );
  }

  ngOnDestroy(): void {
    if (this.themeSubscription) {
      this.themeSubscription.unsubscribe();
    }

    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if (this.observer) {
      this.observer.disconnect();
    }
  }
}
