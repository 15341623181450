import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ɵbypassSanitizationTrustHtml as bypassSanitizationTrustHtml
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FlexService } from 'src/app/lib/services/flex.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { DataSetGroup, IntervalToolTipData } from 'src/app/lib/graph/graph.interface';
import { IFlexStatIpSec } from 'src/app/lib/interfaces/flex.interface';
import { map, share, startWith, switchMap } from 'rxjs/operators';
import { interval, Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as moment from 'moment';

@UntilDestroy()
@Component({
  templateUrl: './flex.component.html',
  styleUrls: ['./flex.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlexComponent implements OnInit, OnDestroy {
  reloadPeriod = 5 * 60 * 1000;
  historyDays = 30;
  noYearLocaleFormat = moment
    .localeData()
    .longDateFormat('LL')
    .replace(/[,\/-/.]*\s*Y+\s*/, '')
    .replace(/MMMM/, 'MMM');
  noMinutesLocaleFormat = moment.localeData().longDateFormat('LT').replace(/.mm/, '').replace(/ /, '');
  selectTimeValues = [
    { text: 'qoe.dataConsumption.30days', value: '30d' as const },
    { text: 'qoe.dataConsumption.24h', value: '24h' as const }
  ];

  uptime$ = interval(this.reloadPeriod).pipe(
    startWith(1),
    switchMap(() => this.flexService.getUptime$(moment().add(-15, 'minutes').toISOString(), moment().toISOString())),
    map((uptime) => ({
      ...uptime,
      refreshTime: moment(uptime?.range?.endTime ?? null)
        .format('LT')
        .toLowerCase()
        .replace(' ', '')
    })),
    share(),
    untilDestroyed(this)
  );

  sources = {
    ipsec: {
      selectedTime: '30d' as '30d' | '24h',
      graphDs$: null as Observable<DataSetGroup[]>,
      reloadTimeout: null,
      reloadSilent: false
    },
    radius: {
      selectedTime: '30d' as '30d' | '24h',
      graphDs$: null as Observable<DataSetGroup[]>,
      reloadTimeout: null,
      reloadSilent: false
    }
  };

  constructor(
    private flexService: FlexService,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private mixpanel: MixpanelService
  ) {}

  ngOnInit(): void {
    this.mixpanel.storeEvent('FLEX_SCREEN');

    this.setTimePeriod('ipsec', this.sources.ipsec.selectedTime);
    this.setTimePeriod('radius', this.sources.radius.selectedTime);
  }

  setTimePeriod(type: 'ipsec' | 'radius', value: '30d' | '24h'): void {
    this.sources[type].selectedTime = value;
    this.sources[type].reloadSilent = false;
    this.sources[type].graphDs$ = this.getDataByType(type, value).pipe(
      map((data) => (value === '30d' ? this.monthDataSource(data, type) : this.dayDataSource(data, type))),
      share()
    );

    clearTimeout(this.sources[type].reloadTimeout);

    if (value === '24h') {
      this.sources[type].reloadTimeout = setTimeout(() => {
        this.setTimePeriod(type, value);
        this.sources[type].reloadSilent = true;
        this.cdr.markForCheck();
      }, this.reloadPeriod);
    }
  }

  private getDataByType(type: 'ipsec' | 'radius', value: '30d' | '24h'): Observable<IFlexStatIpSec> {
    const { start, end } = this.getStartEndTime(value);

    if (type === 'ipsec') {
      return this.flexService.getIpSec(start, end);
    }

    return this.flexService.getRadiusServer(start, end);
  }

  private getStartEndTime(value: '30d' | '24h'): { start: string; end: string } {
    return {
      start:
        value === '30d'
          ? moment().add(-this.historyDays, 'days').startOf('day').toISOString()
          : moment().add(-23, 'hours').startOf('hours').toISOString(),
      end: value === '30d' ? moment().add(-1, 'days').endOf('day').toISOString() : moment().toISOString()
    };
  }

  private monthDataSource(data: IFlexStatIpSec, type: 'ipsec' | 'radius'): DataSetGroup[] {
    return [
      {
        dataSets: [
          {
            color: '',
            barTopColor: '',
            data: this.mapMonthData(data, type),
            type: 'segmentedIntervalBar',
            xStepVisualization: 'line',
            yStepVisualization: 'line',
            xTextPosition: 'forceStart',
            yTextPosition: 'start',
            hoverColor: '',
            yAxisCloseBar: true,
            xStart: 0,
            xEnd: this.historyDays - 1,
            yStart: 0,
            yEnd: 24,
            xLabelStart: 0,
            xLabelStep: 4,
            yLabelStart: 0,
            yLabelStep: 8,
            xValText: (val: number) =>
              `${moment()
                .add(-this.historyDays + val, 'days')
                .format(this.noYearLocaleFormat)}`,
            yValText: (val) =>
              `${moment().startOf('day').add(val, 'hours').format(this.noMinutesLocaleFormat).toLocaleLowerCase()}`,
            toolTipHtml: (data: IntervalToolTipData) => bypassSanitizationTrustHtml(this.toolTipMonth(data))
          }
        ],
        startFromZero: true,
        autoScale: false
      }
    ];
  }

  private mapMonthData(
    data: IFlexStatIpSec,
    type: 'ipsec' | 'radius'
  ): {
    xVal: number;
    yVal: number;
    yInterval: {
      start: number;
      end: number;
      color: string;
      dataObject: string;
    }[];
  }[] {
    const mapByDay = data?.data?.events?.reduce((acc, item) => {
      let start = moment(item.startTime);
      let startOfDay = start.clone().startOf('day').toISOString();

      const end = moment(item.endTime);
      const endOfDay = end.clone().startOf('day').toISOString();

      while (startOfDay < endOfDay) {
        if (!acc[startOfDay]) {
          acc[startOfDay] = [];
        }

        acc[startOfDay].push({
          from: start.hours() + start.minutes() / 60,
          to: 24,
          state: item.value
        });

        start = start.clone().startOf('day').add(1, 'day');
        startOfDay = start.toISOString();
      }

      if (!acc[startOfDay]) {
        acc[startOfDay] = [];
      }

      acc[startOfDay].push({
        from: start.hours() + start.minutes() / 60,
        to:
          end.hours() + end.minutes() === 0 && start.hours() + start.minutes() > 0
            ? 24
            : end.hours() + end.minutes() / 60,
        state: item.value
      });

      return acc;
    }, {} as { [date: string]: { from: number; to: number; state: string }[] });

    if (!mapByDay) {
      return [];
    }

    return Object.keys(mapByDay).map((key) => ({
      xVal: this.historyDays - moment().diff(key, 'days'),
      yVal: 0,
      yInterval: mapByDay[key].map((item) => ({
        start: item.from,
        end: item.to,
        color: `var(--${this.convertState(item.state)})`,
        dataObject: this.translate.instant(`flex.history.${type}.${item.state}`)
      }))
    }));
  }

  private toolTipMonth(data: IntervalToolTipData): string {
    return `
    <div class="flex-data-history-graph-tooltip">
      <span class="dateHeader">
      ${moment()
        .add(-this.historyDays + data.xVal, 'days')
        .format('LL')}
      </span>
      <br>
      <div class="line">
        <div class="circle" style="background: ${data.yInterval[data.activeYIntervalIndex].color}"></div>
        <div class="timeHeader" style="color: ${data.yInterval[data.activeYIntervalIndex].color};">
          ${data.yInterval[data.activeYIntervalIndex].dataObject}
        </div>
        <div class="time">
          ${moment()
            .startOf('day')
            .add(data.yInterval[data.activeYIntervalIndex].start, 'hours')
            .format('LT')
            .replace(' ', '')
            .toLowerCase()}
          -
          ${moment()
            .startOf('day')
            .add(data.yInterval[data.activeYIntervalIndex].end, 'hours')
            .format('LT')
            .replace(' ', '')
            .toLowerCase()}
        </div>
      </div>
    </div>`;
  }

  private dayDataSource(data: IFlexStatIpSec, type: 'ipsec' | 'radius'): DataSetGroup[] {
    return [
      {
        dataSets: [
          {
            color: '',
            barTopColor: '',
            data: this.mapDayData(data, type),
            type: 'segmentedIntervalBar',
            xStepVisualization: 'line',
            yStepVisualization: 'line',
            xTextPosition: 'forceStart',
            yTextPosition: 'start',
            hoverColor: '',
            yAxisCloseBar: true,
            xStart: 1,
            xEnd: 24,
            yStart: 0,
            yEnd: 60,
            xLabelStart: 1,
            xLabelStep: 2,
            yLabelStart: 0,
            yLabelStep: 15,
            xValText: (val: number) =>
              `${moment()
                .add(-24 + val, 'hours')
                .format(this.noMinutesLocaleFormat)}`.toLowerCase(),
            yValText: (val) => `${val === 60 ? 59 : val}m`.toLowerCase(),
            toolTipHtml: (data: IntervalToolTipData) => bypassSanitizationTrustHtml(this.toolTipDay(data))
          }
        ],
        startFromZero: true,
        autoScale: false
      }
    ];
  }

  private mapDayData(
    data: IFlexStatIpSec,
    type: 'ipsec' | 'radius'
  ): {
    xVal: number;
    yVal: number;
    yInterval: {
      start: number;
      end: number;
      color: string;
      dataObject: string;
    }[];
  }[] {
    const mapByHour = data?.data?.events?.reduce((acc, item) => {
      let start = moment(item.startTime);
      let startOfHour = start.clone().startOf('hour').toISOString();

      const end = moment(item.endTime);
      const endOfHour = end.clone().startOf('hour').toISOString();

      while (startOfHour < endOfHour) {
        if (!acc[startOfHour]) {
          acc[startOfHour] = [];
        }

        acc[startOfHour].push({
          from: start.minutes() + start.seconds() / 60,
          to: 60,
          state: item.value
        });

        start = start.clone().startOf('hour').add(1, 'hour');
        startOfHour = start.toISOString();
      }

      if (!acc[startOfHour]) {
        acc[startOfHour] = [];
      }

      acc[startOfHour].push({
        from: start.minutes() + start.seconds() / 60,
        to:
          end.minutes() + end.seconds() === 0 && start.minutes() + start.seconds() > 0
            ? 60
            : end.minutes() + end.seconds() / 60,
        state: item.value
      });

      return acc;
    }, {} as { [date: string]: { from: number; to: number; state: string }[] });

    return Object.keys(mapByHour).map((key) => ({
      xVal: 24 - moment().diff(key, 'hours'),
      yVal: 0,
      yInterval: mapByHour[key].map((item) => ({
        start: item.from,
        end: item.to,
        color: `var(--${this.convertState(item.state)})`,
        dataObject: this.translate.instant(`flex.history.${type}.${item.state}`)
      }))
    }));
  }

  convertState(state: string): string {
    switch (state) {
      case 'all_inactive':
        return 'graphError';
      case 'secondary_active':
        return 'graphWarning';
      case 'primary_active':
        return 'graphGood';
    }
  }

  toolTipDay(data: IntervalToolTipData): string {
    const tooltipHour = moment()
      .add(-24 + data.xVal, 'hours')
      .startOf('hour');

    return `
    <div class="flex-data-history-graph-tooltip">
      <span class="dateHeader">${tooltipHour.format('LL')}</span><br>
        <div class="line">
          <div class="circle" style="background: ${data.yInterval[data.activeYIntervalIndex].color}"></div>
          <div class="timeHeader" style="color: ${data.yInterval[data.activeYIntervalIndex].color};"> ${
      data.yInterval[data.activeYIntervalIndex].dataObject
    }</div>
          <div class="time">${tooltipHour
            .clone()
            .add(data.yInterval[data.activeYIntervalIndex].start, 'minutes')
            .format('LT')
            .replace(' ', '')
            .toLowerCase()} - ${tooltipHour
      .clone()
      .add(data.yInterval[data.activeYIntervalIndex].end, 'minutes')
      .format('LT')
      .replace(' ', '')
      .toLowerCase()}</div>
        </div>
      </div>`;
  }

  ngOnDestroy(): void {
    clearTimeout(this.sources['ipsec'].reloadTimeout);
    clearTimeout(this.sources['radius'].reloadTimeout);
  }
}
