import { Injectable } from '@angular/core';
import { LoggingService } from './logging.service';
import { ToastService } from './toast.service';

@Injectable()
export class CriticalService {
  networkDown: boolean = false;

  constructor(private logging: LoggingService, private toast: ToastService) {}

  setNetworkDown(status: boolean): void {
    if (status !== this.networkDown) {
      if (!status) {
        this.toast.success('toast.critical.successMessage', 'toast.critical.successTitle');
        this.logging.warn('Network is UP');
      } else {
        this.toast.error('toast.critical.errorMessage', 'toast.critical.errorTitle');
        this.logging.error('Network is DOWN. Unable to connect to internet');
      }
      this.networkDown = status;
    }
  }
}
