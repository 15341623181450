<div class="box" [ngClass]="mode">
  <div
    class="header"
    [class.collapsed]="!show"
    [class.collapsible]="collapsible"
    (click)="toggleShow($event)"
    *ngIf="showHeader"
  >
    <icon class="left" [class.open]="show" name="icon-arrow-down" *ngIf="collapsible && toggleSide === 'left'"></icon>
    <ng-content select="[header]"></ng-content>
    <icon class="right" [class.open]="show" name="icon-arrow-down" *ngIf="collapsible && toggleSide === 'right'"></icon>
  </div>
  <div class="divider" *ngIf="mode === 'bare' && showDivider"></div>
  <div class="container" [class.rounded]="!showHeader" *ngIf="show">
    <ng-content select="[container]"></ng-content>
  </div>
</div>
