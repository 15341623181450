import { scaleLinear } from 'd3';
import * as moment from 'moment';

export class TimelinesChart {
  private readonly dayViewMinutesInCluster = 5;
  private data: any[];
  private events: any;
  private width: number;
  private height: number;
  private xScale: any;
  private yScale: any;
  private day: any;
  private endDay: string;
  private margins: any = {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  };

  constructor() {}

  calculateWidth(): number {
    return this.width - this.margins.left - this.margins.right;
  }

  calculateHeight(): number {
    return this.height - this.margins.top - this.margins.bottom;
  }

  prepare(): void {
    if (this.day) {
      const date = moment(this.day.timestamp);

      const oneDay = date.clone().add(23, 'hours');

      const hour = 60 * 60 * 1000;

      this.xScale = scaleLinear()
        .domain([date.valueOf() - hour / 2, oneDay.valueOf() + hour / 2])
        .range([0, this.calculateWidth()]);
      this.yScale = scaleLinear().domain([hour, 0]).range([this.calculateHeight(), 0]);
    } else if (!this.endDay) {
      const today = moment();
      today.startOf('day');

      const monthAgo = today.clone().subtract(28, 'days');

      const day = 24 * 60 * 60 * 1000;

      this.xScale = scaleLinear()
        .domain([monthAgo.valueOf() - day / 2, today.valueOf() + day / 2])
        .range([0, this.calculateWidth()]);
      this.yScale = scaleLinear().domain([day, 0]).range([this.calculateHeight(), 0]);
    } else {
      const day = 24 * 60 * 60 * 1000;
      const endDay = moment(this.endDay).startOf('day');
      const startDay = endDay.clone().subtract(28, 'days');
      this.xScale = scaleLinear()
        .domain([startDay.valueOf() - day / 2, endDay.valueOf() + day / 2])
        .range([0, this.calculateWidth()]);
      this.yScale = scaleLinear().domain([day, 0]).range([this.calculateHeight(), 0]);
    }
  }

  calculateEvents(): void {
    const events = {};

    this.clusters().forEach((event: any) => {
      const timestamp = new Date(event.createdAt).getTime();
      const date = moment(timestamp)
        .startOf(this.day ? 'hour' : 'day')
        .valueOf();
      const time = timestamp - date;

      const newEvent = {
        ...event,
        y: this.yScale(time),
        type: event.eventType.toLowerCase()
      };

      if (event.eventType === 'cluster') {
        if (this.day) {
          newEvent.time = moment(timestamp)
            .subtract(this.dayViewMinutesInCluster / 2, 'minutes')
            .format('LT');
          newEvent.endTime = moment(timestamp)
            .add(this.dayViewMinutesInCluster / 2, 'minutes')
            .format('LT');
        } else {
          newEvent.time = moment(timestamp).subtract(30, 'minutes').format('LT');
          newEvent.endTime = moment(timestamp).add(30, 'minutes').format('LT');
        }
      } else {
        newEvent.time = moment(timestamp).format('LT');
      }

      if (events[date]) {
        events[date].unshift(newEvent);
      } else {
        events[date] = [newEvent];
      }
    });

    this.events = events;
  }

  clusters(): any {
    const clusters = {};
    const events = [];

    if (this.day) {
      this.data.forEach((event: any) => {
        const eventDate = moment(event.createdAt);
        const minuteStart = eventDate.clone().startOf('minute');
        minuteStart.minutes(
          Math.floor(minuteStart.minutes() / this.dayViewMinutesInCluster) * this.dayViewMinutesInCluster
        );

        if (clusters[minuteStart.valueOf()]) {
          clusters[minuteStart.valueOf()].unshift(event);
        } else {
          clusters[minuteStart.valueOf()] = [event];
        }
      });

      for (const date of Object.keys(clusters)) {
        if (clusters[date].length === 1) {
          events.push(clusters[date][0]);
        } else {
          events.push({
            createdAt: moment(parseInt(date, 10))
              .add(this.dayViewMinutesInCluster / 2, 'minutes')
              .toISOString(),
            events: clusters[date],
            eventType: 'cluster',
            clusterSize: clusters[date].length
          });
        }
      }

      return events;
    } else {
      this.data.forEach((event: any) => {
        const eventDate = moment(event.createdAt);
        const hourStart = eventDate.clone().startOf('hour');

        if (clusters[hourStart.valueOf()]) {
          clusters[hourStart.valueOf()].unshift(event);
        } else {
          clusters[hourStart.valueOf()] = [event];
        }
      });

      for (const date of Object.keys(clusters)) {
        if (clusters[date].length === 1) {
          events.push(clusters[date][0]);
        } else {
          events.push({
            createdAt: moment(parseInt(date, 10)).add(30, 'minutes').toISOString(),
            events: clusters[date],
            eventType: 'cluster',
            clusterSize: clusters[date].length
          });
        }
      }

      return events;
    }
  }

  calculateData(): any[] {
    const data = [];

    if (this.day) {
      const date = moment(this.day.timestamp);

      for (let i = 0; i < 24; i++) {
        const val = date.clone().add(i, 'hours');
        const half = val.clone().subtract(30, 'minutes');
        const hour = {
          x: this.xScale(half.valueOf()),
          y: 0,
          width: (this.xScale(val.valueOf()) - this.xScale(half.valueOf())) * 2,
          height: this.calculateHeight(),
          center: this.xScale(val.valueOf()),
          text: val.format('HH[h]'),
          timestamp: val.valueOf(),
          events: this.events[val.valueOf()] ? this.events[val.valueOf()] : [],
          allEvents: this.extractEvents(this.events[val.valueOf()])
        };

        data.push(hour);
      }
    } else if (!this.endDay) {
      const now = moment().startOf('day');

      for (let i = 0; i < 29; i++) {
        const val = now.clone().subtract(i, 'days');
        const half = val.clone().subtract(12, 'hours');
        const day = {
          x: this.xScale(half.valueOf()),
          y: 0,
          width: (this.xScale(val.valueOf()) - this.xScale(half.valueOf())) * 2,
          height: this.calculateHeight(),
          center: this.xScale(val.valueOf()),
          text: val.format('MMM D'),
          timestamp: val.valueOf(),
          events: this.events[val.valueOf()] ? this.events[val.valueOf()] : [],
          allEvents: this.extractEvents(this.events[val.valueOf()])
        };

        data.push(day);
      }
    } else {
      const endDay = moment(this.endDay).startOf('day');

      for (let i = 0; i < 29; i++) {
        const val = endDay.clone().subtract(i, 'days');
        const half = val.clone().subtract(12, 'hours');
        const day = {
          x: this.xScale(half.valueOf()),
          y: 0,
          width: (this.xScale(val.valueOf()) - this.xScale(half.valueOf())) * 2,
          height: this.calculateHeight(),
          center: this.xScale(val.valueOf()),
          text: val.format('MMM D'),
          timestamp: val.valueOf(),
          events: this.events[val.valueOf()] ? this.events[val.valueOf()] : [],
          allEvents: this.extractEvents(this.events[val.valueOf()])
        };

        data.push(day);
      }
    }

    return data;
  }

  extractEvents(events: any): any[] {
    const allEvents = [];

    if (events) {
      events.forEach((event: any) => {
        if (event.eventType === 'cluster') {
          event.events.forEach((event: any) => {
            allEvents.push(event);
          });
        } else {
          allEvents.push(event);
        }
      });
    }

    return allEvents;
  }

  xAxis(): any[] {
    if (this.day) {
      const date = moment(this.day.timestamp);

      return [
        { text: 'timelines.chart.h00', x: this.xScale(date.valueOf()), y: -10, dx: 0, anchor: 'middle' },
        {
          text: 'timelines.chart.h04',
          x: this.xScale(date.add(4, 'hours').valueOf()),
          y: -10,
          dx: 0,
          anchor: 'middle'
        },
        {
          text: 'timelines.chart.h08',
          x: this.xScale(date.add(4, 'hours').valueOf()),
          y: -10,
          dx: 0,
          anchor: 'middle'
        },
        {
          text: 'timelines.chart.h12',
          x: this.xScale(date.add(4, 'hours').valueOf()),
          y: -10,
          dx: 0,
          anchor: 'middle'
        },
        {
          text: 'timelines.chart.h16',
          x: this.xScale(date.add(4, 'hours').valueOf()),
          y: -10,
          dx: 0,
          anchor: 'middle'
        },
        {
          text: 'timelines.chart.h20',
          x: this.xScale(date.add(4, 'hours').valueOf()),
          y: -10,
          dx: 0,
          anchor: 'middle'
        },
        {
          text: 'timelines.chart.h23',
          x: this.xScale(date.add(3, 'hours').valueOf()),
          y: -10,
          dx: 0,
          anchor: 'middle'
        }
      ];
    } else if (!this.endDay) {
      const now = moment().startOf('day');
      const half = this.xScale(now.valueOf()) - this.xScale(now.clone().subtract(12, 'hours').valueOf());

      return [
        { text: 'timelines.chart.today', x: this.xScale(now.valueOf()), y: -10, dx: half, anchor: 'end' },
        {
          text: this.width > 550 ? 'timelines.chart.daysAgo' : '',
          x: this.xScale(now.subtract(7, 'days').valueOf()),
          y: -10,
          dx: 0,
          anchor: 'middle'
        },
        {
          text: 'timelines.chart.twoWeeks',
          x: this.xScale(now.subtract(7, 'days').valueOf()),
          y: -10,
          dx: 0,
          anchor: 'middle'
        },
        {
          text: this.width > 550 ? 'timelines.chart.threeWeeks' : '',
          x: this.xScale(now.subtract(7, 'days').valueOf()),
          y: -10,
          dx: 0,
          anchor: 'middle'
        },
        {
          text: 'timelines.chart.monthAgo',
          x: this.xScale(now.subtract(7, 'days').valueOf()),
          y: -10,
          dx: -half,
          anchor: 'start'
        }
      ];
    } else {
      const endDay = moment(this.endDay).startOf('day');
      const half = this.xScale(endDay.valueOf()) - this.xScale(endDay.clone().subtract(12, 'hours').valueOf());
      return [
        endDay,
        endDay.clone().subtract(7, 'days'),
        endDay.clone().subtract(14, 'days'),
        endDay.clone().subtract(21, 'days'),
        endDay.clone().subtract(28, 'days')
      ].map((date, index, array) => ({
        text: date.format('L'),
        x: this.xScale(date.valueOf()),
        y: -10,
        dx: index === 0 ? half : index === array.length - 1 ? -half : 0,
        anchor: index === 0 ? 'end' : index === array.length - 1 ? 'start' : 'middle'
      }));
    }
  }

  yAxis(): any[] {
    if (this.day) {
      return [
        { text: 'timelines.chart.min0', x: -10, y: this.yScale(0), dy: 6, anchor: 'end' },
        { text: 'timelines.chart.min15', x: -10, y: this.yScale(15 * 60 * 1000), dy: 6, anchor: 'end' },
        { text: 'timelines.chart.min30', x: -10, y: this.yScale(30 * 60 * 1000), dy: 6, anchor: 'end' },
        { text: 'timelines.chart.min45', x: -10, y: this.yScale(45 * 60 * 1000), dy: 6, anchor: 'end' },
        { text: 'timelines.chart.h1', x: -10, y: this.yScale(60 * 60 * 1000), dy: 3, anchor: 'end' }
      ];
    } else {
      return [
        { text: 'timelines.chart.h00', x: -10, y: this.yScale(0), dy: 6, anchor: 'end' },
        { text: 'timelines.chart.h08', x: -10, y: this.yScale(8 * 60 * 60 * 1000), dy: 6, anchor: 'end' },
        { text: 'timelines.chart.h16', x: -10, y: this.yScale(16 * 60 * 60 * 1000), dy: 6, anchor: 'end' },
        { text: 'timelines.chart.h24', x: -10, y: this.yScale(24 * 60 * 60 * 1000), dy: 3, anchor: 'end' }
      ];
    }
  }

  update(data: any[], width?: number, height?: number, margins?: any, day?: any, endDay?: string): void {
    this.data = data ? data : [];
    this.width = width ? width : 0;
    this.height = height ? height : 0;
    this.margins = margins ? margins : this.margins;
    this.day = day ? day : null;
    this.endDay = endDay;

    this.prepare();
    this.calculateEvents();
  }
}
