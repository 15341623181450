<div class="container-fluid">
  <ng-container *ngIf="onboarded == 'uncomplete'">
    <spacer>{{ 'completeOnboarding' | translate }}</spacer>
  </ng-container>
  <ng-container *ngIf="onboarded == 'complete'">
    <ng-container *ngIf="!(capabilities$ | async)?.wifiMotion?.capable">
      <spacer>{{ 'motion.noMotionAccess' + productName | translate }}</spacer>
    </ng-container>
    <ng-container *ngIf="(capabilities$ | async)?.wifiMotion?.capable">
      <ng-container *ngIf="loading">
        <spacer>{{ 'motion.loading' | translate }}</spacer>
      </ng-container>
      <ng-container *ngIf="enabledMotion == 'false' && !loading">
        <spacer>{{ 'motion.noMotionEnabled' + productName | translate }}</spacer>
      </ng-container>
      <ng-container *ngIf="enabledMotion == 'true' && !loading">
        <div class="controls">
          <spacer>{{ 'motion.settings.title' + productName | translate }}</spacer>
          <div class="motionSettings">
            <div class="labels">
              <div class="cell">{{ 'motion.settings.motionDetection' | translate }}</div>
              <div class="cell">
                {{ 'motion.settings.motionAlerts' + productName | translate }}
              </div>
              <div class="cell">{{ 'motion.settings.sensitivity' | translate }}</div>
              <div class="cell">{{ 'motion.settings.pets' | translate }}</div>
              <div class="cell">{{ 'motion.settings.timeBetweenAlerts' | translate }}</div>
            </div>
            <div class="selectors">
              <div class="cell">
                <slidetoggler
                  [options]="motionDetection"
                  [editable]="permissions?.uiFeatures?.editPlumeMotion"
                  (toggle)="updateSettings('wifiMotion', $event)"
                ></slidetoggler>
              </div>
              <div class="cell">
                <slidetoggler
                  [options]="motionEvents"
                  [editable]="permissions?.uiFeatures?.editPlumeMotion"
                  (toggle)="updateSettings('motionEvents', $event)"
                ></slidetoggler>
              </div>
              <div class="cell">
                <slidetoggler
                  [options]="sensitivity"
                  [editable]="permissions?.uiFeatures?.editPlumeMotion"
                  (toggle)="updateSettings('sensitivity', $event)"
                ></slidetoggler>
              </div>
              <div class="cell">
                <slidetoggler
                  [options]="petMode"
                  [editable]="permissions?.uiFeatures?.editPlumeMotion"
                  (toggle)="updateSettings('petMode', $event)"
                ></slidetoggler>
              </div>
              <div class="cell">
                <slidetoggler
                  [options]="cooldown"
                  [editable]="permissions?.uiFeatures?.editPlumeMotion"
                  (toggle)="updateSettings('cooldown', $event)"
                ></slidetoggler>
              </div>
            </div>
          </div>
        </div>
        <div class="events-list">
          <spacer>{{ 'motion.enabledDevices.title' | translate }}</spacer>
          <div class="list">
            <div class="headers">
              <div class="name">{{ 'motion.enabledDevices.name' | translate }}</div>
              <div class="mac">{{ 'motion.enabledDevices.mac' | translate }}</div>
              <div class="auto">{{ 'motion.enabledDevices.auto' | translate }}</div>
              <div class="enabled">{{ 'motion.enabledDevices.enabled' | translate }}</div>
            </div>
            <ng-container *ngIf="enabledDevices.length">
              <div class="device" *ngFor="let device of enabledDevices; trackBy: track">
                <div class="name">{{ device.name | macAddr: true }}</div>
                <div class="mac">{{ device.mac | macAddr }}</div>
                <div class="auto">
                  <slidetoggler
                    [options]="device.autoToggler"
                    [editable]="permissions?.uiFeatures?.editPlumeMotion"
                    (toggle)="device.auto = $event; updateSettings('enabledDevices', device)"
                  ></slidetoggler>
                </div>
                <div class="enabled">
                  <div *ngIf="device.enable">
                    <div class="circle green"></div>
                    <div>{{ 'motion.enabledDevices.on' | translate }}</div>
                  </div>
                  <div *ngIf="!device.enable">
                    <div class="circle red"></div>
                    <div>{{ 'motion.enabledDevices.off' | translate }}</div>
                  </div>
                </div>
              </div>
            </ng-container>
            <div class="message" *ngIf="!enabledDevices.length">
              {{ 'motion.noDevices' | translate }}
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
