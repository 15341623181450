import 'zone.js/plugins/zone-error';
import { server } from './shared/server';
import { environments } from './shared/environments';
import { version } from './shared/version';
import { okta } from './shared/okta';
import { firebase } from './shared/firebase';
import { mixpanel } from './shared/mixpanel';
import { pubnub } from './shared/pubnub';
import { languages } from './shared/languages';
import { metasaurus } from './shared/metasaurus';

export const environment = {
  production: false,
  ENV: 'Development',
  version,
  server,
  environments,
  okta,
  firebase,
  mixpanel,
  pubnub,
  languages,
  metasaurus
};
