<svg:g *ngFor="let xAxis of calculations.axis.xAxis">
  <svg:line
    class="axis"
    [attr.x1]="xAxis.startX"
    [attr.y1]="xAxis.y"
    [attr.x2]="xAxis.endX"
    [attr.y2]="xAxis.y"
  ></svg:line>
  <svg:line
    class="axis"
    *ngIf="xAxis.yClosing !== undefined"
    [attr.x1]="xAxis.startX"
    [attr.y1]="xAxis.yClosing"
    [attr.x2]="xAxis.endX"
    [attr.y2]="xAxis.yClosing"
  ></svg:line>
  <svg:g *ngFor="let line of xAxis.steps; let last = last; let first = first">
    <svg:line
      class="axis"
      [attr.x1]="line.xPos"
      [attr.y1]="xAxis.y"
      [attr.x2]="line.xPos"
      [attr.y2]="xAxis.yEnd"
    ></svg:line>
    <svg:foreignObject
      class="axisText"
      [class.start]="first && line.xPosText === line.xPos && xAxis.xTextPosition !== 'forceStart'"
      [class.end]="last && line.xPosText === line.xPos && xAxis.xTextPosition !== 'forceStart'"
      [class.stepOverflow]="xAxis.xTextStepOverflow"
      [class.multiline]="xAxis.xTextMultiline"
      [attr.x]="line.xPosText"
      [attr.y]="xAxis.y + 10"
      *ngIf="!last || line.xPosText === line.xPos"
    >
      <div
        xmlns="http://www.w3.org/1999/xhtml"
        [style.width.px]="xAxis.xTextMultiline && !xAxis.xTextStepOverflow ? getLabelWidth(xAxis) - 5 : ''"
      >
        <p>{{ line.text }}</p>
      </div>
    </svg:foreignObject>
  </svg:g>
</svg:g>
