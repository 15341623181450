import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Simulation } from 'd3';
import * as erdm from 'element-resize-detector';
import * as moment from 'moment';
import * as noUiSlider from 'nouislider';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { ForceChart } from 'src/app/lib/d3/models/charts/force.chart';
import { Link } from 'src/app/lib/d3/models/objects/link';
import { Node } from 'src/app/lib/d3/models/objects/node';
import { D3Service } from 'src/app/lib/d3/service';
import { GeneralHelper } from 'src/app/lib/helpers/general.helper';
import {
  DeepReadonly,
  IConfigAndState,
  IDevice,
  ILocation,
  INeighborReportCns,
  INode
} from 'src/app/lib/interfaces/interface';
import { CustomerService } from 'src/app/lib/services/customer.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { ThreadService } from 'src/app/lib/services/thread.service';
import { selectConfigAndState, selectPipeLocationOnChange } from 'src/app/store/customer/customer.selectors';
import { selectLteInUse, selectLteNode } from 'src/app/store/lte/lte.selectors';
import { selectDevices, selectNodes } from 'src/app/store/polling/polling.selector';
import { loadNeighborReportCns } from 'src/app/store/qoeCellular/qoeCellular.actions';
import { selectNeighborReportCns } from 'src/app/store/qoeCellular/qoeCellular.selector';

@UntilDestroy()
@Component({
  selector: 'forcechart',
  templateUrl: './forcechart.component.html',
  styleUrls: ['./forcechart.component.scss']
})
export class ForceChartVisualComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  nodes: Node[];
  links: Link[];
  rawNodes: INode[] = [];
  rawDevices: IDevice[] = [];

  searchStr: string = '';
  onlineDevices: number = 0;

  transform: any = {
    x: 0,
    y: 0,
    zoom: 1,
    offset: {
      x: 0,
      y: 0,
      zoom: 0,
      pageX: 0,
      pageY: 0,
      drag: false
    }
  };

  helper: GeneralHelper = new GeneralHelper();
  ui: string = '';
  location: DeepReadonly<ILocation> = {} as any;
  width: number = 0;
  height: number = 0;
  chart: ForceChart;
  simulation: Simulation<Node, Link>;
  erd: any = erdm({ strategy: 'scroll' });
  showChannel: boolean = true;
  showRadios: boolean = true;
  showSerial: boolean = false;
  showBandwidth: boolean = false;
  showAdvanced: boolean = false;
  showThreadNetwork: boolean = false;
  showRealtimeUsage: boolean = false;
  show5GNetwork: boolean = false;
  configAndState: IConfigAndState;
  ttmEndTime: number;
  ttmDuration: number = 3;
  ttmErrorDate: boolean = false;
  ready: boolean = false;
  history: any[] = [];
  isHistory: boolean = false;
  historySlider: any = {
    error: false,
    noData: false,
    open: false,
    min: 0,
    max: 0,
    timestamp: '',
    button: 'charts.force.history.loadData'
  };

  activeFilters: any[] = [];
  categoryFilters: any[] = [];

  overlayFilter: boolean = false;
  overlayData: string = 'none';

  zoomNoUiSlider: any = null;
  ttmNoUiSlider: any = null;
  advancedNoUiSlider: any = null;
  ttmCurrent: number = 0;

  smallbusinessItems: any = [];
  smallbusinessFilter: string = 'all';
  zoomScaling: number = 1; // determines mouse zoom speed
  dragInProgress = false;

  flexItems: any = [];
  flexFilter: string = 'all';
  flexSupportUser: boolean = false;

  haahsEnabled: boolean = false;
  haahsItems: any = [];
  haahsFilter: string = 'all';
  activeLteNode = '';
  getHistorySubscription: Subscription;
  powerMode = '';
  toolbarMenu: any = [];
  isIoT: boolean = false;
  toolbars: any = {
    views: { stackIndex: 35000, selected: false },
    overlays: { stackIndex: 35001, selected: false },
    timemachine: { stackIndex: 35002, selected: false },
    filters: { stackIndex: 35003, selected: false },
    realtimeUsage: { stackIndex: 35004, selected: false }
  };
  enable5gNeighborReports: boolean = false;
  neightborReportCns$: Observable<INeighborReportCns> | null;
  neightborReportCns: INeighborReportCns | null = null;
  tooltip: any = {
    show: false,
    name: null,
    id: null,
    mac: null
  };

  @Input()
  loading: boolean = true;

  @Input()
  nodesList: Node[];

  @Input()
  linksList: Link[];

  @Input()
  checkQoe: number;

  @Input()
  checkTopology: number;

  @Input()
  ttm: boolean;

  @Input()
  barebone: boolean;

  @Input()
  update: number;

  @Input()
  vpn: any;

  @Input()
  enableRealtime: boolean;

  @ViewChild('forcechart')
  container: any;

  @ViewChild('content')
  content: any;

  @ViewChild('ttmSlider')
  ttmSlider: any;

  @ViewChild('zoomSlider')
  zoomSlider: any;

  @ViewChild('advancedSlider')
  advancedSlider: any;

  @ViewChild('toolbar')
  toolbar: any;

  @HostListener('wheel', ['$event'])
  scroll(event: WheelEvent): void {
    if (!this.showRealtimeUsage) {
      event.preventDefault();

      this.transform.offset.zoom = this.transform.offset.zoom + event.deltaY * this.zoomScaling;

      if (this.transform.offset.zoom < 0) {
        this.transform.offset.zoom = 0;
      }

      if (this.transform.offset.zoom > 100) {
        this.transform.offset.zoom = 100;
      }

      this.zoomFit();

      if (this.zoomNoUiSlider) {
        this.zoomNoUiSlider.set(this.transform.offset.zoom);
      }
    }
  }

  constructor(
    private d3: D3Service,
    private customerService: CustomerService,
    private plume: PlumeService,
    private mixpanel: MixpanelService,
    private store: Store,
    private cd: ChangeDetectorRef,
    private thread: ThreadService,
    private translate: TranslateService
  ) {
    this.store.pipe(selectPipeLocationOnChange, untilDestroyed(this)).subscribe((location) => {
      this.location = location;
      this.isIoT = this.location.uprise && this.location.upriseConfig?.iotConfig?.iotNetwork;
    });
    this.chart = this.d3.generate('forcechart');
    this.neightborReportCns$ = this.store.pipe(select(selectNeighborReportCns));
  }

  ngOnInit(): void {
    this.simulation = this.chart.simulation;
    this.simulation.on('tick', () => this.tick());
    this.ui = this.plume.getUI();

    if (this.ui === 'noc') {
      this.showRadios = true;
    } else {
      this.showRadios = false;
    }

    this.initHaahs();
    this.initDeviceFilter();

    combineLatest([this.store.select(selectLteInUse), this.store.select(selectLteNode)])
      .pipe(
        filter(() => !this.history.length),
        untilDestroyed(this)
      )
      .subscribe(([inUse, lteNode]) => {
        this.activeLteNode = inUse && lteNode;
        const { nodes } = this.checkVapTypes(this.nodesList, this.linksList);
        this.nodes = this.recognizeNodes(nodes);
      });

    this.store
      .select(selectNodes)
      .pipe(
        filter(() => !this.history.length && !this.showThreadNetwork && !this.show5GNetwork),
        untilDestroyed(this)
      )
      .subscribe((rawNodes) => {
        this.rawNodes = rawNodes;
        const { nodes } = this.checkVapTypes(this.nodesList, this.linksList);
        this.nodes = this.recognizeNodes(nodes);
      });

    this.store
      .select(selectDevices)
      .pipe(untilDestroyed(this))
      .subscribe((rawDevices) => {
        this.rawDevices = rawDevices;
      });

    this.store
      .select(selectConfigAndState)
      .pipe(untilDestroyed(this))
      .subscribe((configAndState) => {
        this.configAndState = configAndState;
      });
    this.enable5gNeighborReports = this.plume.getPermissions().uiFeatures.qoe5gReports;
    if (this.enable5gNeighborReports && this.configAndState.state.capabilities?.cellularStatistics) {
      this.store.dispatch(loadNeighborReportCns());
      this.neightborReportCns$.subscribe((neighborReport) => {
        if (neighborReport) {
          this.neightborReportCns = neighborReport;
        }
      });
    }
  }

  ngAfterViewInit(): void {
    this.erd.listenTo(this.container.nativeElement, (element: any) => {
      const { width, height } = window.getComputedStyle(element);
      this.width = parseFloat(width);
      this.height = parseFloat(height);
      this.transform.x = this.width / 2;
      this.transform.y = this.height / 2;
      this.ready = true;
      this.tick();
    });

    if (sessionStorage.getItem('modalStack')) {
      this.toolbars = JSON.parse(sessionStorage.getItem('modalStack'));

      this.toolbarMenu.forEach((menu: any) => {
        menu.selected = this.toolbars[menu.action].selected;
      });

      this.cd.detectChanges();
    }

    if (this.toolbars.views.selected) {
      this.initZoomSlider();
    }
  }

  ngOnChanges(changes: any): void {
    if (changes.update) {
      this.initToolbars();
      this.initSmallbusiness();
      this.initFlex();
    }

    if (!this.history.length && !this.showThreadNetwork && !this.show5GNetwork) {
      const { nodes, links } = this.checkVapTypes(this.nodesList, this.linksList);
      // if checkVapTypes filters result change because new data are available for node without changing topology - f.e. after devices loaded.
      const topologyChangedByFilter =
        this.links && this.nodes && (this.nodes.length !== nodes.length || this.links.length !== links.length);

      this.nodes = this.recognizeNodes(nodes);
      this.links = links;

      this.deviceFilter();
      this.searchDevice();

      if (changes.checkTopology || topologyChangedByFilter) {
        this.chart.update(this.nodes, this.links, this.ui, this.width, this.height);
      }
    }
  }

  initDeviceFilter(): void {
    this.categoryFilters = [
      { value: 'Camera', translation: 'devices.filter.filterState.camera', count: 0, enabled: false },
      { value: 'Game Console', translation: 'devices.filter.filterState.gameConsole', count: 0, enabled: false },
      { value: 'Laptop', translation: 'devices.filter.filterState.laptop', count: 0, enabled: false },
      { value: 'Printer', translation: 'devices.filter.filterState.printer', count: 0, enabled: false },
      { value: 'Set Top Box', translation: 'devices.filter.filterState.stb', count: 0, enabled: false },
      { value: 'Smart Device', translation: 'devices.filter.filterState.smartDevice', count: 0, enabled: false },
      { value: 'Smart Phone', translation: 'devices.filter.filterState.smartPhone', count: 0, enabled: false },
      { value: 'Speaker', translation: 'devices.filter.filterState.speaker', count: 0, enabled: false },
      { value: 'Tablet', translation: 'devices.filter.filterState.tablet', count: 0, enabled: false },
      { value: 'Thermostat', translation: 'devices.filter.filterState.thermostat', count: 0, enabled: false },
      { value: 'TV', translation: 'devices.filter.filterState.tv', count: 0, enabled: false },
      { value: 'Voice Assistant', translation: 'devices.filter.filterState.voiceAssistant', count: 0, enabled: false },
      { value: null, translation: 'devices.filter.filterState.others', count: 0, enabled: false }
    ];
  }

  initToolbars(): void {
    this.toolbarMenu = [
      { title: 'modal.view', action: 'views', selected: false },
      ...(this.show5GNetwork ? [] : [{ title: 'modal.overlay', action: 'overlays', selected: false }]),
      ...(this.plume.isFlexRole() || this.show5GNetwork
        ? []
        : [{ title: 'modal.timemachine', action: 'timemachine', selected: false }]),
      ...(this.show5GNetwork ? [] : [{ title: 'modal.filters', action: 'filters', selected: false }]),
      ...(this.enableRealtime || !this.show5GNetwork
        ? [{ title: 'modal.realtimeUsage', action: 'realtimeUsage', selected: false }]
        : [])
    ];
  }

  toggleDeviceFilter(category: any): void {
    category.enabled = !category.enabled;
    this.searchStr = '';
    this.deviceFilter();
  }

  resetFilter(): void {
    this.activeFilters = [];
    this.categoryFilters.forEach((category) => (category.enabled = false));
  }

  clearSearch(): void {
    this.searchStr = '';
    this.searchDevice();
  }

  searchDevice(): void {
    if (this.searchStr.length) {
      this.resetFilter();
    }

    if (!this.activeFilters.length && this.rawDevices) {
      const filteredDevices = this.rawDevices.filter(
        (d: IDevice) =>
          (d.name?.toLowerCase() ? d.name.toLowerCase().includes(this.searchStr.toLowerCase()) : false) ||
          (d.nickname?.toLowerCase() ? d.nickname.toLowerCase().includes(this.searchStr.toLowerCase()) : false) ||
          Boolean(d.mac?.replace(/:/g, '').toLowerCase().includes(this.searchStr.replace(/:/g, '').toLowerCase()))
      );

      this.nodes.forEach((dev: Node) => {
        if (dev.options.type === 'device') {
          const found = filteredDevices.find((d) => dev.id === d.mac);

          dev.options.metadata.dimm = !found;
        } else {
          dev.options.metadata.dimm = false;
        }
      });
    }
  }

  deviceFilter(): void {
    this.activeFilters = this.categoryFilters.filter((filter: any) => {
      filter.count = 0;
      return filter.enabled;
    });

    this.nodes.forEach((node: Node) => {
      if (node.options.type === 'device') {
        node.options.metadata.dimm = true;

        let count = 0;

        this.categoryFilters.forEach((filter: any) => {
          if (node.options.category === filter.value) {
            count++;
            filter.count++;

            if (filter.enabled) {
              node.options.metadata.dimm = false;
            }
          }

          if (!filter.value && !count) {
            filter.count++;

            if (filter.enabled) {
              node.options.metadata.dimm = false;
            }
          }
        });

        if (!this.activeFilters.length) {
          node.options.metadata.dimm = false;
        }
      }
    });
  }

  stack(modal: string): void {
    const oldOrder = [];

    Object.keys(this.toolbars).forEach((toolbar: string) => {
      if (toolbar !== modal) {
        oldOrder.push({ key: toolbar, value: this.toolbars[toolbar].stackIndex });
      }
    });

    oldOrder
      .sort((a: any, b: any) => a.value - b.value)
      .forEach((toolbar: any, index: number) => {
        this.toolbars[toolbar.key].stackIndex = 35000 + index;
      });

    this.toolbars[modal].stackIndex = 35000 + oldOrder.length;

    sessionStorage.setItem('modalStack', JSON.stringify(this.toolbars));
  }

  toggleModal(modal: any): void {
    this.toolbars[modal.action].selected = modal.selected;
    sessionStorage.setItem('modalStack', JSON.stringify(this.toolbars));

    if (modal.action === 'views') {
      if (modal.selected) {
        setTimeout(() => {
          this.initZoomSlider();
        }, 100);
      } else {
        if (this.zoomNoUiSlider) {
          this.zoomNoUiSlider.destroy();
        }
      }
    }

    if (modal.action === 'timemachine') {
      if (modal.selected) {
        if (this.history.length) {
          setTimeout(() => {
            this.initTTMSlider();
          }, 100);
        }

        if (this.showAdvanced) {
          setTimeout(() => {
            this.initAdvancedSlider();
          });
        }
      } else {
        if (this.ttmNoUiSlider) {
          this.ttmNoUiSlider.destroy();
        }
        if (this.advancedNoUiSlider) {
          this.advancedNoUiSlider.destroy();
        }
      }
    }
  }

  initZoomSlider(): void {
    this.zoomNoUiSlider = noUiSlider.create(this.zoomSlider.nativeElement, {
      start: this.transform.offset.zoom,
      step: 1,
      range: {
        min: 0,
        max: 100
      }
    });
    this.zoomNoUiSlider.on('update', (values: any) => {
      this.zoom(parseInt(values[0], 10));
    });
  }

  initTTMSlider(): void {
    this.ttmNoUiSlider = noUiSlider.create(this.ttmSlider.nativeElement, {
      start: this.ttmCurrent,
      step: 1,
      range: {
        min: 0,
        max: this.history.length - 1
      }
    });
    this.ttmNoUiSlider.on('update', (values: any) => {
      const value = parseInt(values[0], 10);
      if (this.ttmCurrent !== value) {
        this.ttmCurrent = value;
        this.renderHistory(this.history[this.ttmCurrent]);
      }
    });
  }

  initAdvancedSlider(): void {
    this.advancedNoUiSlider = noUiSlider.create(this.advancedSlider.nativeElement, {
      start: this.ttmDuration,
      step: 1,
      range: {
        min: 1,
        max: 24
      }
    });
    this.advancedNoUiSlider.on('update', (values: any) => {
      this.ttmDuration = parseInt(values[0], 10);
    });
  }

  tick(): void {
    if (!this.tooltip?.show && !this.dragInProgress) {
      this.zoomFit();
    }

    this.links?.forEach((link: Link) => {
      link.options.radio = this.checkRadio(link);
      if (Array.isArray(link.options?.metadata?.mloConfig?.multiLinks)) link.options.isMlo = true;

      if (this.ui !== 'noc' || link.options.radio === 'ethernet') {
        link.options.lines = this.calculateLines(link);
      }

      if (this.ui === 'noc' && link.options.radio !== 'ethernet') {
        link.options.waves = this.calculateWaves(link);
      }
    });
  }

  mapFrequencyBandToRadio(freqBand: string): string | undefined {
    const freqBandToRadioMap = {
      '6G': 'radio6g',
      '5G': 'radio5g',
      '5GL': 'radio5g',
      '5GU': 'radio5g',
      '2.4G': 'radio2g'
    };
    return freqBandToRadioMap[freqBand];
  }

  checkRadio(link: Link): string {
    if (link.options) {
      if (link.options.metadata?.freqBand) {
        return this.mapFrequencyBandToRadio(link.options.metadata.freqBand);
      }

      if (link.options.medium === 'vpn') {
        return 'vpn';
      }
    }

    return 'ethernet';
  }

  calculateWaves(link: Link): { d: string; radio: string; channel: number }[] {
    const startPoint = { x: 0, y: 0 };
    const endPoint = {
      x: link.target.x - link.source.x,
      y: link.target.y - link.source.y
    };

    const length = Math.sqrt(Math.pow(endPoint.x - startPoint.x, 2) + Math.pow(endPoint.y - startPoint.y, 2));
    const angle = Math.atan2(endPoint.y - startPoint.y, endPoint.x - startPoint.x);
    const freq = this.getWaveFreqForRadio(link.options.radio);
    const resolution = this.getWaveResolutionForRadio(link.options.radio);
    const amplitude = 5;

    const waves: { d: string; radio: string; channel: number }[] = [];

    if (link.options?.isMlo) {
      const multiLinks = link.options.metadata.mloConfig.multiLinks;

      multiLinks.sort((a, b) => {
        if (a.freqBand < b.freqBand) return -1;
        if (a.freqBand > b.freqBand) return 1;
        return 0;
      });

      multiLinks.forEach((multiLink, index) => {
        let d = '';
        const radio = this.mapFrequencyBandToRadio(multiLink.freqBand);
        const freq = this.getWaveFreqForRadio(radio);
        const resolution = this.getWaveResolutionForRadio(radio);
        for (let i = 0; i < length; i += resolution) {
          const tempX = startPoint.x + i;
          let tempY = (amplitude / multiLinks.length) * Math.sin(tempX / freq) + startPoint.y;

          if (multiLinks.length === 2) {
            if (index === 1) tempY += (amplitude / multiLinks.length) * 2;
            if (index === 0) tempY -= (amplitude / multiLinks.length) * 2;
          }

          if (multiLinks.length === 3) {
            if (index === 0) tempY += (amplitude / multiLinks.length) * 4;
            if (index === 2) tempY -= (amplitude / multiLinks.length) * 4;
          }

          const x = tempX * Math.cos(angle) - tempY * Math.sin(angle) + link.source.x;
          const y = tempY * Math.cos(angle) + tempX * Math.sin(angle) + link.source.y;

          if (i === 0) {
            d = 'M' + x + ',' + y;
          } else {
            d += ' L' + x + ',' + y;
          }
        }
        waves.push({ d, radio, channel: multiLink.channel });
      });
    } else {
      let d = '';
      for (let i = 0; i < length; i += resolution) {
        const tempX = startPoint.x + i;
        const tempY = amplitude * Math.sin(tempX / freq) + startPoint.y;

        const x = tempX * Math.cos(angle) - tempY * Math.sin(angle) + link.source.x;
        const y = tempY * Math.cos(angle) + tempX * Math.sin(angle) + link.source.y;

        if (i === 0) {
          d = 'M' + x + ',' + y;
        } else {
          d += ' L' + x + ',' + y;
        }
      }
      waves.push({ d, radio: link.options.radio, channel: link.options.metadata.channel });
    }

    return waves;
  }

  calculateLines(link: Link): { class: string; x1: number; y1: number; x2: number; y2: number }[] {
    if (link.options?.isMlo) {
      const multiLinks = link.options?.metadata?.mloConfig?.multiLinks;

      const lines = [];

      multiLinks.forEach((multiLink, index) => {
        const radio = this.mapFrequencyBandToRadio(multiLink.freqBand);
        let y1 = link.source.y;
        let y2 = link.target.y;

        if (multiLinks.length === 2) {
          if (index === 0) {
            y1 += 3;
            y2 += 3;
          }
          if (index === 1) {
            y1 -= 3;
            y2 -= 3;
          }
        }

        if (multiLinks.length === 3) {
          if (index === 0) {
            y1 += 6;
            y2 += 6;
          }
          if (index === 1) {
            y1 -= 6;
            y2 -= 6;
          }
        }

        lines.push({
          class: radio,
          x1: link.source.x,
          y1,
          x2: link.target.x,
          y2
        });
      });
      return lines;
    } else {
      return [
        {
          class: link.options?.radio,
          x1: link.source.x,
          y1: link.source.y,
          x2: link.target.x,
          y2: link.target.y
        }
      ];
    }
  }

  hasMloLinks(): boolean {
    const linkWithMlo = this.links.find((link) => link.options?.metadata?.mloConfig?.multiLinks?.length);
    return Boolean(linkWithMlo);
  }

  reset(): void {
    this.transform.offset.x = 0;
    this.transform.offset.y = 0;
    this.transform.offset.zoom = 0;
    this.zoomFit();

    if (!this.barebone) {
      this.zoomNoUiSlider.set(0);
    }
  }

  zoom(value: number): void {
    this.transform.offset.zoom = value;
    this.zoomFit();
  }

  pan(mode: string, event: any): void {
    const clientX = event.clientX || event.changedTouches?.[0]?.clientX;
    const clientY = event.clientY || event.changedTouches?.[0]?.clientY;
    switch (mode) {
      case 'enter':
        this.transform.offset.drag = true;
        this.transform.offset.pageX = clientX;
        this.transform.offset.pageY = clientY;
        const e = event;
        this.pauseEvent(e);
        break;

      case 'move':
        if (this.transform.offset.drag) {
          this.transform.offset.x -= this.transform.offset.pageX
            ? this.transform.offset.pageX - clientX
            : this.transform.offset.pageX;
          this.transform.offset.y -= this.transform.offset.pageY
            ? this.transform.offset.pageY - clientY
            : this.transform.offset.pageY;

          this.zoomFit();

          this.transform.offset.pageX = clientX;
          this.transform.offset.pageY = clientY;
        }
        break;

      case 'leave':
        this.transform.offset.drag = false;
        break;
    }
  }

  zoomFit(paddingPercent: number = 0.9): any {
    try {
      const bounds = this.content.nativeElement.getBBox();
      const elementBounds = this.container.nativeElement.getBoundingClientRect();
      const width = bounds.width;
      const height = bounds.height;
      const midX = bounds.x + width / 2;
      const midY = bounds.y + height / 2;

      if (width === 0 || height === 0) {
        return;
      }

      let scale = (paddingPercent || 0.75) / Math.max(width / elementBounds.width, height / elementBounds.height);

      if (scale > 1) {
        scale = 1;
      }

      this.transform.zoom = scale + this.transform.offset.zoom / 100;
      this.transform.x = elementBounds.width / 2 - this.transform.zoom * midX + this.transform.offset.x;
      this.transform.y = elementBounds.height / 2 - this.transform.zoom * midY + this.transform.offset.y;
    } catch (e) {
      return;
    }
  }

  pauseEvent(event: any): boolean {
    if (event.stopPropagation) {
      event.stopPropagation();
    }
    if (event.preventDefault) {
      event.preventDefault();
    }
    event.cancelBubble = true;
    event.returnValue = false;
    return false;
  }

  status(node: Node): string {
    if (node.options) {
      if (node.options.health) {
        return node.options.health.status;
      }
    }
    return '';
  }

  toggleChannel(): void {
    this.showChannel = !this.showChannel;

    if (this.showChannel) {
      this.showBandwidth = false;
    }
  }

  toggleSerial(): void {
    this.showSerial = !this.showSerial;
  }

  toggleRadios(): void {
    this.showRadios = !this.showRadios;
  }

  toggleBandwidth(): void {
    this.showBandwidth = !this.showBandwidth;

    if (this.showBandwidth) {
      this.showChannel = false;
    }
  }

  toggleOverlayFilter(mode?: string): void {
    if (mode) {
      if (this.overlayData === mode) {
        this.overlayData = 'none';
      } else {
        this.overlayData = mode;
        this.mixpanel.storeEvent('TOPOLOGY_OVERLAY_ACTIVE', { OVERLAY_MODE: mode });
      }
    } else {
      this.overlayFilter = !this.overlayFilter;

      if (!this.overlayFilter) {
        this.overlayData = 'none';
      } else {
        this.mixpanel.storeEvent('TOPOLOGY_OVERLAY_DIALOG');
      }
    }

    this.nodes = this.nodes.map((node: Node) => {
      node.overlays = this.setOverlays(node, this.nodes);
      return node;
    });
  }

  toggleRealtimeUsage(): void {
    this.showRealtimeUsage = !this.showRealtimeUsage;

    if (this.showRealtimeUsage) {
      this.toolbarMenu.forEach((item) =>
        item.action !== 'realtimeUsage' ? (item.hiden = true) : (item.hiden = false)
      );

      this.toolbarMenu.forEach((item) => {
        if (item.action !== 'realtimeUsage') {
          item.hiden = true;

          if (this.toolbars[item.action].selected) {
            this.toolbar.itemSelect(item);
          }
        } else {
          item.hiden = false;
        }
      });
    } else {
      this.toolbarMenu.forEach((item) => (item.hiden = false));
    }
  }

  toggleThreadNetwork(): void {
    this.showThreadNetwork = !this.showThreadNetwork;

    if (this.showThreadNetwork) {
      this.loading = true;
      this.nodes = [];
      this.links = [];

      this.toolbars.overlays.selected = false;
      this.toolbars.timemachine.selected = false;
      this.toolbars.filters.selected = false;
      this.toolbars.realtimeUsage.selected = false;

      this.toolbarMenu.forEach((item) => {
        if (item.action !== 'views') {
          item.hiden = true;

          if (this.toolbars[item.action].selected) {
            this.toolbar.itemSelect(item);
          }
        } else {
          item.hiden = false;
        }
      });

      this.fetchThreadNetwork();
    } else {
      this.loading = false;
      this.toolbarMenu.forEach((item) => (item.hiden = false));
      this.ngOnChanges({});
    }
  }

  toggle5GNetwork(): void {
    this.show5GNetwork = !this.show5GNetwork;
    const nodeId = this.neightborReportCns?.nodeId;
    const sourceNode = this.rawNodes.find((node) => node.id === nodeId);
    const model = sourceNode ? sourceNode.model : 'PP203X'; // Default
    const destinationId = this.neightborReportCns?.serving_cell_pcid;
    const connectionList = [{ nodeId, destinationId: destinationId.toString() }];

    if (this.show5GNetwork) {
      const mainNode = new Node(
        this.neightborReportCns.nodeId,
        {
          id: this.neightborReportCns.nodeId,
          name: sourceNode?.nickname || sourceNode?.defaultName || this.neightborReportCns.nodeId,
          type: 'pod',
          metadata: {
            model,
            RSSI: this.neightborReportCns.serving_cell_rssi,
            networkMode: 'router',
            neighborMode5G: true,
            SINR: this.neightborReportCns.serving_cell_sinr
          },
          label: sourceNode?.nickname || sourceNode?.defaultName || this.neightborReportCns.nodeId
        },
        [
          {
            class: 'success',
            icon: 'icon-5g-online',
            horizontal: 'right',
            vertical: 'top'
          }
        ]
      );
      const neighborNodes = this.neightborReportCns.neighbors.map((neighbor) => {
        const isLTE = neighbor?.cell_mode?.startsWith('LTE');
        const metadata = {
          model: 'radiotower',
          networkMode: 'router',
          neighborMode5G: true
        };
        if (neighbor.cell_mode) {
          metadata['connectionType'] = neighbor.cell_mode;
        }
        if (neighbor.rsrp) {
          metadata['RSRP'] = neighbor.rsrp;
        }
        if (neighbor.rsrq) {
          metadata['RSRQ'] = neighbor.rsrq;
        }
        if (neighbor.estimated_throughput) {
          metadata['estimated_throughput'] = neighbor.estimated_throughput;
        }
        if (neighbor.operator_name) {
          metadata['operator_name'] = neighbor.operator_name;
        }
        if (neighbor.freq_band) {
          metadata['freq_band'] = neighbor.freq_band;
        }
        if (neighbor.bandwidth) {
          metadata['bandwidth'] = neighbor.bandwidth;
        }
        if (neighbor.mnc) {
          metadata['mnc'] = neighbor.mnc;
        }
        if (neighbor.mcc) {
          metadata['mcc'] = neighbor.mcc;
        }
        return new Node(
          neighbor.pcid.toString(),
          {
            id: neighbor.pcid.toString(),
            type: 'pod',
            connectionState: 'connected',
            metadata,
            label: 'PCID ' + neighbor.pcid
          },
          [
            {
              class: 'success',
              icon: isLTE ? 'icon-lte-online' : 'icon-5g-online',
              horizontal: 'right',
              vertical: 'top'
            }
          ]
        );
      });
      this.nodes = [mainNode, ...neighborNodes];
      this.links = connectionList.map(
        (link) =>
          new Link(
            this.nodes.find((node) => node.id === link.nodeId),
            this.nodes.find((node) => node.id === link.destinationId),
            {
              source: link.nodeId,
              target: link.destinationId
            }
          )
      );

      this.chart.update(this.nodes, this.links, this.ui, this.width, this.height);
    } else {
      this.ngOnChanges({});
    }
  }

  fetchThreadNetwork(): void {
    this.loading = true;

    this.thread.getNetwork$().subscribe((topology) => {
      if (this.showThreadNetwork) {
        this.nodes = topology.vertices.map(
          (vertice) =>
            new Node(vertice.id.toString(), {
              id: vertice.id.toString(),
              type: vertice.type,
              metadata: {
                ...vertice.metadata,
                threadNetwork: true
              },
              label:
                vertice.type === 'device'
                  ? this.translate.instant('charts.force.threadDevice')
                  : this.translate.instant('charts.force.threadRouter')
            })
        );

        this.links = topology.edges
          .map((edge) => {
            const source = this.nodes.find((node) => node.id === edge.source.toString()) || null;
            const target = this.nodes.find((node) => node.id === edge.target.toString()) || null;

            if (source && target) {
              return new Link(source, target, {
                direction: 'sourceToTarget',
                medium: 'wifi',
                source: edge.source,
                target: edge.target,
                metadata: edge.metadata
              });
            } else {
              return null;
            }
          })
          .filter((edge) => edge !== null);

        this.chart.update(this.nodes, this.links, this.ui, this.width, this.height);
        this.loading = false;
      }
    });
  }

  initSmallbusiness(): void {
    this.smallbusinessItems = [];
    this.smallbusinessFilter = 'all';

    if (this.location?.profile !== 'auto') {
      this.smallbusinessItems = [
        { value: 'all', translation: 'devices.all', selected: this.smallbusinessFilter === 'all' },
        { value: 'home', translation: 'devices.home', selected: this.smallbusinessFilter === 'home' },
        ...(this.location.profile === 'property'
          ? []
          : [
              {
                value: 'fronthaul',
                translation: 'devices.fronthaul',
                selected: this.smallbusinessFilter === 'fronthaul'
              }
            ]),
        {
          value: 'captivePortal',
          translation: this.location.profile === 'property' ? 'devices.propertyCaptivePortal' : 'devices.captivePortal',
          selected: this.smallbusinessFilter === 'captivePortal'
        },
        ...(this.isIoT
          ? [
              {
                value: 'iot',
                translation: 'devices.iot',
                selected: this.smallbusinessFilter === 'iot'
              }
            ]
          : [])
      ];

      this.setSmallBusinessString();
    } else {
      if (this.isIoT) {
        this.smallbusinessItems = [
          { value: 'all', translation: 'devices.all', selected: this.smallbusinessFilter === 'all' },
          {
            value: 'iot',
            translation: 'devices.iot',
            selected: this.smallbusinessFilter === 'iot'
          }
        ];
        this.setSmallBusinessString();
      }
    }
  }

  initFlex(): void {
    this.flexItems = [];
    this.flexSupportUser = this.plume.isFlexRole();

    if (this.location?.flex) {
      this.flexItems = [
        { value: 'all', translation: 'flex.toggler.all', selected: true },
        { value: 'home', translation: 'flex.toggler.home', selected: false },
        { value: 'work', translation: 'flex.toggler.work', selected: false }
      ];

      this.changeFlexFilter(this.flexSupportUser ? 'work' : 'all');
    }
  }

  setFlexString(): void {
    if (this.location?.flex) {
      this.toolbarMenu[0].subtitle =
        this.flexItems.find((item: any) => item.value === this.flexFilter).translation || null;
    }
  }

  setSmallBusinessString(): void {
    if (this.location?.profile !== 'auto') {
      this.toolbarMenu[0].subtitle =
        this.smallbusinessItems.find((item: any) => item.value === this.smallbusinessFilter).translation || null;
    }
  }

  setHaahsString(): void {
    if (this.haahsEnabled) {
      this.toolbarMenu[0].subtitle =
        this.haahsItems.find((item: any) => item.value === this.haahsFilter).translation || null;
    }
  }

  changeVapFilter(event: any): void {
    this.smallbusinessFilter = event;

    let nodesList = [];
    let linksList = [];

    if (this.historySlider.open) {
      nodesList = this.history[this.ttmCurrent].nodes;
      linksList = this.history[this.ttmCurrent].links;
    } else {
      nodesList = this.nodesList;
      linksList = this.linksList;
    }

    const { nodes, links } = this.checkVapTypes(nodesList, linksList);

    this.nodes = this.recognizeNodes(nodes);
    this.links = links;
    this.chart.update(this.nodes, this.links, this.ui, this.width, this.height);
    this.setSmallBusinessString();
  }

  changeFlexFilter(event: any): void {
    this.flexFilter = event;

    let nodesList = [];
    let linksList = [];

    if (this.historySlider.open) {
      nodesList = this.history[this.ttmCurrent].nodes;
      linksList = this.history[this.ttmCurrent].links;
    } else {
      nodesList = this.nodesList;
      linksList = this.linksList;
    }

    const { nodes, links } = this.checkVapTypes(nodesList, linksList);

    this.nodes = this.recognizeNodes(nodes);
    this.links = links;
    this.chart.update(this.nodes, this.links, this.ui, this.width, this.height);
    this.setFlexString();
  }

  changeHaahsFilter(event: any): void {
    this.haahsFilter = event;

    let nodesList = [];
    let linksList = [];

    if (this.historySlider.open) {
      nodesList = this.history[this.ttmCurrent].nodes;
      linksList = this.history[this.ttmCurrent].links;
    } else {
      nodesList = this.nodesList;
      linksList = this.linksList;
    }

    const { nodes, links } = this.checkVapTypes(nodesList, linksList);

    this.nodes = this.recognizeNodes(nodes);
    this.links = links;
    this.chart.update(this.nodes, this.links, this.ui, this.width, this.height);
    this.setHaahsString();
  }

  initHaahs(): void {
    this.haahsEnabled = this.location.haahs?.enable || false;
    this.haahsItems = [
      { value: 'all', translation: 'devices.vapAll', selected: this.haahsFilter === 'all' },
      { value: 'home', translation: 'devices.vapHome', selected: this.haahsFilter === 'home' },
      { value: 'haahs', translation: 'devices.vapHaahs', selected: this.haahsFilter === 'haahs' }
    ];
    this.setHaahsString();
  }

  checkHaahsStatus(vapType: string): boolean {
    return this.haahsFilter === 'all' || this.haahsFilter === vapType;
  }

  checkVapTypes(nodes: any[], links: any[]): any {
    if (this.isIoT && this.smallbusinessFilter === 'iot') {
      nodes = nodes.filter((node: Node) => {
        if (node.options?.type === 'device') {
          return node.options?.metadata?.networkId === 'iot';
        } else {
          return true;
        }

        return true;
      });
      links = links.filter((link: Link) => {
        if (link.target?.options?.type === 'device') {
          return link.target.options?.metadata?.networkId === 'iot';
        } else {
          return true;
        }
        return true;
      });
    }

    if (this.haahsEnabled) {
      nodes = nodes.filter((node: Node) => {
        if (node.options?.type === 'device') {
          return this.checkHaahsStatus(node.options.metadata.vapType);
        } else {
          return true;
        }
      });

      links = links.filter((link: Link) => {
        if (link.target?.options?.type === 'device') {
          return this.checkHaahsStatus(link.target.options.metadata.vapType);
        } else {
          return true;
        }
      });
    }

    if (this.location?.profile !== 'auto') {
      nodes = nodes.filter((node: Node) => {
        if (node.options?.type === 'device') {
          if (
            node.options.metadata.vapType === this.smallbusinessFilter ||
            this.smallbusinessFilter === 'all' ||
            (this.smallbusinessFilter === 'iot' && node.options.metadata.networkId === 'iot')
          ) {
            return true;
          } else {
            if (this.smallbusinessFilter === 'home' && !node.options.metadata.vapType) {
              return true;
            } else {
              return false;
            }
          }
        } else {
          return true;
        }
      });

      links = links.filter((link: Link) => {
        if (link.target?.options?.type === 'device') {
          if (
            link.target.options.metadata.vapType === this.smallbusinessFilter ||
            this.smallbusinessFilter === 'all' ||
            (this.smallbusinessFilter === 'iot' && link.target.options.metadata.networkId === 'iot')
          ) {
            return true;
          } else {
            if (this.smallbusinessFilter === 'home' && link.options.medium === 'ethernet') {
              return true;
            } else {
              return false;
            }
          }
        } else {
          return true;
        }
      });
    }

    if (this.location?.flex) {
      nodes = nodes.filter((node: Node) => {
        if (node.options?.type === 'device') {
          if (this.flexFilter === 'all') {
            return true;
          }

          if (this.flexFilter === 'home' && node.options.networkId !== 'flex') {
            return true;
          }

          if (this.flexFilter === 'work' && node.options.networkId === 'flex') {
            return true;
          }

          return false;
        } else {
          return true;
        }
      });

      links = links.filter((link: Link) => {
        if (link.target?.options?.type === 'device') {
          if (this.flexFilter === 'all') {
            return true;
          }

          if (this.flexFilter === 'home' && link.target.options.networkId !== 'flex') {
            return true;
          }

          if (this.flexFilter === 'work' && link.target.options.networkId === 'flex') {
            return true;
          }

          return false;
        } else {
          return true;
        }
      });
    }

    return { nodes, links };
  }

  trackNodes(index: number, node: any): string {
    return node.id;
  }

  getDate(timestamp: string, mode: string = null): string {
    return moment
      .utc(timestamp)
      .local()
      .format(mode ? mode : 'L LTS');
  }

  getWaveFreqForRadio(radio: string): number {
    switch (radio) {
      case 'radio2g':
        return 3.5;
      case 'radio5g':
        return 1.5;
      case 'radio6g':
        return 0.6;
    }
  }

  getWaveResolutionForRadio(radio: string): number {
    switch (radio) {
      case 'radio2g':
      case 'radio5g':
        return 1;
      case 'radio6g':
        return 0.2;
    }
  }

  getLegendWave(element: HTMLElement, radio: string): string {
    const startPoint = { x: 0, y: element.clientHeight / 2 };
    const endPoint = { x: element.clientWidth, y: element.clientHeight / 2 };

    if (radio !== 'ethernet') {
      const length = Math.sqrt(Math.pow(endPoint.x - startPoint.x, 2) + Math.pow(endPoint.y - startPoint.y, 2));
      const freq = this.getWaveFreqForRadio(radio);
      const resolution = this.getWaveResolutionForRadio(radio);
      const amplitude = 5;

      let d = '';

      for (let i = 0; i < length; i += resolution) {
        const x = startPoint.x + i;
        const y = amplitude * Math.sin(x / freq) + startPoint.y;

        if (i === 0) {
          d = 'M' + x + ',' + y;
        } else {
          d += ' L' + x + ',' + y;
        }
      }

      return d;
    } else {
      return 'M' + startPoint.x + ',' + startPoint.y + ' L' + endPoint.x + ',' + endPoint.y;
    }
  }

  getLegendMloWaves(element: HTMLElement): string[] {
    const startPoint = { x: 0, y: element.clientHeight / 2 };
    const endPoint = { x: element.clientWidth, y: element.clientHeight / 2 };

    const length = Math.sqrt(Math.pow(endPoint.x - startPoint.x, 2) + Math.pow(endPoint.y - startPoint.y, 2));
    const waves: string[] = [];

    ['radio2g', 'radio6g', 'radio5g'].forEach((radio, index) => {
      const freq = this.getWaveFreqForRadio(radio);
      const resolution = this.getWaveResolutionForRadio(radio);
      const amplitude = 5 / 3;

      let d = '';

      for (let i = 0; i < length; i += resolution) {
        const x = startPoint.x + i;
        let y = amplitude * Math.sin(x / freq) + startPoint.y;

        if (index === 0) y += amplitude * 3;
        if (index === 1) y -= amplitude * 3;

        if (i === 0) {
          d = 'M' + x + ',' + y;
        } else {
          d += ' L' + x + ',' + y;
        }
      }

      waves.push(d);
    });

    return waves;
  }

  updateDate(date: number): void {
    this.ttmEndTime = date;
    this.historySlider.noData = false;

    if (this.ttmEndTime > Date.now()) {
      this.ttmErrorDate = true;
      return;
    } else {
      this.ttmErrorDate = false;
    }
  }

  toggleHistory(): void {
    this.isHistory = !this.isHistory;

    if (this.isHistory) {
      this.toggleOverlayFilter('none');
      this.categoryFilters.forEach((category) => (category.enabled = false));
      this.deviceFilter();
    }
    if (!this.isHistory) {
      this.historySlider.open = false;
      this.toggleTtmAdvanced(false);
      this.closeTimemachine();
    }
  }

  closeTimemachine(): void {
    if (this.ttmNoUiSlider) {
      this.ttmNoUiSlider.destroy();
      this.ttmNoUiSlider = null;
      this.ttmCurrent = 0;
    }

    if (this.advancedNoUiSlider) {
      this.advancedNoUiSlider.destroy();
      this.advancedNoUiSlider = null;
      this.ttmDuration = 3;
    }

    this.history = [];
    this.ttmEndTime = 0;
    this.historySlider.timestamp = '';
    this.historySlider.noData = false;
    this.historySlider.error = false;

    const { nodes, links } = this.checkVapTypes(this.nodesList, this.linksList);

    this.nodes = this.recognizeNodes(nodes);
    this.links = links;
    this.chart.update(this.nodes, this.links, this.ui, this.width, this.height);

    if (this.getHistorySubscription) {
      this.getHistorySubscription.unsubscribe();
      this.getHistorySubscription = null;
      this.historySlider.button = 'charts.force.history.loadData';
    }
  }

  timemachine(): void {
    if (this.historySlider.button === 'charts.force.history.loading') {
      return;
    }

    if (!this.ttmEndTime) {
      this.ttmEndTime = Date.now();
    }

    const endTime = new Date(this.ttmEndTime);
    const startTime = new Date(endTime.getTime() - this.ttmDuration * 60 * 60 * 1000);

    if (this.ttmNoUiSlider) {
      this.ttmNoUiSlider.destroy();
      this.ttmNoUiSlider = null;
      this.ttmCurrent = 0;
    }

    this.history = [];
    this.historySlider.button = 'charts.force.history.loading';
    this.historySlider.noData = false;
    this.historySlider.error = false;

    try {
      startTime.toISOString();
      endTime.toISOString();
    } catch (e) {
      this.historySlider.button = 'charts.force.history.loadData';
      this.historySlider.wrongDate = true;
      this.historySlider.timestamp = '';
      this.ttmEndTime = 0;
      return;
    }

    this.getHistorySubscription = combineLatest([
      this.customerService.topologyChanges$(startTime, endTime),
      this.store.select(selectDevices).pipe(take(1))
    ]).subscribe(
      ([response, devices]) => {
        response.forEach((snapshot: any) => {
          this.history.push(this.prepare(snapshot, devices));
        });

        this.historySlider.button = 'charts.force.history.loadData';

        this.mixpanel.storeEvent('TOPOLOGY_TTM_HISTORY', {
          START_TIME: startTime.toISOString(),
          END_TIME: endTime.toISOString(),
          HISTORY_COUNT: this.history.length
        });

        if (response.length) {
          if (response.length >= 2) {
            this.initTTMSlider();
          }

          this.historySlider.wrongDate = false;
          this.historySlider.error = false;
          this.historySlider.noData = false;
          this.historySlider.max = this.history.length - 1;
          this.renderHistory(this.history[0]);
        } else {
          this.historySlider.noData = true;

          const { nodes, links } = this.checkVapTypes(this.nodesList, this.linksList);

          this.nodes = this.recognizeNodes(nodes);
          this.links = links;
          this.chart.update(this.nodes, this.links, this.ui, this.width, this.height);
        }
      },
      () => {
        this.historySlider.button = 'charts.force.history.loadData';
        this.historySlider.error = true;
      }
    );
  }

  next(): void {
    if (this.ttmNoUiSlider) {
      this.ttmNoUiSlider.set(this.ttmCurrent + 1);
    }
  }

  previous(): void {
    if (this.ttmNoUiSlider) {
      this.ttmNoUiSlider.set(this.ttmCurrent - 1);
    }
  }

  toggleTtmAdvanced(state: boolean = null): void {
    this.showAdvanced = state === null ? !this.showAdvanced : state;

    if (this.showAdvanced) {
      this.initAdvancedSlider();
    } else {
      if (this.advancedNoUiSlider) {
        this.advancedNoUiSlider.destroy();
        this.advancedNoUiSlider = null;
        this.ttmDuration = 3;
      }
    }
  }

  prepare(snapshot: any, devices: IDevice[]): any {
    const nodes = [];
    const links = [];

    snapshot.topology.forEach((node: any) => {
      const newNode = this.prepareNode(node, this.helper.isGateway(node.id, snapshot.topology));

      nodes.push(newNode);

      node.wifiConfig.forEach((band: any) => {
        if (band.devices && band.devices.length) {
          band.devices.forEach((device: any) => {
            const newDevice = this.prepareDevice(device, devices);

            nodes.push(newDevice);

            links.push(this.prepareLink(newNode, newDevice, band));
          });
        }
      });

      if (node.ethernetdevices && node.ethernetdevices.length) {
        node.ethernetdevices.forEach((device: any) => {
          const newDevice = this.prepareDevice(device, devices);

          nodes.push(newDevice);

          links.push(this.prepareLink(newNode, newDevice));
        });
      }
    });

    snapshot.topology.forEach((node: any) => {
      node.wifiConfig.forEach((band: any) => {
        if (band.parentId) {
          links.push(
            this.prepareLink(
              nodes.find((n: any) => n.id === band.parentId),
              nodes.find((n: any) => n.id === node.id),
              band
            )
          );
        }
      });

      if (node.ethernetparentnodeid) {
        links.push(
          this.prepareLink(
            nodes.find((n: any) => n.id === node.ethernetparentnodeid),
            nodes.find((n: any) => n.id === node.id)
          )
        );
      }
    });

    return { timestamp: new Date(snapshot.timestamp), nodes, links, powerMode: snapshot.powerMode };
  }

  prepareNode(node: any, isGatewayNode: boolean): Node {
    const foundNode = this.rawNodes.find((n: any) => n.id === node.id);
    if (foundNode) {
      return new Node(
        node.id,
        {
          connectionState: node.connectionState,
          health: node.connectionState === 'connected' ? foundNode.health : 'offline',
          id: foundNode.id,
          label: foundNode.nickname || foundNode.name,
          metadata: {
            connectedDeviceCount: foundNode.connectedDeviceCount,
            backhaulType: foundNode.backhaulType,
            firmwareVersion: foundNode.firmwareVersion,
            mac: foundNode.mac,
            model: foundNode.model,
            networkMode: foundNode.networkMode,
            platformVersion: foundNode.platformVersion,
            serialNumber: foundNode.serialNumber,
            radio24: { channel: foundNode['2gChannel'], vaps: [] },
            radio50: { channel: foundNode['5gChannel'], vaps: [] },
            radio60: { channel: foundNode['6gChannel'], vaps: [] },
            isGateway: isGatewayNode
          },
          radios: this.prepareRadios(node),
          type: 'pod'
        },
        []
      );
    } else {
      // If there has been a Node Swap foundNode will be empty
      return new Node(node.id, {
        id: node.id,
        label: node.id,
        type: 'pod',
        metadata: {
          model: 'pod'
        }
      });
    }
  }

  prepareDevice(device: IDevice, devices: IDevice[]): Node {
    let foundDevice = devices.find((d: any) => d.mac === device.mac);

    if (!foundDevice) {
      foundDevice = {
        connectionState: '',
        health: null,
        mac: device.mac,
        name: device.hostName,
        icon: 'unknown',
        iconV2: 'unknown',
        vapType: device.vapType,
        networkId: device.networkId
      } as IDevice;
    }

    return new Node(
      device.mac,
      {
        alerts: [],
        connectionState: 'connected',
        health: foundDevice.health,
        id: foundDevice.mac,
        label: foundDevice.nickname || foundDevice.name,
        isPartnerComponent: false,
        metadata: {
          icon: foundDevice.kind?.type?.icon || foundDevice.icon,
          iconV2: foundDevice.kind?.type?.iconV2 || foundDevice.iconV2,
          mac: foundDevice.mac,
          vapType: foundDevice.vapType,
          networkId: foundDevice.networkId
        },
        type: 'device'
      },
      []
    );
  }

  prepareLink(source: Node, target: Node, band: any = null): Link {
    if (band) {
      return new Link(source, target, {
        direction: 'sourceToTarget',
        medium: 'wifi',
        metadata: {
          parentVapType: band.parentvapType,
          freqBand: band.freqBand,
          channel: Number(band.channel),
          channelWidth: Number(band.channelWidth)
        },
        source: source.id,
        target: target.id
      });
    } else {
      return new Link(source, target, {
        direction: 'sourceToTarget',
        medium: 'ethernet',
        source: source.id,
        target: target.id
      });
    }
  }

  recognizeNodes(nodes: any[]): any[] {
    if (nodes && nodes.length && this.nodes && this.nodes.length) {
      nodes = nodes.map((node: any) => {
        this.nodes.forEach((n: any) => {
          if (n.id === node.id) {
            if (n.options && n.options.label && node.options) {
              node.options.label = n.options.label;
            }

            node.vx = n.vx;
            node.vy = n.vy;
            node.x = n.x;
            node.y = n.y;
          }
        });

        return node;
      });
    }

    let onlineDevices = 0;

    nodes = nodes.map((node: any) => {
      if (node.options) {
        node.appendixes = this.appendixes(node.options);
        node.options.label =
          node.options.label?.length > 17 ? node.options.label.substring(0, 16) + '…' : node.options.label;

        if (node.options.type === 'device') {
          onlineDevices++;
        }
      }

      node.overlays = this.setOverlays(node, nodes);

      return node;
    });

    this.onlineDevices = onlineDevices;

    return nodes;
  }

  setOverlays(node: Node, nodes: Node[]): any {
    if (this.overlayData) {
      const data: {
        overlayWidth: number;
        overlayBadge: boolean;
        overlayWarning: boolean;
        overlay: string;
        mloRssiOverlays: string[];
      } = {
        overlayWidth: 0,
        overlayBadge: false,
        overlayWarning: false,
        overlay: null,
        mloRssiOverlays: []
      };

      switch (this.overlayData) {
        case 'usage24':
          const usages = nodes.filter(
            (node: Node) => node.options.usage24 && node.options.connectionState === 'connected'
          );

          usages.sort((a: Node, b: Node) => b.options.usage24 - a.options.usage24);

          nodes.forEach((node: Node) => {
            if (node.options.type === 'device') {
              node.options.usage24badge = '';
            }
          });

          let uLen = usages.length;

          if (uLen > 3) {
            uLen = 3;
          }

          for (let i = 0; i < uLen; i++) {
            nodes.forEach((node: Node) => {
              if (usages[i] && node.id === usages[i].id) {
                if (i === 0) {
                  node.options.usage24badge = 'charts.force.overlays.first';
                }

                if (i === 1) {
                  node.options.usage24badge = 'charts.force.overlays.second';
                }

                if (i === 2) {
                  node.options.usage24badge = 'charts.force.overlays.third';
                }
              }
            });
          }

          data.overlayWidth = 80;
          data.overlayBadge = node.options.usage24badge || false;
          data.overlay = node.options.usage24
            ? node.options.usage24 > 1000
              ? node.options.usage24 > 1000000
                ? Math.round((node.options.usage24 / 1000000 + Number.EPSILON) * 100) / 100 + ' TB'
                : Math.round((node.options.usage24 / 1000 + Number.EPSILON) * 100) / 100 + ' GB'
              : Math.round((node.options.usage24 + Number.EPSILON) * 100) / 100 + ' MB'
            : null;
          break;
        case 'usageNow':
          data.overlayWidth = 90;
          data.overlay = ((currentUsage: number) =>
            currentUsage
              ? currentUsage * 1000 > 1000
                ? Math.round((currentUsage + Number.EPSILON) * 100) / 100 + ' Mbps'
                : Math.round((currentUsage * 1000 + Number.EPSILON) * 100) / 100 + ' Kbps'
              : null)(node.options.mloQoe?.currentUsage || node.options.qoe?.currentUsage);
          break;
        case 'potentialThroughput':
          data.overlayWidth = 80;
          data.overlay = ((potentialThroughput: number) =>
            potentialThroughput ? Math.round((potentialThroughput + Number.EPSILON) * 100) / 100 + ' Mbps' : null)(
            node.options.mloQoe?.potentialThroughput || node.options.qoe?.potentialThroughput
          );
          break;
        case 'rssi':
          data.overlayWidth = 84;
          if (node.options.mloQoe?.mloRssi?.length) {
            for (const link of node.options.mloQoe.mloRssi) {
              if (link.rssi) data.mloRssiOverlays.push(`${link.freqBand}: ${link.rssi} dBm`);
            }
            break;
          }
          data.overlayWarning = node.options.qoe.rssi ? (node.options.qoe.rssi < -72 ? true : false) : false;
          data.overlay = node.options.qoe.rssi ? node.options.qoe.rssi + ' dBm' : null;
          break;
        case 'capable5g':
          data.overlayWidth = 80;
          data.overlay = node.options.capable5g ? 'charts.force.overlays.capable5g' : null;
          break;
        case 'averageSpeedTest':
          data.overlayWidth = 80;
          const value = Number.parseFloat(node.options.averageSpeedTest);
          data.overlay = value
            ? value * 1000 > 1000
              ? Math.round((value + Number.EPSILON) * 100) / 100 + ' Mbps'
              : Math.round((value * 1000 + Number.EPSILON) * 100) / 100 + ' Kbps'
            : null;
          break;
      }

      return data;
    }
  }

  renderHistory(snapshot: any): void {
    this.powerMode = snapshot.powerMode;
    this.historySlider.open = true;
    this.historySlider.timestamp = snapshot.timestamp;

    const { nodes, links } = this.checkVapTypes(snapshot.nodes, snapshot.links);

    this.nodes = this.recognizeNodes(nodes);
    this.links = links;
    this.chart.update(this.nodes, this.links, this.ui, this.width, this.height);
  }

  gateWayIcon(vertice: any): string {
    if (vertice.connectionState === 'connected') {
      if (this.enable5gNeighborReports && this.configAndState.state.capabilities?.cellularStatistics) {
        return 'icon-5g-online';
      }

      if (this.activeLteNode === vertice.id && !this.isHistory) {
        return 'icon-lte-online';
      }

      return 'icon-globe-online';
    }
    return 'icon-globe-offline';
  }

  isGateway(vertice: any): boolean {
    return vertice.metadata.isGateway ?? this.helper.isGateway(vertice.id, this.rawNodes || []);
  }

  appendixes(vertice: any): any[] {
    const appendixes = [];

    if (vertice.type === 'pod') {
      const isGateway = this.isGateway(vertice);

      if (isGateway) {
        appendixes.push({
          class: 'success',
          icon: this.gateWayIcon(vertice),
          horizontal: 'right',
          vertical: 'top'
        });
      }
    }

    if (vertice.alerts && vertice.alerts.length) {
      appendixes.push({
        class: 'warning',
        icon: 'icon-alert-warning',
        horizontal: 'left',
        vertical: 'top'
      });
    }

    if (vertice.connectionState === 'disconnected') {
      appendixes.push({
        class: 'warning',
        icon: 'icon-alert-warning',
        horizontal: 'left',
        vertical: 'top'
      });
    }

    if (vertice.metadata.networkId === 'iot') {
      appendixes.push({
        class: 'warning',
        icon: 'icon-iot',
        horizontal: 'left',
        vertical: 'top'
      });
    }

    return appendixes;
  }

  // for time machine
  prepareRadios(node: any): any[] {
    const radios = [];

    if (node.wifiConfig.length) {
      node.wifiConfig.sort((a: any, b: any) => a.freqBand.localeCompare(b.freqBand));
      node.wifiConfig.forEach((config: any) => {
        if (config.freqBand === '2.4G') {
          radios.push({
            mode: '2g',
            channel: config.channel,
            hasClientNetwork: this.helper.hasClientNetwork(config.vaps)
          });
        }

        if (config.freqBand === '5G') {
          radios.push({
            mode: '5g',
            channel: config.channel,
            hasClientNetwork: this.helper.hasClientNetwork(config.vaps)
          });
        }

        if (config.freqBand === '5GL') {
          radios.push({
            mode: '5gl',
            channel: config.channel,
            hasClientNetwork: this.helper.hasClientNetwork(config.vaps)
          });
        }

        if (config.freqBand === '5GU') {
          radios.push({
            mode: '5gu',
            channel: config.channel,
            hasClientNetwork: this.helper.hasClientNetwork(config.vaps)
          });
        }
        if (config.freqBand === '6G') {
          radios.push({
            mode: '6g',
            channel: config.channel,
            hasClientNetwork: this.helper.hasClientNetwork(config.vaps)
          });
        }
      });
    }

    return radios;
  }

  updateTooltip(state: any, i: number, type: 'link' | 'node'): void {
    this.tooltip = {
      ...state,
      values: state.values.map((value) => ({ ...value, value: this.tooltipEllipsisText(this.container, value.value) }))
    };
    if (type === 'node') {
      this.tooltip.position = `translate(${this.nodes[i].x - 130},${this.nodes[i].y - 20 * state.values.length - 50})`;
    } else {
      const middleX = (this.links[i]?.source?.x + this.links[i]?.target?.x) / 2;
      const middleY = (this.links[i]?.source?.y + this.links[i]?.target?.y) / 2;
      this.tooltip.position = `translate(${middleX - 130},${middleY - 20 * state.values.length - 50})`;
    }
  }

  tooltipBottomOverflow(elm: HTMLElement): boolean {
    return elm.getBoundingClientRect().bottom > (window.innerHeight || document.documentElement.clientHeight);
  }

  tooltipEllipsisText(svgDocument: ElementRef<Document>, text: string): string {
    const textMaxLength = 170;
    const data = document.createTextNode(text);
    const svgElement = document.createElementNS('http://www.w3.org/2000/svg', 'text');
    svgElement.appendChild(data);
    svgDocument.nativeElement.appendChild(svgElement);

    if (svgElement.getBBox().width >= textMaxLength) {
      data.textContent = text.slice(0, -1) + '...';

      let failsafe = 0;
      while (svgElement.getBBox().width >= textMaxLength) {
        if (failsafe > 20) break;
        data.textContent = data.textContent.slice(0, -4) + '...';
        failsafe += 1;
      }

      svgElement.parentNode.removeChild(svgElement);

      return data.textContent;
    }

    svgElement.parentNode.removeChild(svgElement);

    return text;
  }

  ngOnDestroy(): void {
    if (this.simulation) {
      this.simulation.stop();
      this.simulation.on('tick', null);
    }

    if (this.container) {
      this.erd.uninstall(this.container.nativeElement);
    }

    delete this.simulation;
    delete this.chart;

    if (this.zoomNoUiSlider) {
      this.zoomNoUiSlider.destroy();
    }

    if (this.ttmNoUiSlider) {
      this.ttmNoUiSlider.destroy();
    }

    if (this.advancedNoUiSlider) {
      this.advancedNoUiSlider.destroy();
    }
  }
}
