import { IRingUser, IRingWatchers } from './thrive.interfaces';

export const ringUser: IRingUser = {
  cards: [
    {
      title: 'thrive.ringUser.fall',
      icon: 'icon-thrive-fall',
      notifications: [
        { id: 'falldetection', translation: 'thrive.ringUser.notification.fallDetection', enabled: true }
      ],
      activities: [{ id: 'lastUpdated', translation: 'thrive.ringUser.activities.lastUpdated', timestamp: Date.now() }]
    },
    {
      title: 'thrive.ringUser.activity',
      icon: 'icon-thrive-activity',
      notifications: [
        { id: 'heartRate', translation: 'thrive.ringUser.notification.heartRate', enabled: true },
        { id: 'bloodO2', translation: 'thrive.ringUser.notification.bloodO2', enabled: true }
      ],
      activities: [
        { id: 'steps', translation: 'thrive.ringUser.activities.steps', timestamp: Date.now() },
        { id: 'actualSession', translation: 'thrive.ringUser.activities.actualSession', timestamp: Date.now() },
        { id: 'liveHeartRate', translation: 'thrive.ringUser.activities.liveHeartRate', timestamp: Date.now() }
      ]
    },
    {
      title: 'thrive.ringUser.sleep',
      icon: 'icon-thrive-sleep',
      notifications: [
        { id: 'heartRate', translation: 'thrive.ringUser.notification.heartRate', enabled: true },
        { id: 'bloodO2', translation: 'thrive.ringUser.notification.bloodO2', enabled: false }
      ],
      activities: [
        { id: 'overnightSleep', translation: 'thrive.ringUser.activities.overnightSleep', timestamp: Date.now() },
        { id: 'nap', translation: 'thrive.ringUser.activities.nap', timestamp: Date.now() }
      ]
    },
    {
      title: 'thrive.ringUser.readiness',
      icon: 'icon-thrive-readiness',
      notifications: [
        { id: 'readinessScore', translation: 'thrive.ringUser.notification.readinessScore', enabled: false }
      ],
      activities: [{ id: 'lastUpdated', translation: 'thrive.ringUser.activities.lastUpdated', timestamp: Date.now() }]
    }
  ]
};

export const ringWatchers: IRingWatchers = {
  active: [{ name: 'Ellie Lambourne' }, { name: 'Ellie Lambourne' }, { name: 'Ellie Lambourne' }],
  invited: [{ name: 'Ellie Lambourne' }],
  cards: [
    {
      title: 'thrive.ringUser.fall',
      icon: 'icon-thrive-fall',
      notifications: [
        { id: 'viewDashboard', translation: 'thrive.ringWatchers.notification.viewDashboard', enabled: true },
        { id: 'falldetection', translation: 'thrive.ringWatchers.notification.fallDetection', enabled: true }
      ]
    },
    {
      title: 'thrive.ringUser.activity',
      icon: 'icon-thrive-activity',
      notifications: [
        { id: 'viewDashboard', translation: 'thrive.ringWatchers.notification.viewDashboard', enabled: true },
        { id: 'maxHeartRate', translation: 'thrive.ringWatchers.notification.maxHeartRate', enabled: true },
        {
          id: 'bloodO2',
          translation: 'thrive.ringWatchers.notification.bloodO2',
          notifications: [
            { id: 'notification', translation: 'thrive.ringWatchers.notification.notification', enabled: true },
            { id: 'thresholdsEdits', translation: 'thrive.ringWatchers.notification.thresholdsEdits', enabled: true }
          ]
        },
        {
          id: 'lowActivity',
          translation: 'thrive.ringWatchers.notification.lowActivity',
          notifications: [
            { id: 'notification', translation: 'thrive.ringWatchers.notification.notification', enabled: true },
            { id: 'thresholdsEdits', translation: 'thrive.ringWatchers.notification.thresholdsEdits', enabled: true }
          ]
        }
      ]
    },
    {
      title: 'thrive.ringUser.sleep',
      icon: 'icon-thrive-sleep',
      notifications: [
        { id: 'viewDashboard', translation: 'thrive.ringWatchers.notification.viewDashboard', enabled: true },
        {
          id: 'heartRate',
          translation: 'thrive.ringWatchers.notification.heartRate',
          notifications: [
            { id: 'highHRAlert', translation: 'thrive.ringWatchers.notification.highHRAlert', enabled: true },
            { id: 'thresholdsEdits', translation: 'thrive.ringWatchers.notification.thresholdsEdits', enabled: true }
          ]
        },
        {
          id: 'bloodO2',
          translation: 'thrive.ringWatchers.notification.bloodO2',
          notifications: [
            { id: 'notification', translation: 'thrive.ringWatchers.notification.notification', enabled: true },
            { id: 'lowO2Alert', translation: 'thrive.ringWatchers.notification.lowO2Alert', enabled: true }
          ]
        },
        {
          id: 'sleepTrends',
          translation: 'thrive.ringWatchers.notification.sleepTrends',
          notifications: [
            { id: 'lowTrendAlert', translation: 'thrive.ringWatchers.notification.lowTrendAlert', enabled: true },
            { id: 'thresholdsEdits', translation: 'thrive.ringWatchers.notification.thresholdsEdits', enabled: true }
          ]
        }
      ]
    },
    {
      title: 'thrive.ringUser.readiness',
      icon: 'icon-thrive-readiness',
      notifications: [
        { id: 'viewDashboard', translation: 'thrive.ringWatchers.notification.viewDashboard', enabled: true },
        { id: 'lowTrendAlert', translation: 'thrive.ringWatchers.notification.lowTrendAlert', enabled: true }
      ]
    }
  ]
};
