<svg:g *ngFor="let yAxis of calculations.axis.yAxis">
  <svg:line
    class="axis"
    [attr.x1]="yAxis.x"
    [attr.y1]="yAxis.startY"
    [attr.x2]="yAxis.x"
    [attr.y2]="yAxis.endY"
  ></svg:line>
  <svg:line
    class="axis"
    *ngIf="yAxis.xClosing !== undefined"
    [attr.x1]="yAxis.xClosing"
    [attr.y1]="yAxis.startY"
    [attr.x2]="yAxis.xClosing"
    [attr.y2]="yAxis.endY"
  ></svg:line>
  <svg:g *ngFor="let line of yAxis.steps; let last = last; let first = first">
    <svg:line
      class="axis"
      [attr.x1]="yAxis.x"
      [attr.y1]="line.yPos || 0"
      [attr.x2]="yAxis.xEnd"
      [attr.y2]="line.yPos || 0"
    ></svg:line>
    <svg:text
      class="axisText"
      [attr.x]="yAxis.x - 10"
      [attr.y]="line.yPosText"
      *ngIf="!last || line.yPosText === line.yPos"
    >
      {{ line.text }}
    </svg:text>
  </svg:g>
</svg:g>
