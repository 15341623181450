<ng-container *ngFor="let group of calculations.groupedDatasets">
  <ng-container *ngIf="group.calculateStack">
    <ng-container *ngFor="let dataSet of group.dataSets; let dsIndex = index">
      <ng-container *ngIf="dataSet.type === 'labeledStackedBar'">
        <ng-container *ngFor="let data of dataSet.data">
          <svg:rect
            (mouseenter)="hover(data.xVal)"
            (mouseleave)="hover(undefined)"
            [attr.x]="data.xPos + 31"
            [attr.y]="data.yPosStackedEnd || 0"
            [attr.width]="21"
            [attr.height]="
              (data.yPosStackedStart || 0) -
              (data.yPosStackedEnd || 0) -
              (dsIndex === 0 ? 0 : 2)
            "
            [attr.fill]="
              hoveredXVal === data.xVal ? dataSet.hoverColor : dataSet.color
            "
            rx="10"
            ry="10"
          />
          <svg:text
            class="text"
            [attr.transform]="
              'translate(' +
              (data.xPos + 15 + 8) +
              ', ' +
              (calculations.elementSize.height -
                calculations.elementSize.bottom) +
              ') rotate(-90)'
            "
          >
            {{ dataSet.xValText && dataSet.xValText(data.xVal) }}
          </svg:text>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
