import { Component, OnInit } from '@angular/core';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { ApiService } from 'src/app/lib/services/api.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { ToolbarService } from 'src/app/lib/services/toolbar.service';
import { Line } from 'src/app/lib/d3/models/objects/line';
import { Point } from 'src/app/lib/d3/models/objects/point';
import { Series } from 'src/app/lib/d3/models/objects/series';
import { AuthService } from 'src/app/lib/services/auth.service';
import { ToastService } from 'src/app/lib/services/toast.service';

@Component({
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  logo: string = '';
  pattern: string = '';
  openStats = false;
  openStatsDialog = false;
  stats: boolean = false;
  charts: any = {};
  chartMargins: any = {
    top: 10,
    right: 20,
    bottom: 30,
    left: 70
  };

  constructor(
    private auth: AuthService,
    private plume: PlumeService,
    private api: ApiService,
    private mixpanel: MixpanelService,
    private toolbar: ToolbarService,
    private toast: ToastService
  ) {}

  ngOnInit(): void {
    if (
      (this.plume.getUI() === 'noc' && (this.plume.isGroupSupportTechnician() || this.plume.isGroupSupportCentral())) ||
      this.plume.isSuperGroupAdminRole() ||
      this.plume.isIntegrationRole()
    ) {
      const errorMessage = this.plume.isGroupSupportTechnician()
        ? 'landing.errorGroupSupportTechnicianMessage'
        : this.plume.isGroupSupportCentral()
        ? 'landing.errorGroupSupportCentralMessage'
        : 'landing.errorRoleNotAllowed';

      this.toast.error(errorMessage, 'landing.errorGroupSupportTechnicianTitle', {
        disableTimeOut: true
      });

      this.mixpanel.storeEvent('RESTRICTED_UI_ACCESS');

      setTimeout(() => this.auth.logout(), 5000);
    }

    this.mixpanel.storeEvent('HOME_SCREEN');

    this.toolbar.resetModals();
    this.plume.clearCustomer();

    const ui = this.plume.getUI() === 'noc' ? '23' : '1';
    const vendor = this.plume.getVendor();

    this.logo = 'assets/logo/' + vendor + '_frontline_tier' + ui + '.svg';
    this.pattern = 'assets/logo/haze' + ui + '.svg';

    if (ui === '23' && this.plume.isStrictAdminRole()) {
      this.getStats();
    }
  }

  getStats(): void {
    this.stats = true;
    this.initTogglers();
    this.getDeviceHappiness();
    this.getAlarms();
    this.getBandSteeringStats();
    this.getClientSteeringStats();
    this.getOptimizationStats();
  }

  initTogglers(): void {
    this.charts.deviceHappinessPeriod = 1;
    this.charts.deviceHappinessToggler = [
      { value: 1, translation: 'landing.24h', selected: true },
      { value: 7, translation: 'landing.7days', selected: false }
    ];

    this.charts.alarmsPeriod = 1;
    this.charts.alarmsToggler = [
      { value: 1, translation: 'landing.24h', selected: true },
      { value: 7, translation: 'landing.7days', selected: false }
    ];

    this.charts.bandSteeringStatsPeriod = 1;
    this.charts.bandSteeringStatsToggler = [
      { value: 1, translation: 'landing.24h', selected: true },
      { value: 7, translation: 'landing.7days', selected: false }
    ];

    this.charts.clientSteeringStatsPeriod = 1;
    this.charts.clientSteeringStatsToggler = [
      { value: 1, translation: 'landing.24h', selected: true },
      { value: 7, translation: 'landing.7days', selected: false }
    ];

    this.charts.optimizationStatsPeriod = 1;
    this.charts.optimizationStatsToggler = [
      { value: 1, translation: 'landing.24h', selected: true },
      { value: 7, translation: 'landing.7days', selected: false }
    ];
  }

  action(command: string, action: any): void {
    switch (command) {
      case 'deviceHappiness':
        this.charts.deviceHappinessPeriod = action;
        this.getDeviceHappiness();
        break;
      case 'alarms':
        this.charts.alarmsPeriod = action;
        this.getAlarms();
        break;
      case 'bandSteeringStats':
        this.charts.bandSteeringStatsPeriod = action;
        this.getBandSteeringStats();
        break;
      case 'clientSteeringStats':
        this.charts.clientSteeringStatsPeriod = action;
        this.getClientSteeringStats();
        break;
      case 'optimizationStats':
        this.charts.optimizationStatsPeriod = action;
        this.getOptimizationStats();
        break;
    }
  }

  getDeviceHappiness(): void {
    this.charts.deviceHappiness = {
      alarmed: {
        series: {
          color: 'var(--chart-yellow)',
          text: 'deviceHappinessAlarmed',
          translation: 'landing.deviceHappinessAlarmed'
        },
        axis: 'left',
        data: []
      },
      total: {
        series: {
          color: 'var(--chart-green)',
          text: 'deviceHapinessTotal',
          translation: 'landing.deviceHapinessTotal'
        },
        axis: 'left',
        data: []
      }
    };

    const params =
      this.charts.deviceHappinessPeriod === 1 ? { granularity: 'hours', limit: 24 } : { granularity: 'days', limit: 7 };

    this.api.get('/Groups/0/deviceHappiness', 'reports', { params }).subscribe((response: any) => {
      this.charts.deviceHappiness.alarmed = new Line(
        new Series('var(--chart-yellow)', 'deviceHappinessAlarmed', 'landing.deviceHappinessAlarmed'),
        'left',
        response.alarmedDeviceCount.map(
          (obj: any) =>
            new Point(
              new Date(obj.timestamp),
              obj.value !== null ? Math.round((obj.value + Number.EPSILON) * 100) / 100 : null
            )
        )
      );

      this.charts.deviceHappiness.total = new Line(
        new Series('var(--chart-green)', 'deviceHapinessTotal', 'landing.deviceHappinessTotal'),
        'left',
        response.totalDeviceCount.map(
          (obj: any) =>
            new Point(
              new Date(obj.timestamp),
              obj.value !== null ? Math.round((obj.value + Number.EPSILON) * 100) / 100 : null
            )
        )
      );
    });
  }

  getAlarms(): void {
    this.charts.alarms = {
      congestion: {
        series: {
          color: 'var(--chart-yellow)',
          text: 'alarmsCongestion',
          translation: 'landing.alarmsCongestion'
        },
        axis: 'left',
        data: []
      },
      coverage: {
        series: {
          color: 'var(--chart-green)',
          text: 'alarmsCoverage',
          translation: 'landing.alarmsCoverage'
        },
        axis: 'left',
        data: []
      }
    };

    const params =
      this.charts.alarmsPeriod === 1 ? { granularity: 'hours', limit: 24 } : { granularity: 'days', limit: 7 };

    this.api.get('/Groups/0/alarms', 'reports', { params }).subscribe((response: any) => {
      this.charts.alarms.coverage = new Line(
        new Series('var(--chart-green)', 'alarmsCoverage', 'landing.alarmsCoverage'),
        'left',
        response.coverage.map(
          (obj: any) =>
            new Point(
              new Date(obj.timestamp),
              obj.value !== null ? Math.round((obj.value + Number.EPSILON) * 100) / 100 : null
            )
        )
      );
    });
  }

  getBandSteeringStats(): void {
    this.charts.bandSteeringStats = {
      failed: {
        series: {
          color: 'var(--chart-yellow)',
          text: 'bandSteeringStatsFailed',
          translation: 'landing.bandSteeringStatsFailed'
        },
        axis: 'left',
        data: []
      },
      succeeded: {
        series: {
          color: 'var(--chart-green)',
          text: 'bandSteeringStatsSucceeded',
          translation: 'landing.bandSteeringStatsSucceeded'
        },
        axis: 'left',
        data: []
      }
    };

    const params =
      this.charts.bandSteeringStatsPeriod === 1
        ? { granularity: 'hours', limit: 24 }
        : { granularity: 'days', limit: 7 };

    this.api.get('/Groups/0/bandSteeringStats', 'reports', { params }).subscribe((response: any) => {
      this.charts.bandSteeringStats.failed = new Line(
        new Series('var(--chart-yellow)', 'bandSteeringStatsFailed', 'landing.bandSteeringStatsFailed'),
        'left',
        response.points?.map(
          (obj: any) =>
            new Point(
              new Date(obj.timestamp),
              obj.bs_failed !== null ? Math.round((obj.bs_failed + Number.EPSILON) * 100) / 100 : null
            )
        )
      );

      this.charts.bandSteeringStats.succeeded = new Line(
        new Series('var(--chart-green)', 'bandSteeringStatsSucceeded', 'landing.bandSteeringStatsSucceeded'),
        'left',
        response.points?.map(
          (obj: any) =>
            new Point(
              new Date(obj.timestamp),
              obj.bs_succeeded !== null ? Math.round((obj.bs_succeeded + Number.EPSILON) * 100) / 100 : null
            )
        )
      );
    });
  }

  getClientSteeringStats(): void {
    this.charts.clientSteeringStats = {
      failed: {
        series: {
          color: 'var(--chart-yellow)',
          text: 'clientSteeringStatsFailed',
          translation: 'landing.clientSteeringStatsFailed'
        },
        axis: 'left',
        data: []
      },
      succeeded: {
        series: {
          color: 'var(--chart-green)',
          text: 'clientSteeringStatsSucceeded',
          translation: 'landing.clientSteeringStatsSucceeded'
        },
        axis: 'left',
        data: []
      }
    };

    const params =
      this.charts.clientSteeringStatsPeriod === 1
        ? { granularity: 'hours', limit: 24 }
        : { granularity: 'days', limit: 7 };

    this.api.get('/Groups/0/clientSteeringStats', 'reports', { params }).subscribe((response: any) => {
      this.charts.clientSteeringStats.failed = new Line(
        new Series('var(--chart-yellow)', 'clientSteeringStatsFailed', 'landing.clientSteeringStatsFailed'),
        'left',
        response.points.map(
          (obj: any) =>
            new Point(
              new Date(obj.timestamp),
              obj.cs_failed !== null ? Math.round((obj.cs_failed + Number.EPSILON) * 100) / 100 : null
            )
        )
      );

      this.charts.clientSteeringStats.succeeded = new Line(
        new Series('var(--chart-green)', 'clientSteeringStatsSucceeded', 'landing.clientSteeringStatsSucceeded'),
        'left',
        response.points.map(
          (obj: any) =>
            new Point(
              new Date(obj.timestamp),
              obj.cs_succeeded !== null ? Math.round((obj.cs_succeeded + Number.EPSILON) * 100) / 100 : null
            )
        )
      );
    });
  }

  getOptimizationStats(): void {
    this.charts.optimizationStats = {
      failed: {
        series: {
          color: 'var(--chart-yellow)',
          text: 'optimizationStatsFailed',
          translation: 'landing.optimizationStatsFailed'
        },
        axis: 'left',
        data: []
      },
      succeeded: {
        series: {
          color: 'var(--chart-green)',
          text: 'optimizationStatsSucceeded',
          translation: 'landing.optimizationStatsSucceeded'
        },
        axis: 'left',
        data: []
      }
    };

    const params =
      this.charts.optimizationStatsPeriod === 1
        ? { granularity: 'hours', limit: 24 }
        : { granularity: 'days', limit: 7 };

    this.api.get('/Groups/0/optimizationStats', 'reports', { params }).subscribe((response: any) => {
      this.charts.optimizationStats.failed = new Line(
        new Series('var(--chart-yellow)', 'optimizationStatsFailed', 'landing.optimizationStatsFailed'),
        'left',
        response.failed
          .map(
            (obj: any) =>
              new Point(
                new Date(obj.timestamp),
                obj.value !== null ? Math.round((obj.value + Number.EPSILON) * 100) / 100 : null
              )
          )
          .reverse()
      );

      this.charts.optimizationStats.succeeded = new Line(
        new Series('var(--chart-green)', 'optimizationStatsSucceeded', 'landing.optimizationStatsSucceeded'),
        'left',
        response.succeeded
          .map(
            (obj: any) =>
              new Point(
                new Date(obj.timestamp),
                obj.value !== null ? Math.round((obj.value + Number.EPSILON) * 100) / 100 : null
              )
          )
          .reverse()
      );
    });
  }
}
