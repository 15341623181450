<header placeholder="header.searchCustomers"></header>
<div class="container-fluid">
  <div class="title">
    <span class="titleText"> {{ 'header.groupManagement.groupInformationTitle' | translate }}: {{ group?.name }} </span>
    <div class="back" (click)="goBack()">
      <icon name="icon-arrow-left"></icon>
      {{ 'header.groupManagement.returnToGroupList' | translate }}
    </div>
  </div>
  <div class="info">
    <div class="data">
      <div class="grid">
        <div class="label">{{ 'header.groupManagement.name' | translate }}</div>
        <div class="value" *ngIf="!editGroupName">
          <span class="text" [tooltip]="group?.name" [onlyOverflowText]="true">{{ group?.name }}</span>
          <icon name="icon-edit" (click)="toggleEditGroupName()" *ngIf="plume.isStrictAdminRole()"></icon>
        </div>
        <div class="value" *ngIf="editGroupName">
          <input
            (keyup.enter)="updateGroupName()"
            (keyup.escape)="toggleEditGroupName()"
            [formControl]="groupName"
            autofocus
          />
          <div class="commands">
            <icon
              name="icon-check"
              class="confirm"
              tooltip="{{ 'techdash.tooltip.set' | translate }}"
              (click)="updateGroupName()"
            ></icon>
            <icon
              name="icon-close"
              class="size-0_8 cancel"
              tooltip="{{ 'techdash.tooltip.cancel' | translate }}"
              (click)="editGroupName = false"
            ></icon>
          </div>
        </div>
      </div>
      <div class="grid">
        <div class="label">{{ 'header.groupManagement.description' | translate }}</div>
        <div class="value" *ngIf="!editGroupDescription">
          {{ group?.description }}
          <icon name="icon-edit" (click)="toggleEditGroupDescription()" *ngIf="plume.isStrictAdminRole()"></icon>
        </div>
        <div class="value" *ngIf="editGroupDescription">
          <input
            (keyup.enter)="updateGroupDescription()"
            (keyup.escape)="toggleEditGroupDescription()"
            [formControl]="groupDescription"
            autofocus
          />
          <div class="commands">
            <icon
              name="icon-check"
              class="confirm"
              tooltip="{{ 'techdash.tooltip.set' | translate }}"
              (click)="updateGroupDescription()"
            ></icon>
            <icon
              name="icon-close"
              class="size-0_8 cancel"
              tooltip="{{ 'techdash.tooltip.cancel' | translate }}"
              (click)="editGroupDescription = false"
            ></icon>
          </div>
        </div>
      </div>
      <div class="grid">
        <div class="label">{{ 'header.groupManagement.groupId' | translate }}</div>
        <div class="value">{{ group?.id }}</div>
      </div>
      <div class="grid">
        <div class="label">{{ 'header.groupManagement.customerCount' | translate }}</div>
        <div class="value">{{ customerCount }}</div>
      </div>
      <div class="controls" *ngIf="plume.isStrictAdminRole()">
        <button class="action" (click)="deleteModal()">
          {{ 'header.groupManagement.deleteGroup' | translate }}
        </button>
      </div>
    </div>
  </div>
  <ng-container *ngIf="customerList.length">
    <div class="title">{{ 'header.groupManagement.recentlyCreated' | translate }}</div>
    <div class="groupListContainer">
      <div class="headers">
        <div class="name header">{{ 'header.groupManagement.customerId' | translate }}</div>
        <div class="description header">{{ 'header.groupManagement.accountId' | translate }}</div>
        <div class="id header">{{ 'header.groupManagement.createdAt' | translate }}</div>
        <div class="go header">{{ 'header.groupManagement.view' | translate }}</div>
      </div>
      <div class="grouplist" *ngFor="let customer of customerList">
        <div class="name" [tooltip]="customer.id" [onlyOverflowText]="true">{{ customer.id }}</div>
        <div class="description" [tooltip]="customer.accountId" [onlyOverflowText]="true">{{ customer.accountId }}</div>
        <div class="id">{{ customer.date }}</div>
        <div class="go">
          <button class="action small" (click)="navigate($event, customer.group, customer.id)">
            {{ 'header.groupManagement.view' | translate }}
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</div>
