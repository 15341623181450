import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'tile',
  templateUrl: './tile.component.html',
  styleUrls: ['./tile.component.scss']
})
export class TileComponent {
  @Input()
  tileIcon: string = null;

  @Input()
  tileImage: string = null;

  @Input()
  title: string;

  @Input()
  disabled: boolean = false;

  @Input()
  largeImage: boolean = false;

  @Input()
  options: boolean = false;

  @Input()
  optionsTooltip: string;

  @Input()
  optionsBadgeTooltip: string;

  @Input()
  optionsBadge: string;

  @Output()
  action: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}
}
