import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'stacked-bar',
  templateUrl: './stacked-bar.component.html',
  styleUrls: ['./stacked-bar.component.scss']
})
export class StackedBarComponent implements OnInit, OnChanges {
  @Input() selectedItemIndex = 0;
  @Output() selectedItemIndexChange = new EventEmitter<number>();
  @Input() data: {
    value: number;
    color: string;
  }[] = [];

  totalValue = 0;
  minPercentValue = 5;
  renderData: {
    color: string;
    percent: number;
  }[] = [];

  emptyData = [
    { color: 'var(--text-light)', value: 17 },
    { color: 'var(--text-light)', value: 61 },
    { color: 'var(--text-light)', value: 137 },
    { color: 'var(--text-light)', value: 9 }
  ];
  emptyDataVisible = true;
  emptyDataBiggestBlockIndex = this.biggestBlockIndex(this.emptyData);

  constructor() {}

  ngOnInit(): void {
    if (!this.emptyDataVisible) {
      this.blockSelected(this.biggestBlockIndex(this.data));
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      this.emptyDataVisible = false;

      if (!this.data || this.data.length === 0) {
        this.emptyDataVisible = true;
        this.data = this.emptyData;
        this.blockSelected(-1);
      }

      this.totalValue = this.data?.reduce((acc, val) => acc + val.value, 0);
      this.adjustData();
    }
  }

  adjustData(): void {
    const nonMinimalDatapoints = [];
    let overflow = 0;

    const renderData = this.data.map((dataPoint, index) => {
      const percent = (dataPoint.value / this.totalValue) * 100;

      if (percent < this.minPercentValue) {
        overflow += this.minPercentValue - percent;
        return { color: dataPoint.color, percent: this.minPercentValue };
      } else {
        nonMinimalDatapoints.push(index);
        return { color: dataPoint.color, percent };
      }
    });

    this.renderData =
      overflow > 0
        ? renderData.map((dataPoint, index) => {
            if (nonMinimalDatapoints.includes(index)) {
              dataPoint.percent -= overflow / nonMinimalDatapoints.length;
            }

            return dataPoint;
          })
        : renderData;
  }

  blockSelected(index: number): void {
    this.selectedItemIndex = index;
    this.selectedItemIndexChange.emit(index);
  }

  private biggestBlockIndex(
    data: {
      value: number;
      color: string;
    }[]
  ): number {
    return data?.reduce((acc, item, index) => (item.value > acc.value ? { index, value: item.value } : acc), {
      index: -1,
      value: 0
    }).index;
  }
}
