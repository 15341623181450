<modal class="landing" mode="compact" (overlayAction)="hideCustomerDialog()" *ngIf="show.landing">
  <ng-container title>{{ 'newCustomer.landing.title' | translate }}</ng-container>
  <ng-container content>
    <p>{{ 'newCustomer.landing.description' | translate }}</p>
  </ng-container>
  <ng-container controls>
    <button class="tertiary medium light" (click)="newCustomerFlow('legacy')">
      {{ 'newCustomer.landing.newCustomer' | translate }}
    </button>
    <button class="super-primary medium" (click)="newCustomerFlow('onboarding', 'createCustomer')">
      {{ 'newCustomer.landing.customerOnboarding' | translate }}
    </button>
  </ng-container>
</modal>
<modal
  class="narrator"
  mode="normal"
  [closeOnClickOutside]="false"
  (overlayAction)="hideCustomerDialog()"
  *ngIf="show.onboarding"
>
  <ng-container title>{{ 'newCustomer.title' | translate }}</ng-container>
  <ng-container content>
    <ng-container *ngIf="onboarding.flow === 'createCustomer'">
      <div class="grid">
        <div class="label">{{ 'newCustomer.name' | translate }}:</div>
        <input
          trimValue
          [formControl]="customerName"
          type="text"
          placeholder="{{ 'newCustomer.placeholders.name' | translate }}"
          autocomplete="none"
          autoreadonly
        />
      </div>
      <div class="grid">
        <div class="label">{{ 'newCustomer.email' | translate }}:</div>
        <input
          [formControl]="customerEmail"
          type="text"
          placeholder="{{ 'newCustomer.placeholders.email' | translate }}"
          autocomplete="none"
          autoreadonly
        />
      </div>
      <div class="grid" *ngIf="!usePartnerAPI">
        <div class="label">{{ 'newCustomer.password' | translate }}:</div>
        <input
          [formControl]="customerPassword"
          type="password"
          placeholder="{{ 'newCustomer.placeholders.passwordOptional' | translate }}"
          autocomplete="none"
          autoreadonly
        />
      </div>
      <div class="grid" *ngIf="role != 'admin'">
        <div class="label">{{ 'newCustomer.accountId' | translate }}:</div>
        <input
          [formControl]="customerAccountId"
          type="text"
          placeholder="{{ 'newCustomer.placeholders.accountId' | translate }}"
          autocomplete="none"
          autoreadonly
        />
      </div>
      <div class="grid" *ngIf="groups.length && role != 'admin' && !usePartnerAPI">
        <div class="label top">{{ 'newCustomer.group' | translate }}:</div>
        <div class="groups">
          <div
            class="group"
            [class.selected]="group.id == groupSelected"
            *ngFor="let group of groups"
            (click)="selectGroup(group)"
          >
            {{ group.name }}
            <icon name="icon-check" class="size-0_7" *ngIf="group.id == groupSelected"></icon>
          </div>
        </div>
      </div>
      <div class="grid" *ngIf="usePartnerAPI && user.accessibleLeafPartnerIds?.length">
        <div class="label top">{{ 'newCustomer.partnerIds' | translate }}:</div>
        <div class="groups">
          <div
            class="group"
            [class.selected]="partnerId == partnerIdSelected"
            *ngFor="let partnerId of user.accessibleLeafPartnerIds"
            (click)="selectPartnerId(partnerId)"
          >
            {{ getPartnerIdName(partnerId) }}
            <icon name="icon-check" class="size-0_7" *ngIf="partnerId == partnerIdSelected"></icon>
          </div>
        </div>
      </div>
      <div class="grid" *ngIf="role === 'groupAdmin' || role === 'groupSupportTechnician'">
        <div class="label">{{ 'newCustomer.profile' | translate }}:</div>
        <div><slidetoggler [options]="profileItems" (toggle)="profileSelectedItem = $event"></slidetoggler></div>
      </div>
    </ng-container>
    <ng-container *ngIf="onboarding.flow !== 'createCustomer'">
      <div class="navigation">
        <div class="step" [class.active]="onboarding.step >= 1">
          <div class="circle">1</div>
          <div class="label">{{ 'newCustomer.onboarding.navAddNode' | translate }}</div>
        </div>
        <div class="line" [class.active]="onboarding.step >= 2"></div>
        <div class="step" [class.active]="onboarding.step >= 2">
          <div class="circle">2</div>
          <div class="label">{{ 'newCustomer.onboarding.navNetwork' | translate }}</div>
        </div>
        <div class="line" [class.active]="onboarding.step >= 3"></div>
        <div class="step" [class.active]="onboarding.step >= 3">
          <div class="circle">3</div>
          <div class="label">{{ 'newCustomer.onboarding.navPassword' | translate }}</div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="onboarding.flow === 'addNode'">
      <div class="nodeList" *ngIf="onboarding.addNode.list.length">
        <div class="label">{{ 'newCustomer.addNode.listLabel' | translate }}</div>
        <div class="node" *ngFor="let node of onboarding.addNode.list">{{ node.id }} ({{ node.defaultName }})</div>
      </div>
      <div class="addNode" *ngIf="!onboarding.addNode.loading">
        <labeled-input>
          <div label>{{ 'newCustomer.addNode.nodeid' | translate }}</div>
          <input [formControl]="onboarding.addNode.nodeid" autofocus />
          <div info error *ngIf="onboarding.addNode.error">
            {{ onboarding.addNode.error }}
          </div>
          <div info error *ngIf="onboarding.addNode.error404">{{ 'newCustomer.addNode.error404' | translate }}</div>
          <div info error *ngIf="onboarding.addNode.error403">{{ 'newCustomer.addNode.error403' | translate }}</div>
          <div info error *ngIf="onboarding.addNode.errorInput">
            {{ 'newCustomer.addNode.errorInput' | translate }}
          </div>
        </labeled-input>
        <div class="controls">
          <icon
            name="icon-check"
            class="confirm"
            tooltip="{{ 'newCustomer.addNode.addNode' | translate }}"
            [noTouchToolTip]="true"
            (click)="addNode()"
          ></icon>
          <icon
            name="icon-close"
            class="cancel size-0_8"
            tooltip="{{ 'newCustomer.addNode.clear' | translate }}"
            [noTouchToolTip]="true"
            (click)="clearNode()"
          ></icon>
          <icon
            name="icon-qr-scan"
            tooltip="{{ 'newCustomer.addNode.scanQr' | translate }}"
            [noTouchToolTip]="true"
            (click)="showQrcodeScanner()"
          ></icon>
        </div>
      </div>
      <div class="addNode" *ngIf="onboarding.addNode.loading">
        {{ 'newCustomer.addNode.loading' | translate }}
      </div>
    </ng-container>
    <ng-container *ngIf="onboarding.flow === 'networkSettings'">
      <div class="networkSettings">
        <p>{{ 'health.networkInformation.onboarding' | translate }}</p>
        <div class="input" *ngIf="onboarding.networkSettings.ssid">
          <div class="label">{{ 'health.networkInformation.ssid' | translate }}:</div>
          <ng-container *ngIf="!onboarding.networkSettings.ssidEdit">
            <div class="value" *ngIf="!onboarding.networkSettings.ssid.value.length">
              {{ onboarding.networkSettings.currentSsid }}
            </div>
            <div class="value" *ngIf="onboarding.networkSettings.ssid.value.length">
              {{ onboarding.networkSettings.ssid.value }}
            </div>
            <div class="controls">
              <icon
                name="icon-edit"
                tooltip="{{ 'newCustomer.networkSettings.editSSID' | translate }}"
                [noTouchToolTip]="true"
                (click)="onboarding.networkSettings.ssidEdit = !onboarding.networkSettings.ssidEdit"
              ></icon>
            </div>
          </ng-container>
          <ng-container *ngIf="onboarding.networkSettings.ssidEdit">
            <labeled-input>
              <input type="text" [formControl]="onboarding.networkSettings.ssid" autofocus />
              <div info error *ngIf="onboarding.networkSettings.errorSsid">
                {{ 'techdash.infoArea.ssidValidation' | translate }}
              </div>
            </labeled-input>
            <div class="controls">
              <icon
                name="icon-check"
                class="confirm"
                tooltip="{{ 'newCustomer.networkSettings.saveSSID' | translate }}"
                [noTouchToolTip]="true"
                (click)="setWifiNetwork('ssid')"
              ></icon>
              <icon
                name="icon-close"
                class="cancel size-0_8"
                tooltip="{{ 'newCustomer.networkSettings.cancel' | translate }}"
                [noTouchToolTip]="true"
                (click)="cancelSsid()"
                *ngIf="onboarding.networkSettings.password"
              ></icon>
              <icon
                name="icon-close"
                class="cancel size-0_8"
                tooltip="{{ 'newCustomer.networkSettings.clear' | translate }}"
                [noTouchToolTip]="true"
                (click)="onboarding.networkSettings.ssid.reset()"
                *ngIf="!onboarding.networkSettings.password"
              ></icon>
            </div>
          </ng-container>
        </div>
        <div class="input" *ngIf="onboarding.networkSettings.password">
          <div class="label">{{ 'health.networkInformation.password' | translate }}:</div>
          <ng-container *ngIf="!onboarding.networkSettings.passwordEdit">
            <div class="value">**********</div>
            <div class="controls">
              <icon
                name="icon-edit"
                tooltip="{{ 'newCustomer.networkSettings.editPassword' | translate }}"
                [noTouchToolTip]="true"
                (click)="onboarding.networkSettings.passwordEdit = !onboarding.networkSettings.passwordEdit"
              ></icon>
            </div>
          </ng-container>
          <ng-container *ngIf="onboarding.networkSettings.passwordEdit">
            <labeled-input>
              <input type="password" [formControl]="onboarding.networkSettings.password" autofocus />
              <div info error *ngIf="onboarding.networkSettings.errorPassword">
                {{ 'techdash.infoArea.passwordValidation' | translate }}
              </div>
            </labeled-input>
            <div class="controls">
              <icon
                name="icon-check"
                class="confirm"
                tooltip="{{ 'newCustomer.networkSettings.savePassword' | translate }}"
                [noTouchToolTip]="true"
                (click)="setWifiNetwork('password')"
              ></icon>
              <icon
                name="icon-close"
                class="cancel size-0_8"
                tooltip="{{ 'newCustomer.networkSettings.cancel' | translate }}"
                [noTouchToolTip]="true"
                (click)="cancelPassword()"
              ></icon>
            </div>
          </ng-container>
        </div>
        <ng-container
          *ngIf="onboarding.location.profile === 'smallbusiness' && onboarding.networkSettings?.currentSsid?.length"
        >
          <p>{{ 'health.networkInformation.onboardingEmployee' | translate }}</p>
          <div class="input" *ngIf="onboarding.networkSettings.employee.ssid">
            <div class="label">{{ 'health.networkInformation.ssid' | translate }}:</div>
            <ng-container *ngIf="!onboarding.networkSettings.employee.ssidEdit">
              <div class="value" *ngIf="!onboarding.networkSettings.employee.ssid.value.length">
                {{ onboarding.networkSettings.employee.currentSsid }}
              </div>
              <div class="value" *ngIf="onboarding.networkSettings.employee.ssid.value.length">
                {{ onboarding.networkSettings.employee.ssid.value }}
              </div>
              <div class="controls">
                <icon
                  name="icon-edit"
                  tooltip="{{ 'newCustomer.networkSettings.editSSID' | translate }}"
                  [noTouchToolTip]="true"
                  (click)="onboarding.networkSettings.employee.ssidEdit = !onboarding.networkSettings.employee.ssidEdit"
                ></icon>
              </div>
            </ng-container>
            <ng-container *ngIf="onboarding.networkSettings.employee.ssidEdit">
              <labeled-input>
                <input type="text" [formControl]="onboarding.networkSettings.employee.ssid" autofocus />
                <div info error *ngIf="onboarding.networkSettings.employee.errorSsid">
                  {{ 'techdash.infoArea.ssidValidation' | translate }}
                </div>
              </labeled-input>
              <div class="controls">
                <icon
                  name="icon-check"
                  class="confirm"
                  tooltip="{{ 'newCustomer.networkSettings.saveSSID' | translate }}"
                  [noTouchToolTip]="true"
                  (click)="setEmployeeNetwork('ssid')"
                ></icon>
                <icon
                  name="icon-close"
                  class="cancel size-0_8"
                  tooltip="{{ 'newCustomer.networkSettings.cancel' | translate }}"
                  [noTouchToolTip]="true"
                  (click)="cancelEmployeeSsid()"
                  *ngIf="onboarding.networkSettings.employee.password"
                ></icon>
                <icon
                  name="icon-close"
                  class="cancel size-0_8"
                  tooltip="{{ 'newCustomer.networkSettings.clear' | translate }}"
                  [noTouchToolTip]="true"
                  (click)="onboarding.networkSettings.employee.employee.ssid.reset()"
                  *ngIf="!onboarding.networkSettings.employee.password"
                ></icon>
              </div>
            </ng-container>
          </div>
          <div class="input" *ngIf="onboarding.networkSettings.employee.password">
            <div class="label">{{ 'health.networkInformation.password' | translate }}:</div>
            <ng-container *ngIf="!onboarding.networkSettings.employee.passwordEdit">
              <div class="value">**********</div>
              <div class="controls">
                <icon
                  name="icon-edit"
                  tooltip="{{ 'newCustomer.networkSettings.editPassword' | translate }}"
                  [noTouchToolTip]="true"
                  (click)="
                    onboarding.networkSettings.employee.passwordEdit = !onboarding.networkSettings.employee.passwordEdit
                  "
                ></icon>
              </div>
            </ng-container>
            <ng-container *ngIf="onboarding.networkSettings.employee.passwordEdit">
              <labeled-input>
                <input type="password" [formControl]="onboarding.networkSettings.employee.password" autofocus />
                <div info error *ngIf="onboarding.networkSettings.employee.errorPassword">
                  {{ 'techdash.infoArea.passwordValidation' | translate }}
                </div>
              </labeled-input>
              <div class="controls">
                <icon
                  name="icon-check"
                  class="confirm"
                  tooltip="{{ 'newCustomer.networkSettings.savePassword' | translate }}"
                  [noTouchToolTip]="true"
                  (click)="setEmployeeNetwork('password')"
                ></icon>
                <icon
                  name="icon-close"
                  class="cancel size-0_8"
                  tooltip="{{ 'newCustomer.networkSettings.cancel' | translate }}"
                  [noTouchToolTip]="true"
                  (click)="cancelEmployeePassword()"
                ></icon>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="onboarding.flow === 'passwordReset'">
      <div class="passwordReset">
        <div class="label">{{ 'newCustomer.passwordReset.account' | translate }}</div>
        <div class="email">{{ onboarding.customer.email }}</div>
        <div class="label">{{ 'newCustomer.passwordReset.description' | translate }}</div>
        <div class="toggler" (click)="onboardingToggleVerifyAndPassword()">
          <div class="checkbox">
            <label class="toggle" [class.checked]="onboarding.passwordReset.verifyAndPasswordReset"></label>
          </div>
          <span>{{ 'newCustomer.verifyAndPasswordReset' | translate }}</span>
        </div>
        <div
          class="toggler"
          [class.disable]="onboarding.passwordReset.verifyAndPasswordReset"
          (click)="onboarding.passwordReset.sendVerifyEmail = !onboarding.passwordReset.sendVerifyEmail"
          *ngIf="role === 'groupAdmin'"
        >
          <div class="checkbox">
            <label class="toggle" [class.checked]="onboarding.passwordReset.sendVerifyEmail"></label>
          </div>
          <span>{{ 'newCustomer.sendVerifyEmail' | translate }}</span>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <ng-container controls>
    <button
      class="super-primary medium"
      (click)="confirmNewCustomer(true)"
      *ngIf="onboarding.flow === 'createCustomer'"
    >
      {{ 'newCustomer.addCustomer' | translate }}
    </button>
    <button
      class="super-primary medium"
      (click)="newCustomerFlow('onboarding', 'networkSettings')"
      *ngIf="onboarding.flow === 'addNode'"
    >
      {{ 'newCustomer.next' | translate }}
    </button>
    <button
      class="tertiary light medium"
      (click)="newCustomerFlow('onboarding', 'addNode')"
      *ngIf="onboarding.flow === 'networkSettings'"
    >
      {{ 'newCustomer.back' | translate }}
    </button>
    <button
      class="super-primary medium"
      (click)="newCustomerFlow('onboarding', 'passwordReset')"
      *ngIf="onboarding.flow === 'networkSettings'"
    >
      {{ 'newCustomer.next' | translate }}
    </button>
    <button
      class="tertiary light medium"
      (click)="newCustomerFlow('onboarding', 'networkSettings')"
      *ngIf="onboarding.flow === 'passwordReset'"
    >
      {{ 'newCustomer.back' | translate }}
    </button>
    <button class="super-primary medium" (click)="finalizeCustomer()" *ngIf="onboarding.flow === 'passwordReset'">
      {{ 'newCustomer.done' | translate }}
    </button>
  </ng-container>
</modal>
<modal class="addCustomer" mode="compact" (overlayAction)="hideCustomerDialog()" *ngIf="show.legacy">
  <ng-container title>{{ 'newCustomer.title' | translate }}</ng-container>
  <ng-container content>
    <div class="grid">
      <div class="label">{{ 'newCustomer.name' | translate }}:</div>
      <input
        [formControl]="customerName"
        trimValue
        type="text"
        placeholder="{{ 'newCustomer.placeholders.name' | translate }}"
        autocomplete="none"
        autoreadonly
      />
    </div>
    <div class="grid">
      <div class="label">{{ 'newCustomer.email' | translate }}:</div>
      <input
        [formControl]="customerEmail"
        type="text"
        placeholder="{{ 'newCustomer.placeholders.email' | translate }}"
        autocomplete="none"
        autoreadonly
      />
    </div>
    <div class="grid">
      <div class="label">{{ 'newCustomer.password' | translate }}:</div>
      <input
        [formControl]="customerPassword"
        type="password"
        placeholder="{{ 'newCustomer.placeholders.passwordOptional' | translate }}"
        autocomplete="none"
        autoreadonly
      />
    </div>
    <div class="grid" *ngIf="role != 'admin'">
      <div class="label">{{ 'newCustomer.accountId' | translate }}:</div>
      <input
        [formControl]="customerAccountId"
        type="text"
        placeholder="{{ 'newCustomer.placeholders.accountId' | translate }}"
        autocomplete="none"
        autoreadonly
      />
    </div>
    <div class="grid" *ngIf="groups.length && role != 'admin'">
      <div class="label top">{{ 'newCustomer.group' | translate }}:</div>
      <div class="groups">
        <div
          class="group"
          [class.selected]="group.id == groupSelected"
          *ngFor="let group of groups"
          (click)="selectGroup(group)"
        >
          {{ group.name }}
          <icon name="icon-check" class="size-0_7" *ngIf="group.id == groupSelected"></icon>
        </div>
      </div>
    </div>
    <div class="grid" *ngIf="role === 'groupAdmin' || role === 'groupSupportTechnician'">
      <div class="label">{{ 'newCustomer.profile' | translate }}:</div>
      <div><slidetoggler [options]="profileItems" (toggle)="profileSelectedItem = $event"></slidetoggler></div>
    </div>
    <div class="toggler" (click)="partnerIdSameAsGroupId = !partnerIdSameAsGroupId" *ngIf="role != 'admin'">
      <div class="circle">
        <icon name="icon-check" class="size-0_7" *ngIf="partnerIdSameAsGroupId"></icon>
      </div>
      {{ 'newCustomer.partnerId' | translate }}
    </div>
    <div class="toggler" (click)="verifyAndPasswordReset = !verifyAndPasswordReset" *ngIf="role === 'groupAdmin'">
      <div class="circle">
        <icon name="icon-check" class="size-0_7" *ngIf="verifyAndPasswordReset"></icon>
      </div>
      {{ 'newCustomer.verifyAndPasswordReset' | translate }}
    </div>
    <div
      class="toggler"
      (click)="sendVerifyEmail = !sendVerifyEmail"
      *ngIf="role === 'groupAdmin' && !verifyAndPasswordReset"
    >
      <div class="circle">
        <icon name="icon-check" class="size-0_7" *ngIf="sendVerifyEmail"></icon>
      </div>
      {{ 'newCustomer.sendVerifyEmail' | translate }}
    </div>
  </ng-container>
  <ng-container controls>
    <button class="tertiary light" (click)="hideCustomerDialog()">
      {{ 'newCustomer.cancel' | translate }}
    </button>
    <button class="super-primary" (click)="confirmNewCustomer()">
      {{ 'newCustomer.addCustomer' | translate }}
    </button>
  </ng-container>
</modal>
<modal class="qrcode" mode="normal" (overlayAction)="closeQrCode()" *ngIf="show.qrcode">
  <ng-container title> {{ 'newCustomer.addNode.scanQrCode' | translate }}</ng-container>
  <ng-container content>
    <div id="qrcode-scanner"></div>
  </ng-container>
  <ng-container controls>
    <button class="tertiary light" (click)="closeQrCode()">
      {{ 'newCustomer.cancel' | translate }}
    </button>
  </ng-container>
</modal>
