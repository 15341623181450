<div *ngFor="let node of nodes" class="align-middle row infoDeviceRow">
  <div class="infoDeviceColumn">
    <icon [path]="node.kind.icon" class="size-2" *ngIf="node && node?.kind && node?.kind?.icon"></icon>
  </div>
  <div class="align-middle">
    <span class="align-middle infoDeviceName">
      {{ node?.nickname || node?.name || node?.defaultName || 'Extender' }}
    </span>
    <br />
    <span
      class="align-middle infoDeviceDetail {{ node?.connectionState }}"
      *ngIf="node?.connectionState && node?.connectionState === 'connected'"
    >
      {{ 'health.networkPerformance.extenderConnections.online' | translate }}
    </span>
    <span
      class="align-middle infoDeviceDetail {{ node?.connectionState }}"
      *ngIf="node?.connectionState && node?.connectionState !== 'connected'"
    >
      {{ 'health.networkPerformance.extenderConnections.offline' | translate }}
    </span>
  </div>
</div>
