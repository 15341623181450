import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectBaseUrl, selectCustomerIdentification } from '../customer/customer.selectors';
import { RemoveSingleActionReducer } from '../reducers';
import { featureName, reducer } from './captive-portal.reducer';

export const selectCaptivePortal = createFeatureSelector<RemoveSingleActionReducer<typeof reducer>>(featureName);
export const selectCaptivePortalState = ({
  authenticated,
  url,
  mywifi
}: {
  authenticated: boolean;
  url?: string;
  mywifi?: string;
}) =>
  createSelector(selectCaptivePortal, selectCustomerIdentification, (captivePortal, customer) =>
    !customer.customerid ||
    !customer.locationid ||
    !captivePortal.identification.network ||
    !captivePortal.identification ||
    !url ||
    !mywifi ||
    !authenticated
      ? 'error'
      : captivePortal.identification.state
  );

export const selectCaptivePortalErrorMsg = createSelector(
  selectCaptivePortal,
  (captivePortal) => captivePortal.identification.campaignErrorMsg
);

export const selectCaptivePortalMode = createSelector(
  selectCaptivePortal,
  (captivePortal) => captivePortal.identification.mode
);

export const selectCaptivePortalBaseUrl = createSelector(
  selectBaseUrl(),
  (baseUrl) => `${baseUrl}/secondaryNetworks/captivePortals`
);

export const selectCaptivePortalNetworkUrl = createSelector(
  selectCaptivePortal,
  selectCaptivePortalBaseUrl,
  (captivePortal, baseUrl) => `${baseUrl}/${captivePortal.identification.network}`
);
export const selectCaptivePortalLoginOptions = createSelector(
  selectCaptivePortal,
  (captivePortal) => captivePortal.loginOptions
);
export const selectCaptivePortalConfig = createSelector(selectCaptivePortal, (captivePortal) => captivePortal.config);
export const selectCaptivePortalIsUprise = createSelector(
  selectCaptivePortal,
  (captivePortal) => captivePortal.identification.mode === 'uprise'
);

export const selectCaptivePortalLogo = createSelector(
  selectCaptivePortal,
  (captivePortal) => captivePortal.config.branding.logo
);

export const selectCaptivePortalLogoLoadingMode = createSelector(
  selectCaptivePortal,
  (captivePortal) => captivePortal.logoLoadingMode
);

export const selectCaptivePortalBrandingTitleText = createSelector(
  selectCaptivePortal,
  (captivePortal) => captivePortal.config.branding.titleText
);

export const selectCaptivePortalBrandingFooterText = createSelector(
  selectCaptivePortal,
  (captivePortal) => captivePortal.config.branding.campaignFooter.support
);

export const selectCaptivePortalCustomTermsText = createSelector(
  selectCaptivePortal,
  (captivePortal) => captivePortal.config.advancedOptions.termsCondition.customTerms.text
);

export const selectCaptivePortalPreviewMode = createSelector(
  selectCaptivePortal,
  (captivePortal) => captivePortal.preview.mode
);

export const selectCaptivePortalPreviewUrl = createSelector(
  selectCaptivePortal,
  (captivePortal) => captivePortal.preview.url
);

export const selectCaptivePortalPreviewErrorMsg = createSelector(
  selectCaptivePortal,
  (captivePortal) => captivePortal.preview.errorMsg
);

export const selectCaptivePortalPublishMode = createSelector(
  selectCaptivePortal,
  (captivePortal) => captivePortal.publish.mode
);
