import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GuidedTroubleshootingState } from './guidedTroubleshooting.reducer';

export const selectGuidedTroubleshootingState =
  createFeatureSelector<GuidedTroubleshootingState>('guidedTroubleshooting');

export const selectMessagesWithSelections = createSelector(
  selectGuidedTroubleshootingState,
  (state: GuidedTroubleshootingState) => state.messagesWithSelections
);

export const selectGuidedTroubleShootingSessionInProgress = createSelector(
  selectGuidedTroubleshootingState,
  (state: GuidedTroubleshootingState) => state.sessionInProgress
);
