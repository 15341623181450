<svg #container>
  <svg:g [attr.transform]="'translate(' + margins.left + ',' + margins.top + ')'">
    <svg:g
      [attr.visibility]="nodata.show ? 'hidden' : 'visible'"
      [attr.class]="tick.class"
      [tickVisual]="tick"
      *ngFor="let tick of xAxis"
    ></svg:g>
    <svg:g [attr.class]="tick.class" [tickVisual]="tick" *ngFor="let tick of yAxisGrid"></svg:g>
    <svg:g [attr.class]="tick.class + ' ylabel'" *ngFor="let tick of yAxisLabels">
      <svg:g [attr.transform]="tick.transform">
        <svg:rect x="0" y="-20" width="60" height="20" fill="rgba(235, 237, 240, 0.5)" rx="5" ry="5"></svg:rect>
        <svg:text [attr.x]="30" [attr.y]="-5" [attr.dx]="0" [attr.dy]="0" [attr.transform]="tick.text.transform">
          {{ tick.value }}
        </svg:text>
      </svg:g>
    </svg:g>
    <svg:g
      [attr.class]="bar.class"
      opacity="0.75"
      [barVisual]="bar"
      *ngFor="let bar of bars"
      (mouseenter)="showTooltip(bar)"
      (mouseleave)="hideTooltip()"
    ></svg:g>
    <svg:g class="nodata" *ngIf="nodata.show" [attr.transform]="'translate(' + nodata.x + ',' + nodata.y + ')'">
      <svg:rect x="-60" y="-18" width="120" height="40" rx="5" ry="5" fill="rgba(0, 0, 0, 0.35)"></svg:rect>
      <svg:text x="0" y="0" class="title">{{ 'speedtests.speednode.noDataTitle' | translate }}</svg:text>
      <svg:text x="0" y="0" dy="12">{{ 'speedtests.speednode.noDataMessage' | translate }}</svg:text>
    </svg:g>
  </svg:g>
</svg>
<div
  id="tooltip-{{ id }}"
  class="tooltip"
  [class.show]="tooltip?.show"
  [style.bottom.px]="tooltip?.bottom"
  [style.left.px]="tooltip?.left"
>
  <div *ngFor="let test of tooltip?.tests">
    {{ 'speedtests.speednode.' + test?.type | translate }}:
    <ng-container *ngIf="test?.timestamp?.mode == '24h'">
      <b>{{ test?.value | number: '1.0-2' }}</b> Mbps
      {{ 'speedtests.speednode.at' | translate }}
      <b>{{ test?.timestamp?.time }}</b>
    </ng-container>
    <ng-container *ngIf="test?.timestamp?.mode == '7d'">
      <b>{{ test?.value | number: '1.0-2' }}</b> Mbps,
      <b>{{ test?.timestamp?.date }}</b>
      {{ 'speedtests.speednode.at' | translate }}
      <b>{{ test?.timestamp?.time }}</b>
    </ng-container>
    <ng-container *ngIf="test?.speedDetails?.length">, {{ test.speedDetails }}</ng-container>
  </div>
</div>
