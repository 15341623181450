import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'line-tick-chart',
  templateUrl: './line-tick-chart.component.html',
  styleUrls: ['./line-tick-chart.component.scss']
})
export class LineTickChartComponent {
  @Input()
  data: {
    ticks: {
      busy?: string;
      color?: string;
      border?: string;
      time: string;
      state?: string;
    }[];
    axis: string[];
  };

  @Input()
  hasOfflines: boolean = false;

  @Input()
  status: string = null;

  @Input()
  stateTemplate: TemplateRef<unknown>;

  tooltipOverflowMove: number = null;
  translatePrefix = '';

  constructor() {}

  mouseEnter(elm: HTMLDivElement): void {
    this.tooltipOverflowMove = null;

    const info = elm.getElementsByClassName('line-tick-chart-info');
    const time = elm.getElementsByClassName('time');
    const elmWidth = Math.max(
      info?.[0]?.getBoundingClientRect()?.width || 0,
      time?.[0]?.getBoundingClientRect()?.width || 0
    );

    const left = 0;
    const right = left + window.innerWidth - 20;
    const elmLeft = elm.getBoundingClientRect().x - elmWidth / 2;
    const elmRight = elmLeft + elmWidth;

    if (left > elmLeft) {
      this.tooltipOverflowMove = left - elmLeft;
    }

    if (right < elmRight) {
      this.tooltipOverflowMove = right - elmRight;
    }
  }
}
