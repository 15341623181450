<div class="row" id="conf-powermanagement" *ngIf="powerCapable">
  <div class="col-xs-12">
    <collapsiblebox [show]="expand" [collapsible]="true" (toggle)="toggleExpand()">
      <div header>{{ 'configurations.powermanagement.title' | translate }}</div>
      <div container *ngIf="expand">
        <div class="columns">
          <div class="column" *ngrxLet="location$ as location">
            <div class="row">
              <div class="key">{{ 'configurations.powermanagement.powerAwareness' | translate }}</div>
              <div class="value">
                <slider-toggle-mobile-aware
                  [editable]="!(powerState.status === 'PM_STATUS_ACTIVATING' || powerState.status === 'PM_STATUS_DEACTIVATING')"
                  [options]="modeItems"
                  (toggle)="action('powermode', $event)"
                ></slider-toggle-mobile-aware>
              </div>
            </div>
            <div class="row">
              <div class="key">
                {{ 'configurations.powermanagement.lowPowerMode' | translate }}
                <icon
                  class="icon-info"
                  name="icon-info"
                  [tooltip]="'configurations.powermanagement.forceTooltip' | translate"
                ></icon>
              </div>
              <div class="value">
                <slider-toggle-mobile-aware
                  [editable]="!(powerState.enable !== true ||
                    powerState.status === 'PM_STATUS_ACTIVATING' ||
                    powerState.status === 'PM_STATUS_DEACTIVATING' ||
                    isOffline)"
                  [options]="forceModeItems"
                  (toggle)="action('forceMode', $event)"
                >
                </slider-toggle-mobile-aware>
                <div
                  class="slide-hint"
                  *ngIf="powerState.status === 'PM_STATUS_ACTIVATING' || powerState.status === 'PM_STATUS_DEACTIVATING'"
                >
                  <icon class="icon-loading" name="icon-loading"></icon>
                  {{ 'configurations.powermanagement.timeoutMessage' | translate }}
                </div>
                <div class="slide-hint" *ngIf="powerState.status === 'PM_STATUS_UNKNOWN'">
                  {{ 'configurations.powermanagement.unknownMessage' | translate }}
                </div>
                <div
                  class="side-hints"
                  [class.disabled]="powerState.enable !== true"
                  *ngIf="powerState.status === 'PM_STATUS_ACTIVE'"
                >
                  <div
                    class="slide-hint"
                    *ngIf="powerState.transitionTrigger === 'PM_TRIGGER_SUPPORT_FORCED' && timeRemaining > 0"
                  >
                    {{ 'configurations.powermanagement.autoDeactivateIn' | translate }}
                    {{ getTimeString() }}
                  </div>
                  <div
                    class="slide-hint"
                    *ngIf="powerState.transitionTrigger === 'PM_TRIGGER_SUPPORT_FORCED' && timeRemaining < 0"
                  >
                    {{ 'configurations.powermanagement.autoDeactivatePending' | translate }}
                  </div>
                  <div class="slide-hint" *ngIf="powerState.transitionTrigger === 'PM_TRIGGER_UNKNOWN'">
                    {{ 'configurations.powermanagement.activatedByUnknown' | translate }}
                  </div>
                  <div class="slide-hint" *ngIf="powerState.transitionTrigger === 'PM_TRIGGER_SCHEDULED'">
                    {{ 'configurations.powermanagement.activatedBySchedule' | translate }}
                  </div>
                  <div class="slide-hint" *ngIf="powerState.transitionTrigger === 'PM_TRIGGER_CUSTOMER_FORCED'">
                    {{ 'configurations.powermanagement.activatedByCustomer' | translate }}
                  </div>
                  <div class="slide-hint" *ngIf="powerState.transitionTrigger === 'PM_TRIGGER_SUPPORT_FORCED'">
                    {{ 'configurations.powermanagement.activatedBySupport' | translate }}
                  </div>
                  <div
                    class="slide-hint"
                    *ngIf="powerState.transitionTrigger === 'PM_TRIGGER_BANDWIDTH_THRESHOLD_EXCEEDED'"
                  >
                    {{ 'configurations.powermanagement.activatedByBandwidth' | translate }}
                  </div>
                  <div class="slide-hint" *ngIf="powerState.transitionTrigger === 'PM_TRIGGER_CONFIG_CHANGED'">
                    {{ 'configurations.powermanagement.activatedByConfigChanged' | translate }}
                  </div>
                  <div class="slide-hint" *ngIf="powerState.transitionTrigger === 'PM_TRIGGER_NETWORK_ACTIVITY'">
                    {{ 'configurations.powermanagement.activatedByNetworkActivity' | translate }}
                  </div>
                  <div class="slide-hint" *ngIf="powerState.transitionTrigger === 'PM_TRIGGER_INTERFERENCE'">
                    {{ 'configurations.powermanagement.activatedByInterference' | translate }}
                  </div>
                  <div class="slide-hint" *ngIf="powerState.transitionTrigger === 'PM_TRIGGER_TOPOLOGY_FAILED'">
                    {{ 'configurations.powermanagement.activatedByTopologyFailed' | translate }}
                  </div>
                  <div class="slide-hint" *ngIf="powerState.transitionTrigger === 'PM_TRIGGER_EXPIRED'">
                    {{ 'configurations.powermanagement.activatedByExpired' | translate }}
                  </div>
                  <div class="slide-hint" *ngIf="powerState.transitionTrigger === 'PM_TRIGGER_TOPOLOGY_DEVIATED'">
                    {{ 'configurations.powermanagement.activatedByTopologyDeviated' | translate }}
                  </div>
                  <div class="slide-hint" *ngIf="powerState.transitionTrigger === 'PM_TRIGGER_LINK_DISCOVERY'">
                    {{ 'configurations.powermanagement.activatedByLinkDiscovery' | translate }}
                  </div>
                </div>
                <div
                  class="side-hints"
                  [class.disabled]="powerState.enable !== true"
                  *ngIf="powerState.status === 'PM_STATUS_INACTIVE'"
                >
                  <div class="slide-hint" *ngIf="powerState.transitionTrigger === 'PM_TRIGGER_UNKNOWN'">
                    {{ 'configurations.powermanagement.deactivatedByUnknown' | translate }}
                  </div>
                  <div class="slide-hint" *ngIf="powerState.transitionTrigger === 'PM_TRIGGER_SCHEDULED'">
                    {{ 'configurations.powermanagement.deactivatedBySchedule' | translate }}
                  </div>
                  <div class="slide-hint" *ngIf="powerState.transitionTrigger === 'PM_TRIGGER_CUSTOMER_FORCED'">
                    {{ 'configurations.powermanagement.deactivatedByCustomer' | translate }}
                  </div>
                  <div class="slide-hint" *ngIf="powerState.transitionTrigger === 'PM_TRIGGER_SUPPORT_FORCED'">
                    {{ 'configurations.powermanagement.deactivatedBySupport' | translate }}
                  </div>
                  <div
                    class="slide-hint"
                    *ngIf="powerState.transitionTrigger === 'PM_TRIGGER_BANDWIDTH_THRESHOLD_EXCEEDED'"
                  >
                    {{ 'configurations.powermanagement.deactivatedByBandwidth' | translate }}
                  </div>
                  <div class="slide-hint" *ngIf="powerState.transitionTrigger === 'PM_TRIGGER_CONFIG_CHANGED'">
                    {{ 'configurations.powermanagement.deactivatedByConfigChanged' | translate }}
                  </div>
                  <div class="slide-hint" *ngIf="powerState.transitionTrigger === 'PM_TRIGGER_NETWORK_ACTIVITY'">
                    {{ 'configurations.powermanagement.deactivatedByNetworkActivity' | translate }}
                  </div>
                  <div class="slide-hint" *ngIf="powerState.transitionTrigger === 'PM_TRIGGER_INTERFERENCE'">
                    {{ 'configurations.powermanagement.deactivatedByInterference' | translate }}
                  </div>
                  <div class="slide-hint" *ngIf="powerState.transitionTrigger === 'PM_TRIGGER_TOPOLOGY_FAILED'">
                    {{ 'configurations.powermanagement.deactivatedByTopologyFailed' | translate }}
                  </div>
                  <div class="slide-hint" *ngIf="powerState.transitionTrigger === 'PM_TRIGGER_EXPIRED'">
                    {{ 'configurations.powermanagement.deactivatedByExpired' | translate }}
                  </div>
                  <div class="slide-hint" *ngIf="powerState.transitionTrigger === 'PM_TRIGGER_TOPOLOGY_DEVIATED'">
                    {{ 'configurations.powermanagement.deactivatedByTopologyDeviated' | translate }}
                  </div>
                  <div class="slide-hint" *ngIf="powerState.transitionTrigger === 'PM_TRIGGER_LINK_DISCOVERY'">
                    {{ 'configurations.powermanagement.deactivatedByLinkDiscovery' | translate }}
                  </div>
                  <div class="slide-hint" *ngIf="powerState.transitionTrigger === 'PM_TRIGGER_LOCATION_OFFLINE'">
                    {{ 'configurations.powermanagement.deactivatedByLocationOffline' | translate }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </collapsiblebox>
  </div>
</div>
