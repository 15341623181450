import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import dayjs from 'dayjs';
import { IDevice, IFocus } from 'src/app/lib/interfaces/interface';
import { CustomerService } from 'src/app/lib/services/customer.service';
import { ToastService } from 'src/app/lib/services/toast.service';
import { selectPipeLocationOnChangeOptimistic } from 'src/app/store/customer/customer.selectors';

@UntilDestroy()
@Component({
  selector: 'focus-modal',
  templateUrl: './focus-modal.component.html',
  styleUrls: ['./focus-modal.component.scss']
})
export class FocusModalComponent implements OnInit {
  @Input()
  device: IDevice & { focused?: boolean } = null;

  @Output()
  refreshDevice = new EventEmitter<IDevice>();

  @Output()
  closeFocusModal = new EventEmitter<void>();

  focuses: IFocus[] = [];
  focusScheduleModalOpen = false;
  focusScheduleDeleteLoading = '';
  focusSchedule: {
    id?: string;
    name?: FormControl;
    enabled?: FormControl;
    schedules?: {
      startTime: FormControl;
      endTime: FormControl;
      selectedDays: number[];
    }[];
    mode?: 'add' | 'update';
  } = {};

  constructor(private store: Store, private toast: ToastService, private customerService: CustomerService) {}

  ngOnInit(): void {
    this.store
      .pipe(selectPipeLocationOnChangeOptimistic)
      .pipe(untilDestroyed(this))
      .subscribe((location) => {
        if (location.isUtilizingFocuses) this.getFocuses();
      });
  }

  getFocuses(): void {
    this.customerService.getFocuses$().subscribe((response) => {
      this.focuses = response;
      this.focuses.sort((a, b) => (a.name > b.name ? 1 : -1));
    });
  }

  toggleFocus(focus: IFocus, enabled: boolean): void {
    const focusEnable: IFocus = {
      id: focus.id,
      enabled
    };
    this.customerService.patchFocuses$(focusEnable).subscribe(
      () => {
        this.toast.success('devices.device.focusUpdated', 'devices.device.success');
        this.getFocuses();
        this.refreshDevice.emit(this.device);
      },
      (error) => {
        this.toast.error(error.error.error.message, error.error.error.name);
      }
    );
  }

  editFocusSchedule(focus: IFocus): void {
    this.focusSchedule = {
      id: focus.id,
      name: new FormControl(focus.name),
      enabled: new FormControl(focus.enabled),
      schedules: [
        {
          startTime: new FormControl(focus.schedule?.schedules[0]?.startTime || ''),
          endTime: new FormControl(focus.schedule?.schedules[0]?.endTime || ''),
          selectedDays: focus.schedule?.schedules[0]?.daysOfWeek || []
        }
      ],
      mode: 'update'
    };
    this.focusScheduleModalOpen = true;
  }

  openFocusScheduleModal(): void {
    this.focusSchedule = {
      name: new FormControl(''),
      enabled: new FormControl(true),
      schedules: [
        {
          startTime: new FormControl(''),
          endTime: new FormControl(''),
          selectedDays: []
        }
      ],
      mode: 'add'
    };
    this.focusScheduleModalOpen = true;
  }

  closeFocusScheduleModal(): void {
    this.focusSchedule = {};
    this.focusScheduleModalOpen = false;
  }

  deleteFocusSchedule(focus: IFocus): void {
    this.focusScheduleDeleteLoading = focus.id;
    this.customerService.deleteFocus$(focus.id).subscribe(
      () => {
        this.toast.success('devices.device.focusDeleted', 'devices.device.success');
        this.focusScheduleDeleteLoading = '';
        this.getFocuses();
        this.refreshDevice.emit(this.device);
      },
      (error) => {
        this.toast.error(error.error.error.message, error.error.error.name);
      }
    );
  }

  processDay(day: number): string {
    return dayjs().day(day).format('ddd');
  }

  checkDeviceIsUsingFocus(focus: IFocus): boolean {
    const appliesToThisDevice = this.device.focuses.findIndex((deviceFocus) => deviceFocus.id === focus.id) > -1;
    return appliesToThisDevice && focus.enabled;
  }

  closeModal(): void {
    this.closeFocusModal.emit();
  }
}
