<div
  #modal
  class="toolmodal"
  [class.show]="show"
  [style.left.px]="modalState.position?.left"
  [style.top.px]="modalState.position?.top"
  [style.minWidth.px]="pos.width"
  [style.zIndex]="stack"
>
  <div class="toolicon">
    <icon name="icon-snap" class="size-0_8" [class.hide]="isDocked() || !modalState.dockable" (click)="dock()"></icon>
    <icon
      name="icon-grip-vert"
      class="size-0_8"
      [class.hide]="!modalState.dragable"
      (mousedown)="startDrag($event)"
    ></icon>
  </div>
  <div class="modalcontent">
    <ng-content></ng-content>
  </div>
</div>
