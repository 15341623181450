<div class="row" id="conf-optimization">
  <div class="col-xs-12">
    <collapsiblebox [show]="expand" [collapsible]="true" (toggle)="toggleExpand()">
      <div header>{{ 'configurations.optimization.title' | translate }}</div>
      <div container *ngIf="expand">
        <div class="columns">
          <div class="column">
            <div class="row">
              <div class="key">{{ 'configurations.optimization.autoOptimize' | translate }}</div>
              <div class="value">
                <slider-toggle-mobile-aware
                  [options]="autoOptimizeItems$ | async"
                  (toggle)="autoOptimize($event)"
                ></slider-toggle-mobile-aware>
              </div>
            </div>
            <div class="row">
              <div class="key">{{ 'configurations.optimization.dfsMode' | translate }}</div>
              <div class="value">
                <slider-toggle-mobile-aware
                  [options]="dfsModeItems$ | async"
                  (toggle)="action('dfsMode', $event)"
                ></slider-toggle-mobile-aware>
              </div>
            </div>
            <div class="row">
              <div class="key">{{ 'configurations.optimization.zeroWaitDfsMode' | translate }}</div>
              <div class="value">
                <slider-toggle-mobile-aware
                  [options]="zeroWaitDfsModeItems$ | async"
                  [editable]="plume.isSomeAdminRole()"
                  (toggle)="action('zeroWaitDfsMode', $event)"
                ></slider-toggle-mobile-aware>
              </div>
            </div>
            <div class="row" *ngIf="!plume.cloudVersionAbove1_124()">
              <div class="key">{{ 'configurations.optimization.prefer160hzMode' | translate }}</div>
              <div class="value">
                <slider-toggle-mobile-aware
                  [options]="prefer160MhzItems$ | async"
                  [editable]="plume.isSomeAdminRole()"
                  (toggle)="action('prefer160MhzMode', $event)"
                ></slider-toggle-mobile-aware>
              </div>
            </div>
            <div class="row" *ngIf="plume.cloudVersionAbove1_124()">
              <div class="key">{{ 'configurations.optimization.max24GhzChannelWidth' | translate }}</div>
              <div class="value">
                <slider-toggle-mobile-aware
                  [options]="max24GhzChannelWidthItems$ | async"
                  [editable]="plume.isSomeAdminRole()"
                  (toggle)="action('max24GhzChannelWidthMode', $event)"
                ></slider-toggle-mobile-aware>
              </div>
            </div>
            <div class="row" *ngIf="plume.cloudVersionAbove1_124()">
              <div class="key">{{ 'configurations.optimization.max5GhzChannelWidth' | translate }}</div>
              <div class="value">
                <slider-toggle-mobile-aware
                  [options]="max5GhzChannelWidthItems$ | async"
                  [editable]="plume.isSomeAdminRole()"
                  (toggle)="action('max5GhzChannelWidthMode', $event)"
                ></slider-toggle-mobile-aware>
              </div>
            </div>
            <div class="row" *ngIf="plume.cloudVersionAbove1_124()">
              <div class="key">{{ 'configurations.optimization.max6GhzChannelWidth' | translate }}</div>
              <div class="value">
                <slider-toggle-mobile-aware
                  [options]="max6GhzChannelWidthItems$ | async"
                  [editable]="plume.isSomeAdminRole()"
                  (toggle)="action('max6GhzChannelWidthMode', $event)"
                ></slider-toggle-mobile-aware>
              </div>
            </div>
            <div class="row">
              <div class="key">{{ 'configurations.optimization.unii4Mode' | translate }}</div>
              <div class="value">
                <slider-toggle-mobile-aware
                  [options]="uniiItems$ | async"
                  [editable]="plume.isSomeAdminRole()"
                  (toggle)="action('unii4Mode', $event)"
                ></slider-toggle-mobile-aware>
              </div>
            </div>
            <div class="row" *ngIf="plume.cloudVersionAbove1_88()">
              <div class="key">{{ 'configurations.optimization.hopPenalty' | translate }}</div>
              <div class="value">
                <slider-toggle-mobile-aware
                  [options]="hopPenaltyItems$ | async"
                  [editable]="plume.isSomeAdminRole()"
                  (toggle)="action('hopPenaltyMode', $event)"
                ></slider-toggle-mobile-aware>
              </div>
            </div>
            <div class="row" *ngIf="plume.cloudVersionAbove1_88()">
              <div class="key">{{ 'configurations.optimization.dfsPreCacScheduler' | translate }}</div>
              <div class="value">
                <slider-toggle-mobile-aware
                  [options]="dfsPreCacSchedulerItems$ | async"
                  [editable]="plume.isSomeAdminRole()"
                  (toggle)="action('dfsPreCacSchedulerItems', $event)"
                ></slider-toggle-mobile-aware>
              </div>
            </div>
            <div class="row">
              <div class="key">{{ 'configurations.optimization.blacklistedLinks' | translate }}</div>
              <div class="value">
                {{ (blacklistedLinks$ | async)?.length ? '' : ('configurations.optimization.none' | translate) }}
                <icon
                  name="icon-info"
                  tooltip="{{ 'configurations.optimization.tooltipBlacklistedLinks' | translate }}"
                ></icon>
              </div>
            </div>
            <div class="row">
              <div class="key">{{ 'configurations.optimization.title' | translate }}</div>
              <div class="controls solo" *ngrxLet="enableOptimizationButton$ as enableOptimizationButton">
                <button
                  class="action small"
                  [class.disabled]="!validateOptimization() || !enableOptimizationButton"
                  (click)="enableOptimizationButton && triggerOptimize()"
                  *ngIf="optimizeState !== 'loading' && nodesPool?.length > 0"
                >
                  {{ getOptimizationLabel() | translate }}
                  <icon name="icon-refresh" [class.busy]="optimizeState === 'running'"></icon>
                  <div class="progressbar" *ngIf="optimizeState === 'running'"><div class="indeterminate"></div></div>
                </button>
                <button class="action medium disabled" *ngIf="optimizeState !== 'loading' && nodesPool?.length < 1">
                  {{ 'configurations.optimization.noNodes' | translate }}
                </button>
                <button class="action medium disabled" *ngIf="optimizeState === 'loading' && nodesPool?.length < 1">
                  {{ 'configurations.loading' | translate }}
                </button>
              </div>
            </div>
            <div class="row" *ngIf="plume.isSomeAdminRole()">
              <div class="key">{{ 'configurations.optimization.resetLinkFailures' | translate }}</div>
              <div class="value">
                <button class="action medium" [disabled]="!nodes || nodes.length === 0" (click)="resetLinkFailures()">
                  {{ 'configurations.optimization.reset' | translate }}
                </button>
                <icon
                  name="icon-info"
                  tooltip="{{ 'configurations.optimization.tooltipResetLinkFailures' | translate }}"
                ></icon>
              </div>
            </div>
            <div class="row" *ngIf="plume.isStrictAdminRole()">
              <div class="key">{{ 'configurations.optimization.fastInterface' | translate }}</div>
              <div class="value">
                <button class="action medium" [disabled]="!nodes || nodes.length === 0" (click)="fastInterfaceReset()">
                  {{ 'configurations.optimization.reset' | translate }}
                </button>
                <icon
                  name="icon-info"
                  tooltip="{{ 'configurations.optimization.tooltipResetFitc' | translate }}"
                ></icon>
                <button class="action medium" (click)="fastInterfaceDetails()">
                  {{ 'configurations.optimization.details' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </collapsiblebox>
  </div>
</div>

<modal mode="wide" (overlayAction)="showFastInterface = $event" *ngIf="showFastInterface">
  <ng-container title>{{ 'configurations.optimization.fastInterface' | translate }}</ng-container>
  <ng-container content>
    <div>
      <ngx-json-viewer [expanded]="false" [json]="(fastInterface$ | async) || {}"></ngx-json-viewer>
    </div>
  </ng-container>
  <ng-container controls>
    <button class="tertiary light" (click)="showFastInterface = false">
      {{ 'health.networkInformation.modal.close' | translate }}
    </button>
  </ng-container>
</modal>
