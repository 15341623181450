<box>
  <div header>
    {{ 'profiler.deviceCategories.title' | translate }}
    <slidetoggler *ngIf="!loading" [options]="items" (toggle)="toggle($event)"></slidetoggler>
  </div>
  <div container>
    <div class="group" *ngIf="!loading">
      <div class="item" *ngFor="let item of deviceCategories">
        <icon [path]="item.icon"></icon>
        <div class="enabled" *ngIf="item.count">
          {{ item.count }}
        </div>
        <div class="label">
          <div class="text">{{ item.category | translate }}</div>
        </div>
      </div>
    </div>
    <div class="loading" *ngIf="loading">{{ 'loadingIndicator' | translate }}</div>
  </div>
</box>
