<header placeholder="header.searchCustomers"></header>
<div class="container-fluid">
  <div class="sidemenu">
    <div class="group">
      <div class="row middle-xs menu" routerLink="/netops/firmware" routerLinkActive="active">
        {{ 'netops.firmwareUpgrade' | translate }}
      </div>
      <div
        class="row middle-xs menu"
        routerLink="/netops/firmwarev2"
        routerLinkActive="active"
        *ngIf="(plume.permissions | async).uiFeatures?.bulkFirmwareVersionV2"
      >
        {{ 'netops.firmwareUpgradev2' | translate }}
      </div>
      <div class="row middle-xs menu" routerLink="/netops/reports" routerLinkActive="active">
        {{ 'netops.upgradeReports' | translate }}
      </div>
      <div
        class="row middle-xs menu"
        routerLink="/netops/support"
        routerLinkActive="active"
        *ngIf="plume.isStrictAdminRole()"
      >
        {{ 'netops.supportTools' | translate }}
      </div>
    </div>
  </div>
  <div id="netops-content" class="content" cdk-scrollable>
    <router-outlet></router-outlet>
  </div>
</div>
