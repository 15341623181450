import { Component, OnInit } from '@angular/core';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { environments } from 'src/environments/shared/environments';

@Component({
  selector: 'cookie',
  templateUrl: './cookie.component.html',
  styleUrls: ['./cookie.component.scss']
})
export class CookieComponent implements OnInit {
  show: boolean = false;
  privacyUrl: string = 'https://www.plume.com/legal?tabId=privacy';

  constructor(public plume: PlumeService) {}

  ngOnInit(): void {
    const cookie = localStorage.getItem('cookie');

    if (cookie !== 'true') {
      const env = this.plume.getEnv();
      if (env) {
        const environmentsDefinition = environments.find((e: any) => e.id === env.id);
        if (environmentsDefinition?.privacyUrl) {
          this.privacyUrl = environmentsDefinition?.privacyUrl;
        }
      }
      this.show = true;
    }
  }

  accept(): void {
    localStorage.setItem('cookie', 'true');
    this.show = false;
  }
}
