import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { TooltipDirective } from 'src/app/lib/directives/tooltip.directive';
import { IDevice } from 'src/app/lib/interfaces/interface';
import { IconService } from '../../../../../lib/services/icon.service';

export interface IDeviceWithTrafficUsage extends IDevice {
  trafficUsage?: {
    value: number;
    color: string;
    title: string;
    totalUsage: string; // formatted value with unit
    percentage: number;
    qoeScore: number;
    outer: { value: number; color: string; title: string }[];
  }[];
  loading?: true;
}

@Component({
  selector: 'traffic-pie-monitor-device',
  templateUrl: './traffic-pie-monitor-device.component.html',
  styleUrls: ['./traffic-pie-monitor-device.component.scss']
})
export class TrafficPieMonitorDeviceComponent implements OnChanges {
  @Input() device: IDeviceWithTrafficUsage;
  @Input() timeValue: string;
  @ViewChild('outerCircleTooltipContainer', { read: TooltipDirective }) outerCircleTooltipContainer: TooltipDirective;

  selectedItemIndex = 0;

  constructor(private icons: IconService) {}

  ngOnChanges(): void {
    if (this.device?.trafficUsage) {
      this.selectedItemIndex = this.device.trafficUsage.reduce(
        (acc, item, index) => (item.value > acc.value ? { value: item.value, index } : acc),
        { index: -1, value: -1 }
      ).index;
    }
  }

  getIconPath(): string {
    if (this.device?.iconV3) {
      return this.icons.getV3Path(this.device.iconV3, 'small');
    } else {
      return this.icons.getPath(this.device.iconV2);
    }
  }

  outerCircleHover(event: { value: number; color: string; title?: string; path: SVGPathElement } | null): void {
    if (!event) {
      this.outerCircleTooltipContainer.hide();
    } else {
      this.outerCircleTooltipContainer.container = event.path;
      this.outerCircleTooltipContainer.tooltip = `${event.title || ''} ${event.value}%`;
      this.outerCircleTooltipContainer.show();
    }
  }
}
