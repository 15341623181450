<div class="control" [class.disable]="isUprise" *ngIf="!nodeAddOpen && !loading">
  <button class="action" (click)="toggleAddNode()" *ngIf="permissions?.uiFeatures.claimNodes">
    {{ 'nodes.nodeArea.addNodeExtender' | translate }}
    <icon name="icon-add"></icon>
  </button>
</div>
<div class="nodeAdd" [class.hide]="nodeAddOpen !== true" *ngIf="!loading">
  <div class="addNodeHeader">{{ 'nodes.nodeArea.addNodeExtender' | translate }}</div>

  <div class="row">
    <span>{{ 'nodes.nodeArea.newNodeName' | translate }}</span>
    <div class="group">
      <div class="input">
        <input
          class="name-input"
          (keyup.enter)="createNewNode()"
          (keyup.escape)="toggleAddNode()"
          [formControl]="nodenameFormControl"
          autofocus
        />
      </div>
      <span
        class="button"
        (click)="createNewNode()"
        *ngIf="nodenameFormControl.value && nodenameFormControl.value.trim()"
      >
        <icon name="icon-check" class="confirm" tooltip="{{ 'techdash.tooltip.save' | translate }}"></icon>
      </span>
      <span class="button" (click)="toggleAddNode()">
        <icon name="icon-close" class="size-0_8 cancel" tooltip="{{ 'techdash.tooltip.cancel' | translate }}"></icon>
      </span>
    </div>
  </div>
</div>
<icon name="icon-spinner" class="size-4 spin" *ngIf="loading"></icon>
