import { Component, Input, OnChanges } from '@angular/core';
import { Line } from 'src/app/lib/d3/models/objects/line';
import { Point } from 'src/app/lib/d3/models/objects/point';
import { Series } from 'src/app/lib/d3/models/objects/series';
import { ApiService } from 'src/app/lib/services/api.service';
import { ModalService } from 'src/app/lib/services/modal.service';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { ProfilerService } from 'src/app/lib/services/profiler.service';
import * as moment from 'moment';

@Component({
  selector: 'wanusage',
  templateUrl: './wanusage.component.html',
  styleUrls: ['./wanusage.component.scss']
})
export class WanusageComponent implements OnChanges {
  open: boolean = true;
  chart: any = {};
  period: number = 1;
  downloadSpeed: number = null;
  uploadSpeed: number = null;
  lastDownload: number = null;
  lastUpload: number = null;
  maxDownload: number = null;
  maxUpload: number = null;
  chartModes: any[] = [];
  noData: boolean = false;
  wanData: any = {};
  download: any[] = [];
  upload: any[] = [];

  @Input()
  locationid: string = '';

  constructor(
    private api: ApiService,
    private plume: PlumeService,
    private modal: ModalService,
    private profiler: ProfilerService
  ) {}

  ngOnChanges(): void {
    this.initModeToggler();
    this.getWAN();
  }

  initModeToggler(): void {
    if (this.period !== 10) {
      this.chartModes = [
        { value: 1, translation: '24h', selected: this.period === 1 ? true : false },
        { value: 7, translation: '7d', selected: this.period === 7 ? true : false },
        { value: 30, translation: '30d', selected: this.period === 30 ? true : false }
      ];
    }
  }

  toggleState(): void {
    this.open = !this.open;

    if (this.open) {
      this.getWAN();
    }
  }

  toggleChartMode(event: any): void {
    this.period = event;
    this.initModeToggler();
    this.getWAN();
  }

  getWAN(): void {
    this.noData = false;
    this.wanData = {};
    this.chart = {
      data: [{ data: [] }],
      period: this.period
    };

    if (this.plume.customerid && this.plume.locationid) {
      this.api
        .get('/Customers/' + this.plume.customerid + '/locations/' + this.plume.locationid + '/appFacade/dashboard')
        .subscribe((response: any) => {
          this.downloadSpeed = response?.dashboard?.mostRecent?.download || null;
          this.uploadSpeed = response?.dashboard?.mostRecent?.upload || null;

          if (this.downloadSpeed === null && this.uploadSpeed === null) {
            this.noData = true;
            return;
          }

          this.profiler.wanStats$(this.period).subscribe((response: any) => {
            const modes = { 1: 'fifteenMins', 7: 'hours', 30: 'days' };
            const nullCount = { download: 0, upload: 0 };

            this.download = [];
            this.upload = [];
            this.wanData = response;

            response[modes[this.period]].forEach((datapoint: any) => {
              const downloadValue =
                datapoint.rxMaxMbps !== null
                  ? Math.round(((datapoint.rxMaxMbps * 100) / this.downloadSpeed + Number.EPSILON) * 100) / 100
                  : null;
              const downloadTimestamp =
                this.period === 30
                  ? moment(datapoint.timestamp).startOf('day').add(1, 'hour')
                  : moment(datapoint.timestamp);

              if (downloadValue === null) {
                nullCount.download++;
              }

              this.download.push(new Point(downloadTimestamp, downloadValue > 100 ? 100 : downloadValue));

              const uploadValue =
                datapoint.txMaxMbps !== null
                  ? Math.round(((datapoint.txMaxMbps * 100) / this.uploadSpeed + Number.EPSILON) * 100) / 100
                  : null;
              const uploadTimestamp =
                this.period === 30
                  ? moment(datapoint.timestamp).startOf('day').add(1, 'hour')
                  : moment(datapoint.timestamp);

              if (uploadValue === null) {
                nullCount.upload++;
              }

              this.upload.push(new Point(uploadTimestamp, uploadValue > 100 ? 100 : uploadValue));
            });

            if (this.download.length === nullCount.download) {
              this.noData = true;
              return;
            }

            if (this.upload.length === nullCount.upload) {
              this.noData = true;
              return;
            }

            this.chart = {
              data: [
                new Line(
                  new Series('var(--chart-green)', 'download', 'health.networkInformation.wan.download'),
                  'left',
                  this.download
                ),
                new Line(
                  new Series('var(--chart-yellow)', 'upload', 'health.networkInformation.wan.upload'),
                  'left',
                  this.upload
                )
              ],
              period: this.period
            };
          });
        });
    }
  }

  calculateMinMax(domains: any): void {
    const modes = { 1: 'fifteenMins', 7: 'hours', 30: 'days' };
    this.maxDownload = null;
    this.maxUpload = null;
    this.lastDownload = null;
    this.lastUpload = null;

    if (Object.keys(this.wanData).length && this.wanData[modes[this.period]] && !this.noData) {
      this.wanData[modes[this.period]].forEach((datapoint: any) => {
        if (new Date(datapoint.timestamp).getTime() > new Date(domains[0]).getTime()) {
          const downloadValue =
            datapoint.rxMaxMbps !== null
              ? Math.round(((datapoint.rxMaxMbps * 100) / this.downloadSpeed + Number.EPSILON) * 100) / 100
              : null;

          if (downloadValue > this.maxDownload) {
            this.maxDownload = downloadValue > 100 ? 100 : downloadValue;
          }

          const uploadValue =
            datapoint.txMaxMbps !== null
              ? Math.round(((datapoint.txMaxMbps * 100) / this.uploadSpeed + Number.EPSILON) * 100) / 100
              : null;

          if (uploadValue > this.maxUpload) {
            this.maxUpload = uploadValue > 100 ? 100 : uploadValue;
          }
        }
      });

      if (this.download.length) {
        this.lastDownload = this.download[0].value;
      }

      if (this.upload.length) {
        this.lastUpload = this.upload[0].value;
      }
    }
  }

  showInfo(): void {
    this.modal.showDialog(
      'health.networkInformation.wan.explanation',
      'health.networkInformation.wan.explanationTitle',
      {
        buttons: [{ style: 'tertiary light', value: 'health.networkInformation.wan.close' }]
      }
    );
  }
}
