import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'network',
  templateUrl: './network.component.html',
  styleUrls: ['./network.component.scss']
})
export class NetworkComponent {
  open: boolean = true;

  @Output()
  toggle: any = new EventEmitter();

  constructor() {}

  toggleState(): void {
    this.open = !this.open;

    if (this.open) {
      this.toggle.emit();
    }
  }
}
