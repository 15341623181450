import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { enableES5 } from 'immer';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { FirebaseService } from './services/firebase.service';
import { LoggingService } from './services/logging.service';
import { MixpanelService } from './services/mixpanel.service';
import { PlumeService } from './services/plume.service';
import { ThemeService } from './services/theme.service';

@Component({
  selector: 'app',
  template: `
    <router-outlet *ngIf="ready"></router-outlet>
    <dialog></dialog>
    <toast></toast>
    <cookie></cookie>
  `
})
export class AppComponent implements OnInit {
  ready: boolean = false;

  constructor(
    private translate: TranslateService,
    private plume: PlumeService,
    private logging: LoggingService,
    private firebase: FirebaseService,
    private mixpanel: MixpanelService,
    private theme: ThemeService,
    private title: Title
  ) {
    enableES5();
  }

  ngOnInit(): void {
    const ui = this.plume.getUI();
    const theme = this.plume.getTheme();
    this.logging.log('Frontline Tier ' + (ui === 'noc' ? '2 & 3' : '1') + ' version: ' + this.plume.getVersion());
    this.logging.log('Browser language: ' + this.translate.currentLang);
    this.logging.log('Theme: ' + theme);

    this.plume.setLocale(navigator.language);
    this.title.setTitle('Frontline Tier ' + (ui === 'noc' ? '2 & 3' : '1'));

    const user = this.plume.getUser();
    const role = this.plume.getRole();
    const env = this.plume.getEnv();
    this.mixpanel.init();
    this.logging.log('MP initialised:' + environment.ENV);
    this.mixpanel.setUI(ui);

    if (env) {
      this.mixpanel.setEnvironment(env.id);
      this.logging.log('MP env:' + env.id);
    }

    if (user) {
      this.mixpanel.setUser(user.userId || user.id, user.email, role, user.partnerId);
      this.logging.log('MP user:' + user.userId || user.id);
    }

    this.firebase.root.on('value', (data: any) => {
      const user = this.plume.getUser();
      const role = this.plume.getRole();
      const env = this.plume.getEnv();
      const subdomain = env && env.subdomain ? env.subdomain : null;

      this.firebase.snapshot = data.val();

      this.plume.setPermissions(
        this.firebase.calculatePermissions(this.firebase.snapshot.config, role, subdomain, user)
      );
    });

    moment.locale(this.translate.currentLang);

    this.translate.onLangChange.subscribe(() => {
      moment.locale(this.translate.currentLang);
      this.plume.toggler.next();
    });

    if (theme) {
      this.theme.setActiveTheme(theme);
    } else {
      this.plume.setTheme(this.theme.setDefaultTheme(ui));
    }

    this.ready = true;
  }
}
