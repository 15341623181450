<div class="bubble">
  <div class="infoLogMessage">
    <ng-content></ng-content>
    <span class="log">
      <div>{{ 'health.bubbles.logBubble.referenceLogRecord' | translate }}:</div>
      <div class="link">
        <input type="text" readonly [value]="recordlog | translate" />
        <icon
          name="icon-copy"
          tooltip="{{ 'health.bubbles.informBubble.copy' | translate }}"
          (click)="copy(recordlog)"
        ></icon>
      </div>
    </span>
    <div class="progressbar progressbar--bottom" [class.hidden]="!showProgress"><div class="indeterminate"></div></div>
    <div class="row optButtonRow">
      <div class="col-xs-4 optButtonCol">
        <div class="tsButton" (click)="sendMessage()">{{ buttonText | translate }}</div>
      </div>
      <div class="col-xs-8 optButtonMsg">{{ 'health.bubbles.logBubble.continueAfterEscalated' | translate }}</div>
    </div>
  </div>
</div>
