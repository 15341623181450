import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'drop-down-select',
  templateUrl: './drop-down-select.component.html',
  styleUrls: ['./drop-down-select.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DropDownSelectComponent<T extends boolean, V extends string> implements OnChanges {
  filterText = '';
  filteredValues: { text: string; value: V; object?: unknown }[] = [];

  @Input() emptyText = '';
  @Input() values: { text: string; value: V; object?: unknown }[];
  @Input() multiSelect: T;
  @Input() multiSelectSeparator = '';
  @Input() ownModel: T extends true ? V[] : V;
  @Input() disabled = false;
  @Input() readOnly = false;
  @Input() rowTemplate: TemplateRef<unknown>;
  @Input() translate = true;
  @Input() headerTemplate: TemplateRef<unknown>;
  @Input() headerPrefix = '';
  @Input() noHeaderValue = false;
  @Input() fixedHeader: string = '';
  @Input() listWidth: null | number | 'asHeader' = 'asHeader';
  @Input() listMaxWidth: null | number;
  @Input() listMinWidth: null | number | 'asHeader' = null;
  @Input() filterVisible: boolean | 'auto' = 'auto';
  @Input() filterPlaceHolder = 'components.dropdown.filterDefaultText';
  @Input() revealOnHover: boolean = false;
  @Output() ownModelChange = new EventEmitter<T extends true ? V[] : V>();
  @Output() opened = new EventEmitter<boolean>();

  @ViewChild('filterInput') filterInput: ElementRef<HTMLInputElement>;

  @Input() filterFunction(value: { text: string; value: V; object?: unknown }, filterText: string): boolean {
    return value.text.toLowerCase().includes(filterText.toLowerCase());
  }

  constructor(private translateService: TranslateService, public elRef: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.values) {
      this.filter(this.filterText);
    }
  }

  headerText(): string {
    if (!this.fixedHeader) {
      if (!this.noHeaderValue) {
        if (this.ownModel && (!Array.isArray(this.ownModel) || this.ownModel.length > 0)) {
          if (this.multiSelect && Array.isArray(this.ownModel)) {
            if (this.multiSelectSeparator) {
              if (this.headerPrefix) {
                return (
                  this.headerPrefix +
                  ' ' +
                  this.ownModel.map((v) => this.valueToText(v)).join(this.multiSelectSeparator)
                );
              }

              return this.ownModel.map((v) => this.valueToText(v)).join(this.multiSelectSeparator);
            } else {
              if (this.headerPrefix) {
                return this.headerPrefix + ' ' + this.ownModel.length.toString();
              }

              return this.ownModel.length.toString();
            }
          } else {
            return this.valueToText(this.ownModel as V);
          }
        } else {
          if (this.multiSelect && !this.multiSelectSeparator && !this.emptyText) {
            return '0';
          }

          return this.emptyText;
        }
      } else {
        return '';
      }
    } else {
      return this.fixedHeader;
    }
  }

  valueToText(value: V): string {
    if (this.translate) {
      const key = this.values?.find((v) => v.value === value)?.text;
      return key ? this.translateService.instant(key) : value;
    }

    return this.values?.find((v) => v.value === value)?.text ?? value;
  }

  selectValue(value: V): void {
    if (this.readOnly) {
      return;
    }

    if (!this.multiSelect) {
      this.ownModelChange.emit(value as T extends true ? V[] : V);
      return;
    }

    if (!this.ownModel) {
      this.ownModelChange.emit([value] as T extends true ? V[] : V);
      return;
    }

    if ((this.ownModel as V[]).includes(value)) {
      this.ownModelChange.emit(
        (this.ownModel as V[]).filter((modelValue) => modelValue !== value) as T extends true ? V[] : V
      );
    } else {
      // sort by this.values
      this.ownModelChange.emit(
        this.values
          .filter((val) => this.ownModel.includes(val.value) || val.value === value)
          .map((val) => val.value) as T extends true ? V[] : V
      );
    }
  }

  filter(filterText: string): void {
    if (!filterText) {
      this.filteredValues = this.values;
    } else {
      this.filteredValues = this.values.filter((value) => this.filterFunction(value, filterText));
    }
  }

  setFilter(value: string): void {
    this.filterText = value;
    this.filter(value);
  }

  focusFilter(): void {
    setTimeout(() => {
      this.filterInput?.nativeElement?.focus();
    }, 0);
  }

  mouseEnter(event: any): void {
    if (this.revealOnHover) {
      const offsetWidth = event.target.firstChild.offsetWidth;
      const scrollWidth = event.target.firstChild.scrollWidth;

      if (scrollWidth > offsetWidth) {
        const diff = scrollWidth - offsetWidth;
        const speed = diff / 150;
        const value = event.target.querySelector('.value');
        value.style.transitionTimingFunction = 'linear';
        value.style.transitionProperty = 'transform';
        value.style.transitionDuration = speed + 's';
        value.style.transitionDelay = '1s';
        value.style.transform = 'translateX(-' + diff + 'px)';
        value.style.flexDirection = 'column';
        value.style.display = 'inline-flex';
      }
    }
  }

  mouseLeave(event: any): void {
    const value = event.target.querySelector('.value');
    value.style.transitionTimingFunction = '';
    value.style.transitionProperty = '';
    value.style.transitionDuration = '';
    value.style.transitionDelay = '';
    value.style.transform = '';
    value.style.flexDirection = '';
    value.style.display = '';
  }

  filterVisibleValue(): boolean {
    if (this.filterVisible === 'auto') {
      return this.values?.length >= 8;
    }
    return this.filterVisible;
  }
}
