import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Line } from 'src/app/lib/d3/models/objects/line';
import { Point } from 'src/app/lib/d3/models/objects/point';
import { Series } from 'src/app/lib/d3/models/objects/series';
import { ApiService } from 'src/app/lib/services/api.service';
import { ModelRefService } from 'src/app/lib/services/modelref.service';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { ThemeService } from 'src/app/lib/services/theme.service';

@Component({
  selector: 'congestionalarm',
  templateUrl: './congestionalarm.component.html',
  styleUrls: ['./congestionalarm.component.scss']
})
export class CongestionalarmComponent implements OnInit, OnChanges, OnDestroy {
  themeSubscription: any;
  skin: string;

  @Input() alarm: any;
  @Input() bare = false; // remove some design for alerts
  opened: { [id: string]: { open: boolean; chartData?: any; chartSeries?: any } } = {};
  hasAlarms = false;

  constructor(
    private plume: PlumeService,
    private theme: ThemeService,
    private api: ApiService,
    private modelRef: ModelRefService
  ) {}

  ngOnInit(): void {
    this.themeSubscription = this.theme.listener.subscribe((theme: string) => {
      this.skin = theme;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.hasAlarms = !!changes.alarm?.currentValue?.find((item) => item.alarmed);
  }

  getIcon(model: string): string {
    return this.modelRef.get(model).icon;
  }

  track(index: number, item: any): any {
    return item.node.id;
  }

  toggleHistory(open: boolean, data: any): void {
    this.opened[data.node.id] = { open };

    if (open) {
      this.api
        .get(
          '/Customers/' +
            this.plume.customerid +
            '/locations/' +
            this.plume.locationid +
            '/nodes/' +
            data.node.id +
            '/utilizationMetrics?granularity=days&limit=1',
          'reports'
        )
        .subscribe((response: any) => {
          const charts = [];
          const series = [];

          for (const band of Object.keys(response)) {
            if (band !== 'statsDateRange') {
              const points = response[band].map(
                (obj: any) => new Point(new Date(obj.ts).toString(), parseFloat(obj.avgutilization))
              );

              const serie = new Series(
                band === '2.4G'
                  ? 'var(--chart-green)'
                  : band === '5G'
                  ? 'var(--chart-yellow)'
                  : band === '5GL'
                  ? 'var(--is-timelineseries7)'
                  : band === '5GU'
                  ? 'var(--is-timelineseries1)'
                  : 'var(--chart-purple)',
                band,
                'health.networkPerformance.congestionAlarm.' + band
              );

              series.push(serie);
              charts.push(new Line(serie, 'left', points));
            }
          }

          this.opened[data.node.id].chartData = charts;
          this.opened[data.node.id].chartSeries = series;
        });
    }
  }

  ngOnDestroy(): void {
    if (this.themeSubscription) {
      this.themeSubscription.unsubscribe();
    }
  }
}
