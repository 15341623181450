import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { ApiService } from 'src/app/lib/services/api.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { ToastService } from 'src/app/lib/services/toast.service';

@Component({
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss']
})
export class GroupsComponent implements OnInit {
  allGroups: any[] = [];
  groupList: any = [];
  groupName: FormControl = new FormControl();
  groupDescription: FormControl = new FormControl();
  groupsFilter: FormControl = new FormControl();
  expand: boolean = true;

  constructor(
    private router: Router,
    public plume: PlumeService,
    private toast: ToastService,
    private api: ApiService,
    private mixpanel: MixpanelService
  ) {}

  ngOnInit(): void {
    this.mixpanel.storeEvent('GROUPS_LIST_SCREEN');

    this.getGroups();
  }

  getGroups(offset: number = 0): void {
    const limit = 500;
    if (this.plume.isStrictAdminRole() || this.plume.isStrictSupportRole()) {
      this.api
        .get('/Groups?filter={"order":"name ASC","limit":' + limit + ',"offset":' + offset + '}')
        .subscribe((response: any) => {
          this.allGroups = [...this.allGroups, ...response];

          if (response.length === limit) {
            this.getGroups(this.allGroups.length);
          } else {
            this.filter();
          }
        });
    } else {
      this.allGroups = this.plume.getGroups();
      this.filter();
    }
  }

  clearFilter(): void {
    this.groupsFilter.reset();
  }

  filter(): void {
    if (this.groupsFilter.value && this.groupsFilter.value.length) {
      const filter = new RegExp(this.groupsFilter.value.replace('(', '\\(').replace(')', '\\)'), 'i');

      this.groupList = this.allGroups.filter((group: any) => {
        if (group.name.match(filter) || group.description?.match(filter) || group.id.match(filter)) {
          return true;
        } else {
          return false;
        }
      });
    } else {
      this.groupList = [...this.allGroups];
    }
  }

  selectGroup(group: any): void {
    this.router.navigate(['groups', group.id]);
  }

  clearAddGroupFields(): void {
    this.groupName.setValue('');
    this.groupDescription.setValue('');
  }

  confirmNewGroup(): void {
    this.api
      .post('/Groups', {
        name: this.groupName.value,
        description: this.groupDescription.value
      })
      .subscribe(
        () => {
          this.mixpanel.storeEvent('CREATE_GROUP_SUCCESS', { GROUP_NAME: this.groupName.value });
          this.toast.success('header.groupManagement.createGroupSuccessToast', 'header.success');
          this.allGroups = [];
          this.getGroups();
          this.clearAddGroupFields();
        },
        (error: any) => {
          this.toast.error(error.error.error.message, 'header.failed');
          this.mixpanel.storeEvent('CREATE_GROUP_SERVER_FAILURE');
        }
      );
  }
}
