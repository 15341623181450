import { AppState } from '../reducers';

export const selectNodes = (state: AppState) => state.polling?.nodes?.data;
export const selectDevices = (state: AppState) => state.polling?.devices?.data;
export const selectLocationQoE = (state: AppState) => state.polling?.locationQoE?.data;
export const selectLocationBbe = (state: AppState) => state.polling?.bbe?.data;
export const selectLocationInternet = (state: AppState) => state.polling?.internet?.data;
export const selectLocationStability = (state: AppState) => state.polling.stability?.data;
export const selectLocationAlarmReports = (state: AppState) => state.polling?.alarmReports?.data;
export const selectLocationTopology = (state: AppState) => state.polling?.topology?.data;
export const selectLocationOptimization = (state: AppState) => state.polling?.optimization?.data;
export const selectPowerManagement = (state: AppState) => state.polling?.powerManagement?.data;
