<div class="row" id="conf-mobile">
  <div class="col-xs-12">
    <collapsiblebox [show]="expand" [collapsible]="true" (toggle)="toggleExpand()">
      <div header>{{ 'configurations.mobile.title' | translate }}</div>
      <div container class="main-container utilities" *ngIf="expand">
        <div class="columns">
          <div class="column">
            <div class="row">
              <div class="key">{{ 'configurations.mobile.focusEnabled' | translate }}</div>
              <div class="value enabled">
                <ng-container *ngIf="isUtilizingFocuses$ | async">
                  <div class="circle green"></div>
                  <div>{{ 'configurations.mobile.enabled' | translate }}</div>
                </ng-container>
                <ng-container *ngIf="(isUtilizingFocuses$ | async) === false">
                  <div class="circle red"></div>
                  <div>{{ 'configurations.mobile.disabled' | translate }}</div>
                </ng-container>
              </div>
            </div>
            <div class="row">
              <div class="key">
                {{ 'configurations.mobile.revertToHomePass2' | translate }}
              </div>
              <div class="value">
                <button
                  class="action medium"
                  [class.disabled]="!plume.isSomeAdminRole() || (isUtilizingFocuses$ | async) === false"
                  (click)="showRevertModal()"
                >
                  {{ 'configurations.mobile.revertButton' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </collapsiblebox>
  </div>
</div>
