<div class="overlap" *ngIf="series">
  <seriesVisual [series]="series" *ngIf="!scale.thresholdValue"></seriesVisual>
  <seriesVisual
    [series]="series"
    threshold="charts.force.legend.threshold"
    alarm="charts.force.legend.alarm"
    *ngIf="scale.thresholdValue"
  ></seriesVisual>
</div>
<svg *ngIf="width && height">
  <defs>
    <clipPath id="strip">
      <rect x="0" y="-10" [attr.width]="chart.calculateWidth()" [attr.height]="chart.calculateHeight() + 10" />
    </clipPath>
    <clipPath id="wideStrip">
      <rect x="-10" y="-20" [attr.width]="chart.calculateWidth() + 20" [attr.height]="chart.calculateHeight() + 30" />
    </clipPath>
  </defs>
  <svg:g [attr.transform]="'translate(' + margins.left + ',' + margins.top + ')'">
    <svg:g [attr.class]="tick.class" [tickVisual]="tick" *ngFor="let tick of xAxis"></svg:g>
    <svg:g [attr.class]="tick.class" [tickVisual]="tick" *ngFor="let tick of yAxis"></svg:g>
    <svg:rect
      class="axis"
      [attr.x]="0"
      [attr.y]="-10"
      [attr.width]="1"
      [attr.height]="chart.calculateHeight() + 10"
    ></svg:rect>
    <svg:rect
      class="axis"
      [attr.x]="0"
      [attr.y]="chart.calculateHeight()"
      [attr.width]="chart.calculateWidth()"
      [attr.height]="1"
    ></svg:rect>
    <svg:g
      *ngFor="let line of lines"
      style="-webkit-clip-path: url(#strip); clip-path: url(#strip)"
      clip-path="url(#strip)"
    >
      <svg:path
        *ngFor="let fill of line.fills"
        [attr.d]="fill"
        [attr.fill]="line.series.color"
        [style.opacity]="0.5"
      ></svg:path>
      <svg:g
        *ngFor="let path of line.paths"
        [attr.class]="line.class"
        [pathVisual]="path"
        [style]="{ stroke: line.series.color, strokeWidth: 3 }"
      ></svg:g>
    </svg:g>
    <svg:g *ngFor="let line of lines">
      <svg:circle *ngFor="let dot of line.alarms" class="alarm" [attr.r]="6" [attr.cx]="dot.cx" [attr.cy]="dot.cy">
        <svg:animate attributeName="r" dur="1s" repeatCount="indefinite" values="3;6;3"></svg:animate>
      </svg:circle>
      <svg:circle
        *ngFor="let dot of line.dots"
        [class.hide]="scale.timeSpan"
        [attr.fill]="dot.series.color"
        [attr.r]="dot.r"
        [attr.cx]="dot.cx"
        [attr.cy]="dot.cy"
      ></svg:circle>
    </svg:g>
    <svg:line
      *ngIf="scale.thresholdValue"
      class="threshold"
      [attr.x1]="0"
      [attr.y1]="chart.getYbyValue(scale.thresholdValue)"
      [attr.x2]="chart.calculateWidth()"
      [attr.y2]="chart.getYbyValue(scale.thresholdValue)"
      style="-webkit-clip-path: url(#strip); clip-path: url(#strip)"
      clip-path="url(#strip)"
    ></svg:line>
  </svg:g>
  <svg:g class="quality" *ngIf="scale.thresholdValue">
    <svg:text [attr.transform]="'translate(' + (width - margins.right) + ',' + (margins.top - 23) + ')'">
      {{ (scale.above ? 'qoe.charts.lowerQuality' : 'qoe.charts.higherQuality') | translate }}
    </svg:text>
    <svg:text [attr.transform]="'translate(' + (width - margins.right) + ',' + (height - margins.bottom - 5) + ')'">
      {{ (scale.above ? 'qoe.charts.higherQuality' : 'qoe.charts.lowerQuality') | translate }}
    </svg:text>
  </svg:g>
</svg>
<div class="tooltips" *ngFor="let tooltip of tooltips">
  <div class="region" [ngStyle]="tooltip.region" (mouseenter)="tooltip.show = true" (mouseleave)="tooltip.show = false">
    <div class="info" [ngStyle]="tooltip.style" *ngIf="tooltip.show">
      <div class="data" *ngFor="let data of tooltip.data">
        <div class="circle" [style.backgroundColor]="data.color"></div>
        {{ data.series | translate }}: {{ data.value > 1 ? (data.value | number: '1.0-2') : data.value }}
        {{ scale.symbol }}
      </div>
    </div>
  </div>
</div>
