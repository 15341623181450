import { createAction, props } from '@ngrx/store';
import {
  IAlarmReports,
  IBroadbandEfficiencyAlert,
  IDevice,
  IInternet,
  ILocationQoe,
  INode,
  IOptimization,
  IOptimizationSummary,
  IPowerState,
  ITopology
} from 'src/app/lib/interfaces/interface';

export const pollingPull = createAction('[Polling] Polling pull', props<{ debugSource: string }>()); // should be replaced with more precise events
export const pollingIntervalPull = createAction('[Polling] Polling interval pull');
export const optimizationChanged = createAction(
  '[Polling] Optimization changed',
  props<{ optimization: IOptimization }>()
);
export const powerManagementChanged = createAction(
  '[Polling] Power management changed',
  props<{ powerManagement: IPowerState }>()
);
export const powerManagementLoaded = createAction(
  '[Polling] Power management loaded',
  props<{ powerManagement: IPowerState }>()
);

export const topologyChanged = createAction('[Polling] Topology changed', props<{ topology: ITopology }>());
export const topologyChangedError = createAction('[Polling] Topology changed error', props<{ error: any }>());
export const nodesLoaded = createAction('[Polling] Nodes loaded', props<{ nodes: INode[] }>());
export const nodesLoadedError = createAction('[Polling] Nodes loaded error', props<{ error: any }>());
export const devicesLoaded = createAction('[Polling] Devices loaded', props<{ devices: IDevice[] }>());
export const devicesLoadedError = createAction('[Polling] Devices loaded error', props<{ error: any }>());
export const locationQoeLoaded = createAction('[Polling] Location QoE loaded', props<{ qoe: ILocationQoe }>());
export const locationQoeLoadedError = createAction('[Polling] Location QoE loaded error', props<{ error: any }>());
export const broadbandEfficiencyAlertLoaded = createAction(
  '[Polling] Broadband Efficiency Alert loaded',
  props<{ bbe: IBroadbandEfficiencyAlert }>()
);
export const broadbandEfficiencyAlertLoadedError = createAction(
  '[Polling] Broadband Efficiency Alert loaded error',
  props<{ error: any }>()
);
export const internetLoaded = createAction('[Polling] Internet loaded', props<{ internet: IInternet }>());
export const internetLoadedError = createAction('[Polling] Internet loaded error', props<{ error: any }>());
export const stabilityLoaded = createAction('[Polling] Stability loaded', props<{ stability: IOptimizationSummary }>());
export const stabilityLoadedError = createAction('[Polling] Stability loaded error', props<{ error: any }>());

export const alarmReportsLoaded = createAction(
  '[Polling] Alarm reports loaded',
  props<{ alarmReports: IAlarmReports }>()
);
export const alarmReportsLoadedError = createAction('[Polling] Alarm reports loaded error', props<{ error: any }>());

export const blankAction = createAction('[Polling] Blank action');
