import { scaleLinear, scaleTime, extent } from 'd3';
import { Line } from '../objects/line';
import { Tick } from '../objects/tick';
import { Point } from '../objects/point';

export class LineChart {
  private data: any[];
  private lines: Line[];
  private width: number;
  private height: number;
  private scale: string;
  private xScale: any;
  private yScaleLeft: any;
  private yScaleRight: any;
  private margins: any = {
    top: 80,
    right: 60,
    bottom: 80,
    left: 60
  };

  constructor() {}

  calculateWidth(): number {
    return this.width - this.margins.left - this.margins.right;
  }

  calculateHeight(): number {
    return this.height - this.margins.top - this.margins.bottom;
  }

  prepareScales(): void {
    this.xScale = scaleTime().range([0, this.calculateWidth()]);
    this.yScaleLeft = scaleLinear().range([this.calculateHeight(), 0]);
    this.yScaleRight = scaleLinear().range([this.calculateHeight(), 0]);
  }

  prepareDomains(): void {
    const xdomains = [];
    const yleftdomains = [];
    const yrightdomains = [];

    this.data.forEach((line: any) => {
      xdomains.push(...line.data.map((d: any) => new Date(d.label)));

      if (line.axis === 'left') {
        yleftdomains.push(...line.data.map((d: any) => d.value));
      }
      if (line.axis === 'right') {
        yrightdomains.push(...line.data.map((d: any) => d.value));
      }
    });

    const date = new Date();

    switch (this.scale) {
      case 'year':
        date.setFullYear(date.getFullYear() - 1);
        this.xScale.domain([date, new Date()]);
        break;

      case 'quarter':
        date.setFullYear(date.getFullYear(), date.getMonth() - 3);
        this.xScale.domain([date, new Date()]);
        break;

      case 'month':
        date.setFullYear(date.getFullYear(), date.getMonth() - 1);
        this.xScale.domain([date, new Date()]);
        break;

      case 'week':
        date.setFullYear(date.getFullYear(), date.getMonth(), date.getDate() - 7);
        this.xScale.domain([date, new Date()]);
        break;

      default:
        this.xScale.domain(extent(xdomains));
    }

    this.yScaleLeft.domain(extent([0, ...yleftdomains, 1])).nice();
    this.yScaleRight.domain(extent([0, ...yrightdomains])).nice();
  }

  calculateLines(): void {
    this.lines = this.data.map((line: Line) => {
      let resetLine = false;
      let command = 'M';
      let x = 0;
      let y = 0;

      line.d = '';

      line.data.forEach((point: Point, index: number) => {
        if (point.value !== null) {
          if (index && !resetLine) {
            command = 'L';
          } else {
            command = 'M';
            resetLine = false;
          }

          x = this.xScale(new Date(point.label));

          if (line.axis === 'left') {
            y = this.yScaleLeft(point.value);
          } else {
            y = this.yScaleRight(point.value);
          }

          line.d += command + x + ' ' + y + ' ';
        } else {
          resetLine = true;
        }
      });

      return line;
    });
  }

  xAxis(): any[] {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    switch (this.scale) {
      case 'year':
        return this.xScale.ticks(12).map((tick: any) => new Tick(
            'xaxis',
            months[tick.getMonth()],
            'translate(' + this.xScale(tick) + ',' + (this.calculateHeight() + 20) + ')',
            { y: 10 },
            true,
            { y1: -9, y2: -11 }
          ));

      case 'quarter':
        return this.xScale.ticks(3).map((tick: any) => new Tick(
            'xaxis',
            months[tick.getMonth()],
            'translate(' + this.xScale(tick) + ',' + (this.calculateHeight() + 20) + ')',
            { y: 10 },
            true,
            { y1: -9, y2: -11 }
          ));

      case 'month':
        return this.xScale.ticks(4).map((tick: any) => new Tick(
            'xaxis',
            months[tick.getMonth()] + ' ' + tick.getDate(),
            'translate(' + this.xScale(tick) + ',' + (this.calculateHeight() + 20) + ')',
            { y: 10 },
            true,
            { y1: -9, y2: -11 }
          ));

      case 'week':
        return this.xScale.ticks(7).map((tick: any) => new Tick(
            'xaxis',
            days[tick.getDay()],
            'translate(' + this.xScale(tick) + ',' + (this.calculateHeight() + 20) + ')',
            { y: 10 },
            true,
            { y1: -9, y2: -11 }
          ));
    }
  }

  yAxisLeft(): any[] {
    return this.yScaleLeft.ticks(5).map((tick: any) => new Tick(
        'yaxis left',
        tick * 100 + '%',
        'translate(0, ' + this.yScaleLeft(tick) + ')',
        { x: -20 - this.margins.left / 2, dy: 0.32 },
        false
      ));
  }

  yAxisRight(): any[] {
    return this.yScaleRight.ticks(5).map((tick: any) => new Tick(
        'yaxis right',
        this.converter(tick),
        'translate(' + this.calculateWidth() + ', ' + this.yScaleRight(tick) + ')',
        { x: 20 + this.margins.right / 2, dy: 0.32 },
        false
      ));
  }

  update(data: any[], width?: number, height?: number, margins?: any, scale: string = 'year'): Line[] {
    this.data = data;

    if (width) {
      this.width = width;
    }
    if (height) {
      this.height = height;
    }
    if (margins) {
      this.margins = margins;
    }
    if (scale) {
      this.scale = scale;
    }

    this.prepareScales();
    this.prepareDomains();

    this.calculateLines();

    return this.lines;
  }

  converter(value: number): string {
    const units = ['', 'k', 'M', 'B'];

    const tier = Math.log10(value) / 3 || 0;

    if (tier === 0) {
      return value.toString();
    }

    const prefix = units[tier];
    const scale = Math.pow(10, tier * 3);

    const scaled = value / scale;

    return parseFloat(scaled.toFixed(1)) + prefix;
  }
}
