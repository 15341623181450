import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RoleService } from './services/role.service';
import { NotfoundComponent } from 'src/app/views/404/404.component';
import { LandingComponent } from 'src/app/views/landing/landing.component';
import { CustomerComponent } from 'src/app/views/customer/customer.component';
import { TechnicianComponent } from 'src/app/views/customer/technician/technician.component';
import { LoginComponent, LoginHandleComponent } from 'src/app/views/login/login.component';
import { ResetPasswordComponent } from 'src/app/views/login/reset/reset.component';
import { HealthComponent } from 'src/app/views/customer/health/health.component';
import { ProfilerComponent } from 'src/app/views/customer/profiler/profiler.component';
import { NetworkRouterComponent } from 'src/app/views/networkRouter/networkRouter.component';
import { NodesComponent } from 'src/app/views/customer/nodes/nodes.component';
import { DevicesComponent } from 'src/app/views/customer/devices/devices.component';
import { TopologyComponent } from 'src/app/views/customer/topology/topology.component';
import { QoeComponent } from 'src/app/views/customer/qoe/qoe.component';
import { FlexComponent } from 'src/app/views/customer/flex/flex.component';
import { SpeedtestsComponent } from 'src/app/views/customer/speedtests/speedtests.component';
import { TimelinesComponent } from 'src/app/views/customer/timelines/timelines.component';
import { SecurityComponent } from 'src/app/views/customer/security/security.component';
import { MotionComponent } from 'src/app/views/customer/motion/motion.component';
import { ConfigurationComponent } from 'src/app/views/customer/configuration/configuration.component';
import { GroupsComponent } from 'src/app/views/groups/groups.component';
import { GroupComponent } from 'src/app/views/groups/group/group.component';
import { ApiexplorerComponent } from 'src/app/views/apiexplorer/apiexplorer.component';
import { NetopsComponent } from 'src/app/views/netops/netops.component';
import { UpgradeFirmwareComponent } from 'src/app/views/netops/upgradefirmware/upgradefirmware.component';
import { UpgradeFirmwareV2Component } from 'src/app/views/netops/upgradefirmwarev2/upgradefirmwarev2.component';
import { UpgradeReportsComponent } from 'src/app/views/netops/upgradereports/upgradereports.component';
import { LteViewComponent } from 'src/app/views/customer/lte-view/lte-view.component';
import { SupportToolsComponent } from 'src/app/views/netops/supporttools/supporttools.component';
import { ComponentClearerComponent } from 'src/app/views/component-clearer/component-clearer.component';
import { CaptiveportalComponent } from 'src/app/views/captiveportal/captiveportal.component';
import { TrafficComponent } from 'src/app/views/customer/traffic/traffic.component';
import { ThriveComponent } from 'src/app/views/customer/thrive/thrive.component';
import { TrafficDeviceComponent } from '../views/customer/traffic/traffic-device/traffic-device.component';

const customer: Routes = [
  {
    path: 'customer/:customerid/location/:locationid',
    component: CustomerComponent,
    children: [
      {
        path: 'health',
        component: HealthComponent,
        data: { name: 'health', requireUser: true },
        canActivate: [RoleService]
      },
      {
        path: 'profiler',
        component: ProfilerComponent,
        data: { name: 'profiler', requireUser: true },
        canActivate: [RoleService]
      },
      {
        path: 'nodes/:nodeid',
        component: NodesComponent,
        data: { name: 'nodes', requireUser: true },
        canActivate: [RoleService]
      },
      {
        path: 'nodes',
        component: NodesComponent,
        data: { name: 'nodes', requireUser: true },
        canActivate: [RoleService]
      },
      {
        path: 'devices/:deviceid',
        component: DevicesComponent,
        data: { name: 'devices', requireUser: true },
        canActivate: [RoleService]
      },
      {
        path: 'devices',
        component: DevicesComponent,
        data: { name: 'devices', requireUser: true },
        canActivate: [RoleService]
      },
      {
        path: 'qoe/:mode/:id',
        component: QoeComponent,
        data: { name: 'qoe', requireUser: true },
        canActivate: [RoleService]
      },
      {
        path: 'qoe/:mode',
        component: QoeComponent,
        data: { name: 'qoe', requireUser: true },
        canActivate: [RoleService]
      },
      { path: 'qoe', redirectTo: 'qoe/summary', pathMatch: 'full' },
      {
        path: 'thrive/:mode',
        component: ThriveComponent,
        data: { name: 'thrive', requireUser: true },
        canActivate: [RoleService]
      },
      { path: 'thrive', redirectTo: 'thrive/user', pathMatch: 'full' },
      { path: 'traffic/device', redirectTo: 'traffic/configuration', pathMatch: 'full' },
      {
        path: 'traffic/:mode',
        component: TrafficComponent,
        data: { name: 'traffic', requireUser: true },
        canActivate: [RoleService]
      },
      {
        path: 'traffic/device/:mac',
        component: TrafficDeviceComponent,
        data: { name: 'traffic', requireUser: true },
        canActivate: [RoleService]
      },
      { path: 'traffic', redirectTo: 'traffic/configuration', pathMatch: 'full' },
      {
        path: 'lte',
        component: LteViewComponent,
        data: { name: 'lte', requireUser: true },
        canActivate: [RoleService]
      },
      { path: 'flex', component: FlexComponent, data: { name: 'flex', requireUser: true }, canActivate: [RoleService] },
      {
        path: 'timelines/:mode',
        component: TimelinesComponent,
        data: { name: 'timelines', requireUser: true },
        canActivate: [RoleService]
      },
      { path: 'timelines', redirectTo: 'timelines/optimizer', pathMatch: 'full' },
      {
        path: 'topology',
        component: TopologyComponent,
        data: { name: 'topology', requireUser: true },
        canActivate: [RoleService]
      },
      {
        path: 'speedtests',
        component: SpeedtestsComponent,
        data: { name: 'speedtests', requireUser: true },
        canActivate: [RoleService]
      },
      {
        path: 'security',
        component: SecurityComponent,
        data: { name: 'security', requireUser: true },
        canActivate: [RoleService]
      },
      {
        path: 'motion',
        component: MotionComponent,
        data: { name: 'motion', requireUser: true },
        canActivate: [RoleService]
      },
      {
        path: 'technician',
        component: TechnicianComponent,
        data: { name: 'technician', requireUser: true },
        canActivate: [RoleService]
      },
      {
        path: 'configuration',
        component: ConfigurationComponent,
        data: { name: 'configuration', requireUser: true },
        canActivate: [RoleService]
      },
      { path: 'component-clearer', component: ComponentClearerComponent },
      { path: '', redirectTo: '', pathMatch: 'full', data: { name: 'routeCustomer' }, canActivate: [RoleService] }
    ]
  }
];

const netops: Routes = [
  {
    path: 'netops',
    component: NetopsComponent,
    children: [
      { path: 'firmware', component: UpgradeFirmwareComponent, data: { name: 'netops' }, canActivate: [RoleService] },
      {
        path: 'firmwarev2',
        component: UpgradeFirmwareV2Component,
        data: { name: 'netops' },
        canActivate: [RoleService]
      },
      { path: 'reports', component: UpgradeReportsComponent, data: { name: 'netops' }, canActivate: [RoleService] },
      { path: 'support', component: SupportToolsComponent, data: { name: 'netops' }, canActivate: [RoleService] },
      { path: '', redirectTo: 'firmware', pathMatch: 'full' }
    ]
  }
];

const pages: Routes = [
  { path: 'groups', component: GroupsComponent, data: { name: 'groups' }, canActivate: [RoleService] },
  { path: 'groups/:groupid', component: GroupComponent, data: { name: 'groups' }, canActivate: [RoleService] },
  {
    path: 'groups/:groupid/:customerid',
    component: GroupComponent,
    data: { name: 'groups' },
    canActivate: [RoleService]
  },
  {
    path: 'location/:location',
    component: NetworkRouterComponent,
    data: { name: 'locationRouter' },
    canActivate: [RoleService]
  }
];
const explorer: Routes = [
  { path: 'explorer', component: ApiexplorerComponent, data: { name: 'explorer' }, canActivate: [RoleService] }
];

const login: Routes = [
  { path: 'reset', component: ResetPasswordComponent },
  { path: 'login', component: LoginComponent },
  { path: 'login/handle', component: LoginHandleComponent }
];

const captiveportal: Routes = [
  {
    path: 'captiveportal/customer/:customer/location/:location/network/:network/:mode',
    component: CaptiveportalComponent,
    data: { name: 'captiveportal', requireUser: true },
    canActivate: [RoleService]
  }
];

const landing: Routes = [
  { path: '', component: LandingComponent, pathMatch: 'full', data: { name: 'landing' }, canActivate: [RoleService] }
];

const state: Routes = [{ path: '**', component: NotfoundComponent }];

const routes: Routes = [
  ...customer,
  ...netops,
  ...pages,
  ...explorer,
  ...login,
  ...captiveportal,
  ...landing,
  ...state
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class RoutingModule {}
