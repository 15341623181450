import { createAction, props } from '@ngrx/store';
import { ICaptivePortalConfig, ICaptivePortalLoginOptions } from 'src/app/lib/interfaces/captive-portal';

export const prepareCaptivePortal = createAction(
  '[Captive portal] prepare',
  props<{ network: string; mode: string }>()
);

export const campaignDataLoaded = createAction(
  '[Captive portal] campaign data loaded',
  props<{ config: ICaptivePortalConfig; loginOptions: ICaptivePortalLoginOptions[] }>()
);

export const campaignDataLoadedError = createAction(
  '[Captive portal] campaign data loaded error',
  props<{ error: string }>()
);

export const loginOptionEnabledChanged = createAction(
  '[Captive portal] login option enabled changed',
  props<{ option: ICaptivePortalLoginOptions & { enabled: boolean } }>()
);

export const toggleMarketingConsent = createAction('[Captive portal] Toggle marketing consent');

export const loginOptionSetCode = createAction(
  '[Captive portal] login set code',
  props<{ code: string; name: string }>()
);

export const languageChanged = createAction(
  '[Captive portal] language changed',
  props<{ lang: string; lang_code: string }>()
);

export const multilingualChanged = createAction(
  '[Captive portal] multilingual changed',
  props<{ multilingual: boolean }>()
);

export const textColorChanged = createAction('[Captive portal] text color changed', props<{ color: string }>());

export const backgroundColorChanged = createAction(
  '[Captive portal] background color changed',
  props<{ color: string }>()
);

export const redirectTimeChanged = createAction('[Captive portal] redirect time changed', props<{ time: number }>());

export const redirectEnabledChanged = createAction(
  '[Captive portal] redirect enabled changed',
  props<{ enabled: boolean }>()
);

export const redirectUrlChanged = createAction(
  '[Captive portal] redirect url changed',
  props<{ url: string; toggle: boolean }>()
);

export const logoSizeChanged = createAction('[Captive portal] logo size changed', props<{ size: number }>());

export const logoLoadingModeChanged = createAction(
  '[Captive portal] logo loading mode changed',
  props<{ mode: 'loading' | 'init' | 'scan' }>()
);

export const logoEnabledChanged = createAction(
  '[Captive portal] logo enabled changed',
  props<{ enabled: boolean; url: string }>()
);

export const backgroundImageDeleted = createAction(
  '[Captive portal] background image deleted',
  props<{ enabled: boolean; url: string }>()
);


export const scanningUrlForLogo = createAction('[Captive portal] scanning URL for logo', props<{ url: string }>());

export const logoUploading = createAction('[Captive portal] logo uploading', props<{ logo: File }>());

export const logoUploadingFinished = createAction('[Captive portal] logo uploading finished', props<{ url: string }>());

export const logoUploadingError = createAction('[Captive portal] logo uploading error', props<{ error: any }>());

export const backgroundImageUploading = createAction('[Captive portal] backgroundImage uploading', props<{ backgroundImage: File }>());

export const backgroundImageUploadingFinished = createAction('[Captive portal] backgroundImage uploading finished', props<{ url: string }>());

export const backgroundImageUploadingError = createAction('[Captive portal] backgroundImage uploading error', props<{ error: any }>());

export const backgroundRepeatAlignmentChanged = createAction(
  '[Captive Portal] Background Repeat Alignment Changed',
  props<{ backgroundRepeatAlignment: { label: string; value: string } }>()
);

export const layerUpdated = createAction(
  '[Captive Portal] Layer Updated',
  props<{ layer: { enabled: boolean; layerColor: string } }>()
);

export const scanningUrlForLogoFinished = createAction(
  '[Captive portal] scanning URL for logo finished',
  props<{ imageUrl: string }>()
);

export const scanningUrlForLogoError = createAction(
  '[Captive portal] scanning URL for logo error',
  props<{ error: any }>()
);

export const brandingTitleTextChanged = createAction(
  '[Captive portal] branding title text changed',
  props<{ text: string }>()
);

export const brandingFooterTextChanged = createAction(
  '[Captive portal] branding footer text changed',
  props<{ text: string }>()
);

export const customTermsTextChanged = createAction(
  '[Captive portal] customer terms text changed',
  props<{ text: string }>()
);

export const previewOpened = createAction('[Captive portal] preview opened');
export const previewClosed = createAction('[Captive portal] preview closed');
export const previewLoaded = createAction('[Captive portal] preview loaded', props<{ previewUrl: string }>());
export const previewLoadedError = createAction('[Captive portal] preview loaded error', props<{ error: any }>());
export const publishOpened = createAction('[Captive portal] publish opened');
export const publishClosed = createAction('[Captive portal] publish closed');
export const publishLoaded = createAction('[Captive portal] publish loaded');
export const publishError = createAction('[Captive portal] publish error', props<{ error: any }>());
