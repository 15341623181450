import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'tooltip-container',
  templateUrl: './tooltip-container.component.html',
  styleUrls: ['./tooltip-container.component.scss']
})
export class TooltipContainerComponent {
  @Input() data: any = '';
  @Input() maxWidth: number = null;
  @Input() template: TemplateRef<unknown>;

  constructor() {}
}
