import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ThemeService {
  private active: string = 'light';
  private themes: string[] = ['light', 'dark'];
  public listener: BehaviorSubject<string> = new BehaviorSubject<string>(this.active);

  getAvailableThemes(): string[] {
    return this.themes;
  }

  getActiveTheme(): string {
    return this.active;
  }

  setActiveTheme(theme: string): any {
    this.themes.forEach((t: string) => {
      if (theme === t) {
        this.active = theme;

        this.listener.next(theme);

        document.documentElement.setAttribute('theme', theme);
      }
    });

    return theme;
  }

  setDefaultTheme(ui: string): string {
    if (ui === 'noc') {
      return this.setActiveTheme('dark');
    } else {
      return this.setActiveTheme('light');
    }
  }
}
