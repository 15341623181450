import { Directive, ElementRef, HostBinding, HostListener, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[toggler]'
})
export class TogglerDirective {
  @HostBinding('class.open')
  open: boolean = false;

  @Output()
  toggler: any = new EventEmitter();

  constructor(private el: ElementRef) {}

  @HostListener('document:click', ['$event'])
  handleClick(event: MouseEvent): void {
    const ignore = this.el.nativeElement.querySelector('.ignore');
    const enable = this.el.nativeElement.querySelector('.enable');

    if (ignore && ignore.contains(event.target)) {
      return;
    }

    if (enable && enable.contains(event.target)) {
      this.toggle(true);
      return;
    }

    if (this.el.nativeElement.contains(event.target)) {
      if (this.open) {
        this.toggle(false);
      } else {
        this.toggle(true);
      }
    } else {
      this.toggle(false);
    }
  }

  toggle(mode: boolean): void {
    this.open = mode;
    this.toggler.emit(mode);
  }
}
