import { Directive, OnInit, ElementRef, Renderer2, HostListener } from '@angular/core';

@Directive({
  selector: '[autoreadonly]'
})
export class AutoreadonlyDirective implements OnInit {
  constructor(private el: ElementRef, private render: Renderer2) {}

  @HostListener('focus')
  focusIn(): void {
    this.focus(true);
  }

  @HostListener('focusout')
  focusOut(): void {
    this.focus(false);
  }

  @HostListener('touchstart')
  touch(): void {
    this.focus(true);
  }

  ngOnInit(): void {
    this.focus(false);
  }

  focus(enable: boolean): void {
    if (enable) {
      this.render.removeAttribute(this.el.nativeElement, 'readonly');
      this.el.nativeElement.select();
    } else {
      this.render.setAttribute(this.el.nativeElement, 'readonly', 'readonly');
    }
  }
}
