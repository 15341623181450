import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ModalComponent } from 'src/app/components/modal/modal.component';

interface Button {
  style: string;
  value: string;
}

interface Options {
  buttons: Button[];
  wordWrap?: boolean; // default is true
  params?: any;
}

@Injectable()
export class ModalService {
  showGlobalInventory: Subject<any> = new Subject<any>();

  modals: ModalComponent[] = [];
  dialog: Subject<any> = new Subject<any>();

  constructor() {}

  add(modal: ModalComponent): void {
    this.modals.push(modal);
  }

  remove(modal: ModalComponent): void {
    this.modals = this.modals.filter((m: ModalComponent) => m !== modal);
  }

  showDialog(message: string, title: string, options: Options): Subject<{ item: Button; options: Options }> {
    const event = new Subject<{ item: Button; options: Options }>();

    this.dialog.next({
      title,
      message,
      options,
      event
    });

    return event;
  }
}
