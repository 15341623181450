<spacer>
  <div class="breadcrumbs">
    <div class="breadcrumb">{{ 'breadcrumb.thrive' | translate }}<icon name="icon-arrow-down"></icon></div>
    <div class="breadcrumb">{{ 'breadcrumb.dataStream' | translate }}</div>
  </div>
</spacer>
<spacer>
  <h1>{{ 'thrive.title' | translate }}</h1>
  <icon name="icon-info" class="size-1_5" tooltip="{{ 'thrive.tooltip' | translate }}"></icon>
</spacer>
<div class="container-fluid">
  <collapsiblebox [collapsible]="false">
    <div header>
      <div class="title">
        {{ ring.name }}
        <icon name="icon-info" class="size-1" tooltip="{{ 'thrive.deviceTooltip' | translate }}"></icon>
      </div>
      <div class="status">
        <div class="circle connected"></div>
        {{ 'thrive.online' | translate }}
      </div>
    </div>
    <div container>
      <div class="image">
        <div class="glow"></div>
        <img src="/assets/raster/thrive_ring.png" />
      </div>
      <div class="column">
        <div class="data">
          <div class="key">{{ 'thrive.ringId' | translate }}</div>
          <div class="value">{{ ring.id }}</div>
        </div>
        <div class="data">
          <div class="key">{{ 'thrive.firmwareVersion' | translate }}</div>
          <div class="value">{{ ring.firmware }}</div>
        </div>
        <div class="data">
          <div class="key">{{ 'thrive.phoneModel' | translate }}</div>
          <div class="value">{{ ring.phone }}</div>
        </div>
        <div class="data">
          <div class="key">{{ 'thrive.appVersion' | translate }}</div>
          <div class="value">{{ ring.version }}</div>
        </div>
        <div class="data">
          <div class="key">{{ 'thrive.accountStatus' | translate }}</div>
          <div class="value">{{ ring.status }}</div>
        </div>
        <div class="data">
          <div class="key">{{ 'thrive.ringBattery' | translate }}</div>
          <div class="value">
            <icon [name]="'icon-battery-' + ring.battery"></icon>
            {{ ring.battery }}%
          </div>
        </div>
        <div class="data">
          <div class="key">{{ 'thrive.lastConnectAttempt' | translate }}</div>
          <div class="value">
            {{ ring.lastConnectAttempt }}
            <div class="link" (click)="toggleLogModal()">{{ 'thrive.viewLog' | translate }}</div>
          </div>
        </div>
        <div class="data">
          <div class="key">{{ 'thrive.lastError' | translate }}</div>
          <div class="value">{{ ring.lastError }}</div>
        </div>
      </div>
    </div>
  </collapsiblebox>
  <spacer>
    <h2>{{ 'thrive.wellbeingMetrics' | translate }}</h2>
  </spacer>
  <div class="tabs">
    <div class="tab" [class.selected]="mode === 'user'" (click)="changeTab('user')">
      {{ 'thrive.ringUser.tab' | translate }}
    </div>
    <div class="tab" [class.selected]="mode === 'watchers'" (click)="changeTab('watchers')">
      {{ 'thrive.ringWatchers.tab' | translate }}
    </div>
  </div>
  <ng-container *ngIf="mode === 'user'">
    <div class="cards">
      <collapsiblebox [showHeader]="false" *ngFor="let card of ringUser.cards">
        <div container>
          <div class="title">{{ card.title | translate }}</div>
          <icon [name]="card.icon" class="size-4"></icon>
          <div class="subtitle">{{ 'thrive.ringUser.notifications' | translate }}</div>
          <div class="notification" *ngFor="let notification of card.notifications">
            <div class="event">
              {{ notification.translation | translate }}
              <icon name="icon-info" class="size-0_6"></icon>
            </div>
            <div class="status">
              <div class="circle" [class.enabled]="notification.enabled"></div>
              {{ (notification.enabled ? 'thrive.ringUser.on' : 'thrive.ringUser.off') | translate }}
            </div>
          </div>
        </div>
      </collapsiblebox>
    </div>
    <div class="cards overlap">
      <collapsiblebox [showHeader]="false" *ngFor="let card of ringUser.cards">
        <div container>
          <div class="subtitle">{{ 'thrive.ringUser.recentActivity' | translate }}</div>
          <div class="activity" *ngFor="let activity of card.activities">
            {{ activity.translation | translate }}
            <div class="data">
              <div class="date">{{ activity.timestamp | date: 'M/d/y' }}</div>
              <div class="time">{{ activity.timestamp | date: 'hh:mm:ss' }}</div>
              <div class="link" (click)="toggleActivityLogModal()">{{ 'thrive.ringUser.viewLog' | translate }}</div>
            </div>
          </div>
        </div>
      </collapsiblebox>
    </div>
  </ng-container>
  <ng-container *ngIf="mode === 'watchers'">
    <div class="cards watchers">
      <collapsiblebox [showHeader]="false">
        <div container>
          <div class="title large">
            {{ 'thrive.ringWatchers.active' | translate }}
            <icon name="icon-add" class="size-0_8" (click)="toggleActiveWatchersModal()"></icon>
          </div>
          <div class="watchers">
            <div class="watcher" *ngFor="let watcher of ringWatchers.active">
              <div class="name">{{ watcher.name }}</div>
              <div class="action">{{ 'thrive.ringWatchers.remove' | translate }}</div>
            </div>
          </div>
        </div>
      </collapsiblebox>
      <collapsiblebox [showHeader]="false">
        <div container>
          <div class="title large">
            {{ 'thrive.ringWatchers.invited' | translate }}
            <icon name="icon-add" class="size-0_8" (click)="toggleInvitedWatchersModal()"></icon>
          </div>
          <div class="watchers">
            <div class="watcher" *ngFor="let watcher of ringWatchers.invited">
              <div class="name">{{ watcher.name }}</div>
              <div class="action">{{ 'thrive.ringWatchers.remove' | translate }}</div>
            </div>
          </div>
        </div>
      </collapsiblebox>
    </div>
    <div class="cards">
      <collapsiblebox [showHeader]="false" *ngFor="let card of ringWatchers.cards">
        <div container>
          <div class="title">{{ card.title | translate }}</div>
          <icon [name]="card.icon" class="size-4"></icon>
          <div class="subtitle">{{ 'thrive.ringWatchers.notifications' | translate }}</div>
          <div
            class="notification"
            [class.sub-notification]="notification.notifications"
            *ngFor="let notification of card.notifications"
          >
            <div class="event">
              {{ notification.translation | translate }}
              <icon name="icon-info" class="size-0_6" *ngIf="!notification.notifications"></icon>
            </div>
            <div class="status" *ngIf="!notification.notifications">
              <div class="circle" [class.enabled]="notification.enabled"></div>
              {{ (notification.enabled ? 'thrive.ringWatchers.on' : 'thrive.ringWatchers.off') | translate }}
            </div>
            <div class="sub-notifications" *ngIf="notification.notifications">
              <div class="notification" *ngFor="let subNotification of notification.notifications">
                <div class="event">
                  {{ subNotification.translation | translate }}
                  <icon name="icon-info" class="size-0_6"></icon>
                </div>
                <div class="status">
                  <div class="circle" [class.enabled]="subNotification.enabled"></div>
                  {{ (subNotification.enabled ? 'thrive.ringWatchers.on' : 'thrive.ringWatchers.off') | translate }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </collapsiblebox>
    </div>
  </ng-container>
  <div class="footer">
    <button class="action">
      <icon name="icon-garbage"></icon>
      <span>{{ 'thrive.deleteAllCustomerData' | translate }}</span>
    </button>
  </div>
</div>

<modal class="logModal" mode="compact" (overlayAction)="toggleLogModal()" *ngIf="showLogModal">
  <ng-container title>{{ 'thrive.logModal.title' | translate }}</ng-container>
  <ng-container content>
    <div>00:00:00 - Log 1</div>
    <div>00:00:00 - Log 2</div>
    <div>00:00:00 - Log 3</div>
    <div>00:00:00 - Log 4</div>
    <div>00:00:00 - Log 5</div>
    <div>00:00:00 - Log 6</div>
  </ng-container>
  <ng-container controls>
    <button class="tertiary medium light" (click)="toggleLogModal()">
      {{ 'thrive.logModal.close' | translate }}
    </button>
  </ng-container>
</modal>

<modal class="logModal" mode="compact" (overlayAction)="toggleActivityLogModal()" *ngIf="showActivityLogModal">
  <ng-container title>{{ 'thrive.logModal.activityTitle' | translate }}</ng-container>
  <ng-container content>
    <div>00:00:00 - Log 1</div>
    <div>00:00:00 - Log 2</div>
    <div>00:00:00 - Log 3</div>
    <div>00:00:00 - Log 4</div>
    <div>00:00:00 - Log 5</div>
    <div>00:00:00 - Log 6</div>
  </ng-container>
  <ng-container controls>
    <button class="tertiary medium light" (click)="toggleActivityLogModal()">
      {{ 'thrive.logModal.close' | translate }}
    </button>
  </ng-container>
</modal>

<modal
  class="activeWatchersModal"
  mode="compact"
  (overlayAction)="toggleActiveWatchersModal()"
  *ngIf="showActiveWatchersModal"
>
  <ng-container title>{{ 'thrive.watchersModal.activeWatchersTitle' | translate }}</ng-container>
  <ng-container content>
    <div>TO DO</div>
  </ng-container>
  <ng-container controls>
    <button class="tertiary medium light" (click)="toggleActiveWatchersModal()">
      {{ 'thrive.logModal.close' | translate }}
    </button>
  </ng-container>
</modal>

<modal
  class="invitedWatchersModal"
  mode="compact"
  (overlayAction)="toggleInvitedWatchersModal()"
  *ngIf="showInvitedWatchersModal"
>
  <ng-container title>{{ 'thrive.watchersModal.invitedWatchersTitle' | translate }}</ng-container>
  <ng-container content>
    <div>TO DO</div>
  </ng-container>
  <ng-container controls>
    <button class="tertiary medium light" (click)="toggleInvitedWatchersModal()">
      {{ 'thrive.logModal.close' | translate }}
    </button>
  </ng-container>
</modal>
