import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { IDevice } from 'src/app/lib/interfaces/interface';
import { DeviceService } from 'src/app/lib/services/device.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { ToastService } from 'src/app/lib/services/toast.service';
import { pollingPull } from 'src/app/store/polling/polling.actions';

@Component({
  selector: 'priority-modal',
  templateUrl: './priority-modal.component.html',
  styleUrls: ['./priority-modal.component.scss']
})
export class PriorityModalComponent implements OnInit {
  @Input()
  device: IDevice = null;

  @Output()
  closePriorityModal = new EventEmitter<void>();

  canEditDeviceQos = !this.plume.isSomeSupportRole() || this.plume.cloudVersionAbove1_100();
  priority = {
    priorityTypes: ['auto', 'voice', 'video', 'bestEffort', 'background', 'none', 'ignore'],
    showPriorities: false,
    current: {
      priorityType: 'auto'
    }
  };

  constructor(
    private deviceService: DeviceService,
    private mixpanel: MixpanelService,
    private store: Store,
    private toast: ToastService,
    private plume: PlumeService
  ) {}

  ngOnInit(): void {
    this.priority.current.priorityType = this.device?.qos?.prioritization
      ? this.device.qos.prioritization.mode
      : 'auto';
  }

  selectDevicePriority(priorityType: string) {
    this.priority.showPriorities = false;
    this.priority.current.priorityType = priorityType;
  }

  setDevicePriority(): void {
    this.deviceService.setDevicePriority$(this.device.mac, this.priority.current.priorityType).subscribe(
      (qos) => {
        this.mixpanel.storeEvent('DEVICES_PRIORITY_UPDATE', {
          DEVICE_MAC: this.device.mac,
          PRIORITY: this.priority.current.priorityType
        });

        this.device.qos = qos;
        this.closeModal();
        this.store.dispatch(pollingPull({ debugSource: 'device - set device priority' }));
        this.toast.success(
          this.priority.current.priorityType === 'auto'
            ? 'devices.device.successPrioritizeRemovedMessage'
            : 'devices.device.successPrioritizeMessage',
          'devices.device.successPrioritizeTitle',
          {
            params: {
              device: this.device.name
            }
          }
        );
      },
      (error) => {
        this.toast.error('devices.device.errorPrioritizeMessage', 'devices.device.errorPrioritizeTitle', {
          params: {
            error: error.error.error.message
          }
        });
      }
    );
  }

  closeModal() {
    this.closePriorityModal.emit();
  }
}
