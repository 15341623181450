<header placeholder="header.searchCustomers"></header>
<div class="container-fluid">
  <div class="sidemenu">
    <div class="inner-left">
      <div class="item" [class.active]="mode === 'Customer'" (click)="getCustomer()">
        {{ 'header.apiexplorer.customer' | translate }}
      </div>
      <div class="item" [class.active]="mode === 'Reports'" (click)="getReports()">
        {{ 'header.apiexplorer.reports' | translate }}
      </div>
      <div
        class="item"
        [class.active]="mode === 'LTE'"
        (click)="getLTE()"
        *ngIf="
          plume.getEnv()?.lteUrl &&
          plume.getPermissions()?.uiFeatures?.lte &&
          plume.cloudVersionAbove1_88() &&
          !plume.isUpriseProperyManager()
        "
      >
        {{ 'header.apiexplorer.lte' | translate }}
      </div>
    </div>
  </div>
  <div class="content">
    <div class="inner-right">
      <div class="api-permission">{{ 'header.apiexplorer.description' | translate }}</div>
      <div id="swagger-ui"></div>
    </div>
  </div>
</div>
