<section *ngIf="(isUprise$ | async) === false">
  <div class="header">
    <div class="label">{{ 'captiveportal.guestLanguage' | translate }}</div>
    <div class="toggler" [class.enabled]="multilingual" (click)="toggleMultilingual()">
      <div class="handle"></div>
    </div>
  </div>
</section>
<section *ngIf="(isUprise$ | async) === false">
  <div class="header">
    <div class="label">{{ 'captiveportal.language' | translate }}</div>
    <div class="language">
      <drop-down>
        {{ language.lang | translate }}
        <ng-container dropdown>
          <li *ngFor="let language of languages" (click)="selectLanguage(language)">{{ language.lang | translate }}</li>
        </ng-container>
      </drop-down>
    </div>
  </div>
</section>
<section *ngIf="(isUprise$ | async) === false">
  <div class="header">
    <div class="label">{{ 'captiveportal.textColor' | translate }}</div>
    <div class="sample">
      {{ 'captiveportal.colorPicker' | translate }}
      <div class="wrapper">
        <input [formControl]="textColor" type="color" />
      </div>
    </div>
  </div>
</section>

<section *ngIf="(isUprise$ | async) === false">
  <section *ngIf="(isUprise$ | async) === false">
    <div class="header">
      <div class="label">{{ 'captiveportal.Background' | translate }}</div>
    </div>
      <div class="header">
        <div class="sub-label">{{ 'captiveportal.backgroundType' | translate }}</div>
      <div class="background-dropdown">
        <drop-down>
          {{ selectedBackgroundType | translate}}
          <ng-container dropdown>
            <li *ngFor="let type of backgroundType" (click)="changeBackgroundType(type)">{{ type | translate }}</li>
          </ng-container>
        </drop-down>
      </div>
      </div>
  </section>
  <section *ngIf="(isUprise$ | async) === false && selectedBackgroundType===backgroundType[1]">
    <div class="header">
      <div class="sub-label">{{ 'captiveportal.backgroundColor' | translate }}</div>
      <div class="sample">
        {{ 'captiveportal.colorPicker' | translate }}
        <div class="wrapper">
          <input [formControl]="backgroundColor" type="color" />
        </div>
      </div>
    </div>
  </section>
  <section *ngIf="(isUprise$ | async) === false && selectedBackgroundType===backgroundType[0]">
    <div class="header">
      <span>{{ 'captiveportal.backgroundImage' | translate }}</span>
    </div>
    <div class="upload-section">
      <button class="secondary" (click)="upload()">{{ 'captiveportal.upload' | translate }}</button>
      <button *ngIf="isImageUploaded" class="secondary" (click)="delete()">{{ 'captiveportal.delete' | translate }}</button>
    </div>
    <section>
      <div class="header">
        <span>{{ 'captiveportal.imagePosition' | translate }}</span>
        <div class="background-dropdown">
          <drop-down>
            {{selectedBackgroundImagePosition | translate}}
            <ng-container dropdown>
              <ng-container *ngFor="let position of backgroundImagePositionDropdownLabel">
                <li (click)="changeBackgroundSetting('position', position)" >
                  {{ position | translate }}
                </li>
              </ng-container>
            </ng-container>
          </drop-down>
        </div>
      </div>
    </section>
    <section>
      <div class="header">
        <span>{{ 'captiveportal.backgroundAdjustment' | translate }}</span>
        <div class="background-dropdown">
          <drop-down>
            {{selectedBackgroundImageAdjustment | translate}}
            <ng-container dropdown>
              <ng-container *ngFor="let adjustment of backgroundImageAdjustment">
                <li (click)="changeBackgroundSetting('adjustment', adjustment)" >
                  {{adjustment | translate}}
                </li>
              </ng-container>
            </ng-container>
          </drop-down>
        </div>
      </div>
    </section> 
  </section>
</section>
<section>
  <div class="header">
    <div class="label">{{ 'captiveportal.redirect.url' | translate }}</div>
    <div class="toggler" [class.enabled]="redirectEnabled" (click)="toggleRedirect()">
      <div class="handle"></div>
    </div>
  </div>
  <div class="content">
    <labeled-input [clearButton]="true">
      <input
        [formControl]="redirectUrl"
        type="text"
        placeholder="{{ 'captiveportal.redirect.placeholder' | translate }}"
        (blur)="validateRedirectUrl(redirectUrl.value)"
      />
    </labeled-input>
  </div>
</section>
<section *ngIf="(isUprise$ | async) === false">
  <div class="header">
    <div class="label">{{ 'captiveportal.redirect.duration' | translate }}</div>
  </div>
  <p>{{ 'captiveportal.redirect.manual' | translate }}</p>
  <div class="content">
    <div class="slider" #redirectSlider></div>
    <div class="value">{{ redirectSeconds }} {{ 'captiveportal.redirect.seconds' | translate }}</div>
  </div>
</section>
