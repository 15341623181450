<modal class="focus" mode="normal" (overlayAction)="closeModal()">
  <ng-container title>
    {{ device.nickname || device.name | macAddr : true }}
    {{ 'devices.device.focusTitle' | translate }}
  </ng-container>

  <ng-container content>
    <icon name="icon-alert-warning" class="size-0_8 focusWarning"></icon>
    {{ 'devices.device.changeFocusWarning' | translate }}
  </ng-container>

  <ng-container content>
    <div class="columns">
      <div class="column">
        <div class="row">
          <div class="key">{{ 'devices.device.focused' | translate }}:</div>
          <div class="value" [ngClass]="device.focused ? 't-good' : 't-bad'">
            {{ (device.focused ? 'devices.device.yes' : 'devices.device.no') | translate }}
          </div>
        </div>
      </div>
    </div>

    <div *ngFor="let focus of focuses; let focusId = index">
      <div class="columns">
        <div class="column">
          <div class="row">
            <div class="name">
              {{ focus.name }}
              <icon
                name="icon-check"
                class="size-0_8 focusActive"
                tooltip="{{ 'devices.device.focusThisDevice' | translate }}"
                *ngIf="checkDeviceIsUsingFocus(focus)"
              ></icon>
              <icon
                name="icon-info"
                class="size-0_8 focusInactive"
                tooltip="{{ 'devices.device.focusNotThisDevice' | translate }}"
                *ngIf="!checkDeviceIsUsingFocus(focus)"
              ></icon>
            </div>
          </div>
          <div class="row">
            <button class="action small" (click)="toggleFocus(focus, false)" *ngIf="focus.enabled">
              {{ 'devices.device.suspendFocus' | translate }}
            </button>
            <button class="action small" (click)="toggleFocus(focus, true)" *ngIf="!focus.enabled">
              {{ 'devices.device.enableFocus' | translate }}
            </button>
            <icon
              name="icon-edit"
              class="size-1_2 icon"
              tooltip="{{ 'devices.device.edit' | translate }}"
              (click)="editFocusSchedule(focus)"
            ></icon>
            <icon
              name="icon-close"
              class="size-0_7 icon"
              tooltip="{{ 'devices.device.remove' | translate }}"
              (click)="deleteFocusSchedule(focus)"
              *ngIf="focusScheduleDeleteLoading !== focus.id"
            ></icon>
            <icon
              name="icon-spinner"
              class="spinner icon"
              [class.loading]="focusScheduleDeleteLoading === focus.id"
              *ngIf="focusScheduleDeleteLoading === focus.id"
            ></icon>
          </div>
        </div>
      </div>
      <div class="schedules" *ngIf="focus.schedule?.schedules.length > 0">
        <div class="schedule" *ngFor="let schedule of focus.schedule.schedules; let id = index">
          <div class="schedule-wrapper">
            <div class="schedule">
              <div class="time">
                {{ schedule.startTime }} - {{ schedule.endTime || ('devices.device.endOfDay' | translate) }}
              </div>
              <div class="days">
                <div
                  class="day"
                  [class.selected]="schedule.daysOfWeek.indexOf(day) >= 0"
                  *ngFor="let day of [1, 2, 3, 4, 5, 6, 7]"
                >
                  {{ processDay(day) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container controls>
    <div class="control-container">
      <button class="tertiary light" (click)="closeModal()">
        {{ 'health.networkInformation.modal.close' | translate }}
      </button>
      <button class="action" (click)="openFocusScheduleModal()">
        {{ 'devices.device.addSchedule' | translate }}
      </button>
    </div>
  </ng-container>
</modal>

<focus-schedule-modal
  [device]="device"
  [focusSchedule]="focusSchedule"
  (updateFocuses)="getFocuses()"
  (closeFocusScheduleModal)="closeFocusScheduleModal()"
  *ngIf="focusScheduleModalOpen && device"
></focus-schedule-modal>
