import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cache'
})
export class CachePipe implements PipeTransform {
  cache: unknown = null;
  transform<T>(value: T, enabled = true): T {
    if (value !== undefined && value !== null) {
      this.cache = value;
    }
    return enabled ? this.cache as T : value;
  }
}
