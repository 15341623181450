import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { TroubleshootingService } from 'src/app/lib/services/troubleshooting.service';
import { ToastService } from 'src/app/lib/services/toast.service';
import { MessageService } from 'src/app/lib/services/message.service';
import { LoggingService } from 'src/app/lib/services/logging.service';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { ApiService } from 'src/app/lib/services/api.service';
import { findIndex } from 'lodash';
import { OptimizationService } from 'src/app/lib/services/optimization.service';
import { selectLocationOptimization, selectNodes } from 'src/app/store/polling/polling.selector';
import { Store } from '@ngrx/store';

@Component({
  selector: 'informbubble',
  templateUrl: './informBubble.component.html',
  styleUrls: ['../bubbles.scss', './informBubble.component.scss']
})
export class InformBubbleComponent implements OnInit, OnDestroy {
  @Input()
  extender?: any;
  @Input()
  nocheck: boolean = false;
  @Input()
  finishbutton: boolean = false;
  @Input()
  hasextenders: boolean = true;
  @Input()
  alarm: string;

  nodes: any = [];
  nodeSubscription: any;
  logSubscription: any;
  optimizationSubscription: any;
  connectionsOK: boolean = false;
  speedtestOk: any;
  recordlog: string = 'health.bubbles.logBubble.recordlog';
  optimizeState: string = 'none';
  enableOptimizationButton$ = this.optimizationService.enableOptimization$();
  optimizationChange: number = 0;

  constructor(
    private toast: ToastService,
    private troubleShoot: TroubleshootingService,
    private message: MessageService,
    private logging: LoggingService,
    private plume: PlumeService,
    private api: ApiService,
    private optimizationService: OptimizationService,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.logSubscription = this.troubleShoot.pulllog.subscribe((response: any) => {
      this.recordlog = response;
    });

    this.troubleShoot.initPullLog();

    this.nodeSubscription = this.store.select(selectNodes).subscribe((nodes) => {
      if (nodes) {
        this.nodes = nodes;
        this.checkConnections();
      }
    });

    this.optimizeState = 'loading';

    this.api
      .get(
        '/Customers/' +
          this.plume.customerid +
          '/locations/' +
          this.plume.locationid +
          '/appFacade/home?filters=optimization'
      )
      .subscribe((response: any) => {
        this.optimizationState(
          response.optimization.state || 'techdash.infoArea.ready',
          response.optimization.stateChangedAt || Date.now(),
          true
        );
      });

    this.optimizationSubscription = this.store.select(selectLocationOptimization).subscribe((response: any) => {
      if (response) {
        this.optimizationState(response.state, response.stateChangedAt);
      }
    });
  }

  triggerOptimize(): void {
    if (this.validateOptimization()) {
      this.api
        .post('/Customers/' + this.plume.customerid + '/locations/' + this.plume.locationid + '/optimize', {})
        .subscribe(() => {
          this.optimizeState = 'running';
          this.toast.success(
            'toast.technician.successOptimizationStartedMessage',
            'toast.technician.successOptimizationStartedTitle'
          );
        });
    }
  }

  optimizationState(state: any, changedAt: number, init: boolean = false): void {
    if (this.optimizationChange < changedAt) {
      this.optimizationChange = changedAt;

      switch (state) {
        case 'optimized':
          this.optimizeState = 'optimized';
          if (!init) {
            this.toast.success(
              'toast.technician.successOptimizationCompletedMessage',
              'toast.technician.successOptimizationCompletedTitle'
            );
          }
          break;
        case 'failed':
        case 'fail':
          this.optimizeState = 'fail';
          if (!init) {
            this.toast.warning(
              'toast.technician.successOptimizationFailedMessage',
              'toast.technician.successOptimizationFailedTitle'
            );
          }

          break;
        case 'initiated':
        case 'inProgress':
          this.optimizeState = 'running';
          break;
        default:
          this.optimizeState = state;
      }
    }
  }

  validateOptimization(): boolean {
    let counter = 0;
    for (const node of this.nodes) {
      if (node.connectionState === 'connected') {
        counter++;
      }
    }
    if (counter) {
      return true;
    } else {
      return false;
    }
  }

  copy(value: string): void {
    try {
      window.navigator.clipboard.writeText(value);
      this.toast.success('toast.informBubble.successMessage', 'toast.informBubble.successTitle');
    } catch (error) {}
  }

  finish(): void {
    this.message.sendMessage({ source: 'closeNetworkSection' });
  }

  checkConnections(): void {
    this.speedtestOk = true;
    if (this.extender) {
      if (this.extender.leafToRoot && this.extender.leafToRoot.length > 0) {
        this.connectionsOK = true;

        this.extender.leafToRoot.forEach((connection: any) => {
          const index = findIndex(this.nodes, { id: connection.id });
          if (index > -1) {
            const connectedNode = this.nodes[index];
            if (connectedNode.health && connectedNode.health.status === 'poor') {
              this.connectionsOK = false;
            }
          } else {
            this.logging.warn('Unable to find node in leaftoroot');
            return null;
          }
        });
      }
    }
    this.logging.log('inform.checkConnections: ' + this.connectionsOK);
  }

  ngOnDestroy(): void {
    if (this.optimizationSubscription) {
      this.optimizationSubscription.unsubscribe();
    }
    if (this.logSubscription) {
      this.logSubscription.unsubscribe();
    }
    if (this.nodeSubscription) {
      this.nodeSubscription.unsubscribe();
    }
  }
}
