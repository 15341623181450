import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { RouterNavigationAction, ROUTER_NAVIGATION, SerializedRouterStateSnapshot } from '@ngrx/router-store';
import { combineLatest, Observable, of, OperatorFunction } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { customerClose, customerChange } from '../customer/customer.actions';
import { selectCustomerIdentification } from '../customer/customer.selectors';
import { AuthService } from 'src/app/lib/services/auth.service';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { ToastService } from 'src/app/lib/services/toast.service';
import { Router } from '@angular/router';

@Injectable()
export class RouterEffects {
  closeCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      switchMap((action: RouterNavigationAction) =>
        combineLatest([of(action), this.store.select(selectCustomerIdentification).pipe(take(1))])
      ),
      filter(
        ([action, storedCustomer]) =>
          !this.isCustomerUrl(action) && (!!storedCustomer.locationid || !!storedCustomer.customerid)
      ),
      map(() => customerClose())
    )
  );

  openOrChangeCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      map(
        (action: RouterNavigationAction) =>
          [action, RouterEffects.getCustomerFromURL(action.payload.routerState.url)] as const
      ),
      distinctUntilChanged(
        ([, prevUrlCustomer], [, currUrlCustomer]) =>
          prevUrlCustomer.customerid === currUrlCustomer.customerid &&
          prevUrlCustomer.locationid === currUrlCustomer.locationid
      ),
      filter(([action]) => this.isCustomerUrl(action) && this.auth.isAuthenticated()),
      switchMap(([action, customerFromUrl]) => combineLatest([
          of(action),
          of(customerFromUrl),
          this.store.select(selectCustomerIdentification).pipe(take(1)),
          this.loadAccessTokenIfNeeded$(customerFromUrl)
        ])),
      map(([action, customerFromUrl, storedCustomer]) =>
        customerChange({
          ...customerFromUrl,
          fromBrowserHistory:
            !action?.payload?.routerState?.url?.endsWith('/component-clearer') && !!storedCustomer.customerid
        })
      )
    )
  );

  public static getCustomerFromURL(url: string): { customerid: string; locationid: string } {
    const urlParts = url.split('/');
    if (url?.startsWith('/customer')) {
      return {
        customerid: urlParts[2],
        locationid: urlParts[4]
      };
    }
    if (url?.startsWith('/captiveportal')) {
      return {
        customerid: urlParts[3],
        locationid: urlParts[5]
      };
    }
    return { customerid: '', locationid: '' };
  }

  private loadAccessTokenIfNeeded$(customer: { customerid: string; locationid: string }): Observable<unknown> {
    const env = this.plume.getEnv();

    if (this.plume.isUpriseProperyManager()) {
      return this.auth.getAccessToken(env.upriseUrl, customer.locationid).pipe(
        tap((response) => this.auth.setUpriseToken(response.id, false)),
        this.tokenError(customer.locationid)
      );
    }

    if (this.plume.isFlexRole()) {
      return this.auth.getAccessToken(env.flexUrl, customer.locationid).pipe(
        tap((response) => this.auth.setFlexToken(response.id, false)),
        this.tokenError(customer.locationid)
      );
    }
    return of(true);
  }

  private tokenError(locationid: string): OperatorFunction<unknown, any> {
    return catchError((err) => {
      this.router.navigate(['/']).then(() => {
        this.toast.error('toast.customer.locationInvalid', 'toast.customer.locationInvalidTitle', {
          disableTimeOut: true,
          params: {
            id: locationid
          }
        });
      });
      throw err;
    });
  }

  private isCustomerUrl(action: RouterNavigationAction<SerializedRouterStateSnapshot>): boolean {
    return (
      action?.payload?.routerState?.url?.startsWith('/customer') ||
      action?.payload?.routerState?.url?.startsWith('/captiveportal')
    );
  }

  constructor(
    private actions$: Actions,
    private store: Store,
    private auth: AuthService,
    private plume: PlumeService,
    private toast: ToastService,
    private router: Router
  ) {}
}
