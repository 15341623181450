import { Directive, OnChanges, Input, HostListener, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[keyboard]'
})
export class KeyboardDirective implements OnChanges {
  @Input()
  size: number = 0;

  @Output()
  keyboard: any = new EventEmitter();

  current: number = 0;

  @HostListener('keydown', ['$event'])
  handleKeys(event: KeyboardEvent): void {
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      event.preventDefault();

      if (this.size) {
        let current = null;

        if (event.key === 'ArrowUp') {
          current = this.current !== null ? (this.current > 0 ? this.current - 1 : 0) : this.size - 1;
        }

        if (event.key === 'ArrowDown') {
          current = this.current !== null ? (this.current < this.size - 1 ? this.current + 1 : this.size - 1) : 0;
        }

        this.update('move', current, current);
      } else {
        this.update('move', 0, 0);
      }
    }

    if (event.key === 'Enter' && this.current !== null) {
      event.preventDefault();
      this.update('select', this.current, this.current);
    }
  }

  ngOnChanges(): void {
    this.current = null;
  }

  update(mode: string, current: number, index: number): void {
    this.current = current;
    this.keyboard.emit({ mode, index });
  }
}
