import { Component, EventEmitter, HostListener, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { DropDownComponent } from '../drop-down/drop-down.component';

export interface DropDownSearchItem {
  property: string;
  section: string;
  translation: string;
}

@Component({
  selector: 'drop-down-search',
  templateUrl: './drop-down-search.component.html',
  styleUrls: ['./drop-down-search.component.scss']
})
export class DropDownSearchComponent implements OnChanges {
  @Input() items: DropDownSearchItem[] = [];
  @Input() label = '';
  @Input() disabled = false;
  @Output() selected = new EventEmitter<DropDownSearchItem>();
  @ViewChild('dropdown') dropDown: DropDownComponent;

  filteredItems: (
    | (DropDownSearchItem & { title?: undefined })
    | { title: string; section?: undefined; translation?: undefined }
  )[] = [];
  filtertext = '';

  constructor() {}

  ngOnChanges(): void {
    this.filterValues();
  }

  filterValues(): void {
    const sections = this.items
      .filter((item) => item.translation.toLowerCase().includes(this.filtertext.toLowerCase()))
      .reduce(
        (acc, item) => ({ ...acc, [item.section]: [...(acc[item.section] ?? []), item] }),
        {} as { [key: string]: DropDownSearchItem[] }
      );

    this.filteredItems = Object.entries(sections).reduce(
      (acc, [key, items]) => [...acc, { title: key }, ...items],
      [] as DropDownSearchItem[]
    );
  }

  selectValue(
    item:
      | (DropDownSearchItem & {
          title?: undefined;
        })
      | {
          title: string;
          section?: undefined;
          translation?: undefined;
        }
  ): void {
    if (!item.title) {
      this.filtertext = '';
      this.filterValues();
      this.selected.emit(item as DropDownSearchItem);
    }
  }

  @HostListener('focusin') focusin(): void {
    this.dropDown.setOpenState(true);
  }

  @HostListener('click') click(): void {
    this.dropDown.setOpenState(true);
  }

  trackBy(index: number, item: DropDownSearchItem): string {
    return item.section + '.' + item.property;
  }
}
