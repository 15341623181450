import { createReducer, on } from '@ngrx/store';
import { I5gQoeMetrics, INeighborReportCns } from 'src/app/lib/interfaces/interface';
import { neighborReportCnsLoaded, neighborReportCnsLoadedError, qoe5GReportsLoaded, qoe5GReportsLoadedError } from './qoeCellular.actions';

export interface QoE5gMetricsState {
    qoe5gmetrics: I5gQoeMetrics[] | null;
    loading: boolean;
    error: any;
  }
export const initialState: QoE5gMetricsState = {
    qoe5gmetrics: null,
    loading: false,
    error: null,
  };

export const qoe5gMetricsReducer = createReducer(
    initialState,
    on(qoe5GReportsLoaded, (state, { qoe5gReports }) => ({
      ...state,
      qoe5gmetrics: qoe5gReports,
      loading: false,
      error: null
    })),
    on(qoe5GReportsLoadedError, (state, { error }) => ({
      ...state,
      loading: false,
      error
    }))
  );
export interface NeighborReportCnsState {
    neighborReportCns: INeighborReportCns | null;
    loading: boolean;
    error: any;
  }

export const neighborReportCnsInitialState: NeighborReportCnsState = {
    neighborReportCns: null,
    loading: false,
    error: null,
  };

export const NeighborReportCnsReducer = createReducer(
    neighborReportCnsInitialState,
    on(neighborReportCnsLoaded, (state, { neighborReportCns }) => ({
      ...state,
      neighborReportCns,
      loading: false,
      error: null
    })),
    on(neighborReportCnsLoadedError, (state, { error }) => ({
      ...state,
      loading: false,
      error
    }))
  );
