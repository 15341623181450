import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import mixpanel from 'mixpanel-browser';

@Injectable()
export class MixpanelService {
  enabled: boolean = environment.mixpanel.enabled;
  initialized = false;

  init(): void {
    if (this.enabled) {
      switch (environment.ENV) {
        case 'Production':
          mixpanel.init(environment.mixpanel.production);
          break;
        case 'Staging':
          mixpanel.init(environment.mixpanel.staging);
          break;
        case 'Local':
          mixpanel.init(environment.mixpanel.local);
          break;
        default:
          mixpanel.init(environment.mixpanel.development);
      }
    }
    this.initialized = true;
  }

  storeEvent(id: string, action: any = {}): void {
    if (this.enabled && this.initialized) {
      mixpanel.track(id, action);
    }
  }

  setUser(id: string, emailAddress: string, role: string, cspOperatorPartner: string = null): void {
    if (this.enabled) {
      mixpanel.identify(id);
      mixpanel.people.set({
        'Cloud ID': id,
        'Cloud Role': role,
        'Cloud Operator Partner': cspOperatorPartner
      });
      mixpanel.register({
        CLOUD_ID: id,
        CLOUD_ROLE: role,
        CLOUD_OPERATOR_PARTNERID: cspOperatorPartner
      });
    }
  }

  setEnvironment(environment: string): void {
    if (this.enabled) {
      mixpanel.register({ CLOUD_ENV: environment });
    }
  }

  setPartnerId(partnerId: string): void {
    if (this.enabled) {
      mixpanel.register({ CLOUD_PARTNER: partnerId });
    }
  }

  setLocation(customerId: string, locationId: string): void {
    if (this.enabled) {
      mixpanel.register({ CLOUD_CUSTOMER: customerId, CLOUD_LOCATION: locationId });
    }
  }

  setTheme(theme: string): void {
    if (this.enabled) {
      mixpanel.register({ THEME: theme });
    }
  }

  setUI(ui: string): void {
    if (this.enabled) {
      mixpanel.register({ UI: ui });
    }
  }

  clearCustomer(): void {
    if (this.enabled) {
      mixpanel.register({ CLOUD_CUSTOMER: null, CLOUD_LOCATION: null });
    }
  }

  clearUserAndCustomerDetails(): void {
    if (this.enabled) {
      mixpanel.reset();
    }
  }
}
