import { createAction, props } from '@ngrx/store';
import {
  ILteSupported,
  ILteSignalStrength,
  ILteCurrentState,
  ILteHwInfo,
  ILteNetworkConfiguration,
  ILteConfiguration
} from 'src/app/lib/interfaces/lte.interface';

export const lteConfigurationOpened = createAction('[LTE] LTE configuration opened');
export const lteConfigurationClosed = createAction('[LTE] LTE configuration closed');

export const lteSupportedLoaded = createAction('[LTE] Supported loaded', props<{ supported: ILteSupported }>());
export const lteSupportedLoadedError = createAction('[LTE] Supported loaded error', props<{ error: any }>());

export const lteSignalStrengthLoaded = createAction(
  '[LTE] Signal Strength loaded',
  props<{ signalStrength: ILteSignalStrength }>()
);
export const lteSignalStrengthLoadedError = createAction('[LTE] Signal Strength loaded error', props<{ error: any }>());

export const lteCurrentStateLoaded = createAction(
  '[LTE] Current state loaded',
  props<{ currentState: ILteCurrentState }>()
);
export const lteCurrentStateLoadedError = createAction('[LTE] Current state loaded error', props<{ error: any }>());

export const lteHWInfoLoaded = createAction('[LTE] HW info loaded', props<{ hwInfo: ILteHwInfo }>());
export const lteHWInfoLoadedError = createAction('[LTE] HW info loaded error', props<{ error: any }>());

export const lteNetworkConfigurationLoaded = createAction(
  '[LTE] network configuration loaded',
  props<{ networkConfiguration: ILteNetworkConfiguration }>()
);
export const lteNetworkConfigurationLoadedError = createAction(
  '[LTE] network configuration loaded error',
  props<{ error: any }>()
);

export const lteConfigurationLoaded = createAction(
  '[LTE] configuration loaded',
  props<{ configuration: ILteConfiguration }>()
);
export const lteConfigurationLoadedError = createAction('[LTE] configuration loaded error', props<{ error: any }>());

// temporal reload of data - removed when editing will be done in store instead of direct call of service.
export const lteReloadConfigAndState = createAction('[LTE] reload config and state');
