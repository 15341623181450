import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GraphCalculationsService } from '../graph-calculations/graph-calculations.service';
import { DataWithPosition, GraphDataSetComputed } from '../graph.interface';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[flm-graph-bar]',
  templateUrl: './graph-bar.component.html',
  styleUrls: ['./graph-bar.component.scss'],
})
export class GraphBarComponent {
  constructor(public calculations: GraphCalculationsService) {}
  @Input() dataSet?: GraphDataSetComputed;
  @Output() barHover = new EventEmitter<DataWithPosition | undefined>();

  isNumber(num: number): boolean {
    return !Number.isNaN(num) && Number.isFinite(num);
  }

  hover(data: DataWithPosition | undefined): void {
    this.barHover.emit(data);
  }
}
