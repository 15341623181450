<div class="modal-bg" (click)="close('overlay')" [style.zIndex]="zIndex"></div>

<div class="modal-window" [ngClass]="mode + ' ' + class" [style.zIndex]="zIndex + 1">
  <div class="modal-title">
    <ng-content select="[title]"></ng-content>
    <icon name="icon-close" (click)="close()"></icon>
  </div>
  <div class="modal-content" cdk-scrollable>
    <ng-content select="[content]"></ng-content>
  </div>
  <div class="modal-controls">
    <ng-content select="[controls]"></ng-content>
  </div>
</div>