export class Series {
  color: string;
  text: string;
  translation: string = null;

  constructor(color: any, text: any, translation?: string) {
    this.color = color;
    this.text = text;

    if (translation) {
      this.translation = translation;
    }
  }
}
