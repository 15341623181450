import { Component, OnInit, OnDestroy } from '@angular/core';
import { ModalService } from 'src/app/lib/services/modal.service';

@Component({
  selector: 'dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit, OnDestroy {
  data: any;
  subscription: any;
  show: boolean = false;

  constructor(private modal: ModalService) {}

  ngOnInit(): void {
    this.subscription = this.modal.dialog.subscribe((data: any) => {
      this.data = data;
      this.show = true;
    });
  }

  action(button: { style: string; value: string }): void {
    this.data.event.next({ item: button, options: this.data.options });
    this.data.event.complete();
    this.show = false;
    this.data = null;
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
