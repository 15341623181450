<box>
  <div header>
    {{ 'profiler.wanStats.title' | translate }}
  </div>
  <div container>
    <ng-container *ngIf="!loading">
      <div class="section-title">{{ 'profiler.wanStats.downloaded' | translate }}</div>
      <div class="group hero">
        <div class="item">
          <div class="value">
            {{ wanStats.hero24h.value + ' ' + wanStats.hero24h.unit }}
          </div>
          <div class="title">
            <div>{{ 'profiler.wanStats.download24h' | translate }}</div>
            <div>
              {{ 'profiler.wanStats.usageRating' | translate }}:
              <b [ngClass]="wanStats.consumption24h">
                {{ 'profiler.wanStats.' + wanStats.consumption24h + 'Usage' | translate }}
              </b>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="value">
            {{ wanStats.hero7d.value + ' ' + wanStats.hero7d.unit }}
          </div>
          <div class="title">
            <div>{{ 'profiler.wanStats.download7d' | translate }}</div>
            <div>
              {{ 'profiler.wanStats.usageRating' | translate }}:
              <b [ngClass]="wanStats.consumption7d">
                {{ 'profiler.wanStats.' + wanStats.consumption7d + 'Usage' | translate }}
              </b>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="value">
            {{ wanStats.hero30d.value + ' ' + wanStats.hero30d.unit }}
          </div>
          <div class="title">
            <div>{{ 'profiler.wanStats.download30d' | translate }}</div>
            <div>
              {{ 'profiler.wanStats.usageRating' | translate }}:
              <b [ngClass]="wanStats.consumption30d">
                {{ 'profiler.wanStats.' + wanStats.consumption30d + 'Usage' | translate }}
              </b>
            </div>
          </div>
        </div>
      </div>
      <div class="section-title">
        {{ 'profiler.wanStats.description1' | translate }}
        {{ 'profiler.wanStats.description2' | translate }}
        <a [routerLink]="url + '/health'">{{ 'profiler.wanStats.linkHealth' | translate }}</a
        >,
        {{ 'profiler.wanStats.description3' | translate }}
        <a [routerLink]="url + '/qoe/wan'">{{ 'profiler.wanStats.linkQoeWan' | translate }}</a
        >.
      </div>
      <div class="group divide">
        <div class="cell header">
          {{ 'profiler.wanStats.usage24h' | translate }}
        </div>
        <div
          *ngFor="let bucket of wanStats.chart24h"
          class="cell"
          [class.disabled]="bucket.percent === -1"
          [ngClass]="bucket.consumption"
        >
          <div class="value">
            <div class="percent">
              <ng-container *ngIf="bucket.percent !== -1">{{ bucket.percent | number: '1.0-2' }}%</ng-container>
              <ng-container *ngIf="bucket.percent === -1">&mdash;</ng-container>
            </div>
            <div class="amount" *ngIf="bucket.amount">{{ bucket.amount.value }} {{ bucket.amount.unit }}</div>
          </div>
          <div class="time">{{ bucket.date }}</div>
        </div>
      </div>
      <div class="group divide">
        <div class="cell header">
          {{ 'profiler.wanStats.usageWeek' | translate }}
        </div>
        <div
          *ngFor="let bucket of wanStats.chart7d"
          class="cell"
          [class.disabled]="bucket.percent === -1"
          [ngClass]="bucket.consumption"
        >
          <div class="value">
            <div class="percent">
              <ng-container *ngIf="bucket.percent !== -1">{{ bucket.percent | number: '1.0-2' }}%</ng-container>
              <ng-container *ngIf="bucket.percent === -1">&mdash;</ng-container>
            </div>
            <div class="amount" *ngIf="bucket.amount">{{ bucket.amount.value }} {{ bucket.amount.unit }}</div>
          </div>
          <div class="time">{{ bucket.date }}</div>
        </div>
      </div>
      <div class="legend">
        <div class="text">Less</div>
        <div class="square percent20"></div>
        <div class="square percent40"></div>
        <div class="square percent60"></div>
        <div class="square percent80"></div>
        <div class="square percent100"></div>
        <div class="text">More</div>
      </div>
    </ng-container>
    <div class="loading" *ngIf="loading">{{ 'loadingIndicator' | translate }}</div>
  </div>
</box>
