<div class="row" id="conf-agentintegrations" *ngIf="(samKnowsCapable$ | async) || (appQoeCapable$ | async)">
  <div class="col-xs-12">
    <collapsiblebox [show]="expand" [collapsible]="true" (toggle)="toggleExpand()">
      <div header>{{ 'configurations.agentintegrations.title' | translate }}</div>
      <div container class="main-container utilities" *ngIf="expand">
        <div class="columns">
          <div class="column">
            <div class="row" *ngIf="samKnowsCapable$ | async">
              <div class="key">{{ 'configurations.agentintegrations.samKnows' | translate }}</div>
              <div class="value">
                <div class="value">
                  <slider-toggle-mobile-aware
                    [editable]="(reloading$ | async) === false && plume.isSomeAdminRole()"
                    [options]="samKnowsOptions$ | async"
                    (toggle)="updateSamKnows($event)"
                  ></slider-toggle-mobile-aware>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="appQoeCapable$ | async">
              <div class="key">{{ 'configurations.agentintegrations.appQoe' | translate }}</div>
              <div class="value">
                <div class="value">
                  <slider-toggle-mobile-aware
                    [editable]="(reloading$ | async) === false && plume.isSomeAdminRole()"
                    [confirmChange]="confirmAppQoeChange"
                    [options]="appQoeOptions$ | async"
                    (toggle)="updateAppQoe($event)"
                  ></slider-toggle-mobile-aware>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </collapsiblebox>
  </div>
</div>
