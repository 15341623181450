import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'labeled-input',
  templateUrl: './labeled-input.component.html',
  styleUrls: ['./labeled-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LabeledInputComponent implements OnInit, OnDestroy {
  childObserver: MutationObserver;
  inputDisableObserver: MutationObserver;
  detectedState: 'info' | 'error' | 'success' = 'info';
  isPassword = false;
  showedPassword = false;
  input: HTMLInputElement;
  disabled = false;

  @Input() clearButton = false;
  @Output() cleared: EventEmitter<any> = new EventEmitter<any>();

  constructor(private elm: ElementRef<HTMLElement>, private readonly cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.childObserver = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          this.childChange();
        }
      }
    });
    this.inputDisableObserver = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.attributeName === 'disabled') {
          this.disabled = this.input.disabled;
          this.cdRef.markForCheck();
        }
      }
    });
    this.input = this.elm.nativeElement.querySelector('input');
    this.disabled = this.input?.disabled;
    this.childObserver.observe(this.elm.nativeElement, { childList: true, subtree: true });
    if (this.input) {
      this.inputDisableObserver.observe(this.input, { attributes: true });
    }
    this.childChange();
    this.isPassword = this.input?.type === 'password';
  }

  @HostListener('click') click(): void {
    this.input?.focus();
  }

  @HostListener('focusout') focusOut(): void {
    // needed to angular detect focus out
  }

  @HostBinding('class.filledUp') get filledUp(): boolean {
    return !!this.input?.value;
  }

  @HostBinding('class.error') get errorClass(): boolean {
    return this.detectedState === 'error';
  }

  @HostBinding('class.success') get successClass(): boolean {
    return this.detectedState === 'success';
  }

  @HostBinding('class.disabled') get disabledClass(): boolean {
    return this.disabled;
  }

  showPassword(): void {
    this.input.type = 'text';
    this.showedPassword = true;
  }

  hidePassword(): void {
    this.input.type = 'password';
    this.showedPassword = false;
  }

  clear(): void {
    this.input.value = '';
    this.input.dispatchEvent(new Event('input'));
    this.cleared.emit();
  }

  private childChange(): void {
    if (this.elm.nativeElement.querySelector('[error]')) {
      this.detectedState = 'error';
    } else if (this.elm.nativeElement.querySelector('[success]')) {
      this.detectedState = 'success';
    } else {
      this.detectedState = 'info';
    }
    this.cdRef.markForCheck();
  }

  ngOnDestroy(): void {
    if (this.childObserver) {
      this.childObserver.disconnect();
    }
    if (this.inputDisableObserver) {
      this.inputDisableObserver.disconnect();
    }
  }
}
