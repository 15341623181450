<ng-container *ngrxLet="previewMode$ as previewMode">
  <div class="simulated-preview" *ngIf="previewMode === 'closed'">
    <ng-container *ngrxLet="portal$ as portal">
      <div class="card" [style.color]="portal?.color">
        <div class="background-layer" 
             [style.background-image]="'url(' + portal?.background + ')'"
             [style.background-position]="portal?.backgroundRepeatAlignment"
             [style.filter]="portal?.backgroundFilter"
             [style.background-color]="portal?.background">
        </div>
        <div *ngIf="portal?.layer?.enabled" 
             class="layer-overlay"
             [style.background-color]="portal?.layer?.layerColor">
        </div>
        <div class="content-layer">
          <div class="language" *ngIf="portal?.multilingual">
            {{ portal.language.lang | translate }}
            <i class="arrow"></i>
          </div>
          <div class="logo">
            <img [src]="portal?.logo" [style.height.px]="portal?.logoSize" *ngIf="portal?.logo?.length" />
          </div>
          <div class="name">{{ portal?.name }}</div>
          <div class="options">
            <div class="navigation" *ngFor="let option of loginOptions$ | async">
              <i class="fa" [ngClass]="option.icon"></i>
              <span>{{ option.text | translate }}</span>
            </div>
          </div>
          <div class="footer">{{ portal?.footer }}</div>
        </div>
      </div>
    </ng-container>
  </div>
  <ng-container *ngrxLet="previewUrl$ as previewUrl">
    <div class="real-preview" *ngIf="previewMode !== 'closed'">
      <div class="bubble loading" *ngIf="previewMode !== 'error' && loadingPreview">
        <icon name="icon-loading"></icon>
        <div class="message">{{ 'captiveportal.creatingPreview' | translate }}</div>
      </div>
      <div class="bubble error" *ngIf="previewMode === 'error'">
        <icon name="icon-close"></icon>
        <div class="message">{{ 'captiveportal.createPreviewError' | translate }}</div>
        <div class="error">{{ previewErrorMsg$ | async }}</div>
      </div>
      <iframe
        class="preview"
        [src]="previewUrl | safe"
        (load)="doneLoading()"
        *ngIf="previewMode !== 'error' && previewUrl"
      ></iframe>
    </div>
  </ng-container>
</ng-container>
<ng-container *ngrxLet="publishMode$ as publishMode">
  <div class="publish" *ngIf="publishMode !== 'closed'">
    <div class="bubble loading" *ngIf="publishMode === 'loading'">
      <icon name="icon-loading"></icon>
      <div class="message">{{ 'captiveportal.publishing' | translate }}</div>
    </div>
    <div class="bubble error" *ngIf="publishMode === 'error'">
      <icon name="icon-close"></icon>
      <div class="message">{{ 'captiveportal.publishError' | translate }}</div>
      <button class="super-primary" (click)="closePublish()">{{ 'captiveportal.returnToEditor' | translate }}</button>
    </div>
    <div class="bubble published" *ngIf="publishMode === 'success'">
      <icon name="captive-publish"></icon>
      <h3>{{ 'captiveportal.successfullPublish' | translate }}</h3>
      <button class="super-primary" (click)="closePublish()">{{ 'captiveportal.returnToEditor' | translate }}</button>
    </div>
  </div>
</ng-container>
