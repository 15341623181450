import { createSelector } from '@ngrx/store';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { selectCapabilities } from '../customer/capabilities.selector';

export const selectBandSteeringEditable = ({ plume }: { plume: PlumeService }) =>
  createSelector(
    selectCapabilities,
    (capabilities: ReturnType<typeof selectCapabilities>) =>
      capabilities?.bandSteering?.capable &&
      (plume.isSomeAdminRole() ||
        (plume.cloudVersionAbove1_98() && (plume.isStrictGroupSupportRole() || plume.isStrictSupportRole())))
  );
