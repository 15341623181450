<ng-container *ngIf="loading">
  {{ 'qoe.loading' | translate }}
</ng-container>
<div class="contentContainer" *ngIf="!loading">
  <ng-container *ngIf="!live">
    <slidetoggler class="chartSelector" [options]="chartModes" (toggle)="toggleChartMode($event)"></slidetoggler>
    <div class="grid">
      <div>{{ 'qoe.lteGraphs.labels.rssi' | translate }}</div>
      <nodechart
        [series]="linegraphs.rssi.series"
        [data]="linegraphs.rssi.graph"
        [scale]="{ range: [-125, 0], symbol: 'dBm' }"
        [period]="chartMode === '24h' ? 1 : chartMode === '7d' ? 7 : 30"
        [isFilled]="false"
      ></nodechart>

      <div>{{ 'qoe.lteGraphs.labels.rsrp' | translate }}</div>
      <nodechart
        [series]="linegraphs.rsrp.series"
        [data]="linegraphs.rsrp.graph"
        [scale]="{ range: [-125, 0], symbol: 'dBm' }"
        [period]="chartMode === '24h' ? 1 : chartMode === '7d' ? 7 : 30"
        [isFilled]="false"
      ></nodechart>

      <div>{{ 'qoe.lteGraphs.labels.rsrq' | translate }}</div>
      <nodechart
        [series]="linegraphs.rsrq.series"
        [data]="linegraphs.rsrq.graph"
        [scale]="{ range: [-22, 0], symbol: 'dB' }"
        [period]="chartMode === '24h' ? 1 : chartMode === '7d' ? 7 : 30"
        [isFilled]="false"
      ></nodechart>

      <div>{{ 'qoe.lteGraphs.labels.sinr' | translate }}</div>
      <nodechart
        [series]="linegraphs.sinr.series"
        [data]="linegraphs.sinr.graph"
        [scale]="{ range: [-10, 30], symbol: 'dB' }"
        [period]="chartMode === '24h' ? 1 : chartMode === '7d' ? 7 : 30"
        [isFilled]="false"
      ></nodechart>

      <div>{{ 'qoe.lteGraphs.labels.signalBar' | translate }}</div>
      <nodechart
        [series]="linegraphs.signalBar.series"
        [data]="linegraphs.signalBar.graph"
        [scale]="{ range: [0, 5], symbol: '' }"
        [period]="chartMode === '24h' ? 1 : chartMode === '7d' ? 7 : 30"
        [isFilled]="false"
      ></nodechart>

      <div>{{ 'qoe.lteGraphs.labels.freqBand' | translate }}</div>
      <nodechart
        [series]="linegraphs.freqBand.series"
        [data]="linegraphs.freqBand.graph"
        [scale]="{ symbol: '' }"
        [period]="chartMode === '24h' ? 1 : chartMode === '7d' ? 7 : 30"
        [isFilled]="false"
      ></nodechart>

      <ng-container *ngIf="plume.cloudVersionAbove1_93()">
        <div>{{ 'qoe.lteGraphs.labels.lteUplinkBandwidth' | translate }}</div>
        <nodechart
          [series]="linegraphs.lteUplinkBandwidth.series"
          [data]="linegraphs.lteUplinkBandwidth.graph"
          [scale]="{ range: [0, 20], symbol: 'MHz' }"
          [period]="chartMode === '24h' ? 1 : chartMode === '7d' ? 7 : 30"
          [isFilled]="false"
        ></nodechart>

        <div>{{ 'qoe.lteGraphs.labels.lteDownlinkBandwidth' | translate }}</div>
        <nodechart
          [series]="linegraphs.lteDownlinkBandwidth.series"
          [data]="linegraphs.lteDownlinkBandwidth.graph"
          [scale]="{ range: [0, 20], symbol: 'MHz' }"
          [period]="chartMode === '24h' ? 1 : chartMode === '7d' ? 7 : 30"
          [isFilled]="false"
        ></nodechart>
      </ng-container>

      <div>{{ 'qoe.lteGraphs.labels.totalTxRxBytes' | translate }}</div>
      <nodechart
        [series]="linegraphs.totalTxRxBytes.series"
        [data]="linegraphs.totalTxRxBytes.graph"
        [scale]="{ symbol: linegraphs.totalTxRxBytes.unit }"
        [period]="chartMode === '24h' ? 1 : chartMode === '7d' ? 7 : 30"
        [isFilled]="false"
      ></nodechart>
    </div>
  </ng-container>
  <ng-container *ngIf="live">
    <ng-container *ngIf="plume.cloudVersionAbove1_93()">
      <div class="grid" *ngIf="liveGraphs.length; else noLiveDataYet">
        <ng-container *ngFor="let chart of liveGraphs; trackBy: liveGraphTrack">
          <div>{{ chart?.series[0]?.translation | translate }}</div>
          <nodechart
            [series]="chart.series"
            [data]="chart.data"
            [scale]="{
              range: [chart.minValue, chart.maxValue],
              symbol: chart.unit || 'qoe.lteGraphs.units.' + chart?.series[0]?.text | translate
            }"
            [period]="9"
            [itemCount]="(10 * 60) / superLiveInterval"
            [isFilled]="false"
          ></nodechart>
        </ng-container>
      </div>
      <ng-template #noLiveDataYet>
        <div class="no-data">{{ 'qoe.noQoeData' | translate }}</div>
      </ng-template>
    </ng-container>
    <ng-container *ngIf="!plume.cloudVersionAbove1_93()">
      {{ 'qoe.lteGraphs.liveModeNotAvailable' | translate }}
    </ng-container>
  </ng-container>
</div>
