import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MixpanelService } from './mixpanel.service';

@Injectable()
export class PlumeService {
  ui: string = '';
  theme: string = '';
  env: any = {};
  userid: string = '';
  role: string = '';
  groups: any = [];
  customerid: string = '';
  locationid: string = '';
  permissions: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  toggler: Subject<any> = new Subject<any>();
  cloudVersionCache: { [key: string]: boolean } = {};
  pausedPolling: boolean = false;
  disableNodePolling: boolean = false;

  constructor(private mixpanel: MixpanelService) {
    this.theme = this.getTheme();
    this.userid = this.getUser() ? this.getUser().userId || this.getUser().id : null;
    this.role = this.getRole() ? this.getRole() : null;
    this.env = this.getEnv();
    this.permissions.next(this.getPermissions());
    this.cacheCloudVersions();
  }

  setLocale(lang: string): void {
    moment.locale(lang);
  }

  setUser(user: any): void {
    this.saveObject('user', user);
    this.userid = user.userId || user.id;
  }

  setRole(role: string): void {
    this.saveObject('role', role, false);
    this.role = role;
  }

  setGroups(groups: any): void {
    this.saveObject('groups', groups);
    this.groups = groups;
  }

  setTTL(ttl: number): void {
    this.saveObject('ttl', ttl, false);
  }

  setEnv(env: any): void {
    this.saveObject('env', env);
    this.env = env;
    this.mixpanel.setEnvironment(env.id);
    this.cacheCloudVersions();
  }

  setTheme(theme: string): void {
    this.saveObject('theme', theme, false, true);
    this.theme = theme;
    this.mixpanel.setTheme(theme);
  }

  setPermissions(permissions: any): void {
    this.saveObject('permissions', permissions);
    this.permissions.next(permissions);
  }

  getUI(): string {
    if (window.location.hostname.indexOf('noc') >= 0) {
      return 'noc';
    } else {
      return 'central';
    }
  }

  getVersion(): string {
    return environment.version;
  }

  getTheme(): string {
    return this.getObject('theme', false, true);
  }

  getUser(): any {
    return this.getObject('user');
  }

  getRole(): string {
    return this.getObject('role', false);
  }

  getGroups(): any {
    return this.getObject('groups');
  }

  getTTL(): number {
    return this.getObject('ttl', false);
  }

  getEnv(): any {
    return this.getObject('env');
  }

  getVendor(): any {
    if (window.location.hostname.indexOf('adtran') >= 0) {
      return 'adtran';
    } else {
      if (this.env?.id === 'rho' || this.env?.id === 'rho-dev' || this.env?.id === 'rhosandbox') {
        return 'jio';
      } else {
        return 'plume';
      }
    }
  }

  getPermissions(): any {
    return this.getObject('permissions');
  }

  isPartner(): boolean {
    const user = this.getUser();
    return (
      this.getPermissions().search.partnerAPI &&
      this.isGroupRole() &&
      this.cloudVersionAbove1_91() &&
      (user.partnerId?.length || user.accessibleLeafPartnerIds?.length)
    );
  }

  isFlexRole(): boolean {
    return this.role === 'flexAdmin';
  }

  isStrictAdminRole(): boolean {
    return this.role === 'admin';
  }

  isGroupAdminRole(): boolean {
    return this.role === 'groupAdmin';
  }

  isSuperGroupAdminRole(): boolean {
    return this.role === 'superGroupAdmin';
  }

  isIntegrationRole(): boolean {
    return this.role === 'integration';
  }

  isSomeAdminRole(): boolean {
    return this.isStrictAdminRole() || this.isGroupAdminRole();
  }

  isStrictGroupSupportRole(): boolean {
    return this.role === 'groupSupport';
  }

  isSomeGroupSupportRole(): boolean {
    const groupSupport = ['groupSupport', 'groupSupportTechnician', 'groupSupportCentral'];
    return groupSupport.indexOf(this.role) >= 0;
  }

  isGroupSupportTechnician(): boolean {
    return this.role === 'groupSupportTechnician';
  }

  isGroupSupportCentral(): boolean {
    return this.role === 'groupSupportCentral';
  }

  isGroupRole(): boolean {
    const groupRoles = ['groupAdmin', 'groupSupportTechnician', 'groupSupportCentral', 'groupSupport', 'groupAdminNOC'];
    return groupRoles.indexOf(this.role) >= 0;
  }

  isStrictSupportRole(): boolean {
    return this.role === 'support';
  }

  isSomeSupportRole(): boolean {
    const roles = ['support', 'groupSupportCentral', 'groupSupport'];
    return roles.indexOf(this.role) >= 0;
  }

  isUpriseProperyManager(): boolean {
    return this.role === 'uprisePropertyManager';
  }

  hidePersonalDetails(): boolean {
    return !this.getPermissions()?.uiFeatures?.displayCustomerIdentifiers;
  }

  getStatusPageURL(envId: string): string {
    if (envId) {
      return environment.environments.find((env: any) => env.id === envId).statuspage;
    } else {
      return null;
    }
  }

  clearCustomer(): void {
    this.customerid = null;
    this.locationid = null;
    this.removeObject('customer');
    this.removeObject('location');
    this.removeObject('onboardedToast');
    this.removeObject('flexAuth');
    this.removeObject('upriseAuth');
    this.mixpanel.clearCustomer();
  }

  getObject(key: string, json: boolean = true, permanent: boolean = false): any {
    const value = (permanent ? localStorage.getItem(key) : sessionStorage.getItem(key)) || null;

    try {
      if (json) {
        return JSON.parse(value);
      } else {
        return value;
      }
    } catch (e) {
      return null;
    }
  }

  saveObject(key: string, value: any, json: boolean = true, permanent: boolean = false): void {
    value = json ? JSON.stringify(value) : value;

    if (localStorage.getItem('otl') || permanent) {
      localStorage.setItem(key, value);
    } else {
      sessionStorage.setItem(key, value);
    }
  }

  removeObject(key: string): void {
    localStorage.removeItem(key);
    sessionStorage.removeItem(key);
  }

  cloudVersionAbove1_85(): boolean {
    return this.cloudVersionCache['>1.85.0'];
  }

  cloudVersionAbove1_86(): boolean {
    return this.cloudVersionCache['>1.86.0'];
  }

  cloudVersionAbove1_87(): boolean {
    return this.cloudVersionCache['>1.87.0'];
  }

  cloudVersionAbove1_88(): boolean {
    return this.cloudVersionCache['>1.88.0'];
  }

  cloudVersionAbove1_89(): boolean {
    return this.cloudVersionCache['>1.89.0'];
  }

  cloudVersionAbove1_90(): boolean {
    return this.cloudVersionCache['>1.90.0'];
  }

  cloudVersionAbove1_91(): boolean {
    return this.cloudVersionCache['>1.91.0'];
  }

  cloudVersionAbove1_92(): boolean {
    return this.cloudVersionCache['>1.92.0'];
  }

  cloudVersionAbove1_93(): boolean {
    return this.cloudVersionCache['>1.93.0'];
  }

  cloudVersionAbove1_94(): boolean {
    return this.cloudVersionCache['>1.94.0'];
  }

  cloudVersionAbove1_97(): boolean {
    return this.cloudVersionCache['>1.97.0'];
  }

  cloudVersionAbove1_98(): boolean {
    return this.cloudVersionCache['>1.98.0'];
  }

  cloudVersionAbove1_100(): boolean {
    return this.cloudVersionCache['>1.100.0'];
  }

  cloudVersionAbove1_103(): boolean {
    return this.cloudVersionCache['>1.103.0'];
  }

  cloudVersionAbove1_105(): boolean {
    return this.cloudVersionCache['>1.105.0'];
  }

  cloudVersionAbove1_108(): boolean {
    return this.cloudVersionCache['>1.108.0'];
  }

  cloudVersionAbove1_114(): boolean {
    return this.cloudVersionCache['>1.114.0'];
  }

  cloudVersionAbove1_117(): boolean {
    return this.cloudVersionCache['>1.117.0'];
  }

  cloudVersionAbove1_118(): boolean {
    return this.cloudVersionCache['>1.118.0'];
  }

  cloudVersionAbove1_119(): boolean {
    return this.cloudVersionCache['>1.119.0'];
  }

  cloudVersionAbove1_121(): boolean {
    return this.cloudVersionCache['>1.121.0'];
  }

  cloudVersionAbove1_122(): boolean {
    return this.cloudVersionCache['>1.122.0'];
  }

  cloudVersionAbove1_124(): boolean {
    return this.cloudVersionCache['>1.124.0'];
  }

  cloudVersionAbove1_128(): boolean {
    return this.cloudVersionCache['>1.128.0'];
  }

  cloudVersionAbove1_130(): boolean {
    return this.cloudVersionCache['>1.130.0'];
  }

  cloudVersionAbove1_134(): boolean {
    return this.cloudVersionCache['>1.134.0'];
  }

  versionCompare(v1: string, v2: string, lowerThanOrEqual: boolean = false): boolean {
    const v1parts = v1 ? v1.split('.').map((str) => Number.parseInt(str, 10)) : null;
    const v2parts = v2 ? v2.split('.').map((str) => Number.parseInt(str, 10)) : null;

    if (!v1parts || !v2parts) {
      return false;
    }

    for (let i = 0; i < v1parts.length; i++) {
      if (v2parts.length === i) {
        return true;
      }

      if (v1parts[i] === v2parts[i]) {
        continue;
      } else if ((v1parts[i] > v2parts[i] && !lowerThanOrEqual) || (v1parts[i] < v2parts[i] && lowerThanOrEqual)) {
        return true;
      } else {
        return false;
      }
    }

    if (v1parts.length !== v2parts.length) {
      return false;
    }

    return true;
  }

  private cacheCloudVersions(): void {
    if (!this.getEnv()) {
      return;
    }
    const envVersion = this.getEnv().version;

    this.cloudVersionCache = Object.getOwnPropertyNames(Object.getPrototypeOf(this))
      .filter((name) => name.startsWith('cloudVersionAbove') || name.startsWith('cloudVersionBelow'))
      .map((name) => name.replace('cloudVersionAbove', '>').replace('cloudVersionBelow', '<').replace('_', '.'))
      .map((version) => (version.match(/\./g).length === 2 ? version : version + '.0'))
      .reduce(
        (acc, version) => ({
          ...acc,
          [version]: this.versionCompare(envVersion, version.substring(1), version.startsWith('<'))
        }),
        {} as { [key: string]: boolean }
      );
  }
}
