import { Component, OnInit, OnDestroy, ViewChild, HostBinding } from '@angular/core';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { Node } from 'src/app/lib/d3/models/objects/node';
import { Link } from 'src/app/lib/d3/models/objects/link';
import { ForceChartVisualComponent } from 'src/app/lib/d3/visuals/charts/forcechart/forcechart.component';
import { QoeService } from 'src/app/lib/services/qoe.service';
import { ApiService } from 'src/app/lib/services/api.service';
import { GeneralHelper } from 'src/app/lib/helpers/general.helper';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { selectPipeLocationOnChange } from 'src/app/store/customer/customer.selectors';
import { DeepReadonly, ILocation } from 'src/app/lib/interfaces/interface';
import { AuthService } from 'src/app/lib/services/auth.service';
import { SpeedTestService } from 'src/app/lib/services/speed-test.service';
import {
  selectDevices,
  selectLocationInternet,
  selectLocationQoE,
  selectLocationTopology,
  selectNodes
} from 'src/app/store/polling/polling.selector';
import { SpeedConverterService } from 'src/app/lib/services/speed-converter.service';
import { TranslateService } from '@ngx-translate/core';

@UntilDestroy()
@Component({
  templateUrl: './topology.component.html',
  styleUrls: ['./topology.component.scss']
})
export class TopologyComponent implements OnInit, OnDestroy {
  subscriptions: any[] = [];
  debouncerTimeout: any;
  permissions: any;

  onboarded: string = null;
  loading: boolean = true;
  nodes: Node[] = [];
  links: Link[] = [];
  rawNodes: any[] = [];
  rawDevices: any[] = [];
  rawNodesQoe: any[] = [];
  rawDevicesQoe: any[] = [];
  vertices: any[] = [];
  edges: any[] = [];

  locationChange: number = 0;
  updateQoe: boolean = false;
  checkQoe: number = 0;
  updateTopology: boolean = false;
  checkTopology: number = 0;
  ui: string = '';
  helper: GeneralHelper = new GeneralHelper();
  location: DeepReadonly<ILocation> = {} as any;
  vpn: any = null;
  location$ = this.store.pipe(selectPipeLocationOnChange);

  @ViewChild(ForceChartVisualComponent) chart: ForceChartVisualComponent;

  @HostBinding('class.enabled') enabled: boolean = true;

  constructor(
    private plume: PlumeService,
    private qoe: QoeService,
    private api: ApiService,
    private auth: AuthService,
    private mixpanel: MixpanelService,
    private store: Store,
    private speedTestService: SpeedTestService,
    private SpeedConverter: SpeedConverterService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.mixpanel.storeEvent('TOPOLOGY_SCREEN');
    this.ui = this.plume.getUI();

    this.subscriptions.push(
      this.store.select(selectNodes).subscribe((nodes) => {
        this.rawNodes = nodes;
        this.debounce(() => this.updateData(), 500);
      })
    );

    this.subscriptions.push(
      this.store.select(selectDevices).subscribe((devices) => {
        this.rawDevices = devices;
        this.debounce(() => this.updateData(), 500);
      })
    );

    this.subscriptions.push(
      this.store.select(selectLocationQoE).subscribe((response: any) => {
        if (response && response.nodes) {
          this.rawNodesQoe = response.nodes;
        }

        if (response && response.devices) {
          this.rawDevicesQoe = response.devices;
        }

        this.updateQoe = true;
        this.debounce(() => this.updateData(), 500);
      })
    );

    this.subscriptions.push(
      this.store.select(selectLocationTopology).subscribe((response) => {
        if (response) {
          response = JSON.parse(JSON.stringify(response));
          this.vertices = response.vertices;
          this.edges = response.edges;
          this.updateTopology = true;
          this.debounce(() => this.updateData(), 500);
        }
      })
    );

    this.subscriptions.push(
      this.plume.permissions.subscribe((data) => {
        this.permissions = data;
      })
    );

    this.subscriptions.push(
      this.store.select(selectLocationInternet).subscribe((response) => {
        if (response) {
          this.isOnboarded(response);
        }
      })
    );

    this.location$
      .pipe(
        filter((location) => !!location),
        untilDestroyed(this)
      )
      .subscribe((location) => {
        this.location = location;
        this.debounce(() => this.updateData(), 500);
      });
  }

  updateData(): void {
    this.updateNodes();
    this.updateLinks();
    this.get24hUsageData();
    this.getNodeAverageSpeed();
    this.initVPN();

    if (this.updateQoe) {
      this.updateQoe = false;
      this.checkQoe = Date.now();
    }

    if (this.updateTopology) {
      this.loading = false;
      this.updateTopology = false;
      this.checkTopology = Date.now();
    }
  }

  debounce(fn: () => void, delay: number): void {
    clearTimeout(this.debouncerTimeout);
    this.debouncerTimeout = setTimeout(fn, delay);
  }

  initVPN(): void {
    if (this.location.flex) {
      const convert = (currentUsage: number) =>
        this.translate.instant('units.toSpeed', {
          baseValue: this.SpeedConverter.formatValue(this.SpeedConverter.convertToOptimalUnit(currentUsage, 'b'))
        });

      const env = this.plume.getEnv();
      this.api
        .raw(
          'GET',
          env.flexUrl + '/v1/locations/' + this.location.id + '/summary',
          {},
          { headers: this.auth.getFrontlineHeader() }
        )
        .subscribe(
          (response: any) => {
            response.internet.speed = convert(response.internet.downloadSpeed);
            response.ipsec.speed = convert(response.ipsec.downloadSpeed);

            this.vpn = response;
          },
          () => {
            this.vpn = null;
          }
        );
    } else {
      this.vpn = null;
    }
  }

  get24hUsageData(): void {
    const macs = [];

    this.nodes.forEach((node: Node) => {
      if (node.options.type === 'device') {
        const edge = this.edges.find((e: Link) => e.target === node.id) || null;
        let add = true;

        if (node.options.hasOwnProperty('usage24')) {
          add = false;
        }

        if (edge && edge.medium === 'ethernet') {
          add = false;
        }

        if (add) {
          macs.push(node.id);
        }
      }
    });

    if (macs.length) {
      const isFlexAdmin = this.plume.isFlexRole();
      const request = {
        url:
          '/Customers/' +
          this.plume.customerid +
          '/locations/' +
          this.plume.locationid +
          '/dashboard?macs=' +
          JSON.stringify(macs),
        endpoint: 'reports'
      };

      if (isFlexAdmin) {
        request.url =
          '/Customers/' +
          this.plume.customerid +
          '/locations/' +
          this.plume.locationid +
          '/flex/dashboard?macs=' +
          JSON.stringify(macs);
        request.endpoint = 'api';
      }

      this.api.get(request.url, request.endpoint).subscribe((response: any) => {
        if (response && response.daily && response.daily.mostActive) {
          response.daily.mostActive.forEach((usage: any) => {
            const node = this.nodes.find((n: Node) => n.id === usage.mac);
            node.options.usage24 = usage.download;
          });

          this.checkQoe = Date.now();
        }
      });
    }
  }

  private getNodeAverageSpeed(): void {
    this.speedTestService.getBroadBandEfficiencyAlert$().subscribe((alert) => {
      this.nodes.forEach((node: Node) => {
        const alertNode = alert.nodes.find((n) => n.nodeId === node.id);
        node.options.averageSpeedTest = node.options.isGateway
          ? alertNode?.broadbandDownloadSpeed?.average
          : alertNode?.wifiDownloadSpeed?.average;
      });
    });
  }

  isOnboarded(response: any): void {
    try {
      const permissions = this.plume.getPermissions();

      if (permissions.uiFeatures.overrideOnboarded) {
        this.onboarded = 'complete';
        this.enabled = true;
      } else {
        if (['OnboardingComplete', 'PodsAdded'].includes(response.summary.onboardingStatus)) {
          this.onboarded = 'complete';
          this.enabled = true;
        } else {
          this.onboarded = 'uncomplete';
          if (this.ui === 'central') {
            this.enabled = false;
          }
        }
      }
    } catch (err) {
      this.onboarded = 'uncomplete';
      this.enabled = false;
    }
  }

  updateNodes(): void {
    this.vertices.forEach((vertice: any) => {
      let count = 0;

      this.nodes.forEach((node: Node) => {
        if (vertice.id === node.id) {
          node.options = this.prepare(vertice);
        } else {
          count = count + 1;
        }
      });

      if (this.nodes.length === count) {
        this.nodes = [...this.nodes, new Node(vertice.id, this.prepare(vertice))];
      }
    });

    if (this.nodes.length > this.vertices.length) {
      this.nodes = [
        ...this.nodes.filter((node: Node) => {
          let found = false;

          this.vertices.forEach((vertice: any) => {
            if (node.id === vertice.id) {
              found = true;
            }
          });

          if (found) {
            return true;
          } else {
            return false;
          }
        })
      ];
    }
  }

  updateLinks(): void {
    this.edges.forEach((edge: any) => {
      let count = 0;

      this.links.forEach((link: Link) => {
        if (edge.source === link.source.id && edge.target === link.target.id) {
          link.options = edge;
        } else {
          count = count + 1;
        }
      });

      if (this.links.length === count) {
        this.links = [
          ...this.links,
          new Link(
            this.nodes.find((node: Node) => node.id === edge.source),
            this.nodes.find((node: Node) => node.id === edge.target),
            edge
          )
        ];
      }
    });

    if (this.links.length > this.edges.length) {
      this.links = [
        ...this.links.filter((link: Link) => {
          let found = false;

          this.edges.forEach((edge: any) => {
            if (link.source.id === edge.source && link.target.id === edge.target) {
              found = true;
            }
          });

          if (found) {
            return true;
          } else {
            return false;
          }
        })
      ];
    }
  }

  prepare(node: any): any {
    let previous: Node = null;
    let foundNode: any = {};
    let qoe: any = {};

    if (node.type === 'pod') {
      previous = this.nodes.find((d: Node) => d.id === node.id);
      foundNode = this.rawNodes.find((n: any) => n.id === node.id);
      qoe = this.rawNodesQoe.find((n: any) => n.id === node.id);
      node.isGateway = this.helper.isGateway(node.id, this.rawNodes || []);
      node.radios = this.prepareRadios(node);
    } else {
      previous = this.nodes.find((d: Node) => d.id === node.id);
      foundNode = this.rawDevices.find((d: any) => d.mac === node.id);
      qoe = this.rawDevicesQoe.find((d: any) => d.mac === node.id);
    }

    if (foundNode) {
      if (node.type === 'pod') {
        node.label = foundNode.nickname || foundNode.defaultName;
        node.averageSpeedTest = previous?.options?.averageSpeedTest;
      } else {
        node.label = foundNode.nickname || foundNode.name;
        node.category = foundNode.category || 'unknown';
        node.ip = foundNode.ip;
        node.networkId = foundNode.networkId;

        if (node.metadata.iconV2 === 'unknown' && foundNode.kind?.type?.iconV2 !== 'unknown') {
          this.mixpanel.storeEvent('BUG_UNKNOWN_ICON');
          node.metadata.iconV2 = foundNode.kind?.type?.iconV2 || foundNode.iconV2;
        }
      }

      if (foundNode.capabilities && foundNode.freqBand) {
        node.capable5g = foundNode.capabilities.radio50 && foundNode.freqBand === '2.4G' && !foundNode.isMlo;
      }
    }

    if (previous) {
      if (previous.options.usage24) {
        node.usage24 = previous.options.usage24;
      }
    }

    node.qoe = this.qoe.prepare(qoe);
    node.mloQoe = qoe?.mlo ? this.qoe.prepare(qoe.mlo) : null;

    return node;
  }

  prepareRadios(node: any): any[] {
    const radios = [];

    if (node.metadata) {
      if (node.metadata.radio24) {
        radios.push({
          mode: '2g',
          channel: node.metadata.radio24.channel,
          hasClientNetwork: this.helper.hasClientNetwork(node.metadata.radio24.vaps)
        });
      }

      if (node.metadata.radio50) {
        radios.push({
          mode: '5g',
          channel: node.metadata.radio50.channel,
          hasClientNetwork: this.helper.hasClientNetwork(node.metadata.radio50.vaps)
        });
      }

      if (node.metadata.radio50L) {
        radios.push({
          mode: '5gl',
          channel: node.metadata.radio50L.channel,
          hasClientNetwork: this.helper.hasClientNetwork(node.metadata.radio50L.vaps)
        });
      }

      if (node.metadata.radio50U) {
        radios.push({
          mode: '5gu',
          channel: node.metadata.radio50U.channel,
          hasClientNetwork: this.helper.hasClientNetwork(node.metadata.radio50U.vaps)
        });
      }
      if (node.metadata.radio60) {
        radios.push({
          mode: '6g',
          channel: node.metadata.radio60.channel,
          hasClientNetwork: this.helper.hasClientNetwork(node.metadata.radio60.vaps)
        });
      }
    }

    return radios;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: any) => subscription.unsubscribe());

    if (this.debouncerTimeout) {
      clearTimeout(this.debouncerTimeout);
    }
  }
}
