import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PlumeService } from 'src/app/lib/services/plume.service';

@Injectable()
export class ApiService {
  constructor(private http: HttpClient, private handler: HttpBackend, private plume: PlumeService) {}

  get(url: string, endpoint: string = 'api', options: any = {}): Observable<any> {
    return this.http.get(this.apiURL(endpoint) + url, options);
  }

  post(url: string, data: any, endpoint: string = 'api', options: any = {}): Observable<any> {
    return this.http.post(this.apiURL(endpoint) + url, data, options);
  }

  put(url: string, data: any, endpoint: string = 'api', options: any = {}): Observable<any> {
    return this.http.put(this.apiURL(endpoint) + url, data, options);
  }

  patch(url: string, data: any, endpoint: string = 'api', options: any = {}): Observable<any> {
    return this.http.patch(this.apiURL(endpoint) + url, data, options);
  }

  delete(url: string, endpoint: string = 'api', options: any = {}): Observable<any> {
    return this.http.delete(this.apiURL(endpoint) + url, options);
  }

  raw(type: string, url: string, data: any = {}, options: any = {}): Observable<any> {
    const http = new HttpClient(this.handler);

    options = {
      responseType: 'json',
      ...options
    };

    switch (type.toUpperCase()) {
      case 'GET':
        return http.get(url, options);
      case 'POST':
        return http.post(url, data, options);
      case 'PUT':
        return http.put(url, data, options);
      case 'PATCH':
        return http.patch(url, data, options);
      case 'DELETE':
        return http.delete(url, options);
      default:
        return null;
    }
  }

  hostname(): string {
    return this.plume.getEnv() ? this.plume.getEnv().hostname : '';
  }

  apiURL(endpoint: string = 'api'): string {
    return this.hostname() + environment.server.endpoint[endpoint];
  }
}
