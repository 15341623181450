<ng-container *ngIf="totalValue">
  <div
    class="block"
    *ngFor="let block of renderData; let index = index"
    [style.height.%]="block.percent"
    [style.background]="block.color"
    [class.selected]="emptyDataVisible ? index === emptyDataBiggestBlockIndex : index === selectedItemIndex"
    [class.emptyData]="emptyDataVisible"
    (click)="blockSelected(index)"
  ></div>
</ng-container>
