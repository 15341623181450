import { Component, OnInit, OnDestroy, Input, OnChanges } from '@angular/core';
import { Line } from 'src/app/lib/d3/models/objects/line';
import { Series } from 'src/app/lib/d3/models/objects/series';
import { Point } from 'src/app/lib/d3/models/objects/point';
import { NodeService } from 'src/app/lib/services/nodes.service';
import { ProfilerService } from 'src/app/lib/services/profiler.service';
import * as moment from 'moment';

@Component({
  selector: 'qoewan',
  templateUrl: './qoewan.component.html',
  styleUrls: ['./qoewan.component.scss']
})
export class QoeWANComponent implements OnInit, OnChanges, OnDestroy {
  debouncerTimeout: any;
  liveTimeout: any;
  location: any = null;

  loading: boolean = false;
  period: number = 1;
  wan: any = null;
  chartModes: any[] = [];
  hideStrips: string[] = [];

  @Input()
  live: any;

  constructor(private nodeService: NodeService, private profiler: ProfilerService) {}

  ngOnInit(): void {
    this.initModeToggler();
  }

  ngOnChanges(): void {
    this.loading = true;

    if (this.live) {
      this.period = 10;
    } else {
      this.period = 1;
    }

    this.initModeToggler();
    this.getWAN();
  }

  debounce(fn: () => void, delay: number): void {
    clearTimeout(this.debouncerTimeout);
    this.debouncerTimeout = setTimeout(fn, delay);
  }

  track(index: number, chart: any): string {
    return chart?.data?.series?.text || '';
  }

  initModeToggler(): void {
    if (this.period !== 10) {
      this.chartModes = [
        { value: 1, translation: '24h', selected: this.period === 1 ? true : false },
        { value: 7, translation: '7d', selected: this.period === 7 ? true : false },
        { value: 30, translation: '30d', selected: this.period === 30 ? true : false }
      ];
    }
  }

  getWAN(): void {
    clearTimeout(this.liveTimeout);

    if (this.live) {
      this.getLiveWAN();
    } else {
      this.profiler.wanStats$(this.period).subscribe((response: any) => {
        const modes = { 1: 'fifteenMins', 7: 'hours', 30: 'days' };
        this.calculateWAN(response[modes[this.period]]);
        this.loading = false;
      });
    }
  }

  getLiveWAN(): void {
    const startTime = moment().subtract(12, 'minutes').toISOString();
    this.nodeService.wanStatsLiveModeStream$(startTime).subscribe((response: any) => {
      if (response.length) {
        this.calculateWAN(response[0].samples);
      } else {
        this.wan = [];
      }

      this.loading = false;

      this.liveTimeout = setTimeout(() => this.getLiveWAN(), 60 * 1000);
    });
  }

  calculateWAN(data: any[]): void {
    const points = {};
    const charts = [];
    let noData = true;

    if (data) {
      data.forEach((point: any) => {
        Object.keys(point).forEach((prop: string) => {
          if (prop !== 'timestamp') {
            if (!points[prop]) {
              points[prop] = {
                data: [],
                maxValue: 1
              };
            }

            if (point[prop] !== null) {
              noData = false;
            }

            points[prop].data.push(new Point(new Date(point.timestamp).toString(), point[prop]));
            points[prop].maxValue = point[prop] > points[prop].maxValue ? point[prop] : points[prop].maxValue;
          }
        });
      });

      Object.keys(points).forEach((prop: string) => {
        charts.push({
          data: new Line(new Series('rgb(0, 156, 223)', prop, prop), 'left', points[prop].data),
          maxValue: points[prop].maxValue,
          period: this.period
        });
      });
    }
    if (noData) {
      this.wan = [];
    } else {
      this.wan = charts.reverse();
    }
  }

  isShowChart(id: string): boolean {
    return !this.hideStrips.includes(id);
  }

  hideGraph(id: string): void {
    if (this.hideStrips.includes(id)) {
      this.hideStrips = this.hideStrips.filter((sid) => sid !== id);
    } else {
      this.hideStrips.push(id);
    }
  }

  toggleChartMode(event: any): void {
    this.period = event;
    this.initModeToggler();
    this.getWAN();
  }

  ngOnDestroy(): void {
    clearTimeout(this.debouncerTimeout);
    clearTimeout(this.liveTimeout);
  }
}
