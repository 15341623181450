import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { IPv6Service } from 'src/app/lib/services/ipv6.service';
import { ToastService } from 'src/app/lib/services/toast.service';
import {
  IPv6AddressingConfigSet,
  IPv6AddressingConfigSetError,
  IPv6AddressingConfigSetFinished,
  IPv6ModeSet,
  IPv6ModeSetError,
  IPv6ModeSetFinished,
  IPv6PartialStateSet,
  IPv6PartialStateSetError,
  IPv6PartialStateSetFinished
} from './ipv6.actions';

@Injectable()
export class IPv6Effects {
  setMode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IPv6ModeSet),
      switchMap(({ mode }) =>
        this.ipv6Service.setMode$(mode).pipe(
          map((ipv6) => IPv6ModeSetFinished({ ipv6 })),
          catchError((error) => {
            this.toast.error(error.error.error.message, error.error.error.name);
            return of(IPv6ModeSetError({ error }));
          })
        )
      )
    )
  );

  setAddressingConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IPv6AddressingConfigSet),
      switchMap(({ addressingConfig }) =>
        this.ipv6Service.setAddressingConfig$(addressingConfig).pipe(
          map((ipv6) => IPv6AddressingConfigSetFinished({ ipv6 })),
          catchError((error) => {
            this.toast.error(error.error.error.message, error.error.error.name);
            return of(IPv6AddressingConfigSetError({ error }));
          })
        )
      )
    )
  );

  setPartialState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IPv6PartialStateSet),
      switchMap(({ partialState }) =>
        this.ipv6Service.setPartialState$(partialState).pipe(
          map((ipv6) => IPv6PartialStateSetFinished({ ipv6 })),
          catchError((error) => {
            this.toast.error(error.error.error.message, error.error.error.name);
            return of(IPv6PartialStateSetError({ error }));
          })
        )
      )
    )
  );

  constructor(private actions$: Actions, private toast: ToastService, private ipv6Service: IPv6Service) {}
}
