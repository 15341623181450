import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import {
  dppSetFinished,
  configOptimizationsAutoModeEnable,
  profileSetFinished,
  configOptimizationsZeroWaitDfsModeSetFinished,
  configOptimizationsZeroWaitDfsModeSetError,
  configOptimizationsPrefer160MhzSetFinished,
  configOptimizationsPrefer160MhzSetError,
  configOptimizationsHopPenaltyModeSetFinished,
  configOptimizationsHopPenaltyModeSetError,
  configOptimizationsPreCACSchedulerSetFinished,
  configOptimizationsPreCACSchedulerSetError,
  configPaSAppTimeEnableSetFinished,
  configPaSAppTimeEnableSetError,
  configPaSPreferredIntelligenceSetFinished,
  configPaSPreferredIntelligenceSetError,
  configPaSIpThreatIntelligenceSetFinished,
  configPaSIpThreatIntelligenceSetError,
  configPaSWcHealthTelemetrySetFinished,
  configPaSWcHealthTelemetrySetError,
  configPaSWcHeroTelemetrySetError,
  configPaSWcHeroTelemetrySetFinished,
  configPaSIpThreatProtectSetFinished,
  configPaSIpThreatProtectSetError,
  configPaSInlineDpiSetError,
  configPaSInlineDpiSetFinished,
  configPaSDosProtectionActionSetError,
  configPaSDosProtectionActionSetFinished,
  configPaSDosProtectionSetError,
  configPaSDosProtectionSetFinished,
  configPaSDpiContentFilteringSetError,
  configPaSDpiContentFilteringSetFinished,
  configPaSFlowStatsSetError,
  configPaSFlowStatsSetFinished,
  configOptimizationsMax24GhzChannelWidthSetFinished,
  configOptimizationsMax24GhzChannelWidthSetError,
  configOptimizationsMax5GhzChannelWidthSetFinished,
  configOptimizationsMax5GhzChannelWidthSetError,
  configOptimizationsMax6GhzChannelWidthSetFinished,
  configOptimizationsMax6GhzChannelWidthSetError
} from '../configview/config.actions';
import { customerLoaded } from '../customer/customer.actions';

@Injectable()
export class MixpanelEffects {
  profileSetFinished$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(profileSetFinished),
        tap(({ profile }) => {
          this.mixpanel.storeEvent('CONFIGURATION_LOCATION_PATCH', { COMMAND: 'profile', ACTION: profile });
        })
      ),
    { dispatch: false }
  );

  dppSetFinished$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(dppSetFinished),
        tap(({ dpp }) => {
          this.mixpanel.storeEvent('CONFIGURATION_DPP_PATCH', { COMMAND: 'dpp', ACTION: dpp });
        })
      ),
    { dispatch: false }
  );

  optimizationsAutoModeEnable$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(configOptimizationsAutoModeEnable),
        tap(({ value }) => {
          this.mixpanel.storeEvent('OPTIMIZER_CONFIG_AUTO_OPTIMIZE', { OPTIMIZEMODE: value });
        })
      ),
    { dispatch: false }
  );

  configOptimizationsZeroWaitDfsModeSetFinished$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(configOptimizationsZeroWaitDfsModeSetFinished),
        tap(({ value }) => {
          this.mixpanel.storeEvent('OPTIMIZER_CONFIG_ZERO_WAIT_DFS_MODE', { ZERO_WAIT_DFSMODE: value });
        })
      ),
    { dispatch: false }
  );

  configOptimizationsZeroWaitDfsModeSetError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(configOptimizationsZeroWaitDfsModeSetError),
        tap(() => {
          this.mixpanel.storeEvent('OPTIMIZER_CONFIG_ZERO_WAIT_DFS_MODE_ERROR');
        })
      ),
    { dispatch: false }
  );

  configOptimizationsPrefer160MhzSetFinished$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(configOptimizationsPrefer160MhzSetFinished),
        tap(({ value }) => {
          this.mixpanel.storeEvent('OPTIMIZER_PREFER_160MHZ_MODE', { PREFER160MHZMODE: value });
        })
      ),
    { dispatch: false }
  );

  configOptimizationsPrefer160MhzSetError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(configOptimizationsPrefer160MhzSetError),
        tap(() => {
          this.mixpanel.storeEvent('OPTIMIZER_PREFER_160MHZ_MODE_ERROR');
        })
      ),
    { dispatch: false }
  );

  configOptimizationsMax24GhzChannelWidthSetFinished$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(configOptimizationsMax24GhzChannelWidthSetFinished),
        tap(({ value }) => {
          this.mixpanel.storeEvent('OPTIMIZER_MAX_24GHZ_CHANNEL_WIDTH', {
            MAXBANDWIDTH: { MAX24GHZCHANNELWIDTH: value }
          });
        })
      ),
    { dispatch: false }
  );

  configOptimizationsMax24GhzChannelWidthSetError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(configOptimizationsMax24GhzChannelWidthSetError),
        tap(() => {
          this.mixpanel.storeEvent('OPTIMIZER_MAX_24GHZ_CHANNEL_WIDTH_ERROR');
        })
      ),
    { dispatch: false }
  );

  configOptimizationsMax5GhzChannelWidthSetFinished$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(configOptimizationsMax5GhzChannelWidthSetFinished),
        tap(({ value }) => {
          this.mixpanel.storeEvent('OPTIMIZER_MAX_5GHZ_CHANNEL_WIDTH', {
            MAXBANDWIDTH: { MAX5GHZCHANNELWIDTH: value }
          });
        })
      ),
    { dispatch: false }
  );

  configOptimizationsMax5GhzChannelWidthSetError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(configOptimizationsMax5GhzChannelWidthSetError),
        tap(() => {
          this.mixpanel.storeEvent('OPTIMIZER_MAX_5GHZ_CHANNEL_WIDTH_ERROR');
        })
      ),
    { dispatch: false }
  );

  configOptimizationsMax6GhzChannelWidthSetFinished$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(configOptimizationsMax6GhzChannelWidthSetFinished),
        tap(({ value }) => {
          this.mixpanel.storeEvent('OPTIMIZER_MAX_6GHZ_CHANNEL_WIDTH', {
            MAXBANDWIDTH: { MAX6GHZCHANNELWIDTH: value }
          });
        })
      ),
    { dispatch: false }
  );

  configOptimizationsMax6GhzChannelWidthSetError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(configOptimizationsMax6GhzChannelWidthSetError),
        tap(() => {
          this.mixpanel.storeEvent('OPTIMIZER_MAX_6GHZ_CHANNEL_WIDTH_ERROR');
        })
      ),
    { dispatch: false }
  );

  configOptimizationsHopPenaltyModeSetFinished$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(configOptimizationsHopPenaltyModeSetFinished),
        tap(({ value }) => {
          this.mixpanel.storeEvent('OPTIMIZER_HOP_PENALTY_MODE', { MODE: value });
        })
      ),
    { dispatch: false }
  );

  configOptimizationsHopPenaltyModeSetError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(configOptimizationsHopPenaltyModeSetError),
        tap(() => {
          this.mixpanel.storeEvent('OPTIMIZER_HOP_PENALTY_MODE_ERROR');
        })
      ),
    { dispatch: false }
  );

  configOptimizationsPreCACSchedulerSetFinished$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(configOptimizationsPreCACSchedulerSetFinished),
        tap(({ value }) => {
          this.mixpanel.storeEvent('OPTIMIZER_DFS_PRE_CAC_SCHEDULER_PENALTY_MODE', { MODE: value });
        })
      ),
    { dispatch: false }
  );

  configOptimizationsPreCACSchedulerSetError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(configOptimizationsPreCACSchedulerSetError),
        tap(() => {
          this.mixpanel.storeEvent('OPTIMIZER_DFS_PRE_CAC_SCHEDULER_PENALTY_MODE_ERROR');
        })
      ),
    { dispatch: false }
  );

  configPaSAppTimeEnableSetFinished$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(configPaSAppTimeEnableSetFinished),
        tap(() => {
          this.mixpanel.storeEvent('CONFIGURATION_PRIVACY_AND_SECURITY_APPTIME');
        })
      ),
    { dispatch: false }
  );

  configPaSAppTimeEnableSetError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(configPaSAppTimeEnableSetError),
        tap(() => {
          this.mixpanel.storeEvent('CONFIGURATION_PRIVACY_AND_SECURITY_APPTIME_ERROR');
        })
      ),
    { dispatch: false }
  );

  configPaSPreferredIntelligenceSetFinished$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(configPaSPreferredIntelligenceSetFinished),
        tap(() => {
          this.mixpanel.storeEvent('CONFIGURATION_PRIVACY_AND_SECURITY_PREFERRED_INTELLIGENCE');
        })
      ),
    { dispatch: false }
  );

  configPaSPreferredIntelligenceSetError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(configPaSPreferredIntelligenceSetError),
        tap(() => {
          this.mixpanel.storeEvent('CONFIGURATION_PRIVACY_AND_SECURITY_PREFERRED_INTELLIGENCE_ERROR');
        })
      ),
    { dispatch: false }
  );

  configPaSIpThreatIntelligenceSetFinished$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(configPaSIpThreatIntelligenceSetFinished),
        tap(() => {
          this.mixpanel.storeEvent('CONFIGURATION_PRIVACY_AND_SECURITY_INTELLIGENCE');
        })
      ),
    { dispatch: false }
  );

  configPaSIpThreatIntelligenceSetError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(configPaSIpThreatIntelligenceSetError),
        tap(() => {
          this.mixpanel.storeEvent('CONFIGURATION_PRIVACY_AND_SECURITY_INTELLIGENCE_ERROR');
        })
      ),
    { dispatch: false }
  );

  configPaSWcHealthTelemetrySetFinished$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(configPaSWcHealthTelemetrySetFinished),
        tap(() => {
          this.mixpanel.storeEvent('CONFIGURATION_PRIVACY_AND_SECURITY_WC_HEALTH_TELEMETRY');
        })
      ),
    { dispatch: false }
  );

  configPaSWcHealthTelemetrySetError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(configPaSWcHealthTelemetrySetError),
        tap(() => {
          this.mixpanel.storeEvent('CONFIGURATION_PRIVACY_AND_SECURITY_WC_HEALTH_TELEMETRY_ERROR');
        })
      ),
    { dispatch: false }
  );

  configPaSWcHeroTelemetrySetFinished$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(configPaSWcHeroTelemetrySetFinished),
        tap(() => {
          this.mixpanel.storeEvent('CONFIGURATION_PRIVACY_AND_SECURITY_WC_HERO_TELEMETRY');
        })
      ),
    { dispatch: false }
  );

  configPaSWcHeroTelemetrySetError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(configPaSWcHeroTelemetrySetError),
        tap(() => {
          this.mixpanel.storeEvent('CONFIGURATION_PRIVACY_AND_SECURITY_WC_HERO_TELEMETRY_ERROR');
        })
      ),
    { dispatch: false }
  );

  configPaSIpThreatProtectSetFinished$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(configPaSIpThreatProtectSetFinished),
        tap(() => {
          this.mixpanel.storeEvent('CONFIGURATION_PRIVACY_AND_SECURITY_IP_THREAT_PROTECT');
        })
      ),
    { dispatch: false }
  );

  configPaSIpThreatProtectSetError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(configPaSIpThreatProtectSetError),
        tap(() => {
          this.mixpanel.storeEvent('CONFIGURATION_PRIVACY_AND_SECURITY_IP_THREAT_PROTECT_ERROR');
        })
      ),
    { dispatch: false }
  );

  configPaSInlineDpiSetFinished$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(configPaSInlineDpiSetFinished),
        tap(() => {
          this.mixpanel.storeEvent('CONFIGURATION_PRIVACY_AND_SECURITY_INLINE_DPI');
        })
      ),
    { dispatch: false }
  );

  configPaSInlineDpiSetError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(configPaSInlineDpiSetError),
        tap(() => {
          this.mixpanel.storeEvent('CONFIGURATION_PRIVACY_AND_SECURITY_INLINE_DPI_ERROR');
        })
      ),
    { dispatch: false }
  );

  configPaSDosProtectionSetFinished$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(configPaSDosProtectionSetFinished),
        tap(() => {
          this.mixpanel.storeEvent('CONFIGURATION_PRIVACY_AND_SECURITY_DOS_PROTECTION');
        })
      ),
    { dispatch: false }
  );

  configPaSDosProtectionSetError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(configPaSDosProtectionSetError),
        tap(() => {
          this.mixpanel.storeEvent('CONFIGURATION_PRIVACY_AND_SECURITY_DOS_PROTECTION_ERROR');
        })
      ),
    { dispatch: false }
  );

  configPaSDosProtectionActionSetFinished$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(configPaSDosProtectionActionSetFinished),
        tap(() => {
          this.mixpanel.storeEvent('CONFIGURATION_PRIVACY_AND_SECURITY_DOS_PROTECTION_ACTION');
        })
      ),
    { dispatch: false }
  );

  configPaSDosProtectionActionSetError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(configPaSDosProtectionActionSetError),
        tap(() => {
          this.mixpanel.storeEvent('CONFIGURATION_PRIVACY_AND_SECURITY_DOS_PROTECTION_ACTION_ERROR');
        })
      ),
    { dispatch: false }
  );

  configPaSDpiContentFilteringSetFinished$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(configPaSDpiContentFilteringSetFinished),
        tap(() => {
          this.mixpanel.storeEvent('CONFIGURATION_PRIVACY_AND_SECURITY_DPI_CONTENT_FILTERING');
        })
      ),
    { dispatch: false }
  );

  configPaSDpiContentFilteringSetError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(configPaSDpiContentFilteringSetError),
        tap(() => {
          this.mixpanel.storeEvent('CONFIGURATION_PRIVACY_AND_SECURITY_DPI_CONTENT_FILTERING_ERROR');
        })
      ),
    { dispatch: false }
  );

  configPaSFlowStatsSetFinished$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(configPaSFlowStatsSetFinished),
        tap(({ msgId }) => {
          this.mixpanel.storeEvent('CONFIGURATION_PRIVACY_AND_SECURITY_' + msgId);
        })
      ),
    { dispatch: false }
  );

  configPaSFlowStatsSetError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(configPaSFlowStatsSetError),
        tap(({ msgId }) => {
          this.mixpanel.storeEvent('CONFIGURATION_PRIVACY_AND_SECURITY_' + msgId + '_ERROR');
        })
      ),
    { dispatch: false }
  );

  customerLoaded$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(customerLoaded),
        tap(({ customer }) => {
          this.mixpanel.setPartnerId(customer.partnerId);
        })
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private mixpanel: MixpanelService) {}
}
