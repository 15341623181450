<section>
  <div class="header">
    <div class="label">{{ 'captiveportal.logo' | translate }}</div>
    <div class="toggler" *ngrxLet="logo$ as logo" [class.enabled]="logo?.length" (click)="disableLogo(logo?.length)">
      <div class="handle"></div>
    </div>
  </div>
  <div class="content">
    <div class="logoInput" *ngIf="(isUprise$ | async) === false">
      <ng-container *ngIf="(mode$ | async) === 'init'">
        <button class="secondary" (click)="upload()">{{ 'captiveportal.upload' | translate }}</button>
        <span>{{ 'captiveportal.or' | translate }}</span>
        <button class="secondary" (click)="scanModeActivate()">{{ 'captiveportal.scanWebsite' | translate }}</button>
      </ng-container>
      <ng-container *ngIf="(mode$ | async) === 'scan'">
        <labeled-input [clearButton]="true" (cleared)="initModeActivate()">
          <input #url type="text" placeholder="URL" />
        </labeled-input>
        <button class="secondary scan" (click)="fetchLogo(url.value)">{{ 'captiveportal.scanUrl' | translate }}</button>
        <div class="icon close" (click)="initModeActivate()"></div>
      </ng-container>
      <ng-container *ngIf="(mode$ | async) === 'loading'">{{ 'captiveportal.loading' | translate }}</ng-container>
    </div>
    <div class="logoInput" *ngIf="(isUprise$ | async) === true">
      <button class="secondary" (click)="upload()">{{ 'captiveportal.upload' | translate }}</button>
    </div>
  </div>
  <div class="content" *ngIf="(isUprise$ | async) === false">
    <div class="slider" #imageSlider></div>
    <div class="value">{{ (imageSize * 100) / 150 | number: '1.0-0' }}%</div>
  </div>
</section>
<section>
  <div class="header">
    <div class="label" *ngIf="(isUprise$ | async) === false">{{ 'captiveportal.businessName' | translate }}</div>
    <div class="label" *ngIf="(isUprise$ | async) === true">{{ 'captiveportal.propertyName' | translate }}</div>
    <div
      class="toggler"
      [class.enabled]="name.value.length"
      (click)="name.value.length ? name.setValue('') : showError(errorMissingTextValue)"
    >
      <div class="handle"></div>
    </div>
  </div>
  <div class="content">
    <labeled-input [clearButton]="true">
      <input
        [formControl]="name"
        name="name"
        type="text"
        placeholder="{{
          ((isUprise$ | async) === true
            ? 'captiveportal.propertyNamePlaceholder'
            : 'captiveportal.businessNamePlaceholder'
          ) | translate
        }}"
        autocomplete="“off”"
        autocorrect="“off”"
        autocapitalize="“off”"
        spellcheck="“false”"
        maxlength="1000"
      />
    </labeled-input>
  </div>
</section>
<section>
  <div class="header">
    <div class="label">{{ 'captiveportal.footerText' | translate }}</div>
    <div
      class="toggler"
      [class.enabled]="footer.value.length"
      (click)="footer.value.length ? footer.setValue('') : showError(errorMissingTextValue)"
    >
      <div class="handle"></div>
    </div>
  </div>
  <div class="content">
    <labeled-input [clearButton]="true">
      <input
        [formControl]="footer"
        name="footer"
        type="text"
        placeholder="{{ 'captiveportal.footerTextPlaceholder' | translate }}"
        autocomplete="“off”"
        autocorrect="“off”"
        autocapitalize="“off”"
        spellcheck="“false”"
        maxlength="1000"
      />
    </labeled-input>
  </div>
</section>
<section>
  <div class="header">
    <div class="label">{{ 'captiveportal.termsConditions' | translate }}</div>
    <div
      class="toggler"
      [class.enabled]="terms.value.length"
      (click)="terms.value.length ? terms.setValue('') : showError(errorMissingTextValue)"
    >
      <div class="handle"></div>
    </div>
  </div>
  <div class="content">
    <labeled-input>
      <textarea
        [formControl]="terms"
        name="terms"
        placeholder="{{ 'captiveportal.termsConditionsPlaceholder' | translate }}"
        autocomplete="“off”"
        autocorrect="“off”"
        autocapitalize="“off”"
        spellcheck="“false”"
      ></textarea>
    </labeled-input>
  </div>
</section>
