import { FlexibleConnectedPositionStrategy, Overlay, OverlayRef, ScrollDispatcher } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { GraphCalculationsService } from '../graph-calculations/graph-calculations.service';

@Component({
  selector: 'flm-graph-tool-tip-bar',
  templateUrl: './graph-tool-tip-bar.component.html',
  styleUrls: ['./graph-tool-tip-bar.component.scss']
})
export class GraphToolTipBarComponent implements OnDestroy, AfterViewInit {
  @Input() @HostBinding('style.left.px') posX = 0;
  @Input() posY: number | undefined = undefined;
  @Input() contrast = false;
  @ViewChild('tooltipTemplate') tooltipTemplate;
  @HostBinding('style.top.px') get calculatedPosY(): number {
    if (this.posY !== undefined) {
      return this.posY;
    }

    return (
      (this.calculations.elementSize.height -
        this.calculations.elementSize.top -
        this.calculations.elementSize.bottom) /
        2 +
      this.calculations.elementSize.top +
      2
    );
  }

  overlayRef: OverlayRef;

  constructor(
    private calculations: GraphCalculationsService,
    private overlay: Overlay,
    private scrollDispatcher: ScrollDispatcher,
    private elementRef: ElementRef<HTMLDivElement>,
    private viewContainerRef: ViewContainerRef
  ) {}

  private createOverlay(): void {
    const scrollableAncestors = this.scrollDispatcher.getAncestorScrollContainers(this.elementRef.nativeElement);
    const strategy = this.overlay
      .position()
      .flexibleConnectedTo(this.elementRef.nativeElement)
      .withFlexibleDimensions(false)
      .withViewportMargin(8)
      .withScrollableContainers(scrollableAncestors);

    this.overlayRef = this.overlay.create({
      positionStrategy: strategy,
      panelClass: 'noPointerEvents',
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    const position = this.overlayRef.getConfig().positionStrategy as FlexibleConnectedPositionStrategy;

    if (this.posY !== undefined) {
      position.withPositions([
        { originX: 'end', originY: 'center', overlayX: 'start', overlayY: 'center', offsetX: 16 },
        { originX: 'start', originY: 'center', overlayX: 'end', overlayY: 'center', offsetX: -16 }
      ]);
    }
    else {
      position.withPositions([{ originX: 'center', originY: 'center', overlayX: 'center', overlayY: 'center' }]);
    }

    const portal = new TemplatePortal(this.tooltipTemplate, this.viewContainerRef);
    this.overlayRef.attach(portal);
  }

  ngAfterViewInit(): void {
    this.createOverlay();
  }

  ngOnDestroy(): void {
    this.overlayRef.detach();
    this.overlayRef.dispose();
    delete this.overlayRef;
  }
}
