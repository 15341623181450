import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DropDownComponent } from '../drop-down/drop-down.component';

@Component({
  selector: 'drop-down-icon',
  templateUrl: './drop-down-icon.component.html',
  styleUrls: ['./drop-down-icon.component.scss']
})
export class DropDownIconComponent {
  @Input() headerIcon = 'icon-more';
  @Input() highlight = false;
  @ViewChild('dropDown') dropDown: DropDownComponent;
  @Input() iconSize = '';
  @Output() opened = new EventEmitter<boolean>();

  constructor(public elRef: ElementRef) {}

  setOpenState(state: boolean): void {
    this.dropDown.setOpenState(state);
  }
}
