import { Component, Input, ElementRef, OnInit, OnChanges, AfterViewInit, OnDestroy } from '@angular/core';
import { D3Service } from 'src/app/lib/d3/service';
import { QoeliveChart } from 'src/app/lib/d3/models/charts/qoelive.chart';
import { Tick } from 'src/app/lib/d3/models/objects/tick';
import { Line, MultiLine } from 'src/app/lib/d3/models/objects/line';
import { Series } from 'src/app/lib/d3/models/objects/series';
import * as erdm from 'element-resize-detector';

@Component({
  selector: 'qoelivechart',
  templateUrl: './qoelivechart.component.html',
  styleUrls: ['./qoelivechart.component.scss']
})
export class QoeliveChartVisualComponent implements AfterViewInit, OnInit, OnChanges, OnDestroy {
  @Input()
  series: Series[];
  @Input()
  data: Line[];
  @Input()
  scale: any = null;
  @Input()
  autoscale: boolean = false;

  chart: QoeliveChart;
  lines: MultiLine[];
  xAxis: Tick[];
  yAxis: Tick[];
  tooltips: any[];
  width: number;
  height: number;
  margins = {
    top: 40,
    right: 80,
    bottom: 30,
    left: 100
  };

  erd: any = erdm({ strategy: 'scroll' });

  constructor(private d3: D3Service, private host: ElementRef) {}

  ngOnInit(): void {
    this.width = this.host.nativeElement.clientWidth;
    this.height = this.host.nativeElement.clientHeight;
    this.chart = this.d3.generate('qoelivechart');

    this.render();
  }

  ngAfterViewInit(): void {
    this.erd.listenTo(this.host.nativeElement, () => {
      this.render();
    });
  }

  ngOnChanges(): void {
    this.render();
  }

  isBrowserNarrow(): boolean {
    return this.host.nativeElement.clientWidth < 640;
  }

  render(): void {
    if (this.isBrowserNarrow() && this.autoscale) {
      this.margins.left = 0;
      this.margins.right = 0;
    } else {
      this.margins.left = 80;
      this.margins.right = 100;
    }

    if (this.chart !== undefined && this.host.nativeElement.clientWidth && this.host.nativeElement.clientHeight) {
      this.lines = this.chart.update(
        JSON.parse(JSON.stringify(this.data)), // this.chart.update changes this data and when more charts using this data then expression is changing after paint
        this.host.nativeElement.clientWidth,
        this.host.nativeElement.clientHeight,
        this.margins,
        this.scale
      );

      if (this.isBrowserNarrow()) {
        this.xAxis = this.chart.xAxisNarrow();
      } else {
        this.xAxis = this.chart.xAxis();
      }

      this.yAxis = this.chart.yAxis();
      this.tooltips = this.generateTooltips();
    }

    this.width = this.host.nativeElement.clientWidth;
    this.height = this.host.nativeElement.clientHeight;
  }

  generateTooltips(): any[] {
    const tooltips = [];
    const temp = {};

    this.lines.forEach((line) => {
      if (!line) {
        return;
      }

      line.data.forEach((data: any) => {
        if (data.value || data.value === 0) {
          const date = this.chart.roundTime(new Date(data.label)).toString();

          if (date in temp) {
            temp[date].push({
              series: line.series.translation,
              value: this.round(data.value, 4),
              color: line.series.color
            });
          } else {
            temp[date] = [
              {
                series: line.series.translation,
                value: this.round(data.value, 4),
                color: line.series.color
              }
            ];
          }
        }
      });
    });

    const regionWidth = 5;

    Object.keys(temp).forEach((key: any) => {
      tooltips.push({
        date: key,
        data: temp[key],
        show: false,
        region: {
          'left.px': this.chart.scaleValue('x', new Date(key)) + this.margins.left - 3 - regionWidth / 2,
          'height.px': this.chart.calculateHeight() + 35,
          'width.px': regionWidth
        },
        style: {
          'top.px': this.chart.calculateHeight() / 2
        }
      });
    });

    return tooltips;
  }

  round(value: number, decimalPlaces: number = 0): number {
    const multiplier = Math.pow(10, decimalPlaces);
    return Math.round(value * multiplier + Number.EPSILON) / multiplier;
  }

  ngOnDestroy(): void {
    this.erd.uninstall(this.host.nativeElement);
  }
}
