<div class="container-fluid" [class.noOverflow]="mode === 'summary'">
  <ng-container *ngIf="onboarded === 'uncomplete' && ui !== 'noc'">
    <spacer>{{ 'completeOnboarding' | translate }}</spacer>
  </ng-container>
  <ng-container *ngIf="(onboarded === 'complete' || ui === 'noc') && !superLiveMode.show">
    <div class="demo" *ngIf="permissions?.uiFeatures.demoModeOption && demo.current && mode !== 'summary'">
      <label for="mocks">Recommendation engine demo</label>
      <drop-down-select
        class="small"
        [values]="demo.deviceMap"
        [ownModel]="demo.current.id"
        [multiSelect]="false"
        (ownModelChange)="demoSelectDevice($event)"
      ></drop-down-select>
      <drop-down-select
        class="small"
        [values]="demo.modesMap"
        [ownModel]="demo.current.demo.current.mode"
        (ownModelChange)="demoSelectMode($event)"
      ></drop-down-select>
      <button class="action small" (click)="demoReset()">{{ 'qoe.demo.resetAll' | translate }}</button>
    </div>
    <spacer>
      <div class="navigation">
        <div class="title">{{ 'qoe.qualityOfExperienceDashboard' | translate }}</div>
        <div class="tabs">
          <div
            class="tab"
            *ngIf="permissions.qoeSummary"
            [class.selected]="mode === 'summary'"
            [class.disabled]="engine.show"
            (click)="handle('summary')"
          >
            {{ 'qoe.summary' | translate }}
          </div>
          <div class="tab" [class.selected]="mode === 'nodes'" [class.disabled]="engine.show" (click)="handle('nodes')">
            {{ 'qoe.nodes' | translate }}
          </div>
          <div
            class="tab"
            [class.selected]="mode === 'devices'"
            [class.disabled]="engine.show"
            (click)="handle('devices')"
          >
            {{ 'qoe.devices' | translate }}
          </div>
          <div
            *ngIf="wanSupported$ | async"
            class="tab"
            [class.selected]="mode === 'wan'"
            [class.disabled]="engine.show"
            (click)="handle('wan')"
          >
            {{ 'qoe.wan' | translate }}
          </div>
          <div
            *ngIf="lteMetricsSupported$ | async"
            class="tab"
            [class.selected]="mode === 'lte'"
            [class.disabled]="engine.show"
            (click)="handle('lte')"
          >
            {{ 'qoe.lte' | translate }}
          </div>
          <div
            *ngIf="qoe5gconfigAndState && enableQoE5gReports"
            class="tab"
            [class.selected]="mode === '5g'"
            [class.disabled]="engine.show"
            (click)="handle('5g')"
          >
            {{ 'qoe.5g' | translate }}
          </div>
        </div>
      </div>
      <div class="controls" *ngIf="mode !== 'summary' && mode !== '5g'">
        <button class="action medium live-mode" (click)="handle('live', $event)">
          <span *ngIf="!live.timer.show">{{ 'qoe.enableLiveMode' | translate }}</span>
          <span *ngIf="live.timer.show">{{ 'qoe.liveView' | translate }}</span>
          <span class="timer" *ngIf="live.timer.show">
            (
            {{
              (live.timer.hours > 0 ? 'qoe.liveViewCountDownHoursMinSec' : 'qoe.liveViewCountDownMinSec')
                | translate: live.timer
            }}
            )
          </span>
          <div class="toggler">
            <div class="checkbox">
              <label class="toggle" [class.checked]="live.enabled"></label>
            </div>
          </div>
          <drop-down-icon class="medium" (click)="eventAddIgnore($event)">
            <li (click)="increaseLiveModeDuration(24)">{{ 'qoe.liveViewAdd1Day' | translate }}</li>
            <li (click)="cancelLiveMode()" *ngIf="live.enabled">{{ 'qoe.cancelLiveMode' | translate }}</li>
          </drop-down-icon>
        </button>
        <button
          class="action medium recommendation-engine"
          [class.selected]="engine.show"
          (click)="handle('engine')"
          *ngIf="mode !== 'wan' && mode !== 'lte'"
        >
          {{ 'qoe.recommendationEngine' | translate }}
          <div class="badge" [class.hide]="!(engine.badge.nodes + engine.badge.devices)">
            {{ engine.badge.nodes + engine.badge.devices }}
          </div>
        </button>
        <button
          class="action medium control-button"
          (click)="openSuperLiveMode()"
          *ngIf="
            live.enabled &&
            ui === 'noc' &&
            permissions?.uiFeatures.superLiveMode &&
            mode !== 'wan' &&
            (mode !== 'lte' || (lteSuperLiveMetricsSupported$ | async))
          "
        >
          {{ 'qoe.superLiveMode' | translate }}
        </button>
      </div>
    </spacer>

    <div
      class="search-input-wrapper"
      *ngIf="!engine.show && mode !== 'wan' && mode !== '5g' && mode !== 'lte' && mode !== 'summary'"
    >
      <div class="search-input">
        <icon name="icon-search"></icon>
        <input [(ngModel)]="searchValue" type="text" placeholder="{{ 'devices.search' | translate }}" />
      </div>
    </div>

    <ng-container *ngIf="loading && mode !== 'wan' && mode !== 'lte' && mode !== '5g'">
      <div class="content">{{ 'qoe.loading' | translate }}</div>
    </ng-container>

    <ng-container *ngIf="mode === 'nodes' && !loading">
      <div class="content" *ngIf="!engine.show && live.enabled">
        <div *ngIf="!nodes.loading && nodes.data && nodes.data.length">
          <div class="headers">
            <div
              *ngFor="let header of nodes.live.headers"
              class="header {{ header.key }}"
              [class.selected]="header.key === nodes.live.sort"
            >
              <div class="title" *ngIf="header.key === 'space'">&nbsp;</div>
              <div class="value" *ngIf="header.key !== 'space'" (click)="changeSort(nodes, 'live', header.key)">
                {{ header.value | translate }}
              </div>
              <icon
                name="icon-triangle-down"
                class="size-0_7"
                [class.rotate180]="header.desc"
                *ngIf="header.key === nodes.live.sort"
              ></icon>
            </div>
          </div>

          <ng-container
            *ngIf="
              nodes.data | filterArrayBy: { filtertext: searchValue, properties: ['name', 'qoe.id'] } as filteredNodes
            "
          >
            <qoestriplive
              *ngFor="let node of filteredNodes; trackBy: track"
              [attr.id]="node.id"
              [data]="node"
            ></qoestriplive>
            <div *ngIf="!filteredNodes.length">
              {{ 'devices.messageAllFiltered' | translate }}
            </div>
          </ng-container>
        </div>
        <div *ngIf="!nodes.loading && nodes.data && !nodes.data.length">
          {{ 'qoe.noNodes' | translate }}
        </div>
        <div *ngIf="nodes.loading">
          {{ 'qoe.loading' | translate }}
        </div>
      </div>
      <div class="content" *ngIf="!engine.show && !live.enabled">
        <div *ngIf="!nodes.loading && nodes.data && nodes.data.length">
          <div class="headers noLive">
            <div
              *ngFor="let header of nodes.min15.headers"
              class="header {{ header.key }}"
              [class.selected]="header.key === nodes.min15.sort"
            >
              <div class="title" *ngIf="header.key === 'space'">&nbsp;</div>
              <div class="value" *ngIf="header.key !== 'space'" (click)="changeSort(nodes, 'min15', header.key)">
                {{ header.value | translate }}
              </div>
              <icon
                name="icon-triangle-down"
                class="size-0_7"
                [class.rotate180]="header.desc"
                *ngIf="header.key === nodes.min15.sort"
              ></icon>
            </div>
          </div>
          <ng-container
            *ngIf="
              nodes.data | filterArrayBy: { filtertext: searchValue, properties: ['name', 'qoe.id'] } as filteredNodes
            "
          >
            <qoestrip *ngFor="let node of filteredNodes; trackBy: track" [attr.id]="node.id" [data]="node"></qoestrip>
            <div *ngIf="!filteredNodes.length">
              {{ 'devices.messageAllFiltered' | translate }}
            </div>
          </ng-container>
        </div>
        <div *ngIf="!nodes.loading && nodes.data && !nodes.data.length">
          {{ 'qoe.noNodes' | translate }}
        </div>
        <div *ngIf="nodes.loading">
          {{ 'qoe.loading' | translate }}
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="mode === 'devices' && !loading">
      <div class="content" *ngIf="!engine.show && live.enabled">
        <div *ngIf="!devices.loading && devices.data && devices.data.length">
          <div class="headers">
            <div
              *ngFor="let header of devices.live.headers"
              class="header {{ header.key }}"
              [class.selected]="header.key === devices.live.sort"
            >
              <div class="title" *ngIf="header.key === 'space'">&nbsp;</div>
              <div class="value" *ngIf="header.key !== 'space'" (click)="changeSort(devices, 'live', header.key)">
                {{ header.value | translate }}
              </div>
              <icon
                name="icon-triangle-down"
                class="size-0_7"
                [class.rotate180]="header.desc"
                *ngIf="header.key === devices.live.sort"
              ></icon>
            </div>
          </div>

          <ng-container
            *ngIf="
              devices.data
                | filterArrayBy
                  : { filtertext: searchValue, properties: ['privateName', 'privateId'] } as filteredDevices
            "
          >
            <qoestriplive
              *ngFor="let device of filteredDevices; trackBy: track"
              [attr.id]="device.id"
              [data]="device"
              type="device"
            ></qoestriplive>
            <div *ngIf="!filteredDevices.length">
              {{ 'devices.messageAllFiltered' | translate }}
            </div>
          </ng-container>
        </div>
        <div *ngIf="!devices.loading && devices.data && !devices.data.length">
          {{ 'qoe.noDevices' | translate }}
        </div>
        <div *ngIf="devices.loading">
          {{ 'qoe.loading' | translate }}
        </div>
      </div>
      <div class="content" *ngIf="!engine.show && !live.enabled">
        <div *ngIf="!devices.loading && devices.data && devices.data.length">
          <div class="headers noLive">
            <div
              *ngFor="let header of devices.min15.headers"
              class="header {{ header.key }}"
              [class.selected]="header.key === devices.min15.sort"
            >
              <div class="title" *ngIf="header.key === 'space'">&nbsp;</div>
              <div class="value" *ngIf="header.key !== 'space'" (click)="changeSort(devices, 'min15', header.key)">
                {{ header.value | translate }}
              </div>
              <icon
                name="icon-triangle-down"
                class="size-0_7"
                [class.rotate180]="header.desc"
                *ngIf="header.key === devices.min15.sort"
              ></icon>
            </div>
          </div>
          <ng-container
            *ngIf="
              devices.data
                | filterArrayBy
                  : { filtertext: searchValue, properties: ['privateName', 'privateId'] } as filteredDevices
            "
          >
            <qoestrip
              *ngFor="let device of filteredDevices; trackBy: track"
              [attr.id]="device.id"
              [data]="device"
              type="device"
            ></qoestrip>
            <div *ngIf="!filteredDevices.length">
              {{ 'devices.messageAllFiltered' | translate }}
            </div>
          </ng-container>
        </div>
        <div *ngIf="!devices.loading && devices.data && !devices.data.length">
          {{ 'qoe.noDevices' | translate }}
        </div>
        <div *ngIf="devices.loading">
          {{ 'qoe.loading' | translate }}
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="mode === 'wan'">
      <qoewan [live]="live.enabled"></qoewan>
    </ng-container>

    <ng-container *ngIf="mode === 'lte' && (lteMetricsSupported$ | async)">
      <qoelte [live]="live.enabled"></qoelte>
    </ng-container>

    <ng-container *ngIf="mode === '5g'">
      <qoe5g [mode]="mode"></qoe5g>
    </ng-container>

    <ng-container *ngIf="mode === 'summary' && !loading">
      <div *ngIf="devices.loading || nodes.loading">
        {{ 'qoe.loading' | translate }}
      </div>
      <qoesummary
        *ngIf="!devices.loading && !nodes.loading"
        [nodes]="nodes.data"
        [devices]="devices.data"
        (clickedDevice)="openDevice($event)"
        (clickedNode)="openNode($event)"
      ></qoesummary>
    </ng-container>

    <ng-container *ngIf="engine.show && live.enabled && !loading">
      <div class="content">
        <div class="title">{{ 'qoe.nodes' | translate }}</div>
        <div class="headers">
          <div
            *ngFor="let header of recommendations.nodes.live.headers"
            class="header {{ header.key }}"
            [class.selected]="header.key === recommendations.nodes.live.sort"
          >
            <div class="title" *ngIf="header.key === 'space'"></div>
            <div
              class="value"
              *ngIf="header.key !== 'space'"
              (click)="changeSort(recommendations.nodes, 'live', header.key)"
            >
              {{ header.value | translate }}
            </div>
            <icon
              name="icon-triangle-down"
              class="size-0_7"
              [class.rotate180]="header.desc"
              *ngIf="header.key === recommendations.nodes.live.sort"
            ></icon>
          </div>
        </div>
        <div *ngIf="recommendations.nodes.data && recommendations.nodes.data.length && live">
          <qoestriplive *ngFor="let node of recommendations.nodes.data; trackBy: track" [data]="node"></qoestriplive>
        </div>
        <div class="noRecommendations" *ngIf="!recommendations.nodes.data || !recommendations.nodes.data.length">
          {{ 'qoe.noNodeRecommendations' | translate }}
        </div>
        <div class="title">{{ 'qoe.devices' | translate }}</div>
        <div class="headers">
          <div
            *ngFor="let header of recommendations.devices.live.headers"
            class="header {{ header.key }}"
            [class.selected]="header.key === recommendations.devices.live.sort"
          >
            <div class="title" *ngIf="header.key === 'space'"></div>
            <div
              class="value"
              *ngIf="header.key !== 'space'"
              (click)="changeSort(recommendations.devices, 'live', header.key)"
            >
              {{ header.value | translate }}
            </div>
            <icon
              name="icon-triangle-down"
              class="size-0_7"
              [class.rotate180]="header.desc"
              *ngIf="header.key === recommendations.devices.live.sort"
            ></icon>
          </div>
        </div>
        <div *ngIf="recommendations.devices.data && recommendations.devices.data.length && live">
          <qoestriplive
            *ngFor="let device of recommendations.devices.data; trackBy: track"
            [data]="device"
            type="device"
          ></qoestriplive>
        </div>
        <div class="noRecommendations" *ngIf="!recommendations.devices.data || !recommendations.devices.data.length">
          {{ 'qoe.noDeviceRecommendations' | translate }}
        </div>
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="superLiveMode.show">
    <div class="superLive">
      <div class="top-controls">
        <div>
          <p class="placeholder-text">{{ 'qoe.superLiveModeDescription' | translate }}</p>
          <div class="tabs">
            <div class="tab" [class.selected]="mode === 'nodes'" (click)="handle('nodes')">
              {{ 'qoe.nodes' | translate }}
            </div>
            <div class="tab" [class.selected]="mode === 'devices'" (click)="handle('devices')">
              {{ 'qoe.devices' | translate }}
            </div>
            <div
              class="tab"
              [class.selected]="mode === 'lte'"
              (click)="handle('lte')"
              *ngIf="lteSuperLiveMetricsSupported$ | async"
            >
              {{ 'qoe.lte' | translate }}
            </div>
          </div>
          <p class="placeholder-text">
            <ng-container *ngIf="mode === 'nodes'">{{ 'qoe.superLiveModeSelectNode' | translate }}</ng-container>
            <ng-container *ngIf="mode === 'devices'">{{ 'qoe.superLiveModeSelectDevice' | translate }}</ng-container>
          </p>
          <div class="device-selector" *ngIf="mode !== 'lte'">
            {{ (mode === 'nodes' ? 'qoe.currentNode' : 'qoe.currentDevice') | translate }}:
            <drop-down-select
              class="selector medium"
              emptyText="{{ (mode === 'nodes' ? 'qoe.selectNode' : 'qoe.selectDevice') | translate }}"
              [values]="superLiveMode.list"
              [filterVisible]="true"
              [filterFunction]="filterSuperLiveDevices"
              [ownModel]="superLiveMode.current?.name"
              (ownModelChange)="selectSuperLiveDevice($event)"
            ></drop-down-select>
          </div>
        </div>

        <div class="controls">
          <div class="back" (click)="closeSuperLiveMode()">
            <icon name="icon-arrow-left"></icon>
            {{ 'qoe.backToQoe' | translate }}
          </div>
          <div class="period-select">
            <slidetoggler [options]="superLiveMode.periods" (toggle)="action('superlivePeriod', $event)"></slidetoggler>
          </div>
          <div class="super-live-mode" *ngIf="superLiveMode.current">
            <div class="controls">
              <button
                class="action medium live-mode super-live-mode"
                [ngClass]="live.timer.show && live.timer.mins < 1 && 'ending'"
                (click)="toggleSuperLiveMode()"
              >
                <span *ngIf="!superLiveMode.enabled">{{ 'qoe.start' | translate }}</span>
                <span *ngIf="superLiveMode.enabled">{{ 'qoe.stop' | translate }}</span>
                <span class="timer" *ngIf="live.timer.show">
                  (
                  {{
                    (live.timer.hours > 0 ? 'qoe.liveViewCountDownHoursMinSec' : 'qoe.liveViewCountDownMinSec')
                      | translate: live.timer
                  }}
                  )
                </span>
                <div class="toggler">
                  <div class="checkbox">
                    <label class="toggle" [class.checked]="superLiveMode.enabled"></label>
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="superLiveMode.current && mode !== 'lte'">
        <qoesuperlive
          [data]="superLiveMode.current"
          [period]="superLiveMode.period"
          [enabled]="superLiveMode.enabled || live.timer.secs < 1"
        ></qoesuperlive>
      </div>
      <div *ngIf="mode === 'lte'">
        <qoelte [live]="live.enabled" [superLiveInterval]="superLiveMode.period"></qoelte>
      </div>
    </div>
  </ng-container>
</div>
