import { Component, Input } from '@angular/core';
import { ModelRefService } from 'src/app/lib/services/modelref.service';

@Component({
  selector: 'ethernetuplinkalarm',
  templateUrl: './ethernetuplinkalarm.component.html',
  styleUrls: ['./ethernetuplinkalarm.component.scss']
})
export class EthernetUplinkAlarmComponent {
  @Input()
  alerts: any = [];

  constructor(private modelRef: ModelRefService) {}

  getIcon(model: string): string {
    return this.modelRef.get(model).icon;
  }
}
