<div class="container-fluid">
  <div class="row">
    <div class="col-xs-12">
      <collapsiblebox mode="fill" [show]="expanded" [collapsible]="true" toggleSide="left" (toggle)="toggleExpand()">
        <div header>
          <div class="group">
            <div class="title">{{ 'timelines.optimizer.title' | translate }}</div>
            <icon name="icon-info" tooltip="{{ 'timelines.optimizer.tooltip' | translate }}" [maxWidth]="300"></icon>
          </div>

          <div class="grow" *ngIf="expanded && (dayView || (!dayView && (moreThanMaxEvents || moreThanMaxRequests)))">
            <div class="tag" *ngIf="!dayView && moreThanMaxEvents">
              <div class="tooMany">
                <div>{{ 'timelines.optimizer.tooMany' | translate }}</div>
                <div>{{ 'timelines.optimizer.reachedLimit' | translate }}</div>
              </div>
            </div>
            <div class="tag" *ngIf="!dayView && !moreThanMaxEvents && moreThanMaxRequests">
              <div class="tooMany">
                <div>{{ 'timelines.optimizer.tooManyRequests' | translate }}</div>
                <div>{{ 'timelines.optimizer.reachedLimit' | translate }}</div>
              </div>
            </div>
            <div class="tag skip-toggle" (click)="changeView(null)" *ngIf="dayView">
              <icon name="icon-close" class="size-0_5"></icon>
              {{ dayView.text }}
            </div>
          </div>

          <drop-down class="selector medium skip-toggle" *ngIf="expanded">
            {{ selector.current | translate }}
            <ng-container dropdown>
              <li (click)="filterEvents('timelines.optimizer.filter.noFilter', 'noFilter')">
                {{ 'timelines.optimizer.filter.noFilter' | translate }}
              </li>
              <li (click)="filterEvents('timelines.optimizer.filter.allExceptPowerAwareness', 'noPowerAwareness')">
                {{ 'timelines.optimizer.filter.allExceptPowerAwareness' | translate }}
              </li>
              <li (click)="filterEvents('timelines.optimizer.filter.topologyDeviated', 'TopologyDeviated')">
                {{ 'timelines.optimizer.filter.topologyDeviated' | translate }}
              </li>
              <li (click)="filterEvents('timelines.optimizer.filter.fastInterference', 'FastInterference')">
                {{ 'timelines.optimizer.filter.fastInterference' | translate }}
              </li>
              <li (click)="filterEvents('timelines.optimizer.filter.radarDetected', 'RadarDetected')">
                {{ 'timelines.optimizer.filter.radarDetected' | translate }}
              </li>
              <li (click)="filterEvents('timelines.optimizer.filter.nonDfsClientDetect', 'NonDfsClientDetected')">
                {{ 'timelines.optimizer.filter.nonDfsClientDetect' | translate }}
              </li>
              <li (click)="filterEvents('timelines.optimizer.filter.PowerAwareness', 'PowerAwareness')">
                {{ 'timelines.optimizer.filter.PowerAwareness' | translate }}
              </li>
            </ng-container>
          </drop-down>

          <div class="legend" *ngIf="expanded">
            <div class="item" *ngFor="let item of legend">
              <svg viewBox="0 0 4 4">
                <svg:circle [ngClass]="item.color" cx="50%" cy="50%" r="1"></svg:circle>
              </svg>
              <div class="label">{{ item.text | translate }}</div>
            </div>
          </div>
        </div>

        <div container *ngIf="expanded">
          <timelineschart
            [events]="optimizerEvents"
            [dayView]="dayView"
            [eventsMenu]="true"
            (view)="changeView($event)"
            (event)="selectEvent($event)"
          ></timelineschart>
        </div>
      </collapsiblebox>
    </div>
  </div>
  <optimizerdetails *ngIf="expanded && eventDetails" [event]="eventDetails" [nodes]="nodes"></optimizerdetails>
</div>
