<h3>{{ 'configurations.wan.configuration.title' | translate }}</h3>

<ng-container>
  <div class="container">
    <div class="key header">{{ 'configurations.wan.configuration.pppoe' | translate }}</div>
    <div class="value header">
      <div class="toggler">
        <div class="checkbox">
          <label
            class="toggle"
            [class.checked]="pppoe.enabled || wan?.pppoe"
            [class.disabled]="!edit"
            (click)="toggleSection('pppoe')"
          ></label>
        </div>
      </div>
    </div>
    <div class="key">{{ 'configurations.wan.configuration.userName' | translate }}</div>
    <div class="value">
      <ng-container *ngIf="!edit || !pppoe.enabled">{{ wan?.pppoe?.username }}</ng-container>
      <ng-container *ngIf="edit && pppoe.enabled">
        <labeled-input>
          <input type="text" [formControl]="pppoe.username" required />
          <div info error *ngIf="!pppoe.username.value?.length">
            {{ 'configurations.wan.configuration.requiredField' | translate }}
          </div>
        </labeled-input>
      </ng-container>
    </div>
    <div class="key">{{ 'configurations.wan.configuration.password' | translate }}</div>
    <div class="value">
      <ng-container *ngIf="!edit || !pppoe.enabled">{{ wan?.pppoe?.password }}</ng-container>
      <ng-container *ngIf="edit && pppoe.enabled">
        <labeled-input>
          <input type="text" [formControl]="pppoe.password" required />
          <div info error *ngIf="!pppoe.password.value?.length">
            {{ 'configurations.wan.configuration.requiredField' | translate }}
          </div>
        </labeled-input>
      </ng-container>
    </div>

    <div class="key header">{{ 'configurations.wan.configuration.staticIpv4' | translate }}</div>
    <div class="value header">
      <div class="toggler">
        <div class="checkbox">
          <label
            class="toggle"
            [class.checked]="staticIp.enabled || wan?.staticIp"
            [class.disabled]="!edit"
            (click)="toggleSection('staticIp')"
          ></label>
        </div>
      </div>
    </div>
    <div class="key">{{ 'configurations.wan.configuration.ip' | translate }}</div>
    <div class="value">
      <ng-container *ngIf="!edit || !staticIp.enabled">{{ wan?.staticIp?.ip }}</ng-container>
      <ng-container *ngIf="edit && staticIp.enabled">
        <labeled-input>
          <input type="text" [formControl]="staticIp.ip" placeholder="0.0.0.0" [pattern]="ipRegex" required />
          <div info error *ngIf="!staticIp.ip.value?.length">
            {{ 'configurations.wan.configuration.requiredField' | translate }}
          </div>
        </labeled-input>
      </ng-container>
    </div>
    <div class="key">{{ 'configurations.wan.configuration.gateway' | translate }}</div>
    <div class="value">
      <ng-container *ngIf="!edit || !staticIp.enabled">{{ wan?.staticIp?.gateway }}</ng-container>
      <ng-container *ngIf="edit && staticIp.enabled">
        <labeled-input>
          <input type="text" [formControl]="staticIp.gateway" placeholder="0.0.0.0" [pattern]="ipRegex" required />
          <div info error *ngIf="!staticIp.gateway.value?.length">
            {{ 'configurations.wan.configuration.requiredField' | translate }}
          </div>
        </labeled-input>
      </ng-container>
    </div>
    <div class="key">{{ 'configurations.wan.configuration.primaryDns' | translate }}</div>
    <div class="value">
      <ng-container *ngIf="!edit || !staticIp.enabled">{{ wan?.staticIp?.primaryDns }}</ng-container>
      <ng-container *ngIf="edit && staticIp.enabled">
        <labeled-input>
          <input type="text" [formControl]="staticIp.primaryDns" placeholder="0.0.0.0" [pattern]="ipRegex" required />
          <div info error *ngIf="!staticIp.primaryDns.value?.length">
            {{ 'configurations.wan.configuration.requiredField' | translate }}
          </div>
        </labeled-input>
      </ng-container>
    </div>
    <div class="key">{{ 'configurations.wan.configuration.secondaryDns' | translate }}</div>
    <div class="value">
      <ng-container *ngIf="!edit || !staticIp.enabled">{{ wan?.staticIp?.secondaryDns.value }}</ng-container>
      <ng-container *ngIf="edit && staticIp.enabled">
        <labeled-input>
          <input type="text" [formControl]="staticIp.secondaryDns" placeholder="0.0.0.0" [pattern]="ipRegex" required />
        </labeled-input>
      </ng-container>
    </div>
    <div class="key">{{ 'configurations.wan.configuration.subnet' | translate }}</div>
    <div class="value">
      <ng-container *ngIf="!edit || !staticIp.enabled">{{ wan?.staticIp?.subnet }}</ng-container>
      <ng-container *ngIf="edit && staticIp.enabled">
        <labeled-input>
          <input type="text" [formControl]="staticIp.subnet" placeholder="0.0.0.0" [pattern]="ipRegex" required />
          <div info error *ngIf="!staticIp.subnet.value?.length">
            {{ 'configurations.wan.configuration.requiredField' | translate }}
          </div>
        </labeled-input>
      </ng-container>
    </div>

    <div class="key header">{{ 'configurations.wan.configuration.vlan' | translate }}</div>
    <div class="value header">
      <div class="toggler">
        <div class="checkbox">
          <label
            class="toggle"
            [class.checked]="vlan.enabled || wan?.vlan"
            [class.disabled]="!edit"
            (click)="toggleSection('vlan')"
          ></label>
        </div>
      </div>
    </div>
    <div class="key">{{ 'configurations.wan.configuration.vlanid' | translate }}</div>
    <div class="value">
      <ng-container *ngIf="!edit || !vlan.enabled">{{ wan?.vlan?.vlanId }}</ng-container>
      <ng-container *ngIf="edit && vlan.enabled">
        <labeled-input>
          <input type="text" [formControl]="vlan.vlanId" [pattern]="numRegex" required />
          <div info error *ngIf="!vlan.vlanId.value?.length">
            {{ 'configurations.wan.configuration.requiredField' | translate }}
          </div>
        </labeled-input>
      </ng-container>
    </div>
  </div>

  <button class="super-primary" (click)="setEditState()" *ngIf="!edit && plume.isSomeAdminRole()">
    {{ 'configurations.wan.configuration.edit' | translate }}
  </button>
  <button class="tertiary light" (click)="cancelEditState()" *ngIf="edit">
    {{ 'configurations.wan.configuration.cancel' | translate }}
  </button>
  <button class="super-primary" (click)="saveEditState()" *ngIf="edit">
    {{ 'configurations.wan.configuration.save' | translate }}
  </button>
</ng-container>
