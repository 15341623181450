<div class="row" [class.dim]="selected != alarm?.id && selected != null">
  <div class="col-xs-12">
    <box class="wider" [class.selected]="expand">
      <div header (click)="expand = !expand; select(alarm?.id)">
        <span *ngIf="alarmType === 'bbe'">
          <icon name="icon-arrow-down" [class.rotate180]="expand"></icon>
          {{ 'health.networkPerformance.extenderSpeedAlarm.extenderBroadbandEfficiency' | translate }}
        </span>
        <span *ngIf="alarmType !== 'bbe'">
          <icon name="icon-arrow-down" [class.rotate180]="expand"></icon>
          {{ 'health.networkPerformance.extenderSpeedAlarm.lowExtenderSpeed' | translate }}
        </span>
      </div>
      <div container>
        <div class="data">
          <div class="column">
            <icon [path]="alarm?.kind?.icon" *ngIf="alarm && alarm?.kind && alarm?.kind?.icon"></icon>
            <div class="name" *ngIf="alarm?.kind">
              <span class="title">{{ alarm?.kind?.name }}</span> <span class="type">{{ alarm?.kind?.category }}</span>
            </div>
          </div>

          <div class="column speedresultmessage" *ngIf="alarmType === 'bbe'">
            {{
              'health.networkPerformance.extenderSpeedAlarm.messageAverageEfficiency'
                | translate: { broadbandEfficiency: round(alarm?.bbe?.broadbandEfficiency * 100) }
            }}
          </div>

          <div class="column speedresultmessage" *ngIf="alarmType === 'speed'">
            {{
              'health.networkPerformance.extenderSpeedAlarm.messageAverageDownloadSpeed'
                | translate
                  : {
                      wifiDownloadSpeed: round(alarm?.wifiDownloadSpeed),
                      broadbandDownloadSpeed: round(alarm?.broadbandDownloadSpeed)
                    }
            }}
          </div>
        </div>
        <div *ngIf="expand">
          <div class="row speedresultdetails">
            <span class="speedresultlabel">
              {{ 'health.networkPerformance.extenderSpeedAlarm.model' | translate }}:
            </span>
            <span class="speedresultdata"> {{ alarm?.model }}</span>
          </div>
          <div class="row speedresultdetails">
            <span class="speedresultlabel">
              {{ 'health.networkPerformance.extenderSpeedAlarm.firmwareVersion' | translate }}:
            </span>
            <span class="speedresultdata"> {{ alarm?.firmwareVersion }}</span>
          </div>
          <div class="row speedresultdetails">
            <span class="speedresultlabel">
              {{ 'health.networkPerformance.extenderSpeedAlarm.serial' | translate }}:
            </span>
            <span class="speedresultdata"> {{ alarm?.id }}</span>
          </div>
        </div>
      </div>
    </box>
    <div class="wrapper" [class.show]="troubleshoot" *ngIf="troubleshoot">
      <optimizeBubble
        lastOptimize="{{ currentOptimization }}"
        [autorun]="autorunOptimization"
        *ngIf="step === 'checkOptimization' || step === 'escalate'"
      ></optimizeBubble>

      <logbubble *ngIf="step === 'escalate'">
        {{ 'health.networkPerformance.extenderSpeedAlarm.logBubbleEscalate' | translate }}
      </logbubble>

      <logbubble *ngIf="step === 'speedFail'">
        {{ 'health.networkPerformance.extenderSpeedAlarm.logBubbleSpeedFail' | translate }}
      </logbubble>

      <speedtestbubble
        #speedtestcmp
        *ngIf="step === 'checkSpeed' || step === 'checkBacktrace' || step === 'inform'"
        nodeid="{{ alarm?.id }}"
        optimization="{{ currentOptimization }}"
      ></speedtestbubble>

      <div class="bubble" *ngIf="speedtestOk">
        <div class="message">
          {{ 'health.networkPerformance.extenderSpeedAlarm.messageEfficiencyTooLow' | translate }}
        </div>
        <div class="data">
          <div class="column">
            <icon [path]="alarm?.kind?.icon" *ngIf="alarm && alarm?.kind && alarm?.kind?.icon"></icon>
            <div class="name" *ngIf="alarm?.kind">
              <span class="title">{{ alarm?.kind?.name }}</span> <span class="type">{{ alarm?.kind?.category }}</span>
            </div>
          </div>
        </div>
        <leaftoroot [device]="alarm" [nodes]="nodes"></leaftoroot>
      </div>

      <informbubble [extender]="alarm" [nocheck]="true" *ngIf="step === 'inform'"></informbubble>
    </div>
  </div>
</div>
<div class="row" *ngIf="!troubleshoot">
  <div class="col-xs-12">
    <div class="actions">
      <div class="button wide" (click)="startTroubleshooting()">
        {{ 'health.networkPerformance.extenderSpeedAlarm.beginTroubleshooting' | translate }}
      </div>
    </div>
  </div>
</div>
