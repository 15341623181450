export const firebase = {
  enabled: true,
  apiKey: 'AIzaSyAPYtEUA_YyzCi8TwhMaYSnHXXEwhoDqWg',
  authDomain: 'plumecentral.firebaseapp.com',
  databaseURL: 'https://plumecentral.firebaseio.com',
  projectId: 'plumecentral',
  storageBucket: 'plumecentral.appspot.com',
  messagingSenderId: '1032497648688',
  appId: '1:1032497648688:web:6ba20f94f3095412'
};
