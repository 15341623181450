import { Component, OnInit, OnChanges, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { CustomerService } from 'src/app/lib/services/customer.service';

@Component({
  selector: 'notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit, OnChanges, OnDestroy {
  expand: boolean = false;
  shiftyInterval: any;
  newDeviceInterval: any;
  notification: any = {};
  newdevice: any = {};
  locallyAdministeredMacItems: any[] = [];
  newDeviceConnectedItems: any[] = [];
  pollingInterval: number = 3000;

  @Input()
  open: number = 0;

  @Output()
  toggle = new EventEmitter();

  @Output()
  filter = new EventEmitter();

  @Output()
  clearFilter = new EventEmitter<{ section: string }>();

  constructor(
    public plume: PlumeService,
    private mixpanel: MixpanelService,
    private translate: TranslateService,
    private customerService: CustomerService
  ) {}

  ngOnInit(): void {
    this.init();
    this.registerFilter();
  }

  ngOnChanges(changes: any): void {
    this.expand = changes.open.currentValue;
  }

  init(): void {
    this.getShifty();
    this.getNewConnection();
  }

  getShifty(): void {
    this.customerService.shiftyMacs$().subscribe((response) => {
      this.notification = response;
      this.initShiftyTogglers();
    });
  }

  getNewConnection(): void {
    this.customerService.newConnectionNotificationState$().subscribe((response) => {
      this.newdevice = response;
      this.initNewDeviceTogglers();
    });
  }

  registerFilter(): void {
    this.clearFilter.emit({ section: 'notifications' });

    this.translate
      .get('configurations.notifications.newDeviceConnected')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'notifications', property: 'newDeviceConnected', translation: translated })
      );

    this.translate
      .get('configurations.notifications.locallyAdministeredMac')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'notifications', property: 'locallyAdministeredMac', translation: translated })
      );
  }

  // since notifications can take up to 1 second, these calls ensure notifications realized state is accurate when delayed.
  retriggerShifty(): void {
    if (this.shiftyInterval) {
      clearTimeout(this.shiftyInterval);
    }

    this.shiftyInterval = setTimeout(() => this.getShifty(), this.pollingInterval);
  }

  retriggerNewDevice(): void {
    if (this.newDeviceInterval) {
      clearTimeout(this.newDeviceInterval);
    }

    this.newDeviceInterval = setTimeout(() => this.getNewConnection(), this.pollingInterval);
  }

  action(command: string, action: 'perDevice' | 'disable' | 'auto'): void {
    switch (command) {
      case 'locallyAdministeredMac':
        this.customerService.setShiftyMacsMode$(action).subscribe((response) => {
          this.notification = response;
          this.mixpanel.storeEvent('CONFIGURATION_NOTIFICATIONS_SHIFTYSET', { shiftymacs: action });
          this.retriggerShifty();
          this.initShiftyTogglers();
        });
        break;

      case 'newdevice':
        this.customerService.setNewConnectionNotificationMode$(action).subscribe((response) => {
          this.newdevice = response;
          this.mixpanel.storeEvent('CONFIGURATION_NOTIFICATIONS_NEWDEVICESET', { shiftymacs: action });
          this.retriggerNewDevice();
          this.initNewDeviceTogglers();
        });
        break;
    }
  }

  initNewDeviceTogglers(): void {
    this.newDeviceConnectedItems = [
      { value: 'auto', translation: 'auto', selected: this.newdevice.mode === 'auto' },
      {
        value: 'disable',
        translation: 'disabled',
        marked: this.newdevice.modeRealized === 'disable',
        selected: this.newdevice.mode === 'disable'
      },
      {
        value: 'perDevice',
        translation: 'perDevice',
        marked: this.newdevice.modeRealized === 'perDevice',
        selected: this.newdevice.mode === 'perDevice'
      }
    ];
  }

  initShiftyTogglers(): void {
    this.locallyAdministeredMacItems = [
      { value: 'auto', translation: 'auto', selected: this.notification.mode === 'auto' },
      {
        value: 'disable',
        translation: 'disabled',
        marked: this.notification.modeRealized === 'disable',
        selected: this.notification.mode === 'disable'
      },
      {
        value: 'perDevice',
        translation: 'perDevice',
        marked: this.notification.modeRealized === 'perDevice',
        selected: this.notification.mode === 'perDevice'
      }
    ];
  }

  toggleExpand(): void {
    this.toggle.emit(!this.expand);
    this.initShiftyTogglers();
    this.initNewDeviceTogglers();

    if (!this.expand) {
      this.mixpanel.storeEvent('CONFIGURATION_BANDSTEERING_SCREEN');
    }
  }

  ngOnDestroy(): void {
    if (this.shiftyInterval) {
      clearTimeout(this.shiftyInterval);
    }

    if (this.newDeviceInterval) {
      clearTimeout(this.newDeviceInterval);
    }
  }
}
