<div class="description">Send configuration data directly to node via Bluetooth LE</div>
<div class="section loading" *ngIf="mode == 'scanning'">Scanning...</div>
<div class="section scan" *ngIf="mode == 'disconnected'">
  <drop-down class="selector medium">
    <span *ngIf="!node">Select node</span>
    <span *ngIf="node">{{ node.id }} {{ node.bluetoothMac }}</span>
    <ng-container dropdown>
      <li (click)="selectNode(node)" *ngFor="let node of nodes">{{ node.id }} {{ node.bluetoothMac }}</li>
    </ng-container>
  </drop-down>
  <div class="controls">
    <button class="action medium" [class.disabled]="!node" (click)="scan()">Advertisments</button>
    <span>or</span>
    <button class="action medium" [class.disabled]="!node" (click)="requestDevice()">Request Device</button>
  </div>
</div>
<div class="section pin" *ngIf="mode == 'pairing'">
  <div>PIN:</div>
  <div class="code">{{ pin }}</div>
</div>
<div class="section settings" *ngIf="mode == 'connected'">
  <div>Connected to {{ node.id }}</div>
  <div class="setting">
    <div class="header">
      <div class="title">PPPoE</div>
      <div class="toggler" (click)="pppoe = !pppoe">
        <div class="checkbox"><label class="toggle" [class.checked]="pppoe"></label></div>
      </div>
    </div>
    <div class="group" *ngIf="pppoe">
      <div class="data">
        <div class="label">Username:</div>
        <input [formControl]="username" type="text" placeholder="Username..." />
      </div>
      <div class="data">
        <div class="label">Password:</div>
        <input [formControl]="password" type="text" placeholder="Password..." />
      </div>
    </div>
  </div>
  <div class="setting">
    <div class="header">
      <div class="title">Data Service</div>
      <div class="toggler" (click)="dataService = !dataService">
        <div class="checkbox"><label class="toggle" [class.checked]="dataService"></label></div>
      </div>
    </div>
    <div class="group" *ngIf="dataService">
      <div class="data">
        <div class="label">VLAN:</div>
        <input [formControl]="vlan" type="text" placeholder="VLAN..." />
      </div>
      <div class="data">
        <div class="label">QoS:</div>
        <input [formControl]="qos" type="text" placeholder="QoS..." />
      </div>
    </div>
  </div>
  <div class="setting">
    <div class="header">
      <div class="title">Static IPv4</div>
      <div class="toggler" (click)="staticIPv4 = !staticIPv4">
        <div class="checkbox"><label class="toggle" [class.checked]="staticIPv4"></label></div>
      </div>
    </div>
    <div class="group" *ngIf="staticIPv4">
      <div class="data">
        <div class="label">IP:</div>
        <input [formControl]="ip" type="text" placeholder="IP..." />
      </div>
      <div class="data">
        <div class="label">Subnet:</div>
        <input [formControl]="subnet" type="text" placeholder="Subnet..." />
      </div>
      <div class="data">
        <div class="label">Gateway:</div>
        <input [formControl]="gateway" type="text" placeholder="Gateway..." />
      </div>
      <div class="data">
        <div class="label">Primary DNS:</div>
        <input [formControl]="primaryDns" type="text" placeholder="Primary DNS..." />
      </div>
      <div class="data">
        <div class="label">Secondary DNS:</div>
        <input [formControl]="secondaryDns" type="text" placeholder="Secondary DNS..." />
      </div>
    </div>
  </div>
  <div class="controls">
    <button class="action medium" (click)="readValues()">Read data</button>
    <button class="action medium" (click)="writeValues()">Write data</button>
    <button class="action medium" (click)="pushToCloud()" [class.disabled]="!writeSuccess">Save to cloud</button>
    <button class="action medium" (click)="disconnect()">Disconnect</button>
  </div>
</div>