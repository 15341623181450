<ng-container *ngFor="let data of dataSet?.data">
  <svg:g class="container" (mouseenter)="hover(data)" (mouseleave)="hover(undefined)">
    <svg:rect
      *ngFor="let intervalItem of data.yInterval"
      class="line"
      [attr.x]="data.xPos - 3"
      [attr.y]="intervalItem.endPos"
      width="6"
      [attr.height]="intervalItem.startPos - intervalItem.endPos > 6 ? intervalItem.startPos - intervalItem.endPos : 6"
      rx="3"
    />
    <svg:line
      class="verticalHoverLine"
      [attr.x1]="data.xPos"
      [attr.x2]="data.xPos"
      [attr.y1]="calculations.elementSize.height - calculations.elementSize.bottom"
      [attr.y2]="calculations.elementSize.top"
    />
  </svg:g>
</ng-container>
