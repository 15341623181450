<div class="row" id="conf-utilities">
  <div class="col-xs-12">
    <collapsiblebox [show]="expand" [collapsible]="true" (toggle)="toggleExpand()">
      <div header>{{ 'configurations.utilities.title' | translate }}</div>
      <div container class="main-container utilities" *ngIf="expand">
        <div class="columns">
          <div class="column">
            <div class="row">
              <div class="key">
                {{ 'configurations.utilities.rebootLocation' | translate }}
              </div>
              <div class="value">
                <button
                  class="action medium"
                  [class.disabled]="!plume.isSomeAdminRole() && !isSupportOrGroupSupport"
                  (click)="showRebootModal()"
                >
                  {{ 'configurations.utilities.reboot' | translate }}
                </button>
              </div>
            </div>

            <div class="row unclaim" [class.disable]="isUprise$ | async">
              <div class="key">
                {{ 'configurations.utilities.unclaimAllNodes' | translate }}
              </div>
              <div class="value">
                <button class="action medium" [class.disabled]="!plume.isSomeAdminRole()" (click)="showUnclaimModal()">
                  {{ 'configurations.utilities.unclaimAll' | translate }}
                </button>

                <div class="toggler-wrap">
                  <div class="toggler-key">{{ 'configurations.utilities.preservePackIDs' | translate }}</div>
                  <div class="toggler" (click)="preservePackIDs = !preservePackIDs">
                    <div class="checkbox">
                      <label
                        class="toggle"
                        [class.checked]="preservePackIDs"
                        [class.disabled]="!plume.isSomeAdminRole()"
                      ></label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row unclaim">
              <div class="key">
                {{ 'configurations.utilities.factoryReset' | translate }}
              </div>
              <div class="value">
                <button
                  class="action medium"
                  [class.disabled]="!plume.isSomeAdminRole()"
                  (click)="showFactoryResetModal()"
                >
                  {{ 'configurations.utilities.factoryReset' | translate }}
                </button>
                <div class="toggler-wrap">
                  <div class="toggler-key">{{ 'configurations.utilities.retainWiFiNetwork' | translate }}</div>
                  <div class="toggler" (click)="retainWiFiNetwork = !retainWiFiNetwork">
                    <div class="checkbox">
                      <label
                        class="toggle"
                        [class.checked]="retainWiFiNetwork"
                        [class.disabled]="!plume.isSomeAdminRole()"
                      ></label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="key">
                {{ 'configurations.utilities.neighborsReport' | translate }}
              </div>
              <div class="value">
                <button
                  class="action medium"
                  *ngIf="permissions?.uiFeatures?.neighborsHealthCheck"
                  (click)="openNeighborsReportsModal()"
                >
                  {{ 'configurations.utilities.show' | translate }}
                </button>
              </div>
            </div>

            <div class="row">
              <div class="key">
                {{ 'configurations.utilities.customerGroups' | translate }}
              </div>
              <div class="value">
                <button
                  class="action medium"
                  *ngIf="permissions?.uiFeatures?.neighborsHealthCheck"
                  (click)="openCustomerGroupsModal()"
                >
                  {{ 'configurations.utilities.show' | translate }}
                </button>
              </div>
            </div>

            <div class="row">
              <div class="key">
                {{ 'configurations.utilities.emailVerified' | translate }}
              </div>
              <div class="value">
                <button
                  class="action medium"
                  (click)="changeEmailVerified()"
                  *ngIf="permissions?.uiFeatures.editEmailVerified"
                >
                  {{
                    ((customer$ | async)?.emailVerified
                      ? 'configurations.utilities.changeNotVerified'
                      : 'configurations.utilities.changeToVerified'
                    ) | translate
                  }}
                </button>
              </div>
            </div>

            <div
              class="row"
              *ngIf="permissions?.uiFeatures.resendEmailVerified && (customer$ | async)?.emailVerified !== true"
            >
              <div class="key">
                {{ 'configurations.utilities.emailVerification' | translate }}
              </div>
              <div class="value">
                <button class="action medium" (click)="resendEmailVerified()">
                  {{ 'configurations.utilities.sendVerification' | translate }}
                </button>
              </div>
            </div>

            <div class="row" *ngIf="permissions?.uiFeatures.sendPasswordReset && (customer$ | async)?.emailVerified">
              <div class="key">
                {{ 'configurations.utilities.passwordReset' | translate }}
              </div>
              <div class="value">
                <button class="action medium" (click)="sendPasswordReset()">
                  {{ 'configurations.utilities.sendPassword' | translate }}
                </button>
              </div>
            </div>
            <div class="row">
              <div class="key">{{ 'configurations.wifiradio.resyncController' | translate }}</div>
              <div class="value">
                <button class="action medium" [class.disabled]="!plume.isSomeAdminRole()" (click)="resync()">
                  {{ 'configurations.wifiradio.resync' | translate }}
                </button>
              </div>
            </div>
            <div class="row" *ngIf="plume.cloudVersionAbove1_92()">
              <div class="key">
                {{ 'configurations.utilities.auditLogs' | translate }}
              </div>
              <div class="value">
                <button class="action medium" (click)="showAuditLogsModal = true">
                  {{ 'configurations.utilities.show' | translate }}
                </button>
              </div>
            </div>
            <div class="row" *ngIf="isAdmin && plume.cloudVersionAbove1_108()">
              <div class="key">
                {{ 'configurations.utilities.taskStatuses' | translate }}
              </div>
              <div class="value">
                <button class="action medium" (click)="showTaskStatusesModal = true">
                  {{ 'configurations.utilities.show' | translate }}
                </button>
              </div>
            </div>
            <div class="row" *ngIf="plume.isSomeAdminRole()">
              <div class="key">
                {{ 'configurations.utilities.flashLogging' | translate }}
              </div>
              <div class="value">
                <slider-toggle-mobile-aware
                  [editable]="!flashLoggingPending"
                  [options]="logDestinationItems"
                  (toggle)="action('logDestination', $event)"
                >
                </slider-toggle-mobile-aware>
              </div>
            </div>
          </div>
        </div>
        <div class="generate-logpull">
          <h3>{{ 'configurations.utilities.generateTitle' | translate }}</h3>
          <div class="logpull-form">
            <div class="input-wrap">
              <input
                type="text"
                class="enabled"
                placeholder="{{ 'configurations.utilities.generate.requestMessage' | translate }}"
                [formControl]="messageFormControl"
              />
            </div>
            <div class="controls">
              <button class="action medium" (click)="performLogpull()">
                {{ 'configurations.utilities.submit' | translate }}
              </button>
            </div>
          </div>
        </div>
        <div class="events">
          <h3>{{ 'configurations.utilities.historyList' | translate }}</h3>
          <ng-container *ngIf="!logpullHistory">
            <div class="loading">{{ 'loadingIndicator' | translate }}</div>
          </ng-container>
          <ng-container *ngIf="logpullHistory">
            <div class="header">
              <div class="date">{{ 'configurations.utilities.history.datetime' | translate }}</div>
              <div class="comment">{{ 'configurations.utilities.history.comment' | translate }}</div>
            </div>
            <div class="eventsList">
              <div class="event" *ngFor="let event of logpullHistory">
                <div class="date">{{ event.createdAt }}</div>
                <div class="comment">{{ event.comment }}</div>
                <div class="buttons">
                  <button
                    *ngIf="permissions?.uiFeatures.downloadLogpull"
                    class="action small"
                    (click)="downloadLogpull(event.link)"
                  >
                    {{ 'configurations.utilities.history.download' | translate }}
                  </button>
                  <button class="action small" (click)="copiedLogPull(event.copyLink)">
                    {{ 'configurations.utilities.history.copyLink' | translate }}
                  </button>
                </div>
              </div>
              <div class="event" *ngIf="!logpullHistory.length">
                <div class="noEvents">{{ 'configurations.utilities.history.noHistory' | translate }}</div>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="events problems" *ngIf="isAdmin && isHomePass$ | async">
          <h3>{{ 'configurations.utilities.mobilizeProblems' | translate }}</h3>
          <ng-container *ngIf="!mobilizeProblemsHistory">
            <div class="loading">{{ 'loadingIndicator' | translate }}</div>
          </ng-container>
          <ng-container *ngIf="mobilizeProblemsHistory">
            <div class="header">
              <div class="date">{{ 'configurations.utilities.history.datetime' | translate }}</div>
              <div class="comment">{{ 'configurations.utilities.history.device' | translate }}</div>
            </div>
            <div class="eventsList">
              <div class="event" *ngFor="let event of mobilizeProblemsHistory">
                <div class="date">{{ event.LastModified }}</div>
                <div class="comment">{{ event.Key | deviceNameFromMobilizeUrl : devices }}</div>
                <div class="buttons">
                  <button class="action small" (click)="downloadMobilizeProblem(event.Key)">
                    {{ 'configurations.utilities.history.download' | translate }}
                  </button>
                  <button class="action small" (click)="copiedMobilizeProblem(event.Key)">
                    {{ 'configurations.utilities.history.copyLink' | translate }}
                  </button>
                </div>
              </div>
              <div class="event" *ngIf="!mobilizeProblemsHistory.length">
                <div class="noEvents">{{ 'configurations.utilities.history.noHistory' | translate }}</div>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="columns">
          <div class="column">
            <div class="row">
              <div class="key">
                {{ 'configurations.utilities.shard' | translate }}
              </div>
              <div class="value">
                {{ shardInfo.shard }}
              </div>
            </div>
            <div class="row">
              <div class="key">
                {{ 'configurations.utilities.shardUrl' | translate }}
              </div>
              <div class="value wrap">
                {{ shardInfo.httpUrlBase }}
              </div>
            </div>
            <div class="row">
              <div class="key">
                {{ 'configurations.utilities.globalInventory' | translate }}
              </div>
              <div class="value">
                <button
                  class="action medium"
                  [class.disabled]="!permissions?.uiFeatures.globalInventory"
                  (click)="openGlobalInventoryModal()"
                >
                  {{ 'configurations.utilities.show' | translate }}
                </button>
              </div>
            </div>
            <div class="row" [class.disable]="isUprise$ | async" *ngIf="showAccountMigrateOption">
              <div class="key">
                {{ 'configurations.utilities.accountMigration' | translate }}
              </div>
              <div class="value" *ngIf="migrate">
                <button class="action medium" *ngIf="migrate.migrated" (click)="migrateAccount()">
                  {{ 'configurations.utilities.rollback' | translate : { cloud: migrate.targetCloud } }}
                </button>
                <button class="action medium" *ngIf="!migrate.migrated" (click)="migrateAccount()">
                  {{ 'configurations.utilities.migrate' | translate : { cloud: migrate.targetCloud } }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </collapsiblebox>
  </div>
</div>

<neighborsreport [triggerNeighborsReports]="showNeighborsReportsModal"></neighborsreport>

<modal (overlayAction)="showCustomerGroupsModal = $event" *ngIf="showCustomerGroupsModal">
  <ng-container title>{{ 'customerGroups.title' | translate }}</ng-container>
  <ng-container content>
    <customergroups></customergroups>
  </ng-container>
  <ng-container controls>
    <button class="tertiary light" (click)="showCustomerGroupsModal = false">
      {{ 'customerGroups.close' | translate }}
    </button>
  </ng-container>
</modal>

<modal mode="wide" (overlayAction)="showAuditLogsModal = $event" *ngIf="showAuditLogsModal">
  <ng-container title>{{ 'configurations.utilities.auditLogs' | translate }}</ng-container>
  <ng-container content>
    <auditlogs></auditlogs>
  </ng-container>
  <ng-container controls>
    <button class="tertiary light" (click)="showAuditLogsModal = false">
      {{ 'customerGroups.close' | translate }}
    </button>
  </ng-container>
</modal>

<modal mode="wide" (overlayAction)="showTaskStatusesModal = $event" *ngIf="showTaskStatusesModal">
  <ng-container title>{{ 'configurations.utilities.taskStatuses' | translate }}</ng-container>
  <ng-container content>
    <taskstatuses></taskstatuses>
  </ng-container>
  <ng-container controls>
    <button class="tertiary light" (click)="showTaskStatusesModal = false">
      {{ 'customerGroups.close' | translate }}
    </button>
  </ng-container>
</modal>
