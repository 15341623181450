import { Component, OnInit, ɵbypassSanitizationTrustHtml as bypassSanitizationTrustHtml } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Observable, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map, pluck, share, switchMap, tap } from 'rxjs/operators';
import {
  DataSetGroup,
  DataWithPosition,
  GraphDataSet,
  GraphDataSetComputed,
  IntervalToolTipData
} from 'src/app/lib/graph/graph.interface';
import { ITrafficClassStatsTimeBased, ITrafficLegend } from 'src/app/lib/interfaces/interface';
import { SpeedConverterService } from 'src/app/lib/services/speed-converter.service';
import { TrafficService } from 'src/app/lib/services/traffic.service';
import { selectDevices } from 'src/app/store/polling/polling.selector';

interface IDataUsageHoverObject {
  title: string;
  color: string;
  time: string;
  usage: string;
  latencyMin: string;
  latencyAvg: string;
  latencyMax: string;
  throughputMin: string;
  throughputMax: string;
}

@Component({
  selector: 'traffic-device',
  templateUrl: './traffic-device.component.html',
  styleUrls: ['./traffic-device.component.scss']
})
export class TrafficDeviceComponent implements OnInit {
  legends = this.trafficService.legend.sort((a, b) => a.order - b.order);
  devices$ = this.store.select(selectDevices).pipe(filter((devices) => !!devices));
  device$ = combineLatest([(this.route.params as Observable<{ mac: string }>).pipe(pluck('mac')), this.devices$]).pipe(
    map(([mac, devices]) => devices?.find((device) => device.mac === mac)),
    tap((device) => {
      if (!device) {
        this.router.navigate(['traffic', 'pieMonitoring'], { relativeTo: this.route.parent });
      }
    })
  );

  selectedTime$ = this.route.fragment.pipe(
    map((fragment: '15m' | '24h' | '7d' | '30d') => (['15m', '24h', '7d', '30d'].includes(fragment) ? fragment : '30d'))
  );
  timeItems$ = this.selectedTime$.pipe(
    map((time) =>
      [
        { value: '15m' as const, translation: 'traffic.m15' },
        { value: '24h' as const, translation: 'traffic.h24' },
        { value: '7d' as const, translation: 'traffic.d7' },
        { value: '30d' as const, translation: 'traffic.d30' }
      ].map((item) => ({
        ...item,
        selected: item.value === time
      }))
    )
  );
  trafficStats$ = combineLatest([
    this.device$.pipe(
      distinctUntilChanged((a, b) => a?.mac === b?.mac),
      filter((device) => !!device)
    ),
    this.selectedTime$
  ]).pipe(
    switchMap(([device, time]) =>
      this.trafficService
        .trafficClassStats$(
          this.trafficService.granularity(time),
          this.startTime(time),
          this.endTime(time),
          [device.mac],
          this.trafficService.xAxisItemCount(time)
        )
        .pipe(map((stats) => ({ stats, xAxisItemCount: this.trafficService.xAxisItemCount(time), timeType: time })))
    ),
    share()
  );

  dataUsageDS$ = this.trafficStats$.pipe(
    map(({ stats, xAxisItemCount, timeType }) => this.dataUsageDsMapper(stats, xAxisItemCount, timeType)),
    share()
  );

  qoeDS$ = this.trafficStats$.pipe(
    map(({ stats, xAxisItemCount, timeType }) => this.classQoEDsMapper(stats, xAxisItemCount, timeType)),
    share()
  );

  dataUsageHoveredData: IDataUsageHoverObject = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store,
    private speedConverter: SpeedConverterService,
    private trafficService: TrafficService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {}

  changeDevice(mac: string): void {
    this.router.navigate(['..', mac], { relativeTo: this.route, preserveFragment: true });
  }

  changeTime(time: '15m' | '24h' | '7d' | '30d'): void {
    this.router.navigate([], { fragment: time, replaceUrl: true });
  }

  openPieMonitoring(): void {
    this.router.navigate(['traffic', 'pieMonitoring'], { relativeTo: this.route.parent });
  }

  dataUsageHover(
    data:
      | { point: DataWithPosition; dataSet: GraphDataSetComputed }
      | { bar: IntervalToolTipData; dataSet: GraphDataSetComputed }
  ): void {
    if ('bar' in data) {
      this.dataUsageHoveredData = data?.bar?.yInterval
        ? data.bar.yInterval[data.bar.activeYIntervalIndex].dataObject
        : null;
    }
  }

  private startTime(time: '15m' | '24h' | '7d' | '30d'): string {
    switch (time) {
      case '15m':
        return moment().utc().subtract(15, 'minutes').toISOString();
      case '24h':
        return moment().utc().subtract(24, 'hours').toISOString();
      case '7d':
        return moment().utc().subtract(7, 'days').toISOString();
      case '30d':
        return moment().utc().subtract(30, 'days').toISOString();
    }
  }

  private endTime(time: '15m' | '24h' | '7d' | '30d'): string {
    switch (time) {
      case '15m':
        return moment().utc().toISOString();
      case '24h':
        return moment().utc().toISOString();
      case '7d':
        return moment().utc().toISOString();
      case '30d':
        return moment().utc().toISOString();
    }
  }

  private dataUsageDsMapper(
    data: ITrafficClassStatsTimeBased,
    xAxisItemCount: number,
    timeType: '15m' | '24h' | '7d' | '30d'
  ): DataSetGroup[] | undefined {
    if (data.devices.length < 1) {
      return undefined;
    }

    return [
      {
        startFromZero: true,
        autoScale: true,
        autoScaleBase1024: true,
        dataSets: [
          {
            type: 'segmentedIntervalBar',
            color: '',
            xLabelStart: 0,
            xLabelStep: 1,
            xStepVisualization: 'marker',
            yStepVisualization: 'line',
            xTextPosition: 'forceStart',
            yTextPosition: 'start',
            xStart: timeType === '30d' ? -1 : -0.5,
            xEnd: this.trafficService.xAxisItemCount(timeType) - 0.5,
            xValText: (val) => this.xAxisValue(this.xAxisMoment(val, data.startTime, data.granularity), val, timeType),
            yValText: (val) =>
              val === 0
                ? '0'
                : this.speedConverter.formatValue(this.speedConverter.convertToOptimalStorageValue(val, 'B', 1)),
            toolTipHtml: () => '',
            data: new Array(xAxisItemCount).fill(undefined).map((_, currentX) => {
              const yInterval = data.devices[0].trafficClasses
                .map((trafficClass) => ({
                  ...trafficClass,
                  legend: this.trafficService.legend.find(
                    (x) => x.id === trafficClass.name || x.alternativeId === trafficClass.name
                  )
                }))
                .filter((trafficClass) => trafficClass.legend)
                .sort((a, b) => (a.legend.order < b.legend.order ? 1 : -1))
                .map((trafficClass) => {
                  const usage =
                    (trafficClass?.stats?.inNum?.length ? trafficClass.stats.inNum[currentX] : 0) +
                    (trafficClass?.stats?.outNum?.length ? trafficClass.stats.outNum[currentX] : 0);

                  return {
                    id: trafficClass.name,
                    usage,
                    trafficClass
                  };
                })
                .filter((data) => data.usage > 0)
                .reduce(
                  (acc, item) => [
                    ...acc,
                    {
                      start: acc[acc.length - 1]?.end || 0,
                      end: (acc[acc.length - 1]?.end || 0) + item.usage,
                      color: item.trafficClass.legend.color,
                      dataObject: this.usageDataObject(
                        currentX,
                        item.trafficClass.legend,
                        item.usage,
                        item.trafficClass.stats,
                        this.xAxisMoment(currentX, data.startTime, data.granularity)
                      )
                    }
                  ],
                  [] as GraphDataSet['data'][0]['yInterval']
                );

              return {
                xVal: currentX,
                yVal: yInterval[yInterval.length - 1]?.end || 1,
                yInterval
              };
            })
          }
        ]
      }
    ];
  }

  private classQoEDsMapper(
    data: ITrafficClassStatsTimeBased,
    xAxisItemCount: number,
    timeType: '15m' | '24h' | '7d' | '30d'
  ): { name: string; dataSet: DataSetGroup[] }[] {
    if (data.devices.length < 1) {
      return [];
    }

    return this.legends.map((legend) => {
      const trafficClass = data.devices[0].trafficClasses.find(
        (trafficClass) => trafficClass.name === legend.id || trafficClass.name === legend.alternativeId
      );

      return {
        name: legend.label,
        dataSet: [
          {
            startFromZero: true,
            autoScale: false,
            dataSets: [
              {
                type: 'segmentedIntervalBar',
                color: '',
                xLabelStart: 0,
                xLabelStep: 1,
                xStepVisualization: 'marker',
                yStepVisualization: 'line',
                xTextPosition: 'forceStart',
                yTextPosition: 'start',
                xStart: timeType === '30d' ? -1 : -0.5,
                xEnd: this.trafficService.xAxisItemCount(timeType) - 0.5,
                yStart: 0,
                yLabelStart: 0,
                yEnd: 100,
                yLabelStep: 20,
                xValText: (val) =>
                  this.xAxisValue(this.xAxisMoment(val, data.startTime, data.granularity), val, timeType),
                yValText: (val) => `${val}%`,
                toolTipHtml: (val: IntervalToolTipData) => bypassSanitizationTrustHtml(
                    `
                    <span style="font-size: 12px">
                    <div style="font-weight: 700">${this.xAxisValue(
                      this.xAxisMoment(val.xVal, data.startTime, data.granularity),
                      val.xVal,
                      timeType,
                      true
                    )}</div>
                    <span style="color: ${val.yInterval[val.activeYIntervalIndex].color}">${this.translate.instant(
                      val.yInterval[val.activeYIntervalIndex].dataObject.group
                    )}</span>: ${Math.round(val.yInterval[val.activeYIntervalIndex].dataObject.value)}%
                    </span>
                    `
                  ),
                data: new Array(xAxisItemCount).fill(undefined).map((_, currentX) => {
                  if (!trafficClass) {
                    return { xVal: currentX, yVal: 0, yInterval: [] };
                  }

                  const goodStat = trafficClass?.stats?.good?.length ? trafficClass.stats.good[currentX] : 0;
                  const badStat = trafficClass?.stats?.bad?.length ? trafficClass.stats.bad[currentX] : 0;
                  const unstableStat = trafficClass?.stats?.unstable?.length
                    ? trafficClass.stats.unstable[currentX]
                    : 0;
                  const statSampleCount = trafficClass?.stats?.sampleCount?.length
                    ? trafficClass.stats.sampleCount[currentX]
                    : 0;

                  const good = statSampleCount ? (100 * goodStat) / statSampleCount : 0;
                  const bad = statSampleCount ? (100 * badStat) / statSampleCount : 0;
                  const unstable = statSampleCount ? (100 * unstableStat) / statSampleCount : 0;

                  return {
                    xVal: currentX,
                    yVal: 0,
                    yInterval: [
                      ...(bad
                        ? [
                            {
                              start: 0,
                              end: bad,
                              color: '#FA1478',
                              dataObject: {
                                group: 'traffic.bad',
                                value: bad
                              }
                            }
                          ]
                        : []),
                      ...(unstable
                        ? [
                            {
                              start: bad,
                              end: bad + unstable,
                              color: '#FFC500',
                              dataObject: {
                                group: 'traffic.moderate',
                                value: unstable
                              }
                            }
                          ]
                        : []),
                      ...(good
                        ? [
                            {
                              start: bad + unstable,
                              end: bad + unstable + good,
                              color: '#17E3AE',
                              dataObject: {
                                group: 'traffic.good',
                                value: good
                              }
                            }
                          ]
                        : [])
                    ]
                  };
                })
              }
            ]
          }
        ]
      };
    });
  }

  private usageDataObject(
    xVal: number,
    legend: ITrafficLegend,
    usage: number,
    stats: ITrafficClassStatsTimeBased['devices'][0]['trafficClasses'][0]['stats'],
    time: moment.Moment
  ): IDataUsageHoverObject {
    return {
      title: legend.label,
      color: legend.color,
      time: time.format('LT on L'),
      usage: this.speedConverter.formatValue(this.speedConverter.convertToOptimalStorageValue(usage, 'B')),
      latencyAvg: this.latencyCorrection(Math.round(stats.latencyAvg[xVal] || 0)),
      latencyMax: this.latencyCorrection(stats.latencyMax[xVal]),
      latencyMin: this.latencyCorrection(stats.latencyMin[xVal]),
      throughputMax: this.speedConverter.formatValue(
        this.speedConverter.convertToOptimalSpeedValue(Math.max(stats.inMax[xVal], stats.outMax[xVal]), 'Kb', 0),
        'perSeconds'
      ),
      throughputMin: this.speedConverter.formatValue(
        this.speedConverter.convertToOptimalSpeedValue(Math.min(stats.inMin[xVal], stats.outMin[xVal]), 'Kb', 0),
        'perSeconds'
      )
    };
  }

  private xAxisMoment(
    index: number,
    startTime: string,
    granularity: '1 minute' | '15 minutes' | '1 hour' | '1 day'
  ): moment.Moment {
    switch (granularity) {
      case '1 minute':
        return moment(startTime).add(index, 'minutes');
      case '15 minutes':
        return moment(startTime).add(index * 15, 'minutes');
      case '1 hour':
        return moment(startTime).add(index, 'hours');
      case '1 day':
        return moment(startTime).add(index, 'days');
    }
  }

  private xAxisValue(
    val: moment.Moment,
    index: number,
    timeType: '15m' | '24h' | '7d' | '30d',
    showAll: boolean = false
  ): string {
    switch (timeType) {
      case '15m':
        if (showAll) {
          return val.locale(navigator.language).format('LT');
        } else {
          return index % 2 === 0 ? val.locale(navigator.language).format('LT') : '';
        }
      case '24h':
        if (showAll) {
          return val.locale(navigator.language).format('LT');
        } else {
          return (index - 1) % 2 === 0 ? val.locale(navigator.language).format('LT') : '';
        }
      case '7d':
        return val.locale(navigator.language).format('L');
      case '30d':
        if (showAll) {
          return val.locale(navigator.language).format('L');
        } else {
          return (index + 1) % 4 === 1 ? val.locale(navigator.language).format('L') : '';
        }
    }
  }

  private latencyCorrection(value: number): string {
    return value > 0 ? value + ' ms' : 'traffic.na';
  }
}
