import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { GraphCalculationsService } from '../graph-calculations/graph-calculations.service';
import { DataWithPosition, GraphDataSetComputed, IntervalToolTipData } from '../graph.interface';

@Component({
  selector: '[flm-graph-segmented-interval-bar]',
  templateUrl: './graph-segmented-interval-bar.component.html',
  styleUrls: ['./graph-segmented-interval-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GraphSegmentedIntervalBarComponent {
  constructor(public calculations: GraphCalculationsService) {}
  @Input() dataSet?: GraphDataSetComputed;
  @Output() barHover = new EventEmitter<IntervalToolTipData | undefined>();

  hover(data: DataWithPosition | undefined, index?: number): void {
    this.barHover.emit(data ? { ...data, activeYIntervalIndex: index } : undefined);
  }

  path(
    data: DataWithPosition,
    first: boolean,
    last: boolean,
    interval: {
      start: number;
      end: number;
      startPos: number;
      endPos: number;
      color?: string;
      dataObject?: any;
    }
  ): string {
    const width = 10;
    if (first && last) {
      return `
        M ${data.xPos - width / 2} ${interval.endPos + width / 2}
        V ${interval.startPos - width / 2}
        q 0 ${width / 2} ${width / 2} ${width / 2}
        q ${width / 2} 0 ${width / 2} -${width / 2}
        V ${interval.endPos + width / 2}
        q 0 -${width / 2} -${width / 2} -${width / 2}
        q -${width / 2} 0 -${width / 2} ${width / 2}
        z
      `;
    }
    if (first) {
      return `
        M ${data.xPos - width / 2} ${interval.endPos + 0.5}
        V ${interval.startPos - width / 2}
        q 0 ${width / 2} ${width / 2} ${width / 2}
        q ${width / 2} 0 ${width / 2} -${width / 2}
        V ${interval.endPos + 0.5}
        z
      `;
    }
    if (last) {
      return `
      M ${data.xPos - width / 2} ${interval.endPos + width / 2 }
      q 0 -${width / 2} ${width / 2} -${width / 2}
      q ${width / 2} 0 ${width / 2} ${width / 2}
      V ${interval.startPos - interval.endPos > width / 2 ? interval.startPos : interval.endPos + width / 2}
      h -${width}
      V ${interval.endPos + width / 2}
      z
    `;
    }
    return `
        M ${data.xPos - width / 2} ${interval.endPos + 0.5}
        V ${interval.startPos}
        h ${width}
        V ${interval.endPos + 0.5}
        z
      `;
  }
}
