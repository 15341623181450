<div class="overlap" *ngIf="series">
  <seriesVisual [series]="series" *ngIf="!scale?.thresholdValue"></seriesVisual>
  <seriesVisual
    [series]="series"
    threshold="charts.force.legend.threshold"
    alarm="charts.force.legend.alarm"
    *ngIf="scale?.thresholdValue"
  ></seriesVisual>
</div>
<div class="graphBox">
  <svg #chartElm (mousewheel)="safari14fix()">
    <clipPath [attr.id]="id + 'areaClip'">
      <rect x="0" y="0" [attr.width]="width" [attr.height]="height"></rect>
    </clipPath>
    <clipPath [attr.id]="id + 'pointClip'">
      <rect x="-7" y="-7" [attr.width]="width + 14" [attr.height]="height + 14"></rect>
    </clipPath>
    <g class="inner_space" [attr.transform]="'translate(' + margin.left + ',' + margin.top + ')'">
      <g #axisY></g>
      <g #axisX [attr.transform]="'translate(0,' + height + ')'"></g>
      <g>
        <g [attr.clip-path]="'url(#' + id + 'areaClip)'" *ngFor="let line of dataSource">
          <path [attr.fill]="line.series?.color" [attr.d]="line.fill" opacity="0.5"></path>
          <path fill="none" stroke-width="3" [attr.stroke]="line.series?.color" [attr.d]="line.d"></path>
        </g>
        <line
          *ngIf="scale?.thresholdValue && yAxisScale"
          class="threshold"
          [attr.x1]="0"
          [attr.x2]="width"
          [attr.y1]="yAxisScale(this.scale.thresholdValue)"
          [attr.y2]="yAxisScale(this.scale.thresholdValue)"
        ></line>
        <g [attr.clip-path]="'url(#' + id + 'pointClip)'" *ngFor="let line of dataSource">
          <ng-container *ngFor="let dot of line.alarmAwareDots">
            <circle class="dot" [attr.cx]="dot.x" [attr.cy]="dot.y" r="3" [attr.fill]="line.series.color"></circle>
            <circle class="alarm" [attr.cx]="dot.x" [attr.cy]="dot.y" [attr.r]="6" *ngIf="dot.alarm">
              <svg:animate attributeName="r" dur="1s" repeatCount="indefinite" values="3;6;3"></svg:animate>
            </circle>
          </ng-container>
        </g>
      </g>
      <g class="quality" *ngIf="scale?.thresholdValue">
        <text [attr.transform]="'translate(' + width + ', 0)'">
          {{ (scale.above ? 'qoe.charts.lowerQuality' : 'qoe.charts.higherQuality') | translate }}
        </text>
        <text [attr.transform]="'translate(' + width + ',' + (height - 5) + ')'">
          {{ (scale.above ? 'qoe.charts.higherQuality' : 'qoe.charts.lowerQuality') | translate }}
        </text>
      </g>
      <rect
        #zoomRect
        class="zoom"
        fill="transparent"
        [attr.width]="width"
        [attr.height]="height"
        (mouseleave)="toolTip.show = false"
        (mousemove)="reloadToolTipIndex($event); toolTip.show = true"
      ></rect>
    </g>
  </svg>

  <div
    class="tooltip"
    *ngIf="toolTip.show && toolTip.index >= 0 && toolTip.dataAvailable"
    [style.left.px]="toolTip.xPos + margin.left"
  >
    <ng-container *ngFor="let line of dataSource">
      <div class="line" *ngIf="line.allData[toolTip.index].value === 0 || line.allData[toolTip.index].value">
        <div class="circle" [style.backgroundColor]="line.series.color"></div>
        {{ line.series.translation | translate }}:
        {{
          line.allData[toolTip.index].value > 1
            ? (line.allData[toolTip.index].value | number: '1.0-2')
            : (line.allData[toolTip.index].value | number: '1.0-5')
        }}
        {{ scale.symbol }}
      </div>
    </ng-container>
  </div>
</div>
