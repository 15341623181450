import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'input-buttons',
  templateUrl: './input-buttons.component.html',
  styleUrls: ['./input-buttons.component.scss']
})
export class InputButtonsComponent {
  @Input() disabled = false;
  @Input() disableConfirm = false;
  @Input() editState = false;
  @Input() tooltipEdit = 'techdash.tooltip.edit';
  @Input() tooltipSet = 'techdash.tooltip.set';
  @Input() tooltipCancel = 'techdash.tooltip.cancel';
  @Output() editStateChange = new EventEmitter<boolean>();
  @Output() action = new EventEmitter<'set' | 'close'>();

  set(): void {
    if (!this.disableConfirm) {
      this.editState = false;
      this.editStateChange.emit(this.editState);
      this.action.emit('set');
    }
  }

  close(): void {
    this.editState = false;
    this.editStateChange.emit(this.editState);
    this.action.emit('close');
  }

  startEditing(): void {
    this.editState = true;
    this.editStateChange.emit(this.editState);
  }
}
