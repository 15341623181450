<ng-container *ngFor="let group of calculations.groupedDatasets; let gi = index">
  <ng-container *ngFor="let dataset of group.dataSets; let di = index">
    <svg:defs>
      <linearGradient [id]="'barGradient' + id + '-' + gi + '-' + di" gradientTransform="rotate(90)">
        <stop
          offset="5%"
          [attr.stop-color]="dataset.barTopColor || calculations.increaseBrightness(dataset.color, 50)"
        />
        <stop offset="95%" [attr.stop-color]="dataset.color" />
      </linearGradient>
    </svg:defs>
    <svg:g
      flm-graph-bar
      *ngIf="dataset.type === 'bar'"
      class="container"
      [attr.fill]="'url(\'#barGradient' + id + '-' + gi + '-' + di + '\')'"
      [style.color]="dataset.hoverColor"
      [dataSet]="dataset"
      (barHover)="barHover.emit({ bar: $event, dataSet: dataset })"
    ></svg:g>
  </ng-container>
</ng-container>
