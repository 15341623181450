<div class="header">
  <icon path="assets/logo/plume_logo.svg"></icon>
  <button
    class="tertiary light"
    (click)="openPreview()"
    *ngIf="(previewMode$ | async) === 'closed' && (publishing$ | async) === false"
  >
    {{ 'captiveportal.preview' | translate }}
  </button>
  <button
    class="tertiary light"
    (click)="closePreview()"
    *ngIf="(previewMode$ | async) !== 'closed' && (publishing$ | async) === false"
  >
    {{ 'captiveportal.back' | translate }}
  </button>
</div>
<div class="content" [class.disabled]="(previewMode$ | async) !== 'closed' || (publishing$ | async)" cdkScrollable>
  <span class="title">
    {{ ((isUprise$ | async) === true ? 'captiveportal.upriseTitle' : 'captiveportal.title') | translate }}
  </span>
  <captive-info></captive-info>
  <captive-options></captive-options>
  <captive-advanced></captive-advanced>
</div>
<div class="footer" *ngIf="(publishing$ | async) === false">
  <button class="super-primary" (click)="publish()">{{ 'captiveportal.publish' | translate }}</button>
</div>
<div class="adblocker" [class.show]="adblocker">
  {{ 'captiveportal.adblock' | translate }}
  <span *ngFor="let url of unblock">{{ url }}</span>
  {{ 'captiveportal.reload' | translate }}
</div>
