<div class="bubble">
  <div class="message">
    {{ 'health.bubbles.speedTestBubble.lastOptimization' | translate }} {{ optimization | translate }}.<br />
    {{ 'health.bubbles.speedTestBubble.testSpeedExtender' | translate }}
  </div>

  <div class="row optButtonRow">
    <div class="optButtonCol">
      <div
        class="tsButton"
        (click)="doTestExtender()"
        [class.disabled]="stepState === 'busy' || stepState === 'finished'"
      >
        <span *ngIf="stepState === 'busy'" class="testRunning">
          {{ 'health.bubbles.speedTestBubble.testing' | translate }}
        </span>
        <span *ngIf="stepState === 'init'" class="testButton">
          {{ 'health.bubbles.speedTestBubble.testSpeed' | translate }}
        </span>
        <span *ngIf="stepState === 'fail'" class="fail">
          {{ 'health.bubbles.speedTestBubble.testFailed' | translate }}<br />
        </span>
        <span *ngIf="stepState === 'fail'" class="failMsg">
          {{ 'health.bubbles.speedTestBubble.retry' | translate }}
        </span>
        <span *ngIf="stepState === 'finished'" class="testButton">
          {{ 'health.bubbles.speedTestBubble.testComplete' | translate }}
        </span>
      </div>
    </div>
    <div class="rebootMsgBlock">
      <div class="sidePane" *ngIf="stepState === 'busy'">
        <div class="sideMsg">{{ 'health.bubbles.speedTestBubble.runningTest' | translate }}</div>
        <div>{{ 'health.bubbles.speedTestBubble.mayTakeUpTo' | translate }}</div>
      </div>

      <div class="sidePane" *ngIf="stepState === 'finished'">
        <div class="sideMsg">{{ 'health.bubbles.speedTestBubble.speedTestComplete' | translate }}</div>
        <div>
          {{ 'health.bubbles.speedTestBubble.download' | translate }}: {{ round(speedtest.download) }} Mbps<br />
          {{ 'health.bubbles.speedTestBubble.upload' | translate }}: {{ round(speedtest.upload) }} Mbps
        </div>
      </div>
    </div>
  </div>
  <div class="progressbar progressbar--bottom" *ngIf="showProgress"><div class="indeterminate"></div></div>
</div>
