<div class="row" id="conf-capabilities">
  <div class="col-xs-12">
    <collapsiblebox [show]="expand" [collapsible]="true" (toggle)="toggleExpand()">
      <div header>{{ 'configurations.capabilities.title' | translate }}</div>
      <div container *ngIf="expand">
        <div class="columns">
          <div class="column">
            <div class="offlineText" *ngIf="isOffline === true">
              {{ 'configurations.capabilities.locationIsOffline' | translate }}
            </div>
            <slider-toggle-mobile-aware
              *ngIf="filterItems"
              [options]="filterItems"
              (toggle)="viewSetFilter($event)"
            ></slider-toggle-mobile-aware>
            <ng-container *ngIf="capView && filterType !== 'compressed'">
              <ng-container *ngFor="let cap of capView">
                <div class="row">
                  <div class="key">{{ cap.translate | translate }}</div>
                  <div class="value" [tooltip]="cap.reason | translate" [maxWidth]="400">
                    <ng-container *ngIf="cap.capable">
                      <div class="circle green"></div>
                      <div>{{ 'configurations.capabilities.capable' | translate }}</div>
                    </ng-container>
                    <ng-container *ngIf="!cap.capable">
                      <div class="circle red"></div>
                      <div>{{ 'configurations.capabilities.notCapable' | translate }}</div>
                    </ng-container>
                  </div>
                </div>
              </ng-container>
            </ng-container>
            <div class="compressed" *ngIf="capView && filterType === 'compressed'">
              <ng-container *ngFor="let cap of capView">
                <span
                  [class.capable]="cap.capable"
                  [class.notCapable]="!cap.capable"
                  [tooltip]="cap.reason | translate"
                  [maxWidth]="400"
                >
                  {{ cap.sortStr }}
                </span>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </collapsiblebox>
  </div>
</div>
