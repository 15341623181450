import { scaleLinear, scaleTime } from 'd3';
import { Bar } from '../objects/bar';
import { Tick } from '../objects/tick';
import * as moment from 'moment';

export class RecentEventsChart {
  private data: any;
  private bars: Bar[];
  private width: number;
  private height: number;
  private xScale: any;
  private yScale: any;
  private margins: any = {
    top: 100,
    right: 80,
    bottom: 60,
    left: 80
  };

  constructor() {}

  calculateWidth(): number {
    return this.width ? this.width - this.margins.left - this.margins.right : 0;
  }

  calculateHeight(): number {
    return this.height ? this.height - this.margins.top - this.margins.bottom : 0;
  }

  getMargins(): any {
    return this.margins;
  }

  prepareScales(): void {
    this.xScale = scaleTime().range([0, this.calculateWidth()]);
    this.yScale = scaleLinear().range([this.calculateHeight(), 0]);
  }

  prepareDomains(): void {
    this.xScale.domain([
      this.data.hours[0].timestamp.getTime(),
      this.data.hours[this.data.hours.length - 1].timestamp.getTime() + 60 * 60 * 1000
    ]);

    if (this.data.maxCount) {
      this.yScale.domain([0, this.data.maxCount]);
    } else {
      this.yScale.domain([0, 1]);
    }
  }

  calculateBars(): void {
    this.bars = this.data.hours.map((bar: any) => {
      const width = this.calculateWidth() / this.data.hours.length;
      bar.x = this.xScale(new Date(bar.timestamp));
      bar.y = this.yScale(bar.group.length);
      bar.width = width;
      bar.height = this.calculateHeight() - this.yScale(bar.group.length);
      return bar;
    });
  }

  xAxis(): Tick[] {
    const width = this.calculateWidth() / this.data.hours.length;
    const ticks = [];

    this.data.hours.map((hour: any, index: number) => {
      if (
        (this.width < 600 && index % 8 === 0) ||
        (this.width >= 600 && this.width < 950 && index % 4 === 0) ||
        (this.width >= 950 && index % 2 === 0)
      ) {
        ticks.push(
          new Tick(
            'xaxis',
            moment(hour.timestamp).format('LT'),
            'translate(' +
              (this.xScale(new Date(hour.timestamp)) + width / 2) +
              ',' +
              (this.calculateHeight() + 20) +
              ')'
          )
        );
      }
    });

    return ticks;
  }

  yAxis(): Tick[] {
    const ticks = this.data.maxCount < 5 ? this.data.maxCount : 5;

    return this.yScale.ticks(!ticks ? 1 : ticks).map((tick: any) => new Tick('yaxis', tick, 'translate(0, ' + this.yScale(tick) + ')', { dx: -10, dy: 4 }, true, {
        x2: this.calculateWidth()
      }));
  }

  update(data: any, width?: number, height?: number, margins?: any): Bar[] {
    this.data = data;

    if (width) {
      this.width = width;
    }
    if (height) {
      this.height = height;
    }
    if (margins) {
      this.margins = margins;
    }

    this.prepareScales();
    this.prepareDomains();

    this.calculateBars();

    return this.bars;
  }
}
