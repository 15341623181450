<div class="loading" *ngIf="data && data[0]" [class.show]="!data[0].data || !data[0].data.length">
  <div *ngIf="!data[0].class" class="text">{{ 'nodechart.loadingEvents' | translate }}</div>
  <div *ngIf="data[0].class && data[0].data && !data[0].data.length" class="text">
    {{ 'nodechart.noEvents' | translate }}
  </div>
</div>
<svg *ngIf="width">
  <defs>
    <clipPath id="strip-{{ id }}">
      <rect x="0" y="-10" [attr.width]="width" [attr.height]="height + 10" />
    </clipPath>
  </defs>
  <svg:g [attr.transform]="'translate(' + margins.left + ',' + margins.top + ')'">
    <svg:g
      *ngFor="let tick of xAxis"
      [attr.class]="tick.class"
      [tickVisual]="tick"
      [marginLeft]="margins.left"
      [xPreventOverflow]="true"
    ></svg:g>
    <svg:g [attr.class]="tick.class" [tickVisual]="tick" *ngFor="let tick of yAxis"></svg:g>
    <svg:rect class="axis" [attr.x]="0" [attr.y]="-10" [attr.width]="1" [attr.height]="height + 10"></svg:rect>
    <svg:rect class="axis" [attr.x]="0" [attr.y]="height" [attr.width]="width" [attr.height]="1"></svg:rect>
    <svg:g *ngFor="let line of lines" [ngStyle]="{ 'clip-path': 'url(#strip-' + id + ')' }">
      <svg:path
        *ngFor="let fill of line.fills"
        [attr.d]="fill"
        [attr.fill]="line.series.color"
        [style.opacity]="0.5"
      ></svg:path>
      <svg:g
        *ngFor="let path of line.paths"
        [attr.class]="line.class"
        [pathVisual]="path"
        [style]="{ stroke: line.series.color, strokeWidth: 2 }"
      ></svg:g>
      <svg:circle
        *ngFor="let dot of line.dots"
        [class.show]="dot.show"
        [attr.class]="dot.class"
        [attr.fill]="dot.series.color"
        [attr.r]="dot.show ? dot.r : dot.r / 2"
        [attr.cx]="dot.cx"
        [attr.cy]="dot.cy"
      ></svg:circle>
    </svg:g>
  </svg:g>
</svg>
<div class="tooltips" [style.left.px]="margins.left">
  <div
    *ngFor="let tooltip of tooltips"
    class="region"
    [ngStyle]="tooltip.region"
    (mouseenter)="showTooltip(tooltip, true)"
    (mouseleave)="showTooltip(tooltip, false)"
  >
    <div class="info" [ngStyle]="tooltip.style" *ngIf="tooltip.show">
      <div class="date">{{ tooltip.date }}</div>
      <div class="data" *ngFor="let data of tooltip.data">
        <div class="circle" [style.backgroundColor]="data.color"></div>
        <ng-container *ngIf="scale.symbol !== 'MB'">
          {{ data.series | translate }}: &nbsp;<b>{{ data.value }}</b> &nbsp;{{ scale.symbol }}
        </ng-container>
        <ng-container *ngIf="scale.symbol === 'MB'">
          {{ data.series | translate }}: &nbsp;<b>{{ data.formatted.value }}</b> &nbsp;{{ data.formatted.unit }}
        </ng-container>
      </div>
    </div>
  </div>
</div>
