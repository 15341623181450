<div class="scroll-container">
  <table>
    <tr class="headers">
      <td class="description">{{ 'health.networkInformation.modal.description' | translate }}</td>
      <td class="type">{{ 'health.networkInformation.modal.type' | translate }}</td>
      <td class="devices">{{ 'health.networkInformation.modal.advanced' | translate }}</td>
      <td class="disabled">{{ 'health.networkInformation.modal.disabled' | translate }}</td>
      <td class="password">{{ 'health.networkInformation.modal.password' | translate }}</td>
      <td></td>
    </tr>
    <tr *ngFor="let key of network?.keys; trackBy: trackHomepass">
      <td class="description" [tooltip]="key?.description" [onlyOverflowText]="true">
        <div class="content">
          <ng-container *ngIf="key.accessZone !== 'guests'">
            {{ 'health.networkInformation.modal.' + key.accessZone | translate }}
          </ng-container>
          <ng-container *ngIf="key.accessZone === 'guests'">{{ key.description }}</ng-container>
        </div>
      </td>
      <td class="type">{{ 'health.networkInformation.modal.' + key.accessZone | translate }}</td>
      <td class="advanced">
        <icon
          name="icon-wrench"
          (click)="openAdvanced(key.accessibleDevices, key.expiresAt, key.accessZone === 'guests', true)"
          *ngIf="key.accessZone !== 'home'"
        ></icon>
      </td>
      <td class="disabled">
        <slidetoggler [options]="key.disabledItems" [editable]="false"></slidetoggler>
      </td>
      <td class="password">
        <span *ngIf="!key.editing">***********</span>
        <input
          id="homepass-{{ key.id }}"
          class="enabled"
          [class.error]="key.error"
          type="password"
          placeholder="{{ 'health.networkInformation.modal.password' | translate }}"
          *ngIf="key.editing"
        />
      </td>
      <td
        class="actions"
        *ngIf="
          key.editing &&
          permissions?.uiFeatures.editPSK &&
          permissions?.uiFeatures.editHomepass &&
          (reducedMode$ | async) === false
        "
      >
        <div class="icons">
          <icon
            name="icon-check"
            class="confirm"
            tooltip="{{ 'health.networkInformation.modal.confirm' | translate }}"
            (click)="editHomepass(key)"
          ></icon>
          <icon
            name="icon-close"
            class="size-0_8 cancel space"
            tooltip="{{ 'health.networkInformation.modal.cancel' | translate }}"
            (click)="key.editing = false"
          ></icon>
        </div>
      </td>
      <td
        class="actions"
        *ngIf="
          !key.editing &&
          permissions?.uiFeatures.editPSK &&
          permissions?.uiFeatures.editHomepass &&
          (reducedMode$ | async) === false
        "
      >
        <div class="icons">
          <icon
            name="icon-edit"
            tooltip="{{ 'health.networkInformation.modal.edit' | translate }}"
            (click)="key.editing = true"
          ></icon>
          <icon
            name="icon-close"
            class="size-0_8 space"
            tooltip="{{ 'health.networkInformation.modal.delete' | translate }}"
            (click)="confirmDeleteHomepass(key)"
          ></icon>
        </div>
      </td>
    </tr>
  </table>
</div>
<div
  class="title"
  *ngIf="permissions?.uiFeatures.editPSK && permissions?.uiFeatures.editHomepass && (reducedMode$ | async) === false"
>
  {{ 'health.networkInformation.modal.addHomepass' | translate }}
</div>
<div
  class="scroll-container"
  *ngIf="permissions?.uiFeatures.editPSK && permissions?.uiFeatures.editHomepass && (reducedMode$ | async) === false"
>
  <table>
    <tr>
      <td class="description">
        <div class="content">
          <input
            [class.error]="homepass.add.errorDesc"
            [class.enabled]="homepass.add.type.current == 'guests'"
            type="text"
            placeholder="{{ 'health.networkInformation.modal.' + homepass.add.description | translate }}"
            [formControl]="homepassDescriptionFormControl"
          />
        </div>
      </td>
      <td class="type">
        <drop-down class="selector small">
          {{ 'health.networkInformation.modal.' + homepass.add.type.current | translate }}
          <ng-container dropdown>
            <li (click)="selectType(type)" *ngFor="let type of homepass.add.type.list">
              {{ 'health.networkInformation.modal.' + type | translate }}
            </li>
          </ng-container>
        </drop-down>
      </td>
      <td class="advanced">
        <icon
          name="icon-wrench"
          [class.disabled]="homepass.add.type.current === 'home'"
          (click)="
            openAdvanced(
              homepass.add.devices.selected,
              homepass.add.expiresAt,
              homepass.add.type.current === 'guests',
              false
            )
          "
        ></icon>
      </td>
      <td class="disabled">
        <slidetoggler [options]="disabledItems" (toggle)="homepass.add.enabled = $event"></slidetoggler>
      </td>
      <td class="password">
        <input
          class="enabled"
          [class.error]="homepass.add.errorPsk"
          type="password"
          placeholder="{{ 'health.networkInformation.modal.password' | translate }}"
          [formControl]="homepassKeyFormControl"
        />
      </td>
      <td class="actions">
        <div class="buttons">
          <button class="action small" (click)="addHomepassKey()">
            {{ 'health.networkInformation.modal.addNetwork' | translate }}
          </button>
        </div>
      </td>
    </tr>
  </table>
</div>

<modal
  mode="compact"
  class="overflow-visible"
  (overlayAction)="advancedDialog.show = $event"
  *ngIf="advancedDialog.show"
>
  <ng-container title>{{ 'health.networkInformation.modal.advanced' | translate }}</ng-container>
  <ng-container content>
    <div>{{ 'health.networkInformation.modal.expirePassword' | translate }}</div>
    <slidetoggler
      [options]="advancedDialog.showExpiredAtItems"
      (toggle)="advancedDialog.showExpiresAt = $event"
      [editable]="!advancedDialog.readOnly"
    ></slidetoggler>
    <ng-container *ngIf="advancedDialog.showExpiresAt">
      <div>{{ 'health.networkInformation.modal.expirePasswordAt' | translate }}</div>
      <calendar
        [startDate]="advancedDialog.expiresAt"
        (event)="setAdvancedDialogExpirationDate($event)"
        [class.disabled]="advancedDialog.readOnly"
      ></calendar>
    </ng-container>
    <ng-container *ngIf="advancedDialog.showDevices">
      <div>{{ 'health.networkInformation.modal.devices' | translate }}</div>
      <drop-down-select
        class="selector medium"
        [rowTemplate]="valueTemplate"
        [readOnly]="advancedDialog.readOnly"
        [disabled]="
          (homepass.add.devices.list$ | async) === null || (advancedDialog.readOnly && !(selectedDevices?.length > 0))
        "
        [values]="homepass.add.devices.list$ | async"
        [ownModel]="selectedDevices"
        (ownModelChange)="selectDevice($event)"
        [multiSelect]="true"
        [filterVisible]="false"
      ></drop-down-select>
      <ng-template #valueTemplate let-value>
        <div class="ellipsis">{{ value.object.name | macAddr: true }}</div>
        <div class="mac">{{ value.object.mac | macAddr }}</div>
      </ng-template>
    </ng-container>
  </ng-container>
  <ng-container controls>
    <ng-container *ngIf="advancedDialog.readOnly">
      <button class="tertiary light" (click)="cancelAdvanced()">
        {{ 'health.networkInformation.modal.close' | translate }}
      </button>
    </ng-container>
    <ng-container *ngIf="!advancedDialog.readOnly">
      <button class="tertiary light" (click)="cancelAdvanced()">
        {{ 'health.networkInformation.modal.cancel' | translate }}
      </button>
      <button class="super-primary" (click)="saveAdvanced()">
        {{ 'health.networkInformation.modal.confirm' | translate }}
      </button>
    </ng-container>
  </ng-container>
</modal>
