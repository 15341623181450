<div class="row">
  <div class="col-xs-12">
    <box class="wider" [class.selected]="expand">
      <div header (click)="expand = !expand">
        <span *ngIf="alarm.mode === 'network'">
          <icon name="icon-arrow-down" [class.rotate180]="expand"></icon>
          {{ 'health.networkStatus.internetConnection.networkOffline' | translate }}
        </span>
        <span *ngIf="alarm.mode === 'node'">
          <icon name="icon-arrow-down" [class.rotate180]="expand"></icon>
          {{ 'health.networkStatus.internetConnection.extenderOffline' | translate }}
        </span>
        <span class="offlineMessage" *ngIf="alarm.connectionStateChangeAt">
          {{ 'health.networkStatus.internetConnection.offlineSince' | translate }}: {{ offlineTime }}
        </span>
        <button class="action" *ngIf="alarm.mode === 'network' && isGuidedTroubleshootPubnubSubscribed && enableGuidedTroubleshootAccess" (click)="preventExpand($event); openGuidedTroubleshoot()">
          {{ 'health.networkInformation.modal.beginTroubleshooting' | translate }}
        </button>
      </div>

      <div container>
        <div class="data">
          <div class="column">
            <icon [path]="alarm.kind.icon"></icon>
            <div class="name">
              <span class="title">{{ alarm.kind.name }}</span> <span class="type">{{ alarm.kind.category }}</span>
            </div>
          </div>
        </div>

        <div class="details" [class.show]="expand" *ngIf="alarm.mode === 'network'">
          <div class="column"></div>
          <div class="column wide">
            <div class="info">
              <div class="key">{{ 'health.networkStatus.internetConnection.networkId' | translate }}</div>
              <div class="value">{{ alarm.id }}</div>
            </div>

            <div class="info">
              <div class="key">{{ 'health.networkStatus.internetConnection.ssid' | translate }}</div>
              <div class="value">{{ alarm.ssid }}</div>
            </div>

            <div class="info">
              <div class="key">{{ 'health.networkStatus.internetConnection.firmwareUpdatedAt' | translate }}</div>
              <div class="value">{{ alarm.firmwareVersion }}</div>
            </div>
          </div>
        </div>

        <div class="details" [class.show]="expand" *ngIf="alarm.mode === 'node'">
          <div class="column"></div>
          <div class="column wide">
            <div class="info">
              <div class="key">{{ 'health.networkStatus.internetConnection.deviceType' | translate }}</div>
              <div class="value">{{ alarm.type }}</div>
            </div>
            <div class="info">
              <div class="key">{{ 'health.networkStatus.internetConnection.firmwareVersion' | translate }}</div>
              <div class="value">{{ alarm.firmwareVersion }}</div>
            </div>
            <div class="info">
              <div class="key">{{ 'health.networkStatus.internetConnection.serial' | translate }}</div>
              <div class="value">{{ alarm.id }}</div>
            </div>
          </div>
        </div>
      </div>
    </box>
  </div>
</div>
