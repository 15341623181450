import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { selectBaseUrl } from 'src/app/store/customer/customer.selectors';
import { IIspSpeedTestConfiguration, IReportBroadbandEfficiencyAlert } from '../interfaces/interface';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class SpeedTestService {
  constructor(private store: Store, private api: ApiService) {}

  configure$(configuration: { enable: boolean; enableAllNodes: boolean }): Observable<IIspSpeedTestConfiguration> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.put(`${baseUrl}/ispSpeedTestConfiguration`, configuration)),
      map((value) => value.ispSpeedTestConfiguration)
    );
  }

  getBroadBandEfficiencyAlert$(): Observable<IReportBroadbandEfficiencyAlert> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.get(`${baseUrl}/broadbandEfficiencyAlert`, 'reports'))
    );
  }
}
