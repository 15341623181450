import { createReducer, on } from '@ngrx/store';
import {
  IAlarmReports,
  IBroadbandEfficiencyAlert,
  IDevice,
  IInternet,
  ILocationQoe,
  INode,
  IOptimization,
  IOptimizationSummary,
  IPowerState,
  ITopology
} from 'src/app/lib/interfaces/interface';
import { setErrorState, setInitialState, setLoadedState } from '../state-interfaces';
import {
  alarmReportsLoaded,
  alarmReportsLoadedError,
  broadbandEfficiencyAlertLoaded,
  broadbandEfficiencyAlertLoadedError,
  devicesLoaded,
  devicesLoadedError,
  internetLoaded,
  internetLoadedError,
  locationQoeLoaded,
  locationQoeLoadedError,
  nodesLoaded,
  nodesLoadedError,
  optimizationChanged,
  powerManagementLoaded,
  stabilityLoaded,
  stabilityLoadedError,
  topologyChanged,
  topologyChangedError
} from './polling.actions';

export const initialState = {
  nodes: setInitialState<INode[], string>(null),
  devices: setInitialState<IDevice[], string>(null),
  locationQoE: setInitialState<ILocationQoe, string>(null),
  bbe: setInitialState<IBroadbandEfficiencyAlert, string>(null),
  internet: setInitialState<IInternet, string>(null),
  stability: setInitialState<IOptimizationSummary, string>(null),
  alarmReports: setInitialState<IAlarmReports, string>(null),
  topology: setInitialState<ITopology, string>(null),
  optimization: setInitialState<IOptimization, string>(null),
  powerManagement: setInitialState<IPowerState, string>(null)
};

export const PollingReducer = createReducer(
  initialState,
  on(nodesLoaded, (state, { nodes }) => ({
    ...state,
    nodes: setLoadedState(nodes)
  })),
  on(nodesLoadedError, (state, { error }) => ({
    ...state,
    nodes: setErrorState(state.nodes, error)
  })),
  on(devicesLoaded, (state, { devices }) => ({
    ...state,
    devices: setLoadedState(devices)
  })),
  on(devicesLoadedError, (state, { error }) => ({
    ...state,
    devices: setErrorState(state.devices, error)
  })),
  on(locationQoeLoaded, (state, { qoe }) => ({
    ...state,
    locationQoE: setLoadedState(qoe)
  })),
  on(locationQoeLoadedError, (state, { error }) => ({
    ...state,
    locationQoE: setErrorState(state.locationQoE, error, { devices: [], nodes: [] })
  })),
  on(broadbandEfficiencyAlertLoaded, (state, { bbe }) => ({
    ...state,
    bbe: setLoadedState(bbe)
  })),
  on(broadbandEfficiencyAlertLoadedError, (state, { error }) => ({
    ...state,
    bbe: setErrorState(state.bbe, error)
  })),
  on(internetLoaded, (state, { internet }) => ({
    ...state,
    internet: setLoadedState(internet)
  })),
  on(internetLoadedError, (state, { error }) => ({
    ...state,
    internet: setErrorState(state.internet, error)
  })),
  on(stabilityLoaded, (state, { stability }) => ({
    ...state,
    stability: setLoadedState(stability)
  })),
  on(stabilityLoadedError, (state, { error }) => ({
    ...state,
    stability: setErrorState(state.stability, error)
  })),
  on(alarmReportsLoaded, (state, { alarmReports }) => ({
    ...state,
    alarmReports: setLoadedState(alarmReports)
  })),
  on(alarmReportsLoadedError, (state, { error }) => ({
    ...state,
    alarmReports: setErrorState(state.alarmReports, error)
  })),
  on(topologyChanged, (state, { topology }) => ({
    ...state,
    topology: setLoadedState(topology)
  })),
  on(topologyChangedError, (state, { error }) => ({
    ...state,
    topology: setErrorState(state.topology, error)
  })),
  on(optimizationChanged, (state, { optimization }) => ({
    ...state,
    optimization: setLoadedState(optimization)
  })),
  on(powerManagementLoaded, (state, { powerManagement }) => ({
    ...state,
    powerManagement: setLoadedState(powerManagement)
  }))
);
