<div class="row">
  <div class="col-xs-12">
    <box class="wider" [class.selected]="expand">
      <div header>
        <span>
          {{ 'health.networkStatus.stabilityAlarm.networkUnstable' | translate }}: {{ alarm?.name | translate }}
        </span>
      </div>
      <div container>
        <div class="data">
          <div class="column">
            <icon path="assets/icons/{{ alarm?.icon }}.svg"></icon>
            <div class="name">
              <span class="title">{{ alarm?.message | translate }}</span>
            </div>
          </div>
        </div>
      </div>
    </box>

    <div class="wrapper" [class.show]="expand && troubleshoot">
      <logbubble [id]="alarm.name">
        {{
          alarm?.name === 'health.networkStatus.stabilityAlarm.highOptimizationsAlarmTitle'
            ? ('health.networkStatus.stabilityAlarm.manyOptimizations' | translate)
            : ('health.networkStatus.stabilityAlarm.failedOptimizations' | translate)
        }}
      </logbubble>
    </div>
    <div class="wrapper" [class.show]="expand && troubleshoot" *ngIf="step2"><rebootbubble></rebootbubble></div>
    <div class="wrapper" [class.show]="expand && troubleshoot" *ngIf="step3">
      <logbubble [id]="alarm.name" buttonText="health.bubbles.logBubble.finish" buttonAction="finish">
        {{
          optimization === true
            ? ('health.networkStatus.stabilityAlarm.optimizationSuccessful' | translate)
            : ('health.networkStatus.stabilityAlarm.optimizationFailed' | translate)
        }}
      </logbubble>
    </div>
  </div>
</div>
<div class="row" *ngIf="!troubleshoot">
  <div class="col-xs-12">
    <div class="actions">
      <div class="button wide" (click)="startTroubleshooting()">
        {{ 'health.networkStatus.stabilityAlarm.beginTroubleshooting' | translate }}
      </div>
      <!-- <div class="button">Dismiss Alert</div> -->
    </div>
  </div>
</div>
