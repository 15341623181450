import { Component, Input, OnInit } from '@angular/core';
import { findIndex } from 'lodash';
import { LoggingService } from 'src/app/lib/services/logging.service';

@Component({
  selector: 'leaftoroot',
  templateUrl: './leaftoroot.component.html',
  styleUrls: ['./leaftoroot.component.scss']
})
export class LeaftorootComponent implements OnInit {
  @Input()
  nodes: any;
  @Input()
  node?: any;
  @Input()
  device?: any;
  @Input()
  alarm: string;

  timeline: any = [];

  constructor(private logging: LoggingService) {}

  getConnection(health: any): any {
    switch (health.status) {
      case 'poor':
        return { status: 'poor', line: 'dotted', strength: 'leaftoroot.poor' };
      case 'fair':
        return { status: 'fair', line: 'solid', strength: 'leaftoroot.fair' };
      case 'good':
        return { status: 'good', line: 'solid', strength: 'leaftoroot.good' };
      case 'excellent':
        return { status: 'good', line: 'solid', strength: 'leaftoroot.excellent' };
      default:
        return { status: 'unknown', line: 'none', strength: 'leaftoroot.unknown' };
    }
  }

  ngOnInit(): void {
    this.logging.debug('<leaftoroot> _____________________________');
    this.logging.debug('     device: ', this.device);
    this.logging.debug('      nodes: ', this.nodes);

    let pHealth = this.device?.health;
    this.timeline = [];

    if (this.device?.leafToRoot && this.device?.leafToRoot.length > 0) {
      this.device.leafToRoot.forEach((connection: any, i: number) => {
        const index = findIndex(this.nodes, { id: connection.id });

        if (index > -1) {
          const connectedNode = this.nodes[index];
          const newTimelineEntry = {
            connection: i === 0 ? { ...this.getConnection(pHealth), alarm: this.alarm } : this.getConnection(pHealth),
            node: {
              icon: 'icon-pod-hollow',
              name: connectedNode.nickname || connectedNode.name || connectedNode.defaultName
            }
          };
          this.timeline.push(newTimelineEntry);
          if (connectedNode && connectedNode.health) {
            pHealth = connectedNode.health;
          }
        } else {
          this.logging.warn('Unable to find node ', this.device.id, ' in leaftoroot');
          return null;
        }
      });
    } else if (this.node && this.node.leafToRoot && this.node.leafToRoot.length > 0 && !this.device) {
      this.timeline = []; // only showing a single hop for nodes

      const nodeHealth = this.node?.health;
      const connection = this.node.leafToRoot[0];
      const newTimelineEntry = {
        connection: { ...this.getConnection(nodeHealth), alarm: this.alarm },
        node: {
          icon: 'icon-pod-hollow',
          name: connection.nickname || connection.defaultName || connection.id
        }
      };
      this.timeline.push(newTimelineEntry);
      if (this.node.leafToRoot.length > 1) {
        this.timeline.push({ connection: { line: 'dottedShort' } }); // add a blank entry to show multiple hops
      }
    } else {
      this.logging.error('Device or node missing leafToRoot');
    }
  }
}
