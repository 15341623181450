import {
  Component,
  Input,
  ElementRef,
  OnChanges,
  AfterViewInit,
  ViewChild,
  Output,
  EventEmitter,
  OnDestroy
} from '@angular/core';
import { D3Service } from 'src/app/lib/d3/service';
import { SecurityChart } from 'src/app/lib/d3/models/charts/security.chart';
import * as erdm from 'element-resize-detector';

@Component({
  selector: 'securitychart',
  templateUrl: './securitychart.component.html',
  styleUrls: ['./securitychart.component.scss']
})
export class SecurityChartVisualComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input()
  events: any[] = [];

  @Input()
  dayView: any = null;

  @Input()
  filterEnabled: boolean = false;

  @Input()
  filterType: string = null;

  @Output()
  filter: any = new EventEmitter();

  @Output()
  view: any = new EventEmitter();

  @ViewChild('canvas')
  canvas: ElementRef;

  chart: SecurityChart;
  positionTimeout: any;
  showTimeout: any;
  data: any[] = [];
  plot: any = {
    days: [],
    hours: []
  };
  margins: any = {
    top: 60,
    right: 40,
    bottom: 40,
    left: 60
  };

  xAxis: any[] = [];
  yAxis: any[] = [];

  tooltipDay: any = {
    show: false,
    style: { 'top.px': 0, 'left.px': 0 }
  };

  tooltipEvent: any = {
    show: false,
    style: { 'top.px': 0, 'left.px': 0 },
    data: {}
  };

  erd: any = erdm({ strategy: 'scroll' });

  constructor(private d3: D3Service) {
    this.chart = this.d3.generate('securitychart');
  }

  ngAfterViewInit(): void {
    this.erd.listenTo(this.canvas.nativeElement, () => {
      this.render();
    });
  }

  ngOnChanges(): void {
    if (this.canvas) {
      this.render();
    }
  }

  render(): void {
    this.chart.update(
      this.events,
      this.canvas.nativeElement.clientWidth,
      this.canvas.nativeElement.clientHeight,
      this.margins,
      this.dayView
    );

    this.xAxis = this.chart.xAxis();
    this.yAxis = this.chart.yAxis();

    this.data = this.chart.calculateData();
  }

  changeView(day: any = null, cancel: boolean = false): void {
    this.tooltipEvent.show = false;

    if (cancel) {
      this.view.emit(day);
    } else {
      if (!this.dayView) {
        this.view.emit(day);
      }
    }
  }

  positionTooltipDay(day: any, mode: boolean): void {
    day.hover = mode;
    this.tooltipDay.style['left.px'] = day.center + this.margins.left;
    this.tooltipDay.text = day.text;
    this.tooltipDay.show = mode;
  }

  filterEvents(eventType: string = null): void {
    this.filter.emit({ mode: !this.filterEnabled, type: eventType });
  }

  positionTooltipEvent(day: any, event: any, mode: boolean): void {
    clearTimeout(this.positionTimeout);
    clearTimeout(this.showTimeout);

    if (mode) {
      event.hover = mode;
      this.tooltipEvent.style['left.px'] = day.center + this.margins.left;
      this.tooltipEvent.style['top.px'] = 20 + event.y + this.margins.top;
      this.tooltipEvent.data = event;

      this.positionTimeout = setTimeout(() => {
        const tooltipPosition = document.getElementById('tooltip-event').getBoundingClientRect();
        const canvasPosition = this.canvas.nativeElement.getBoundingClientRect();

        if (tooltipPosition.left <= canvasPosition.left) {
          this.tooltipEvent.style['left.px'] =
            day.center + this.margins.left + (canvasPosition.left - tooltipPosition.left);
        }

        if (tooltipPosition.left + tooltipPosition.width >= canvasPosition.left + canvasPosition.width) {
          this.tooltipEvent.style['left.px'] =
            day.center +
            this.margins.left -
            (tooltipPosition.left + tooltipPosition.width - (canvasPosition.left + canvasPosition.width));
        }

        if (tooltipPosition.top + tooltipPosition.height >= canvasPosition.top + canvasPosition.height) {
          this.tooltipEvent.style['top.px'] = this.margins.top + (event.top ?? event.y) - tooltipPosition.height - 20;
        }

        this.showTimeout = setTimeout(() => {
          this.tooltipEvent.show = mode;
        }, 10);
      }, 10);
    } else {
      event.hover = mode;
      this.tooltipEvent.show = mode;
    }
  }

  ngOnDestroy(): void {
    clearTimeout(this.positionTimeout);
    clearTimeout(this.showTimeout);

    if (this.canvas) {
      this.erd.uninstall(this.canvas.nativeElement);
    }
  }
}
