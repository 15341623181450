<div id="networkrouter-component">
  <div class="title">
    {{ 'networkRouter.title' | translate }}
  </div>

  <div class="message" [class.errormsg]="errormsg">{{ msg | translate }} {{ id }}</div>

  <div class="home" *ngIf="errormsg" routerLink="/">
    <icon name="icon-arrow-left"></icon>
    {{ 'networkRouter.home' | translate }}
  </div>
</div>
