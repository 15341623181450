<div class="row details" *ngIf="event && event.eventData">
  <div class="col-xs-12">
    <div class="bubble">
      <div class="title">
        {{ 'timelines.optimizer.details.title' | translate }}
        <icon name="icon-close" (click)="closeEvent()"></icon>
      </div>
      <div class="columns">
        <div class="column">
          <div class="row">
            <div class="key">{{ 'timelines.optimizer.details.created' | translate }}</div>
            <div class="value" *ngIf="event.eventData?.request?.createdAt">
              {{ event.eventData?.request?.createdAt | date: 'L LT' }}
            </div>
          </div>
          <div class="row">
            <div class="key">{{ 'timelines.optimizer.details.optimized' | translate }}</div>
            <div class="value">{{ event.eventData?.response?.createdAt | date: 'L LT' }}</div>
          </div>
          <div class="row">
            <div class="key">{{ 'timelines.optimizer.details.requestId' | translate }}</div>
            <div
              class="value"
              [tooltip]="event.eventData?.request?.id || event.eventData?.response?.id"
              [onlyOverflowText]="true"
            >
              {{ event.eventData?.request?.id || event.eventData?.response?.id }}
            </div>
          </div>
        </div>
        <div class="column">
          <div class="row">
            <div class="key">{{ 'timelines.optimizer.details.responseStatus' | translate }}</div>
            <div class="value">{{ event.eventData.response.status }}</div>
          </div>
          <div class="row">
            <div class="key">{{ 'timelines.optimizer.details.topologyChangesStatus' | translate }}</div>
            <div class="value status">
              <div>
                {{
                  event.eventData.topologyChange
                    ? (event.eventData.topologyChange.status | translate)
                    : ('unknown' | translate)
                }}
              </div>
              <icon
                name="icon-info"
                class="size-0_8"
                [tooltip]="event.eventData.topologyChange.opsLog[event.eventData.topologyChange.opsLog.length - 1]"
                [maxWidth]="500"
                *ngIf="event.eventData.topologyChange && event.eventData.topologyChange.status === 'failed'"
              ></icon>
            </div>
          </div>
          <div class="row">
            <div class="key">{{ 'timelines.optimizer.details.triggers' | translate }}</div>
            <div class="value">
              <div class="pill" *ngFor="let trigger of event.eventData.response.triggers">
                {{ 'timelines.optimizer.details.triggersList.' + trigger | translate
                }}<icon name="icon-leaf" *ngIf="trigger === 'EnterPowerMode'"></icon>
              </div>
            </div>
          </div>
          <div class="row preCac" *ngIf="event.eventData.topologyChange?.pcs">
            <div class="key">{{ 'timelines.optimizer.details.preCacStatus' | translate }}</div>
            <div class="value">
              {{ event.eventData.topologyChange.pcs.status | translate }}
              <icon
                name="icon-info"
                tooltip="{{ 'timelines.optimizer.details.channels' | translate }}: {{
                  event.eventData.topologyChange.pcs.channels5G?.join(', ')
                }}"
                *ngIf="
                  event.eventData.topologyChange.pcs.status === 'succeeded' &&
                  event.eventData.topologyChange.pcs.channels5G?.length
                "
              ></icon>
            </div>
          </div>
        </div>
      </div>
      <div class="buttons">
        <drop-down class="selector medium" *ngIf="ui === 'noc'">
          {{ selector.current.name | translate }}
          <ng-container dropdown>
            <li (click)="changeStats(item)" *ngFor="let item of selector.list">
              {{ item.name | translate }}
            </li>
          </ng-container>
        </drop-down>
        <button *ngIf="ui === 'noc' && event?.eventData?.response" class="action medium" (click)="downloadResponse()">
          {{ 'timelines.optimizer.details.download' | translate }}
        </button>
        <button *ngIf="enableTopology" class="action medium" (click)="showTopologyModal()">
          {{ 'timelines.optimizer.details.topology' | translate }}
        </button>
        <button
          *ngIf="!enableTopology"
          class="action medium disabled allow-pointer-events"
          (click)="showTopologyModal()"
          tooltip="{{ 'timelines.optimizer.details.noTopologyChanges' | translate }}"
        >
          {{ 'timelines.optimizer.details.topology' | translate }}
        </button>
        <button
          class="action medium"
          [class.disabled]="!event.eventData.topologyChange || !event.eventData.topologyChange.opsLog"
          (click)="event.eventData?.topologyChange?.opsLog && showOpsLogModal()"
          *ngIf="ui === 'noc'"
        >
          {{ 'timelines.optimizer.details.opsLog' | translate }}
        </button>
      </div>
      <div
        class="lpmWarningMessage"
        *ngIf="selector.current.id !== null && (lpmStatus.event === 'LPM' || lpmStatus.target === 'LPM')"
      >
        <icon name="icon-info" [tooltip]="'timelines.optimizer.details.lpmWarningInfo' | translate"></icon>
        {{ 'timelines.optimizer.details.lpmWarningMessage' | translate }}
      </div>
      <div
        class="stats"
        *ngIf="
          selector.current.id === 'radio24' ||
          selector.current.id === 'radio50' ||
          selector.current.id === 'radio50L' ||
          selector.current.id === 'radio50U' ||
          selector.current.id === 'radio60'
        "
      >
        <div class="table fixedLayout" [class.small]="table.length > 5">
          <div class="table-row" *ngFor="let row of table">
            <div class="table-cell" *ngFor="let cell of row">
              <div class="header" *ngIf="cell.type === 'header'">
                <ng-container *ngIf="cell.id === cell.name">
                  <div class="name">{{ cell.name }}</div>
                </ng-container>
                <ng-container *ngIf="cell.id !== cell.name">
                  <div class="name">{{ cell.name }}</div>
                  <div class="id">{{ cell.id }}</div>
                </ng-container>
              </div>
              <div class="data" [class.highlight]="cell.highlight" [ngClass]="cell.state" *ngIf="cell.type === 'value'">
                <div class="value">{{ cell.value }}</div>
                <div class="line"></div>
                <div class="unit">dB</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="stats"
        *ngIf="
          selector.current.id === 'interference-radio24' ||
          selector.current.id === 'interference-radio50' ||
          selector.current.id === 'interference-radio50L' ||
          selector.current.id === 'interference-radio50U' ||
          selector.current.id === 'interference-radio60'
        "
      >
        <horizontalscroll>
          <div class="table small">
            <div class="table-row" *ngFor="let row of table">
              <div class="table-cell sticky" *ngIf="row.type === 'headers'"></div>
              <div class="table-cell sticky" *ngIf="row.type === 'data'">
                <div class="header">
                  <ng-container *ngIf="row.id === row.name">
                    <div class="name">{{ row.name }}</div>
                  </ng-container>
                  <ng-container *ngIf="row.id !== row.name">
                    <div class="name">{{ row.name }}</div>
                    <div class="id">{{ row.id }}</div>
                  </ng-container>
                </div>
              </div>
              <div class="table-cell" *ngFor="let cell of row.channels">
                <div
                  class="data"
                  [class.highlight]="cell.state"
                  [ngClass]="cell.state"
                  [style.color]="cell.channelColor"
                >
                  <div class="unit" *ngIf="row.type === 'headers'">Ch</div>
                  {{ row.type === 'headers' ? cell.value : cell.type === 'empty' ? '' : cell.value + '%' }}
                </div>
              </div>
            </div>
          </div>
        </horizontalscroll>
      </div>
      <div class="stats" *ngIf="selector.current.id === 'radar'">
        <div *ngIf="table.length < 1" class="no-radar">
          {{ 'timelines.optimizer.details.noRadarEvents' | translate }}
        </div>
        <horizontalscroll *ngIf="table.length > 0">
          <div class="table small">
            <div class="table-row" *ngFor="let row of table">
              <div class="table-cell sticky" *ngIf="row.type === 'headers'"></div>
              <div class="table-cell sticky" *ngIf="row.type === 'data'">
                <div class="header">
                  <ng-container *ngIf="row.id === row.name">
                    <div class="name">{{ row.name }}</div>
                  </ng-container>
                  <ng-container *ngIf="row.id !== row.name">
                    <div class="name">{{ row.name }}</div>
                    <div class="id">{{ row.id }}</div>
                  </ng-container>
                </div>
              </div>
              <div class="table-cell" *ngFor="let cell of row.channels">
                <div
                  class="data"
                  [class.highlight]="cell.state"
                  [ngClass]="cell.state"
                  [style.color]="cell.channelColor"
                >
                  <span class="unit" *ngIf="row.type === 'headers'">Ch</span>
                  {{ cell.value }}
                </div>
              </div>
            </div>
          </div>
        </horizontalscroll>
      </div>

      <div class="stats" *ngIf="selector.current.id === 'puncturelist'">
        <horizontalscroll *ngIf="table.length > 0">
          <div class="table small">
            <div class="table-row">
              <div class="table-cell sticky"></div>
              <div class="table-cell sticky">{{ 'timelines.optimizer.details.channel' | translate }}</div>
              <div class="table-cell sticky">{{ 'timelines.optimizer.details.puncture' | translate }}</div>
            </div>
            <div class="table-row" *ngFor="let row of table">
              <div class="table-cell sticky" *ngIf="row.type === 'headers'"></div>
              <div class="table-cell sticky" *ngIf="row.type === 'data'">
                <div class="header">
                  <ng-container *ngIf="row.id !== row.name">
                    <div class="name">{{ row.name }}</div>
                    <div class="id">{{ row.id }}</div>
                  </ng-container>
                  <ng-container *ngIf="row.id === row.name">
                    <div class="name">{{ row.id }}</div>
                  </ng-container>
                </div>
              </div>
              <div class="table-cell sticky" [ngStyle]="{ color: getChannelColor(row.channel) }">
                {{ row.channel }}
              </div>
              <div class="table-cell sticky">
                <span *ngIf="!row.punctured5G || row.punctured5G.length < 1">
                  {{ 'timelines.optimizer.details.none' | translate }}
                </span>
                <span *ngFor="let pChan of row.punctured5G; index as i">
                  <span [ngStyle]="{ color: getChannelColor(pChan) }">
                    {{ pChan }}
                  </span>
                  <span *ngIf="i < row.punctured5G.length - 1">,</span>
                </span>
              </div>
            </div>
          </div>
        </horizontalscroll>
        <horizontalscroll *ngIf="table.length < 1">
          {{ 'timelines.optimizer.details.punctureNotSupported' | translate }}
        </horizontalscroll>
      </div>
      <div class="stats" *ngIf="selector.current.id === 'perfPred'">
        <horizontalscroll>
          <div class="table">
            <div class="table-row" *ngFor="let row of table">
              <ng-container *ngIf="row.type === 'header'">
                <div class="table-cell sticky">
                  <div class="header">{{ row.header | translate }}</div>
                </div>
                <div class="table-cell" *ngIf="row.radio24">
                  <div class="header"><div class="name">2.4 GHz</div></div>
                </div>
                <div class="table-cell" *ngIf="row.radio50">
                  <div class="header"><div class="name">5 GHz</div></div>
                </div>
                <div class="table-cell" *ngIf="row.radio60">
                  <div class="header"><div class="name">6 GHz</div></div>
                </div>
              </ng-container>
              <ng-container *ngIf="row.type === 'data'">
                <div class="table-cell sticky">
                  <div class="header">
                    <ng-container *ngIf="row.id === row.name">
                      <div class="name">{{ row.name }}</div>
                    </ng-container>
                    <ng-container *ngIf="row.id !== row.name">
                      <div class="name">{{ row.name }}</div>
                      <div class="id">{{ row.id }}</div>
                    </ng-container>
                  </div>
                </div>
                <div class="table-cell" *ngIf="row.radio24">
                  <div class="data">
                    <div class="value">{{ row.radio24 }}</div>
                    <div class="unit">Mbps</div>
                  </div>
                </div>
                <div class="table-cell" *ngIf="row.radio50">
                  <div class="data">
                    <div class="value">{{ row.radio50 }}</div>
                    <div class="unit">Mbps</div>
                  </div>
                </div>
                <div class="table-cell" *ngIf="row.radio60">
                  <div class="data">
                    <div class="value">{{ row.radio60 }}</div>
                    <div class="unit">Mbps</div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </horizontalscroll>
      </div>
      <div class="stats" *ngIf="selector.current.id === 'mduWifiConfig'">
        <div class="table">
          <div class="table-row" *ngFor="let row of table">
            <ng-container *ngIf="row.type === 'header'">
              <div class="table-cell">
                <div class="header">{{ row.header | translate }}</div>
              </div>
              <ng-container *ngFor="let rowValues of row | keyvalue">
                <div class="table-cell" *ngIf="rowValues.key !== 'header' && rowValues.key !== 'type'">
                  <div class="header">{{ rowValues.key }}</div>
                </div>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="row.type === 'data'">
              <div class="table-cell">
                <div class="header">
                  <div class="name">{{ row.name }}</div>
                </div>
              </div>
              <ng-container *ngFor="let rowValues of row | keyvalue">
                <div class="table-cell" *ngIf="rowValues.key !== 'name' && rowValues.key !== 'type'">
                  <div class="data">
                    <div class="value">{{ rowValues.value }}</div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="stats phy" *ngIf="selector.current.id === 'estPhy' || selector.current.id === 'obsPhy'">
        <div class="table fixedLayout" [class.small]="table.length > 5">
          <div class="table-row" *ngFor="let row of table">
            <div class="table-cell" *ngFor="let cell of row">
              <div class="header" *ngIf="cell.type === 'header'">
                <ng-container *ngIf="cell.id === cell.name">
                  <div class="name">{{ cell.name }}</div>
                </ng-container>
                <ng-container *ngIf="cell.id !== cell.name">
                  <div class="name">{{ cell.name }}</div>
                  <div class="id">{{ cell.id }}</div>
                </ng-container>
              </div>
              <div class="data" *ngIf="cell.type === 'value'">
                <div
                  class="row"
                  [class.highlight]="channel.highlight"
                  [style.borderColor]="channel.highlight ? channel.channelColor : 'transparent'"
                  tooltip="{{ 'timelines.optimizer.details.band' | translate }}: {{ channel.band.join(', ') }}"
                  [hideToolTip]="channel.hideToolTip"
                  *ngFor="let channel of cell.channels"
                >
                  <div
                    class="failures"
                    *ngIf="channel.blacklisted"
                    tooltip="{{ channel.blacklisted }} {{ 'timelines.optimizer.details.linkFailures' | translate }}"
                    (mouseenter)="channel.hideToolTip = true"
                    (mouseleave)="channel.hideToolTip = false"
                  >
                    {{ channel.blacklisted }}
                  </div>
                  <div class="channel-number" [style.color]="channel.channelColor">
                    Ch {{ channel.channel }}/{{ channel.bandWidth }}:
                  </div>
                  <div class="channel-value">{{ channel.value }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<modal mode="wide" (overlayAction)="showTopology = $event" *ngIf="showTopology">
  <ng-container title>
    {{
      enableTopology
        ? ('timelines.optimizer.details.changesViaOptimizer' | translate)
        : ('timelines.optimizer.details.noTopologyChanges' | translate)
    }}
  </ng-container>
  <ng-container content>
    <div class="switch">
      <slidetoggler [options]="topologyModeItems" (toggle)="topologyModeChange($event)"></slidetoggler>
    </div>
    <div class="content">
      <div class="topologyChanges" *ngIf="enableTopology">
        <div class="node" *ngFor="let node of topologyChanges">
          <div class="details">
            <div class="name">{{ node.node.nickname || node.node.defaultName }}</div>
            <div class="id">({{ node.node.id }})</div>
          </div>
          <div class="changes">
            <div class="change" *ngFor="let change of node.changes">
              <ng-container *ngIf="change.type === 'band'">
                {{ change.band }} {{ 'timelines.optimizer.details.channel' | translate }}:
                {{ change.original.channel }} &rarr; {{ change.optimized.channel }}
              </ng-container>
              <ng-container *ngIf="change.type === 'vaps'">
                {{ change.band }} {{ 'timelines.optimizer.details.vaps' | translate }}: [{{
                  change.original.vaps.join(', ')
                }}] &rarr; [{{ change.optimized.vaps.join(', ') }}]
              </ng-container>
              <ng-container *ngIf="change.type === 'parent'">
                {{ 'timelines.optimizer.details.parent' | translate }}:
                {{ change.originalParent?.nickname || change.originalParent?.defaultName || change.original.parentId }}
                ({{ change.original.freqBand }} Ch. {{ change.original.channel }}) &rarr;
                {{
                  change.optimizedParent?.nickname || change.optimizedParent?.defaultName || change.optimized.parentId
                }}
                ({{ change.optimized.freqBand }} Ch. {{ change.optimized.channel }})
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="topology" [class.full]="!enableTopology">
        <div class="chart">
          <forcechart
            [nodesList]="topologyNodes"
            [linksList]="topologyLinks"
            [checkTopology]="checkTopology"
            [barebone]="true"
          ></forcechart>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container controls>
    <button class="tertiary light" (click)="showTopology = false">
      {{ 'timelines.optimizer.details.close' | translate }}
    </button>
  </ng-container>
</modal>

<modal mode="compact" (overlayAction)="showOpsLog = $event" *ngIf="showOpsLog">
  <ng-container title>{{ 'timelines.optimizer.details.opsLog' | translate }}</ng-container>
  <ng-container content>
    <div class="content vertical">
      <div *ngFor="let log of event.eventData.topologyChange.opsLog">{{ log }}</div>
    </div>
  </ng-container>
  <ng-container controls>
    <button class="tertiary light" (click)="showOpsLog = false">
      {{ 'timelines.optimizer.details.close' | translate }}
    </button>
  </ng-container>
</modal>
