import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { selectLocationNotReady } from 'src/app/store/customer/customer.selectors';

@Component({
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss']
})
export class ConfigurationComponent implements OnInit, OnDestroy {
  loading$ = this.store.select(selectLocationNotReady);
  routeSubscription: any;
  searchList: any[] = [];
  selectedSectionIndex: number = 0;
  selectedChildIndex: number = 0;
  scrollTimeout: number = 2000;
  timeout: any;

  fragments: string[] = [];
  path: string[] = ['customer', this.plume.customerid, 'location', this.plume.locationid, 'configuration'];
  expand: any = {
    firmware: false,
    optimization: false,
    smallbusiness: false,
    wan: false,
    powermanagement: false,
    steering: false,
    onboarding: false,
    capabilities: false,
    subscription: false,
    ipv6: false,
    iptv: false,
    privacyandsecurity: false,
    multicast: false,
    wifiradio: false,
    dhcp: false,
    dns: false,
    utilities: false,
    notifications: false,
    lte: false,
    mobile: false,
    agentintegrations: false,
    threadnetwork: false
  };

  constructor(
    private plume: PlumeService,
    private mixpanel: MixpanelService,
    private route: ActivatedRoute,
    private router: Router,
    private cdf: ChangeDetectorRef,
    private store: Store
  ) {}

  ngOnInit(): void {
    window.scrollTo(0, 0);

    this.routeSubscription = this.route.fragment.subscribe((fragments: string) => {
      Object.keys(this.expand).forEach((section: string) => (this.expand[section] = false));

      const oldFragments = [...this.fragments];

      this.fragments = fragments ? fragments.split(',') : [];
      this.fragments.forEach((section: string) => (this.expand[section] = true));

      if (this.fragments.length && this.fragments.length > oldFragments.length) {
        this.scrollTo(this.fragments[this.fragments.length - 1], this.scrollTimeout);
      }

      this.scrollTimeout = 500;
    });
  }

  changeToggle(section: string, state: any): void {
    const index = this.fragments.indexOf(section);
    const fragments = [...this.fragments];

    if (state) {
      if (index < 0) {
        fragments.push(section);
      }

      this.router.navigate(this.path, { fragment: fragments.join(',') });
    } else {
      if (index >= 0) {
        fragments.splice(index, 1);
      }

      if (fragments.length) {
        this.router.navigate(this.path, { fragment: fragments.join(',') });
      } else {
        this.router.navigate(this.path);
      }
    }
  }

  scrollTo(section: string, timeout: number = 500): void {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      document.getElementById('conf-' + section)?.scrollIntoView({ behavior: 'smooth' });
    }, timeout);
  }

  closeAll(): void {
    this.router.navigate(this.path);
    this.mixpanel.storeEvent('CONFIGURATION_CLOSE_ALL', {});
  }

  addToSearchList(event: any): void {
    this.searchList = [...this.searchList, event];
    this.cdf.detectChanges();
  }

  removeFromSearchList(event: { section: string }): void {
    this.searchList = this.searchList.filter((item) => item.section !== event.section);
    this.cdf.detectChanges();
  }

  selectSearch(item: any): void {
    this.expand[item.section] = Date.now();
    this.changeToggle(item.section, true);
  }

  ngOnDestroy(): void {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }

    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }
}
