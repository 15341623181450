import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'date' })
export class DatePipe implements PipeTransform {
  transform(value: number | string, format?: string): string {
    if (format) {
      return moment(value).format(format);
    } else {
      return moment(value).toLocaleString();
    }
  }
}
