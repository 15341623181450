import { createReducer, on } from '@ngrx/store';
import produce from 'immer';
import {
  IAppPrioritization,
  IAppPrioritizationMonitoring,
  IAppPrioritizationV2,
  IAppTime,
  ICapabilities,
  IConfigAndState,
  ICustomer,
  ILocation,
  ILocationLog,
  IMobilizeLog,
  IPrimarySecondaryNetworks,
  ISecondaryNetworks
} from 'src/app/lib/interfaces/interface';
import {
  backhaulSetFinished,
  configOptimizationsHopPenaltyModeSet,
  configOptimizationsHopPenaltyModeSetError,
  configOptimizationsHopPenaltyModeSetFinished,
  configOptimizationsPreCACSchedulerSet,
  configOptimizationsPreCACSchedulerSetError,
  configOptimizationsPreCACSchedulerSetFinished,
  configOptimizationsPrefer160MhzSet,
  configOptimizationsPrefer160MhzSetError,
  configOptimizationsPrefer160MhzSetFinished,
  configOptimizationsMax24GhzChannelWidthSet,
  configOptimizationsMax24GhzChannelWidthSetFinished,
  configOptimizationsMax24GhzChannelWidthSetError,
  configOptimizationsMax5GhzChannelWidthSet,
  configOptimizationsMax5GhzChannelWidthSetFinished,
  configOptimizationsMax5GhzChannelWidthSetError,
  configOptimizationsMax6GhzChannelWidthSet,
  configOptimizationsMax6GhzChannelWidthSetFinished,
  configOptimizationsMax6GhzChannelWidthSetError,
  configOptimizationsZeroWaitDfsModeSet,
  configOptimizationsZeroWaitDfsModeSetError,
  configOptimizationsZeroWaitDfsModeSetFinished,
  configPaSAppTimeEnableSet,
  configPaSAppTimeEnableSetError,
  configPaSAppTimeEnableSetFinished,
  configPaSDosProtectionActionSet,
  configPaSDosProtectionActionSetError,
  configPaSDosProtectionActionSetFinished,
  configPaSDosProtectionSet,
  configPaSDosProtectionSetError,
  configPaSDosProtectionSetFinished,
  configPaSDpiContentFilteringSet,
  configPaSDpiContentFilteringSetError,
  configPaSDpiContentFilteringSetFinished,
  configPaSFlowStatsSetError,
  configPaSFlowStatsSetFinished,
  configPaSInlineDpiSet,
  configPaSInlineDpiSetError,
  configPaSInlineDpiSetFinished,
  configPaSIpThreatIntelligenceSet,
  configPaSIpThreatIntelligenceSetError,
  configPaSIpThreatIntelligenceSetFinished,
  configPaSIpThreatProtectSet,
  configPaSIpThreatProtectSetError,
  configPaSIpThreatProtectSetFinished,
  configPaSPreferredIntelligenceSet,
  configPaSPreferredIntelligenceSetError,
  configPaSPreferredIntelligenceSetFinished,
  configPaSWcHealthTelemetrySet,
  configPaSWcHealthTelemetrySetError,
  configPaSWcHealthTelemetrySetFinished,
  configPaSWcHeroTelemetrySet,
  configPaSWcHeroTelemetrySetError,
  configPaSWcHeroTelemetrySetFinished,
  dppLoaded,
  dppSet,
  dppSetError,
  dppSetFinished,
  profileSet,
  profileSetError,
  profileSetFinished,
  steeringBandSteeringSet
} from '../configview/config.actions';
import {
  bandSteeringSet,
  bandSteeringSetError,
  bandSteeringSetFinished,
  customerSetFromEmailVerified,
  customerSetFromRename,
  locationNameSet,
  locationNameSetError,
  locationNameSetFinished,
  networkModeSet,
  networkModeSetError,
  networkModeSetFinished,
  networkModeSetWan,
  nodesAuthorizationSet,
  nodesAuthorizationSetError,
  nodesAuthorizationSetFinished,
  optimizationsAutoModeEnable,
  optimizationsAutoModeEnableError,
  optimizationsAutoModeEnableFinished,
  optimizationsDfsModeSet,
  optimizationsDfsModeSetError,
  optimizationsDfsModeSetFinished,
  partnerIdSaved,
  partnerIdSavedError,
  partnerIdSet,
  podsAuthorizationSet,
  podsAuthorizationSetError,
  podsAuthorizationSetFinished,
  serviceIdSet,
  serviceIdSetError,
  serviceIdSetFinished,
  serviceLevelSet,
  serviceLevelSetError,
  serviceLevelSetFinished
} from '../customer-info/customer-info.actions';
import { customerSelected } from '../header/header.actions';
import {
  IPv6AddressingConfigSet,
  IPv6AddressingConfigSetError,
  IPv6AddressingConfigSetFinished,
  IPv6ModeSet,
  IPv6ModeSetError,
  IPv6ModeSetFinished,
  IPv6PartialStateSet,
  IPv6PartialStateSetError,
  IPv6PartialStateSetFinished
} from '../ipv6/ipv6.actions';
import { speedTestConfigureFinished } from '../speed-test/speed-test.actions';
import {
  sendValue,
  setErrorState,
  setInitialState,
  setLoadedState,
  setLoadingState,
  setNextLoadedState
} from '../state-interfaces';
import { customerSetTechnician } from '../technician/technician.actions';
import {
  customerClose,
  customerChange,
  customerLocationConfigAndStateLoaded,
  samKnowsModeSet,
  customerLocationConfigAndStateLoadedError,
  samKnowsModeSetError,
  sipAlgModeSet,
  sipAlgModeSetError,
  customerLocationLoaded,
  customerLocationLoadedError,
  customerLoadedError,
  customerLoaded,
  customerCapabilitiesLoaded,
  customerCapabilitiesLoadedError,
  customerSetFromUtilities,
  customerLocationListLoaded,
  customerLocationListLoadedError,
  setSSID,
  primarySecondaryNetworksLoaded,
  appQoeModeSetError,
  appQoeModeSet,
  appPrioritizationLoaded,
  appPrioritizationLoadedError,
  appPrioritizationMonitoringLoaded,
  appPrioritizationMonitoringLoadedError,
  secondaryNetworksLoaded,
  logpullHistoryLoaded,
  logpullHistoryLoadedError,
  mobilizeProblemsHistoryLoaded,
  mobilizeProblemsHistoryLoadedError,
  appTimeLoaded,
  appTimeLoadedError,
  appPrioritizationV2LoadedError,
  appPrioritizationV2Loaded
} from './customer.actions';

const customerState = {
  customerid: null as string | null,
  locationid: null as string | null
};

const locationConfigAndState: IConfigAndState = {
  config: {
    locationId: null,
    networks: null,
    lte: null,
    samKnows: null,
    sipAlg: null,
    nodeLogging: null,
  },
  state: {
    nodeState: null,
    locationId: null,
    capabilities: null,
    lte: null,
    samKnows: null,
    sipAlg: null,
    appTime: null,
    powerManagement: null,
    mapTe: null
  }
};

export const initialState = {
  identification: setInitialState<typeof customerState, unknown>(customerState),
  locationConfigState: setInitialState<IConfigAndState, unknown>(locationConfigAndState),
  customer: setInitialState<ICustomer, unknown>(null),
  location: setInitialState<ILocation, unknown>(null),
  locationList: setInitialState<ILocation[], unknown>(null),
  capabilities: setInitialState<ICapabilities, unknown>(null),
  primarySecondaryNetworks: setInitialState<IPrimarySecondaryNetworks, unknown>(null),
  secondaryNetworks: setInitialState<ISecondaryNetworks, unknown>(null),
  trafficClass: {
    appPrioritization: setInitialState<IAppPrioritization, unknown>(null),
    appPrioritizationV2: setInitialState<IAppPrioritizationV2, unknown>(null),
    monitoring: setInitialState<IAppPrioritizationMonitoring, unknown>(null)
  },
  logpullHistory: setInitialState<ILocationLog[], unknown>([]),
  mobilizeProblemsHistory: setInitialState<IMobilizeLog[], unknown>([]),
  appTime: setInitialState<IAppTime, unknown>(null),
  partnerIdLoading: false
};

export const CustomerReducer = createReducer(
  initialState,
  on(customerChange, (state, { customerid, locationid }) => ({
    ...state,
    identification: setLoadedState({ customerid, locationid }),
    locationConfigState: setLoadingState(state.locationConfigState)
  })),
  on(customerClose, (state) => ({
    ...state,
    identification: setLoadedState({ customerid: null, locationid: null })
  })),
  on(
    customerLoaded,
    partnerIdSaved,
    customerSetFromEmailVerified,
    customerSetFromRename,
    customerSetFromUtilities,
    customerSetTechnician,
    (state, { customer }) => ({
      ...state,
      customer: setLoadedState({ ...customer, loadedTime: new Date().valueOf() })
    })
  ),
  on(customerLoadedError, (state, { error }) => ({
    ...state,
    customer: setErrorState(state.customer, error)
  })),

  on(customerLocationLoaded, (state, { location }) => ({
    ...state,
    location: setLoadedState({
      ...location,
      loadedTime: new Date().valueOf(),
      errorRecoverDetector: state.location.data?.errorRecoverDetector
    })
  })),
  on(customerLocationLoadedError, (state, { error }) => ({
    ...state,
    location: setErrorState(state.location, error)
  })),

  on(customerSelected, (state, { locationList }) => ({
    ...state,
    locationList: setLoadedState(locationList ? locationList : state.locationList.data)
  })),
  on(customerLocationListLoaded, (state, { locationList }) => ({
    ...state,
    locationList: setLoadedState(locationList)
  })),
  on(customerLocationListLoadedError, (state, { error }) => ({
    ...state,
    locations: setErrorState(state.locationList, error)
  })),

  on(customerLocationConfigAndStateLoaded, (state, { configAndState }) => ({
    ...state,
    locationConfigState: setLoadedState({ ...configAndState, loadedTime: new Date().valueOf() })
  })),
  on(customerLocationConfigAndStateLoadedError, (state, { error }) => ({
    ...state,
    locationConfigState: setErrorState(state.locationConfigState, error)
  })),
  on(customerCapabilitiesLoaded, (state, { capabilities }) => ({
    ...state,
    capabilities: setLoadedState(capabilities)
  })),
  on(customerCapabilitiesLoadedError, (state, { error }) => ({
    ...state,
    capabilities: setErrorState(state.capabilities, error)
  })),
  on(primarySecondaryNetworksLoaded, (state, { options }) => ({
    ...state,
    primarySecondaryNetworks: setLoadedState(options)
  })),
  on(secondaryNetworksLoaded, (state, { options }) => ({
    ...state,
    secondaryNetworks: setLoadedState(options)
  })),
  on(samKnowsModeSet, (oldState, { mode }) =>
    produce(oldState, (newState) => {
      newState.locationConfigState.data.config.samKnows.mode = mode;
      newState.locationConfigState = setNextLoadedState(newState.locationConfigState.data);
    })
  ),
  on(samKnowsModeSetError, (state, { error }) => ({
    ...state,
    locationConfigState: setErrorState(state.locationConfigState, error)
  })),
  on(appQoeModeSet, (oldState, { mode }) =>
    produce(oldState, (newState) => {
      newState.locationConfigState.data.config.appTime.appQoe = mode;
      newState.locationConfigState = setNextLoadedState(newState.locationConfigState.data);
    })
  ),
  on(appQoeModeSetError, (state, { error }) => ({
    ...state,
    locationConfigState: setErrorState(state.locationConfigState, error)
  })),
  on(sipAlgModeSet, (oldState, { mode }) =>
    produce(oldState, (newState) => {
      newState.locationConfigState.data.config.sipAlg.mode = mode;
      newState.locationConfigState = setNextLoadedState(newState.locationConfigState.data);
    })
  ),
  on(sipAlgModeSetError, (state, { error }) => ({
    ...state,
    locationConfigState: setErrorState(state.locationConfigState, error)
  })),
  on(partnerIdSet, (state) => ({
    ...state,
    partnerIdLoading: true
  })),
  on(partnerIdSaved, (state, { customer }) => ({
    ...state,
    partnerIdLoading: false,
    location: setNextLoadedState({ ...state.location.data, partnerId: customer.partnerId, partnerName: '' })
  })),
  on(partnerIdSavedError, (state) => ({
    ...state,
    partnerIdLoading: false
  })),
  on(optimizationsAutoModeEnable, (state, { value }) => ({
    ...state,
    location: sendValue(
      state.location,
      produce(state.location.data, (newLocation) => {
        newLocation.optimizations.auto = value;
        newLocation.optimizations.enable = value;
      })
    )
  })),
  on(optimizationsAutoModeEnableFinished, (state) => ({
    ...state,
    location: setNextLoadedState(state.location.data)
  })),
  on(
    optimizationsAutoModeEnableError,
    optimizationsDfsModeSetError,
    bandSteeringSetError,
    serviceLevelSetError,
    networkModeSetError,
    serviceIdSetError,
    locationNameSetError,
    IPv6PartialStateSetError,
    IPv6ModeSetError,
    IPv6AddressingConfigSetError,
    podsAuthorizationSetError,
    nodesAuthorizationSetError,
    profileSetError,
    dppSetError,
    configOptimizationsZeroWaitDfsModeSetError,
    configOptimizationsPrefer160MhzSetError,
    configOptimizationsMax24GhzChannelWidthSetError,
    configOptimizationsMax5GhzChannelWidthSetError,
    configOptimizationsMax6GhzChannelWidthSetError,
    configOptimizationsHopPenaltyModeSetError,
    configOptimizationsPreCACSchedulerSetError,
    configPaSAppTimeEnableSetError,
    configPaSPreferredIntelligenceSetError,
    configPaSIpThreatIntelligenceSetError,
    configPaSWcHealthTelemetrySetError,
    configPaSWcHeroTelemetrySetError,
    configPaSIpThreatProtectSetError,
    configPaSInlineDpiSetError,
    configPaSDosProtectionSetError,
    configPaSDosProtectionActionSetError,
    configPaSDpiContentFilteringSetError,
    configPaSFlowStatsSetError,
    (state) => ({
      ...state,
      location: setLoadedState({
        ...state.location.lastValidData,
        errorRecoverDetector: (state.location.data.errorRecoverDetector || 0) + 1
      })
    })
  ),
  on(optimizationsDfsModeSet, (state, { value }) => ({
    ...state,
    location: sendValue(
      state.location,
      produce(state.location.data, (newLocation) => {
        newLocation.optimizations.dfsMode = value;
        newLocation.dfsMode = undefined;
      })
    )
  })),
  on(optimizationsDfsModeSetFinished, (state) => ({
    ...state,
    location: setNextLoadedState(state.location.data)
  })),
  on(bandSteeringSet, steeringBandSteeringSet, (state, { value }) => ({
    ...state,
    location: sendValue(
      state.location,
      produce(state.location.data, (newLocation) => {
        newLocation.bandSteering.mode = value;
      })
    )
  })),
  on(bandSteeringSetFinished, (state, steering) => ({
    ...state,
    location: setLoadedState(
      produce(state.location.data, (newLocation) => {
        newLocation.bandSteering = steering;
      })
    )
  })),
  on(serviceLevelSet, (state, { value }) => ({
    ...state,
    location: sendValue(
      state.location,
      produce(state.location.data, (newLocation) => {
        newLocation.serviceLevel.status = value === 'reset' ? undefined : value;
        newLocation.serviceLevel.auto = value === 'reset';
      })
    )
  })),
  on(serviceLevelSetFinished, (state, { steering }) => ({
    ...state,
    location: setLoadedState({
      ...state.location.data,
      serviceLevel: { ...state.location.data.serviceLevel, ...steering }
    })
  })),
  on(networkModeSet, networkModeSetWan, (state, { value }) => ({
    ...state,
    location: sendValue(
      state.location,
      produce(state.location.data, (newLocation) => {
        newLocation.networkMode = value;
      })
    )
  })),
  on(networkModeSetFinished, (state, { value }) => ({
    ...state,
    location: setLoadedState({
      ...state.location.data,
      networkMode: value
    })
  })),
  on(serviceIdSet, (state, { value }) => ({
    ...state,
    location: sendValue(state.location, { ...state.location.data, serviceId: value })
  })),
  on(serviceIdSetFinished, (state, { }) => ({
    ...state,
    location: setNextLoadedState(state.location.data)
  })),
  on(locationNameSet, (state, { value }) => ({
    ...state,
    location: sendValue(state.location, { ...state.location.data, name: value })
  })),
  on(locationNameSetFinished, (state, { }) => ({
    ...state,
    location: setNextLoadedState(state.location.data)
  })),
  on(speedTestConfigureFinished, (state, { value }) => ({
    ...state,
    location: setLoadedState({ ...state.location.data, ispSpeedTestConfiguration: value })
  })),
  on(IPv6PartialStateSet, (state, { partialState }) => ({
    ...state,
    location: sendValue(state.location, {
      ...state.location.data,
      ipv6: { ...state.location.data.ipv6, ...partialState }
    })
  })),
  on(IPv6ModeSet, (state, { mode }) => ({
    ...state,
    location: sendValue(state.location, {
      ...state.location.data,
      ipv6: { ...state.location.data.ipv6, mode }
    })
  })),
  on(IPv6AddressingConfigSet, (state, { addressingConfig }) => ({
    ...state,
    location: sendValue(state.location, {
      ...state.location.data,
      ipv6: { ...state.location.data.ipv6, addressingConfig }
    })
  })),
  on(IPv6PartialStateSetFinished, IPv6ModeSetFinished, IPv6AddressingConfigSetFinished, (state, { ipv6 }) => ({
    ...state,
    location: setLoadedState({
      ...state.location.data,
      ipv6
    })
  })),
  on(nodesAuthorizationSet, (state, { nodes }) => ({
    ...state,
    location: sendValue(state.location, {
      ...state.location.data,
      authorizations: produce(state.location.data.authorizations, (newState) => {
        newState.numNodesAuthorized = nodes;
      })
    })
  })),
  on(podsAuthorizationSet, (state, { count }) => ({
    ...state,
    location: sendValue(state.location, {
      ...state.location.data,
      authorizations: produce(state.location.data.authorizations, (newState) => {
        newState.numPodsAuthorized = count;
      })
    })
  })),
  on(nodesAuthorizationSetFinished, podsAuthorizationSetFinished, (state, { value }) => ({
    ...state,
    location: setLoadedState({
      ...state.location.data,
      authorizations: value
    })
  })),
  on(setSSID, (state, { ssid }) => ({
    ...state,
    location: setLoadedState({
      ...state.location.data,
      ssid
    })
  })),
  on(profileSet, (state, { profile }) => ({
    ...state,
    location: sendValue(state.location, {
      ...state.location.data,
      profile
    })
  })),
  on(profileSetFinished, (state, { profile }) => ({
    ...state,
    location: setLoadedState({
      ...state.location.data,
      profile
    })
  })),
  on(dppSet, (state, { dpp }) => ({
    ...state,
    location: sendValue(state.location, {
      ...state.location.data,
      dppConfiguration: { ...state.location.data.dppConfiguration, mode: dpp }
    })
  })),
  on(dppSetFinished, (state) => ({
    ...state,
    location: setNextLoadedState(state.location.data)
  })),
  on(dppLoaded, (state, { dppConfiguration }) => ({
    ...state,
    location: setLoadedState({
      ...state.location.data,
      dppConfiguration
    })
  })),
  on(backhaulSetFinished, (state, { backhaul }) => ({
    ...state,
    location: setNextLoadedState({
      ...state.location.data,
      backhaul
    })
  })),
  on(configOptimizationsZeroWaitDfsModeSet, (state, { value }) => ({
    ...state,
    location: setNextLoadedState({
      ...state.location.data,
      optimizations: { ...state.location.data.optimizations, zeroWaitDfsMode: value }
    })
  })),
  on(configOptimizationsPrefer160MhzSet, (state, { value }) => ({
    ...state,
    location: setNextLoadedState({
      ...state.location.data,
      optimizations: { ...state.location.data.optimizations, prefer160MhzMode: value }
    })
  })),
  on(configOptimizationsMax24GhzChannelWidthSet, (state, { value }) => ({
    ...state,
    location: setNextLoadedState({
      ...state.location.data,
      optimizations: { ...state.location.data.optimizations, maxBandwidth: { maxBandwidth24g: value, maxBandwidth5g: state.location.data.optimizations.maxBandwidth.maxBandwidth5g, maxBandwidth6g: state.location.data.optimizations.maxBandwidth.maxBandwidth6g } }
    })
  })),
  on(configOptimizationsMax5GhzChannelWidthSet, (state, { value }) => ({
    ...state,
    location: setNextLoadedState({
      ...state.location.data,
      optimizations: { ...state.location.data.optimizations, maxBandwidth: { maxBandwidth5g: value, maxBandwidth24g: state.location.data.optimizations.maxBandwidth.maxBandwidth24g, maxBandwidth6g: state.location.data.optimizations.maxBandwidth.maxBandwidth6g } }
    })
  })),
  on(configOptimizationsMax6GhzChannelWidthSet, (state, { value }) => ({
    ...state,
    location: setNextLoadedState({
      ...state.location.data,
      optimizations: { ...state.location.data.optimizations, maxBandwidth: { maxBandwidth6g: value, maxBandwidth24g: state.location.data.optimizations.maxBandwidth.maxBandwidth24g, maxBandwidth5g: state.location.data.optimizations.maxBandwidth.maxBandwidth5g } }
    })
  })),
  on(configOptimizationsHopPenaltyModeSet, (state, { value }) => ({
    ...state,
    location: setNextLoadedState({
      ...state.location.data,
      optimizations: { ...state.location.data.optimizations, hopPenalty: value }
    })
  })),
  on(configOptimizationsPreCACSchedulerSet, (state, { value }) => ({
    ...state,
    location: setNextLoadedState({
      ...state.location.data,
      optimizations: { ...state.location.data.optimizations, preCACScheduler: value }
    })
  })),
  on(
    configOptimizationsZeroWaitDfsModeSetFinished,
    configOptimizationsPrefer160MhzSetFinished,
    configOptimizationsMax24GhzChannelWidthSetFinished,
    configOptimizationsMax5GhzChannelWidthSetFinished,
    configOptimizationsMax6GhzChannelWidthSetFinished,
    configOptimizationsHopPenaltyModeSetFinished,
    configOptimizationsPreCACSchedulerSetFinished,
    (state) => ({
      ...state,
      location: setNextLoadedState(state.location.data)
    })
  ),
  on(configPaSAppTimeEnableSet, (state, { value }) => ({
    ...state,
    location: sendValue(state.location, { ...state.location.data, appTime: value.enable })
  })),
  on(configPaSAppTimeEnableSetFinished, (state, { value }) => ({
    ...state,
    location: setLoadedState({ ...state.location.data, appTime: value })
  })),
  on(configPaSPreferredIntelligenceSet, (state, { value }) => ({
    ...state,
    location: sendValue(state.location, {
      ...state.location.data,
      securityConfiguration: { ...state.location.data.securityConfiguration, preferredIntelligence: value }
    })
  })),
  on(configPaSIpThreatIntelligenceSet, (state, { value }) => ({
    ...state,
    location: sendValue(state.location, {
      ...state.location.data,
      securityConfiguration: { ...state.location.data.securityConfiguration, ipThreatIntelligence: value }
    })
  })),
  on(configPaSWcHealthTelemetrySet, (state, { value }) => ({
    ...state,
    location: sendValue(state.location, {
      ...state.location.data,
      securityConfiguration: { ...state.location.data.securityConfiguration, wcHealthTelemetry: value }
    })
  })),
  on(configPaSWcHeroTelemetrySet, (state, { value }) => ({
    ...state,
    location: sendValue(state.location, {
      ...state.location.data,
      securityConfiguration: { ...state.location.data.securityConfiguration, wcHeroTelemetry: value }
    })
  })),
  on(configPaSIpThreatProtectSet, (state, { value }) => ({
    ...state,
    location: sendValue(state.location, {
      ...state.location.data,
      securityConfiguration: { ...state.location.data.securityConfiguration, ipThreatProtect: value }
    })
  })),
  on(configPaSInlineDpiSet, (state, { value }) => ({
    ...state,
    location: sendValue(state.location, {
      ...state.location.data,
      securityConfiguration: { ...state.location.data.securityConfiguration, inlineDpi: value }
    })
  })),
  on(configPaSDosProtectionSet, (state, { value }) => ({
    ...state,
    location: sendValue(state.location, {
      ...state.location.data,
      securityConfiguration: {
        ...state.location.data.securityConfiguration,
        dosProtection: { ...state.location.data.securityConfiguration.dosProtection, mode: value }
      }
    })
  })),
  on(configPaSDosProtectionActionSet, (state, { value }) => ({
    ...state,
    location: sendValue(state.location, {
      ...state.location.data,
      securityConfiguration: {
        ...state.location.data.securityConfiguration,
        dosProtection: { ...state.location.data.securityConfiguration.dosProtection, action: value }
      }
    })
  })),
  on(configPaSDpiContentFilteringSet, (state, { value }) => ({
    ...state,
    location: sendValue(state.location, {
      ...state.location.data,
      securityConfiguration: { ...state.location.data.securityConfiguration, dpiContentFiltering: value }
    })
  })),
  on(
    configPaSPreferredIntelligenceSetFinished,
    configPaSIpThreatIntelligenceSetFinished,
    configPaSWcHealthTelemetrySetFinished,
    configPaSWcHeroTelemetrySetFinished,
    configPaSIpThreatProtectSetFinished,
    configPaSInlineDpiSetFinished,
    configPaSDosProtectionSetFinished,
    configPaSDosProtectionActionSetFinished,
    configPaSDpiContentFilteringSetFinished,

    (state, { securityConfiguration }) => ({
      ...state,
      location: setLoadedState({ ...state.location.data, securityConfiguration })
    })
  ),
  on(
    configPaSFlowStatsSetFinished,

    (state) => ({
      ...state,
      location: setNextLoadedState({ ...state.location.data })
    })
  ),
  on(appPrioritizationLoaded, (state, { appPrioritization }) => ({
    ...state,
    trafficClass: {
      ...state.trafficClass,
      appPrioritization: setLoadedState(appPrioritization)
    }
  })),
  on(appPrioritizationLoadedError, (state) => ({
    ...state,
    trafficClass: {
      ...state.trafficClass,
      appPrioritization: setLoadedState(null)
    }
  })),
  on(appPrioritizationV2Loaded, (state, { appPrioritizationV2 }) => ({
    ...state,
    trafficClass: {
      ...state.trafficClass,
      appPrioritizationV2: setLoadedState(appPrioritizationV2)
    }
  })),
  on(appPrioritizationV2LoadedError, (state) => ({
    ...state,
    trafficClass: {
      ...state.trafficClass,
      appPrioritizationV2: setLoadedState(null)
    }
  })),
  on(appPrioritizationMonitoringLoaded, (state, { monitoring }) => ({
    ...state,
    trafficClass: {
      ...state.trafficClass,
      monitoring: setLoadedState(monitoring)
    }
  })),
  on(appPrioritizationMonitoringLoadedError, (state) => ({
    ...state,
    trafficClass: {
      ...state.trafficClass,
      monitoring: setLoadedState(null)
    }
  })),
  on(logpullHistoryLoaded, (state, { logpullHistory }) => ({
    ...state,
    logpullHistory: setLoadedState(logpullHistory)
  })),
  on(logpullHistoryLoadedError, (state, { error }) => ({
    ...state,
    logpullHistory: setErrorState(state.logpullHistory, error, [])
  })),
  on(mobilizeProblemsHistoryLoaded, (state, { mobilizeProblemsHistory }) => ({
    ...state,
    mobilizeProblemsHistory: setLoadedState(mobilizeProblemsHistory)
  })),
  on(mobilizeProblemsHistoryLoadedError, (state, { error }) => ({
    ...state,
    mobilizeProblemsHistory: setErrorState(state.mobilizeProblemsHistory, error, [])
  })),
  on(appTimeLoaded, (state, { appTime }) => ({
    ...state,
    appTime: setLoadedState(appTime)
  })),
  on(appTimeLoadedError, (state, { error }) => ({
    ...state,
    appTime: setErrorState(state.appTime, error)
  }))
);
