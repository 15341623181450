import { Component, Input } from '@angular/core';
import { IPowerState } from 'src/app/lib/interfaces/interface';

@Component({
  selector: 'header-volt',
  templateUrl: './header-volt.component.html',
  styleUrls: ['./header-volt.component.scss']
})
export class HeaderVoltComponent {
  @Input() powerState: IPowerState | null;

  constructor() {}
}
