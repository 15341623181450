<div class="grid">
  <div class="header">{{ 'health.networkInformation.signInHistoryEventHeader.createdAt' | translate }}</div>
  <div class="header">{{ 'health.networkInformation.signInHistoryEventHeader.event' | translate }}</div>
  <div class="header">{{ 'health.networkInformation.signInHistoryEventHeader.expiredAt' | translate }}</div>
  <div class="header">{{ 'health.networkInformation.signInHistoryEventHeader.accountId' | translate }}</div>
  <div class="header">{{ 'health.networkInformation.signInHistoryEventHeader.partnerId' | translate }}</div>
  <div class="header">{{ 'health.networkInformation.signInHistoryEventHeader.id' | translate }}</div>
  <div class="header">{{ 'health.networkInformation.signInHistoryEventHeader.xRequestId' | translate }}</div>
  <ng-container *ngFor="let event of audits$ | async">
    <div>{{ event.createdAt | date: 'l LT' }}</div>
    <div>{{ 'health.networkInformation.signInHistoryEventTypes.' + event.event | translate }}</div>
    <div>{{ event.expireAt | date: 'l LT' }}</div>
    <div>{{ event.accountId }}</div>
    <div>{{ event.partnerId }}</div>
    <div>{{ event.id }}</div>
    <div>{{ event.xRequestId }}</div></ng-container
  >
</div>
