import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'guardcounts',
  templateUrl: './guardcounts.component.html',
  styleUrls: ['./guardcounts.component.scss']
})
export class GuardCountsComponent implements OnChanges {
  @Input()
  reset: number = 0;

  @Input()
  hourlyCounts: any = {};

  guardCounts: any = {};
  loading: boolean = true;

  constructor() {}

  ngOnChanges(changes: any): void {
    if (changes.reset) {
      this.loading = true;
    }

    if (changes.hourlyCounts) {
      this.prepare();
    }
  }

  prepare(): void {
    if (this.hourlyCounts) {
      const counts = {};
      const filter = {
        teenagers: 'contentAccess',
        kids: 'contentAccess',
        adultAndSensitive: 'contentAccess',
        workAppropriate: 'contentAccess',
        ipThreat: 'onlineProtection',
        secureAndProtect: 'onlineProtection',
        'SecureAndProtect.outboundIpBlocked': 'onlineProtection',
        'SecureAndProtect.inboundIpBlocked': 'onlineProtection',
        anomaly: 'anomaly',
        'IoTProtect.blacklistedAnomalyRollback': 'anomaly',
        'IoTProtect.whitelistedAnomaly': 'anomaly',
        'IoTProtect.blacklistedAnomaly': 'anomaly',
        'IoTProtect.rolledbackAnomaly': 'anomaly'
      };

      Object.keys(this.hourlyCounts).forEach((section: any) => {
        this.hourlyCounts[section].forEach((day: any) => {
          day.forEach((hour: any) => {
            section = filter[section] || section;

            if (counts[section]) {
              counts[section] += hour;
            } else {
              counts[section] = hour;
            }
          });
        });
      });

      this.guardCounts = counts;
      this.loading = false;
    }
  }
}
