<div class="row" xmlns="http://www.w3.org/1999/html">
  <div class="col-xs-12" [class.dim]="loading">
    <box>
      <div header>
        <div class="name">
          <div class="circle">
            <icon [path]="getIcon(node?.model)" class="size-1_4"></icon>
            <icon
              [path]="gateWayIcon$ | async"
              class="size-0_8 appendix"
              [ngClass]="node?.connectionState"
              *ngIf="node?.connectionState == 'connected' && node?.isGateway && (isDemo$ | async) === false"
            ></icon>
            <icon
              name="icon-5g"
              class="size-0_8 appendix"
              [ngClass]="node?.connectionState"
              *ngIf="node?.connectionState == 'connected' && node?.isGateway && (isDemo$ | async) === true"
            ></icon>
          </div>
          <div class="data">
            <div class="nodeName">
              <div class="editable">
                <div class="nickname" [class.hide]="node.isRename">{{ node?.nickname || node?.defaultName }}</div>
                <div class="nickname" [class.hide]="!node.isRename">
                  <div class="input">
                    <input
                      #input
                      class="name-input"
                      (keyup.enter)="name.value?.trim() && confirmRename()"
                      [formControl]="name"
                      type="text"
                      placeholder="{{ 'nodes.node.newName' | translate }}"
                    />
                  </div>
                  <div class="data controls">
                    <icon
                      name="icon-check"
                      class="confirm"
                      tooltip="{{ 'techdash.nodeStrip.tooltip.save' | translate }}"
                      (click)="confirmRename()"
                      *ngIf="name.value?.trim()"
                    ></icon>
                    <icon
                      name="icon-close"
                      class="size-0_8 cancel"
                      tooltip="{{ 'techdash.nodeStrip.tooltip.cancel' | translate }}"
                      (click)="cancelRename()"
                    ></icon>
                  </div>
                </div>
                <div class="name-input-underline" [class.show]="node.isRename"></div>
              </div>
              <div class="status mobile">
                <div class="copy-link">
                  <icon
                    name="icon-copy-link"
                    tooltip="{{ 'nodes.node.copyLink' | translate }}"
                    (click)="copyLink($event)"
                  ></icon>
                </div>
                <drop-down-icon class="medium" tooltip="{{ 'nodes.node.options' | translate }}" *ngIf="!node.isRename">
                  <li *ngIf="showNodeRebootOption && node?.connectionState == 'connected'" (click)="reboot()">
                    {{ 'nodes.node.reboot' | translate }}
                  </li>
                  <li
                    *ngIf="showNodeRenameOption && permissions?.uiFeatures.renameNodes"
                    (click)="name.setValue(node.nickname); launchRename()"
                  >
                    {{ 'nodes.node.rename' | translate }}
                  </li>
                  <li
                    *ngIf="(isDemo$ | async) === true && node.isGateway && node.connectionState == 'connected'"
                    (click)="toggle5GModal(true)"
                  >
                    {{ 'nodes.node.wan5g' | translate }}
                  </li>
                  <li
                    *ngIf="
                      permissions?.qoeAccess &&
                      node.rawQoe &&
                      node.medium != 'ethernet' &&
                      node.connectionState == 'connected'
                    "
                    (click)="toggleQoeModal(true)"
                  >
                    {{ 'nodes.node.qoe' | translate }}
                  </li>
                  <li
                    *ngIf="node?.connectionState === 'connected' && showLedModeOption && ledMode === 'locate'"
                    (click)="setLedMode('locate')"
                  >
                    {{ 'nodes.node.ledLocate' | translate }}
                  </li>
                  <li
                    *ngIf="node?.connectionState === 'connected' && showLedModeOption && ledMode === 'normal'"
                    (click)="setLedMode('normal')"
                  >
                    {{ 'nodes.node.ledNormal' | translate }}
                  </li>
                  <li
                    (click)="launchDeleteNode()"
                    [class.disable]="isUprise$ | async"
                    *ngIf="permissions?.uiFeatures.deleteNodes && showDeleteOption"
                  >
                    {{ 'nodes.node.deleteNode' | translate }}
                  </li>
                  <li (click)="openHardwareInfoModal.emit()">
                    {{ 'nodes.node.hardwareInfo' | translate }}
                  </li>
                  <li
                    *ngIf="this.node.showPuncturingListItem && this.puncChan.length > 0"
                    (click)="showPuncturingModal = true"
                  >
                    {{ 'nodes.node.puncturedChannels' | translate }}
                  </li>
                  <li *ngIf="this.node.isUprise" (click)="openUprise()">
                    {{ 'nodes.node.openUprise' | translate }}
                  </li>
                </drop-down-icon>
                <div
                  class="circle {{ node?.connectionState }}"
                  tooltip="
                {{ node?.connectionStateMsg | translate : { datetime: node?.connectionStateTime } }}<br>
                {{ node?.booted.mode ? ('nodes.node.bootedAt' | translate : { datetime: node?.booted.at }) : '' }}
                {{ node?.booted.mode ? '<br>' : '' }}
                {{
                    node?.booted.mode
                      ? ('nodes.node.uptime' + node?.booted.mode
                        | translate
                          : { days: node?.booted.days, hours: node?.booted.hours, minutes: node?.booted.minutes })
                      : ''
                  }}
                {{ node?.booted.mode ? '<br>' : '' }}
                {{
                    node?.claimedAt
                      ? ('nodes.node.claimedAt'
                        | translate : { datetime: moment.utc(node?.claimedAt).local().format('L LT') })
                      : ''
                  }}"
                ></div>
              </div>
            </div>
            <div class="sn">{{ 'nodes.node.id' | translate }}: {{ node?.id }}</div>
            <div class="alarms mobile">
              <div class="txt">{{ 'nodes.node.alarms' | translate }}:</div>
              <div class="list">
                <span class="num" *ngIf="node?.connectionState == 'disconnected'">
                  {{ 'nodes.node.alarmsOffline' | translate }}
                </span>
                <span class="num" *ngIf="node?.connectionState == 'connected' && !node?.alarms">
                  {{ 'nodes.node.alarmsNone' | translate }}
                </span>
                <ng-container *ngIf="node?.connectionState == 'connected' && node?.alarms?.length > 0">
                  <div class="num" *ngFor="let alarm of node.alarms">
                    {{ 'nodes.node.' + alarm.type | translate }}
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
        <div class="other">
          <div class="alarms desktop">
            <div class="txt">{{ 'nodes.node.alarms' | translate }}:</div>
            <div class="list">
              <span class="num" *ngIf="node?.connectionState == 'disconnected'">
                {{ 'nodes.node.alarmsOffline' | translate }}
              </span>
              <span class="num" *ngIf="node?.connectionState == 'connected' && !node?.alarms">
                {{ 'nodes.node.alarmsNone' | translate }}
              </span>
              <ng-container *ngIf="node?.connectionState == 'connected' && node?.alarms?.length > 0">
                <div class="num" *ngFor="let alarm of node.alarms">
                  {{ 'nodes.node.' + alarm.type | translate }}
                </div>
              </ng-container>
            </div>
          </div>
          <div class="status desktop">
            <div class="copy-link">
              <icon
                name="icon-copy-link"
                tooltip="{{ 'nodes.node.copyLink' | translate }}"
                (click)="copyLink($event)"
              ></icon>
            </div>
            <drop-down-icon class="medium" tooltip="{{ 'nodes.node.options' | translate }}" *ngIf="!node.isRename">
              <li *ngIf="showNodeRebootOption && node?.connectionState == 'connected'" (click)="reboot()">
                {{ 'nodes.node.reboot' | translate }}
              </li>
              <li
                *ngIf="showNodeRenameOption && permissions?.uiFeatures.renameNodes"
                (click)="name.setValue(node.nickname); launchRename()"
              >
                {{ 'nodes.node.rename' | translate }}
              </li>
              <li
                *ngIf="(isDemo$ | async) === true && node.isGateway && node.connectionState == 'connected'"
                (click)="toggle5GModal(true)"
              >
                {{ 'nodes.node.wan5g' | translate }}
              </li>
              <li
                *ngIf="
                  permissions?.qoeAccess &&
                  node.rawQoe &&
                  node.medium != 'ethernet' &&
                  node.connectionState == 'connected'
                "
                (click)="toggleQoeModal(true)"
              >
                {{ 'nodes.node.qoe' | translate }}
              </li>
              <li
                *ngIf="node?.connectionState === 'connected' && showLedModeOption && ledMode === 'locate'"
                (click)="setLedMode('locate')"
              >
                {{ 'nodes.node.ledLocate' | translate }}
              </li>
              <li
                *ngIf="node?.connectionState === 'connected' && showLedModeOption && ledMode === 'normal'"
                (click)="setLedMode('normal')"
              >
                {{ 'nodes.node.ledNormal' | translate }}
              </li>
              <li
                (click)="launchDeleteNode()"
                [class.disable]="isUprise$ | async"
                *ngIf="permissions?.uiFeatures.deleteNodes && showDeleteOption"
              >
                {{ 'nodes.node.deleteNode' | translate }}
              </li>
              <li (click)="openHardwareInfoModal.emit()">
                {{ 'nodes.node.hardwareInfo' | translate }}
              </li>
              <li
                *ngIf="this.node.showPuncturingListItem && this.puncChan.length > 0"
                (click)="showPuncturingModal = true"
              >
                {{ 'nodes.node.puncturedChannels' | translate }}
              </li>
              <li *ngIf="this.node.isUprise" (click)="openUprise()">
                {{ 'nodes.node.openUprise' | translate }}
              </li>
            </drop-down-icon>
            <div
              class="circle {{ node?.connectionState }}"
              tooltip="
              {{ node?.connectionStateMsg | translate : { datetime: node?.connectionStateTime } }}<br>
              {{ node?.booted.mode ? ('nodes.node.bootedAt' | translate : { datetime: node?.booted.at }) : '' }}
              {{ node?.booted.mode ? '<br>' : '' }}
              {{
                node?.booted.mode
                  ? ('nodes.node.uptime' + node?.booted.mode
                    | translate : { days: node?.booted.days, hours: node?.booted.hours, minutes: node?.booted.minutes })
                  : ''
              }}
              {{ node?.booted.mode ? '<br>' : '' }}
              {{
                node?.claimedAt
                  ? ('nodes.node.claimedAt'
                    | translate : { datetime: moment.utc(node?.claimedAt).local().format('L LT') })
                  : ''
              }}"
            ></div>
          </div>
        </div>
      </div>
      <div container>
        <div class="data">
          <div class="data-table column desktop">
            <div class="data-row info" *ngIf="node?.name">
              <div class="data-cell key">{{ 'nodes.node.deviceType' | translate }}</div>
              <div class="data-cell value">
                <span tooltip="{{ node?.model }}">{{ node?.name }}</span>
              </div>
            </div>
            <div class="data-row info" *ngIf="node?.firmwareVersion">
              <div class="data-cell key">{{ 'nodes.node.firmwareVersion' | translate }}</div>
              <div class="data-cell value">
                <div class="firmware">
                  {{ node?.firmwareVersion }}
                  <div class="wrapper" toggler>
                    <icon
                      name="icon-info"
                      tooltip="{{ 'nodes.node.firmwareModules.title' | translate }}"
                      *ngIf="node?.firmwareModules"
                    ></icon>
                    <div class="list">
                      <div class="title">
                        {{ 'nodes.node.firmwareModules.title' | translate }}
                        <icon name="icon-close" class="size-0_5"></icon>
                      </div>
                      <div class="header">
                        <div class="name">{{ 'nodes.node.firmwareModules.name' | translate }}</div>
                        <div class="status">{{ 'nodes.node.firmwareModules.status' | translate }}</div>
                        <div class="integrated">{{ 'nodes.node.firmwareModules.integrated' | translate }}</div>
                        <div class="version">{{ 'nodes.node.firmwareModules.version' | translate }}</div>
                      </div>
                      <div class="data" *ngFor="let module of node.firmwareModules">
                        <div class="name">{{ module.name }}</div>
                        <div class="status">{{ module.status }}</div>
                        <div class="integrated">{{ module.integrated }}</div>
                        <div class="version">{{ module.version }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="data-row info"
              *ngIf="node?.manufacturerSerialNumber && node?.manufacturerSerialNumber !== node.id"
            >
              <div class="data-cell key">{{ 'nodes.node.manufacturerSerialNumber' | translate }}</div>
              <div class="data-cell value">{{ node?.manufacturerSerialNumber }}</div>
            </div>
            <div class="data-row info" *ngIf="node?.platformVersion && node?.platformVersion !== '1'">
              <div class="data-cell key">{{ 'nodes.node.platformVersion' | translate }}</div>
              <div class="data-cell value">{{ node?.platformVersion }}</div>
            </div>
            <div class="data-row info" *ngIf="node?.ports">
              <div class="data-cell key">{{ 'nodes.node.ethernetPorts' | translate }}</div>
              <div class="data-cell value">{{ node?.ports }}</div>
            </div>
            <div class="data-row info" *ngIf="node['2gChannel']">
              <div class="data-cell key">{{ 'nodes.node.wifi2g' | translate }}</div>
              <div class="data-cell value">
                <span *ngIf="node?.wifiCapabilities?.radio24">{{ node?.wifiCapabilities?.radio24 }},</span>
                Ch {{ node['2gChannel'] }}
                <span *ngIf="puncInfo['2.4G']"
                  >, {{ 'nodes.node.punctureWidth' | translate }} {{ puncInfo['2.4G'] }}</span
                >
                <icon
                  name="icon-info"
                  tooltip="{{ 'health.networkInformation.encryption' | translate }}: {{ node.wpaMode24g | translate }}"
                  *ngIf="node.wpaMode24g"
                ></icon>
              </div>
            </div>
            <div class="data-row info" *ngIf="node['5gChannel']">
              <div class="data-cell key">{{ 'nodes.node.wifi5g' | translate }}</div>
              <div class="data-cell value">
                <span *ngIf="node?.wifiCapabilities?.radio50">{{ node?.wifiCapabilities?.radio50 }},</span>
                Ch {{ node['5gChannel'] }}
                <span *ngIf="puncInfo['5G']">, {{ 'nodes.node.punctureWidth' | translate }} {{ puncInfo['5G'] }}</span>
                <icon
                  name="icon-info"
                  tooltip="{{ 'health.networkInformation.encryption' | translate }}: {{ node.wpaMode5g | translate }}"
                  *ngIf="node.wpaMode5g"
                ></icon>
              </div>
            </div>
            <div class="data-row info" *ngIf="node['5glChannel']">
              <div class="data-cell key">{{ 'nodes.node.wifi5g1' | translate }}</div>
              <div class="data-cell value">
                <span *ngIf="node?.wifiCapabilities?.radio50L">{{ node?.wifiCapabilities?.radio50L }},</span>
                Ch {{ node['5glChannel'] }}
                <span *ngIf="puncInfo['5GL']"
                  >, {{ 'nodes.node.punctureWidth' | translate }} {{ puncInfo['5GL'] }}</span
                >
                <icon
                  name="icon-info"
                  tooltip="{{ 'health.networkInformation.encryption' | translate }}: {{ node.wpaMode5gl | translate }}"
                  *ngIf="node.wpaMode5gl"
                ></icon>
              </div>
            </div>
            <div class="data-row info" *ngIf="node['5guChannel']">
              <div class="data-cell key">{{ 'nodes.node.wifi5g2' | translate }}</div>
              <div class="data-cell value">
                <span *ngIf="node?.wifiCapabilities?.radio50U">{{ node?.wifiCapabilities?.radio50U }},</span>
                Ch {{ node['5guChannel'] }}
                <span *ngIf="puncInfo['5GU']"
                  >, {{ 'nodes.node.punctureWidth' | translate }} {{ puncInfo['5GU'] }}</span
                >
                <icon
                  name="icon-info"
                  tooltip="{{ 'health.networkInformation.encryption' | translate }}: {{ node.wpaMode5gu | translate }}"
                  *ngIf="node.wpaMode5gu"
                ></icon>
              </div>
            </div>
            <div class="data-row info" *ngIf="node['6gChannel']">
              <div class="data-cell key">{{ 'nodes.node.wifi6g' | translate }}</div>
              <div class="data-cell value">
                <span *ngIf="node?.wifiCapabilities?.radio60">{{ node?.wifiCapabilities?.radio60 }},</span>
                Ch {{ node['6gChannel'] }}
                <span *ngIf="puncInfo['6G']">, {{ 'nodes.node.punctureWidth' | translate }} {{ puncInfo['6G'] }}</span>
                <icon
                  name="icon-info"
                  tooltip="{{ 'health.networkInformation.encryption' | translate }}: {{ node.wpaMode6g | translate }}"
                  *ngIf="node.wpaMode6g"
                ></icon>
              </div>
            </div>
            <ng-container *ngFor="let linkState of node?.linkStates">
              <div class="data-row info" *ngIf="linkState.isUplink || linkState.hasEthClient">
                <div class="data-cell key">{{ linkState.ifName }}</div>
                <div class="data-cell value">
                  {{ linkState.linkSpeed }} Mbps {{ linkState.duplex }} {{ linkState.isUplink ? 'uplink' : 'client' }}
                </div>
              </div>
            </ng-container>
            <div class="data-row info" *ngIf="node?.ethernetLan?.default?.mode === 'disable'">
              <div class="data-cell key">{{ 'nodes.node.ethernet' | translate }}</div>
              <div class="data-cell value warning">
                {{ 'nodes.node.disabled' | translate }}
              </div>
            </div>
            <div class="data-row info" *ngIf="node.leafToRoot.length > 0">
              <div class="data-cell key">{{ 'nodes.node.connection' | translate }}</div>
              <div class="data-cell value">
                <div class="leaf-to-root" *ngIf="node.leafToRoot.length > 0">
                  <div class="root-name">
                    <div class="icon-container">
                      <icon [path]="getIcon(node?.model)" class="size-1_4"></icon>
                    </div>
                    <span class="node-name">{{ node.nickname || node.defaultName || node.id }}</span>
                  </div>
                  <leaftoroot [node]="node" [device]="" [alarm]=""></leaftoroot>
                </div>
              </div>
            </div>
          </div>

          <div class="data-table column">
            <div class="data-row info mobile" *ngIf="node?.name">
              <div class="data-cell key">{{ 'nodes.node.deviceType' | translate }}</div>
              <div class="data-cell value">
                <span tooltip="{{ node?.model }}">{{ node?.name }}</span>
              </div>
            </div>
            <div class="data-row info mobile" *ngIf="node?.firmwareVersion">
              <div class="data-cell key">{{ 'nodes.node.firmwareVersion' | translate }}</div>
              <div class="data-cell value">{{ node?.firmwareVersion }}</div>
            </div>
            <div class="data-row info mobile" *ngIf="node?.ports">
              <div class="data-cell key">{{ 'nodes.node.ethernetPorts' | translate }}</div>
              <div class="data-cell value">{{ node?.ports }}</div>
            </div>
            <div class="data-row info mobile" *ngIf="node['2gChannel']">
              <div class="data-cell key">{{ 'nodes.node.wifi2g' | translate }}</div>
              <div class="data-cell value">
                <span *ngIf="node?.wifiCapabilities?.radio24">{{ node?.wifiCapabilities?.radio24 }},</span>
                Ch {{ node['2gChannel'] }}
                <icon
                  name="icon-info"
                  tooltip="{{ 'health.networkInformation.encryption' | translate }}: {{ node.wpaMode24g | translate }}"
                  *ngIf="node.wpaMode24g"
                ></icon>
              </div>
            </div>
            <div class="data-row info mobile" *ngIf="node['5gChannel']">
              <div class="data-cell key">{{ 'nodes.node.wifi5g' | translate }}</div>
              <div class="data-cell value">
                <span *ngIf="node?.wifiCapabilities?.radio50">{{ node?.wifiCapabilities?.radio50 }},</span>
                Ch {{ node['5gChannel'] }}
                <icon
                  name="icon-info"
                  tooltip="{{ 'health.networkInformation.encryption' | translate }}: {{ node.wpaMode5g | translate }}"
                  *ngIf="node.wpaMode5g"
                ></icon>
              </div>
            </div>
            <div class="data-row info mobile" *ngIf="node['5glChannel']">
              <div class="data-cell key">{{ 'nodes.node.wifi5g1' | translate }}</div>
              <div class="data-cell value">
                <span *ngIf="node?.wifiCapabilities?.radio50L">{{ node?.wifiCapabilities?.radio50L }},</span>
                Ch {{ node['5glChannel'] }}
                <icon
                  name="icon-info"
                  tooltip="{{ 'health.networkInformation.encryption' | translate }}: {{ node.wpaMode5gl | translate }}"
                  *ngIf="node.wpaMode5gl"
                ></icon>
              </div>
            </div>
            <div class="data-row info mobile" *ngIf="node['5guChannel']">
              <div class="data-cell key">{{ 'nodes.node.wifi5g2' | translate }}</div>
              <div class="data-cell value">
                <span *ngIf="node?.wifiCapabilities?.radio50U">{{ node?.wifiCapabilities?.radio50U }},</span>
                Ch {{ node['5guChannel'] }}
                <icon
                  name="icon-info"
                  tooltip="{{ 'health.networkInformation.encryption' | translate }}: {{ node.wpaMode5gu | translate }}"
                  *ngIf="node.wpaMode5gu"
                ></icon>
              </div>
            </div>
            <div class="data-row info mobile" *ngIf="node['6gChannel']">
              <div class="data-cell key">{{ 'nodes.node.wifi6g' | translate }}</div>
              <div class="data-cell value">
                <span *ngIf="node?.wifiCapabilities?.radio60">{{ node?.wifiCapabilities?.radio60 }},</span>
                Ch {{ node['6gChannel'] }}
                <icon
                  name="icon-info"
                  tooltip="{{ 'health.networkInformation.encryption' | translate }}: {{ node.wpaMode6g | translate }}"
                  *ngIf="node.wpaMode6g"
                ></icon>
              </div>
            </div>
            <div
              class="data-row info"
              *ngIf="node?.health?.status && !node?.isGateway && node?.connectionState == 'connected'"
            >
              <div class="data-cell key">{{ 'nodes.node.healthRating' | translate }}</div>
              <div class="data-cell value">
                <div class="group">
                  <health-line [value]="node.health?.score" [status]="node.health?.status"></health-line>
                  <div [ngClass]="'t-' + node?.health?.status" *ngIf="node?.health?.status">
                    {{ node?.health?.status | translate }}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="data-row info"
              *ngIf="node?.connectionState == 'connected' && (node?.rssi || node?.health?.details?.channelGain)"
            >
              <div class="data-cell key">{{ 'nodes.node.backhaulRssi' | translate }}</div>
              <div class="data-cell value">
                <div class="group" *ngIf="node?.rssi || node?.health?.details?.channelGain">
                  <health-dots [rssiOrQoe]="node"></health-dots>
                  <div class="num">
                    {{ node?.rssi || node?.health?.details?.channelGain + 20 | number : '1.0-2' }} dBm
                  </div>
                  <div class="backhaulChannel" *ngIf="node?.backhaulChannel">(Ch {{ node?.backhaulChannel }})</div>
                </div>
              </div>
            </div>
            <div
              class="data-row info"
              *ngIf="node?.health?.details?.interference && node?.connectionState == 'connected'"
            >
              <div class="data-cell key">{{ 'nodes.node.interference' | translate }}</div>
              <div class="data-cell value">
                <div class="group">
                  <health-ball [value]="node?.health?.details?.interference"></health-ball>
                  <div class="num" *ngIf="node?.health?.details?.interference">
                    {{ node?.health?.details?.interference | number : '1.0-2' }}%
                  </div>
                </div>
              </div>
            </div>
            <div class="data-row info" *ngIf="node?.mac">
              <div class="data-cell key">{{ 'nodes.node.mac' | translate }}</div>
              <div class="data-cell value">{{ node?.mac | macAddr }}</div>
            </div>
            <div class="data-row info" *ngIf="node?.connectionState == 'connected'">
              <div class="data-cell key">
                {{ 'nodes.node.IP' | translate }}
              </div>
              <div class="data-cell value">
                <span
                  *ngIf="node.dhcpLease?.leaseTime && node.dhcpLease?.leaseTime > 1"
                  class="tooltipHover"
                  tooltip="{{ 'nodes.node.DHCPLease' | translate }} : {{
                    'nodes.node.DHCPTime' | translate : getHMS(node.dhcpLease.leaseTime)
                  }}"
                >
                  {{ node?.ip }}
                </span>
                <span *ngIf="!node.dhcpLease?.leaseTime || node.dhcpLease?.leaseTime <= 1">
                  {{ node?.ip }}
                </span>
              </div>
            </div>
            <div class="data-row info list" *ngIf="node?.ipv6?.length && node?.connectionState == 'connected'">
              <div class="data-cell key">
                {{ 'nodes.node.ipv6' | translate }}
                <icon name="icon-info" class="size-0_8" (click)="toggleIPV6Modal(true)"></icon>
              </div>
              <div class="data-cell value truncate" *ngIf="!node.dhcpV6Lease?.leaseTime">
                <div class="data-trunc" *ngFor="let ip of node.ipv6">
                  {{ ip | ipv6 }}
                </div>
              </div>
              <div
                class="data-cell value truncate tooltipHover"
                *ngIf="node.dhcpV6Lease?.leaseTime && node.dhcpV6Lease?.leaseTime > 1"
                tooltip="{{ 'nodes.node.DHCPV6Lease' | translate }} : {{
                  'nodes.node.DHCPTime' | translate : getHMS(node.dhcpV6Lease.leaseTime)
                }}"
              >
                <div class="data-trunc" *ngFor="let ip of node.ipv6">
                  {{ ip | ipv6 }}
                </div>
              </div>
            </div>
            <div class="data-row info" *ngIf="node?.dhcpV6Lease?.leaseTime">
              <div class="data-cell key">
                {{ 'nodes.node.DHCPV6Lease' | translate }}
              </div>
              <div class="data-cell value" *ngIf="node.dhcpV6Lease?.leaseTime > 1">
                {{ 'nodes.node.DHCPTime' | translate : getHMS(node.dhcpV6Lease.leaseTime) }}"
              </div>
              <div class="data-cell value" *ngIf="!(node.dhcpV6Lease?.leaseTime > 1)">
                {{ 'nodes.node.expired' | translate }}"
              </div>
            </div>

            <div class="data-row info" *ngIf="node?.wanIp && node?.connectionState == 'connected'">
              <div class="data-cell key">{{ 'nodes.node.WANIP' | translate }}</div>
              <div class="data-cell value">{{ node?.wanIp | ipv4Wan }}</div>
            </div>
            <div
              class="data-row info"
              *ngIf="node?.publicIp && node?.connectionState == 'connected' && node?.isGateway"
            >
              <div class="data-cell key">
                {{ 'nodes.node.publicIp' | translate }}
                <icon name="icon-info" class="size-0_8" (click)="togglePublicIPModal(true)"></icon>
              </div>
              <div class="data-cell value truncate">
                <div class="data-trunc">{{ node?.publicIp | ipv4Wan }}</div>
              </div>
            </div>
            <div class="data-row info" *ngIf="node?.speed">
              <div class="data-cell key">{{ 'nodes.node.predictedSpeed' | translate }}</div>
              <div class="data-cell value">{{ 'nodes.node.noData' | translate }}</div>
            </div>
          </div>
        </div>

        <ng-container *ngIf="ui === 'noc'">
          <collapsiblebox
            mode="bare"
            [showDivider]="false"
            [show]="node.channelCongestionGraphOpen"
            [collapsible]="true"
            (toggle)="toggleChannelCongestionGraph()"
          >
            <ng-container header>{{ 'nodes.node.channelCongestionGraph.header' | translate }}</ng-container>
          </collapsiblebox>
          <ng-container *ngIf="node.channelCongestionGraphOpen">
            <div class="graph-wrap">
              <div class="options-wrap">
                <slidetoggler
                  [options]="congestionFrequencyItems"
                  (toggle)="action('congestionFrequency', $event)"
                ></slidetoggler>
                <slidetoggler
                  [options]="congestionPeriodItems"
                  (toggle)="action('congestionPeriod', $event)"
                ></slidetoggler>
              </div>
              <div class="stat" [class.show]="congestionStats">
                <span class="label interference-stat">
                  {{ 'nodes.node.channelCongestionGraph.avgInterference' | translate }}
                </span>
                {{ congestionStats?.interference }}
                <span class="label total-stat">
                  {{ 'nodes.node.channelCongestionGraph.avgTotal' | translate }}
                </span>
                {{ congestionStats?.total }}
              </div>
              <nodechart
                [autosize]="true"
                [series]="[node.congestionChart?.total.series, node.congestionChart?.interference.series]"
                [data]="[node.congestionChart?.total, node.congestionChart?.interference]"
                [scale]="{ range: [0, 100], symbol: '%' }"
                [period]="congestionPeriod"
              ></nodechart>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="ui === 'noc' && showHardwareHealth">
          <collapsiblebox
            mode="bare"
            [showDivider]="false"
            [show]="node.hardwareHealthGraphOpen"
            [collapsible]="true"
            (toggle)="toggleHardwareHealthGraph()"
          >
            <ng-container header>{{ 'nodes.node.hardwareHealth.header' | translate }}</ng-container>
          </collapsiblebox>
          <ng-container *ngIf="node.hardwareHealthGraphOpen">
            <div class="graph-wrap">
              <div class="options-wrap">
                <slidetoggler
                  [options]="hardwareInfoPeriodItems"
                  (toggle)="action('hardwareHealthPeriod', $event)"
                ></slidetoggler>
              </div>
              <ng-container *ngIf="showHardwareHealthOpenSync20Features">
                <div class="info">
                  <div>{{ 'nodes.node.hardwareHealth.cpuUtil' | translate }}</div>
                  <div class="stat" [class.show]="latestHardwareSnapshot?.cpuUtilPct">
                    <span class="label interference-stat">
                      {{ 'nodes.node.hardwareHealth.mostRecent' | translate }}
                    </span>
                    {{ latestHardwareSnapshot?.cpuUtilPct | number : '1.0-0' }}%
                  </div>
                </div>
                <nodechart
                  [autosize]="true"
                  [data]="[node.hardwareHealthChart?.cpuUtilPct]"
                  [scale]="{ range: [0, 100], symbol: '%' }"
                  [period]="hardwareHealthPeriod"
                ></nodechart>
                <div class="info">
                  <div>{{ 'nodes.node.hardwareHealth.memUsed' | translate }}</div>
                  <div class="stat" [class.show]="latestHardwareSnapshot?.memUsedPct">
                    <span class="label interference-stat">
                      {{ 'nodes.node.hardwareHealth.mostRecent' | translate }}
                    </span>
                    {{ latestHardwareSnapshot?.memUsedPct | number : '1.0-0' }}%
                  </div>
                </div>
                <nodechart
                  [autosize]="true"
                  [data]="[node.hardwareHealthChart?.memUsedPct]"
                  [scale]="{ range: [0, 100], symbol: '%' }"
                  [period]="hardwareHealthPeriod"
                ></nodechart
              ></ng-container>
              <ng-container *ngIf="radioTempFrequencyItems?.length > 0">
                <div class="options-wrap">
                  <div class="hardware-toggler-wrap">
                    <slidetoggler
                      [options]="radioTempFrequencyItems"
                      (toggle)="action('radioTempFrequency', $event)"
                    ></slidetoggler>
                  </div>
                </div>
                <div class="info">
                  <div>{{ 'nodes.node.hardwareHealth.radioTemp' | translate }}</div>
                  <div class="stat" [class.show]="latestRadioTemp">
                    <span class="label interference-stat">
                      {{ 'nodes.node.hardwareHealth.mostRecent' | translate }}
                    </span>
                    {{ latestRadioTemp | number : '1.0-0' }} &deg;C
                  </div>
                </div>
                <nodechart
                  [autosize]="true"
                  [data]="selectedRadioTempData"
                  [scale]="{ range: [0, 100], symbol: '°C' }"
                  [period]="hardwareHealthPeriod"
                ></nodechart>
              </ng-container>
              <ng-container *ngIf="showHardwareHealthOpenSync54Features">
                <div class="info">
                  <div>{{ 'nodes.node.hardwareHealth.fanRpm' | translate }}</div>
                  <div class="stat" [class.show]="latestHardwareSnapshot?.fanRpm">
                    <span class="label interference-stat">
                      {{ 'nodes.node.hardwareHealth.mostRecent' | translate }}
                    </span>
                    {{ latestHardwareSnapshot?.fanRpm | number : '1.0-0' }}
                    {{ 'nodes.node.hardwareHealth.rpm' | translate }}
                  </div>
                </div>
                <nodechart
                  [autosize]="true"
                  [data]="[node.hardwareHealthChart?.fanRpm]"
                  [scale]="{ range: [0, 8000], symbol: 'nodes.node.hardwareHealth.rpm' | translate }"
                  [period]="hardwareHealthPeriod"
                ></nodechart
              ></ng-container>
              <div class="info">
                <div>{{ 'nodes.node.hardwareHealth.uptime' | translate }}</div>
                <div class="stat" [class.show]="latestHardwareSnapshot?.uptimeInHrs">
                  <span class="label interference-stat">
                    {{ 'nodes.node.hardwareHealth.mostRecent' | translate }}
                  </span>
                  {{ latestHardwareSnapshot?.uptimeInHrs | number : '1.0-2' }} {{ 'hourShort' | translate }}
                </div>
              </div>
              <nodechart
                [autosize]="true"
                [data]="[node.hardwareHealthChart?.uptime]"
                [scale]="{ range: null, symbol: 'hourShort' | translate }"
                [period]="hardwareHealthPeriod"
              ></nodechart>
              <ng-container *ngIf="showHardwareHealthOpenSync64Features">
                <div class="info">
                  <div>{{ 'nodes.node.hardwareHealth.processMemoryUsed' | translate }}</div>
                  <drop-down class="selector medium" *ngIf="processesForMemoryChartData?.length">
                    {{ selectedProcessForMemoryChartData }}
                    <ng-container dropdown>
                      <li
                        (click)="selectProcessForMemoryChartData(process)"
                        *ngFor="let process of processesForMemoryChartData"
                      >
                        {{ process }}
                      </li>
                    </ng-container>
                  </drop-down>
                </div>
                <nodechart
                  [autosize]="true"
                  [data]="[node.hardwareHealthChart?.psMemUtil]"
                  [scale]="{ range: null, symbol: 'KB' }"
                  [period]="hardwareHealthPeriod"
                ></nodechart>
                <div class="info">
                  <div>{{ 'nodes.node.hardwareHealth.processCpuUtil' | translate }}</div>
                  <drop-down class="selector medium" *ngIf="processesForCpuChartData?.length">
                    {{ selectedProcessForCpuChartData }}
                    <ng-container dropdown>
                      <li
                        (click)="selectProcessForCpuChartData(process)"
                        *ngFor="let process of processesForCpuChartData"
                      >
                        {{ process }}
                      </li>
                    </ng-container>
                  </drop-down>
                </div>
                <nodechart
                  [autosize]="true"
                  [data]="[node.hardwareHealthChart?.psCpuUtil]"
                  [scale]="{ range: null, symbol: '%' }"
                  [period]="hardwareHealthPeriod"
                ></nodechart>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="ui === 'noc' && !node?.isGateway">
          <collapsiblebox
            mode="bare"
            [showDivider]="false"
            [show]="node.bandwidthUsageGraphOpen"
            [collapsible]="true"
            (toggle)="toggleBandwidthUsageGraph()"
          >
            <ng-container header>{{ 'nodes.node.bandwidthUsageGraph.header' | translate }}</ng-container>
          </collapsiblebox>
          <ng-container *ngIf="node.bandwidthUsageGraphOpen">
            <div class="graph-wrap">
              <div class="options-wrap">
                <slidetoggler
                  [options]="bandwidthFrequencyItems"
                  (toggle)="action('bandwidthFrequency', $event)"
                ></slidetoggler>
                <slidetoggler
                  [options]="bandwidthPeriodItems"
                  (toggle)="action('bandwidthPeriod', $event)"
                ></slidetoggler>
              </div>
              <div class="stat" [class.show]="bandwidthStats">
                <span class="label received-stat">
                  {{ 'nodes.node.bandwidthUsageGraph.totalReceived' | translate }}
                </span>
                {{ bandwidthStats?.received }}
                <span class="label transmitted-stat">
                  {{ 'nodes.node.bandwidthUsageGraph.totalTransmitted' | translate }}
                </span>
                {{ bandwidthStats?.transmitted }}
              </div>
              <nodechart
                [series]="[node.bandwidthChart?.received?.series, node.bandwidthChart?.transmitted?.series]"
                [data]="[node.bandwidthChart?.received, node.bandwidthChart?.transmitted]"
                [scale]="{ symbol: 'MB' }"
                [period]="bandwidthPeriod"
              ></nodechart>
            </div>
          </ng-container>
        </ng-container>

        <collapsiblebox
          mode="bare"
          [showDivider]="false"
          [show]="node.deviceListOpen"
          [collapsible]="true"
          (toggle)="toggleDeviceList()"
        >
          <ng-container header>
            {{ node?.devices?.length || 0 }} {{ 'nodes.node.connectedDevices' | translate }}
          </ng-container>
        </collapsiblebox>
        <ng-container *ngIf="node.deviceListOpen">
          <div class="connected-devices">
            <ng-container *ngIf="node?.devices?.length">
              <div class="device" *ngFor="let device of node?.devices; trackBy: track">
                <div class="column wrapper" (click)="redirectToDevice(device)">
                  <div class="icon-container">
                    <icon [path]="getIconPath(device)" class="device-icon"></icon>
                  </div>
                  <div class="info">
                    <div class="name">{{ device?.nickname || device?.name | macAddr : true }}</div>
                    <div class="type" *ngIf="device && device?.kind">{{ device?.kind.category }}</div>
                  </div>
                </div>
                <div class="column">
                  <div class="details">
                    <div class="key">{{ 'nodes.node.health' | translate }}</div>
                    <div class="value">
                      <div class="group">
                        <health-line [value]="device.health?.score" [status]="device.health?.status"></health-line>
                        <div class="status" [ngClass]="'t-' + device?.health?.status" *ngIf="device?.health?.status">
                          {{ device?.health?.status | translate }}
                        </div>
                      </div>
                    </div>
                    <ng-container *ngIf="device?.medium === 'wifi'">
                      <ng-container *ngIf="!device?.isMlo">
                        <div class="key">{{ 'nodes.node.rssi' | translate }}</div>
                        <div class="value">
                          <div class="group">
                            <health-dots [rssiOrQoe]="device"></health-dots>
                            <div class="num">
                              {{ device?.rssi || device?.health?.details?.channelGain + 20 | number : '1.0-0' }} dBm
                            </div>
                          </div>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="device.isMlo">
                        <div class="key mlo">{{ 'nodes.node.mloConnection' | translate }}</div>
                      </ng-container>
                    </ng-container>

                    <ng-container *ngIf="!device?.isMlo">
                      <div class="key channel-small">{{ 'nodes.node.channel' | translate }}</div>
                      <div class="value channel-small">
                        <span *ngIf="device?.medium === 'wifi'">{{ device?.channel }}</span>
                        <span *ngIf="device?.medium !== 'wifi'">{{ 'nodes.node.ethernet' | translate }}</span>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="device?.isMlo && device?.links">
                      <ng-container *ngFor="let link of device.links">
                        <div class="key channel-small">{{ link.freqBand }}</div>
                        <div class="value channel-small">
                          <span *ngFor="let link of device.links">{{ link.channel }}</span>
                        </div>
                      </ng-container>
                    </ng-container>
                  </div>

                  <div class="channel" *ngIf="!device?.isMlo">
                    <span *ngIf="device?.medium === 'wifi'">Ch {{ device?.channel }}</span>
                    <span *ngIf="device?.medium !== 'wifi'">{{ 'nodes.node.ethernet' | translate }}</span>
                  </div>

                  <div class="channel" *ngIf="device?.isMlo">
                    <ng-container *ngIf="device?.links">
                      <div *ngFor="let link of device.links">
                        <span>{{ link.freqBand }}: {{ link.channel }}</span>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="!node?.devices?.length">
              <div class="device">{{ 'nodes.node.noConnectedDevices' | translate }}</div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </box>
  </div>
</div>

<modal mode="compact" (overlayAction)="show5GModal = $event" *ngIf="show5GModal">
  <ng-container title>{{ 'nodes.node.wan5g' | translate }}</ng-container>
  <ng-container content>
    <div class="qoeDetails">
      <div class="list" *ngFor="let wan of demo5gWAN | keyvalue">
        <div class="key">{{ wan.key | translate }}</div>
        <div class="value">{{ wan.value }}</div>
      </div>
    </div>
  </ng-container>
  <ng-container controls>
    <button class="tertiary light" (click)="toggle5GModal(false)">
      {{ 'health.networkInformation.modal.close' | translate }}
    </button>
  </ng-container>
</modal>

<modal mode="compact" (overlayAction)="showQoeModal = $event" *ngIf="showQoeModal">
  <ng-container title>{{ 'qoe.charts.mostRecentMeasurements' | translate }}</ng-container>
  <ng-container content>
    <div class="qoeDetails">
      <div class="list" *ngFor="let qoe of node.rawQoe | keyvalue">
        <div class="key">{{ '' + qoe.key | translate }}</div>
        <div class="value">{{ qoe.key === 'mldAddr' ? ('' + qoe.value | macAddr) : qoe.value }}</div>
      </div>
    </div>
  </ng-container>
  <ng-container controls>
    <button class="tertiary light" (click)="toggleQoeModal(false)">
      {{ 'health.networkInformation.modal.close' | translate }}
    </button>
    <button class="super-primary" (click)="gotoQoe()">
      {{ 'health.networkInformation.modal.qoeData' | translate }}
    </button>
  </ng-container>
</modal>

<modal mode="compact" (overlayAction)="showPuncturingModal = $event" *ngIf="showPuncturingModal">
  <ng-container title>{{ 'nodes.node.puncturedChannels' | translate }}</ng-container>
  <ng-container content>
    <div class="puncturing">
      <div class="list" *ngFor="let punc of puncChan">
        <div class="key" *ngIf="punc.puncturedChannels">
          {{ punc.key | translate }}
        </div>
        <div class="value" *ngIf="punc.puncturedChannels && punc.puncturedChannels.length > 0">
          {{ punc.puncturedChannels }}
        </div>
        <div class="value" *ngIf="punc.puncturedChannels && punc.puncturedChannels.length < 1">
          {{ 'nodes.node.wifiNone' | translate }}
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container controls>
    <button class="tertiary light" (click)="showPuncturingModal = false">
      {{ 'health.networkInformation.modal.close' | translate }}
    </button>
  </ng-container>
</modal>

<modal mode="normal" (overlayAction)="showIPV6Modal = $event" *ngIf="showIPV6Modal">
  <ng-container title>{{ 'nodes.node.ipv6' | translate }}</ng-container>
  <ng-container content>
    <div class="ipV6Details" *ngFor="let ip of node.ipv6">
      {{ ip | ipv6 }}
    </div>
  </ng-container>
  <ng-container controls>
    <button class="tertiary light" (click)="showIPV6Modal = false">
      {{ 'health.networkInformation.modal.close' | translate }}
    </button>
  </ng-container>
</modal>

<modal mode="normal" (overlayAction)="showPublicIPModal = $event" *ngIf="showPublicIPModal">
  <ng-container title>{{ 'nodes.node.publicIp' | translate }}</ng-container>
  <ng-container content>
    {{ node.publicIp | ipv4Wan }}
  </ng-container>
  <ng-container controls>
    <button class="tertiary light" (click)="showPublicIPModal = false">
      {{ 'health.networkInformation.modal.close' | translate }}
    </button>
  </ng-container>
</modal>
