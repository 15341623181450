<div class="container-fluid">
  <collapsiblebox mode="fill" [show]="active" [collapsible]="true" toggleSide="left" (toggle)="toggleExpand()">
    <div header>
      <div class="group">
        <div class="title">{{ 'timelines.logpull.title' | translate }}</div>
        <icon name="icon-info" tooltip="{{ 'timelines.logpull.tooltip' | translate }}" [maxWidth]="300"></icon>
      </div>

      <div class="grow" *ngIf="dayView">
        <div class="tag skip-toggle" (click)="changeView(null)">
          <icon name="icon-close" class="size-0_5"></icon>
          {{ dayView.text }}
        </div>
      </div>

      <button class="action medium" (click)="openLogpullModal($event)" *ngIf="active">
        {{ 'customerinfo.performLogpull' | translate }}
      </button>
    </div>
    <div container>
      <timelineschart
        *ngIf="active"
        [events]="logpullEvents"
        [dayView]="dayView"
        [endDay]="endDay === today ? null : endDay"
        [eventsMenu]="true"
        (view)="changeView($event)"
        (event)="selectEvent($event)"
      ></timelineschart>
      <ng-container *ngIf="allLogpullEvents !== null && allLogpullEvents.length > 0">
        <div class="timeButtons" *ngIf="!dayView">
          <div class="pill" [tooltip]="'configurations.firmware.previousMonth' | translate" (click)="previousDate()">
            &lt;
          </div>
          <div
            class="pill"
            [tooltip]="'configurations.firmware.nextMonth' | translate"
            [class.disabled]="today === endDay"
            (click)="nextDate()"
          >
            &gt;
          </div>
        </div>
      </ng-container>
    </div>
  </collapsiblebox>
  <logpulldetails [event]="eventDetails"></logpulldetails>
  <logpullmodal [(showLogPull)]="showLogPull"></logpullmodal>
</div>
