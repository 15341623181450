import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  ViewChild
} from '@angular/core';
import * as erdm from 'element-resize-detector';

@Component({
  selector: 'horizontalscroll',
  templateUrl: './horizontalscroll.component.html',
  styleUrls: ['./horizontalscroll.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HorizontalScrollComponent implements AfterViewInit, OnDestroy {
  scrollable: boolean = false;
  left: boolean = false;
  right: boolean = false;
  erd = erdm({ strategy: 'scroll' });
  observer: MutationObserver;

  @ViewChild('wrapper') wrapper: ElementRef;

  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.check();

    this.erd.listenTo(this.wrapper.nativeElement, () => {
      this.check();
    });

    this.observer = new MutationObserver(() => this.check());
    this.observer.observe(this.wrapper.nativeElement, { childList: true, subtree: true });
  }

  check(): void {
    const wrapper = this.wrapper.nativeElement;
    const viewWidth = wrapper.clientWidth;
    const actualWidth = wrapper.scrollWidth;
    const leftOffset = wrapper.scrollLeft;

    if (actualWidth > viewWidth) {
      this.scrollable = true;
    } else {
      this.scrollable = false;
    }

    if (leftOffset === 0) {
      this.left = false;
    } else {
      this.left = true;
    }

    if (leftOffset + viewWidth === actualWidth) {
      this.right = false;
    } else {
      this.right = true;
    }

    this.cdr.detectChanges();
  }

  move(side: string): void {
    const wrapper = this.wrapper.nativeElement;
    const percent = wrapper.clientWidth / 3;
    let left = 0;

    if (side === 'left') {
      left = wrapper.scrollLeft - percent - 10;
    }

    if (side === 'right') {
      left = wrapper.scrollLeft + percent + 10;
    }

    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');

    if (msie > 0) {
      // IE 11 only
      wrapper.scrollTo(left, 0);
    } else {
      wrapper.scrollTo({
        top: 0,
        left,
        behavior: 'smooth'
      });
    }

    setTimeout(() => {
      // after scroll is done
      this.check();
    }, 500);
  }

  ngOnDestroy(): void {
    if (this.observer) {
      this.observer.disconnect();
    }

    if (this.wrapper?.nativeElement) {
      this.erd.uninstall(this.wrapper.nativeElement);
    }
  }
}
