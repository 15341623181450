import { scaleLinear } from 'd3';
import * as moment from 'moment';

export class SecurityChart {
  private data: any[];
  private events: any;
  private width: number;
  private height: number;
  private xScale: any;
  private yScale: any;
  private day: any;
  private margins: any = {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  };

  constructor() {}

  calculateWidth(): number {
    return this.width - this.margins.left - this.margins.right;
  }

  calculateHeight(): number {
    return this.height - this.margins.top - this.margins.bottom;
  }

  prepare(): void {
    if (this.day) {
      const date = moment(this.day.timestamp);

      const oneDay = date.clone().add(23, 'hours');

      const hour = 60 * 60 * 1000;

      this.xScale = scaleLinear()
        .domain([date.valueOf() - hour / 2, oneDay.valueOf() + hour / 2])
        .range([0, this.calculateWidth()]);
      this.yScale = scaleLinear()
        .domain([hour, 0])
        .range([this.calculateHeight(), 0]);
    } else {
      const today = moment();
      today.startOf('day');

      const monthAgo = today.clone().subtract(28, 'days');

      const day = 24 * 60 * 60 * 1000;

      this.xScale = scaleLinear()
        .domain([monthAgo.valueOf() - day / 2, today.valueOf() + day / 2])
        .range([0, this.calculateWidth()]);
      this.yScale = scaleLinear()
        .domain([day, 0])
        .range([this.calculateHeight(), 0]);
    }
  }

  calculateEvents(): void {
    const events = {};

    this.data.forEach((event: any) => {
      const timestamp = new Date(event.createdAt).getTime();
      const date = moment(timestamp)
        .startOf(this.day ? 'hour' : 'day')
        .valueOf();
      const time = timestamp - date;

      const newEvent = {
        ...event,
        ...event.eventData,
        time: moment(timestamp).format('LT'),
        y: this.yScale(time),
        type: event.eventType.toLowerCase().split('.')[0]
      };

      if (events[date]) {
        events[date].push(newEvent);
      } else {
        events[date] = [newEvent];
      }
    });

    this.events = events;
  }

  calculateData(): any[] {
    const data = [];

    if (this.day) {
      const date = moment(this.day.timestamp);

      for (let i = 0; i < 24; i++) {
        const val = date.clone().add(i, 'hours');
        const half = val.clone().subtract(30, 'minutes');
        const hour = {
          x: this.xScale(half.valueOf()),
          y: 0,
          width: (this.xScale(val.valueOf()) - this.xScale(half.valueOf())) * 2,
          height: this.calculateHeight(),
          center: this.xScale(val.valueOf()),
          text: i === 24 ? '24h' : val.format('HH[h]'),
          timestamp: val.valueOf(),
          events: this.events[val.valueOf()] ? this.events[val.valueOf()] : []
        };

        data.push(hour);
      }
    } else {
      const now = moment().startOf('day');

      for (let i = 0; i < 29; i++) {
        const val = now.clone().subtract(i, 'days');
        const half = val.clone().subtract(12, 'hours');
        const day = {
          x: this.xScale(half.valueOf()),
          y: 0,
          width: (this.xScale(val.valueOf()) - this.xScale(half.valueOf())) * 2,
          height: this.calculateHeight(),
          center: this.xScale(val.valueOf()),
          text: val.format('MMM D'),
          timestamp: val.valueOf(),
          events: this.events[val.valueOf()] ? this.events[val.valueOf()] : []
        };

        data.push(day);
      }
    }

    return data;
  }

  xAxis(): any[] {
    if (this.day) {
      const date = moment(this.day.timestamp);

      return [
        { text: 'aisecurity.chart.h00', x: this.xScale(date.valueOf()), y: -10, dx: 0, anchor: 'middle' },
        {
          text: 'aisecurity.chart.h04',
          x: this.xScale(date.add(4, 'hours').valueOf()),
          y: -10,
          dx: 0,
          anchor: 'middle'
        },
        {
          text: 'aisecurity.chart.h08',
          x: this.xScale(date.add(4, 'hours').valueOf()),
          y: -10,
          dx: 0,
          anchor: 'middle'
        },
        {
          text: 'aisecurity.chart.h12',
          x: this.xScale(date.add(4, 'hours').valueOf()),
          y: -10,
          dx: 0,
          anchor: 'middle'
        },
        {
          text: 'aisecurity.chart.h16',
          x: this.xScale(date.add(4, 'hours').valueOf()),
          y: -10,
          dx: 0,
          anchor: 'middle'
        },
        {
          text: 'aisecurity.chart.h20',
          x: this.xScale(date.add(4, 'hours').valueOf()),
          y: -10,
          dx: 0,
          anchor: 'middle'
        },
        {
          text: 'aisecurity.chart.h23',
          x: this.xScale(date.add(3, 'hours').valueOf()),
          y: -10,
          dx: 0,
          anchor: 'middle'
        }
      ];
    } else {
      const now = moment().startOf('day');
      const half =
        this.xScale(now.valueOf()) -
        this.xScale(
          now
            .clone()
            .subtract(12, 'hours')
            .valueOf()
        );

      return [
        { text: 'aisecurity.chart.today', x: this.xScale(now.valueOf()), y: -10, dx: half, anchor: 'end' },
        {
          text: 'aisecurity.chart.daysAgo',
          x: this.xScale(now.subtract(7, 'days').valueOf()),
          y: -10,
          dx: 0,
          anchor: 'middle'
        },
        {
          text: 'aisecurity.chart.twoWeeks',
          x: this.xScale(now.subtract(7, 'days').valueOf()),
          y: -10,
          dx: 0,
          anchor: 'middle'
        },
        {
          text: 'aisecurity.chart.threeWeeks',
          x: this.xScale(now.subtract(7, 'days').valueOf()),
          y: -10,
          dx: 0,
          anchor: 'middle'
        },
        {
          text: 'aisecurity.chart.monthAgo',
          x: this.xScale(now.subtract(7, 'days').valueOf()),
          y: -10,
          dx: -half,
          anchor: 'start'
        }
      ];
    }
  }

  yAxis(): any[] {
    if (this.day) {
      return [
        { text: 'aisecurity.chart.min0', x: -10, y: this.yScale(0), dy: 6, anchor: 'end' },
        { text: 'aisecurity.chart.min15', x: -10, y: this.yScale(15 * 60 * 1000), dy: 6, anchor: 'end' },
        { text: 'aisecurity.chart.min30', x: -10, y: this.yScale(30 * 60 * 1000), dy: 6, anchor: 'end' },
        { text: 'aisecurity.chart.min45', x: -10, y: this.yScale(45 * 60 * 1000), dy: 6, anchor: 'end' },
        { text: 'aisecurity.chart.h1', x: -10, y: this.yScale(60 * 60 * 1000), dy: 3, anchor: 'end' }
      ];
    } else {
      return [
        { text: 'aisecurity.chart.h00', x: -10, y: this.yScale(0), dy: 6, anchor: 'end' },
        { text: 'aisecurity.chart.h08', x: -10, y: this.yScale(8 * 60 * 60 * 1000), dy: 6, anchor: 'end' },
        { text: 'aisecurity.chart.h16', x: -10, y: this.yScale(16 * 60 * 60 * 1000), dy: 6, anchor: 'end' },
        { text: 'aisecurity.chart.h24', x: -10, y: this.yScale(24 * 60 * 60 * 1000), dy: 3, anchor: 'end' }
      ];
    }
  }

  update(data: any[], width?: number, height?: number, margins?: any, day?: any): void {
    this.data = data ? data : [];
    this.width = width ? width : 0;
    this.height = height ? height : 0;
    this.margins = margins ? margins : this.margins;
    this.day = day ? day : null;

    this.prepare();
    this.calculateEvents();
  }
}
