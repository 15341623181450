<div class="nodestrip">
  <div class="top">
    <div class="info">
      <div class="cellHead nickname" [class.hide]="node.isRename">
        {{ node.nickname || node.name || node.defaultName }}
      </div>
      <div class="cellHead" [class.hide]="!node.isRename">
        <input
          class="name-input"
          (keyup.enter)="name.value?.trim() && confirmRename()"
          [formControl]="name"
          type="text"
          placeholder="{{ 'techdash.nodeStrip.newName' | translate }}"
          autofocus
        />
      </div>
      <div class="cellSub">{{ 'techdash.nodeStrip.id' | translate }}: {{ node.id || '' }}</div>
      <div class="cellSub">{{ node.model || '' }}</div>
      <div class="cellSub">{{ 'techdash.nodeStrip.fw' | translate }}: {{ node.firmwareVersion || '' }}</div>
    </div>

    <div class="status">
      <icon
        [path]="gateWayIcon$ | async"
        class="globe"
        *ngIf="node.isGateway && node.connectionState === 'connected'"
      ></icon>
      <div *ngIf="!node.isGateway || node.connectionState !== 'connected'">
        <div class="statuspoint"><div class="point" [ngClass]="node.connectionState"></div></div>
      </div>
      <icon [path]="node.kind.icon"></icon>
    </div>
  </div>

  <div class="main">
    <div class="stripCell small">
      <icon
        [path]="gateWayIcon$ | async"
        class="globe"
        *ngIf="node.isGateway && node.connectionState === 'connected'"
      ></icon>
      <div *ngIf="!node.isGateway || node.connectionState !== 'connected'">
        <div class="statuspoint"><div class="point" [ngClass]="node.connectionState"></div></div>
      </div>
    </div>

    <div class="stripCell small"><icon [path]="node.kind.icon"></icon></div>

    <div class="stripCell info">
      <div class="cellHead nickname" [class.hide]="node.isRename">
        {{ node.nickname || node.name || node.defaultName }}
      </div>
      <div class="cellHead" [class.hide]="!node.isRename">
        <input
          class="name-input"
          (keyup.enter)="name.value?.trim() && confirmRename()"
          [formControl]="name"
          type="text"
          placeholder="{{ 'techdash.nodeStrip.newName' | translate }}"
          autofocus
        />
      </div>
      <div class="cellSub">{{ 'techdash.nodeStrip.id' | translate }}: {{ node.id || '' }}</div>
      <div class="cellSub">{{ node.model || '' }}</div>
      <div class="cellSub">{{ 'techdash.nodeStrip.fw' | translate }}: {{ node.firmwareVersion || '' }}</div>
    </div>
    <div
      class="stripCell rssi"
      [class.dim]="node.isRename"
      *ngIf="!node.isGateway && node.backhaulType === 'wifi' && node.connectionState === 'connected'"
    >
      <div class="cellHead">{{ node?.rssi || node.health?.details?.channelGain + 20 | number: '1.0-0' }} dBm</div>
      <health-dots [rssiOrQoe]="node"></health-dots>
      <div class="cellFoot" *ngIf="node.channel">
        <div class="cellSub">{{ node.channel.mode }} (Ch {{ node.channel.value }})</div>
      </div>
    </div>

    <div
      class="stripCell rssi"
      [class.dim]="node.isRename"
      *ngIf="!node.isGateway && node.backhaulType === 'ethernet' && node.connectionState === 'connected'"
    >
      {{ 'techdash.nodeStrip.ethernet' | translate }}
    </div>

    <div
      class="stripCell rssi"
      [class.dim]="node.isRename"
      *ngIf="node.isGateway && node.connectionState === 'connected'"
    >
      {{ 'techdash.nodeStrip.gateway' | translate }}
    </div>

    <div class="stripCell rssi" [class.dim]="node.isRename" *ngIf="node.connectionState !== 'connected'">
      {{ 'techdash.nodeStrip.disconnected' | translate }}
    </div>

    <div
      class="stripCell health"
      [class.dim]="node.isRename"
      *ngIf="!node.isGateway && node.backhaulType === 'wifi' && node.connectionState === 'connected'"
    >
      <div class="cellHead" *ngIf="node.health?.score && node.health?.status !== 'calculating'">
        {{ node.health.score }} ({{ node.health.status | translate }})
      </div>
      <div class="cellHead" *ngIf="node.health?.status === 'calculating'">
        {{ node.health.status | translate }}
      </div>
      <health-line [value]="node.health?.score" [status]="node.health?.status"></health-line>
    </div>

    <div
      class="stripCell health"
      [class.dim]="node.isRename"
      *ngIf="!node.isGateway && node.backhaulType === 'ethernet' && node.connectionState === 'connected'"
    >
      {{ 'techdash.nodeStrip.ethernet' | translate }}
    </div>

    <div
      class="stripCell health"
      [class.dim]="node.isRename"
      *ngIf="node.isGateway && node.connectionState === 'connected'"
    >
      {{ 'techdash.nodeStrip.gateway' | translate }}
    </div>

    <div class="stripCell health" [class.dim]="node.isRename" *ngIf="node.connectionState !== 'connected'">
      {{ 'techdash.nodeStrip.disconnected' | translate }}
    </div>
    <div
      class="stripCell speed"
      [class.dim]="node.isRename"
      *ngIf="(!node || !node.speedTest || !node.speedTest.download) && !testPending"
    >
      <div class="cellSub speedtest">{{ 'techdash.nodeStrip.noSpeedTest' | translate }}</div>
    </div>

    <div
      class="stripCell speed"
      [class.dim]="node.isRename"
      *ngIf="(node && node.speedTest && node.speedTest.download) || testPending"
    >
      <div class="cellHead" *ngIf="!testPending && node && node.speedTest && node.speedTest.download">
        {{ node.speedTest.download | number: '1.0-0' || '' }} Mbps
      </div>
      <div class="cellSub speedtest" *ngIf="testPending">{{ 'techdash.nodeStrip.testingSpeed' | translate }}</div>

      <div class="cellSub speedtest" *ngIf="!testPending">{{ 'techdash.nodeStrip.downloadSpeed' | translate }}</div>
      <div class="cellProgress" *ngIf="testPending">
        <div class="progressbar"><div class="indeterminate"></div></div>
      </div>
    </div>

    <div class="stripCell controls" *ngIf="!node.isRename">
      <div
        class="button"
        (click)="name.setValue(node.nickname); launchRename()"
        *ngIf="permissions?.uiFeatures.renameNodes && !plume.isStrictSupportRole()"
      >
        <icon name="icon-edit" tooltip="{{ 'techdash.nodeStrip.tooltip.rename' | translate }}"></icon>
      </div>
      <div
        class="button"
        [class.disabled]="speedTestDisabled$ | async"
        (click)="launchSpeedTest()"
        *ngIf="
          node.connectionState == 'connected' &&
          !node.disableSpeedTest &&
          permissions?.uiFeatures.speedTestsConfiguration
        "
      >
        <icon
          name="icon-gauge"
          class="size-0_8"
          tooltip="{{ 'techdash.nodeStrip.tooltip.speedTest' | translate }}"
        ></icon>
      </div>
      <div
        class="button"
        [class.disable]="isUprise$ | async"
        (click)="launchDeleteNode()"
        *ngIf="permissions?.uiFeatures.deleteNodes && !plume.isStrictSupportRole()"
      >
        <icon
          name="icon-close"
          class="size-0_7"
          tooltip="{{ 'techdash.nodeStrip.tooltip.deleteNode' | translate }}"
        ></icon>
      </div>
      <div
        class="button"
        [class.disable]="isUprise$ | async"
        (click)="swapModal = true"
        *ngIf="permissions?.uiFeatures.swapNode && node.residentialGateway"
      >
        <icon name="icon-swap" class="size-0_8" tooltip="{{ 'techdash.nodeStrip.tooltip.swap' | translate }}"></icon>
      </div>
      <div
        class="button"
        (click)="startWps()"
        *ngIf="node.connectionState === 'connected' && (capabilities$ | async)?.wps?.capable && isWpsRole()"
      >
        <div class="wps" tooltip="{{ 'techdash.nodeStrip.tooltip.WPS' | translate }}">WPS</div>
      </div>
    </div>

    <div class="stripCell controls" *ngIf="node.isRename">
      <div class="button" (click)="confirmRename()" *ngIf="name.value?.trim()">
        <icon name="icon-check" class="confirm" tooltip="{{ 'techdash.nodeStrip.tooltip.save' | translate }}"></icon>
      </div>
      <div class="button" (click)="cancelRename()">
        <icon
          name="icon-close"
          class="size-0_8 cancel"
          tooltip="{{ 'techdash.nodeStrip.tooltip.cancel' | translate }}"
        ></icon>
      </div>
    </div>
  </div>
  <div class="bottom">
    <div
      class="stripCell speed"
      [class.dim]="node.isRename"
      *ngIf="!node || !node.speedTest || !node.speedTest.download"
    >
      {{ 'techdash.nodeStrip.noSpeedTest' | translate }}
    </div>

    <div class="stripCell speed" [class.dim]="node.isRename" *ngIf="node && node.speedTest && node.speedTest.download">
      <div class="cellHead" *ngIf="!testPending">
        <span class="cellSub">{{ 'techdash.nodeStrip.downloadSpeed' | translate }}</span>
        {{ node.speedTest.download | number: '1.0-0' || '' }} Mbps
      </div>

      <div class="cellSub speedtest" *ngIf="testPending">{{ 'techdash.nodeStrip.testingSpeed' | translate }}</div>
      <div class="cellProgress" *ngIf="testPending">
        <div class="progressbar"><div class="indeterminate"></div></div>
      </div>
    </div>
  </div>
</div>

<modal mode="compact" (overlayAction)="swapModal = $event" *ngIf="swapModal">
  <ng-container title>{{ 'techdash.nodeStrip.swapGateway' | translate }}</ng-container>
  <ng-container content>
    <div class="general">
      <div class="id">
        {{ 'techdash.nodeStrip.currentSN' | translate }}: <b>{{ node.id }}</b>
      </div>
      <div class="input">
        <input
          class="gateway-input"
          (keyup.enter)="swapGateway(gatewayInput.value)"
          (keyup.escape)="gatewayInput.value = ''; swapModal = false"
          placeholder="{{ 'techdash.nodeStrip.newGatewaySN' | translate }}"
          #gatewayInput
          autofocus
        />
      </div>
    </div>
  </ng-container>
  <ng-container controls>
    <button class="tertiary light" (click)="gatewayInput.value = ''; swapModal = false">
      {{ 'techdash.nodeStrip.buttonCancel' | translate }}
    </button>
    <button class="super-primary" (click)="swapGateway(gatewayInput.value)">
      <icon name="icon-spinner" class="spinner loading" *ngIf="loading"></icon>
      <div *ngIf="!loading">{{ 'techdash.nodeStrip.buttonSwap' | translate }}</div>
    </button>
  </ng-container>
</modal>
