import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/lib/services/api.service';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { LoggingService } from 'src/app/lib/services/logging.service';
import { TroubleshootingService } from 'src/app/lib/services/troubleshooting.service';
import { MessageService } from 'src/app/lib/services/message.service';
import { IconService } from 'src/app/lib/services/icon.service';
import * as moment from 'moment';
import { selectDevices, selectNodes } from 'src/app/store/polling/polling.selector';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'coveragealarm',
  templateUrl: './coveragealarm.component.html',
  styleUrls: ['./coveragealarm.component.scss']
})
export class CoveragealarmComponent implements OnInit, OnDestroy {
  @Output() reload = new EventEmitter<void>();
  subscriptions: any[] = [];
  alarms: any = [];
  history: any = [];
  nodes: any = {
    list: [],
    gw: 0,
    ext: 0
  };
  axis24: string;
  axis48: string;
  axisNow: string;
  expand: boolean = false;
  extStep1: string = null;
  noExtStep1: string = null;
  lastOptimize: string = 'health.networkPerformance.coverageAlarm.loading';
  messageSubscription: any;

  constructor(
    private router: Router,
    private api: ApiService,
    public plume: PlumeService,
    private logging: LoggingService,
    private troubleShoot: TroubleshootingService,
    private message: MessageService,
    private icons: IconService,
    private store: Store
  ) {
    this.axisNow = 'health.networkPerformance.coverageAlarm.now';
    this.axis24 = moment().subtract(24, 'hours').format('L');
    this.axis48 = moment().subtract(48, 'hours').format('L');
    this.history = [
      { name: moment().format('dd'), alarm: 0 },
      {
        name: moment().subtract(1, 'days').format('dd'),
        alarm: 0
      },
      {
        name: moment().subtract(2, 'days').format('dd'),
        alarm: 0
      },
      {
        name: moment().subtract(3, 'days').format('dd'),
        alarm: 0
      },
      {
        name: moment().subtract(4, 'days').format('dd'),
        alarm: 0
      },
      {
        name: moment().subtract(5, 'days').format('dd'),
        alarm: 0
      },
      {
        name: moment().subtract(6, 'days').format('dd'),
        alarm: 0
      }
    ];
  }

  ngOnInit(): void {
    const history = [];
    this.logging.log('coverage ngOnInit');

    for (let i = 1; i <= 96; i++) {
      history.push({
        time: moment()
          .subtract(i * 15, 'minutes')
          .format('h:mm a'),
        warning: (i > 20 && i < 30) || (i > 60 && i < 70) ? true : false,
        error: (i > 45 && i < 61) || (i > 69 && i < 100) ? true : false
      });
    }

    this.api
      .get(
        '/Customers/' +
          this.plume.customerid +
          '/locations/' +
          this.plume.locationid +
          '/alarm?granularity=days&limit=7',
        'reports'
      )
      .subscribe((response: any) => {
        const history = [];
        for (let i = 1; i <= 7; i++) {
          history.push({
            day: moment.utc().subtract(i, 'days').format('dd'),
            timestamp: moment.utc().subtract(i, 'days').format('YYYY-MM-DD'),
            alarm: null
          });
        }

        response.deviceAlarm.forEach((alarm: any) => {
          alarm.timestamp = moment.utc(alarm.timestamp).format('YYYY-MM-DD');
        });

        response.deviceAlarm.forEach((alarm: any) => {
          history.forEach((day, i) => {
            if (day.timestamp === alarm.timestamp) {
              history[i].alarm = alarm.value;
            }
          });
        });
        this.logging.log('coverage history=', history);
        this.history = history;
      });

    this.api
      .get(
        '/Customers/' +
          this.plume.customerid +
          '/locations/' +
          this.plume.locationid +
          '/nodeDevice/alarms?granularity=days&limit=1',
        'reports'
      )
      .subscribe((response: any) => {
        response.alarmIds.forEach((mac: string) => {
          combineLatest([
            this.api.get(
              '/Customers/' +
                this.plume.customerid +
                '/locations/' +
                this.plume.locationid +
                '/devices/' +
                mac +
                '/alarms?granularity=hours&limit=48',
              'reports'
            ),
            this.store.select(selectDevices).pipe(take(1))
          ]).subscribe(([alarms, devices]) => {
            const device = devices.find((device: any) => mac === device.mac) || null;

            if (device) {
              if (device.iconV3) {
                this.alarms.push({
                  ...device,
                  history: this.calculateAlarms(alarms),
                  iconPathV3: this.icons.getV3Path(device.iconV3, 'small')
                });
              } else {
                this.alarms.push({
                  ...device,
                  history: this.calculateAlarms(alarms),
                  iconPath: this.icons.getPath(device.kind?.type?.iconV2 || device.iconV2)
                });
              }
            } else {
              this.api
                .get('/Customers/' + this.plume.customerid + '/locations/' + this.plume.locationid + '/devices/' + mac)
                .subscribe((device: any) => {
                  device.history = this.calculateAlarms(alarms);
                  if (device.iconV3) {
                    device.iconPathV3 = this.icons.getV3Path(device.iconV3, 'small');
                  } else {
                    device.iconPath = this.icons.getPath(device.kind?.type?.iconV2 || device.iconV2);
                  }
                  this.alarms.push(device);
                });
            }
          });
        });
      });

    this.subscriptions.push(
      this.store.select(selectNodes).subscribe((nodes) => {
        if (nodes) {
          this.nodes.list = nodes;
          nodes.forEach((node: any) => {
            if (node.backhaulType === 'wifi') {
              this.nodes.ext++;
            }
            if (node.backhaulType === 'ethernet') {
              this.nodes.gw++;
            }
          });

          this.logging.debug('<nodes>', this.nodes);
        }
      })
    );

    this.subscriptions.push(
      this.troubleShoot.getOptimized().subscribe((response: any) => {
        this.lastOptimize = response.optimization.state;
      })
    );

    this.messageHandler();
  }

  messageHandler(): void {
    this.messageSubscription = this.message.getMessage().subscribe((message: any) => {
      this.logging.log(' RECV MESSAGE coveragealarm : ', message);

      switch (message.source) {
        case 'finish': {
          this.toggleButton('extStep1', 'finish');
          break;
        }
      }
    });
  }

  calculateAlarms(alarms: any): any[] {
    const data = {};

    alarms['2g'].forEach((tick: any) => {
      data[tick.timestamp] = tick.value;
    });

    alarms['5g'].forEach((tick: any) => {
      data[tick.timestamp] = tick.value;
    });

    alarms['5gl'].forEach((tick: any) => {
      data[tick.timestamp] = tick.value;
    });

    alarms['5gu'].forEach((tick: any) => {
      data[tick.timestamp] = tick.value;
    });

    const round = (interval: number, moment: any) => {
      const roundedMinutes = Math.floor(moment.minute() / interval) * interval;
      return moment.clone().minute(roundedMinutes).second(0);
    };

    const start = round(15, moment());

    const history = [];

    for (let i = 0; i < 192; i++) {
      const tick = {
        time: moment.utc(start).local().format('LLL'),
        error: data[moment.utc(start).format('YYYY-MM-DD[T]HH:mm:ss.[000Z]')] === 1 ? true : false
      };

      history.push(tick);

      start.subtract(15, 'minutes');
    }

    return history;
  }

  goto(mac: string): void {
    this.router.navigate(['customer', this.plume.customerid, 'location', this.plume.locationid, 'devices', mac]);
  }

  toggleButton(variable: any, answer: string): void {
    if (this[variable] === answer) {
      this[variable] = null;
    } else {
      this[variable] = answer;
    }
  }

  finish(): void {
    this.expand = false;
    this.extStep1 = null;
    this.noExtStep1 = null;
    this.reload.emit();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: any) => subscription.unsubscribe());

    if (this.messageSubscription) {
      this.messageSubscription.unsubscribe();
    }
  }
}
