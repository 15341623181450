import { Pipe, PipeTransform } from '@angular/core';
import { PlumeService } from '../services/plume.service';

@Pipe({
  name: 'customerName'
})
export class CustomerNamePipe implements PipeTransform {
  constructor(private plume: PlumeService) {}

  transform(value?: string): string {
    if (this.plume.hidePersonalDetails() && value) {
      return value
        .split(' ')
        .map((value, index, array) => (array.length - 1 === index && array.length > 1 ? '*****' : value))
        .join(' ');
    }
    return value;
  }
}
