import { Component, OnInit, OnChanges, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { LoggingService } from 'src/app/lib/services/logging.service';
import { TroubleshootingService } from 'src/app/lib/services/troubleshooting.service';
import { MessageService } from 'src/app/lib/services/message.service';
import { ThemeService } from 'src/app/lib/services/theme.service';
import { ModelRefService } from 'src/app/lib/services/modelref.service';
import * as moment from 'moment';

@Component({
  selector: 'extenderhealthalarm',
  templateUrl: './extenderhealthalarm.component.html',
  styleUrls: ['./extenderhealthalarm.component.scss']
})
export class ExtenderHealthAlarmComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  extender: any = {};
  @Input()
  selected: any;
  @Input()
  nodes: any;
  @Output()
  section: EventEmitter<string> = new EventEmitter<string>();

  axis24: string;
  axis12: string;
  axisNow: string;
  expand: boolean = false;
  troubleshoot: boolean = false;
  run: boolean = false;
  messageSubscription: any;
  themeSubscription: any;
  step: string = '';

  constructor(
    private logging: LoggingService,
    private troubleShoot: TroubleshootingService,
    private message: MessageService,
    private modelRef: ModelRefService,
    private theme: ThemeService
  ) {
    this.axisNow = 'health.networkPerformance.extenderHealthAlarm.now';
    this.axis12 = moment().subtract(12, 'hours').format('h a');
    this.axis24 = moment().subtract(24, 'hours').format('h a');
  }

  ngOnInit(): void {
    this.messageSubscription = this.message.getMessage().subscribe((message: any) => {
      this.logging.log(' RECV MESSAGE extenderhealthalarm : ', message);

      switch (message.source) {
        case 'finish': {
          this.troubleshoot = false;
          this.expand = false;
          this.step = '';
          break;
        }
      }
    });

    this.themeSubscription = this.theme.listener.subscribe(() => {
      this.prepare();
    });
  }

  ngOnChanges(changes: any): void {
    if (changes && changes.selected && changes.selected.currentValue) {
      if (changes.selected.currentValue !== this.extender.id) {
        this.expand = false;
      }
    }
  }

  prepare(): void {
    if (this.extender) {
      const model = this.modelRef.get(this.extender.model);

      if (model) {
        if (this.extender.kind) {
          this.extender = { ...this.extender, kind: { ...this.extender.kind, icon: model.icon } };
        } else {
          this.extender = { ...this.extender, kind: { icon: model.icon } };
        }
      }
    }
  }

  select(type: string): void {
    this.section.emit(type);
  }

  handleQuestion(answer: string): void {
    if (answer === 'yes' || answer === 'no') {
      if (this.step === answer) {
        this.step = '';
      } else {
        this.step = answer;
      }
    }

    if (answer === 'order') {
      this.troubleShoot.orderExtenders();
      this.logging.debug('<extenderHealth> ORDER EXTENDERS');
    }
  }

  startTroubleshooting(): void {
    this.troubleshoot = true;
    this.expand = true;
  }

  ngOnDestroy(): void {
    if (this.messageSubscription) {
      this.messageSubscription.unsubscribe();
    }

    if (this.themeSubscription) {
      this.themeSubscription.unsubscribe();
    }
  }
}
