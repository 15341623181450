import { Injectable } from '@angular/core';
import { interval, Observable, of } from 'rxjs';
import { catchError, startWith, switchMap } from 'rxjs/operators';
import { IGlobalInventoryNode, IIGlobalInventoryNodeResponse, ISystemCloudMatrix } from '../interfaces/interface';
import { ApiService } from './api.service';
import { PlumeService } from './plume.service';

@Injectable({ providedIn: 'root' })
export class SystemService {
  constructor(private api: ApiService, private plume: PlumeService) {}

  // returns status every 60 seconds or emits undefined on error or no status page
  status$(): Observable<{ status: { indicator: string; description: string } } | undefined> {
    let env = this.plume.getEnv();

    if (!env) {
      env = { id: window.location.hostname.split('.')[0] };
    }

    let statusPageURL = this.plume.getStatusPageURL(env.id);

    if (!statusPageURL) {
      return of(undefined);
    }

    return interval(60000).pipe(
      startWith(-1),
      switchMap(() =>
        this.api.raw('GET', `${statusPageURL}/api/v2/status.json`).pipe(
          catchError(() => {
            if (statusPageURL === `https://statuspage-${env.id}-prod.plumenet.io`) {
              return of(undefined);
            }

            statusPageURL = `https://statuspage-${env.id}-prod.plumenet.io`;

            return this.api.raw('GET', `${statusPageURL}/api/v2/status.json`).pipe(catchError(() => of(undefined)));
          })
        )
      )
    );
  }

  cloudMatrix$(): Observable<ISystemCloudMatrix> {
    return this.api.get('/cloudMatrix', 'cloudMatrix');
  }

  reportVersion$(): Observable<{ apiVersion: string }> {
    const env = this.plume.getEnv();

    return this.api.raw(
      'get',
      env.hostname + '/reports/version',
      {},
      { headers: { 'Plume-Application-Name': 'PlumeCentral' } }
    );
  }

  globalInventoryAddNode$(node: IGlobalInventoryNode): Observable<IIGlobalInventoryNodeResponse> {
    return this.api.post('/Nodes', node);
  }

  renamePackID$(podId: string, packName: string): Observable<undefined> {
    return this.api.put('/Nodes/' + podId + '/packId', {
      id: podId,
      packId: packName
    });
  }

  translateStatusPageResponse(response: { status: { indicator: string; description: string } } | undefined):
    | {
        indicator: string;
        translation: string;
      }
    | undefined {
    if (!response) {
      return undefined;
    }

    let translation = '';

    switch (response.status.description) {
      case 'All Systems Operational':
        translation = 'header.statuspage.allSystemsOperational';
        break;
      case 'Major System Outage':
        translation = 'header.statuspage.majorSystemOutage';
        break;
      case 'Partial System Outage':
        translation = 'header.statuspage.partialSystemOutage';
        break;
      case 'Minor System Outage':
        translation = 'header.statuspage.minorSystemOutage';
        break;
      case 'Degraded Systems Service':
        translation = 'header.statuspage.degradedSystemService';
        break;
      case 'Partially Degraded Service':
        translation = 'header.statuspage.partiallyDegradedService';
        break;
      case 'System Under Maintenance':
        translation = 'header.statuspage.systemUnderMaintenance';
        break;
      case 'Service Under Maintenance':
        translation = 'header.statuspage.serviceUnderMaintenance';
        break;
    }

    return { indicator: response.status.indicator, translation };
  }
}
