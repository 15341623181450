<drop-down
  #dropDown
  [headerTemplate]="header"
  [listWidth]="null"
  [class.small]="elRef.nativeElement.classList.contains('small')"
  [class.medium]="elRef.nativeElement.classList.contains('medium')"
  (opened)="opened.emit($event)"
>
  <ng-container dropdown>
    <ng-content></ng-content>
  </ng-container>
</drop-down>
<ng-template #header let-opened>
  <icon [name]="headerIcon" [class]="iconSize" [class.highlight]="highlight"></icon>
</ng-template>
