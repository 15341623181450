import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import * as moment from 'moment/moment';
import { INode, IReboot } from 'src/app/lib/interfaces/interface';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectNodes } from 'src/app/store/polling/polling.selector';
import { catchError, map, share, startWith, switchMap } from 'rxjs/operators';
import { TimelinesService } from 'src/app/lib/services/timelines.service';

interface IMappedReboot {
  createdAt: string;
  eventData: {
    event: IReboot & { name: string };
    tooltipProperties: { key: string; value: string }[];
    eventTime: string;
    eventName: string;
    status: string;
    target: string;
    created: string;
    updated: string;
    reason: string;
    firmwareversion: string;
    processName: string;
  };
  eventType: string;
}

type IDataDefinition =
  | {
      events: IMappedReboot[];
      loading: boolean;
      loadMoreVisible: boolean;
      tooManyReboots?: boolean;
      unknownError?: undefined;
    }
  | { loading?: undefined; loadMoreVisible?: undefined; tooManyReboots?: undefined; unknownError: true; events: null };

@Component({
  selector: 'reboottimeline',
  templateUrl: './reboottimeline.component.html',
  styleUrls: ['./reboottimeline.component.scss']
})
export class ReboottimelineComponent implements OnChanges {
  @Input() active: boolean;
  dayView: { text: string } | null = null;
  eventDetails: IMappedReboot['eventData'] = null;
  loadingChunkLength = 100;
  startTime = moment().subtract(30, 'days').startOf('day').utc().toISOString();
  tooManyRebootsStartTime = moment().subtract(7, 'days').startOf('day').utc().toISOString();
  loadPreviousWithEvents$ = new Subject<IMappedReboot[]>();
  dataDefinition$?: Observable<IDataDefinition> = null;

  legend = [
    { text: 'timelines.reboot.legend.cloud', color: 'timelineseries1' },
    { text: 'timelines.reboot.legend.watchdog', color: 'timelineseries2' },
    { text: 'timelines.reboot.legend.health_check', color: 'timelineseries3' },
    { text: 'timelines.reboot.legend.power_cycle', color: 'timelineseries4' },
    { text: 'timelines.reboot.legend.cold_boot', color: 'timelineseries5' },
    { text: 'timelines.reboot.legend.process_crash', color: 'timelineseries6' },
    { text: 'timelines.reboot.legend.thermal', color: 'timelineseries7' },
    { text: 'timelines.reboot.legend.crash', color: 'timelineseries8' }
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private timeLinesService: TimelinesService,
    private plume: PlumeService,
    private store: Store,
    private mixpanel: MixpanelService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.active.currentValue && !this.dataDefinition$) {
      this.dataDefinition$ = this.dataLoader(); // initial loading
      setTimeout(() => {
        this.loadPreviousWithEvents$.next([]); // dispatch event after view is initialized
      }, 0);
    }
  }

  toggleExpand(): void {
    const enabledModes: string[] = this.route.snapshot.params?.mode?.length
      ? this.route.snapshot.params.mode.split(',')
      : [];

    if (this.active) {
      this.dayView = null;
      this.eventDetails = null;
      this.mixpanel.storeEvent('REBOOT_TIMELINE_MACHINE_EXPAND', { EXPANDED: false });
      this.router.navigate([
        'customer',
        this.plume.customerid,
        'location',
        this.plume.locationid,
        'timelines',
        enabledModes.filter((mode) => mode !== 'reboot').join(',')
      ]);
    } else {
      this.mixpanel.storeEvent('REBOOT_TIMELINE_MACHINE_EXPAND', { EXPANDED: true });
      this.router.navigate([
        'customer',
        this.plume.customerid,
        'location',
        this.plume.locationid,
        'timelines',
        [...enabledModes, 'reboot'].join(',')
      ]);
    }
  }

  loadMore(currentEvents: IMappedReboot[]): void {
    this.loadPreviousWithEvents$.next(currentEvents);
  }

  changeView(event: { text: string } | null): void {
    this.dayView = event;
  }

  selectEvent(event: IMappedReboot): void {
    this.eventDetails = event.eventData;
  }

  private dataLoader(): Observable<IDataDefinition> {
    return combineLatest([
      this.loadPreviousWithEvents$.pipe(
        map((mappedEvents) => mappedEvents.map((e) => e.eventData.event)),
        switchMap((events) =>
          this.timeLinesService
            .reboots$(this.startTime, this.loadingChunkLength, events?.length > 0 ? events[0]?.createdAt : undefined)
            .pipe(
              map((result) => ({
                events: [...events, ...result],
                loading: false,
                loadMoreVisible: result.length === this.loadingChunkLength
              })),
              startWith({ events: null, loading: true, loadMoreVisible: false }),
              catchError((error) => {
                if (error.status === 504) {
                  return this.timeLinesService.reboots$(this.tooManyRebootsStartTime, this.loadingChunkLength).pipe(
                    map((result) => ({
                      events: result,
                      loading: false,
                      loadMoreVisible: false,
                      tooManyReboots: true
                    })),
                    catchError(() => of({ unknownError: true as const }))
                  );
                }
                return of({ unknownError: true as const });
              })
            )
        )
      ),
      this.store.select(selectNodes)
    ]).pipe(
      map(([defs, nodes]) => ({
        ...defs,
        events:
          ('events' in defs ? defs?.events : null)
            ?.sort((a, b) => Number(new Date(a.createdAt)) - Number(new Date(b.createdAt)))
            .map((event) => this.mapFirmwareEvent(event, nodes)) ?? null
      })),
      share()
    );
  }

  private mapFirmwareEvent(event: IReboot, nodes: INode[]): IMappedReboot {
    const timeOfDay = moment(event.createdAt).format('LT');
    const tooltipProperties = [];
    const node = nodes?.find((node) => node.id === event.nodeid) || null;

    tooltipProperties.push({ key: 'timelines.reboot.time', value: timeOfDay });
    tooltipProperties.push({ key: 'timelines.reboot.firmwareversion', value: event.firmwareversion });
    tooltipProperties.push({ key: 'timelines.reboot.nodeid', value: event.nodeid });

    if (node) {
      if (node.nickname || node.defaultName) {
        tooltipProperties.push({ key: 'timelines.reboot.name', value: node.nickname || node.defaultName });
      }
    }

    tooltipProperties.push({ key: 'timelines.reboot.reason', value: event.reason });
    tooltipProperties.push({ key: 'timelines.reboot.type', value: event.reboottype.toLowerCase() });

    if (event.processName) {
      tooltipProperties.push({ key: 'timelines.reboot.processName', value: event.processName });
    }

    return {
      createdAt: event.createdAt,
      eventData: {
        event: { ...event, name: node?.nickname || node?.defaultName || null },
        tooltipProperties,
        eventTime: timeOfDay,
        eventName: event.reason || event.reboottype.toLowerCase(),
        status: event.reboottype.toLowerCase(),
        target: event.nodeid,
        created: event.createdAt,
        updated: event.createdAt,
        reason: event.reason,
        firmwareversion: event.firmwareversion,
        processName: event.processName
      },
      eventType: event.reboottype.toLowerCase()
    };
  }
}
