<ng-container *ngIf="editState && !disabled">
  <icon
    class="confirm"
    [class.disabled]="disableConfirm"
    name="icon-check"
    [tooltip]="tooltipSet | translate"
    (click)="set()"
  ></icon>
  <icon class="size-0_8 cancel" name="icon-close" [tooltip]="tooltipCancel | translate" (click)="close()"></icon>
</ng-container>
<icon
  *ngIf="!editState || disabled"
  name="icon-edit"
  [tooltip]="tooltipEdit | translate"
  (click)="startEditing()"
  [class.disabled]="disabled"
></icon>
