<ng-container *ngIf="!isError">
  <ng-container *ngIf="(nodeTaskStatuses$ | async)?.length && !loading">
    <div class="node" [class.open]="open[node.id]" *ngFor="let node of nodeTaskStatuses$ | async">
      <div class="header" (click)="open[node.id] = !open[node.id]">
        <icon [path]="node.icon" class="size-1_5" *ngIf="node.icon"></icon>
        <div class="content">
          <div class="details">
            <div class="name">{{ node.name || node.id }}</div>
            <div class="id" *ngIf="node.name">{{ node.id }}</div>
          </div>
          <div class="tasks" *ngIf="node.taskCount">
            {{ 'taskStatuses.taskCount' | translate: { count: node.taskCount } }}
          </div>
        </div>
        <icon name="icon-arrow-down" class="arrow size-0_6"></icon>
      </div>
      <div class="content">
        <div class="tasks" *ngIf="node.taskStatuses.length">
          <div class="task" *ngFor="let task of node.taskStatuses">
            <div class="details">
              <div>{{ task.name }}</div>
              <div>{{ 'taskStatuses.status.' + task.status | translate }}</div>
              <div *ngIf="task.failedAction">{{ task.failedAction }}</div>
            </div>
            <div class="line"></div>
            <div class="timestamp">
              {{ task.finishedAt | date: 'L LT' }}
            </div>
          </div>
        </div>
        <div class="tasks" *ngIf="!node.taskStatuses.length">
          <div class="task">{{ 'taskStatuses.noTasks' | translate: { count: node.taskCount } }}</div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!(nodeTaskStatuses$ | async)?.length && !loading">
    <div class="error">{{ 'taskStatuses.noData' | translate }}</div>
  </ng-container>
  <ng-container *ngIf="loading">
    <div class="error">{{ 'taskStatuses.loading' | translate }}</div>
  </ng-container>
</ng-container>
<ng-container *ngIf="isError">
  <div class="error">{{ 'taskStatuses.noData' | translate }}</div>
</ng-container>
