<section>
  <div class="header">
    <div class="title">{{ 'captiveportal.authentication' | translate }}</div>
  </div>
  <div class="divider">
    <span>{{ 'captiveportal.noGuestDataCollected' | translate }}</span>
    <div class="line"></div>
  </div>
  <ng-container *ngFor="let option of options$ | async">
    <div class="option" *ngIf="!option.consent">
      <div class="fa" ngClass="{{ option.icon }}"></div>
      <div class="label">
        <div class="title">{{ option.text | translate }}</div>
        <div class="hint" *ngIf="!option.editable">{{ 'captiveportal.noAuthRequired' | translate }}</div>
        <div class="hint" *ngIf="option.editable">
          {{ 'captiveportal.currentPasscode' | translate }}:
          <input
            #passcodeInput
            [formControl]="settingsInput"
            type="text"
            placeholder="{{ 'captiveportal.passcodePlaceholder' | translate }}"
          />
          <div class="fa fa-edit" *ngIf="option.enabled && !settings" (click)="edit(option)"></div>
          <ng-container *ngIf="option.enabled && settings">
            <div class="fa fa-times" (click)="edit(null)"></div>
            <div class="fa fa-check" (click)="changeSettings()"></div>
          </ng-container>
        </div>
      </div>
      <div class="toggler" [class.enabled]="option.enabled" (click)="toggleOption(option)">
        <div class="handle"></div>
      </div>
    </div>
  </ng-container>
  <div class="divider">
    <span>{{ 'captiveportal.guestDataCollected' | translate }}</span>
    <div class="line"></div>
  </div>
  <ng-container *ngFor="let option of options$ | async">
    <div class="option" *ngIf="option.consent">
      <div class="fa" ngClass="{{ option.icon }}"></div>
      <div class="label">{{ option.text | translate }}</div>
      <div class="toggler" [class.enabled]="option.enabled" (click)="toggleOption(option)">
        <div class="handle"></div>
      </div>
    </div>
  </ng-container>
  <div class="consent">
    <div class="content">
      <div class="title">{{ 'captiveportal.consent.title' | translate }}</div>
      <div class="message">
        <ng-container *ngIf="!(config$ | async).advancedOptions.consent.gdprEnabled">
          {{ 'captiveportal.consent.message' | translate }}
        </ng-container>
        <ng-container *ngIf="(config$ | async).advancedOptions.consent.gdprEnabled">
          {{ 'captiveportal.consent.messageGDPR' | translate }}
        </ng-container>
      </div>
    </div>
    <div class="toggler" [class.enabled]="(config$ | async).advancedOptions.consent.enabled" (click)="toggleConsent()">
      <div class="handle"></div>
    </div>
  </div>
</section>
