import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { LoggingService } from 'src/app/lib/services/logging.service';
import { ModelRefService } from 'src/app/lib/services/modelref.service';
import { MessageService } from 'src/app/lib/services/message.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { ThemeService } from 'src/app/lib/services/theme.service';
import { Bar } from 'src/app/lib/d3/models/objects/bar';
import { findIndex } from 'lodash';

@Component({
  selector: 'extenderspeedalarm',
  templateUrl: './extenderspeedalarm.component.html',
  styleUrls: ['./extenderspeedalarm.component.scss']
})
export class ExtenderspeedalarmComponent implements OnInit, OnDestroy {
  @Input()
  alarm: any;
  @Input()
  selected: any;
  @Input()
  facade: any;
  @Input()
  bbe: any;
  @Input()
  thresholds: any;
  @Input()
  nodes: any;
  @Input()
  alarmType: string;
  @Output()
  section: EventEmitter<string> = new EventEmitter<string>();

  autorunOptimization: boolean = false;
  bars: Bar[] = [];
  range: any = { start: new Date(), end: new Date() };
  max: number = 0;
  min: number = 0;
  loading: boolean = false;
  percent: number = 0;
  expand: boolean = false;
  troubleshoot: boolean = false;
  step: any = 'init'; // current step in the flow ( for view)
  stepState: any = 'init'; // state of the step (i.e. is a test in progress)
  currentOptimization: any; // current state of opti

  speedtest: any = { download: 0, upload: 0 };
  speedtestOk: boolean = false;
  connectionsOK: boolean = false;
  messageSubscription: any;
  themeSubscription: any;

  constructor(
    private modelRef: ModelRefService,
    private logging: LoggingService,
    private message: MessageService,
    private mixpanel: MixpanelService,
    private theme: ThemeService
  ) {}

  ngOnInit(): void {
    this.logging.log('<extenderspeedalarm>', this.alarm, this.nodes);

    this.themeSubscription = this.theme.listener.subscribe(() => {
      this.prepare();
    });

    this.messageSubscription = this.message.getMessage().subscribe((message: any) => {
      this.logging.log(' RECV MESSAGE extenderspeedalarm : ', message);
      switch (message.source) {
        case 'speed': {
          this.step = 'inform';
          this.checkConnections();
          break;
        }
        case 'optimize': {
          if (message.success) {
            this.step = 'checkSpeed'; // simulate success
            this.stepState = 'init';
          } else {
            this.logging.warn('Optimize fail');
            this.step = 'escalate';
            this.stepState = 'fail';
          }
          break;
        }
        case 'finish': {
          this.logging.log('extenderspeedtest finish');
          this.step = 'checkSpeed';
          break;
        }
      }
    });
  }

  prepare(): void {
    if (this.alarm) {
      const model = this.modelRef.get(this.alarm.model);

      if (model) {
        if (this.alarm.kind) {
          this.alarm = { ...this.alarm, kind: { ...this.alarm.kind, icon: model.icon } };
        } else {
          this.alarm = { ...this.alarm, kind: { icon: model.icon } };
        }
      }
    }
  }

  isOptimized(): boolean {
    this.currentOptimization = this.facade.optimization.state;
    return this.facade.optimization.state === 'optimized';
  }

  startTroubleshooting(): void {
    this.expand = true;

    if (this.isOptimized() && !this.autorunOptimization) {
      this.step = 'checkSpeed';
    } else {
      this.step = 'checkOptimization';
    }

    this.troubleshoot = true;

    this.mixpanel.storeEvent('HEALTH_DIAGNOSTIC', {
      ACTION: 'Start Troubleshooting',
      ALARM: this.alarmType === 'bbe' ? 'Extender Broadband Efficiency' : 'Low Extender Speed'
    });

    this.message.sendMessage({ component: 'speedtest', action: 'reset' });
  }

  round(num: number): number {
    return Math.round(num);
  }

  select(type: string): void {
    this.section.emit(type);
  }

  checkConnections(): void {
    this.speedtestOk = true;
    if (this.alarm.leafToRoot && this.alarm.leafToRoot.length > 0) {
      this.connectionsOK = true;

      this.alarm.leafToRoot.forEach((connection: any) => {
        // this.logging.log("connection ===> ",connection);
        const index = findIndex(this.nodes, { id: connection.id });
        if (index > -1) {
          const connectedNode = this.nodes[index];
          // this.logging.log("connectedNode ===> ",connectedNode);
          // this.logging.log("connectedNode.health ===> ",connectedNode.health);
          if (connectedNode.health && connectedNode.health.status === 'poor') {
            this.connectionsOK = false;
          }
        } else {
          this.logging.warn('Unable to find node in leaftoroot');
          return null;
        }
      });
    } else {
      this.logging.error('Device or node missing leafToRoot');
    }
  }

  ngOnDestroy(): void {
    if (this.messageSubscription) {
      this.messageSubscription.unsubscribe();
    }

    if (this.themeSubscription) {
      this.themeSubscription.unsubscribe();
    }
  }
}
