import { Component, ElementRef, Input, OnChanges, ViewChild } from '@angular/core';

import { Tick } from 'src/app/lib/d3/models/objects/tick';

@Component({
  selector: '[tickVisual]',
  templateUrl: './tick.component.html',
  styleUrls: ['./tick.component.scss']
})
export class TickVisualComponent implements OnChanges {
  @Input()
  tickVisual: Tick;

  @Input()
  xPreventOverflow = false;

  @Input() marginLeft = 0;

  @ViewChild('text') text: ElementRef<SVGTextElement>;

  constructor(private elm: ElementRef<SVGGElement>) {}

  ngOnChanges(): void {
    if (this.xPreventOverflow) {
      setTimeout(() => {
        const svgWidth = this.elm.nativeElement.parentElement?.parentElement?.getBoundingClientRect()?.width;
        const textEndOffset = this.text?.nativeElement?.getBBox().width / 2;
        const translateX = Number.parseFloat(
          this.tickVisual.transform.substring('translate('.length, this.tickVisual.transform.search(','))
        );
        const xOverflow = this.marginLeft + textEndOffset + translateX - svgWidth;
        if (xOverflow > 0) {
          this.tickVisual.text.dx -= xOverflow;
        }
      }, 0);
    }
  }
}
