import { Component, OnInit, OnChanges, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { Store } from '@ngrx/store';
import {
  selectOffline,
  selectPipeLocationOnChangeOptimistic,
  selectPowerManagementState
} from 'src/app/store/customer/customer.selectors';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PowerManagementService } from 'src/app/lib/services/powerManagement.service';
import { IPowerState, LPMState, LPMStatus, LPMConfig } from 'src/app/lib/interfaces/interface';
import { ToastService } from 'src/app/lib/services/toast.service';

@UntilDestroy()
@Component({
  selector: 'powermanagement',
  templateUrl: './powermanagement.component.html',
  styleUrls: ['./powermanagement.component.scss']
})
export class PowerManagementComponent implements OnInit, OnChanges, OnDestroy {
  expand: boolean = false;
  location$ = this.store.pipe(selectPipeLocationOnChangeOptimistic);

  @Input()
  open: number = 0;

  @Output()
  toggle = new EventEmitter();

  @Output()
  filter = new EventEmitter();

  @Output()
  clearFilter = new EventEmitter<{ section: string }>();

  modeItems = [];
  forceModeItems = [];

  powerPermission: boolean = false;
  powerState: IPowerState = null;
  powerCapable: boolean = false;
  powerMode: string = '';
  forceMode: string = 'DEACTIVATE';
  showMode: string | null = null;
  useMock: boolean = false;
  optimizationSubscription: any;
  topoSubscription: any;
  voltInterval = null;
  timeRemaining: number = 0;
  internet: any = {};
  isOffline: boolean = false;

  constructor(
    public plume: PlumeService,
    private mixpanel: MixpanelService,
    private translate: TranslateService,
    private store: Store,
    private powerManagement: PowerManagementService,
    private toast: ToastService
  ) {}

  ngOnInit(): void {
    this.plume.permissions.pipe(untilDestroyed(this)).subscribe((data: any) => {
      this.powerPermission = data.uiFeatures?.powerManagement || false;
    });

    this.store
      .select(selectOffline)
      .pipe(untilDestroyed(this))
      .subscribe((offlineState) => {
        this.isOffline = offlineState;
      });

    this.store
      .select(selectPowerManagementState)
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        this.powerState = data.state;
        this.powerCapable = data.capable;
        this.powerMode = data.mode;

        if (this.powerCapable) {
          this.registerFilter();
        }

        if (this.showMode === null) {
          this.showMode =
            this.powerState?.status === LPMStatus.STATUS_ACTIVE ||
            this.powerState?.status === LPMStatus.STATUS_ACTIVATING
              ? 'ACTIVATE'
              : 'DEACTIVATE';
        }

        this.forceMode = this.powerState?.enable ? 'ACTIVATE' : 'DEACTIVATE';

        this.setForceMode();
        this.setPowerMode();
        this.clearInterval();

        if (this.powerState?.status === LPMStatus.STATUS_ACTIVE) {
          if (this.powerState.forcePowerManagementExpiresAt) {
            this.voltInterval = setInterval(() => {
              this.timeRemaining = this.powerState.forcePowerManagementExpiresAt.value - Date.now();
            }, 500);
          }
        } else {
          this.clearInterval();
        }
      });
  }

  ngOnChanges(changes: any): void {
    this.expand = changes.open.currentValue;
  }

  getTimeString(): string {
    const ts: number = this.timeRemaining / 1000;
    return `${Math.floor(ts / 60)} min ${Math.floor(ts % 60)} s`;
  }

  registerFilter(): void {
    this.clearFilter.emit({ section: 'powermanagement' });

    this.translate
      .get('configurations.powermanagement.lowPowerMode')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'powermanagement', property: 'lowPowerMode', translation: translated })
      );
    this.translate
      .get('configurations.powermanagement.powerAwareness')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'powermanagement', property: 'powerAwareness', translation: translated })
      );
  }

  toggleExpand(): void {
    this.toggle.emit(!this.expand);

    if (!this.expand) {
      this.mixpanel.storeEvent('CONFIGURATION_POWERMANAGEMENT_SCREEN');
    }
  }

  action(property: string, value: string): void {
    if (property === 'powermode') {
      this.powerMode = value;
      this.powerManagement.setPowerMode$(value).subscribe(
        () => {
          this.setPowerMode();
        },
        () => {
          this.toast.error(
            'configurations.powermanagement.apiFailedPowerManagement',
            'configurations.powermanagement.failed'
          );
          this.mixpanel.storeEvent('SET_POWER_MODE', {
            mode: value
          });
        }
      );
    }

    if (property === 'forceMode') {
      this.forceMode = value;
      this.showMode = value;

      this.powerManagement.setForcePower$(value === 'ACTIVATE' ? 600 : 0).subscribe(
        () => {
          this.setForceMode();
        },
        () => {
          this.toast.error(
            'configurations.powermanagement.apiFailedForcePower',
            'configurations.powermanagement.failed'
          );
          this.mixpanel.storeEvent('SET_FORCE_POWER_MODE', {
            duration: value === 'ACTIVATE' ? 600 : 0
          });
        }
      );
    }
  }

  setPowerMode(): void {
    this.modeItems = [
      {
        value: LPMConfig.CONFIG_AUTO,
        translation: 'configurations.powermanagement.auto',
        selected: this.powerMode === LPMConfig.CONFIG_AUTO
      },
      {
        value: LPMConfig.CONFIG_ENABLE,
        translation: 'configurations.powermanagement.enable',
        marked: this.powerState?.enable,
        selected: this.powerMode === LPMConfig.CONFIG_ENABLE
      },
      {
        value: LPMConfig.CONFIG_DISABLE,
        translation: 'configurations.powermanagement.disabled',
        marked: !this.powerState?.enable,
        selected: this.powerMode === LPMConfig.CONFIG_DISABLE
      }
    ];
  }

  setForceMode(): void {
    switch (this.powerState?.status) {
      case LPMStatus.STATUS_ACTIVE:
        this.forceModeItems = [
          {
            value: LPMState.STATUS_DEACTIVATE,
            translation: 'configurations.powermanagement.deactivate',
            selected: this.showMode === LPMState.STATUS_DEACTIVATE
          },
          {
            marked: true,
            value: LPMState.STATUS_ACTIVATE,
            translation: 'configurations.powermanagement.activate',
            selected: this.showMode === LPMState.STATUS_ACTIVATE
          }
        ];
        break;
      case LPMStatus.STATUS_ACTIVATING:
        this.forceModeItems = [
          {
            value: LPMState.STATUS_DEACTIVATE,
            translation: 'configurations.powermanagement.deactivate'
          },
          {
            value: LPMState.STATUS_ACTIVATE,
            translation: 'configurations.powermanagement.activating',
            selected: true
          }
        ];
        break;
      case LPMStatus.STATUS_DEACTIVATING:
        this.forceModeItems = [
          {
            value: LPMState.STATUS_DEACTIVATE,
            translation: 'configurations.powermanagement.deactivating',
            selected: true
          },
          {
            value: LPMState.STATUS_ACTIVATE,
            translation: 'configurations.powermanagement.activate'
          }
        ];
        break;
      case LPMStatus.STATUS_INACTIVE:
        this.forceModeItems = [
          {
            marked: true,
            value: LPMState.STATUS_DEACTIVATE,
            translation: 'configurations.powermanagement.deactivate',
            selected: true
          },
          {
            value: LPMState.STATUS_ACTIVATE,
            translation: 'configurations.powermanagement.activate'
          }
        ];
        break;
      default:
        this.forceModeItems = [
          {
            value: LPMState.STATUS_DEACTIVATE,
            translation: 'configurations.powermanagement.deactivate',
            selected: this.showMode === LPMState.STATUS_DEACTIVATE
          },
          {
            value: LPMState.STATUS_ACTIVATE,
            translation: 'configurations.powermanagement.activate',
            selected: this.showMode === LPMState.STATUS_ACTIVATE
          }
        ];
        break;
    }
  }

  clearInterval(): void {
    if (this.voltInterval) {
      clearInterval(this.voltInterval);
    }
  }

  ngOnDestroy(): void {
    this.clearInterval();
  }
}
