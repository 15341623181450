import { Pipe, PipeTransform } from '@angular/core';
import { PlumeService } from '../services/plume.service';

@Pipe({
  name: 'ipv4Wan'
})
export class Ipv4WanPipe implements PipeTransform {
  constructor(private plume: PlumeService) {}

  transform(value?: string): string {
    if (this.plume.hidePersonalDetails() && value) {
      return `${value.split('.')[0]}.***.***.***`;
    }
    return value?.toUpperCase();
  }
}
