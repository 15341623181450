import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';
import { GeneralHelper } from 'src/app/lib/helpers/general.helper';
import { CustomerService } from 'src/app/lib/services/customer.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { ModalService } from 'src/app/lib/services/modal.service';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { SecondaryNetworksService } from 'src/app/lib/services/secondary-networks.service';
import { ToastService } from 'src/app/lib/services/toast.service';
import { selectCapabilities, selectCapabilityWpa3 } from 'src/app/store/customer/capabilities.selector';
import {
  getPrimarySecondaryNetworks,
  setPrimarySecondaryNetworks,
  wifiPasswordUpdateLoaded,
  wifiPasswordUpdateLoadedError
} from 'src/app/store/customer/customer.actions';
import {
  selectIsHomePass,
  selectIsUpriseLocation,
  selectPipeLocationOnChange,
  selectPrimarySecondaryNetworks,
  selectReducedMode,
  selectWPA3Recommendation
} from 'src/app/store/customer/customer.selectors';
import { DeepReadonly, ILocation, IPrimarySecondaryNetworks } from '../../lib/interfaces/interface';

@Component({
  selector: 'networksettings',
  templateUrl: './networksettings.component.html',
  styleUrls: ['./networksettings.component.scss']
})
export class NetworksettingsComponent implements OnInit, OnDestroy {
  @Input()
  advancedOptions: boolean = true;

  @Input()
  mode: string = '';

  subscription: any;
  permissionsSubscription: any;
  permissions: any = {};
  isHomePass$ = this.store.select(selectIsHomePass);
  network: any = {};
  networkError: boolean = false;
  encryptionItems: any[] = [];
  encryptionMode: string = 'psk2';
  encryptionEnabled: boolean = true;
  editOriginalEncryptionMode: string = '';
  loading: boolean = true;
  ssidModal: boolean = false;
  editSsid: boolean = false;

  editPassword: boolean = false;
  passwordFormControl: FormControl = new FormControl();
  ssidFormControl: FormControl = new FormControl();
  wpaFormControl: FormControl = new FormControl();

  location$ = this.store.pipe(selectPipeLocationOnChange);
  reducedMode$ = this.store.select(selectReducedMode);
  wpa3Capable$ = this.store.select(selectCapabilityWpa3);
  capabilities$ = this.store.select(selectCapabilities);
  WPA3Recommendation$ = this.store.select(selectWPA3Recommendation);
  primarySecondaryNetworks$ = this.store.select(selectPrimarySecondaryNetworks);
  isUprise$ = this.store.select(selectIsUpriseLocation);

  networkEnabledItems: any[] = [];
  networkEnabled: boolean = false;
  networkTogglerEnabled: boolean = false;
  showNetworkEnabled: boolean = false;

  helper: GeneralHelper = new GeneralHelper();

  constructor(
    private plume: PlumeService,
    private toast: ToastService,
    private modal: ModalService,
    private mixpanel: MixpanelService,
    private secondaryNetworks: SecondaryNetworksService,
    private store: Store,
    private customerService: CustomerService
  ) {}

  ngOnInit(): void {
    this.permissionsSubscription = this.plume.permissions.subscribe((data: any) => {
      this.permissions = data;
    });

    this.loading = true;

    this.subscription = this.primarySecondaryNetworks$.subscribe(() => {
      this.getNetwork(() => {
        if (this.network.wpaMode) {
          // Workaround to Fix SOBI-3008
          this.encryptionMode = this.network.wpaMode === 'wpa2-psk' ? 'wpa2' : this.network.wpaMode;
        }

        this.initEncryptionToggler();
      });
    });
  }

  initEnableToggler(): void {
    this.networkEnabledItems = [
      { value: true, translation: 'health.networkInformation.enabled', selected: this.networkEnabled },
      { value: false, translation: 'health.networkInformation.disabled', selected: !this.networkEnabled }
    ];
  }

  changeNetworkEnabled(mode: boolean): void {
    this.primarySecondaryNetworks$.pipe(take(1)).subscribe((networks) => {
      if (this.mode === 'wpa2') {
        this.store.dispatch(setPrimarySecondaryNetworks({ options: { ...networks, wpa2enabled: mode } }));
      }

      if (this.mode === 'wpa3') {
        this.store.dispatch(setPrimarySecondaryNetworks({ options: { ...networks, wpa3enabled: mode } }));
      }
    });
  }

  initEncryptionToggler(): void {
    this.encryptionItems = [
      { value: 'psk2', translation: 'health.networkInformation.wpa2', selected: this.encryptionMode === 'psk2' },
      {
        value: 'sae-mixed',
        translation: 'health.networkInformation.wpa3transition',
        selected: this.encryptionMode === 'sae-mixed'
      }
    ];
  }

  changeEncryption(mode: string): void {
    const buttons = [
      { style: 'tertiary light', value: 'health.networkInformation.wpa3cancel' },
      { style: 'super-primary', value: 'health.networkInformation.wpa3ok' }
    ];

    if (mode === 'sae-mixed' && this.network.keys?.length > 1) {
      this.modal
        .showDialog('health.networkInformation.wpa3ConfirmMsg', 'health.networkInformation.wpa3ConfirmTitle', {
          buttons
        })
        .subscribe((response: any) => {
          this.encryptionAction(response, mode);
        });
    } else {
      if (this.network.wpaMode) {
        this.modal
          .showDialog('health.networkInformation.wpa3WarningMsg', 'health.networkInformation.wpa3WarningTitle', {
            buttons
          })
          .subscribe((response: any) => {
            this.encryptionAction(response, mode);
          });
      } else {
        this.encryptionMode = mode;
      }
    }
  }

  encryptionAction(response: any, mode: string): void {
    if (response.item?.value === 'health.networkInformation.wpa3ok') {
      if (!this.editPassword) {
        this.toggleEditPassword();
      }

      this.editOriginalEncryptionMode = this.encryptionMode;
      this.encryptionMode = mode;
      this.initEncryptionToggler();
    }

    if (response.item?.value === 'health.networkInformation.wpa3cancel') {
      this.initEncryptionToggler();
    }
  }

  isGroupSupportTechnician(): boolean {
    return this.plume.isGroupSupportTechnician();
  }

  getNetworkToggler(location: DeepReadonly<ILocation>, networks: IPrimarySecondaryNetworks): boolean {
    if (this.plume.isGroupSupportTechnician()) {
      return false;
    }
    return networks && networks.wpa2ssid && networks.wpa3ssid && location.profile !== 'smallbusiness';
  }

  getNetwork(callback: any = null): void {
    combineLatest([this.location$.pipe(take(1)), this.primarySecondaryNetworks$.pipe(take(1))]).subscribe(
      ([location, networks]) => {
        let query: Observable<any> = this.customerService.getWifiNetwork$();

        const isWPA3 = this.mode === 'wpa3' && location.profile !== 'smallbusiness';
        const isSecondaryNetwork = this.mode === 'secondary';
        if (isWPA3 || isSecondaryNetwork) {
          query = this.secondaryNetworks.getFronthaul$();
        }

        if (this.getNetworkToggler(location, networks)) {
          this.encryptionEnabled = false;
          this.showNetworkEnabled = true;
          this.networkEnabled = true;
          this.networkTogglerEnabled = true;

          if ((this.mode === 'wpa2' && !networks.wpa2enabled) || (this.mode === 'wpa3' && !networks.wpa3enabled)) {
            this.networkEnabled = false;
          }

          if ((this.mode === 'wpa2' && !networks.wpa3enabled) || (this.mode === 'wpa3' && !networks.wpa2enabled)) {
            this.networkTogglerEnabled = false;
          }

          this.initEnableToggler();
        } else {
          this.encryptionEnabled = true;
          this.networkEnabled = true;
          this.showNetworkEnabled = false;
        }

        query.subscribe(
          (response: any) => {
            if (isWPA3 || isSecondaryNetwork) {
              const network =
                response.find((network: any) => network.networkId === (isSecondaryNetwork ? 'secondary' : 'primary')) ||
                null;

              if (network) {
                this.network = network;
                this.networkError = false;
              } else {
                this.network = {};
                this.networkError = true;
              }
            } else {
              response.wifiNetwork.keys = response.wifiNetwork.keys.map((key: any) => {
                const zone = response.wifiNetwork.accessZones.find((zone: any) => zone.id === key.accessZoneId);

                key.description = zone.description;
                key.disabledItems = [
                  { value: false, translation: 'health.networkInformation.modal.yes', selected: !key.enable },
                  { value: true, translation: 'health.networkInformation.modal.no', selected: key.enable }
                ];

                return key;
              });

              response.wifiNetwork.keys.sort((a: any, b: any) => (a.accessZoneId < b.accessZoneId ? -1 : 1));

              this.network = response.wifiNetwork;
              this.networkError = false;
            }

            this.initEncryptionToggler();
            this.loading = false;

            if (callback) {
              callback();
            }
          },
          () => {
            this.network = {};
            this.networkError = true;

            this.initEncryptionToggler();
            this.loading = false;

            if (callback) {
              callback();
            }
          }
        );
      }
    );
  }

  executeRecommendation(): void {
    if (this.wpaFormControl.value?.length > 0) {
      combineLatest([this.primarySecondaryNetworks$, this.WPA3Recommendation$])
        .pipe(take(1))
        .subscribe(([{ wpa2enabled, wpa2ssid, wpa3enabled, wpa3ssid }, WPA3Recommendation]) => {
          switch (WPA3Recommendation) {
            case 'addWpa2Ssid':
              wpa2enabled = true;
              wpa2ssid = this.wpaFormControl.value;
              break;
            // case 'replaceWpa2SsidWithWpa3Ssid':
            //   break;
            case 'addWpa3Ssid':
              wpa3enabled = true;
              wpa3ssid = this.wpaFormControl.value;
              break;
            // case 'addWpa3SsidWithCaveats':
            //   break;
          }

          this.store.dispatch(
            setPrimarySecondaryNetworks({ options: { wpa2enabled, wpa2ssid, wpa3enabled, wpa3ssid } })
          );

          this.ssidModal = false;
        });
    }
  }

  checkIfRecommendationCanBeExecuted(recommendation: string): boolean {
    switch (recommendation) {
      case 'addWpa2Ssid':
      case 'addWpa3Ssid':
        return true;
      // case 'replaceWpa2SsidWithWpa3Ssid':
      // case 'addWpa3SsidWithCaveats':
      default:
        return false;
    }
  }

  toggleEditSsid(): void {
    this.editSsid = !this.editSsid;

    if (this.editSsid) {
      this.ssidFormControl.setValue(this.network.ssid);
      this.mixpanel.storeEvent('NETWORK_EDIT_SSID_ENABLE');
    } else {
      this.mixpanel.storeEvent('NETWORK_EDIT_SSID_DISABLE');
    }
  }

  updateWifiNetworkSSID(): void {
    if (this.validateSsid()) {
      if (this.networkError) {
        this.mixpanel.storeEvent('NETWORK_EDIT_FIRST_SSID_SUCCESS', {
          NEW_SSID: this.ssidFormControl.value
        });

        this.network.ssid = this.ssidFormControl.value;
        this.editSsid = false;
        this.editPassword = true;
      } else {
        this.location$.pipe(take(1)).subscribe((location) => {
          const data = { ssid: this.ssidFormControl.value };

          let query: Observable<any> = this.customerService.patchWifiNetwork$(data);

          if (this.mode === 'wpa3' && location.profile !== 'smallbusiness') {
            query = this.secondaryNetworks.editFronthaul$('primary', data);
          }
          if (this.mode === 'secondary') {
            query = this.secondaryNetworks.editFronthaul$('secondary', data);
          }

          query.subscribe(
            () => {
              this.mixpanel.storeEvent('NETWORK_EDIT_SSID_SUCCESS', {
                OLD_SSID: this.network.ssid,
                NEW_SSID: this.ssidFormControl.value
              });

              this.editSsid = false;
              this.network.ssid = this.ssidFormControl.value;
              this.store.dispatch(getPrimarySecondaryNetworks());
              this.toast.success('toast.technician.successSsidMessage', 'toast.technician.successSsidTitle');
            },
            (error: any) => {
              this.mixpanel.storeEvent('NETWORK_EDIT_SSID_ERROR', {
                OLD_SSID: this.network.ssid,
                NEW_SSID: this.ssidFormControl.value,
                ERROR: error.error.error.message
              });

              this.editSsid = false;
              this.store.dispatch(getPrimarySecondaryNetworks());
              this.toast.error('toast.technician.errorSsidMessage', 'toast.technician.errorSsidTitle', {
                disableTimeOut: true,
                params: {
                  error: error.error.error.message
                }
              });
            }
          );
        });
      }
    }
  }

  toggleEditPassword(): void {
    this.editPassword = !this.editPassword;

    if (this.editPassword) {
      this.passwordFormControl.setValue('');
      this.mixpanel.storeEvent('NETWORK_EDIT_PASSWORD_ENABLE');
    } else {
      this.mixpanel.storeEvent('NETWORK_EDIT_PASSWORD_DISABLE');

      if (this.editOriginalEncryptionMode) {
        this.encryptionMode = this.editOriginalEncryptionMode;
        this.initEncryptionToggler();
      }
    }

    this.editOriginalEncryptionMode = '';
  }

  updateWifiPassword(): void {
    const ssidToSet = this.networkError ? this.ssidFormControl.value : this.network.ssid;

    if (this.validatePassword()) {
      if (this.networkError) {
        if (!ssidToSet) {
          this.toast.error('toast.technician.errorSetSSIDFirstMessage', 'toast.technician.errorPasswordTitle');
          this.editPassword = false;
          return;
        }
      }

      this.location$.pipe(take(1)).subscribe((location) => {
        const data = { ssid: ssidToSet, encryptionKey: this.passwordFormControl.value, wpaMode: this.encryptionMode };

        let query: Observable<any> = this.customerService.setWifiNetworkPassword$(data);

        if (this.mode === 'wpa3' && location.profile !== 'smallbusiness') {
          query = this.secondaryNetworks.editFronthaul$('primary', data);
        }

        if (this.mode === 'secondary') {
          query = this.secondaryNetworks.editFronthaul$('secondary', data);
        }

        query.subscribe(
          () => {
            this.editPassword = false;
            this.mixpanel.storeEvent('NETWORK_EDIT_PASSWORD_SUCCESS');
            this.store.dispatch(wifiPasswordUpdateLoaded());
            this.store.dispatch(getPrimarySecondaryNetworks());
            this.toast.success('toast.technician.successPasswordMessage', 'toast.technician.successPasswordTitle');
          },
          (error: any) => {
            if (error.error.error.statusCode === 422) {
              let errorMsg = 'toast.technician.errorUniquePasswordMessage';

              if (this.editOriginalEncryptionMode) {
                errorMsg = 'toast.technician.errorUniquePasswordRevertedMessage';
                this.toggleEditPassword();
              } else {
                this.passwordFormControl.setValue('');
              }

              this.mixpanel.storeEvent('NETWORK_EDIT_PASSWORD_ERROR', { ERROR: error.error.error.message });
              this.toast.error(errorMsg, 'toast.technician.errorUniquePasswordTitle', {
                disableTimeOut: true
              });
            } else {
              this.mixpanel.storeEvent('NETWORK_EDIT_PASSWORD_ERROR', { ERROR: error.error.error.message });
              this.store.dispatch(wifiPasswordUpdateLoadedError());
              this.toast.error('toast.technician.errorPasswordMessage', 'toast.technician.errorPasswordTitle', {
                disableTimeOut: true,
                params: {
                  error: error.error.error.message
                }
              });
            }
          }
        );
      });
    }
  }

  validateSsid(): boolean {
    const str = this.ssidFormControl.value;
    if (str) {
      const len = this.getUTF8Length(str);
      return len > 0 && len < 33;
    }
    return false;
  }

  validatePassword(): boolean {
    const str = this.passwordFormControl.value;
    if (str) {
      const len = this.getUTF8Length(str);
      return len > 7 && len < 64;
    }
    return false;
  }

  validateWpa(): boolean {
    const str = this.wpaFormControl.value;
    if (str) {
      const len = this.getUTF8Length(str);
      return len > 0 && len < 33;
    }
    return false;
  }

  getUTF8Length(str: string): number {
    let utf8length = 0;
    for (let n = 0; n < str.length; n++) {
      const c = str.charCodeAt(n);
      if (c < 128) {
        utf8length++;
      } else if (c > 127 && c < 2048) {
        utf8length = utf8length + 2;
      } else {
        utf8length = utf8length + 3;
      }
    }
    return utf8length;
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if (this.permissionsSubscription) {
      this.permissionsSubscription.unsubscribe();
    }
  }
}
