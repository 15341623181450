import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { TroubleshootingService } from 'src/app/lib/services/troubleshooting.service';
import { ToastService } from 'src/app/lib/services/toast.service';
import { MessageService } from 'src/app/lib/services/message.service';
import { LoggingService } from 'src/app/lib/services/logging.service';
import * as moment from 'moment';
import { selectLocationInternet } from 'src/app/store/polling/polling.selector';
import { Store } from '@ngrx/store';

@Component({
  selector: 'optimizeBubble',
  templateUrl: './optimizeBubble.component.html',
  styleUrls: ['../bubbles.scss']
})
export class OptimizeBubbleComponent implements OnInit, OnDestroy {
  @Input()
  lastOptimize: any;

  @Input()
  autorun: boolean = false;

  optimizeState: string = 'init';
  optimized: string = '';
  optimizeTime: any;
  internetSubscription: any;
  timeoutInterval: any;
  timeout: number = 300000;

  constructor(
    private toast: ToastService,
    private troubleShoot: TroubleshootingService,
    private message: MessageService,
    private logging: LoggingService,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.optimized = this.lastOptimize;
    this.logging.log('Optimize autorun: ', this.autorun);
    if (this.autorun) {
      this.triggerOptimize();
    }
  }

  setTimeout(): void {
    this.timeoutInterval = setInterval(() => {
      this.logging.error('Optimize timeout with no response!');
      this.toast.error('toast.optimizationBubble.errorMessage', 'toast.optimizationBubble.errorTitle');
      this.clearTimeout();
    }, this.timeout);
  }

  clearTimeout(): void {
    if (this.timeoutInterval) {
      clearInterval(this.timeoutInterval);
    }
  }

  triggerOptimize(): void {
    this.triggerOptimizeActual();
  }

  sendMessage(success: boolean): void {
    this.logging.log('OPTIMIZE complete: ', success);
    this.message.sendMessage({ source: 'optimize', success });
  }

  triggerOptimizeActual(): void {
    this.setTimeout();
    this.optimizeTime = moment();
    this.optimizeState = 'busy';
    this.troubleShoot.triggerOptimization().subscribe(() => {
      this.logging.log('TRIGGER OPTIMIZE: ' + this.optimizeTime.format('HH:mm:ss:ms'));
      if (this.internetSubscription) {
        this.internetSubscription.unsubscribe();
      }
      this.internetSubscription = this.store.select(selectLocationInternet).subscribe((response: any) => {
        if (response) {
          const isBefore = this.optimizeTime.isBefore(response.optimization.createdAt);
          this.optimized = response.optimization.state;
          this.logging.log(
            ' POLL OPTIMIZE: ' +
              this.optimized +
              '  ' +
              moment(response.optimization.createdAt).format('HH:mm:ss:ms') +
              '   ' +
              isBefore
          );

          if (response.optimization.state === 'optimized' && isBefore) {
            if (this.internetSubscription) {
              this.internetSubscription.unsubscribe();
            }
            this.optimizeState = 'optimized';
            this.sendMessage(true);
            this.clearTimeout();
          }

          if ((response.optimization.state === 'failed' || response.optimization.state === 'fail') && isBefore) {
            if (this.internetSubscription) {
              this.internetSubscription.unsubscribe();
            }
            this.optimizeState = 'fail';
            this.sendMessage(false);
            this.clearTimeout();
          }
        }
      });
    });
  }

  simulateOptimizeSuccess(): void {
    this.logging.error('<simulateOptimizeSuccess> NOT for release');
    this.optimizeState = 'optimized';
    this.sendMessage(true);
  }

  simulateOptimizeFail(): void {
    this.logging.error('<simulateOptimizeFail> NOT for release');
    this.optimizeState = 'fail';
    this.sendMessage(false);
  }

  ngOnDestroy(): void {
    this.clearTimeout();

    if (this.internetSubscription) {
      this.internetSubscription.unsubscribe();
    }
  }
}
