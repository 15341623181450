import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, share, take } from 'rxjs/operators';
import { DataSetGroup, DataWithPosition } from 'src/app/lib/graph/graph.interface';
import { IDevice } from 'src/app/lib/interfaces/interface';
import {
  IIspOutage,
  IIspOutageMonthData,
  IIspOutageYearData,
  ILteDataUsage,
  ILteDeviceUsagePercentage
} from 'src/app/lib/interfaces/lte.interface';
import { LteService } from 'src/app/lib/services/lte.service';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { selectDevices } from 'src/app/store/polling/polling.selector';

type SingleUsageGroupWithStatistics = {
  devices: { deviceUsagePercentage: number; name: string; mac: string; iconV2: string }[];
  statistics: { deviceSum: number; usageSum: number };
};

type UsageGroupedWithStatistics = {
  [key: string]: SingleUsageGroupWithStatistics;
};

@UntilDestroy()
@Component({
  selector: 'lte-view',
  templateUrl: './lte-view.component.html',
  styleUrls: ['./lte-view.component.scss']
})
export class LteViewComponent implements OnInit {
  usage = {
    usage$: null,
    endTime: moment(),
    nextAvailable: false,
    usageDurationList: [
      { value: 'monthly' as const, translation: 'lte.month', selected: true },
      { value: 'yearly' as const, translation: 'lte.year', selected: false }
    ],
    selectedUsageDuration: 'monthly' as 'monthly' | 'yearly'
  };

  monthOutageInfo = {
    outage$: null as Observable<IIspOutage<IIspOutageMonthData[]>>,
    endTime: moment(),
    nextAvailable: false,
    dataSet: this.initMonthOutageInfo()
  };

  yearOutageInfo = {
    outage$: null as Observable<IIspOutage<IIspOutageYearData[]>>,
    endTime: moment(),
    nextAvailable: false,
    dataSet: [
      {
        dataSets: [
          {
            barTopColor: '#DB6EA3',
            color: '#8884FF',
            data: [],
            type: 'bar',
            xStepVisualization: 'labelOnly',
            yStepVisualization: 'line',
            xTextPosition: 'start',
            yTextPosition: 'start',
            xStart: 1,
            xEnd: 12,
            yStart: 0,
            yEnd: 15,
            xLabelStart: 1,
            xLabelStep: 2,
            yLabelStart: 0,
            yLabelStep: 5,
            xValText: (val) =>
              `${moment()
                .startOf('year')
                .add(val - 1, 'months')
                .format('MMM')}`,
            yValText: (val) => `${val}`,
            toolTipHtml: (data: DataWithPosition) =>
              this.sanitizer.bypassSecurityTrustHtml(
                `<div style="white-space: nowrap;">${this.translate.instant('lte.yearOutageTooltip', {
                  month: moment()
                    .month(data.xVal - 1)
                    .format('MMM'),
                  hours: Math.floor(data.yVal),
                  minutes: Math.round((data.yVal % 1) * 60)
                })}</div>`
              )
          }
        ],
        startFromZero: true,
        autoScale: false
      }
    ] as DataSetGroup[]
  };

  outgoingOutageMin$ = null;
  timezoneOffset = new Date().getTimezoneOffset() * -1;

  constructor(
    private lteService: LteService,
    private store: Store,
    private translate: TranslateService,
    private sanitizer: DomSanitizer,
    public plume: PlumeService
  ) {}

  ngOnInit(): void {
    this.reloadMonthOutage();
    this.reloadYearOutage();
    this.reloadUsage();
  }

  reloadUsage(): void {
    this.usage.usage$ = combineLatest([
      this.lteService.dataUsage$(
        this.usage.endTime
          .clone()
          .utc()
          .startOf(this.usage.selectedUsageDuration === 'monthly' ? 'month' : 'year')
          .format('YYYY-MM-DD'),
        this.usage.endTime.utc().format('YYYY-MM-DD'),
        this.usage.selectedUsageDuration,
        this.timezoneOffset
      ),
      this.store.select(selectDevices)
    ]).pipe(
      map(([usage, devices]) => this.groupUsageToCategories(usage, devices)),
      share()
    );
  }

  durationString(outage: IIspOutageMonthData[] | IIspOutageYearData[], type: 'monthly' | 'yearly'): string {
    if (!outage) {
      return '';
    }
    const sumMinutes =
      type === 'monthly'
        ? (outage as IIspOutageMonthData[]).reduce((acc, val) => acc + val.totalOutageTimeInMinutesPerMonth, 0)
        : (outage as IIspOutageYearData[]).reduce((acc, val) => acc + val.totalOutageTimeInMinutesPerYear, 0);
    const hours = Math.floor(sumMinutes / 60);
    const minutes = sumMinutes % 60;
    return this.translate.instant('lte.formatedDuration', { hours, minutes });
  }

  outagePreviousMonth(): void {
    this.monthOutageInfo.endTime = this.monthOutageInfo.endTime.subtract(1, 'months').endOf('month');

    this.monthOutageInfo.nextAvailable = true;
    this.reloadMonthOutage();
  }

  outageNextMonth(): void {
    this.monthOutageInfo.endTime = this.monthOutageInfo.endTime.add(1, 'months').endOf('month');

    if (this.monthOutageInfo.endTime > moment()) {
      this.monthOutageInfo.endTime = moment();
      this.monthOutageInfo.nextAvailable = false;
    }
    this.reloadMonthOutage();
  }

  reloadMonthOutage(): void {
    this.monthOutageInfo.outage$ = this.lteService
      .ispOutage$(
        this.monthOutageInfo.endTime.clone().utc().startOf('month').format('YYYY-MM-DD'),
        this.monthOutageInfo.endTime.utc().format('YYYY-MM-DD'),
        'monthly',
        this.timezoneOffset
      )
      .pipe(
        map((val) => ({
          ...val,
          data: (val.data ).filter((item) => item.outageMonth === val.statsDateRange.start)
        })),
        share()
      );

    this.outgoingOutageMin$ = this.monthOutageInfo.outage$?.pipe(
      filter((outage) => {
        const start = moment(outage.statsDateRange.end).endOf('day');
        const end = moment().utc().endOf('day');
        const offset = this.timezoneOffset * 1000 * 60;

        return start.diff(end) + offset === 0;
      }),
      map((outage) =>
        outage.data?.[0]?.ongoingOutageTimeInMinutes
          ? outage.data?.[0]?.ongoingOutageTimeInMinutes + this.timezoneOffset
          : undefined
      )
    );

    this.monthOutageInfo.outage$.pipe(take(1)).subscribe(
      (outage: IIspOutage<IIspOutageMonthData[]>) => {
        this.monthOutageInfo.dataSet = [...this.monthOutageInfo.dataSet];
        this.monthOutageInfo.dataSet[0].dataSets[0] = {
          ...this.monthOutageInfo.dataSet[0].dataSets[0],
          xEnd: this.monthOutageInfo.endTime.daysInMonth(),
          data: this.convertMonthOutageToDataSource(outage.data )
        };
      },
      () => {
        this.monthOutageInfo.dataSet = [...this.monthOutageInfo.dataSet];
        this.monthOutageInfo.dataSet[0].dataSets[0] = {
          ...this.monthOutageInfo.dataSet[0].dataSets[0],
          xEnd: this.monthOutageInfo.endTime.daysInMonth(),
          data: []
        };
      }
    );
  }

  outagePreviousYear(): void {
    this.yearOutageInfo.endTime = this.yearOutageInfo.endTime.subtract(1, 'years').endOf('year');

    this.yearOutageInfo.nextAvailable = true;
    this.reloadYearOutage();
  }

  outageNextYear(): void {
    this.yearOutageInfo.endTime = this.yearOutageInfo.endTime.add(1, 'years').endOf('year');

    if (this.yearOutageInfo.endTime > moment()) {
      this.yearOutageInfo.endTime = moment();
      this.yearOutageInfo.nextAvailable = false;
    }
    this.reloadYearOutage();
  }

  pruneOutageData(outageData: any): any {
    const outageYr = this.yearOutageInfo.endTime.format('YYYY');
    const newOutageData: any = [];
    outageData.forEach((dat: any) => {
      if (outageYr === dat.outageYear.split('-')[0]) {
        newOutageData.push(dat);
      }
    });
    return newOutageData;
  }

  reloadYearOutage(): void {
    this.yearOutageInfo.outage$ = this.lteService
      .ispOutage$(
        this.yearOutageInfo.endTime.clone().utc().startOf('year').format('YYYY-MM-DD'),
        this.yearOutageInfo.endTime.utc().format('YYYY-MM-DD'),
        'yearly',
        this.timezoneOffset
      )
      .pipe(share());

    this.yearOutageInfo.outage$.pipe(take(1)).subscribe((outage: IIspOutage<IIspOutageYearData[]>) => {
      this.yearOutageInfo.dataSet = [...this.yearOutageInfo.dataSet];
      const data = this.convertYearOutageToDataSource(this.pruneOutageData(outage.data));
      this.yearOutageInfo.dataSet[0].dataSets[0] = {
        ...this.yearOutageInfo.dataSet[0].dataSets[0],
        data
      };
      this.yearOutageInfo.dataSet[0].autoScale = data?.reduce((acc, item) => acc + item.yVal, 0) > 0; // autoScale must contains at lest one non zero data
    });
  }

  getUsageByCategory(
    data: UsageGroupedWithStatistics | undefined | null,
    category: string
  ): SingleUsageGroupWithStatistics {
    return data?.[category];
  }

  usageDurationChange(value: 'monthly' | 'yearly'): void {
    this.usage.endTime = moment();
    this.usage.nextAvailable = false;
    this.usage.selectedUsageDuration = value;
    this.reloadUsage();
  }

  usagePrevious(): void {
    this.usage.endTime = this.usage.endTime
      .subtract(1, this.usage.selectedUsageDuration === 'monthly' ? 'months' : 'years')
      .endOf(this.usage.selectedUsageDuration === 'monthly' ? 'month' : 'year');

    this.usage.nextAvailable = true;
    this.reloadUsage();
  }

  usageNext(): void {
    this.usage.endTime = this.usage.endTime
      .add(1, this.usage.selectedUsageDuration === 'monthly' ? 'months' : 'years')
      .endOf(this.usage.selectedUsageDuration === 'monthly' ? 'month' : 'year');

    if (this.usage.endTime > moment()) {
      this.usage.endTime = moment();
      this.usage.nextAvailable = false;
    }
    this.reloadUsage();
  }

  round(num: number): number {
    return Math.round(num || 0);
  }

  private initMonthOutageInfo(): DataSetGroup[] {
    return [
      {
        dataSets: [
          {
            color: '#8884FF',
            barTopColor: '#1FDC90',
            data: [],
            type: 'intervalBar',
            xStepVisualization: 'line',
            yStepVisualization: 'line',
            xTextPosition: 'start',
            yTextPosition: 'start',
            xStart: 1,
            xEnd: 30,
            yStart: 0,
            yEnd: 24 * 60,
            xLabelStart: 1,
            xLabelStep: 1,
            yLabelStart: 0,
            yLabelStep: (24 * 60) / 3,
            xValText: (val) =>
              val % 10 === 1 || val % 10 === 6 ? `${this.monthOutageInfo.endTime.format('MMM')} ${val}` : '',
            yValText: (val) => moment((val - 1) * 60 * 1000).format('ha'),
            toolTipHtml: (data: DataWithPosition) => {
              const timeData = data.yInterval
                .map((interval) => ({
                  start: this.monthOutageInfo.endTime
                    .clone()
                    .startOf('month')
                    .add(data.xVal - 1, 'days')
                    .add(interval.start, 'minutes')
                    .format('L LT'),
                  end: this.monthOutageInfo.endTime.clone().startOf('day').add(interval.end, 'minutes').format('LT')
                }))
                .map((time) => `${time.start} - ${time.end}`);
              return this.sanitizer.bypassSecurityTrustHtml(
                `<div style="white-space: nowrap;">${timeData.join('<br>')}</div>`
              );
            }
          }
        ],
        startFromZero: false,
        autoScale: false
      }
    ] as DataSetGroup[];
  }

  private convertMonthOutageToDataSource(data: IIspOutageMonthData[]): {
    xVal: number;
    yVal: number;
    yInterval?: {
      start: number;
      end: number;
    }[];
  }[] {
    return (
      data
        // flat [][][] to [][]
        .reduce((acc, val) => [...acc, ...val.outagesPerMonth], [] as { startTime: string; endTime: string }[][])
        // flat [][] to []
        .reduce(
          (acc, value) => [...acc, ...value],
          [] as {
            startTime: string;
            endTime: string;
          }[]
        )
        // change time array to array with days
        .reduce((acc, value) => {
          let start = moment(value.startTime);
          // moment takes null as error, but undefined as current time. endTime === null represents ongoing event, so it should end on current time
          let end = moment(value.endTime || undefined);

          if (end.clone().startOf('day') > this.monthOutageInfo.endTime) {
            end = this.monthOutageInfo.endTime.clone();
          }

          // start and end can be in another day / month
          while (start.clone().startOf('day') <= end.clone().startOf('day')) {
            if (start >= this.monthOutageInfo.endTime.clone().startOf('month')) {
              acc.push({
                day: start.date(),
                start: start.diff(start.clone().startOf('day'), 'minutes'), // start in minutes from beginning of day
                // end in minutes from beginning of day - if same day then use end time, otherwise end of day
                end:
                  start.clone().startOf('day').diff(end.clone().startOf('day')) === 0
                    ? end.diff(end.clone().startOf('day'), 'minutes')
                    : 24 * 60
              });
            }
            start = start.clone().add(1, 'days').startOf('day');
          }

          return acc;
        }, [] as { day: number; start: number; end: number }[])
        // reduce array by days
        .reduce(
          (acc, value) => {
            const accItem = acc.find((v) => v.xVal === value.day);
            const interval = { start: value.start, end: value.end };

            if (accItem) {
              accItem.yInterval.push(interval);
              return acc;
            } else {
              return [...acc, { xVal: value.day, yVal: 0, yInterval: [interval] }];
            }
          },
          [] as {
            xVal: number;
            yVal: number;
            yInterval?: { start: number; end: number }[];
          }[]
        )
    );
  }

  private convertYearOutageToDataSource(data: IIspOutageYearData[]): {
    xVal: number;
    yVal: number;
  }[] {
    return data
      .reduce(
        (acc, val) => [...acc, ...val.totalOutageTimeInMinutesPerMonth],
        [] as { outageMonthNumber: number; timeInMinutes: number }[]
      )
      .map((val) => ({ xVal: val.outageMonthNumber, yVal: val.timeInMinutes / 60 }));
  }

  private groupUsageToCategories(usage: ILteDataUsage, devices: IDevice[]): UsageGroupedWithStatistics {
    return (
      // flat [][] to []
      usage.data
        .reduce((acc, val) => [...acc, ...val.deviceUsagePercentage], [] as ILteDeviceUsagePercentage[])
        // mix in device to usage object
        .map((deviceUsage) => ({
          ...deviceUsage,
          device: devices?.find((device) => device.mac === deviceUsage.deviceMac)
        }))
        // filter and flat device data fo usage object
        .map((deviceUsage) => ({
          name: deviceUsage.device?.nickname ?? deviceUsage.device?.name,
          deviceUsagePercentage: deviceUsage.deviceUsagePercentage,
          category: deviceUsage.device?.category,
          iconV2: deviceUsage.device?.iconV2,
          mac: deviceUsage.deviceMac
        }))
        // drop usage object without device category or device name
        .filter((usageObj) => usageObj.category && usageObj.name)
        // group by category and add statistics
        .reduce((acc, val) => {
          const { category, ...mappedVal } = val;
          return {
            ...acc,
            [category]: {
              devices: acc[category]?.devices ? [...acc[category]?.devices, mappedVal] : [mappedVal],
              statistics: {
                deviceSum: (acc[category]?.statistics?.deviceSum ?? 0) + 1,
                usageSum: (acc[category]?.statistics?.usageSum ?? 0) + mappedVal.deviceUsagePercentage
              }
            }
          };
        }, {} as UsageGroupedWithStatistics)
    );
  }
}
