import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { hostNameParse } from 'src/environments/shared/okta';
import firebase from 'firebase/app';
import * as merge from 'deepmerge';
import 'firebase/database';
import 'firebase/auth';

@Injectable()
export class FirebaseService {
  db: any;
  root: any;
  snapshot: any = {};
  timer: any;
  timeout: number = 3000;
  callbacks: any[] = [];
  fallbackUrl: string = '/assets/config/firebase.json';

  constructor() {
    firebase.initializeApp(environment.firebase);

    const deployment = this.getDeployment();

    this.db = firebase.database();
    this.root = this.db.ref('/' + deployment);
  }

  authenticate(): Promise<firebase.auth.UserCredential> {
    return firebase.auth().signInAnonymously();
  }

  calculateTokenPermissions(user: any): any {
    const rootFeatures = {};
    const uiFeatures = {};

    if (user) {
      if ('centralEnabledUIFeatures' in user) {
        user.centralEnabledUIFeatures.forEach((feature: string) => {
          if (feature.includes('root.')) {
            const rootFeature = feature.substring(5);

            rootFeatures[rootFeature] = true;
          } else {
            uiFeatures[feature] = true;
          }
        });
      }

      if ('centralDisabledUIFeatures' in user) {
        user.centralDisabledUIFeatures.forEach((feature: string) => {
          if (feature.includes('root.')) {
            const rootFeature = feature.substring(5);

            rootFeatures[rootFeature] = false;
          } else {
            uiFeatures[feature] = false;
          }
        });
      }
    }

    if (Object.keys(uiFeatures).length) {
      return { ...rootFeatures, uiFeatures };
    } else {
      return rootFeatures;
    }
  }

  calculatePermissions(data: any, role: string, domain: string, user: any): any {
    if (role) {
      return merge.all([
        data.defaults.permissions,
        data.defaults.roles[role] ? data.defaults.roles[role] : {},
        data.domains[domain] && data.domains[domain].permissions ? data.domains[domain].permissions : {},
        data.domains[domain] && data.domains[domain][role] ? data.domains[domain][role] : {},
        this.calculateTokenPermissions(user),
        user.partnerId &&
        data.partnerIds &&
        data.partnerIds[user.partnerId] &&
        data.partnerIds[user.partnerId].permissions
          ? data.partnerIds[user.partnerId].permissions
          : {},
        user.partnerId &&
        data.partnerIds &&
        data.partnerIds[user.partnerId] &&
        data.partnerIds[user.partnerId].roles &&
        data.partnerIds[user.partnerId].roles[role]
          ? data.partnerIds[user.partnerId].roles[role]
          : {}
      ]);
    } else {
      return null;
    }
  }

  getDeployment(): 'production' | 'development' {
    const { deployment } = hostNameParse();

    if (deployment) {
      switch (deployment) {
        case 'noc':
        case 'central':
        case 'noc-stg':
        case 'central-stg':
          return 'production';
        default:
          return 'development';
      }
    } else {
      switch (environment.ENV) {
        case 'Production':
        case 'Staging':
          return 'production';
        default:
          return 'development';
      }
    }
  }

  fallback(callback: any): void {
    this.callbacks.push(callback);
    this.cancelFallback();
    this.timer = setTimeout(() => {
      this.callbacks.forEach((callback: any) => callback());
      this.callbacks = [];
    }, this.timeout);
  }

  cancelFallback(): void {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }
}
