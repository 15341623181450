import { createAction, props } from '@ngrx/store';
import { I5gQoeMetrics, INeighborReportCns } from 'src/app/lib/interfaces/interface';
export const load5gQoeMetrics = createAction(
    '[5g] Load 5G QoE Metrics',
    props<{ startTime: string; endTime: string }>()
  );
export const qoe5GReportsLoaded = createAction('[5g] 5g QoE reportsloaded', props<{ qoe5gReports: I5gQoeMetrics[] }>());
export const qoe5GReportsLoadedError = createAction('[5g]  5g QoE reports loaded error', props<{ error: any }>());

export const loadNeighborReportCns = createAction('[5g] Load Neighbor Report Cns');
export const neighborReportCnsLoaded = createAction('[5g] Neighbor Report Cns loaded ', props<{ neighborReportCns: INeighborReportCns }>());
export const neighborReportCnsLoadedError = createAction('[5g] Neighbor Report Cns loaded error', props<{ error: any }>());
