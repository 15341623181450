import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'locationBubble',
  templateUrl: './locationBubble.component.html',
  styleUrls: ['./locationBubble.component.scss']
})
export class LocationBubbleComponent implements OnChanges {
  @Input()
  locations: any;

  sort: string;
  headers: any;
  list: any[];

  constructor() {
    this.initHeaders();
  }

  ngOnChanges(changes: any): void {
    if (changes.locations) {
      this.list = [...this.locations];
    }
  }

  initHeaders(): void {
    this.sort = 'timeStamp';
    this.headers = [
      {
        key: 'locationID',
        value: 'netops.firmware.locations.locationID',
        desc: false
      },
      {
        key: 'activeVersionMatrix',
        value: 'netops.firmware.locations.activeVersionMatrix',
        desc: false
      },
      {
        key: 'shard',
        value: 'netops.firmware.locations.shard',
        desc: false
      },
      {
        key: 'groups',
        value: 'netops.firmware.locations.groups',
        desc: false
      },
      {
        key: 'models',
        value: 'netops.firmware.locations.models',
        desc: false
      }
    ];
  }
}
