import { Component, Input } from '@angular/core';

@Component({
  selector: 'health-line',
  templateUrl: './health-line.component.html',
  styleUrls: ['./health-line.component.scss']
})
export class HealthLineComponent {
  @Input() value = 0;
  maxValue = 5;
  @Input() status: 'excellent' | 'good' | 'fair' | 'warn' | 'poor' | 'error' | undefined = undefined;
  constructor() {}

  barWidth(): number {
    return (this.value * 100) / this.maxValue || 0;
  }
}
