<div class="toggler" [class.disabled]="!editable">
  <div
    class="slider"
    [style.left.px]="left"
    [style.width.px]="width"
    [class.animate]="animate"
    *ngIf="!hideSlider"
  ></div>
  <div
    #items
    class="option"
    [class.marked]="option.marked"
    [class.selected]="option.selected"
    [class.notselected]="!option.selected"
    [class.unselectable]="option.unselectable"
    *ngFor="let option of options"
    (click)="changeOption(option, $event)"
  >
    <icon [name]="option.icon" class="custom" [ngStyle]="{ fill: option.iconColor }" *ngIf="option.icon"></icon>
    <div class="content">{{ option.translation | translate }}</div>
    <icon name="icon-check" class="size-0_5 marked" *ngIf="option.marked"></icon>
  </div>
</div>
