import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DeviceIconRefService {
  iconRefs: any = {
    awair_glow: true,
    awair: true,
    camera_canary: true,
    camera_nest: true,
    camera: true,
    desktop_imac: true,
    desktop_macpro: true,
    desktop: true,
    doorbell_ring_chime: true,
    doorbell_ring: true,
    ebook_kindle: true,
    ebook_nook: true,
    gamingconsole: true,
    hub_philips: true,
    laptop_macbook: true,
    laptop: true,
    lightbulb_lifx: true,
    lightbulb_philipshue: true,
    lightbulb: true,
    mediasettopbox: true,
    phone: true,
    point: true,
    printer: true,
    rotimatic: true,
    router: true,
    smartphone_android_this: true,
    smartphone_android: true,
    smartphone_iphone: true,
    smartphone: true,
    smartplug_tplink: true,
    smartplug: true,
    smokedetector_nestprotect: true,
    speaker_echo_dot: true,
    speaker_echo_show: true,
    speaker_echo_spot: true,
    speaker_echo: true,
    speaker_homepod: true,
    speaker_google_home: true,
    speaker_sonos_play1: true,
    speaker_sonos_play3: true,
    speaker_sonos_play5: true,
    speaker_sonos_playbar: true,
    speaker_sonos_sub: true,
    speaker: true,
    sprinkler: true,
    tablet_ipad: true,
    tablet_phablet_this: true,
    tablet_phablet: true,
    tablet_this: true,
    tablet: true,
    thermostat: true,
    tv: true,
    unknown: true,
    watch: true,
    weather_bloomsky: true
  };

  get(name: string): string {
    if (name) {
      let iconNameChunks = name.split('-');

      while (iconNameChunks.length > 0) {
        const assembledName = iconNameChunks.join('_');

        if (this.iconRefs[assembledName]) {
          return assembledName;
        } else {
          iconNameChunks = iconNameChunks.slice(0, iconNameChunks.length - 1);
        }
      }
    }

    return 'unknown';
  }
}
