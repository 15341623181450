import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'neighborreportalarm',
  templateUrl: './neighborreportalarm.component.html',
  styleUrls: ['./neighborreportalarm.component.scss']
})
export class NeighborreportalarmComponent implements OnInit {
  @Input()
  alarm: any = {};
  timestamp: string = '';
  hasTimestamp: boolean = false;
  showList: boolean = false;

  constructor() {}

  ngOnInit(): void {
    if (this.alarm.timestamp) {
      this.timestamp = moment(this.alarm.timestamp).format('L LT');
      this.hasTimestamp = true;
    }
  }
}
