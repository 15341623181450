<div class="row" id="conf-multicast">
  <div class="col-xs-12">
    <collapsiblebox [show]="expand" [collapsible]="true" (toggle)="toggleExpand()">
      <div header>{{ 'configurations.multicast.title' | translate }}</div>
      <div container *ngIf="expand">
        <div class="columns">
          <div class="column">
            <div class="row">
              <div class="key">{{ 'configurations.multicast.networkConfigurations.igmpSnooping' | translate }}</div>
              <div class="value">
                <slider-toggle-mobile-aware
                  [options]="igmpSnoopingItems"
                  [editable]="plume.isSomeAdminRole() && (capabilities$ | async)?.igmpSnooping?.capable"
                  (toggle)="action('igmpSnooping', $event)"
                ></slider-toggle-mobile-aware>
              </div>
            </div>
            <div class="row">
              <div class="key">{{ 'configurations.multicast.networkConfigurations.igmpProxy' | translate }}</div>
              <div class="value">
                <slider-toggle-mobile-aware
                  [options]="igmpProxyItems"
                  [editable]="plume.isSomeAdminRole() && (capabilities$ | async)?.igmpProxy?.capable"
                  (toggle)="action('igmpProxy', $event)"
                ></slider-toggle-mobile-aware>
              </div>
            </div>
            <div class="row">
              <div class="key">{{ 'configurations.multicast.networkConfigurations.mldProxy' | translate }}</div>
              <div class="value">
                <slider-toggle-mobile-aware
                  [options]="mldProxyItems"
                  [editable]="plume.isSomeAdminRole() && (capabilities$ | async)?.mldProxy?.capable"
                  (toggle)="action('mldProxy', $event)"
                ></slider-toggle-mobile-aware>
              </div>
            </div>
            <div class="row">
              <div class="key">
                {{ 'configurations.multicast.networkConfigurations.multicastToUnicast' | translate }}
              </div>
              <div class="value">
                <slider-toggle-mobile-aware
                  [options]="multicastToUnicastItems"
                  [editable]="plume.isSomeAdminRole() && (capabilities$ | async)?.multicastToUnicast?.capable"
                  (toggle)="action('multicastToUnicast', $event)"
                ></slider-toggle-mobile-aware>
              </div>
            </div>
          </div>
        </div>
      </div>
    </collapsiblebox>
  </div>
</div>
