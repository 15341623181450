import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { switchMap, take } from 'rxjs/operators';
import { selectBaseUrl } from 'src/app/store/customer/customer.selectors';
import { ICustomer } from '../interfaces/interface';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class PowerManagementService {
  constructor(private store: Store, private api: ApiService) {}

  setPowerMode$(mode: string): Observable<ICustomer> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.patch(`${baseUrl}/config/powerManagement`, { mode }))
    );
  }

  setForcePower$(duration: number): Observable<ICustomer> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.post(`${baseUrl}/event/forcePowerManagement`, { duration }))
    );
  }
}
