<div class="row" id="conf-lte" *ngIf="(permissions$ | async)?.uiFeatures?.lte && lteCloudSupported">
  <div class="col-xs-12">
    <collapsiblebox [show]="expand" [collapsible]="true" (toggle)="toggleExpand()">
      <div header>{{ 'configurations.lte.title' | translate }}</div>
      <div container class="main-container utilities" *ngIf="expand">
        <div class="columns">
          <div class="column">
            <ng-container *ngIf="supportedData$ | async as supportedData">
              <div class="row">
                <div class="key">{{ 'configurations.lte.serviceOwner' | translate }}</div>
                <div class="value">
                  {{ 'configurations.lte.serviceOwnerEnum.' + supportedData?.serviceOwner | translate }}
                </div>
              </div>

              <div class="row">
                <div class="key">{{ 'configurations.lte.isGateway' | translate }}</div>
                <div class="value">
                  {{ 'booleanEnum.' + supportedData?.isGateway | translate }}
                </div>
              </div>
              <div class="row">
                <div class="key">{{ 'configurations.lte.isHardwareCapable' | translate }}</div>
                <div class="value">
                  {{ 'booleanEnum.' + supportedData?.isHardwareCapable | translate }}
                </div>
              </div>
              <div class="row">
                <div class="key">{{ 'configurations.lte.isRouterMode' | translate }}</div>
                <div class="value">
                  {{ 'booleanEnum.' + supportedData?.isRouterMode | translate }}
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="capable$ | async">
              <div class="row">
                <div class="key">{{ 'configurations.lte.iccid' | translate }}</div>
                <div class="value">{{ iccid$ | async }}</div>
              </div>
              <div class="row">
                <div class="key">{{ 'configurations.lte.imei' | translate }}</div>
                <div class="value">{{ imei$ | async }}</div>
              </div>
              <div class="row" *ngIf="signalStrength$ | async as signalStrengthObj">
                <div class="key">{{ 'configurations.lte.signalStrength' | translate }}</div>
                <div class="value">
                  {{
                    'configurations.lte.signalStrengthValue'
                      | translate: { value: signalStrengthObj.signalStrength || 0 }
                  }}
                </div>
              </div>
              <div class="row" *ngIf="linkState$ | async as linkState">
                <div class="key">{{ 'configurations.lte.linkState' | translate }}</div>
                <div class="value">
                  {{ 'configurations.lte.linkStateEnum.' + linkState | translate }}
                </div>
              </div>
              <div class="row">
                <div class="key">{{ 'configurations.lte.apn' | translate }}</div>
                <div class="value editable" *ngIf="!editApn">
                  <ng-container *ngrxLet="apn$ as apn">
                    {{ apn }}
                    <icon name="icon-edit" (click)="startApnEdit(apn)"></icon>
                  </ng-container>
                </div>
                <div class="editMode" *ngIf="editApn">
                  <input
                    (keyup.enter)="confirmApnEdit()"
                    (keyup.escape)="cancelApnEdit()"
                    (keyup)="apnErrorText = ''"
                    [formControl]="apnControl"
                    type="text"
                    autofocus
                  />
                  <div class="controls">
                    <icon name="icon-check" class="confirm" (click)="confirmApnEdit()"></icon>
                    <icon name="icon-close" class="size-0_8 cancel" (click)="cancelApnEdit()"></icon>
                  </div>
                </div>
                <div class="errorText" *ngIf="editApn && apnErrorText">{{ apnErrorText }}</div>
                <div class="errorText" *ngIf="editApn && !apnErrorText && this.apnControl.hasError('maxlength')">
                  {{ 'maxLength' | translate: { max: 63 } }}
                </div>
              </div>
              <div class="row" *ngrxLet="lteServiceState$ as lteServiceState">
                <div class="key">{{ 'configurations.lte.enabled' | translate }}</div>
                <slider-toggle-mobile-aware
                  [options]="lteEnabledOptions$ | async"
                  (toggle)="enableLte($event)"
                ></slider-toggle-mobile-aware>
              </div>
              <div class="row">
                <div class="key">{{ 'configurations.lte.inUse' | translate }}</div>
                <div class="value">
                  <div class="value enabled" *ngrxLet="lteInUse$ as lteInUse">
                    <div class="circle" [class.green]="lteInUse" [class.red]="!lteInUse"></div>
                    <div *ngIf="lteInUse">
                      {{ 'configurations.lte.inUse' | translate }}
                    </div>
                    <div *ngIf="!lteInUse">
                      {{ 'configurations.lte.notInUse' | translate }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="key">{{ 'configurations.lte.forceSwitch' | translate }}</div>
                <div class="value">
                  <div
                    class="toggler"
                    [tooltip]="
                      forceSwitchDisabledTooltip(
                        (lteServiceState$ | async) !== true,
                        (supportedData$ | async)?.isGateway !== true,
                        (supportedData$ | async)?.isRouterMode !== true
                      ) | translate
                    "
                  >
                    <div class="checkbox">
                      <label
                        *ngrxLet="lteForced$ as lteForced"
                        for="toggleLte"
                        class="toggle"
                        [class.checked]="lteForced"
                        [class.disabled]="
                          (lteServiceState$ | async) !== true ||
                          (supportedData$ | async)?.isGateway !== true ||
                          (supportedData$ | async)?.isRouterMode !== true
                        "
                        (click)="forceSwitch(lteForced !== true)"
                      ></label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="key">{{ 'configurations.lte.persistence' | translate }}</div>
                <slider-toggle-mobile-aware
                  [options]="persistenceOptions$ | async"
                  (toggle)="persistenceEdit($event)"
                ></slider-toggle-mobile-aware>
              </div>
              <div class="row">
                <box class="secondaryNetworks">
                  <div header (click)="expandGeoIp = !expandGeoIp">
                    <div>
                      <icon name="icon-arrow-down" class="size-0_8" [class.rotate180]="expandGeoIp"></icon>
                      {{ 'configurations.lte.geoIp' | translate }}
                    </div>
                  </div>
                  <div container *ngIf="expandGeoIp">
                    <ng-container *ngrxLet="geoIp$ as geoIp">
                      <ngx-json-viewer *ngIf="geoIp" [json]="geoIp"></ngx-json-viewer>
                      <span *ngIf="!geoIp">{{ 'configurations.lte.geoIpMissing' | translate }}</span>
                    </ng-container>
                  </div>
                </box>
              </div>
              <div class="row" *ngIf="(networkConfiguration$ | async | cache)?.length > 0">
                <box class="secondaryNetworks">
                  <div header (click)="expandSecondNetworks = !expandSecondNetworks">
                    <div>
                      <icon name="icon-arrow-down" class="size-0_8" [class.rotate180]="expandSecondNetworks"></icon>
                      {{ 'configurations.lte.secondaryNetworksHeader' | translate }}
                    </div>
                  </div>
                  <div container *ngIf="expandSecondNetworks">
                    <div
                      class="row"
                      *ngFor="let network of networkConfiguration$ | async | cache as networks; trackBy: trackByNetwork"
                    >
                      <div class="networkId">{{ network.networkId }}</div>
                      <slider-toggle-mobile-aware
                        [options]="network.toggler"
                        (toggle)="secondaryNetworkChangeState(networks, network.networkId, $event)"
                      ></slider-toggle-mobile-aware>
                    </div>
                  </div>
                </box>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </collapsiblebox>
  </div>
</div>
