<collapsiblebox mode="bare" [collapsible]="true" toggleSide="right" (toggle)="opened = $event" *ngIf="supported">
  <div class="title" header>
    {{ 'health.networkInformation.locationOnline.title' | translate }}
    <icon
      name="icon-info"
      class="skip-toggle"
      [tooltip]="'health.networkInformation.locationOnline.tooltip' | translate"
    ></icon>
  </div>
</collapsiblebox>
<div class="content" *ngIf="opened && supported">
  <div class="sliderBox">
    <slidetoggler [options]="chartModes" (toggle)="toggleChartMode($event)"></slidetoggler>
  </div>
  <div class="subTitle">
    {{ 'health.networkInformation.locationOnline.locationOnline' | translate }}
    <icon
      name="icon-info"
      class="skip-toggle"
      [tooltip]="'health.networkInformation.locationOnline.locationOnlineTooltip' | translate"
    ></icon>
  </div>
  <flm-graph-network-status [dataSet]="dataSet" [axisType]="axisType"></flm-graph-network-status>
  <button class="reportButton action" (click)="getMonthlyOutage($event)" *ngIf="outageEventsSupported">
    {{ 'health.networkInformation.outageReport.outageReportButton' | translate }}
  </button>
</div>

<modal mode="wide" (overlayAction)="showMonthlyOutage = $event" *ngIf="showMonthlyOutage">
  <ng-container title>
    {{ 'health.networkInformation.outageReport.outageReportTitle' | translate }}
  </ng-container>

  <ng-container content class="noOutage" *ngIf="outageRecords.length < 1">
    {{ 'health.networkInformation.outageReport.noOutage' | translate }}
  </ng-container>

  <ng-container content *ngIf="outageRecords.length > 0">
    <div class="table">
      <div class="row header">
        <div class="cell header">{{ 'health.networkInformation.outageReport.start' | translate }}</div>
        <div class="cell header">{{ 'health.networkInformation.outageReport.end' | translate }}</div>
        <div class="cell header">{{ 'health.networkInformation.outageReport.duration' | translate }}</div>
      </div>
      <div *ngFor="let outage of outageRecords" class="row">
        <div class="cell">{{ outage.start }}</div>
        <div class="cell">{{ outage.end }}</div>
        <div class="cell">{{ outage.duration }} {{ 'health.networkInformation.outageReport.minutes' | translate }}</div>
      </div>
    </div>
  </ng-container>
  <ng-container controls>
    <button class="action" (click)="showMonthlyOutage = false">
      {{ 'health.networkInformation.outageReport.close' | translate }}
    </button>
  </ng-container>
</modal>
