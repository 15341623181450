import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IconService } from 'src/app/lib/services/icon.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { QoeService } from 'src/app/lib/services/qoe.service';
import { ToastService } from 'src/app/lib/services/toast.service';
import { TroubleshootingService } from 'src/app/lib/services/troubleshooting.service';

@Component({
  selector: 'qoesuperlive',
  templateUrl: './qoesuperlive.component.html',
  styleUrls: ['./qoesuperlive.component.scss']
})
export class QoesuperliveComponent implements OnChanges, OnDestroy {
  @Input()
  data: any = {};

  @Input()
  period: number = 5;

  @Input()
  enabled: boolean = false;

  loopSubscription: any;
  showLiveCharts: boolean = false;
  showCurrentQoe: boolean = false;
  expanded: boolean = false;
  iconURL: string = '';
  loopLiveData: boolean = false;
  chartSelector: any = {
    list: ['snr', 'qoeScore', 'channelUtilization', 'prr', 'potentialThroughput', 'currentUsage', 'phyRate'],
    selected: 'snr'
  };

  constructor(
    private qoe: QoeService,
    private troubleshooting: TroubleshootingService,
    private mixpanel: MixpanelService,
    private icons: IconService,
    private toast: ToastService,
    private plume: PlumeService
  ) {}

  ngOnChanges(changes: any): void {
    this.getIconPath();
    let updateData: boolean = false;

    if (changes.enabled) {
      this.loopLiveData = this.enabled;

      if (this.enabled) {
        updateData = true;
      }
    }

    if (changes.period) {
      updateData = true;
    }

    if (this.data.liveData) {
      this.data.liveCharts = this.prepareCharts(
        this.qoe.lineCharts(
          JSON.parse(JSON.stringify(this.data.liveData)),
          this.data.demo ? this.data.demo.current.values : {}
        )
      );
    }

    if (changes.data) {
      if (
        changes.data.previousValue &&
        changes.data.previousValue.id &&
        changes.data.previousValue.id !== changes.data.currentValue.id
      ) {
        this.data.liveData = {};
        this.showLiveCharts = false;
        this.expanded = false;
        updateData = true;
      }
    }

    if (updateData) {
      clearTimeout(this.loopSubscription);
      this.getLiveData();
    }

    this.getIconPath();
  }

  selectChart(chart: string): void {
    this.chartSelector.selected = chart;
  }

  getIconPath(): void {
    if (this.data.type === 'pod') {
      this.iconURL = this.data.icon;
    } else {
      this.iconURL = this.icons.getPath(this.data.iconV2);
    }
  }

  toggleExpand(): void {
    if (!this.expanded) {
      this.expanded = true;
      this.showLiveCharts = true;
    } else {
      this.expanded = false;
      this.showLiveCharts = false;
    }
  }

  getLiveData(): void {
    (this.data.type === 'pod'
      ? this.qoe.nodeSuperLiveModeStream$(this.data.id)
      : this.qoe.deviceSuperLiveModeStream$(this.data.id)
    )
      .pipe(catchError((error: any) => of(error)))
      .subscribe((response: any) => {
        this.data.liveData = JSON.parse(JSON.stringify(response));
        this.data.qoe.congestion = this.data.liveData.channelUtilization[0]
          ? this.data.liveData.channelUtilization[0].value
          : this.data.qoe.congestion;
        this.data.liveCharts = this.prepareCharts(
          this.qoe.lineCharts(response, this.data.demo ? this.data.demo.current.values : {})
        );
        if (this.data.liveData.snr[0]?.value) {
          this.data.qoe.rssi = this.data.liveData.snr[0].value - 95;
        }

        if (this.data.status === 'connected') {
          this.showLiveCharts = true;
        }

        this.loopSubscription = setTimeout(() => {
          if (this.loopLiveData) {
            this.getLiveData();
          }
        }, this.period * 1000);
      });
  }

  prepareCharts(charts: any): any {
    Object.keys(charts).forEach((key: string) => {
      if (charts[key].data && charts[key].data.length) {
        charts[key].data = charts[key].data.filter(
          (point: any) =>
            new Date(point.label).getTime() >= new Date(charts[key].data[0].label).getTime() - 10 * 60 * 1000
        );
      }
    });

    return charts;
  }

  resolveAction(action: string): void {
    switch (action) {
      case 'qoe.recommendations.actions.rebootNode':
        if (this.data.type === 'pod') {
          if ((this.plume.cloudVersionAbove1_119() || !this.plume.isStrictSupportRole()) && !this.plume.isFlexRole()) {
            this.troubleshooting.resetNode(this.data.id).subscribe();
            this.mixpanel.storeEvent('QOE_RECOMMENDATION_REBOOT_NODE', { NODE_ID: this.data.id });
          } else {
            this.toast.error('qoe.recommendations.roleMsg', 'qoe.recommendations.roleTitle');
          }
        }
        break;
      case 'qoe.recommendations.actions.resetDevice':
        if (this.data.type === 'device') {
          this.troubleshooting.resetDevice(this.data.id, this.data.parentNodeId, this.data.freqBand).subscribe();
          this.mixpanel.storeEvent('QOE_RECOMMENDATION_RESET_DEVICE', {
            DEVICE_MAC: this.data.id,
            PARENT_ID: this.data.parentNodeId,
            FREQ_BAND: this.data.freqBand
          });
        }
        break;
    }
  }

  ngOnDestroy(): void {
    if (this.loopSubscription) {
      clearTimeout(this.loopSubscription);
    }

    this.loopLiveData = false;
  }
}
