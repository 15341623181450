import { Component, OnDestroy } from '@angular/core';
import { TroubleshootingService } from 'src/app/lib/services/troubleshooting.service';
import { ToastService } from 'src/app/lib/services/toast.service';
import { MessageService } from 'src/app/lib/services/message.service';
import { LoggingService } from 'src/app/lib/services/logging.service';
import * as moment from 'moment';
import { selectLocationInternet, selectNodes } from 'src/app/store/polling/polling.selector';
import { Store } from '@ngrx/store';

@Component({
  selector: 'rebootbubble',
  templateUrl: './rebootBubble.component.html',
  styleUrls: ['../bubbles.scss']
})
export class RebootBubbleComponent implements OnDestroy {
  rebootState: string = 'init';
  rebootTime: any;
  nodeSubscription: any;
  internetSubscription: any;
  timeoutInterval: any;
  timeout: number = 720000;
  optimized: string = '';
  optimizeTime: any;

  constructor(
    private toast: ToastService,
    private troubleShoot: TroubleshootingService,
    private message: MessageService,
    private logging: LoggingService,
    private store: Store
  ) {}

  setTimeout(): void {
    this.timeoutInterval = setInterval(() => {
      this.logging.error('Reboot timeout with no response!');
      this.toast.error('toast.rebootBubble.errorMessage', 'toast.rebootBubble.errorTitle', {
        disableTimeOut: true
      });
      this.clearTimeout();
    }, this.timeout);
  }

  clearTimeout(): void {
    if (this.timeoutInterval) {
      clearInterval(this.timeoutInterval);
    }
  }

  rebootGateway(): void {
    this.rebootGatewayActual();
  }

  sendMessage(success: boolean): void {
    this.logging.log('REBOOT complete: ', success);
    this.message.sendMessage({ source: 'reboot', success, optimized: this.optimized });
  }

  setRebootTime(): void {
    this.rebootTime = moment();
    this.logging.log('REBOOT', this.rebootTime.format('h:mm:ss'));
  }

  checkNodeStatus(nodes: any[], rebootTime: any): boolean {
    let isReboot = false;
    nodes.forEach((node: any) => {
      if (node.backhaulType === 'ethernet') {
        const isBefore = moment(rebootTime).isBefore(node.connectionStateChangeAt);
        this.logging.log(
          'NODE ' +
            node.nickname +
            ' ' +
            node.connectionState +
            ' (last: ' +
            moment(node.connectionStateChangeAt).format('h:mm:ss') +
            ') (isChange=' +
            isBefore +
            ')'
        );

        if (node.connectionState === 'connected' && isBefore === true) {
          this.logging.log('REBOOTED? ' + rebootTime.format('h:mm:ss') + '  REBOOTED');
          isReboot = true;
        }
      }
    });
    this.logging.log('REBOOTED? ' + rebootTime.format('h:mm:ss') + '  isReboot=' + isReboot);
    return isReboot;
  }

  startOptimize(): void {
    if (this.internetSubscription) {
      this.internetSubscription.unsubscribe();
    }

    this.optimizeTime = moment();
    this.troubleShoot.triggerOptimization().subscribe(() => {
      this.logging.log('TRIGGER OPTIMIZE: ' + this.optimizeTime.format('HH:mm:ss:ms'));
      if (this.internetSubscription) {
        this.internetSubscription.unsubscribe();
      }
      this.internetSubscription = this.store.select(selectLocationInternet).subscribe((response: any) => {
        if (response) {
          const isBefore = this.optimizeTime.isBefore(response.optimization.createdAt);
          if (isBefore) {
            this.optimized = response.optimization.state;
          } else {
            this.optimized = 'Initiated';
          }

          this.logging.log(
            ' POLL OPTIMIZE: ' +
              this.optimized +
              '  ' +
              moment(response.optimization.createdAt).format('HH:mm:ss:ms') +
              '   ' +
              isBefore
          );

          if (response.optimization.state === 'optimized' && isBefore) {
            if (this.internetSubscription) {
              this.internetSubscription.unsubscribe();
            }
            this.rebootState = 'success';
            this.logging.log('reboot optimize is optimized');
            this.sendMessage(true);
          }

          if ((response.optimization.state === 'failed' || response.optimization.state === 'fail') && isBefore) {
            if (this.internetSubscription) {
              this.internetSubscription.unsubscribe();
            }
            this.rebootState = 'fail';
            this.logging.log('reboot optimize is fail');
            this.sendMessage(false);
          }
        }
      });
    });
  }

  rebootGatewayActual(): void {
    if (this.rebootState === 'init' || this.rebootState === 'success') {
      this.rebootState = 'busy';
      this.setTimeout();
      this.troubleShoot.resetNetwork().subscribe(
        () => {
          this.setRebootTime();
          if (this.nodeSubscription) {
            this.nodeSubscription.unsubscribe();
          }
          this.nodeSubscription = this.store.select(selectNodes).subscribe((nodes) => {
            if (nodes) {
              if (this.checkNodeStatus(nodes, this.rebootTime)) {
                if (this.nodeSubscription) {
                  this.nodeSubscription.unsubscribe();
                }
                this.rebootState = 'optimize';
                this.clearTimeout();
                this.startOptimize();
              }
            }
          });
        },
        (error: any) => {
          this.logging.error('rebootBubble: error in call to resetNetwork ', error);
        }
      );
    }
  }

  simulateRebootSuccess(): void {
    this.logging.error('<simuilateRebootSuccess> NOT for release');
    this.sendMessage(true);
  }

  simulateRebootFailure(): void {
    this.logging.error('<simulateRebootFailure> NOT for release');
    this.sendMessage(false);
  }

  ngOnDestroy(): void {
    this.clearTimeout();

    if (this.internetSubscription) {
      this.internetSubscription.unsubscribe();
    }

    if (this.nodeSubscription) {
      this.nodeSubscription.unsubscribe();
    }
  }
}
