<ng-container *ngFor="let data of dataSet?.data">
  <svg:g
    *ngIf="isNumber(data.yPos)"
    class="container"
    (mouseenter)="hover(data)"
    (mouseleave)="hover(undefined)"
  >
    <svg:circle
      class="point"
      [attr.cx]="data.xPos"
      [attr.cy]="data.yPos"
      r="2"
      stroke-width="0"
      fill="transparent"
    />
    <!-- hover can work outside of little graphical circle, inside this bigger circle, so user can hover easier -->
    <svg:circle
      [attr.cx]="data.xPos"
      [attr.cy]="data.yPos"
      r="7"
      stroke-width="0"
      fill="transparent"
    />
  </svg:g>
</ng-container>
