<div class="bubble">
  <div class="message" *ngIf="optimized !== 'optimized'">
    {{ 'health.bubbles.optimizeBubble.lastOptimizationFailed' | translate }} '{{ optimized | translate }}'.<br />
    {{ 'health.bubbles.optimizeBubble.tryManual' | translate }}
  </div>
  <div class="message" *ngIf="optimized === 'optimized'">
    {{ 'health.bubbles.optimizeBubble.lastOptimizationStatus' | translate }} '{{ optimized | translate }}'.<br />
    {{ 'health.bubbles.optimizeBubble.tryManual' | translate }}
  </div>

  <div class="row optButtonRow">
    <div class="col-xs-4 optButtonCol">
      <div class="tsButton" (click)="triggerOptimize()">
        <span *ngIf="optimizeState === 'busy'" class="testRunning">
          {{ 'health.bubbles.optimizeBubble.optimizing' | translate }}
        </span>
        <span *ngIf="optimizeState === 'init'" class="testButton">
          {{ 'health.bubbles.optimizeBubble.optimizeNow' | translate }}
        </span>
        <span *ngIf="optimizeState === 'optimized'" class="testButton">
          {{ 'health.bubbles.optimizeBubble.optimized' | translate }}
        </span>
        <span *ngIf="optimizeState === 'fail'" class="fail">
          {{ 'health.bubbles.optimizeBubble.optimizationFailed' | translate }}
        </span>
      </div>
    </div>
    <div class="col-xs-1"></div>
    <div class="col-xs-7 rebootMsgBlock">
      <div *ngIf="optimizeState === 'busy'">
        <div class="sideMsg">{{ 'health.bubbles.optimizeBubble.optimizingNetwork' | translate }}</div>
        <div>{{ 'health.bubbles.optimizeBubble.mayTakeTime' | translate }}</div>
        <div *ngIf="optimized">
          {{ 'health.bubbles.optimizeBubble.lastStatus' | translate }}: {{ optimized | translate }}
        </div>
      </div>
    </div>
  </div>

  <div class="progressbar progressbar--bottom" [class.hidden]="optimizeState !== 'busy'">
    <div class="indeterminate"></div>
  </div>
</div>
