import { Component, Input } from '@angular/core';
import { Bar } from 'src/app/lib/d3/models/objects/bar';

@Component({
  selector: '[barVisual]',
  templateUrl: './bar.component.html',
  styleUrls: ['./bar.component.scss']
})
export class BarVisualComponent {
  @Input()
  barVisual: Bar;

  constructor() {}
}
