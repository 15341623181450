<div class="row" id="conf-steering">
  <div class="col-xs-12">
    <collapsiblebox [show]="expand" [collapsible]="true" (toggle)="toggleExpand()">
      <div header>{{ 'configurations.steering.title' | translate }}</div>
      <div container *ngIf="expand">
        <div class="columns">
          <div class="column">
            <div class="row">
              <div class="key" tooltip="{{ 'configurations.steering.bandSteering' | translate }}">
                {{ 'configurations.steering.opensyncSteering' | translate }}
              </div>
              <div class="value">
                <slider-toggle-mobile-aware
                  [options]="bandSteeringItems$ | async"
                  [editable]="selectBandSteeringEditable$ | async"
                  (toggle)="action('bandSteering', $event)"
                ></slider-toggle-mobile-aware>
              </div>
            </div>
            <div class="row">
              <div class="key" tooltip="{{ 'configurations.steering.clientSteering' | translate }}">
                {{ 'configurations.steering.cloudSteering' | translate }}
              </div>
              <div class="value">
                <slider-toggle-mobile-aware
                  [options]="clientSteeringItems$ | async"
                  [editable]="false"
                  (toggle)="action('clientSteering', $event)"
                ></slider-toggle-mobile-aware>
              </div>
            </div>
          </div>
        </div>
      </div>
    </collapsiblebox>
  </div>
</div>
