import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'qoe15minchart',
  templateUrl: './qoe15minchart.component.html',
  styleUrls: ['./qoe15minchart.component.scss']
})
export class Qoe15minChartVisualComponent implements OnInit {
  @Input()
  titleLabel: string;

  @Input()
  hasOfflines: boolean = false;

  @Input()
  status: string = null;

  @Input()
  data: any;

  render: boolean = false;

  constructor() {}

  ngOnInit(): void {
    setTimeout(() => (this.render = true), this.getRandom());
  }

  getRandom(): number {
    return 100 + Math.floor(Math.random() * 100);
  }
}
