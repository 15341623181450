<modal mode="normal" (overlayAction)="closeModal()">
  <ng-container title>{{ 'devices.device.ipv6' | translate }}</ng-container>
  <ng-container content>
    <div class="ipV6Details" *ngFor="let ip of device.ipv6">
      {{ ip | ipv6 }}
    </div>
  </ng-container>
  <ng-container controls>
    <button class="tertiary light" (click)="closeModal()">
      {{ 'health.networkInformation.modal.close' | translate }}
    </button>
  </ng-container>
</modal>
