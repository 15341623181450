import { Component, Input, OnChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { ModalService } from 'src/app/lib/services/modal.service';
import { selectCapabilities } from 'src/app/store/customer/capabilities.selector';

@Component({
  selector: 'featureengagements',
  templateUrl: './featureengagements.component.html',
  styleUrls: ['./featureengagements.component.scss']
})
export class FeatureEngagementsComponent implements OnChanges {
  capabilities$ = this.store.select(selectCapabilities);
  @Input()
  location: any;

  @Input()
  allDevices: any;

  @Input()
  persons: any;

  @Input()
  wifiMotion: any;

  @Input()
  appTime: any;

  featureEngagements: any = {};

  constructor(private modal: ModalService, private store: Store) {}

  ngOnChanges(changes: any): void {
    if (changes.location) {
      this.prepareLocation();
    }

    if (changes.allDevices) {
      this.prepareDevices();
    }

    if (changes.persons) {
      this.preparePersons();
    }

    if (changes.wifiMotion) {
      this.prepareMotion();
    }

    if (changes.appTime) {
      this.prepareAppTime();
    }
  }

  prepareLocation(): void {
    this.featureEngagements.optimization = {
      label: 'profiler.featureEngagements.optimizationLabel',
      icon: 'assets/icons/icon-feature-optimization.svg',
      description: 'profiler.featureEngagements.optimizationDescription',
      enabled: this.location?.optimizations?.enable
    };

    this.featureEngagements.steering = {
      label: 'profiler.featureEngagements.steeringLabel',
      icon: 'assets/icons/icon-feature-steering.svg',
      description: 'profiler.featureEngagements.steeringDescription',
      enabled: this.location?.clientSteering?.enable || this.location?.bandSteering?.enable
    };

    this.featureEngagements.speedTests = {
      label: 'profiler.featureEngagements.speedTestsLabel',
      icon: 'assets/icons/icon-feature-speedtest.svg',
      description: 'profiler.featureEngagements.speedTestsDescription',
      enabled: this.location?.ispSpeedTestConfiguration?.enable
    };
  }

  prepareDevices(): void {
    if (this.allDevices) {
      const count = {
        devices: this.allDevices?.length || 0,
        freezeSchedules: 0
      };

      this.allDevices?.forEach((device: any) => {
        let hasTimeTemplate = false;

        if (this.location.isUtilizingSharedLocationFreezeSchedules) {
          hasTimeTemplate = !!device.freeze?.schedules?.length;
        } else {
          device.freeze?.timeTemplates?.forEach((timeTemplate: any) => {
            if (timeTemplate.enable) {
              hasTimeTemplate = true;
            }
          });
        }

        if (hasTimeTemplate) {
          count.freezeSchedules++;
        }
      });

      this.featureEngagements.deviceFreeze = {
        label: 'profiler.featureEngagements.deviceFreezeLabel',
        addon: { count: count.freezeSchedules + '/' + count.devices },
        icon: 'assets/icons/icon-feature-freeze.svg',
        description: 'profiler.featureEngagements.deviceFreezeDescription',
        enabled: count.freezeSchedules > 0
      };
    }
  }

  preparePersons(): void {
    this.featureEngagements.peopleProfiles = {
      label: 'profiler.featureEngagements.peopleProfilesLabel',
      icon: 'assets/icons/icon-feature-user.svg',
      description: 'profiler.featureEngagements.peopleProfilesDescription',
      enabled: this.persons?.length > 0
    };
  }

  prepareMotion(): void {
    this.featureEngagements.senseMotion = {
      label: 'profiler.featureEngagements.senseMotionLabel',
      icon: 'assets/icons/icon-feature-sense.svg',
      description: 'profiler.featureEngagements.senseMotionDescription',
      enabled: this.wifiMotion?.auto
    };
  }

  prepareAppTime(): void {
    this.featureEngagements.appTime = {
      label: 'profiler.featureEngagements.appTimeLabel',
      icon: 'assets/icons/icon-feature-apptime.svg',
      description: 'profiler.featureEngagements.appTimeDescription',
      enabled: this.appTime?.enable
    };
  }

  showDetails(item: any): void {
    this.modal.showDialog(item.description, item.label, {
      buttons: [{ style: 'tertiary light', value: 'profiler.close' }],
      params: item.addon || {}
    });
  }
}
