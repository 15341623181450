import { Component, OnInit, OnDestroy } from '@angular/core';
import { ToastService } from 'src/app/lib/services/toast.service';

@Component({
  selector: 'toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnInit, OnDestroy {
  toasts: any[] = [];
  outTimeout: number = 1000;
  inTimeout: number = 5000;
  subscriptions: any[] = [];

  constructor(private toast: ToastService) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.toast.event.subscribe((toast: any) => {
        this.toasts.push(toast);
        if (!toast.options.disableTimeOut) {
          setTimeout(() => {
            this.toast.dismiss(toast.id);
          }, this.inTimeout);
        }
      })
    );

    this.subscriptions.push(
      this.toast.remove.subscribe((id: any) => {
        const toast = this.toasts.find((toast: any) => id === toast.id);
        if (toast) {
          toast.state = 'remove';
          setTimeout(() => {
            this.toasts = this.toasts.filter((message: any) => toast.id !== message.id);
          }, this.outTimeout);
        }
      })
    );
  }

  dismiss(toast: any): void {
    this.toast.dismiss(toast.id);
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length) {
      this.subscriptions.forEach((subscription: any) => subscription.unsubscribe());
    }
  }
}
