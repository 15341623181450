import { Component } from '@angular/core';
import { GraphCalculationsService } from '../graph-calculations/graph-calculations.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[flm-graph-stacked-bar-container]',
  templateUrl: './graph-labeled-stacked-bar.component.html',
  styleUrls: ['./graph-labeled-stacked-bar.component.scss'],
})
export class GraphLabeledStackedBarComponent {
  hoveredXVal?: number;
  constructor(public calculations: GraphCalculationsService) {}

  hover(xVal: number | undefined): void {
    this.hoveredXVal = xVal;
  }
}
