import { Component, Input, OnChanges } from '@angular/core';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { ModalService } from 'src/app/lib/services/modal.service';
import { PlumeService } from 'src/app/lib/services/plume.service';

@Component({
  selector: 'profilersummary',
  templateUrl: './profilersummary.component.html',
  styleUrls: ['./profilersummary.component.scss']
})
export class ProfilerSummaryComponent implements OnChanges {
  @Input()
  reset: any;

  @Input()
  allDevices: any[];

  @Input()
  appFacade: any;

  @Input()
  wanStatsWeekly: any;

  @Input()
  wanStatsDaily: any;

  @Input()
  wifiNetwork: any;

  @Input()
  persons: any;

  streamingDevices: number = null;
  gameConsoles: number = null;
  multiplePeople: boolean = null;
  dataConsumption: string = null;
  otherNetworks: number = null;
  peopleProfiles: number = null;
  possibleGuests: number = null;
  updownstream: boolean = false;
  recommendations: any[] = [];
  loading: boolean = true;

  constructor(private plume: PlumeService, private modal: ModalService, private mixpanel: MixpanelService) {}

  ngOnChanges(changes: any): void {
    if (changes.reset) {
      this.loading = true;
      this.streamingDevices = null;
      this.gameConsoles = null;
      this.multiplePeople = null;
      this.otherNetworks = null;
      this.dataConsumption = null;
      this.peopleProfiles = null;
      this.possibleGuests = null;
      this.updownstream = false;
      this.recommendations = [];
    }

    this.prepare();
  }

  prepare(): void {
    if (this.allDevices && this.wanStatsDaily && this.wanStatsWeekly && this.appFacade && this.persons) {
      this.recommendations = [];
      this.prepareDevices();
      this.prepareSaturation();
      this.prepareWAN();
      this.prepareNetwork();
      this.preparePersons();

      this.loading = false;
    }
  }

  prepareDevices(): void {
    let streamingDevices = 0;
    let gameConsoles = 0;
    let multiplePeople = 0;
    let possibleGuests = 0;

    if (this.allDevices) {
      this.allDevices.forEach((device: any) => {
        switch (device.category) {
          case 'TV':
            streamingDevices++;
            break;
          case 'Game Console':
            gameConsoles++;
            break;
          case 'Smart Phone':
            const date = new Date(device.connectionStateChangeAt).getTime();
            const day = 24 * 60 * 60 * 1000;
            const week = 7 * day;

            if (
              (date > Date.now() - day && device.connectionState === 'disconnected') ||
              device.connectionState === 'connected'
            ) {
              multiplePeople++;
            }

            if (date < Date.now() - week && device.connectionState === 'disconnected') {
              possibleGuests++;
            }
            break;
        }
      });
    }

    if (possibleGuests && this.wifiNetwork?.keys) {
      let guests = 0;

      this.wifiNetwork.keys.forEach((key: any) => {
        switch (key.accessZone) {
          case 'guests':
            guests++;
            break;
        }
      });

      if (!guests && possibleGuests) {
        this.recommendations.push({
          id: 'guestNetwork',
          message: 'profiler.summary.recommendations.guestNetwork.message',
          title: 'profiler.summary.recommendations.guestNetwork.title',
          description: 'profiler.summary.recommendations.guestNetwork.description',
          state: this.getRecommendationState('guestNetwork')
        });
      }
    }

    this.streamingDevices = streamingDevices;
    this.gameConsoles = gameConsoles;
    this.multiplePeople = multiplePeople > 2 ? true : false;
    this.possibleGuests = possibleGuests;
  }

  prepareWAN(): void {
    const data = this.wanStatsWeekly?.hours || null;
    let download = 0;
    let nullValues = 0;

    if (data) {
      data.forEach((hour: any) => {
        if (hour.rxMbytes !== null) {
          download += hour.rxMbytes;
        } else {
          nullValues++;
        }
      });

      if (nullValues < data.length) {
        if (download <= 70000) {
          this.dataConsumption = 'low';
        }

        if (download > 70000 && download <= 231000) {
          this.dataConsumption = 'medium';
        }

        if (download > 231000) {
          this.dataConsumption = 'high';
        }
      } else {
        this.dataConsumption = null;
      }
    } else {
      this.dataConsumption = null;
    }
  }

  prepareNetwork(): void {
    let guests = 0;
    let internetAccess = 0;

    if (this.wifiNetwork?.keys) {
      this.wifiNetwork.keys.forEach((key: any) => {
        switch (key.accessZone) {
          case 'guests':
            guests++;
            break;
          case 'internetAccessOnly':
            internetAccess++;
            break;
        }
      });
    }

    this.otherNetworks = guests + internetAccess;
  }

  preparePersons(): void {
    this.peopleProfiles = this.persons.length;

    if (!this.persons.length && this.allDevices.length) {
      let mobilePhones = 0;

      this.allDevices.forEach((device: any) => {
        if (device.category === 'Smart Phone') {
          mobilePhones++;
        }
      });

      if (mobilePhones > 2) {
        this.recommendations.push({
          id: 'peopleProfiles',
          message: 'profiler.summary.recommendations.peopleProfiles.message',
          title: 'profiler.summary.recommendations.peopleProfiles.title',
          description: 'profiler.summary.recommendations.peopleProfiles.description',
          state: this.getRecommendationState('peopleProfiles')
        });
      }
    }
  }

  prepareSaturation(): void {
    if (this.appFacade && this.wanStatsDaily?.fifteenMins) {
      const downloadSpeed = this.appFacade.dashboard?.mostRecent?.download || null;
      const uploadSpeed = this.appFacade.dashboard?.mostRecent?.upload || null;
      let count = 0;

      this.wanStatsDaily.fifteenMins.forEach((data: any) => {
        const download = Math.round(((data.rxMaxMbps * 100) / downloadSpeed + Number.EPSILON) * 100) / 100;
        const upload = Math.round(((data.txMaxMbps * 100) / uploadSpeed + Number.EPSILON) * 100) / 100;

        if (download > 90 || upload > 90) {
          count++;
        }
      });

      if (count >= 4) {
        this.recommendations.push({
          id: 'internetUsage',
          message: 'profiler.summary.recommendations.internetUsage.message',
          title: 'profiler.summary.recommendations.internetUsage.title',
          description: 'profiler.summary.recommendations.internetUsage.description',
          state: this.getRecommendationState('internetUsage')
        });

        this.updownstream = true;
      } else {
        this.updownstream = false;
      }
    }
  }

  voteRecommendation(state: string, recommendation: any): void {
    recommendation.state = state;

    this.setRecommendations(recommendation);

    this.mixpanel.storeEvent('PROFILER_SUMMARY_RECOMMENDATION_VOTE', {
      RECOMMENDATION: recommendation.id,
      STATE: state
    });
  }

  getRecommendations(): any[] {
    try {
      return JSON.parse(sessionStorage.getItem('profilerSummary'));
    } catch (e: any) {
      return [];
    }
  }

  setRecommendations(recommendation: any): void {
    const recommendations = this.getRecommendations() || {};

    if (recommendations[this.plume.locationid]) {
      recommendations[this.plume.locationid].push({ id: recommendation.id, state: recommendation.state });
    } else {
      recommendations[this.plume.locationid] = [{ id: recommendation.id, state: recommendation.state }];
    }

    sessionStorage.setItem('profilerSummary', JSON.stringify(recommendations));
  }

  getRecommendationState(id: string): string {
    const recommendations = this.getRecommendations() || {};

    let state = 'new';

    if (recommendations[this.plume.locationid]) {
      recommendations[this.plume.locationid].forEach((recommendation: any) => {
        if (recommendation.id === id) {
          state = recommendation.state;
        }
      });
    }

    return state;
  }

  showModal(recommendation: any): void {
    this.modal.showDialog(recommendation.description, recommendation.title, {
      buttons: [{ style: 'tertiary light', value: 'profiler.close' }]
    });
  }
}
