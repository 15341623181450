import { Component, OnInit, OnDestroy } from '@angular/core';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { LoggingService } from 'src/app/lib/services/logging.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { ToolbarService } from 'src/app/lib/services/toolbar.service';
import { Store } from '@ngrx/store';
import { locationChanged } from 'src/app/store/side-menu/side-menu.actions';
import { selectLocationList, selectPipeLocationOnChange } from 'src/app/store/customer/customer.selectors';
import { map, tap } from 'rxjs/operators';
import { selectCapabilities } from 'src/app/store/customer/capabilities.selector';
import { selectLocationInternet, selectNodes } from 'src/app/store/polling/polling.selector';

@Component({
  selector: 'side-menu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss']
})
export class SideMenuComponent implements OnInit, OnDestroy {
  locationList$ = this.store.select(selectLocationList);
  location$ = this.store.pipe(selectPipeLocationOnChange).pipe(
    tap(() => {
      this.toolbar.resetModals();
      this.unsubscribe();
      this.subscriptions = [];
      this.init();
    })
  );
  permissions: any;
  subscriptions: any = [];
  internet: any = {};
  onboarded: boolean = true;
  nodes$ = this.store.select(selectNodes);
  capabilities$ = this.store.select(selectCapabilities);
  hasSecurity$ = this.capabilities$.pipe(
    map((capability) => capability?.dnsCategoryBlocking?.capable || capability?.security?.capable)
  );
  isFlexAdmin: boolean = false;
  isUpriseProperyManager = false;
  ui: string;

  constructor(
    private plume: PlumeService,
    private logging: LoggingService,
    private mixpanel: MixpanelService,
    private toolbar: ToolbarService,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.ui = this.plume.getUI();
  }

  init(): void {
    this.isFlexAdmin = this.plume.isFlexRole();
    this.isUpriseProperyManager = this.plume.isUpriseProperyManager();

    this.subscriptions.push(
      this.plume.permissions.subscribe((data: any) => {
        this.permissions = data;
        this.isOnboarded();
      })
    );

    this.subscriptions.push(
      this.store.select(selectLocationInternet).subscribe((response: any) => {
        if (response) {
          this.internet = response;
          this.isOnboarded();
        }
      })
    );
  }

  changeLocation(location: any): void {
    this.store.dispatch(locationChanged({ newLocation: location.id }));
    this.logging.debug('<changeLocation>');
    this.mixpanel.storeEvent('CHANGE_LOCATION');
  }

  isOnboarded(): void {
    if (this.permissions.uiFeatures.overrideOnboarded) {
      this.onboarded = true;
    } else {
      try {
        if (['OnboardingComplete', 'PodsAdded'].includes(this.internet.summary.onboardingStatus)) {
          this.onboarded = true;
        } else {
          this.onboarded = false;
        }
      } catch (err) {
        this.onboarded = false;
      }
    }
  }

  unsubscribe(): void {
    if (this.subscriptions.length) {
      this.subscriptions.forEach((subscription: any) => subscription?.unsubscribe());
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }
}
