<spacer>{{ 'netops.firmwareUpgrade' | translate }}</spacer>
<div class="container-fluid">
  <div class="row">
    <div class="col-xs-12">
      <span class="title">{{ 'netops.firmware.title' | translate }}</span>
    </div>
  </div>
  <div class="row info">
    <div class="col-xs-12" *ngIf="!shardSelected">
      <div class="row">
        <div class="col-xs-2 key">{{ 'netops.firmware.locationID' | translate }}:</div>
        <div class="col-xs-10">
          <slidetoggler [options]="locationIDItems" (toggle)="action('locationID', $event)"></slidetoggler>
          <div class="details" *ngIf="locationIDSelector != 'off'">
            <div class="pill-hollow">
              <textarea
                rows="1"
                type="text"
                [formControl]="locationIDFormControl"
                (keydown)="resize($event)"
                placeholder="{{ 'netops.firmware.locationID' | translate }}"
                autofocus
              ></textarea>
            </div>
          </div>
        </div>
      </div>

      <ng-container *ngIf="locationIDSelector != 'include-only'">
        <div class="row">
          <div class="col-xs-2 key">{{ 'netops.firmware.groupMembers' | translate }}:</div>
          <div class="col-xs-10">
            <div class="selectors">
              <slidetoggler [options]="membersItems" (toggle)="action('groupMembers', $event)"></slidetoggler>
              <drop-down-select
                class="medium groupsSelect"
                *ngIf="groupMembersSelector != 'off'"
                [emptyText]="'netops.firmware.selectGroup' | translate"
                [multiSelect]="true"
                [filterVisible]="true"
                [values]="groupValues"
                [(ownModel)]="groupsSelected"
                [rowTemplate]="groupRowTemplate"
                [filterPlaceHolder]="'header.search' | translate"
                [filterFunction]="groupFilterFunction"
                [tooltip]="getGroupNames(groupsSelected, groupValues).join(', ')"
                [maxWidth]="99999"
              ></drop-down-select>
              <ng-template #groupRowTemplate let-value>
                <div class="upgradeFirmware groupsSelectRow name">
                  {{ value.object.name }}
                </div>
                <div class="upgradeFirmware groupsSelectRow id">
                  {{ value.object.id }}
                </div>
              </ng-template>
              <icon
                name="icon-copy"
                tooltip="{{ 'netops.firmware.copyGroups' | translate }}"
                (click)="copyGroups()"
                *ngIf="groupMembersSelector !== 'off'"
              ></icon>
              <icon
                name="icon-close"
                class="cancel"
                [class.disabled]="!(groupsSelected?.length > 0)"
                tooltip="{{ 'netops.firmware.clear' | translate }}"
                (click)="groupsSelected = []"
                *ngIf="groupMembersSelector !== 'off'"
              ></icon>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-2 key">{{ 'netops.firmware.versionMatrix' | translate }}:</div>
          <div class="col-xs-10">
            <div class="selectors">
              <slidetoggler [options]="versionItems" (toggle)="action('versionMatrix', $event)"></slidetoggler>
              <button
                class="action medium"
                (click)="openMatrixSelect('versionMatrix')"
                *ngIf="versionMatrixSelector != 'off'"
              >
                {{ 'netops.firmware.selectVersionMatrix' | translate }}
              </button>
            </div>
            <div class="details" *ngIf="versionMatrixSelector != 'off'">
              <div class="pill-hollow">
                <textarea
                  #versionMatrixInput
                  rows="1"
                  type="text"
                  [formControl]="selectedVersionMatrixes"
                  (keydown)="resize($event)"
                  placeholder="{{ 'netops.firmware.versionMatrices' | translate }}"
                ></textarea>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-2 key">{{ 'netops.firmware.models' | translate }}:</div>
          <div class="col-xs-10">
            <div class="selectors">
              <slidetoggler [options]="modelsItems" (toggle)="action('model', $event)"></slidetoggler>
              <drop-down-select
                class="medium modelsSelect"
                *ngIf="modelSelector != 'off'"
                [emptyText]="'netops.firmware.selectModel' | translate"
                [multiSelect]="true"
                multiSelectSeparator=", "
                [values]="modelValues"
                [(ownModel)]="modelsSelected"
                [tooltip]="modelsSelected?.join(', ')"
                [maxWidth]="99999"
              ></drop-down-select>
              <icon
                name="icon-copy"
                tooltip="{{ 'netops.firmware.copyModels' | translate }}"
                (click)="copyModels()"
                *ngIf="modelSelector !== 'off'"
              ></icon>
              <icon
                name="icon-close"
                class="cancel"
                [class.disabled]="!(modelsSelected?.length > 0)"
                tooltip="{{ 'netops.firmware.clear' | translate }}"
                (click)="modelsSelected = []"
                *ngIf="modelSelector !== 'off'"
              ></icon>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="col-xs-12" *ngIf="locationIDSelector != 'include-only'">
      <div class="row time">
        <div class="col-xs-2 key">{{ 'netops.firmware.shardID' | translate }}:</div>
        <div class="col-xs-4">
          <drop-down-select
            class="medium modelsSelect"
            [emptyText]="'netops.firmware.selectShardIDs' | translate"
            [values]="shardValues"
            [multiSelect]="false"
            [(ownModel)]="shardSelected"
          ></drop-down-select>
        </div>
      </div>

      <div class="row" *ngIf="!shardSelected">
        <div class="col-xs-2 key">{{ 'netops.firmware.maxRandomLocations' | translate }}:</div>
        <div class="col-xs-2 details no-margin">
          <div class="pill-hollow">
            <input [formControl]="randomLocationFormControl" type="number" min="1" placeholder="-" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12">
      <span class="title">{{ 'netops.firmware.upgradeParameters' | translate }}</span>
    </div>
  </div>
  <div class="row info">
    <div class="col-xs-12">
      <div class="row">
        <div class="col-xs-8">
          <div class="selectors">
            <div class="row time">
              <div class="col-xs-4">
                <button class="action medium" (click)="openMatrixSelect('targetVersion')">
                  {{ 'netops.firmware.selectVersionMatrix' | translate }}
                </button>
              </div>
              <div class="col-xs-2 label">{{ 'netops.firmware.startTime' | translate }}:</div>
              <div class="col-xs-2 details no-margin">
                <div class="pill-hollow">
                  <input [formControl]="startTimeFormControl" type="text" placeholder="00:00" />
                </div>
              </div>
              <div class="col-xs-2 label">{{ 'netops.firmware.endTime' | translate }}:</div>
              <div class="col-xs-2 details no-margin">
                <div class="pill-hollow">
                  <input [formControl]="endTimeFormControl" type="text" placeholder="23:59" />
                </div>
              </div>
            </div>
          </div>
          <div class="details">
            <div class="pill-hollow">
              <input
                #selectedTargetInput
                type="text"
                [formControl]="selectedTargetVersions"
                placeholder="{{ 'netops.firmware.targetVersion' | translate }}"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row info">
    <div class="row col-xs-12 center">
      <div class="col-xs-12">
        <div class="preview-buttons">
          <button id="preview" class="action" [class.disabled]="loadingLocations" (click)="preview()">
            {{ 'netops.firmware.preview' | translate }}
          </button>
          <button
            class="action"
            (click)="requestUpgrade()"
            [class.disabled]="loadingRequestUpgrade"
            *ngIf="locations.length"
          >
            {{ 'netops.firmware.requestUpgrade' | translate }}
          </button>
        </div>
      </div>
      <div class="col-xs-4" *ngIf="batchFirmwareUpgrade">
        <div>
          {{ 'netops.firmware.batchID' | translate }}: <b>{{ batchFirmwareUpgrade.id }}</b>
        </div>
        <div>
          {{ 'netops.firmware.timestamp' | translate }}: <b>{{ batchFirmwareUpgrade.timestamp }}</b>
        </div>
        <div>
          {{ 'netops.firmware.batchSize' | translate }}: <b>{{ batchFirmwareUpgrade.batchSize }}</b>
        </div>
      </div>
      <div class="col-xs-3" *ngIf="report">
        <button class="action" (click)="downloadCSV()">
          {{ 'netops.reports.downloadLocations' | translate }}
        </button>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="previewTriggered && !batchFirmwareUpgrade">
    <div class="col-xs-12">
      <div class="title row" *ngIf="locations.length && !loadingLocations">
        <div class="col-xs-8">{{ batchSize }} {{ 'netops.firmware.locationsFound' | translate }}</div>
        <div class="col-xs-4 right-align">
          <ng-container *ngIf="batchSize <= 20">
            {{ 'netops.firmware.showingAll' | translate }}
          </ng-container>
          <ng-container *ngIf="batchSize > 20">
            {{ 'netops.firmware.showingFirst' | translate }} {{ locations.length }}
            {{ 'netops.firmware.locationsFirst' | translate }}
          </ng-container>
        </div>
      </div>
      <div class="locations">
        <ng-container *ngIf="loadingLocations">
          <div class="loading-text">
            {{ 'netops.firmware.loading' | translate }}
          </div>
        </ng-container>
        <ng-container *ngIf="!loadingLocations">
          <ng-container *ngIf="locations.length">
            <locationBubble [locations]="locations"></locationBubble>
          </ng-container>

          <ng-container *ngIf="!locations.length">
            <div class="no-locations">
              {{ 'netops.firmware.noLocationsFound' | translate }}
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<modal mode="wide" class="version-select" (overlayAction)="showMatrixSelect = $event" *ngIf="showMatrixSelect">
  <ng-container title>{{ 'netops.firmware.lookupFirmware' | translate }}</ng-container>
  <ng-container content *ngIf="matrices.length === 0 && search.value?.length < 0">
    {{ 'configurations.firmware.noApplicableFirmware' | translate }}
  </ng-container>
  <ng-container content *ngIf="matrices.length > 0 || search.value?.length > 0">
    <div class="top">
      <div class="left">
        <slidetoggler [options]="downgradeItems" (toggle)="toggleDowngrade($event)"></slidetoggler>
      </div>
      <div class="right">
        <div class="actions">
          <button class="action medium" (click)="clear()">
            {{ 'configurations.firmware.clearTarget' | translate }}
          </button>
          <button class="action medium" (click)="disable()">
            {{ 'configurations.firmware.disableActiveMatrix' | translate }}
          </button>
        </div>
      </div>
    </div>
    <div class="sections">
      <div class="section">
        <div class="search title">
          <span>
            {{ 'configurations.firmware.searchVersions' | translate }}
          </span>
          <span>
            {{ 'configurations.firmware.includeModelVersion' | translate }}
            <div class="toggler" (click)="filterIncludeModelsToggle()">
              <div class="checkbox">
                <label class="toggle" [class.checked]="filterIncludeModels"></label>
              </div>
            </div>
          </span>
        </div>
        <div class="search">
          <input
            type="text"
            placeholder="{{ 'netops.firmware.versionsPlaceholder' | translate }}"
            (input)="filterSearch()"
            [formControl]="search"
          />
        </div>
        <div class="list">
          <div
            class="version"
            [class.selected]="selectedVersion && version.versionMatrix === selectedVersion.versionMatrix"
            *ngFor="let version of matrices"
            (mouseenter)="versionDetails = version"
            (mouseleave)="versionDetails = selectedVersion ? selectedVersion : null"
            (click)="versionDetails = version; selectedVersion = version"
          >
            {{ version.versionMatrix }}
          </div>
        </div>
      </div>
      <div class="section">
        <div class="list" *ngIf="versionDetails">
          <div class="row date">
            <div class="key">{{ 'netops.firmware.createdAt' | translate }}</div>
            <div class="value">{{ versionDetails.createdAt | date: 'LLL' }}</div>
          </div>

          <div class="row date" *ngIf="versionDetails.versionMatrixDescription">
            <div class="key">{{ 'netops.firmware.description' | translate }}</div>
            <div class="value">{{ versionDetails.versionMatrixDescription }}</div>
          </div>

          <div class="model" *ngFor="let model of versionDetails.models">
            <div class="row">
              <div class="key">{{ 'netops.firmware.model' | translate }}</div>
              <div class="value">{{ model.model }}</div>
            </div>
            <div class="row">
              <div class="key">{{ 'netops.firmware.version' | translate }}</div>
              <div class="value">{{ model.firmwareVersion }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container controls>
    <div class="desktop">
      <button class="tertiary light" (click)="showMatrixSelect = false; selectedVersion = null">
        {{ 'netops.firmware.close' | translate }}
      </button>
      <div class="update" *ngIf="selectedVersion">
        <slidetoggler [options]="asapItems" (toggle)="toggleAsap($event)"></slidetoggler>
        <button class="super-primary" (click)="selectVersionMatix()">
          {{ 'netops.firmware.select' | translate }}
        </button>
      </div>
    </div>
    <button class="super-primary mobile" (click)="selectVersionMatix()" *ngIf="selectedVersion">
      {{ 'netops.firmware.select' | translate }}
    </button>
  </ng-container>
</modal>
