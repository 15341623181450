import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as PubNub from 'pubnub';
import { optimizationChanged, powerManagementChanged, topologyChanged } from 'src/app/store/polling/polling.actions';
import { environment } from 'src/environments/environment';
import { CustomerService } from './customer.service';
import { LoggingService } from './logging.service';
import { PlumeService } from './plume.service';

@Injectable({ providedIn: 'root' })
export class PollingService {
  private pubnub: PubNub;
  private pubnubListener: PubNub.ListenerParameters;

  constructor(
    private plume: PlumeService,
    private log: LoggingService,
    private customer: CustomerService,
    private store: Store
  ) {}

  start(): void {
    this.log.log('Polling started for location: ', this.plume.locationid);
    this.log.log('Polling against version: ', this.plume.getEnv()?.version);

    if (environment.pubnub.enabled) {
      const userId = this.plume.getUser()?.sub ? this.plume.getUser().sub : this.plume.customerid;
      this.customer.pubnubSubscribe$(userId).subscribe((credentials) => {
        this.pubnub = new PubNub({
          authKey: credentials.authKey,
          subscribeKey: credentials.subscribeKey,
          ssl: true,
          userId
        });
        if (credentials?.token) {
          // TODO remove authKey once fully migrated to token
          this.pubnub.setToken(credentials?.token);
        }

        this.pubnubListener = {
          status: (status) => {
            if (status.operation === 'PNSubscribeOperation' && status.category === 'PNConnectedCategory') {
              this.log.log('PubNub connected to: ', this.plume.locationid);
            }
          },
          signal: (signalData) => {
            switch (signalData.channel) {
              case this.plume.locationid + '.topologyAlert':
                this.log.log('====================> PubNub topologyAlert signal: ');
                if (
                  this.plume.cloudVersionAbove1_130() &&
                  this.plume.permissions.getValue().uiFeatures.useTopologyAlert
                ) {
                  this.refreshTopology();
                }
                break;
              case this.plume.locationid + '.optimizationAlert':
                this.log.log('====================> PubNub SIGNAL optimization stream: ', signalData.message);
                this.store.dispatch(optimizationChanged({ optimization: signalData.message }));

                if (this.plume.permissions.getValue().uiFeatures.pausePollingWhileOptimizing) {
                  if (signalData.message.state === 'initiated' || signalData.message.state === 'inProgress') {
                    this.plume.pausedPolling = true;
                  }

                  if (signalData.message.state === 'optimized' || signalData.message.state === 'failed') {
                    this.plume.pausedPolling = false;
                  }
                }

                break;
              case this.plume.locationid + '.powerManagement':
                this.log.log('====================> PubNub SIGNAL powerManagement stream: ', signalData.message);
                this.store.dispatch(powerManagementChanged({ powerManagement: signalData.message }));
                break;
            }
          },
          message: (data) => {
            switch (data.channel) {
              case this.plume.locationid + '.topologyAdmin':
                this.log.log('====================> PubNub topologyAdmin stream: ');
                if (
                  !this.plume.cloudVersionAbove1_130() ||
                  !this.plume.permissions.getValue().uiFeatures.useTopologyAlert
                ) {
                  this.log.log('====================> PubNub topologyAdmin stream processed: ', data.message);
                  this.store.dispatch(topologyChanged({ topology: JSON.parse(JSON.stringify(data.message)) }));
                }
                break;
              case this.plume.locationid + '.optimizationAlert':
                this.log.log('====================> PubNub optimization stream: ', data.message);
                this.store.dispatch(optimizationChanged({ optimization: data.message }));
                break;
              case this.plume.locationid + '.powerManagement':
                this.log.log('====================> PubNub powerManagement stream: ', data.message);
                this.store.dispatch(powerManagementChanged({ powerManagement: data.message }));
                break;
              case this.plume.locationid + '.topologyAlert':
                this.log.log('====================> PubNub topologyAlert MESSAGE: ');
                if (
                  this.plume.cloudVersionAbove1_130() &&
                  this.plume.permissions.getValue().uiFeatures.useTopologyAlert
                ) {
                  this.refreshTopology();
                }
                break;
            }
          }
        };

        this.pubnub.addListener(this.pubnubListener);

        this.pubnub.subscribe({
          channelGroups: [this.plume.locationid + '.noc']
        });
      });
    }
  }

  refreshTopology(): void {
    this.customer.topology$().subscribe((topo) => {
      this.log.log('====================> topologyAlert processed: ', topo);
      this.store.dispatch(topologyChanged({ topology: JSON.parse(JSON.stringify(topo)) }));
    });
  }

  stop(): void {
    if (this.pubnub) {
      this.pubnub.unsubscribeAll();
      this.pubnub.removeListener(this.pubnubListener);
      delete this.pubnub;
    }
    this.log.log('Polling stopped for location: ', this.plume.locationid);
  }
}
