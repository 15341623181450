<ng-content select="[label]"></ng-content>
<div class="inputRow">
  <ng-content></ng-content>
  <ng-container *ngIf="isPassword">
    <ng-container *ngIf="showedPassword">
      <icon class="size-1_5 pointer" name="eye" (click)="hidePassword()"></icon>
    </ng-container>
    <ng-container *ngIf="!showedPassword">
      <icon class="size-1_5 pointer" name="eye" (click)="showPassword()"></icon>
    </ng-container>
  </ng-container>
  <icon *ngIf="clearButton" class="pointer size-0_8" name="icon-close" (click)="clear()"></icon>
</div>
<ng-content select="[info]"></ng-content>
