import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'devicecategories',
  templateUrl: './devicecategories.component.html',
  styleUrls: ['./devicecategories.component.scss']
})
export class DeviceCategoriesComponent implements OnInit, OnChanges {
  @Input()
  reset: number = 0;

  @Input()
  allDevices: any[] = null;

  deviceCategories: any[] = [];
  items: any[] = [];
  loading: boolean = true;
  mode: boolean = false;

  constructor() {}

  ngOnInit(): void {
    this.initItems();
  }

  ngOnChanges(changes: any): void {
    if (changes.reset) {
      this.loading = true;
    }

    if (changes.allDevices) {
      this.prepare();
    }
  }

  prepare(): void {
    if (this.allDevices !== null) {
      const devices = this.mode
        ? this.allDevices.filter((device: any) => device.connectionState === 'connected')
        : this.allDevices;
      const deviceCategories = [
        {
          category: 'profiler.deviceCategories.gameConsoles',
          icon: 'assets/iconsV2/gameconsole.svg',
          count: 0
        },
        { category: 'profiler.deviceCategories.media', icon: 'assets/iconsV2/tv.svg', count: 0 },
        { category: 'profiler.deviceCategories.mobile', icon: 'assets/iconsV2/smartphone.svg', count: 0 },
        { category: 'profiler.deviceCategories.laptops', icon: 'assets/iconsV2/laptop.svg', count: 0 },
        { category: 'profiler.deviceCategories.iot', icon: 'assets/iconsV2/smartdevice.svg', count: 0 },
        { category: 'profiler.deviceCategories.speaker', icon: 'assets/iconsV2/speaker.svg', count: 0 },
        { category: 'profiler.deviceCategories.others', icon: 'assets/iconsV2/printer.svg', count: 0 },
        { category: 'profiler.deviceCategories.unknown', icon: 'assets/iconsV2/unknown.svg', count: 0 }
      ];

      devices.forEach((device: any) => {
        switch (device.category) {
          case 'Game Console':
            deviceCategories[0].count++;
            break;
          case 'Set Top Box':
          case 'TV':
            deviceCategories[1].count++;
            break;
          case 'Smart Phone':
          case 'Tablet':
            deviceCategories[2].count++;
            break;
          case 'Laptop':
          case 'PC':
            deviceCategories[3].count++;
            break;
          case 'Doorbell':
          case 'Light Bulb':
          case 'Camera':
          case 'Thermostat':
          case 'IoT Platform':
          case 'Plug':
            deviceCategories[4].count++;
            break;
          case 'Speaker':
          case 'Voice Assistant':
            deviceCategories[5].count++;
            break;
          case undefined:
            deviceCategories[7].count++;
            break;
          default:
            deviceCategories[6].count++;
            break;
        }
      });

      this.deviceCategories = deviceCategories;
      this.loading = false;
    }
  }

  initItems(): void {
    this.items = [
      {
        value: false,
        translation: 'profiler.deviceCategories.allDevices',
        selected: !this.mode
      },
      {
        value: true,
        translation: 'profiler.deviceCategories.onlineDevices',
        selected: this.mode
      }
    ];
  }

  toggle(mode: boolean): void {
    this.mode = mode;
    this.prepare();
  }
}
