<div class="grid">
  <div class="label">
    {{ 'locationAuthorization.numNodesAuthorized' | translate }}
  </div>
  <div class="value controls" *ngIf="plume.isSomeAdminRole()">
    <icon name="icon-edit" (click)="startEdit()" *ngIf="(edit$ | async) === false"></icon>
    <icon name="icon-check" class="confirm" (click)="confirmEdit()" *ngIf="(edit$ | async) === true"></icon>
    <icon name="icon-close" class="size-0_8 cancel" (click)="cancelEdit()" *ngIf="(edit$ | async) === true"></icon>
  </div>
</div>
<div class="grid" [class.unset]="(noPodsSet$ | async) && (edit$ | async) === false" *ngFor="let pod of pods">
  <div class="label">{{ pod.model }}:</div>
  <div class="value">
    <input
      [formControl]="pod.control"
      [class.edit]="(edit$ | async) === true"
      [readonly]="(edit$ | async) === false"
      type="text"
      placeholder="0"
    />
  </div>
</div>
<div class="divider"></div>
<div class="grid">
  <div class="label">{{ 'locationAuthorization.numPodsAuthorized' | translate }}:</div>
  <div class="value all">
    <input
      [formControl]="allPods"
      [class.edit]="(editAll$ | async) === true"
      [readonly]="(editAll$ | async) === false"
      type="text"
      placeholder="0"
    />
  </div>
  <div class="controls" *ngIf="plume.isSomeAdminRole()">
    <icon name="icon-edit" (click)="startEditAll()" *ngIf="(editAll$ | async) === false"></icon>
    <icon name="icon-check" class="confirm" (click)="confirmEditAll()" *ngIf="(editAll$ | async) === true"></icon>
    <icon
      name="icon-close"
      class="size-0_8 cancel"
      (click)="cancelEditAll()"
      *ngIf="(editAll$ | async) === true"
    ></icon>
  </div>
</div>
