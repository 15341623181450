import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { INode } from 'src/app/lib/interfaces/interface';
import { MacAddrPipe } from 'src/app/lib/pipes/mac-addr.pipe';

@Component({
  selector: 'hardware-info-modal',
  templateUrl: './hardware-info-modal.component.html',
  styleUrls: ['./hardware-info-modal.component.scss']
})
export class HardwareInfoModalComponent implements OnInit {
  @Input()
  node: INode = null;

  @Output()
  closeHardwareInfoModal = new EventEmitter<void>();

  hardwareInfo = null;
  capabilities = null;

  constructor(private macAddr: MacAddrPipe) {}

  ngOnInit(): void {
    this.prepareHardwareInfo();
    this.prepareCapabilities();
  }

  prepareHardwareInfo(): void {
    this.hardwareInfo = [
      { key: 'nodes.node.hardwareInfoDialog.id', value: this.node.id },
      { key: 'nodes.node.hardwareInfoDialog.model', value: this.node.model },
      { key: 'nodes.node.hardwareInfoDialog.ethernet0Mac', value: this.macAddr.transform(this.node.mac) },
      { key: 'nodes.node.hardwareInfoDialog.ethernet1Mac', value: this.macAddr.transform(this.node.ethernet1Mac) },
      { key: 'nodes.node.hardwareInfoDialog.radioMac24', value: this.macAddr.transform(this.node.radioMac24) },
      { key: 'nodes.node.hardwareInfoDialog.radioMac50', value: this.macAddr.transform(this.node.radioMac50) },
      { key: 'nodes.node.hardwareInfoDialog.radioMac50L', value: this.macAddr.transform(this.node.radioMac50L) },
      { key: 'nodes.node.hardwareInfoDialog.radioMac50U', value: this.macAddr.transform(this.node.radioMac50U) },
      { key: 'nodes.node.hardwareInfoDialog.radioMac60', value: this.macAddr.transform(this.node.radioMac60) },
      { key: 'nodes.node.hardwareInfoDialog.bluetoothMac', value: this.macAddr.transform(this.node.bluetoothMac) }
    ];
    if (this.node.openSyncVersion) {
      this.hardwareInfo.push({
        key: 'nodes.node.hardwareInfoDialog.openSyncVersion',
        value: this.node.openSyncVersion
      });
    }

    if (this.node.vendorName) {
      this.hardwareInfo.push({ key: 'nodes.node.hardwareInfoDialog.vendorName', value: this.node.vendorName });
    }
  }

  prepareCapabilities() {
    this.capabilities = [
      { key: 'nodes.node.capabilitiesDialog.lte', value: this.node.capabilities.lte },
      { key: 'nodes.node.capabilitiesDialog.disable_ports', value: this.node.capabilities.disablePorts },
      { key: 'nodes.node.capabilitiesDialog.captive_portal_v2', value: this.node.capabilities.captivePortalV2 },
      { key: 'nodes.node.capabilitiesDialog.led', value: this.node.capabilities.led },
      { key: 'nodes.node.capabilitiesDialog.bluetooth', value: this.node.capabilities.bluetooth },
      { key: 'nodes.node.capabilitiesDialog.bluetooth_proximity', value: this.node.capabilities.bluetoothProximity }
    ];
  }

  closeModal() {
    this.closeHardwareInfoModal.emit();
  }
}
