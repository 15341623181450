<div class="volt-banner">
  <span *ngIf="powerState?.status === 'PM_STATUS_ACTIVE'">
    <icon name="icon-leaf" class="leaf-icon"></icon>
    {{ 'customer.volt.activated' | translate }}
  </span>
  <span *ngIf="powerState?.status === 'PM_STATUS_ACTIVATING'">
    <icon name="icon-leaf" class="leaf-icon"></icon>
    <icon name="icon-loading" class="load-icon"></icon>
    {{ 'customer.volt.activating' | translate }}
  </span>
  <span *ngIf="powerState?.status === 'PM_STATUS_DEACTIVATING'">
    <icon name="icon-loading" class="load-icon"></icon>
    {{ 'customer.volt.deactivating' | translate }}
  </span>
</div>
