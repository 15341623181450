import { forceSimulation, forceLink, forceManyBody, Simulation, forceX, forceY } from 'd3';
import { Node } from '../objects/node';
import { Link } from '../objects/link';

export class ForceChart {
  public simulation: Simulation<Node, Link>;

  constructor() {
    this.simulation = forceSimulation();
    this.simulation.force('charge', forceManyBody().strength(-2000));
    this.simulation.force('x', forceX().strength(0.1));
    this.simulation.force('y', forceY().strength(0.1));
  }

  update(nodes: Node[], links: Link[], ui: string = 'central', width: number = null, height: number = null): void {
    this.simulation.nodes(
      nodes.map((node: Node) => {
        if (node.id === 'vpnInternet') {
          node.fx = (width * 3) / 4;
          node.fy = (height / 6) * -1.8;
        }

        if (node.id === 'vpnSase') {
          node.fx = (width * 3) / 4;
          node.fy = (height / 6) * -0.6;
        }

        if (node.id === 'vpnDatacenter') {
          node.fx = (width * 3) / 4;
          node.fy = (height / 6) * 0.6;
        }

        if (node.id === 'vpnAuthentication') {
          node.fx = (width * 3) / 4;
          node.fy = (height / 6) * 1.8;
        }

        return node;
      })
    );
    this.simulation.force(
      'link',
      forceLink(links)
        .id((node: Node) => node.id)
        .distance((link: Link) => {
          let devices = 0;
          let length = ui === 'central' ? 120 : 150;

          if (link.source.options.type === 'pod') {
            devices = link.source.options.metadata.connectedDeviceCount;

            if (ui === 'central') {
              length = devices > 8 ? length + devices * 7 : length;
            } else {
              length = devices > 8 ? length + devices * 5 : length;
            }
          }

          if (link.target.options.type === 'pod') {
            length = length + 70;
          }

          return length;
        })
    );
    this.simulation.alpha(1).restart();
  }
}
