<modal mode="wide" (overlayAction)="showNeighborsReport = $event" *ngIf="showNeighborsReport">
  <ng-container title>
    {{ 'neighborsReport.title' | translate }}
    <div class="subtitle" *ngIf="hasTimestamp">
      {{ 'neighborsReport.lastReportDate' | translate: { timestamp: timestamp } }}
    </div>
  </ng-container>

  <ng-container content *ngIf="loadingNeighbors">{{ 'neighborsReport.loading' | translate }}</ng-container>
  <ng-container content *ngIf="!loadingNeighbors && !neighborsReport.length">
    {{ 'neighborsReport.noData' | translate }}
  </ng-container>
  <ng-container content *ngIf="!loadingNeighbors && neighborsReport.length">
    <neighborBubble [neighbors]="neighborsReport"></neighborBubble>
  </ng-container>
  <ng-container controls>
    <button class="tertiary light" (click)="showNeighborsReport = false">
      {{ 'neighborsReport.close' | translate }}
    </button>
  </ng-container>
</modal>
