<header></header>
<div class="container-fluid">
  <div class="row center-xs">
    <div class="col-xs-12 col-sm-8 col-md-6 col-lg-4">
      <div class="container-fluid" (keyup.enter)="reset()">
        <h1>{{ 'login.resetPassword' | translate }}</h1>
        <p>{{ 'login.resetPasswordInfo' | translate }}</p>
        <input
          [class.error]="error.show"
          [formControl]="email"
          type="email"
          placeholder="{{ 'login.emailPlaceholder' | translate }}"
          autofocus
        />
        <button class="login-button" (click)="reset()">
          <span *ngIf="!loading">{{ passwordButton | translate }}</span>
          <ng-container *ngIf="loading"
            >&nbsp;<icon name="icon-spinner" class="size-1_5 spinner loading"></icon
          ></ng-container>
        </button>
        <a routerLink="/login">{{ 'login.back' | translate }}</a>
        <div class="error" [class.show]="error.show">{{ error.message | translate }}</div>
      </div>
    </div>
  </div>
</div>
