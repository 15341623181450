<div class="calendar-container">
  <input
    type="text"
    [value]="calendar.value"
    placeholder="calendar.value"
    (click)="toggle(open ? 'close' : 'open')"
    readonly
  />
  <div #popup class="calendar" [class.top]="positionTop" [class.visible]="visible" *ngIf="open">
    <div class="header">
      <div class="previous" (click)="changeMonth('previous')">&lt;</div>
      <div class="month">{{ calendar.month }} {{ calendar.year }}</div>
      <div class="next" (click)="changeMonth('next')">&gt;</div>
    </div>
    <div class="content">
      <div class="headers">
        <div class="header" *ngFor="let dayName of calendar.headers">{{ dayName }}</div>
      </div>
      <div class="days" *ngFor="let days of calendar.days">
        <div
          class="calendar-day"
          [class.thisMonth]="day.isThisMonth"
          [class.selected]="day.isCurrent"
          [class.disabled]="day.isDisabled"
          (click)="selectDate(day)"
          *ngFor="let day of days"
        >
          {{ day.number }}
        </div>
      </div>
    </div>
    <div class="footer">
      <input #hours type="text" [value]="calendar.hours" maxlength="2" />
      :
      <input #minutes type="text" [value]="calendar.minutes" maxlength="2" />
      <button class="action medium" (click)="selectDateTime()">{{ 'calendar.select' | translate }}</button>
    </div>
  </div>
</div>
