<box>
  <div header>
    {{ 'profiler.summary.title' | translate }}
  </div>
  <div container>
    <ng-container *ngIf="!loading">
      <div class="description">{{ 'profiler.summary.description' | translate }}</div>
      <div class="content">
        <div class="column">
          <div class="item">
            <icon name="icon-streaming"></icon>
            <div class="label">
              {{ streamingDevices }} &nbsp; <span>{{ 'profiler.summary.streamingDevices' | translate }}</span>
            </div>
          </div>
          <div class="item">
            <icon name="icon-gaming-device"></icon>
            <div class="label">
              {{ gameConsoles }} &nbsp; <span>{{ 'profiler.summary.gameConsoles' | translate }}</span>
            </div>
          </div>
          <div class="item">
            <icon name="icon-people-profile"></icon>
            <div class="label">
              {{ peopleProfiles }} &nbsp; <span>{{ 'profiler.summary.peopleProfiles' | translate }}</span>
            </div>
          </div>
          <div class="item">
            <icon name="icon-more-networks"></icon>
            <div class="label">
              {{ otherNetworks }} &nbsp; <span>{{ 'profiler.summary.otherNetworks' | translate }}</span>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="item" *ngIf="dataConsumption">
            <icon name="icon-internet-usage"></icon>
            <div class="label">
              {{ 'profiler.summary.' + dataConsumption | translate }}
              <span>{{ 'profiler.summary.internetUser' | translate }}</span>
            </div>
          </div>
          <div class="item" [class.disabled]="!multiplePeople">
            <icon name="icon-multiple-person"></icon>
            <div class="label">
              <span>{{ 'profiler.summary.possibleMultiplePeople' | translate }}</span>
            </div>
          </div>
          <div class="item" [class.disabled]="!possibleGuests">
            <icon name="icon-guest-access"></icon>
            <div class="label">
              <span>{{ 'profiler.summary.possibleGuestAccess' | translate }}</span>
            </div>
          </div>
          <div class="item" [class.disabled]="!updownstream">
            <icon name="icon-up-down-stream"></icon>
            <div class="label">
              <span>{{ 'profiler.summary.upDownStream' | translate }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="recommendations" *ngIf="recommendations.length">
        <div class="title">{{ 'profiler.summary.recommendations.title' | translate }}</div>
        <div
          class="recommendation"
          [class.new]="recommendation.state === 'new'"
          *ngFor="let recommendation of recommendations"
        >
          <div class="message">
            {{ recommendation.message | translate }}
            <icon name="icon-info" (click)="showModal(recommendation)"></icon>
          </div>
          <div class="actions" *ngIf="recommendation.state === 'new'">
            {{ 'profiler.summary.recommendations.thisIsUseful' | translate }}
            <div class="action" (click)="voteRecommendation('like', recommendation)">
              <icon name="icon-thumbsup"></icon>
            </div>
            <div class="action" (click)="voteRecommendation('dislike', recommendation)">
              <icon name="icon-thumbsup" class="rotate180"></icon>
            </div>
          </div>
          <div class="actions" *ngIf="recommendation.state === 'like'">
            {{ 'profiler.summary.recommendations.recommended' | translate }}
            <div class="action like">
              <icon name="icon-check" class="confirm"></icon>
            </div>
          </div>
          <div class="actions" *ngIf="recommendation.state === 'dislike'">
            {{ 'profiler.summary.recommendations.notUseful' | translate }}
            <div class="action dislike">
              <icon name="icon-close" class="size-0_8 cancel"></icon>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="loading" *ngIf="loading">{{ 'loadingIndicator' | translate }}</div>
  </div>
</box>
