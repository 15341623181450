import { Component, OnInit, OnDestroy, Input, ElementRef, Output, EventEmitter } from '@angular/core';
import { ModalService } from 'src/app/lib/services/modal.service';

@Component({
  selector: 'modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit, OnDestroy {
  @Input() mode: 'normal' | 'compact' | 'wide' = 'normal';
  @Input() class = '';
  @Input() closeOnClickOutside = true;
  @Output() overlayAction: EventEmitter<boolean> = new EventEmitter<boolean>();

  zIndex: number;

  constructor(private service: ModalService, private element: ElementRef) { }

  ngOnInit(): void {
    document.body.appendChild(this.element.nativeElement);

    this.service.add(this);

    this.zIndex = this.service.modals.length * 10 + 70000;
  }

  close(trigger: string = ''): void {
    if (trigger === 'overlay' && !this.closeOnClickOutside) {
      return;
    }

    this.overlayAction.emit(false);
  }

  ngOnDestroy(): void {
    this.service.remove(this);
    this.element.nativeElement.remove();
  }
}
