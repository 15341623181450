<div class="container-fluid">
  <div class="header">{{ 'flex.history.dataHistory' | translate }}</div>
  <div class="columns-2">
    <div class="box" *ngFor="let type of ['ipsec', 'radius']">
      <div class="title">
        {{ 'flex.history.' + type + '.uptimeTitle' | translate }}
        <icon name="icon-info" [tooltip]="'flex.history.' + type + '.uptimeTitleDescription' | translate"></icon>
      </div>
      <ng-container>
        <div class="percentage" [style.visibility]="(uptime$ | async) === null ? 'hidden' : 'visible'">
          {{ (uptime$ | async)?.data[type] || 0 | percent }}
        </div>
        <div class="footer" [style.visibility]="(uptime$ | async) === null ? 'hidden' : 'visible'">
          {{ 'flex.history.lastUpdate' | translate: { time: (uptime$ | async)?.refreshTime } }}
        </div>
        <div class="loading" *ngIf="(uptime$ | async) === null">{{ 'loadingIndicator' | translate }}</div>
      </ng-container>
    </div>
  </div>
  <div class="box" *ngFor="let keyvalue of sources | keyvalue">
    <div class="title">{{ 'flex.history.' + keyvalue.key + '.title' | translate }}</div>
    <div class="selectContainer">
      <drop-down-select
        [values]="selectTimeValues"
        [ownModel]="sources[keyvalue.key].selectedTime"
        [multiSelect]="false"
        (ownModelChange)="sources[keyvalue.key].selectedTime !== $event && setTimePeriod($any(keyvalue).key, $event)"
      >
      </drop-down-select>
    </div>
    <div class="graphContainer">
      <flm-graph
        [dataSet]="sources[keyvalue.key].graphDs$ | async | cache: !!sources[keyvalue.key].reloadSilent"
        [borders]="{ top: 24, right: 32, bottom: 47, left: 45 }"
        [contrast]="true"
      ></flm-graph>
      <div
        class="loading"
        *ngIf="(sources[keyvalue.key].graphDs$ | async | cache: !!sources[keyvalue.key].reloadSilent) === null"
      >
        {{ 'loadingIndicator' | translate }}
      </div>
    </div>
    <div class="legend">
      <div class="group allInactive">
        <div class="circle"></div>
        <div class="text">{{ 'flex.history.' + keyvalue.key + '.all_inactive' | translate }}</div>
      </div>
      <div class="group secondaryActive">
        <div class="circle"></div>
        <div class="text">{{ 'flex.history.' + keyvalue.key + '.secondary_active' | translate }}</div>
      </div>
      <div class="group primaryActive">
        <div class="circle"></div>
        <div class="text">{{ 'flex.history.' + keyvalue.key + '.primary_active' | translate }}</div>
      </div>
    </div>
  </div>
</div>
