<slidetoggler
  #slidetoggler
  [class.visible]="mode === 'slidetoggler'"
  [options]="options"
  [editable]="editable"
  [confirmChange]="confirmChange"
  (toggle)="toggle.emit($event)"
></slidetoggler>

<drop-down [disabled]="!editable" [headerTemplate]="headerTemplate" [class.visible]="mode === 'dropdown'">
  <ng-container dropdown>
    <li *ngFor="let option of options" (click)="select(option)" [class.unselectable]="option.unselectable">
      <icon [name]="option.icon" class="custom" [ngStyle]="{ fill: option.iconColor }" *ngIf="option.icon"></icon>
      <span class="text">{{ option.translation | translate }}</span>
      <icon name="icon-check" class="marked" *ngIf="option.marked"></icon>
    </li>
  </ng-container>
</drop-down>

<ng-template #headerTemplate let-opened>
  <button class="header" role="listbox" [class.disabled]="!editable">
    <icon
      [name]="selectedOption?.icon"
      class="custom"
      [ngStyle]="{ fill: selectedOption?.iconColor }"
      *ngIf="selectedOption?.icon"
    ></icon>
    <span class="text">
      {{ selectedOption?.translation | translate }}
      <span *ngIf="markedOption?.value && selectedOption?.value !== markedOption?.value">
        ({{ markedOption?.translation | translate }})
      </span>
    </span>
    <icon name="icon-arrow-down" [class.rotate180]="opened" class="size-0_8"></icon>
  </button>
</ng-template>
