import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { TranslateService } from '@ngx-translate/core';
import { CustomerService } from 'src/app/lib/services/customer.service';
import { IIpTv } from 'src/app/lib/interfaces/interface';

@Component({
  selector: 'iptv',
  templateUrl: './iptv.component.html',
  styleUrls: ['./iptv.component.scss']
})
export class IptvComponent implements OnInit, OnChanges {
  expand: boolean = false;
  iptvService: IIpTv = null;

  @Input()
  open: number = 0;

  @Output()
  toggle = new EventEmitter<boolean>();

  @Output()
  filter = new EventEmitter<{ section: 'iptv'; property: string; translation: string }>();

  @Output()
  clearFilter = new EventEmitter<{ section: string }>();

  constructor(
    private mixpanel: MixpanelService,
    private translate: TranslateService,
    private customerService: CustomerService
  ) {}

  ngOnInit(): void {
    this.getIPTV();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.expand = changes.open.currentValue;
  }

  getIPTV(): void {
    this.customerService.getIpTv$().subscribe((response) => {
      this.iptvService = response && response.vlanServices && response.vlanServices[0];

      if (this.iptvService) {
        this.registerFilter();
      }
    });
  }

  registerFilter(): void {
    this.clearFilter.emit({ section: 'iptv' });

    this.translate
      .get('configurations.iptv.enable')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'iptv', property: 'enable', translation: translated })
      );

    this.translate
      .get('configurations.iptv.serviceNetworkMode')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'iptv', property: 'serviceNetworkMode', translation: translated })
      );
  }

  toggleExpand(): void {
    this.toggle.emit(!this.expand);

    if (!this.expand) {
      this.mixpanel.storeEvent('CONFIGURATION_IPTV');
    }
  }
}
