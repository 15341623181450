import { Directive, Input, ElementRef, OnInit, Output, EventEmitter } from '@angular/core';
import { select, drag, event, Simulation } from 'd3';
import { Node } from '../models/objects/node';
import { Link } from '../models/objects/link';

@Directive({
  selector: '[draggable]'
})
export class DraggableDirective implements OnInit {
  @Input()
  nodeVisual: Node;

  @Input()
  simulation: Simulation<Node, Link>;

  @Input()
  barebone: boolean;

  @Output()
  dragInProgress = new EventEmitter<boolean>();

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    if (!this.barebone && this.nodeVisual.options.id.indexOf('vpn') < 0) {
      select(this.el.nativeElement).call(
        drag()
          .on('start', () => this.dragstarted(this.nodeVisual))
          .on('drag', () => this.dragged(this.nodeVisual))
          .on('end', () => this.dragend(this.nodeVisual))
      );
    }
  }

  dragstarted(node: any): void {
    if (!event.active) {
      this.simulation.alpha(1).restart();
    }

    node.fx = node.x;
    node.fy = node.y;
    this.dragInProgress.emit(true);
  }

  dragged(node: any): void {
    if (node.fx !== event.x || node.fy !== event.y) {
      node.fx = event.x;
      node.fy = event.y;
      this.simulation.alpha(0.1).restart();
    }
  }

  dragend(node: any): void {
    if (!event.active) {
      this.simulation.alpha(1).restart();
    }

    node.fx = null;
    node.fy = null;
    this.dragInProgress.emit(false);
  }
}
