<div class="bubble arrow yes">
  <div class="message">{{ 'health.bubbles.informBubble.pleaseInformCustomer' | translate }}:</div>
  <div *ngIf="nocheck || connectionsOK" class="connectedMsg padBottom">
    <span *ngIf="hasextenders"
      ><p>{{ 'health.bubbles.informBubble.message1' | translate }}</p></span
    >
    <span *ngIf="!hasextenders">
      <p>{{ 'health.bubbles.informBubble.message4' | translate }}</p>
      <p>{{ 'health.bubbles.informBubble.message5' | translate }}</p>
    </span>
  </div>

  <div *ngIf="!nocheck && !connectionsOK && extender" class="connectedMsg padBottom">
    <p>{{ 'health.bubbles.informBubble.message2' | translate: { extenderHealthStatus: extender.health.status } }}</p>
  </div>

  <div class="data">
    <div class="col-xs-6"><extenderconnections></extenderconnections></div>
    <div class="col-xs-6" *ngIf="extender && nodes">
      <div class="align-middle row network">
        <icon [path]="extender.kind.icon" class="size-2"></icon>
        <span class="title align-middle">{{ extender.nickname || extender.defaultName }}</span>
      </div>
      <leaftoroot [device]="extender" [nodes]="nodes" [alarm]="alarm"></leaftoroot>
    </div>
  </div>

  <div class="infoMessage padTop" *ngIf="hasextenders">
    {{ 'health.bubbles.informBubble.waitForExtenderReposition' | translate }}
  </div>
  <div class="infoMessage padTop" *ngIf="!hasextenders">
    {{ 'health.bubbles.informBubble.waitForGatewayReposition' | translate }}
  </div>

  <div class="data padTop">
    <div class="col-xs-6">
      <div class="infoButtonTopper" *ngIf="hasextenders">
        {{ 'health.bubbles.informBubble.finishedRespositioningExtenders' | translate }}
      </div>
      <div class="infoButtonTopper" *ngIf="!hasextenders">
        {{ 'health.bubbles.informBubble.finishedRespositioningGateway' | translate }}
      </div>
      <div class="infoButtonTopper">{{ 'health.bubbles.informBubble.waitForOptimization' | translate }}</div>
    </div>
    <div class="col-xs-6">
      <div class="infoButtonTopper">{{ 'health.bubbles.informBubble.notEnoughExtenders' | translate }}</div>
      <div class="infoButtonTopper">{{ 'health.bubbles.informBubble.orderMore' | translate }}</div>
    </div>
  </div>

  <div class="infoOptMessage padTop">
    <span class="infoOptState">{{ 'health.bubbles.informBubble.optimizationState' | translate }}: &nbsp;</span>
    <span class="infoOptValue">
      {{ 'health.bubbles.informBubble.optimizationStates.' + optimizeState | translate }}
    </span>
  </div>

  <div class="row">
    <div class="col-xs-6 padTop">
      <div
        class="tsButton"
        [class.enabled]="optimizeState !== 'running' && (enableOptimizationButton$ | async) === true"
        (click)="triggerOptimize()"
      >
        {{ 'health.bubbles.informBubble.finishedRespositioning' | translate }}
      </div>
    </div>

    <div class="col-xs-6 padTop">
      <div class="tsButton enabled" (click)="finish()">
        {{ 'health.bubbles.informBubble.finish' | translate }}
      </div>
    </div>
  </div>

  <div *ngIf="connectionsOK" class="infoBottomMessage padTop">
    {{ 'health.bubbles.informBubble.message3' | translate }}
  </div>

  <div *ngIf="!nocheck && connectionsOK" class="infoLogMessage infoLogSpacer">
    <span class="log">
      <div>{{ 'health.bubbles.informBubble.referenceLogRecord' | translate }}:</div>
      <div class="link">
        <input readonly type="text" [value]="recordlog | translate" />
        <icon
          name="icon-copy"
          tooltip="{{ 'health.bubbles.informBubble.copy' | translate }}"
          (click)="copy(recordlog)"
        ></icon>
      </div>
    </span>
  </div>

  <div class="progressbar progressbar--bottom" [class.hidden]="optimizeState !== 'running'">
    <div class="indeterminate"></div>
  </div>
</div>
