import { Component, AfterContentInit, ContentChildren, QueryList, Input, Output, EventEmitter } from '@angular/core';
import { TabComponent } from './tab/tab.component';

@Component({
  selector: 'tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements AfterContentInit {
  @Input()
  name: string;

  @Output()
  tabChange: any = new EventEmitter<string>();

  @ContentChildren(TabComponent)
  tabs: QueryList<TabComponent>;

  ngAfterContentInit(): void {
    const previousTab = localStorage.getItem(this.name) || null;

    if (previousTab) {
      const tabExist = this.tabs.filter((tab: TabComponent) => tab.id === previousTab) || null;

      setTimeout(() => this.activateTab(tabExist.length ? previousTab : this.tabs.first.id, false), 10);
    } else {
      setTimeout(() => this.activateTab(this.tabs.first.id, false), 10);
    }
  }

  activateTab(id: string, emit: boolean = true): void {
    this.tabs.forEach((item: TabComponent) => {
      item.active = item.id === id ? true : false;
    });

    if (emit) {
      this.tabChange.emit(id);
    }

    localStorage.setItem(this.name, id);
  }
}
