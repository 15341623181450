import { Component, OnInit, OnDestroy, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { TroubleshootingService } from 'src/app/lib/services/troubleshooting.service';
import { LoggingService } from 'src/app/lib/services/logging.service';
import { MessageService } from 'src/app/lib/services/message.service';
import { ToastService } from 'src/app/lib/services/toast.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';

@Component({
  selector: 'stabilityalarm',
  templateUrl: './stabilityalarm.component.html',
  styleUrls: ['./stabilityalarm.component.scss']
})
export class StabilityalarmComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  alarm: any = {};

  @Output() reload = new EventEmitter<void>();

  expand: boolean = false;
  troubleshoot: boolean = false;
  nodes: any = [];
  optimization: boolean = false;
  step1: boolean = true;
  step2: boolean = false;
  step3: boolean = false;
  messageSubscription: any;

  constructor(
    private troubleshootService: TroubleshootingService,
    private toastComponent: ToastService,
    private logging: LoggingService,
    private message: MessageService,
    private mixpanel: MixpanelService
  ) {}

  ngOnInit(): void {
    this.messageSubscription = this.message.getMessage().subscribe((response: any) => {
      switch (response.source) {
        case 'reboot': {
          this.logging.log('Reboot bubble complete: ', response);

          this.step3 = true;
          this.optimization = response.success;

          this.troubleshootService.initPullLog();
          break;
        }
        case 'log': {
          if (response.message === 'confirm' && response.msgId === this.alarm.name && !this.step2) {
            this.step2 = true;
          }

          if (response.message === 'finish' && response.msgId === this.alarm.name) {
            this.finish();
          }
          break;
        }
      }
    });
  }

  startTroubleshooting(): void {
    this.troubleshoot = true;
    this.expand = true;

    let alarm = 'Stability Alarm';

    if (this.alarm.name === 'health.networkStatus.stabilityAlarm.highOptimizationsAlarmTitle') {
      alarm = 'High Optimizations';
    }

    if (this.alarm.name === 'health.networkStatus.stabilityAlarm.failedOptimizationsAlarmTitle') {
      alarm = 'Failed Optimizations';
    }

    this.mixpanel.storeEvent('HEALTH_DIAGNOSTIC', {
      ACTION: 'Start Troubleshooting',
      ALARM: alarm
    });
  }

  ngOnChanges(changes: any): void {
    this.logging.log(changes);
  }

  copy(value: string): void {
    try {
      window.navigator.clipboard.writeText(value);
      this.toastComponent.success('toast.stabilityAlarm.successMessage', 'toast.stabilityAlarm.successTitle');
    } catch (error) {}
  }

  finish(): void {
    this.troubleshoot = false;
    this.expand = false;
    this.step1 = true;
    this.step2 = false;
    this.step3 = false;
    this.reload.emit();
  }

  ngOnDestroy(): void {
    if (this.messageSubscription) {
      this.messageSubscription.unsubscribe();
    }
  }
}
