<div class="content" *ngIf="loading">{{ 'qoe.loading' | translate }}</div>
<div class="content wan" *ngIf="!loading && wan">
  <div class="header" *ngIf="period !== 10">
    <div class="title">{{ 'qoe.WANData' | translate }}</div>
    <slidetoggler [options]="chartModes" (toggle)="toggleChartMode($event)"></slidetoggler>
  </div>
  <div class="header" *ngIf="period === 10">
    <div class="title">{{ 'qoe.WANLiveData' | translate }}</div>
  </div>
  <ng-container *ngIf="!wan.length">
    <div class="no-data">{{ 'qoe.noQoeData' | translate }}</div>
  </ng-container>
  <ng-container *ngIf="wan.length">
    <div class="graph" *ngFor="let chart of wan; trackBy: track">
      <div class="caption" (click)="hideGraph(chart.data.class)">
        {{ chart?.data?.series?.translation | translate }}
        <icon name="icon-triangle-down" class="expander" [class.rotate180]="isShowChart(chart.data.class)"></icon>
      </div>
      <div class="chart-wrap" *ngIf="isShowChart(chart.data.class)">
        <nodechart
          [series]="[]"
          [data]="[chart.data]"
          [scale]="{ range: [0, chart.maxValue], symbol: '' }"
          [period]="chart.period"
          [isFilled]="false"
        ></nodechart>
      </div>
    </div>
  </ng-container>
</div>
