import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IconService } from 'src/app/lib/services/icon.service';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { GeneralHelper } from 'src/app/lib/helpers/general.helper';

@Component({
  selector: 'mostactivedevices',
  templateUrl: './mostactivedevices.component.html',
  styleUrls: ['./mostactivedevices.component.scss']
})
export class MostActiveDevicesComponent implements OnInit, OnChanges {
  @Input()
  reset: number = 0;

  @Input()
  allDevices: any[] = null;

  @Input()
  appFacade: any = null;

  mostActiveDevicesAll: any[] = [];
  mostActiveDevices: any[] = [];
  items: any[] = [];
  mode: string = 'download';
  loading: boolean = true;
  helper: GeneralHelper = new GeneralHelper();

  constructor(private plume: PlumeService, private icon: IconService, private router: Router) {}

  ngOnInit(): void {
    this.initItems();
  }

  ngOnChanges(changes: any): void {
    if (changes.reset) {
      this.allDevices = null;
      this.appFacade = null;
      this.loading = true;
    }

    if (changes.allDevices?.currentValue || changes.appFacade?.currentValue) {
      if (changes.allDevices?.currentValue) {
        this.allDevices = changes.allDevices.currentValue;
      }

      if (changes.appFacade?.currentValue) {
        this.appFacade = changes.appFacade.currentValue;
      }

      this.prepare();
    }
  }

  prepare(): void {
    if (this.allDevices !== null && this.appFacade !== null) {
      const mostActive = [];
      const average = this.appFacade.day.devices.totalConsumption;

      this.appFacade.day.devices.mostActive.forEach((activeDevice: any) => {
        const device = this.allDevices.find((device: any) => device.mac === activeDevice.mac);

        if (device) {
          mostActive.push({
            mac: activeDevice.mac,
            icon: this.icon.getPath(device.kind?.type?.iconV2 || device.iconV2 || device.icon || 'unknown'),
            name: device.nickname || device.name,
            download: this.helper.formatBytes(activeDevice.download, activeDevice.downloadUnits.toUpperCase()),
            upload: this.helper.formatBytes(activeDevice.upload, activeDevice.uploadUnits.toUpperCase()),
            downloadValue: activeDevice.download,
            uploadValue: activeDevice.upload,
            downloadPercent: (activeDevice.download * 100) / average.download,
            uploadPercent: (activeDevice.upload * 100) / average.upload,
            downloadPercentColor: this.getPercentColor((activeDevice.download * 100) / average.download),
            uploadPercentColor: this.getPercentColor((activeDevice.upload * 100) / average.upload)
          });
        }
      });

      this.mostActiveDevicesAll = mostActive;
      this.loading = false;
      this.sort();
    }
  }

  getPercentColor(percent: number): string {
    if (percent >= 40) {
      return 'percent100';
    }

    if (percent >= 30) {
      return 'percent80';
    }

    if (percent >= 20) {
      return 'percent60';
    }

    if (percent >= 10) {
      return 'percent40';
    }

    return 'percent20';
  }

  initItems(): void {
    this.items = [
      {
        value: 'download',
        translation: 'profiler.mostActiveDevices.download',
        selected: this.mode === 'download'
      },
      {
        value: 'upload',
        translation: 'profiler.mostActiveDevices.upload',
        selected: this.mode === 'upload'
      }
    ];
  }

  sort(): void {
    this.mostActiveDevices = this.mostActiveDevicesAll
      .filter((device: any) => device[this.mode + 'Value'] > 0)
      .sort((a: any, b: any) => (a[this.mode + 'Value'] < b[this.mode + 'Value'] ? 1 : -1))
      .slice(0, 6);

    this.loading = false;
  }

  toggle(mode: string): void {
    this.mode = mode;

    this.initItems();
    this.sort();
  }

  open(device: any): void {
    if (!this.plume.hidePersonalDetails()) {
      this.router.navigate([
        'customer',
        this.plume.customerid,
        'location',
        this.plume.locationid,
        'devices',
        device.mac
      ]);
    }
  }
}
