import { Component, Input, ElementRef, OnChanges, AfterViewInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { RecentEventsChart } from 'src/app/lib/d3/models/charts/recentevents.chart';
import { D3Service } from 'src/app/lib/d3/service';
import { Tick } from 'src/app/lib/d3/models/objects/tick';
import { Bar } from 'src/app/lib/d3/models/objects/bar';
import * as erdm from 'element-resize-detector';

@Component({
  selector: 'recenteventschart',
  templateUrl: './recenteventschart.component.html',
  styleUrls: ['./recenteventschart.component.scss']
})
export class RecentEventsChartVisualComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input()
  data: any;

  @Output()
  eventsChange: any = new EventEmitter<any>();

  tooltips: any[] = [];
  nodata: any = { show: false, x: 0, y: 0 };
  chart: RecentEventsChart;
  bars: Bar[];
  xAxis: Tick[];
  yAxis: Tick[];
  margins: any = {
    top: 50,
    right: 50,
    bottom: 50,
    left: 50
  };

  erd: any = erdm({ strategy: 'scroll' });

  constructor(private d3: D3Service, private host: ElementRef) {
    this.chart = this.d3.generate('recenteventschart');
  }

  ngAfterViewInit(): void {
    this.erd.listenTo(this.host.nativeElement, () => {
      this.render();
    });
  }

  ngOnChanges(): void {
    this.render();
  }

  render(): void {
    this.bars = this.chart.update(
      this.data,
      this.host.nativeElement.clientWidth,
      this.host.nativeElement.clientHeight,
      this.margins
    );

    this.xAxis = this.chart.xAxis();
    this.yAxis = this.chart.yAxis();

    this.nodata = {
      show: !this.data.maxCount,
      x: this.chart.calculateWidth() / 2,
      y: this.chart.calculateHeight() / 2
    };

    this.generateTooltips();
  }

  selectEvents(bar: any): void {
    this.eventsChange.emit({ timestamp: bar.timestamp, events: bar.group });
  }

  generateTooltips(): void {
    this.tooltips = this.bars.map((bar: any) => ({
        timestamp: bar.timestamp,
        width: bar.width,
        marginTop: this.margins.top,
        marginBottom: this.margins.bottom,
        left: this.margins.left + bar.x + bar.width / 2,
        top: this.chart.calculateHeight() + 5,
        bottom: this.chart.calculateHeight() + 5,
        time: bar.timestamp.toLocaleTimeString('default', { hour: '2-digit', minute: '2-digit' }),
        count: bar.group.length,
        show: false
      }));
  }

  showTooltip(bar: any): void {
    for (const tooltip of this.tooltips) {
      if (tooltip.timestamp === bar.timestamp) {
        tooltip.show = true;
      } else {
        tooltip.show = false;
      }
    }
  }

  hideTooltip(): void {
    for (const tooltip of this.tooltips) {
      tooltip.show = false;
    }
  }

  ngOnDestroy(): void {
    this.erd.uninstall(this.host.nativeElement);
  }
}
