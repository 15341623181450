import { Pipe, PipeTransform } from '@angular/core';
import { get } from 'lodash';

@Pipe({
  name: 'filterArrayBy'
})
export class FilterArrayByPipe implements PipeTransform {
  transform(value: any[], ...args: { filtertext: string; properties: string[] }[]): any[] {
    if (!value || !args || !args[0] || !args[0].filtertext || !args[0].properties || args[0].properties.length < 1) {
      return value;
    }

    return value.filter((item) => {
      for (const property of args[0].properties) {
        if (get(item, property)?.toLowerCase().indexOf(args[0].filtertext.toLowerCase()) >= 0) {
          return true;
        }
      }
      return false;
    });
  }
}
