import { Component } from '@angular/core';
import { GraphCalculationsService } from '../graph-calculations/graph-calculations.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[flm-graph-axis-x]',
  templateUrl: './graph-axis-x.component.html',
  styleUrls: ['./graph-axis-x.component.scss']
})
export class GraphAxisXComponent {
  constructor(public calculations: GraphCalculationsService) {}

  getLabelWidth(xAxis: any): number {
    const width = xAxis.endX - xAxis.startX;
    const length = xAxis.steps.length;

    return width > 0 && length > 0 ? width / length : 0;
  }
}
