<div class="row" id="conf-ipv6">
  <div class="col-xs-12">
    <collapsiblebox [show]="expand" [collapsible]="true" (toggle)="toggleExpand()">
      <div header>{{ 'configurations.ipv6.title' | translate }}</div>
      <div container *ngIf="expand">
        <div class="columns">
          <div class="column" *ngrxLet="location$ as location">
            <h3>{{ 'configurations.ipv6.lan' | translate }}</h3>
            <div class="indent">
              <div class="row">
                <div class="key">{{ 'configurations.ipv6.prefix' | translate }}</div>
                <div class="value">
                  {{ location?.ipv6?.prefix ? location.ipv6?.prefix : ('configurations.ipv6.notSet' | translate) }}
                </div>
              </div>
              <div class="row">
                <div class="key">{{ 'configurations.ipv6.mode' | translate }}</div>
                <div class="value">
                  <slider-toggle-mobile-aware
                    [options]="modeItems$ | async"
                    [editable]="plume.isSomeAdminRole()"
                    (toggle)="setMode($event)"
                  ></slider-toggle-mobile-aware>
                </div>
              </div>
              <div class="row">
                <div class="key">{{ 'configurations.ipv6.addressing' | translate }}</div>
                <div class="value">
                  <slider-toggle-mobile-aware
                    [options]="addressingItems$ | async"
                    [editable]="plume.isSomeAdminRole()"
                    (toggle)="setAddressing($event)"
                  ></slider-toggle-mobile-aware>
                </div>
              </div>
              <div class="row">
                <div class="key">{{ 'configurations.ipv6.ipv6dns' | translate }}</div>
                <div class="value">
                  <slider-toggle-mobile-aware
                    [options]="ipv6DnsEnableItems$ | async"
                    [editable]="plume.isSomeAdminRole()"
                    (toggle)="enableIpv6Dns($event, location)"
                  ></slider-toggle-mobile-aware>
                </div>
              </div>
              <div class="row">
                <div class="key">
                  {{ 'configurations.ipv6.primarydns' | translate }}
                </div>
                <div class="value" *ngIf="(capabilities$ | async)?.dnsServers?.capable">
                  <div class="output" [class.hide]="editPrimaryDns">
                    {{
                      location?.ipv6?.dns?.primary
                        ? location.ipv6.dns.primary
                        : ('configurations.ipv6.setDNS' | translate)
                    }}
                    <icon
                      name="icon-edit"
                      (click)="enablePrimaryDnsEdit(location)"
                      *ngIf="!editPrimaryDns && plume.isSomeAdminRole()"
                    ></icon>
                  </div>
                  <div class="editable" [class.hide]="!editPrimaryDns">
                    <input
                      (keyup.enter)="confirmPrimaryDnsEdit(location)"
                      (keyup.escape)="cancelPrimaryDnsEdit(location)"
                      [formControl]="primaryDns"
                      type="text"
                      placeholder="{{ 'configurations.ipv6.primarydns' | translate }}..."
                      autofocus
                    />
                    <div class="controls" *ngIf="editPrimaryDns">
                      <icon name="icon-check" class="confirm" (click)="confirmPrimaryDnsEdit(location)"></icon>
                      <icon name="icon-close" class="size-0_8 cancel" (click)="cancelPrimaryDnsEdit(location)"></icon>
                    </div>
                  </div>
                </div>
                <div class="value" *ngIf="!(capabilities$ | async)?.dnsServers?.capable">
                  {{ 'configurations.dns.notCapable' | translate }}
                </div>
              </div>
              <div class="row">
                <div class="key">
                  {{ 'configurations.ipv6.secondarydns' | translate }}
                </div>
                <div class="value" *ngIf="(capabilities$ | async)?.dnsServers?.capable">
                  <div class="output" [class.hide]="editSecondaryDns">
                    {{
                      location?.ipv6?.dns?.secondary
                        ? location.ipv6.dns.secondary
                        : ('configurations.ipv6.setDNS' | translate)
                    }}
                    <icon
                      name="icon-edit"
                      (click)="enableSecondaryDnsEdit(location)"
                      *ngIf="!editSecondaryDns && plume.isSomeAdminRole()"
                    ></icon>
                  </div>
                  <div class="editable" [class.hide]="!editSecondaryDns">
                    <input
                      (keyup.enter)="confirmSecondaryDnsEdit(location)"
                      (keyup.escape)="cancelSecondaryDnsEdit(location)"
                      [formControl]="secondaryDns"
                      type="text"
                      placeholder="{{ 'configurations.ipv6.secondarydns' | translate }}..."
                      autofocus
                    />
                    <div class="controls" *ngIf="editSecondaryDns">
                      <icon name="icon-check" class="confirm" (click)="confirmSecondaryDnsEdit(location)"></icon>
                      <icon name="icon-close" class="size-0_8 cancel" (click)="cancelSecondaryDnsEdit(location)"></icon>
                    </div>
                  </div>
                  <div class="input-underline" [class.show]="editSecondaryDns"></div>
                </div>
                <div class="value" *ngIf="!(capabilities$ | async)?.dnsServers?.capable">
                  {{ 'configurations.dns.notCapable' | translate }}
                </div>
              </div>
              <div class="row top">
                <div class="key">{{ 'configurations.ipv6.ipv6data' | translate }}</div>
                <div class="value">
                  <div class="data-wrap">
                    <div class="selector" [class.collapsed]="!dataExpand" (click)="dataExpand = !dataExpand">
                      {{ 'configurations.ipv6.object' | translate }}
                      <icon name="icon-arrow-down" class="size-0_8" [class.rotate180]="dataExpand"></icon>
                    </div>
                    <ng-container *ngIf="dataExpand">
                      <ngx-json-viewer [json]="location?.ipv6"></ngx-json-viewer>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
            <ng-container *ngIf="wanConfig">
              <h3>{{ 'configurations.ipv6.wan' | translate }}</h3>
              <div class="indent">
                <div class="row">
                  <div class="key">{{ 'configurations.ipv6.IPv4overIPv6' | translate }}</div>
                  <div class="value">
                    <slider-toggle-mobile-aware
                      [options]="IPv4overIPv6Items"
                      [editable]="plume.isSomeAdminRole()"
                      (toggle)="setIPv4overIPv6($event)"
                    ></slider-toggle-mobile-aware>
                    <span *ngIf="wanState?.enable">{{ 'configurations.ipv6.' + wanState.mapType | translate }}</span>
                  </div>
                </div>
                <div class="row top">
                  <div class="key">{{ 'configurations.ipv6.configData' | translate }}</div>
                  <div class="value">
                    <div class="data-wrap">
                      <div
                        class="selector"
                        [class.collapsed]="!configDataExpand"
                        (click)="configDataExpand = !configDataExpand"
                      >
                        {{ 'configurations.ipv6.object' | translate }}
                        <icon name="icon-arrow-down" class="size-0_8" [class.rotate180]="configDataExpand"></icon>
                      </div>
                      <ng-container *ngIf="configDataExpand">
                        <ngx-json-viewer [json]="wanConfig"></ngx-json-viewer>
                      </ng-container>
                    </div>
                  </div>
                </div>
                <div class="row top">
                  <div class="key">{{ 'configurations.ipv6.stateData' | translate }}</div>
                  <div class="value">
                    <div class="data-wrap">
                      <div
                        class="selector"
                        [class.collapsed]="!stateDataExpand"
                        (click)="stateDataExpand = !stateDataExpand"
                      >
                        {{ 'configurations.ipv6.object' | translate }}
                        <icon name="icon-arrow-down" class="size-0_8" [class.rotate180]="stateDataExpand"></icon>
                      </div>
                      <ng-container *ngIf="stateDataExpand">
                        <ngx-json-viewer [json]="wanState"></ngx-json-viewer>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </collapsiblebox>
  </div>
</div>
