<div class="row">
  <div class="col-xs-12">
    <div class="bubble">
      <p>{{ 'health.networkPerformance.ethernetUplinkAlarm.description' | translate }}</p>
      <div class="node" *ngFor="let node of alerts">
        <icon [path]="getIcon(node.model)"></icon>
        <div class="data">
          <div class="name">{{ node.name }}</div>
          <div class="id">ID: {{ node.id }}</div>
          <div class="port">{{ node.ifName }}: {{ node.linkSpeed }} Mbps {{ node.duplex }} uplink</div>
        </div>
      </div>
    </div>
  </div>
</div>
