<div class="row" [class.dim]="selected !== extender?.id && selected !== null">
  <div class="col-xs-12">
    <box class="wider" [class.selected]="expand">
      <div header (click)="expand = !expand">
        <span>
          <icon name="icon-arrow-down" [class.rotate180]="expand"></icon>
          {{ 'health.networkPerformance.extenderHealthAlarm.extenderHealthAlarm' | translate }}
        </span>
      </div>
      <div container>
        <div class="data">
          <div class="column">
            <icon [path]="extender?.kind?.icon"></icon>
            <div class="name">
              <span class="title">{{ extender?.kind?.name || extender?.defaultName }}</span>
              <span class="type">{{ extender?.kind?.category }}</span>
              <span *ngIf="!(extender && extender?.health && extender?.health?.details)">
                {{ 'health.networkPerformance.extenderHealthAlarm.poorHealth' | translate }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </box>
    <div class="wrapper show" *ngIf="expand">
      <informbubble
        [extender]="extender"
        [finishbutton]="true"
        alarm="health.networkPerformance.extenderHealthAlarm.poorHealth"
      ></informbubble>
    </div>
  </div>
</div>
