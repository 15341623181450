export const environments = [
  {
    id: 'beta',
    name: 'Beta',
    loopbackAuth: true,
    oktaAuth: true,
    dynamicIdps: false,
    url: 'https://piranha-beta.prod.us-west-2.aws.plumenet.io',
    lteUrl: 'https://piranha-beta.prod.us-west-2.aws.plumenet.io',
    flexUrl: 'https://piranha-beta.prod.us-west-2.aws.plumenet.io/flex',
    upriseUrl: 'https://eks-ext.usw2.prod.beta.plumenet.io/uprise',
    upriseApplicationUrl: 'https://beta.uprise.plume.com',
    statuspage: 'https://status-beta-prod.plumenet.io',
    mywifi: 'https://captiveportal.plume.me',
    signalUrl: 'https://signal-beta-ext.usw2.prod.datavinci.plumenet.io',
    idps: [],
    partners: {}
  },
  {
    id: 'chi-staging',
    name: 'Chi Staging',
    loopbackAuth: true,
    oktaAuth: false,
    dynamicIdps: true,
    url: 'https://piranha-chi.staging.us-west-2.plume.comcast.net',
    mywifi: 'https://captiveportal.plume.me',
    idps: [
      {
        id: 'comcastStaging',
        description: 'Comcast Cable Staging',
        domains: ['comcast.com', 'cable.comcast.com']
      }
    ]
  },
  {
    id: 'chi-dev',
    name: 'Chi Dev',
    loopbackAuth: true,
    oktaAuth: false,
    dynamicIdps: true,
    url: 'https://piranha-chi.dev.us-west-2.plume.comcast.net',
    mywifi: 'https://captiveportal.plume.me',
    idps: [
      {
        id: 'comcastDev',
        description: 'Comcast Cable Dev',
        domains: ['comcast.com', 'cable.comcast.com']
      }
    ]
  },
  {
    id: 'ci',
    name: 'CI',
    loopbackAuth: true,
    oktaAuth: true,
    oktaMode: 'vanity',
    dynamicIdps: true,
    url: 'https://piranha-ci.dev.us-west-2.aws.plume.tech',
    lteUrl: 'https://piranha-ci.dev.us-west-2.aws.plume.tech',
    flexUrl: 'https://piranha-ci.dev.us-west-2.aws.plume.tech/flex',
    upriseUrl: 'https://eks-ext.usw2.dev.ci.plume.tech/uprise',
    upriseApplicationUrl: 'https://ci.uprise.plume.com',
    metasaurusUrl: 'https://dwb-apptime-dev.plume.tech',
    mywifi: 'https://captiveportal-staging.plume.me',
    signalUrl: 'https://signal-ext.us-west-2.datavinci.dev.plume.tech',
    idps: [
      {
        id: 'vodafoneCav',
        description: 'Sigma-CAV',
        domains: ['vodafone.com']
      },
      {
        id: 'vodafone',
        description: 'Sigma-ES',
        domains: ['vodafone.com']
      },
      {
        id: 'okta',
        description: 'LGI UAT (Staging)',
        value: '0oakoytwm1nTIe99q0h7'
      },
      {
        id: 'okta',
        description: 'JCOM Test Bed',
        value: '0oamrnpc3sZAucc7b0h7'
      },
      {
        id: 'okta',
        description: 'NOS (TEST)',
        value: '0oas0d2of3H6zaw9d0h7'
      },
      {
        id: 'okta',
        description: 'auth0',
        value: '0oa11rkhhk3piCqUC0h8',
        mode: 'domain'
      },
      {
        id: 'okta',
        description: 'auth0 NEW',
        value: '0oa11rm2wmb0tzW250h8',
        mode: 'vanity'
      }
    ],
    partners: {
      vodafone: {
        idps: [0, 1],
        groups: {
          es: {
            idps: [0, 1]
          }
        }
      }
    }
  },
  {
    id: 'delta',
    name: 'Delta',
    defaultSearch: 'id',
    loopbackAuth: true,
    oktaAuth: true,
    dynamicIdps: false,
    url: 'https://piranha-delta.prod.ca-central-1.aws.plumenet.io',
    mywifi: 'https://ca.captiveportal.plume.me',
    statuspage: 'https://status-delta-prod.plumenet.io/',
    idps: [
      {
        id: 'okta',
        description: 'Bell Prod',
        value: '0oad1g0l6tf441eI8357',
        mode: 'vanity'
      }
    ]
  },
  {
    id: 'dogfood',
    name: 'Dogfood',
    pollingInterval: 60,
    loopbackAuth: true,
    oktaAuth: true,
    dynamicIdps: false,
    url: 'https://piranha-dog1.dogfood.us-west-2.aws.plume.tech',
    lteUrl: 'https://piranha-dog1.dogfood.us-west-2.aws.plume.tech',
    flexUrl: 'https://piranha-dog1.dogfood.us-west-2.aws.plume.tech/flex',
    upriseUrl: 'https://eks-ext.usw2.dogfood.dog1.plume.tech/uprise',
    upriseApplicationUrl: 'https://dogfood.uprise.plume.com',
    metasaurusUrl: 'https://dwb-apptime-dev.plume.tech',
    mywifi: 'https://captiveportal-staging.plume.me',
    signalUrl: 'https://signal-dog1-ext.us-west-2.datavinci.dev.plume.tech',
    idps: [],
    partners: {}
  },
  {
    id: 'etna',
    name: 'Etna',
    defaultSearch: 'id',
    loopbackAuth: true,
    oktaAuth: true,
    dynamicIdps: false,
    url: 'https://piranha-etna.prod.us-west-2.aws.plume.tech',
    metasaurusUrl: 'https://dwb-apptime-dev.plume.tech',
    mywifi: 'https://captiveportal-staging.plume.me',
    idps: [
      {
        id: 'okta',
        description: 'Bell PreProd',
        value: '0oad1fyhok5QrIRpI357',
        mode: 'vanity'
      },
      {
        id: 'okta',
        description: 'Bell Prod',
        value: '0oad1g0l6tf441eI8357',
        mode: 'vanity'
      }
    ]
  },
  {
    id: 'etna2',
    name: 'Etna2 Dev',
    pollingInterval: 60,
    loopbackAuth: false,
    oktaAuth: true,
    dynamicIdps: false,
    url: 'https://piranha-etna2.dev.us-west-2.aws.plume.tech',
    mywifi: 'https://captiveportal.plume.me',
    idps: [],
    partners: {}
  },
  {
    id: 'func4',
    name: 'Func4',
    pollingInterval: 60,
    loopbackAuth: false,
    oktaAuth: true,
    dynamicIdps: false,
    url: 'https://piranha-func4.qa.us-west-2.aws.plume.tech',
    mywifi: 'https://captiveportal.plume.me',
    idps: [],
    partners: {}
  },
  {
    id: 'gamma',
    name: 'Gamma',
    loopbackAuth: true,
    oktaAuth: true,
    dynamicIdps: false,
    url: 'https://piranha-gamma.prod.us-west-2.aws.plumenet.io',
    lteUrl: 'https://piranha-gamma.prod.us-west-2.aws.plumenet.io',
    flexUrl: 'https://piranha-gamma.prod.us-west-2.aws.plumenet.io/flex',
    upriseUrl: 'https://eks-ext.usw2.prod.gamma.plumenet.io/uprise',
    upriseApplicationUrl: 'https://gamma.uprise.plume.com',
    statuspage: 'https://status-gamma-prod.plumenet.io',
    mywifi: 'https://captiveportal.plume.me',
    signalUrl: 'https://signal-gamma-ext.usw2.prod.datavinci.plumenet.io',
    idps: [],
    partners: {
      jcom: {
        loopbackAuth: true,
        oktaAuth: true
      }
    }
  },
  {
    id: 'kappa',
    name: 'Kappa',
    loopbackAuth: true,
    oktaAuth: true,
    dynamicIdps: false,
    url: 'https://piranha.eu-central-1.prod.kappa.plumenet.io',
    lteUrl: 'https://piranha.eu-central-1.prod.kappa.plumenet.io',
    flexUrl: 'https://piranha.eu-central-1.prod.kappa.plumenet.io/flex',
    upriseUrl: 'https://eks-ext.euc1.prod.kappa.plumenet.io/uprise',
    upriseApplicationUrl: 'https://kappa.uprise.plume.com',
    statuspage: 'https://status-kappa-prod.plumenet.io',
    mywifi: 'https://eu.captiveportal.plume.me',
    signalUrl: 'https://signal-kappa-ext.euc1.prod.datavinci.plumenet.io',
    idps: [],
    partners: {}
  },
  {
    id: 'iota',
    name: 'Iota',
    loopbackAuth: true,
    oktaAuth: true,
    dynamicIdps: false,
    url: 'https://piranha.apne1.prod.iota.plumenet.io',
    upriseApplicationUrl: 'https://iota.uprise.plume.com',
    statuspage: 'https://status-iota-prod.plumenet.io',
    mywifi: 'https://captiveportal.plume.me',
    signalUrl: 'https://signal-iota-ext.apne1.prod.datavinci.plumenet.io',
    idps: [],
    partners: {}
  },
  {
    id: 'aio-dev01',
    name: 'Aio-dev01',
    pollingInterval: 60,
    loopbackAuth: false,
    oktaAuth: true,
    dynamicIdps: false,
    url: 'https://piranha.usw2.dev01.aio.plume.tech',
    mywifi: 'https://captiveportal.plume.me',
    idps: [],
    partners: {}
  },
  {
    id: 'aio-qa03',
    name: 'Aio-qa03',
    pollingInterval: 60,
    loopbackAuth: true,
    oktaAuth: true,
    dynamicIdps: false,
    url: 'https://piranha.usw2.qa03.aio.plume.tech',
    mywifi: 'https://captiveportal.plume.me',
    idps: [],
    partners: {}
  },
  {
    id: 'aio-qa04',
    name: 'Aio-qa04',
    pollingInterval: 60,
    loopbackAuth: false,
    oktaAuth: true,
    dynamicIdps: false,
    url: 'https://piranha.usw2.qa04.aio.plume.tech',
    mywifi: 'https://captiveportal.plume.me',
    idps: [],
    partners: {}
  },
  {
    id: 'padev2',
    name: 'Padev2',
    pollingInterval: 60,
    loopbackAuth: true,
    oktaAuth: true,
    dynamicIdps: false,
    url: 'https://piranha-padev2.dev.us-west-2.aws.plume.tech',
    mywifi: 'https://captiveportal.plume.me',
    idps: [],
    partners: {}
  },
  {
    id: 'phi',
    name: 'Phi',
    loopbackAuth: true,
    oktaAuth: true,
    dynamicIdps: false,
    url: 'https://piranha.apse1.prod.phi.plumenet.io',
    lteUrl: 'https://piranha.apse1.prod.phi.plumenet.io',
    flexUrl: 'https://piranha.apse1.prod.phi.plumenet.io/flex',
    upriseUrl: 'https://piranha.apse1.prod.phi.plumenet.io/uprise',
    upriseApplicationUrl: 'https://phi.uprise.plume.com',
    statuspage: 'https://status-phi-prod.plumenet.io',
    mywifi: 'https://captiveportal.plume.me',
    idps: [],
    partners: {}
  },
  {
    id: 'pml',
    name: 'PML',
    loopbackAuth: true,
    oktaAuth: true,
    dynamicIdps: false,
    url: 'https://piranha-pml.partner.us-west-2.aws.plume.tech',
    mywifi: 'https://captiveportal.plume.me',
    idps: [
      {
        id: 'vodafoneCav',
        description: 'PML-CAV',
        domains: ['vodafone.com']
      },
      {
        id: 'vodafoneGdi',
        description: 'PML-ES',
        domains: ['vodafone.com']
      }
    ],
    partners: {
      vodafone: {
        idps: [0, 1],
        loopbackAuth: false,
        groups: {
          es: {
            idps: [0, 1]
          }
        }
      }
    }
  },
  {
    id: 'sigma',
    name: 'Sigma',
    pollingInterval: 90,
    defaultSearch: 'id',
    loopbackAuth: false,
    oktaAuth: false,
    url: 'https://piranha.eu-central-1.prod.sigma.plumenet.io',
    mywifi: 'https://captiveportal.plume.me',
    idps: [
      {
        id: 'vodafoneCav',
        description: 'VF-ES Internal',
        domains: ['vodafone.com']
      },
      {
        id: 'vodafoneGdi',
        description: 'VF-ES External',
        domains: ['vodafone.com']
      }
    ],
    partners: {
      vodafone: {
        idps: [0, 1],
        loopbackAuth: false,
        groups: {
          es: {
            idps: [0, 1],
            oktaAuth: false
          }
        }
      }
    }
  },
  {
    id: 'slodev1',
    name: 'Slodev1',
    loopbackAuth: true,
    oktaAuth: false,
    dynamicIdps: false,
    url: 'https://piranha-slodev1.dev.us-west-2.aws.plume.tech',
    mywifi: 'https://captiveportal.plume.me',
    idps: []
  },
  {
    id: 'tau-dev3',
    name: 'Tau-dev3',
    loopbackAuth: true,
    oktaAuth: true,
    dynamicIdps: false,
    url: 'https://piranha-dev3.tau.dev-charter.net',
    mywifi: 'https://captiveportal.plume.me',
    idps: [
      {
        id: 'okta',
        description: 'Charter AD',
        value: '0oa3zowf5zY8ycGlK357'
      }
    ]
  },
  {
    id: 'tau-int',
    name: 'Tau-int',
    loopbackAuth: true,
    oktaAuth: true,
    dynamicIdps: false,
    url: 'https://piranha-int.tau.dev-charter.net',
    mywifi: 'https://captiveportal.plume.me',
    idps: [
      {
        id: 'okta',
        description: 'Charter AD',
        value: '0oa3zowf5zY8ycGlK357'
      }
    ]
  },
  {
    id: 'tau-perf',
    name: 'Tau-perf',
    loopbackAuth: true,
    oktaAuth: true,
    dynamicIdps: false,
    url: 'https://piranha-perf.tau.dev-charter.net',
    mywifi: 'https://captiveportal.plume.me',
    idps: [
      {
        id: 'okta',
        description: 'Charter AD',
        value: '0oa3zowf5zY8ycGlK357'
      }
    ]
  },
  {
    id: 'tau-stage',
    name: 'Tau-stage',
    loopbackAuth: true,
    oktaAuth: true,
    dynamicIdps: false,
    url: 'https://piranha-stage.tau.spectrum.net',
    mywifi: 'https://captiveportal.plume.me',
    idps: [
      {
        id: 'okta',
        description: 'Charter AD',
        value: '0oa3zowf5zY8ycGlK357'
      }
    ]
  },
  {
    id: 'tau-prod',
    name: 'Tau-prod',
    loopbackAuth: true,
    oktaAuth: true,
    dynamicIdps: false,
    url: 'https://piranha-prod.tau.spectrum.net',
    mywifi: 'https://captiveportal.plume.me',
    idps: [
      {
        id: 'okta',
        description: 'Charter Prod AD',
        value: '0oa9911fz2l5AxxuT357'
      }
    ]
  },
  {
    id: 'opensync',
    name: 'OpenSync Development',
    pollingInterval: 60,
    loopbackAuth: true,
    oktaAuth: true,
    dynamicIdps: false,
    url: 'https://piranha-opensync.dev.us-west-2.aws.plume.tech',
    lteUrl: 'https://piranha-opensync.dev.us-west-2.aws.plume.tech',
    flexUrl: 'https://piranha-opensync.dev.us-west-2.aws.plume.tech/flex',
    metasaurusUrl: 'https://dwb-apptime-dev.plume.tech',
    mywifi: 'https://captiveportal.plume.me',
    idps: []
  },
  {
    id: 'rhosandbox',
    name: 'Rho-sandbox',
    defaultSearch: 'id',
    loopbackAuth: true,
    oktaAuth: false,
    dynamicIdps: false,
    url: 'https://piranha-api.as1.sandbox.rho.jiohs.net',
    lteUrl: 'https://piranha-api.as1.sandbox.rho.jiohs.net',
    privacyUrl: 'https://www.jio.com/jcms/en-in/privacy-policy/',
    idps: []
  },
  {
    id: 'rho-sit',
    name: 'Rho-sit',
    defaultSearch: 'id',
    loopbackAuth: true,
    oktaAuth: false,
    dynamicIdps: false,
    url: 'https://sit.rho.jiohs.net',
    lteUrl: 'https://sit.rho.jiohs.net',
    privacyUrl: 'https://www.jio.com/jcms/en-in/privacy-policy/',
    idps: []
  },
  {
    id: 'rho-dev',
    name: 'Rho-Dev',
    defaultSearch: 'id',
    loopbackAuth: true,
    oktaAuth: true,
    dynamicIdps: false,
    url: 'https://piranha.aps1.dev.rho.jiohs.net',
    lteUrl: 'https://piranha.aps1.dev.rho.jiohs.net',
    flexUrl: 'https://piranha.aps1.dev.rho.jiohs.net/flex',
    privacyUrl: 'https://www.jio.com/jcms/en-in/privacy-policy/',
    idps: [
      {
        id: 'okta',
        description: 'Jio Prod',
        value: '0oavrmoxh60cD0nYF357'
      }
    ]
  },
  {
    id: 'rho',
    name: 'Rho',
    defaultSearch: 'id',
    loopbackAuth: true,
    oktaAuth: true,
    dynamicIdps: false,
    url: 'https://piranha.aps1.prod.rho.jiohs.net',
    lteUrl: 'https://piranha.aps1.prod.rho.jiohs.net',
    flexUrl: 'https://piranha.aps1.prod.rho.jiohs.net/flex',
    signalUrl: 'https://signal.aps1.prod.rho.jiohs.net',
    privacyUrl: 'https://www.jio.com/jcms/en-in/privacy-policy/',
    idps: [
      {
        id: 'okta',
        description: 'Jio Prod',
        value: '0oavrmoxh60cD0nYF357'
      }
    ]
  },
  {
    id: 'theta',
    name: 'Theta',
    defaultSearch: 'node',
    loopbackAuth: false,
    oktaAuth: true,
    url: 'https://piranha.eu-central-1.prod.theta.plumenet.io',
    mywifi: 'https://lg.captiveportal.plume.me',
    statuspage: 'https://status-theta-prod.plumenet.io',
    idps: [
      {
        id: 'okta',
        description: 'LGI',
        value: '0oa1b9a2z8fsmwG1x357'
      },
      {
        id: 'okta',
        description: 'VodafoneZiggo Azure',
        value: '0oa6qeav2aIkcm47F357'
      }
    ]
  },
  {
    id: 'theta-stage',
    name: 'Theta-Stage',
    defaultSearch: 'node',
    loopbackAuth: true,
    oktaAuth: true,
    url: 'https://piranha.euc1.stage.theta.plumenet.io',
    mywifi: 'https://lg.captiveportal.plume.me',
    idps: [
      {
        id: 'okta',
        description: 'LGI',
        value: '0oa1b9a2z8fsmwG1x357'
      },
      {
        id: 'okta',
        description: 'VodafoneZiggo Azure',
        value: '0oa6qeav2aIkcm47F357'
      }
    ]
  },
  {
    id: 'thetadev',
    name: 'ThetaDev',
    defaultSearch: 'node',
    loopbackAuth: true,
    oktaAuth: true,
    url: 'https://piranha-thetadev.partner.us-west-2.aws.plume.tech',
    metasaurusUrl: 'https://dwb-apptime-dev.plume.tech',
    mywifi: 'https://captiveportal-staging.plume.me',
    idps: [
      {
        id: 'okta',
        description: 'LGI',
        value: '0oa1b9a2z8fsmwG1x357'
      },
      {
        id: 'okta',
        description: 'VodafoneZiggo Azure',
        value: '0oa6qeav2aIkcm47F357'
      }
    ]
  },
  {
    id: 'tomasz',
    name: 'Tomasz',
    loopbackAuth: true,
    oktaAuth: false,
    dynamicIdps: false,
    url: 'https://piranha-tomasz.dev.us-west-2.aws.plume.tech',
    mywifi: 'https://captiveportal.plume.me',
    idps: []
  }
];
