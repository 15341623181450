import { createSelector } from '@ngrx/store';
import { AppState } from '../reducers';
import { NeighborReportCnsState, QoE5gMetricsState } from './qoeCellular.reducer';

export const selectQoeMetricsState = (state: AppState) => state.qoe5gMetrics;

export const selectQoe5gReports = createSelector(
  selectQoeMetricsState,
  (state: QoE5gMetricsState) => state.qoe5gmetrics
);

export const selectNeighborReportCnsState = (state: AppState) => state.neighborReportCns;

export const selectNeighborReportCns = createSelector(
  selectNeighborReportCnsState,
  (state: NeighborReportCnsState) => state.neighborReportCns
);
