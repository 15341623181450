import { Component, OnInit, OnChanges, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { selectPipeLocationOnChange } from 'src/app/store/customer/customer.selectors';
import { Store } from '@ngrx/store';
import { DeepReadonly, ILocation } from 'src/app/lib/interfaces/interface';
import { selectCapabilities } from 'src/app/store/customer/capabilities.selector';
import { pollingPull } from 'src/app/store/polling/polling.actions';
import { NetworkConfigurationService } from 'src/app/lib/services/network-configuration.service';

@Component({
  selector: 'multicast',
  templateUrl: './multicast.component.html',
  styleUrls: ['./multicast.component.scss']
})
export class MulticastComponent implements OnInit, OnChanges, OnDestroy {
  expand: boolean = false;
  locationSubscription: any;
  location: DeepReadonly<ILocation> = {} as any;
  multicast: any;
  igmpSnoopingItems: any[] = [];
  igmpProxyItems: any[] = [];
  mldProxyItems: any[] = [];
  multicastToUnicastItems: any[] = [];
  capabilities$ = this.store.select(selectCapabilities);

  @Input()
  open: number = 0;

  @Output()
  toggle = new EventEmitter();

  @Output()
  filter = new EventEmitter();

  @Output()
  clearFilter = new EventEmitter<{ section: string }>();

  constructor(
    private networkConfiguration: NetworkConfigurationService,
    public plume: PlumeService,
    private mixpanel: MixpanelService,
    private translate: TranslateService,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.init();
    this.registerFilter();

    this.locationSubscription = this.store.pipe(selectPipeLocationOnChange).subscribe((response: any) => {
      if (response) {
        this.location = response;
        this.initTogglers();
      }
    });
  }

  ngOnChanges(changes: any): void {
    this.expand = changes.open.currentValue;
  }

  init(): void {
    this.networkConfiguration.networkConfiguration$().subscribe((response) => {
      if (response.multicast) {
        this.multicast = response.multicast;
        this.initTogglers();
      }
    });
  }

  registerFilter(): void {
    this.clearFilter.emit({ section: 'multicast' });

    this.translate
      .get('configurations.multicast.networkConfigurations.igmpSnooping')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'multicast', property: 'igmpSnooping', translation: translated })
      );

    this.translate
      .get('configurations.multicast.networkConfigurations.igmpProxy')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'multicast', property: 'igmpProxy', translation: translated })
      );

    this.translate
      .get('configurations.multicast.networkConfigurations.mldProxy')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'multicast', property: 'mldProxy', translation: translated })
      );

    this.translate
      .get('configurations.multicast.networkConfigurations.multicastToUnicast')
      .subscribe((translated: string) =>
        this.filter.emit({
          section: 'multicast',
          property: 'onboamulticastToUnicastrdnetwork',
          translation: translated
        })
      );
  }

  action(command: string, action: string): void {
    switch (command) {
      case 'igmpSnooping':
        this.networkConfiguration.updateMulticast$({ igmpSnooping: { mode: action } }).subscribe((response) => {
          this.multicast = response;
          this.mixpanel.storeEvent('CONFIGURATION_IGMPSNOOPING_SET', { IGMPSNOOPING: action });
          this.store.dispatch(pollingPull({ debugSource: 'multicast igmpSnooping' }));
        });
        break;

      case 'igmpProxy':
        this.networkConfiguration.updateMulticast$({ igmpProxy: { mode: action } }).subscribe((response) => {
          this.multicast = response;
          this.mixpanel.storeEvent('CONFIGURATION_IGMPPROXY_SET', { IGMPPROXY: action });
          this.store.dispatch(pollingPull({ debugSource: 'multicast igmpProxy' }));
        });
        break;

      case 'mldProxy':
        this.networkConfiguration.updateMulticast$({ mldProxy: { mode: action } }).subscribe((response) => {
          this.multicast = response;
          this.mixpanel.storeEvent('CONFIGURATION_MLDPROXY_SET', { MLDPROXY: action });
          this.store.dispatch(pollingPull({ debugSource: 'multicast mldProxy' }));
        });
        break;

      case 'multicastToUnicast':
        this.networkConfiguration.updateMulticast$({ multicastToUnicast: { mode: action } }).subscribe((response) => {
          this.multicast = response;
          this.mixpanel.storeEvent('CONFIGURATION_MULTICAST_TOUNICAST_SET', { MULTICAST_TOUNICAST: action });
          this.store.dispatch(pollingPull({ debugSource: 'multicast multicastToUnicast' }));
        });
        break;
    }
  }

  initTogglers(): void {
    if (this.location) {
      this.igmpSnoopingItems = [
        {
          value: 'auto',
          translation: 'auto',
          selected: !this.multicast || this.multicast?.igmpSnooping?.mode === 'auto'
        },
        {
          value: 'enable',
          translation: 'enabled',
          marked: this.location.multicast?.igmpSnooping,
          selected: this.multicast?.igmpSnooping?.mode === 'enable'
        },
        {
          value: 'disable',
          translation: 'disabled',
          marked: !this.location.multicast?.igmpSnooping,
          selected: this.multicast?.igmpSnooping?.mode === 'disable'
        }
      ];

      this.igmpProxyItems = [
        { value: 'auto', translation: 'auto', selected: !this.multicast || this.multicast?.igmpProxy?.mode === 'auto' },
        {
          value: 'igmpv1',
          translation: 'IGMPv1',
          marked: this.location.multicast?.igmpProxy === 'igmpv1',
          selected: this.multicast?.igmpProxy?.mode === 'igmpv1'
        },
        {
          value: 'igmpv2',
          translation: 'IGMPv2',
          marked: this.location.multicast?.igmpProxy === 'igmpv2',
          selected: this.multicast?.igmpProxy?.mode === 'igmpv2'
        },
        {
          value: 'igmpv3',
          translation: 'IGMPv3',
          marked: this.location.multicast?.igmpProxy === 'igmpv3',
          selected: this.multicast?.igmpProxy?.mode === 'igmpv3'
        },
        {
          value: 'disable',
          translation: 'disabled',
          marked: this.location.multicast?.igmpProxy === 'disable',
          selected: this.multicast?.igmpProxy?.mode === 'disable'
        }
      ];

      this.mldProxyItems = [
        { value: 'auto', translation: 'auto', selected: !this.multicast || this.multicast?.mldProxy?.mode === 'auto' },
        {
          value: 'mldv1',
          translation: 'MLDv1',
          marked: this.location.multicast?.mldProxy === 'mldv1',
          selected: this.multicast?.mldProxy?.mode === 'mldv1'
        },
        {
          value: 'mldv2',
          translation: 'MLDv2',
          marked: this.location.multicast?.mldProxy === 'mldv2',
          selected: this.multicast?.mldProxy?.mode === 'mldv2'
        },
        {
          value: 'disable',
          translation: 'disabled',
          marked: this.location.multicast?.mldProxy === 'disable',
          selected: this.multicast?.mldProxy?.mode === 'disable'
        }
      ];

      this.multicastToUnicastItems = [
        {
          value: 'auto',
          translation: 'auto',
          selected: !this.multicast || this.multicast?.multicastToUnicast?.mode === 'auto'
        },
        {
          value: 'enable',
          translation: 'enabled',
          marked: this.location.multicast?.multicastToUnicast,
          selected: this.multicast?.multicastToUnicast?.mode === 'enable'
        },
        {
          value: 'disable',
          translation: 'disabled',
          marked: !this.location.multicast?.multicastToUnicast,
          selected: this.multicast?.multicastToUnicast?.mode === 'disable'
        }
      ];
    }
  }

  toggleExpand(): void {
    this.toggle.emit(!this.expand);
    this.initTogglers();

    if (!this.expand) {
      this.mixpanel.storeEvent('CONFIGURATION_MULTICAST_SCREEN');
    }
  }

  ngOnDestroy(): void {
    if (this.locationSubscription) {
      this.locationSubscription.unsubscribe();
    }
  }
}
