<div class="tile" [class.disable]="disabled">
  <div class="container" *ngIf="tileIcon">
    <icon [name]="tileIcon"></icon>
  </div>
  <div
    class="container"
    [ngStyle]="{ backgroundImage: 'url(' + tileImage + ')' }"
    [class.large]="largeImage"
    *ngIf="tileImage"
  ></div>
  <div class="title">
    <div class="section">{{ title | translate }}</div>
    <div class="value"><ng-content></ng-content></div>
  </div>
  <div class="options" *ngIf="options">
    <icon
      name="icon-more"
      (click)="action.next()"
      tooltip="{{ optionsTooltip | translate }}"
      *ngIf="optionsTooltip"
    ></icon>
    <icon name="icon-more" (click)="action.next()" *ngIf="!optionsTooltip"></icon>
    <icon
      name="icon-alert-warning"
      class="badge"
      tooltip="{{ optionsBadgeTooltip | translate }}"
      *ngIf="optionsBadge && optionsBadgeTooltip"
    ></icon>
    <icon name="icon-alert-warning" class="badge" *ngIf="optionsBadge && !optionsBadgeTooltip"></icon>
  </div>
</div>
