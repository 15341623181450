<div class="table">
  <div class="neighbor-wrap">
    <div class="header">
      <div class="sortable" [ngClass]="header.key" *ngFor="let header of headers">
        <div class="txt" (click)="changeSort(header.key)">
          {{ header.value | translate }}
        </div>
        <icon name="icon-triangle-down" [class.rotate180]="header.desc" *ngIf="header.key === sort"></icon>
      </div>
    </div>
    <div class="neighbor" [class.isEvil]="markEvil && neighbor.isEvil"
      [class.isParallel]="markParallel && neighbor.isParallel" *ngFor="let neighbor of list">
      <div class="neighborSsid">
        {{ neighbor.neighborSsid || 'health.networkStatus.neighborReportAlarm.hidden' | translate }}
      </div>
      <div class="neighborId">{{ neighbor.neighborId }}</div>
      <div class="channel">{{ neighbor.channel }}</div>
      <div class="channelWidth">{{ neighbor.channelWidth }}</div>
      <div class="rssi" *ngIf="!isGreaterThanThreshold">
        {{ neighbor.rssi }}
      </div>
      <div class="rssi" *ngIf="isGreaterThanThreshold">
        <span class="invalid">{{ 'neighborsReport.invalid' | translate }}</span>
      </div>
      <div class="freqBand">{{ neighbor.freqBand }}</div>
    </div>
  </div>
</div>