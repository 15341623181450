<div class="row" id="conf-wifiradio">
  <div class="col-xs-12">
    <collapsiblebox [show]="expand" [collapsible]="true" (toggle)="toggleExpand()">
      <div header>{{ 'configurations.wifiradio.title' | translate }}</div>
      <div container *ngIf="expand">
        <div class="columns">
          <div class="column">
            <div class="row">
              <div class="key">{{ 'configurations.wifiradio.wifiRadios' | translate }}</div>
              <div class="toggler">
                <div class="checkbox">
                  <label
                    for="toggleChannel"
                    class="toggle"
                    (click)="updateWifiRadio()"
                    [class.checked]="wifiRadio"
                    [class.disabled]="
                      ((capabilities$ | async)?.radioDisableSupported?.capable !== true && wifiRadio) ||
                      !(plume.isSomeAdminRole() || plume.isStrictGroupSupportRole())
                    "
                  ></label>
                </div>
              </div>
              <icon
                name="icon-info"
                class="infoIcon"
                [tooltip]="'configurations.wifiradio.mayPreventFrontLine' | translate"
              ></icon>
            </div>
            <div class="row">
              <div class="key">{{ 'configurations.wifiradio.uapsd' | translate }}</div>
              <div class="toggler">
                <div class="checkbox">
                  <label
                    for="toggleChannel"
                    class="toggle"
                    (click)="updateuAPSD()"
                    [class.checked]="uAPSD"
                    [class.disabled]="!(plume.isSomeAdminRole() || plume.isStrictGroupSupportRole())"
                  ></label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="key">{{ 'configurations.wifiradio.groupRekey' | translate }}</div>
              <div class="value">
                <slider-toggle-mobile-aware
                  [options]="groupRekeyItems"
                  [editable]="plume.isSomeAdminRole() && (nodes$ | async)?.length > 0"
                  (toggle)="action('groupRekey', $event)"
                ></slider-toggle-mobile-aware>
              </div>
            </div>
            <div class="row">
              <div class="key">
                {{ 'configurations.wifiradio.MinWifiMode24' | translate }}
                <icon
                  name="icon-info"
                  class="infoIcon"
                  [tooltip]="'configurations.wifiradio.minWifiModeToolTip' | translate"
                ></icon>
              </div>
              <div class="value">
                <slider-toggle-mobile-aware
                  [options]="MinWifiMode24Items"
                  [editable]="plume.isSomeAdminRole() && (capabilities$ | async)?.minWifiMode24?.capable"
                  (toggle)="action('MinWifiMode24', $event)"
                ></slider-toggle-mobile-aware>
              </div>
            </div>
            <div class="row">
              <div class="key">{{ 'configurations.wifiradio.controlMode' | translate }}</div>
              <div class="value">
                <slider-toggle-mobile-aware
                  [options]="controlModeItems"
                  [editable]="
                    (plume.isSomeAdminRole() || plume.isStrictGroupSupportRole()) &&
                    (selectNonFullModeCapable$ | async) === true
                  "
                  (toggle)="action('controlMode', $event)"
                ></slider-toggle-mobile-aware>
              </div>
            </div>
            <div class="row" *ngIf="(capabilities$ | async)?.haahs?.capable">
              <div class="key">{{ 'configurations.wifiradio.haahs' | translate }}</div>
              <div class="value">
                <slider-toggle-mobile-aware
                  [options]="haahsItems"
                  [editable]="plume.isSomeAdminRole()"
                  (toggle)="action('haahs', $event)"
                ></slider-toggle-mobile-aware>
              </div>
            </div>

            <div class="row" *ngIf="punctureCapable">
              <div class="key">{{ 'configurations.wifiradio.puncturing' | translate }}</div>
              <div class="value">
                <slider-toggle-mobile-aware
                  [options]="puncturingItems"
                  [editable]="plume.isSomeAdminRole() && (nodes$ | async)?.length > 0 && !punctureWait"
                  (toggle)="action('punctureChange', $event)"
                ></slider-toggle-mobile-aware>
              </div>
            </div>
          </div>
        </div>
      </div>
    </collapsiblebox>
  </div>
</div>
