<div class="row" id="conf-smallbusiness" *ngIf="isSmallBusiness$ | async">
  <div class="col-xs-12">
    <collapsiblebox [show]="expand" [collapsible]="true" (toggle)="toggleExpand()">
      <div header>{{ 'configurations.smallbusiness.title' | translate }}</div>
      <div container class="main-container" *ngIf="expand">
        <div class="title">{{ 'configurations.smallbusiness.update' | translate }}</div>

        <div class="managerListContainerMobile">
          <div class="managerList">
            <div class="managerListHeader name">{{ 'configurations.smallbusiness.name' | translate }}</div>
            <div class="managerListHeader email">{{ 'configurations.smallbusiness.email' | translate }}</div>
            <div class="managerListHeader access">{{ 'configurations.smallbusiness.access' | translate }}</div>
            <div class="managerListHeader status">{{ 'configurations.smallbusiness.status' | translate }}</div>
            <div class="managerListHeader action"></div>
            <ng-container *ngFor="let manager of managers">
              <div class="name" [tooltip]="manager.name" [onlyOverflowText]="true">{{ manager.name }}</div>
              <div class="email" [tooltip]="manager.email" [onlyOverflowText]="true">{{ manager.email }}</div>
              <div class="access">{{ manager.accessType }}</div>
              <div class="status">{{ manager.status }}</div>
              <div class="action">
                <icon
                  *ngIf="plume.isSomeAdminRole()"
                  name="icon-close"
                  class="size-0_8"
                  tooltip="{{ 'configurations.smallbusiness.modal.deleteManager' | translate }}"
                  (click)="confirmDeleteManager(manager.managerId)"
                ></icon>
                <button
                  class="action small pending"
                  [class.show]="manager.status === 'pending'"
                  (click)="resendInvite(manager.managerId)"
                >
                  {{ 'configurations.smallbusiness.resendButton' | translate }}
                </button>
              </div>
            </ng-container>
            <div *ngIf="!managers.length" class="no-managers">
              {{ 'configurations.smallbusiness.noManagers' | translate }}
            </div>
          </div>
        </div>

        <div class="managerListContainerDesktop">
          <div class="header">
            <div class="name">{{ 'configurations.smallbusiness.name' | translate }}</div>
            <div class="email">{{ 'configurations.smallbusiness.email' | translate }}</div>
            <div class="access">{{ 'configurations.smallbusiness.access' | translate }}</div>
            <div class="status">{{ 'configurations.smallbusiness.status' | translate }}</div>
          </div>
          <ng-container *ngIf="!managers.length">
            <div class="no-managers">{{ 'configurations.smallbusiness.noManagers' | translate }}</div>
          </ng-container>
          <ng-container *ngIf="managers.length">
            <div class="list-managers" *ngFor="let manager of managers">
              <div class="name">{{ manager.name }}</div>
              <div class="email">{{ manager.email }}</div>
              <div class="access">{{ manager.accessType }}</div>
              <div class="status">{{ manager.status }}</div>
              <div class="actions">
                <icon
                  *ngIf="plume.isSomeAdminRole()"
                  name="icon-close"
                  class="size-0_8"
                  tooltip="{{ 'configurations.smallbusiness.modal.deleteManager' | translate }}"
                  (click)="confirmDeleteManager(manager.managerId)"
                ></icon>
                <button
                  class="action small pending"
                  [class.show]="manager.status === 'pending'"
                  [disabled]="!plume.isSomeAdminRole()"
                  (click)="resendInvite(manager.managerId)"
                >
                  {{ 'configurations.smallbusiness.resendButton' | translate }}
                </button>
              </div>
            </div>
          </ng-container>
        </div>

        <ng-container *ngIf="plume.isSomeAdminRole()">
          <div class="title">
            {{ 'configurations.smallbusiness.add' | translate }}
          </div>

          <div class="add-manager">
            <div class="name">
              <input
                type="text"
                class="enabled"
                placeholder="{{ 'configurations.smallbusiness.name' | translate }}"
                [formControl]="managerNameFormControl"
              />
            </div>
            <div class="email">
              <input
                type="text"
                class="enabled"
                placeholder="{{ 'configurations.smallbusiness.email' | translate }}"
                [formControl]="managerEmailFormControl"
              />
            </div>
            <div>
              <button class="action medium" (click)="addManager()">
                {{ 'configurations.smallbusiness.addButton' | translate }}
              </button>
            </div>
          </div>
        </ng-container>

        <div class="title">{{ 'configurations.smallbusiness.networks' | translate }}</div>
        <div class="list-networks-container">
          <div class="list-networks-header">
            <div class="networkId">{{ 'configurations.smallbusiness.networkId' | translate }}</div>
            <div class="purgatory">{{ 'configurations.smallbusiness.purgatory' | translate }}</div>
            <div class="captivePortal">{{ 'configurations.smallbusiness.captivePortal' | translate }}</div>
            <div class="approvedList">{{ 'configurations.smallbusiness.approvedList' | translate }}</div>
          </div>
          <div class="list-networks" *ngFor="let network of networks">
            <div class="networkId">{{ network.networkId }}</div>
            <div class="purgatory">
              <div class="toggler">
                <div class="checkbox">
                  <label
                    for="toggleChannel"
                    class="toggle"
                    [class.checked]="network.purgatory"
                    [class.disabled]="!plume.isSomeAdminRole()"
                    (click)="togglePurgatory(network.networkId, network.purgatory)"
                  ></label>
                </div>
              </div>
              {{ '' + network.purgatory | translate }}
            </div>
            <div class="captivePortal">{{ '' + (network.captivePortal || false) | translate }}</div>
            <div class="approvedList">{{ network.heaven }}</div>
          </div>
        </div>
      </div>
    </collapsiblebox>
  </div>
</div>
