<ng-container *ngFor="let group of calculations.groupedDatasets; let gi = index">
  <ng-container *ngFor="let dataset of group.dataSets; let di = index">
    <svg:g
      flm-graph-segmented-interval-bar
      *ngIf="dataset.type === 'segmentedIntervalBar'"
      class="container"
      [dataSet]="dataset"
      (barHover)="barHover.emit({ bar: $event, dataSet: dataset })"
    ></svg:g>
  </ng-container>
</ng-container>
