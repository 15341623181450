import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { catchError, map, share } from 'rxjs/operators';
import { UtilitiesService } from 'src/app/lib/services/utilities.service';

interface IAuditOption {
  value: 'location' | 'customer';
  translation: string;
  selected: boolean;
}

@Component({
  selector: 'auditlogs',
  templateUrl: './auditlogs.component.html',
  styleUrls: ['./auditlogs.component.scss']
})
export class AuditlogsComponent implements OnInit {
  auditTrails$: Observable<any> = null;
  options: IAuditOption[] = [
    {
      value: 'location',
      translation: 'auditlogs.location',
      selected: true
    },
    {
      value: 'customer',
      translation: 'auditlogs.customer',
      selected: false
    }
  ];

  constructor(private utilitiesService: UtilitiesService) {}

  ngOnInit(): void {
    this.setMode(this.options.find((opt) => opt.selected).value);
  }

  setMode(value: 'location' | 'customer'): void {
    if (value === 'location') {
      this.auditTrails$ = this.utilitiesService.getLocationAuditTrail();
    } else {
      this.auditTrails$ = this.utilitiesService.getCustomerAuditTrail();
    }
    this.auditTrails$ = this.auditTrails$.pipe(
      map((response) =>
        response
          .map((audit: any) => ({
            ...audit,
            createdAt: moment.utc(audit.createdAt).local().format('L LT')
          }))
          .reverse()
      ),
      catchError(() => []),
      share()
    );
  }
}
