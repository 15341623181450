import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IDevice } from 'src/app/lib/interfaces/interface';

@Component({
  selector: 'ipv6-modal',
  templateUrl: './ipv6-modal.component.html',
  styleUrls: ['./ipv6-modal.component.scss']
})
export class Ipv6ModalComponent {
  @Input()
  device: IDevice = null;

  @Output()
  closeIpv6Modal = new EventEmitter<void>();

  closeModal() {
    this.closeIpv6Modal.emit();
  }
}
