<div class="bubble arrow yes">
  <div class="troubleShootTitle" *ngIf="rebootState === 'init'">
    {{ 'health.bubbles.rebootBubble.rebootRecomended' | translate }}
  </div>
  <div class="troubleShootTitle" *ngIf="rebootState === 'busy' || rebootState === 'pending'">
    {{ 'health.bubbles.rebootBubble.rebootInProgress' | translate }}
  </div>
  <div class="troubleShootTitle" *ngIf="rebootState === 'success'">
    {{ 'health.bubbles.rebootBubble.gatewayRebootComplete' | translate }}
  </div>

  <div class="data">
    <div class="col-xs-6"><extenderconnections></extenderconnections></div>
  </div>

  <div *ngIf="rebootState === 'optimize'" class="lastOptMsg">
    {{ 'health.bubbles.rebootBubble.currentOptimization' | translate }}: {{ optimized | translate }}
  </div>
  <div *ngIf="rebootState === 'busy' || rebootState === 'pending'" class="lastOptMsg">
    {{ 'health.bubbles.rebootBubble.waitingForReboot' | translate }}
  </div>
  <div *ngIf="rebootState === 'success' || rebootState === 'fail'" class="lastOptMsg">&nbsp;</div>

  <div class="row optButtonRow">
    <div class="col-xs-4 optButtonCol">
      <div class="tsButton" (click)="rebootGateway()" *ngIf="rebootState === 'init'">
        <span class="testButton">{{ 'health.bubbles.rebootBubble.rebootGateway' | translate }}</span>
      </div>

      <div class="tsInfo" *ngIf="rebootState !== 'init'">
        <span *ngIf="rebootState === 'busy' || rebootState === 'pending'" class="testRunning">
          {{ 'health.bubbles.rebootBubble.rebooting' | translate }}
        </span>
        <span *ngIf="rebootState === 'optimize'">{{ 'health.bubbles.rebootBubble.optimizing' | translate }}</span>
        <span *ngIf="rebootState === 'success' || rebootState === 'fail'" class="testRunning">
          {{ 'health.bubbles.rebootBubble.rebootComplete' | translate }}<br />
        </span>
      </div>
    </div>
    <div class="col-xs-1"></div>
    <div class="col-xs-7 rebootMsgBlock">{{ 'health.bubbles.rebootBubble.message' | translate }}</div>
  </div>

  <div
    class="progressbar progressbar--bottom"
    [class.hidden]="rebootState !== 'busy' && rebootState !== 'pending' && rebootState !== 'optimize'"
  >
    <div class="indeterminate"></div>
  </div>
</div>
