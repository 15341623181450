<div class="container-fluid">
  <div class="warning" *ngIf="privateMode">{{ 'profiler.privateMode' | translate }}</div>
  <spacer>{{ 'profiler.title' | translate }}</spacer>
  <div class="row">
    <div class="col-xs-12">
      <profilersummary
        [reset]="reset"
        [allDevices]="allDevices"
        [appFacade]="appFacade"
        [wanStatsWeekly]="wanStatsWeekly"
        [wanStatsDaily]="wanStatsDaily"
        [wifiNetwork]="wifiNetwork"
        [persons]="persons"
      ></profilersummary>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12">
      <featureengagements
        [location]="location$ | async"
        [allDevices]="allDevices"
        [persons]="persons"
        [wifiMotion]="wifiMotion"
        [appTime]="appTime"
      ></featureengagements>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12">
      <mostactivedevices [reset]="reset" [allDevices]="allDevices" [appFacade]="appFacade"></mostactivedevices>
    </div>
  </div>
  <div class="row" *ngIf="(capabilities$ | async)?.dnsCategoryBlocking?.capable">
    <div class="col-xs-12">
      <guardcounts [reset]="reset" [hourlyCounts]="hourlyCounts"></guardcounts>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12">
      <devicecategories [reset]="reset" [allDevices]="allDevices"></devicecategories>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12">
      <wanstats
        [reset]="reset"
        [wanStatsDaily]="wanStatsDaily"
        [wanStatsWeekly]="wanStatsWeekly"
        [wanStatsMonthly]="wanStatsMonthly"
      ></wanstats>
    </div>
  </div>
</div>
