<div
  class="row"
  id="conf-threadnetwork"
  *ngIf="configAndState?.state?.capabilities?.thread && plume.cloudVersionAbove1_118()"
>
  <div class="col-xs-12">
    <collapsiblebox [show]="expand" [collapsible]="true" (toggle)="toggleExpand()">
      <div header>{{ 'configurations.threadnetwork.title' | translate }}</div>
      <div container *ngIf="expand">
        <div class="columns">
          <div class="column">
            <div class="row">
              <div class="key">{{ 'configurations.threadnetwork.networkEnabled' | translate }}</div>
              <div class="value">
                <slider-toggle-mobile-aware
                  [options]="enableItems"
                  [editable]="editing"
                  (toggle)="networkEnabled = $event"
                ></slider-toggle-mobile-aware>
              </div>
            </div>
            <div class="row">
              <div class="key">{{ 'configurations.threadnetwork.threadInterface' | translate }}</div>
              <div class="value">
                <div class="output" *ngIf="!editing">
                  {{
                    configAndState?.config?.thread?.threadInterface
                      ? configAndState?.config?.thread?.threadInterface
                      : ('configurations.threadnetwork.noValue' | translate)
                  }}
                </div>
                <div class="editable" *ngIf="editing">
                  <input [formControl]="threadInterface" type="text" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="key">{{ 'configurations.threadnetwork.reportingInterval' | translate }}</div>
              <div class="value">
                <div class="output" *ngIf="!editing">
                  {{
                    configAndState?.config?.thread?.reportingInterval
                      ? configAndState?.config?.thread?.reportingInterval
                      : ('configurations.threadnetwork.noValue' | translate)
                  }}
                </div>
                <div class="editable" *ngIf="editing">
                  <input [formControl]="reportingInterval" type="number" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="key"></div>
              <div class="value">
                <button class="action medium" *ngIf="!editing && plume.isSomeAdminRole()" (click)="editNetwork()">
                  {{ 'configurations.threadnetwork.edit' | translate }}
                </button>
                <button class="super-primary medium" *ngIf="editing" (click)="setNetwork()">
                  {{ 'configurations.threadnetwork.save' | translate }}
                </button>
                <button class="action medium" *ngIf="editing" (click)="cancelEditNetwork()">
                  {{ 'configurations.threadnetwork.cancel' | translate }}
                </button>
              </div>
            </div>
            <div class="row top" *ngIf="configAndState?.config?.thread">
              <div class="key">{{ 'configurations.threadnetwork.config' | translate }}</div>
              <div class="value">
                <div class="data-wrap">
                  <div class="selector" [class.collapsed]="!configExpand" (click)="configExpand = !configExpand">
                    {{ 'configurations.threadnetwork.object' | translate }}
                    <icon name="icon-arrow-down" class="size-0_8" [class.rotate180]="configExpand"></icon>
                  </div>
                  <ng-container *ngIf="configExpand">
                    <ngx-json-viewer [json]="configAndState.config.thread"></ngx-json-viewer>
                  </ng-container>
                </div>
              </div>
            </div>
            <div class="row top" *ngIf="configAndState?.state?.thread">
              <div class="key">{{ 'configurations.threadnetwork.state' | translate }}</div>
              <div class="value">
                <div class="data-wrap">
                  <div class="selector" [class.collapsed]="!stateExpand" (click)="stateExpand = !stateExpand">
                    {{ 'configurations.threadnetwork.object' | translate }}
                    <icon name="icon-arrow-down" class="size-0_8" [class.rotate180]="stateExpand"></icon>
                  </div>
                  <ng-container *ngIf="stateExpand">
                    <ngx-json-viewer [json]="configAndState.state.thread"></ngx-json-viewer>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </collapsiblebox>
  </div>
</div>
