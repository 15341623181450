import { Component, OnInit, OnChanges, Output, EventEmitter, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { ToastService } from 'src/app/lib/services/toast.service';
import * as moment from 'moment';
import { CustomerService } from 'src/app/lib/services/customer.service';
import { Store } from '@ngrx/store';
import { selectPipeLocationOnChange } from 'src/app/store/customer/customer.selectors';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  subscriptionServiceLevelChanged
} from 'src/app/store/configview/config.actions';
import { IMembership } from 'src/app/lib/interfaces/interface';

@UntilDestroy()
@Component({
  selector: 'subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit, OnChanges {
  expand: boolean = false;
  loading: boolean = false;
  location: any = {};
  membership: IMembership = {} as IMembership;
  subscription: any = {};
  serviceLevelSwitches: any[] = [];
  ratePlanSwitches: any[] = [];
  isUprise: boolean = false;
  ratePlanSwitchesEditable = false;
  @Input()
  open: number = 0;

  @Output()
  toggle = new EventEmitter();

  @Output()
  filter = new EventEmitter();

  @Output()
  clearFilter = new EventEmitter<{ section: string }>();

  constructor(
    public plume: PlumeService,
    private toast: ToastService,
    private mixpanel: MixpanelService,
    private translate: TranslateService,
    private customerService: CustomerService,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.init();
    this.registerFilter();
  }

  ngOnChanges(changes: any): void {
    this.expand = changes.open.currentValue;
  }

  init(): void {
    this.getLocation();
    this.getSubscription();
  }

  registerFilter(): void {
    this.clearFilter.emit({ section: 'subscription' });

    this.translate
      .get('configurations.subscription.servicelevel')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'subscription', property: 'servicelevel', translation: translated })
      );

    this.translate.get('configurations.subscription.rateplan').subscribe((translated: string) =>
      this.filter.emit({
        section: 'subscription',
        property: 'rateplan',
        translation: translated
      })
    );

    this.translate.get('configurations.subscription.status').subscribe((translated: string) =>
      this.filter.emit({
        section: 'subscription',
        property: 'status',
        translation: translated
      })
    );

    this.translate.get('configurations.subscription.rateplanchargeid').subscribe((translated: string) =>
      this.filter.emit({
        section: 'subscription',
        property: 'rateplanchargeid',
        translation: translated
      })
    );

    this.translate.get('configurations.subscription.term').subscribe((translated: string) =>
      this.filter.emit({
        section: 'subscription',
        property: 'term',
        translation: translated
      })
    );

    this.translate
      .get('configurations.subscription.effective')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'subscription', property: 'effective', translation: translated })
      );

    this.translate.get('configurations.subscription.expires').subscribe((translated: string) =>
      this.filter.emit({
        section: 'subscription',
        property: 'expires',
        translation: translated
      })
    );

    this.translate.get('configurations.subscription.authorizations').subscribe((translated: string) =>
      this.filter.emit({
        section: 'subscription',
        property: 'authorizations',
        translation: translated
      })
    );
  }

  getSubscription(): any {
    this.customerService.membership$().subscribe(
      (response) => {
        this.membership = response;
        this.initTogglers();
      },
      (error) => {
        this.mixpanel.storeEvent('SUBSCRIPTION_MEMBERSHIP_ERROR', { ERROR_TEXT: error });
        this.toast.error(
          'configurations.subscription.toast.subscriptionErrorMessage',
          'configurations.subscription.toast.subscriptionErrorTitle'
        );
      }
    );
  }

  getLocation(): any {
    this.store
      .pipe(selectPipeLocationOnChange)
      .pipe(untilDestroyed(this))
      .subscribe(
        (response) => {
          this.location = response;
          this.isUprise = response.uprise;
          this.initTogglers();
        },
        (error: any) => {
          this.mixpanel.storeEvent('SUBSCRIPTION_LOCATION_ERROR', { ERROR_TEXT: error });
          this.toast.error(
            'configurations.subscription.toast.locationErrorMessage',
            'configurations.subscription.toast.locationErrorTitle'
          );
        }
      );
  }

  formatDate(d: string): string {
    if (d) {
      return moment(d).format('L');
    } else {
      return 'N/A';
    }
  }

  toggleExpand(): void {
    this.toggle.emit(!this.expand);
    this.initTogglers();

    if (!this.expand) {
      this.mixpanel.storeEvent('CONFIGURATION_SUBSCRIPTION_SCREEN');
    }
  }

  action(command: string, action: string): void {
    switch (command) {
      case 'servicelevel':
        this.patchServiceLevel(action);
        break;
    }
  }

  patchServiceLevel(status: any): any {
    if (!this.isUprise) {
      this.loading = true;
      this.customerService.setServiceLevel$(status).subscribe(
        () => {
          this.store.dispatch(subscriptionServiceLevelChanged({ value: status }));
          this.loading = false;
          this.mixpanel.storeEvent('SUBSCRIPTION_PUT_SERVICELEVEL_SUCCESS', { SERVICE_LEVEL: status });
        },
        (error: any) => {
          this.mixpanel.storeEvent('SUBSCRIPTION_PUT_SERVICELEVEL_ERROR', { ERROR_TEXT: error });
          this.toast.error(
            'configurations.subscription.toast.serviceLevelPatchErrorMessage',
            'configurations.subscription.toast.serviceLevelPatchErrorTitle'
          );
        }
      );
    }
  }

  initTogglers(): void {
    this.serviceLevelSwitches = [
      {
        value: 'reset',
        translation: 'auto',
        selected:
          this.location.serviceLevel &&
          (this.location.serviceLevel.status === 'auto' || this.location.serviceLevel.auto === true)
      },
      {
        value: 'fullService',
        translation: 'fullService',
        marked: this.location.serviceLevel && this.location.serviceLevel.status === 'fullService',
        selected:
          this.location.serviceLevel &&
          this.location.serviceLevel.status === 'fullService' &&
          this.location.serviceLevel.auto === false
      },
      {
        value: 'basicService',
        translation: 'basicService',
        marked: this.location.serviceLevel && this.location.serviceLevel.status === 'basicService',
        selected:
          this.location.serviceLevel &&
          this.location.serviceLevel.status === 'basicService' &&
          this.location.serviceLevel.auto === false
      },
      {
        value: 'noService',
        translation: 'noService',
        marked: this.location.serviceLevel && this.location.serviceLevel.status === 'noService',
        selected:
          this.location.serviceLevel &&
          this.location.serviceLevel.status === 'noService' &&
          this.location.serviceLevel.auto === false
      }
    ];

    if (this.membership && this.membership.ratePlans) {
      this.ratePlanSwitches = [];
      this.membership.ratePlans.forEach((plan) => {
        this.ratePlanSwitches.push({
          value: plan.ratePlanId,
          translation: plan.name,
          marked: this.membership.ratePlanId === plan.ratePlanId,
          selected: this.membership.ratePlanId === plan.ratePlanId
        });
      });
    }
  }
}
