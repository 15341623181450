import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PageScrollService } from 'ngx-page-scroll-core';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { LoggingService } from 'src/app/lib/services/logging.service';
import { AlarmedService } from 'src/app/lib/services/alarmed.service';
import { DeviceIconRefService } from 'src/app/lib/services/deviceIconRef.service';
import { ModelRefService } from 'src/app/lib/services/modelref.service';
import { QoeService } from 'src/app/lib/services/qoe.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { ApiService } from 'src/app/lib/services/api.service';
import { CustomerService } from 'src/app/lib/services/customer.service';
import { GeneralHelper } from 'src/app/lib/helpers/general.helper';
import { updateNodesList } from 'src/app/store/customer/customer.actions';
import {
  selectDevices,
  selectLocationInternet,
  selectLocationQoE,
  selectNodes
} from 'src/app/store/polling/polling.selector';
import { Store } from '@ngrx/store';
import { IConfigAndState, IDHCPLeases, ILocation, INode, IVapType } from 'src/app/lib/interfaces/interface';
import * as moment from 'moment';
import { selectLocationConfigState, selectLocationStateObject } from 'src/app/store/customer/customer.selectors';

@Component({
  templateUrl: './nodes.component.html',
  styleUrls: ['./nodes.component.scss']
})
export class NodesComponent implements OnInit, OnDestroy {
  allNodes: any[] = [];
  nodes: any[] = [];
  online: any[] = [];
  offline: any[] = [];
  alarms: any[] = [];
  nodeResponse: any = null;
  deviceResponse: INode[] = null;
  qoeResponse: any = null;
  vapResponse: IVapType[] = null;
  location: ILocation = null;
  capabilities: IConfigAndState['state']['capabilities'] = null;
  debouncerTimeout: any;
  subscriptions: any[] = [];
  message: string;
  nodeid: string = null;
  onboarded: string = null;
  firstScroll: boolean = true;
  permissions: any;

  // Node modals
  selectedNode: INode = null;
  hardwareInfoModalOpen: boolean = false;

  onboardingStatus: string = '';
  ui: string = '';

  firmwareModules: any[] = [];
  firmware: any = {
    modal: false
  };

  helper: GeneralHelper = new GeneralHelper();
  DHCPLeases: IDHCPLeases;

  @ViewChild('filter')
  filter: any = '';

  constructor(
    public plume: PlumeService,
    private logging: LoggingService,
    private isAlarm: AlarmedService,
    private iconRef: DeviceIconRefService,
    private modelRef: ModelRefService,
    private qoe: QoeService,
    private route: ActivatedRoute,
    private pagescroll: PageScrollService,
    private mixpanel: MixpanelService,
    private customer: CustomerService,
    private api: ApiService,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.mixpanel.storeEvent('NODES_SCREEN');

    this.init();
    this.getFirmwareModules();
    this.ui = this.plume.getUI();
  }

  init(): void {
    this.allNodes = [];
    this.onboarded = null;
    this.message = 'nodes.messageLoading';

    this.route.params.subscribe((params: any) => {
      if (params.nodeid) {
        this.nodeid = params.nodeid;
      }

      this.subscriptions.push(
        this.store.select(selectLocationInternet).subscribe((response: any) => {
          if (response) {
            this.isOnboarded(response);
          }
        })
      );

      this.subscriptions.push(
        this.store.select(selectNodes).subscribe((nodes) => {
          if (nodes) {
            this.nodeResponse = JSON.parse(JSON.stringify(nodes));
            this.debounce(() => this.populate(), 500);
          }
        })
      );

      this.subscriptions.push(
        this.store.select(selectDevices).subscribe((nodes) => {
          this.deviceResponse = JSON.parse(JSON.stringify(nodes));
          this.debounce(() => this.populate(), 500);
        })
      );

      this.subscriptions.push(
        this.store.select(selectLocationQoE).subscribe((response: any) => {
          if (response) {
            this.qoeResponse = JSON.parse(JSON.stringify(response));
            this.debounce(() => this.populate(), 500);
          } else {
            this.qoeResponse = { nodes: [] };
          }
        })
      );

      this.subscriptions.push(
        this.plume.permissions.subscribe((data: any) => {
          this.permissions = data;

          if (this.permissions.uiFeatures.wpaProtocolFromVapStatus) {
            this.customer.getVapStates$().subscribe((response) => {
              this.vapResponse = response;
              this.debounce(() => this.populate(), 500);
            });
          }
        })
      );

      this.subscriptions.push(
        this.store.select(selectLocationStateObject).subscribe(({ data }) => {
          this.location = data;
        })
      );

      this.subscriptions.push(
        this.store.select(selectLocationConfigState).subscribe((configState) => {
          if (configState) {
            this.capabilities = configState.state.capabilities;
          }
        })
      );
    });

    this.checkDHCP();
  }

  checkDHCP(): void {
    if (this.plume.cloudVersionAbove1_114()) {
      this.customer.getDHCPLeases$().subscribe((response: IDHCPLeases) => {
        if (response) {
          this.DHCPLeases = response;
          this.debounce(() => this.populate(), 500);
        }
      });
    }
  }

  debounce(fn: () => void, delay: number): void {
    clearTimeout(this.debouncerTimeout);
    this.debouncerTimeout = setTimeout(fn, delay);
  }

  nodeAdded(added: boolean): void {
    if (added) {
      this.store.dispatch(updateNodesList());
    }
  }

  populate(): void {
    if (this.nodeResponse && this.deviceResponse && this.qoeResponse && this.location) {
      const nodes = [];

      this.online = [];
      this.offline = [];
      this.alarms = [];
      this.logging.log('<nodes> get nodes: ', this.nodeResponse);
      this.logging.log('<nodes> get devices: ', this.deviceResponse);

      this.nodeResponse.forEach((node: any) => {
        node.isUprise = this.location.uprise;
        node.partnerId = this.location.partnerId;
        node.locationId = this.location.id;
        node.showPuncturingListItem = this.capabilities?.puncturing;

        if (this.DHCPLeases) {
          const lease = this.DHCPLeases.dhcpLeases.find((lRecord) => lRecord.mac === node.mac);

          if (lease) {
            node.dhcpLease = lease;
          }

          const v6Lease = this.DHCPLeases.dhcpV6Leases.find((lRecord) => lRecord.mac === node.mac);

          if (v6Lease) {
            node.dhcpV6Lease = v6Lease;
          }
        }

        const model = this.modelRef.get(node.model);
        node.icon = model.icon;
        node.name = model.name;

        node.wifiCapabilities = model.wifiCapabilities;
        node.isGateway = this.helper.isGateway(node.id, this.nodeResponse);

        if (node.connectionState === 'unavailable' || !node.connectionStateChangeAt) {
          node.connectionStateMsg = 'nodes.node.unknownConnectionInformation';
          node.connectionStateTime = '';
        } else {
          node.connectionStateMsg =
            node.connectionState === 'connected' ? 'nodes.node.onlineSince' : 'nodes.node.offlineSince';
          node.connectionStateTime = moment.utc(node.connectionStateChangeAt).local().format('L LT');
        }

        if (node.bootAt) {
          const now = moment.utc();
          const time = moment.utc(node.bootAt);
          const days = now.diff(time, 'days');
          const hours = now.subtract(days, 'days').diff(time, 'hours');
          const minutes = now.subtract(hours, 'hours').diff(time, 'minutes');

          node.booted = {
            at: moment.utc(node.bootAt).local().format('L LT'),
            mode: days > 0 ? 'Days' : hours > 0 ? 'Hours' : 'Minutes',
            days,
            hours,
            minutes
          };
        } else {
          node.booted = {
            at: '',
            mode: null,
            days: 0,
            hours: 0,
            mins: 0
          };
        }

        node.devices = [];

        this.deviceResponse.forEach((device: any) => {
          if (device.leafToRoot && device.leafToRoot[0].id === node.id) {
            if (this.qoeResponse && this.qoeResponse.devices) {
              const deviceQoe = this.qoeResponse.devices.find((qoe: any) => qoe.mac === device.mac) || {};

              device.icon = this.iconRef.get(device.icon);

              node.devices.push(this.qoe.getDeviceQoeData(deviceQoe, device));
            } else {
              node.devices.push(device);
            }
          }
        });

        const firmwareModule = this.firmwareModules.find((n: any) => node.id === n.nodeId) || null;

        if (firmwareModule && firmwareModule.modules.length) {
          node.firmwareModules = firmwareModule.modules;
        }

        const oldNode = this.allNodes.find((oldNode: any) => oldNode.id === node.id) || null;

        if (oldNode && 'deviceListOpen' in oldNode) {
          node.deviceListOpen = oldNode.deviceListOpen;
        } else {
          node.deviceListOpen = false;
        }

        if (oldNode && 'channelCongestionGraphOpen' in oldNode) {
          node.channelCongestionGraphOpen = oldNode.channelCongestionGraphOpen;
        } else {
          node.channelCongestionGraphOpen = false;
        }

        if (oldNode && 'hardwareHealthGraphOpen' in oldNode) {
          node.hardwareHealthGraphOpen = oldNode.hardwareHealthGraphOpen;
        } else {
          node.hardwareHealthGraphOpen = false;
        }

        if (oldNode && 'congestionChart' in oldNode) {
          node.congestionChart = oldNode.congestionChart;
        } else {
          node.congestionChart = {};
        }

        if (oldNode && 'bandwidthUsageGraphOpen' in oldNode) {
          node.bandwidthUsageGraphOpen = oldNode.bandwidthUsageGraphOpen;
        } else {
          node.bandwidthUsageGraphOpen = false;
        }

        if (oldNode && 'bandwidthChart' in oldNode) {
          node.bandwidthChart = oldNode.bandwidthChart;
        } else {
          node.bandwidthChart = {};
        }

        if (oldNode && 'isRename' in oldNode) {
          node.isRename = oldNode.isRename;
        } else {
          node.isRename = false;
        }

        const nodeQoe = this.qoeResponse.nodes.find((qoe: any) => qoe.id === node.id) || {};

        nodes.push(this.qoe.getNodeQoeData(nodeQoe, node));
        node.rawQoe = this.isObjectEmpty(nodeQoe) ? null : nodeQoe;

        if (node.connectionState === 'connected') {
          this.online.push(node);
        } else {
          this.offline.push(node);
        }

        if (this.vapResponse) {
          const nodeVapTypes = this.vapResponse.find((vap) => vap.nodeId === node.id) || null;

          if (nodeVapTypes) {
            nodeVapTypes.vapsState.forEach((state) => {
              switch (state.realizedOnRadios[0]) {
                case '2.4G':
                  node.wpaMode24g = this.helper.wpaMode(state.wifiNetwork.wpaMode);
                  break;
                case '5G':
                  node.wpaMode5g = this.helper.wpaMode(state.wifiNetwork.wpaMode);
                  break;
                case '5GU':
                  node.wpaMode5gu = this.helper.wpaMode(state.wifiNetwork.wpaMode);
                  break;
                case '5GL':
                  node.wpaMode5gl = this.helper.wpaMode(state.wifiNetwork.wpaMode);
                  break;
                case '6G':
                  node.wpaMode6g = this.helper.wpaMode(state.wifiNetwork.wpaMode);
                  break;
              }
            });
          }
        }

        const nodeAlarms = [];

        const lowQoeHealth = this.isAlarm.getPodQoe(node);

        if (lowQoeHealth) {
          nodeAlarms.push({
            type: 'alarmsLowQoeHealth'
          });
        }

        const lowEthernet = this.isAlarm.getEthernetUplink(node);

        if (lowEthernet) {
          nodeAlarms.push({
            type: 'alarmsLowEthernet'
          });
        }

        if (nodeAlarms.length > 0) {
          node.alarms = nodeAlarms;
          this.alarms.push(node);
        }
      });
      this.allNodes = JSON.parse(JSON.stringify(nodes));

      if (this.filter && this.filter.nativeElement.value.length) {
        this.search(this.filter.nativeElement.value);
      } else {
        this.sort(this.allNodes);
      }
    }
  }

  deleteNode(id: string): void {
    this.nodeResponse = this.nodeResponse.filter((node: any) => node.id !== id);
    this.allNodes = this.allNodes.filter((node: any) => node.id !== id);
    this.nodes = this.nodes.filter((node: any) => node.id !== id);
    this.sort(this.allNodes);
    this.store.dispatch(updateNodesList());
  }

  track(index: number, node: any): string {
    return node.id;
  }

  sort(nodes: any[]): void {
    this.nodes = [];

    const online = [];
    const offline = [];

    if (this.allNodes.length) {
      nodes.forEach((node: any) => {
        if (node.connectionState === 'connected') {
          online.push(node);
        } else {
          offline.push(node);
        }
      });

      online.sort((node: any) => (node.isGateway ? -1 : 1));
      offline.sort((node: any) => (node.isGateway ? -1 : 1));

      this.nodes = [...online, ...offline];
      this.message = '';

      if (this.nodeid && this.firstScroll) {
        this.firstScroll = false;
        this.scrollTo('#' + this.nodeid);
      }
    } else {
      this.message = 'nodes.messageNoNodes';
    }
  }

  search(value: string): void {
    this.nodes = this.allNodes.filter((node: any) => {
      if (node.nickname && node.nickname.toLowerCase().indexOf(value.toLowerCase()) >= 0) {
        return true;
      }
      if (node.defaultName && node.defaultName.toLowerCase().indexOf(value.toLowerCase()) >= 0) {
        return true;
      }
      if (node.id.toLowerCase().indexOf(value.toLowerCase()) >= 0) {
        return true;
      }
    });

    if (!this.nodes.length) {
      this.message = 'nodes.messageNoSearchResults';
    } else {
      this.sort(this.nodes);
    }
  }

  scrollTo(selector: string): void {
    setTimeout(() => {
      const view = document.getElementById('customer-view');

      this.pagescroll.scroll({
        document: window.document,
        scrollTarget: selector,
        scrollViews: [view],
        scrollOffset: 10
      });
    }, 100);
  }

  isOnboarded(response: any): void {
    try {
      const permissions = this.plume.getPermissions();

      if (permissions.uiFeatures.overrideOnboarded) {
        this.onboarded = 'complete';
      } else {
        if (['OnboardingComplete', 'PodsAdded'].includes(response.summary.onboardingStatus)) {
          this.onboarded = 'complete';
        } else {
          this.onboarded = 'uncomplete';
        }
      }
    } catch (err) {
      this.onboarded = 'uncomplete';
    }
  }

  isObjectEmpty(obj: any): boolean {
    return Object.keys(obj).length === 0;
  }

  getFirmwareModules(): void {
    this.api
      .get('/Customers/' + this.plume.customerid + '/locations/' + this.plume.locationid + '/firmware/modules')
      .subscribe((response: any) => {
        response.forEach((node: any) => {
          node.modules.sort(
            (a: any, b: any) =>
              a.name.localeCompare(b.name) || a.version.localeCompare(b.version, undefined, { numeric: true })
          );
        });

        this.firmwareModules = response;

        this.debounce(() => this.populate(), 500);
      });
  }

  unsubscribePolling(): void {
    if (this.subscriptions.length) {
      this.subscriptions.forEach((subscription: any) => subscription.unsubscribe());
    }
  }

  openModal(modal: 'hardwareInfo', node: INode) {
    switch (modal) {
      case 'hardwareInfo':
        this.hardwareInfoModalOpen = true;
        break;
    }
    this.selectedNode = node;
  }

  closeModal(modal: 'hardwareInfo') {
    switch (modal) {
      case 'hardwareInfo':
        this.hardwareInfoModalOpen = false;
        break;
    }
    this.selectedNode = null;
  }

  ngOnDestroy(): void {
    this.unsubscribePolling();

    if (this.debouncerTimeout) {
      clearTimeout(this.debouncerTimeout);
    }
  }
}
