<div class="row" id="conf-notifications">
  <div class="col-xs-12">
    <collapsiblebox [show]="expand" [collapsible]="true" (toggle)="toggleExpand()">
      <div header>{{ 'configurations.notifications.title' | translate }}</div>
      <div container *ngIf="expand">
        <div class="columns">
          <div class="column">
            <div class="row">
              <div class="key">{{ 'configurations.notifications.newDeviceConnected' | translate }}</div>
              <div class="value">
                <slider-toggle-mobile-aware
                  [options]="newDeviceConnectedItems"
                  [editable]="plume.isSomeAdminRole()"
                  (toggle)="action('newdevice', $event)"
                ></slider-toggle-mobile-aware>
              </div>
            </div>

            <div class="row">
              <div class="key">{{ 'configurations.notifications.locallyAdministeredMac' | translate }}</div>
              <div class="value">
                <slider-toggle-mobile-aware
                  [options]="locallyAdministeredMacItems"
                  [editable]="true"
                  (toggle)="action('locallyAdministeredMac', $event)"
                ></slider-toggle-mobile-aware>
              </div>
            </div>
          </div>
        </div>
      </div>
    </collapsiblebox>
  </div>
</div>
