<box>
  <div header>
    {{ 'profiler.guardCounts.title' | translate }}
  </div>
  <div container>
    <div class="group" *ngIf="!loading">
      <div class="item">
        <div class="title">
          <icon name="icon-guard-online" class="size-2"></icon>
          {{ 'profiler.guardCounts.onlineProtection' | translate }}
        </div>
        <div class="value">{{ guardCounts.onlineProtection || '--' }}</div>
      </div>
      <div class="item">
        <div class="title">
          <icon name="icon-guard-adblocking" class="size-2"></icon>
          {{ 'profiler.guardCounts.adBlocking' | translate }}
        </div>
        <div class="value">{{ guardCounts.adBlocking || '--' }}</div>
      </div>
      <div class="item">
        <div class="title">
          <icon name="icon-guard-content" class="size-2"></icon>
          {{ 'profiler.guardCounts.contentAccess' | translate }}
        </div>
        <div class="value">{{ guardCounts.contentAccess || '--' }}</div>
      </div>
      <div class="item">
        <div class="title">
          <icon name="icon-guard-iot" class="size-2"></icon>
          {{ 'profiler.guardCounts.anomaly' | translate }}
        </div>
        <div class="value">{{ guardCounts.anomaly || '--' }}</div>
      </div>
    </div>
    <div class="loading" *ngIf="loading">{{ 'loadingIndicator' | translate }}</div>
  </div>
</box>
