<ng-container *ngIf="qoeObj">
  <div
    class="points"
    [ngClass]="qoeObj.qoe.qoe > 4 ? 'good' : qoeObj.qoe.qoe < 2 ? 'poor' : qoeObj.qoe.qoe < 3 ? 'warn' : 'fair'"
  >
    <div class="point" [ngClass]="{ active: qoeObj.qoe.qoe >= 1 }"></div>
    <div class="point" [ngClass]="{ active: qoeObj.qoe.qoe >= 2 }"></div>
    <div class="point" [ngClass]="{ active: qoeObj.qoe.qoe >= 3 }"></div>
    <div class="point" [ngClass]="{ active: qoeObj.qoe.qoe >= 4 }"></div>
    <div class="point" [ngClass]="{ active: qoeObj.qoe.qoe >= 5 }"></div>
  </div>
</ng-container>
<ng-container *ngIf="!qoeObj">
  <div
    class="points"
    [ngClass]="
      (rssiObj?.rssi || rssiObj?.health?.details?.channelGain + 20) > -60
        ? 'good'
        : (rssiObj?.rssi || rssiObj?.health?.details?.channelGain + 20) < -75
        ? 'poor'
        : 'warn'
    "
  >
    <div
      class="point"
      [ngClass]="{ active: (rssiObj?.rssi || rssiObj?.health?.details?.channelGain + 20) > -99 }"
    ></div>
    <div
      class="point"
      [ngClass]="{ active: (rssiObj?.rssi || rssiObj?.health?.details?.channelGain + 20) > -75 }"
    ></div>
    <div
      class="point"
      [ngClass]="{ active: (rssiObj?.rssi || rssiObj?.health?.details?.channelGain + 20) > -65 }"
    ></div>
    <div
      class="point"
      [ngClass]="{ active: (rssiObj?.rssi || rssiObj?.health?.details?.channelGain + 20) > -55 }"
    ></div>
    <div
      class="point"
      [ngClass]="{ active: (rssiObj?.rssi || rssiObj?.health?.details?.channelGain + 20) > -45 }"
    ></div>
  </div>
</ng-container>
