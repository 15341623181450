import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export type DataSizeUnits =
  | 'b'
  | 'B'
  | 'KiB'
  | 'Kib'
  | 'MiB'
  | 'Mib'
  | 'GiB'
  | 'Gib'
  | 'TiB'
  | 'Tib'
  | 'PiB'
  | 'Pib'
  | 'KB'
  | 'Kb'
  | 'MB'
  | 'Mb'
  | 'GB'
  | 'Gb'
  | 'TB'
  | 'Tb'
  | 'PB'
  | 'Pb';

@Injectable({
  providedIn: 'root'
})
export class SpeedConverterService {
  exponentBases = { K: 1, M: 2, G: 3, T: 4, P: 5 };
  constructor(private translate: TranslateService) {}

  convertToBits(value: number, unit: DataSizeUnits): number {
    if (this.isUnitSI(unit)) {
      return value * Math.pow(10, 3 * this.getUnitExponentBase(unit)) * (this.isUnitBytes(unit) ? 8 : 1);
    }
    return value * Math.pow(2, 10 * this.getUnitExponentBase(unit)) * (this.isUnitBytes(unit) ? 8 : 1);
  }

  convertFromBits(value: number, unit: DataSizeUnits, decimalPlaces: number): number {
    let rawResult = 0;
    if (this.isUnitSI(unit)) {
      rawResult = (value * Math.pow(10, -3 * this.getUnitExponentBase(unit))) / (this.isUnitBytes(unit) ? 8 : 1);
    } else {
      rawResult = (value * Math.pow(2, -10 * this.getUnitExponentBase(unit))) / (this.isUnitBytes(unit) ? 8 : 1);
    }
    return Math.round(rawResult * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces);
  }

  bitsOptimalUnit(value: number, resultIsSI: boolean, resultIsBytes: boolean): DataSizeUnits {
    const inBytesIfRequired = resultIsBytes ? value / 8 : value;
    const rawUnitExponentBase = resultIsSI ? Math.log10(inBytesIfRequired) / 3 : Math.log2(inBytesIfRequired) / 10;
    const exponentBase = Math.floor(rawUnitExponentBase);
    const unitPrefix = this.getUnitPrefixFromExponentBase(exponentBase);
    return `${unitPrefix}${resultIsSI || !unitPrefix ? '' : 'i'}${resultIsBytes ? 'B' : 'b'}` as any;
  }

  convertToOptimalUnit(
    value: number,
    unit: DataSizeUnits,
    resultIsSI = true,
    decimalPlaces = 2,
    resultIsBytes = false
  ): { value: number; unit: DataSizeUnits } {
    const bites = this.convertToBits(value, unit);
    const optimalUnit = this.bitsOptimalUnit(bites, resultIsSI, resultIsBytes);
    return { value: this.convertFromBits(bites, optimalUnit, decimalPlaces), unit: optimalUnit };
  }

  // converts to XiB
  convertToOptimalStorageValue(
    value: number,
    unit: DataSizeUnits,
    decimalPlaces = 2
  ): { value: number; unit: DataSizeUnits } {
    return this.convertToOptimalUnit(value, unit, false, decimalPlaces, true);
  }

  // converts to Xbps
  convertToOptimalSpeedValue(
    value: number,
    unit: DataSizeUnits,
    decimalPlaces = 2
  ): { value: number; unit: DataSizeUnits } {
    return this.convertToOptimalUnit(value, unit, true, decimalPlaces, false);
  }

  formatValue(data: { value: number; unit: DataSizeUnits }, formatOptions?: 'perSeconds' | 'short'): string {
    let res = `${data.value.toLocaleString(this.translate.currentLang)} ${this.translate.instant(
      'units.' +
        ((formatOptions === 'perSeconds' || formatOptions === 'short') && data.unit.length === 1
          ? data.unit + 'Short'
          : data.unit)
    )}`;
    if (formatOptions === 'perSeconds') {
      res += 'ps';
    }
    return res;
  }

  private getUnitExponentBase(unit: DataSizeUnits): number {
    const unitPrefixText = unit.charAt(0);
    return this.exponentBases[unitPrefixText] ?? 0;
  }

  private getUnitPrefixFromExponentBase(exponentBase: number): string {
    return Object.entries(this.exponentBases).find(([, value]) => value === exponentBase)?.[0] ?? '';
  }

  private isUnitBytes(unit: DataSizeUnits): boolean {
    return unit.endsWith('B');
  }

  private isUnitSI(unit: DataSizeUnits): boolean {
    return unit.charAt(1) !== 'i';
  }
}
