<ng-container *ngFor="let group of calculations.groupedDatasets">
  <ng-container *ngFor="let dataset of group.dataSets">
    <svg:g
      *ngIf="dataset.type === 'line'"
      class="container"
      [attr.stroke]="dataset.color"
      [style.color]="dataset.hoverColor"
    >
      <svg:path
        class="linePath"
        [attr.d]="getChartLine(dataset)"
        fill="none"
      ></svg:path>
      <svg:g
        flm-graph-line
        [dataSet]="dataset"
        (pointHover)="pointHover.emit({ point: $event, dataSet: dataset })"
      ></svg:g>
    </svg:g>
  </ng-container>
</ng-container>
