import { ConnectedPosition, ScrollStrategyOptions } from '@angular/cdk/overlay';
import { Component, OnChanges, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'filterrow',
  templateUrl: './filterrow.component.html',
  styleUrls: ['./filterrow.component.scss']
})
export class FilterrowComponent implements OnChanges {
  @Input()
  filter: any;

  @Input()
  objects: any;

  @Output()
  update: any = new EventEmitter<string>();

  @Output()
  expand: any = new EventEmitter<any>();
  scrollStrategy = this.sso.close();
  submenuPosition: ConnectedPosition[] = [
    { originX: 'start', overlayX: 'end', originY: 'top', overlayY: 'top', offsetX: -27, offsetY: -9 },
    { originX: 'start', overlayX: 'end', originY: 'bottom', overlayY: 'bottom', offsetX: -27 },
    { originX: 'start', overlayX: 'start', originY: 'top', overlayY: 'top', offsetX: -27, offsetY: -9 }
  ];
  constructor(private sso: ScrollStrategyOptions) {}

  ngOnChanges(): void {
    setTimeout(() => {
      this.getCounts();
    }, 50);
  }

  getCounts(): void {
    const res = this.filter.key.split('.');
    let val: any;

    this.filter.filterStates.forEach((filter: any) => {
      filter['count'] = 0;
    });

    this.objects.forEach((device: any) => {
      if (res.length === 1) {
        val = device[res[0]];
      } else if (res.length === 2) {
        val = device[res[0]][res[1]];
      } else if (res.length === 3) {
        val = device[res[0]][res[1]][res[2]];
      }

      let otherCount = 0;

      if (this.filter && this.filter.filterStates) {
        this.filter.filterStates.forEach((filter: any) => {
          if (filter.value !== -1 && (filter.value === null || val === filter.value)) {
            otherCount++;
            filter.count++;
          }

          if (filter.value === -1 && !otherCount) {
            filter.count++;
          }
        });
      }
    });
  }

  getStateName(): string {
    const foundFilter = this.filter.filterStates.find((filter: any) => filter.value === this.filter.state);
    return foundFilter.translation;
  }

  setState(event: any, state: any): void {
    event.stopPropagation();
    this.filter.state = state.value;
    this.filter.stateTranslation = state.translation;
    this.update.emit();
  }

  select(event: any): void {
    event.stopPropagation();
    this.filter.expand = !this.filter.expand;
    this.expand.emit(this.filter);
  }
}
