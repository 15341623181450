<ng-container *ngIf="event && event.details">
  <div class="details">
    <div class="header">
      {{ 'timelines.logpull.details.title' | translate }}
      <icon name="icon-close" (click)="closeEvent()"></icon>
    </div>
    <div class="column">
      <ng-container *ngFor="let item of event.details | keyvalue">
        <div class="row">
          <div class="key">{{ 'timelines.logpull.' + item.key | translate }}</div>
          <div class="value">{{ item.value }}</div>
        </div>
      </ng-container>
    </div>
    <div class="buttons">
      <div class="row">
        <button
          *ngIf="event.link && permissions?.uiFeatures.downloadLogpull"
          class="action medium"
          (click)="downloadLogpull(event.link)"
        >
          {{ 'timelines.logpull.details.download' | translate }}
        </button>
        <button *ngIf="event.copyLink" class="action medium" (click)="copyLogpullLink(event.copyLink)">
          {{ 'timelines.logpull.details.copy' | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-container>
