<collapsiblebox
  mode="bare"
  [show]="open"
  [collapsible]="true"
  toggleSide="right"
  (toggle)="toggleState()"
  [scrollOnOpen]="true"
>
  <div class="title" header>
    {{ 'health.networkInformation.wan.saturation' | translate }}
    <icon name="icon-info" class="skip-toggle" (click)="showInfo()"></icon>
  </div>

  <div class="content" *ngIf="open" container>
    <div class="controls">
      <div class="max" *ngIf="maxDownload !== null && maxUpload !== null">
        <div class="download">
          {{ 'health.networkInformation.wan.maxDownload' | translate }}: <span>{{ maxDownload }}%</span>
        </div>
        <div class="upload">
          {{ 'health.networkInformation.wan.maxUpload' | translate }}: <span>{{ maxUpload }}%</span>
        </div>
      </div>
      <div class="last" *ngIf="lastDownload !== null && lastUpload !== null">
        <div class="download">
          {{ 'health.networkInformation.wan.lastDownload' | translate }}: <span>{{ lastDownload }}%</span>
        </div>
        <div class="upload">
          {{ 'health.networkInformation.wan.lastUpload' | translate }}: <span>{{ lastUpload }}%</span>
        </div>
      </div>
      <slidetoggler [options]="chartModes" (toggle)="toggleChartMode($event)"></slidetoggler>
    </div>
    <nodechart
      *ngIf="!noData"
      [series]="[]"
      [data]="chart.data"
      [scale]="{ range: [0, 100], symbol: '%' }"
      [period]="chart.period"
      [isFilled]="true"
      [margins]="{ top: 25, right: 25, left: 40, bottom: 30 }"
      (domains)="calculateMinMax($event)"
    ></nodechart>
    <div class="no-data" *ngIf="noData">
      {{ 'health.networkInformation.wan.noData' | translate }}
    </div>
  </div>
</collapsiblebox>
