<spacer>
  <h1>{{ 'traffic.title' | translate }}</h1>
  <icon name="icon-info" class="size-1_5" tooltip="{{ 'traffic.tooltip' | translate }}"></icon>
</spacer>
<div class="container-fluid">
  <div class="tabs">
    <div
      class="tab"
      [class.selected]="mode === 'configuration'"
      (click)="handle('configuration')"
      *ngIf="appPrioCapable"
    >
      {{ 'traffic.configuration' | translate }}
    </div>
    <div class="tab" [class.selected]="mode === 'monitoring'" (click)="handle('monitoring')" *ngIf="appPrioCapable">
      {{ 'traffic.monitoring' | translate }}
    </div>
    <div
      class="tab"
      [class.selected]="mode === 'pieMonitoring'"
      (click)="handle('pieMonitoring')"
      *ngIf="appQoeCapable"
    >
      {{ 'traffic.qoeMonitoring' | translate }}
    </div>
  </div>
  <ng-container [ngSwitch]="mode">
    <ng-container *ngIf="appPrioCapable">
      <traffic-configuration *ngSwitchCase="'configuration'"></traffic-configuration>
    </ng-container>
    <ng-container *ngIf="appPrioCapable">
      <traffic-monitoring *ngSwitchCase="'monitoring'"></traffic-monitoring>
    </ng-container>
    <ng-container *ngIf="appQoeCapable">
      <traffic-pie-monitor *ngSwitchCase="'pieMonitoring'"></traffic-pie-monitor>
    </ng-container>
  </ng-container>
</div>
