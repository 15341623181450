import { Pipe, PipeTransform } from '@angular/core';
import { PlumeService } from '../services/plume.service';

@Pipe({
  name: 'ipv6'
})
export class Ipv6Pipe implements PipeTransform {
  constructor(private plume: PlumeService) {}

  transform(value?: string): string {
    if (this.plume.hidePersonalDetails() && value) {
      const ipv6Parts = value.split(':');
      const emptyPosition = ipv6Parts.indexOf('');
      while (ipv6Parts.length < 8) {
        ipv6Parts.splice(emptyPosition, 0, '');
      }
      for (let i = 0; i <= 2; i++) {
        ipv6Parts[i] = '****';
      }

      return ipv6Parts
        .reduce(
          (acc, val) => ({
            ipShorteningActive: acc.ipShorteningActive || !val,
            ipParts: !val && acc.ipShorteningActive ? acc.ipParts : [...acc.ipParts, val]
          }),
          { ipShorteningActive: false, ipParts: [] as string[] }
        )
        .ipParts.join(':');
    }
    return value?.toUpperCase();
  }
}
