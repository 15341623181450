import { Component, Input, ElementRef, OnChanges, AfterViewInit, OnDestroy } from '@angular/core';
import { D3Service } from 'src/app/lib/d3/service';
import { QoethroughputChart } from 'src/app/lib/d3/models/charts/qoethroughput.chart';
import { Tick } from 'src/app/lib/d3/models/objects/tick';
import { Line } from 'src/app/lib/d3/models/objects/line';
import { Point } from 'src/app/lib/d3/models/objects/point';
import { Series } from 'src/app/lib/d3/models/objects/series';
import { Dot } from 'src/app/lib/d3/models/objects/dot';
import * as erdm from 'element-resize-detector';

@Component({
  selector: 'qoethroughputchart',
  templateUrl: './qoethroughputchart.component.html',
  styleUrls: ['./qoethroughputchart.component.scss']
})
export class QoethroughputChartVisualComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input()
  data: any;

  @Input()
  period: number = 1;

  chart: QoethroughputChart;
  lines: any[];
  xAxis: Tick[];
  yAxis: Tick[];
  tooltips: any[];
  width: number;
  height: number;
  margins: any = {
    top: 10,
    right: 20,
    bottom: 30,
    left: 60
  };

  erd: any = erdm({ strategy: 'scroll' });

  constructor(private d3: D3Service, private host: ElementRef) {
    this.chart = this.d3.generate('qoethroughputchart');
  }

  ngAfterViewInit(): void {
    this.erd.listenTo(this.host.nativeElement, () => {
      this.render();
    });
  }

  ngOnChanges(): void {
    this.render();
  }

  render(): void {
    this.lines = this.chart.update(
      this.convert(this.data),
      this.host.nativeElement.clientWidth,
      this.host.nativeElement.clientHeight,
      this.margins,
      this.period
    );

    this.xAxis = this.chart.xAxis();
    this.yAxis = this.chart.yAxis();
    this.width = this.chart.calculateWidth();
    this.height = this.chart.calculateHeight();

    this.tooltips = this.generateTooltips();
  }

  generateTooltips(): any[] {
    const tooltips = [];
    const temp = {};

    this.lines.forEach((line: Line) => {
      line.data.forEach((data: any) => {
        if (data.value) {
          const date = this.chart.roundTime(new Date(data.label)).toString();
          const timestamp = new Date(date).valueOf();
          const startDate = Date.now() - this.period * 24 * 60 * 60 * 1000;

          if (timestamp >= startDate && timestamp <= Date.now()) {
            if (date in temp) {
              temp[date].push({
                series: line.series.translation,
                value: this.round(data.value, 4),
                color: line.series.color
              });
            } else {
              temp[date] = [
                {
                  series: line.series.translation,
                  value: this.round(data.value, 4),
                  color: line.series.color
                }
              ];
            }
          }
        }
      });
    });

    const width = this.width / (this.period === 1 ? 24 * 4 : 24 * 7);

    Object.keys(temp)
      .sort((a: any, b: any) => new Date(b).getTime() - new Date(a).getTime())
      .forEach((key: any) => {
        const left = this.chart.scaleValue('x', new Date(key)) - width / 2;
        const leftTransform = '0';
        const leftPosition = left < this.width / 2 ? 10 : 'auto';
        const rightPosition = left >= this.width / 2 ? 10 : 'auto';

        tooltips.push({
          date: new Date(key),
          data: temp[key],
          show: false,
          region: {
            'width.px': width,
            'left.px': left,
            'height.px': this.chart.calculateHeight() + 35
          },
          style: {
            'top.px': this.chart.calculateHeight() / 2,
            'left.px': leftPosition,
            'right.px': rightPosition,
            transform: `translate(${leftTransform}, -50%)`
          }
        });
      });

    return tooltips;
  }

  showTooltip(data: any, mode: boolean): void {
    data.show = mode;

    this.lines.forEach((line: Line) => {
      line.dots.forEach((dot: Dot) => {
        if (new Date(dot.label).valueOf() === new Date(data.date).valueOf()) {
          dot.show = mode;
        }
      });
    });
  }

  convert(data: any): Line[] {
    const lines = [];

    data.forEach((chart: any, index: number) => {
      let series = null;
      chart.forEach((chartdata: any) => {
        if (chartdata.state) {

          if (chartdata.tooltip === 'actualThroughput'){
            series = new Series('rgb(0,0,255)', 'actualThroughput', 'qoe.actualThroughput');
          }
          if (chartdata.tooltip === 'estimatedThroughput'){
            series = new Series('rgb(0,0,255)', 'estimatedThroughput', 'qoe.estimatedThroughput');
          }

          if (chartdata.tooltip === 'speedTestLatency'){
            series = new Series('rgb(0,0,255)', 'speedTestLatency', 'qoe.speedTestLatency');
          }
          if (index === 0 && chartdata.tooltip === 'averagePredictedWifiSpeed') {
            series = new Series('rgb(0,0,255)', 'averagePredictedWifiSpeed', 'qoe.charts.averagePredictedWifiSpeed');
          }

          if (index === 1 && chartdata.tooltip === 'minPredictedWifiSpeed') {
            series = new Series('rgb(0,255,0)', 'minPredictedWifiSpeed', 'qoe.charts.minPredictedWifiSpeed');
          }
        }
      });

      const points = [];

      chart.forEach((point: any) => {
        points.push(new Point(point.time, point.state !== null ? parseFloat(point.state) : null));
      });

      lines[index] = new Line(series, 'left', points);
    });

    return lines;
  }

  round(value: number, decimalPlaces: number = 0): number {
    const multiplier = Math.pow(10, decimalPlaces);
    return Math.round(value * multiplier + Number.EPSILON) / multiplier;
  }

  ngOnDestroy(): void {
    this.erd.uninstall(this.host.nativeElement);
  }
}
