import { Injectable } from '@angular/core';
import { LoggingService } from './logging.service';
import { ModelRefService } from './modelref.service';
import { PlumeService } from './plume.service';
import { INode } from '../interfaces/interface';
import * as moment from 'moment';

@Injectable()
export class AlarmedService {
  constructor(private logging: LoggingService, private modelRef: ModelRefService, private plume: PlumeService) {}

  getInconsistentSpeedAlarm(msgSummary: any): boolean {
    const avgISPSpeed = msgSummary.twentyFourHours.broadbandDownloadSpeed.average;
    const stDev = msgSummary.twentyFourHours.broadbandDownloadSpeed.standardDeviation;

    this.logging.info('getInconsistentSpeedAlarm -------------------------', msgSummary);
    this.logging.info('        avgISPSpeed : ', avgISPSpeed);
    this.logging.info('        stDev       : ', stDev);

    const alarmed = stDev > avgISPSpeed * 0.34;

    this.logging.info('        alarmed     : ', alarmed);

    return alarmed;
  }

  getLowExtenderSpeed(mNode: any, msgSummary: any, node: any): boolean {
    let podSpeed: number;
    let alarmed: boolean;

    // if there is a speed test reading with 24 hours, use that for podSpeed
    // if not then use average
    const utcEnd = moment(moment()).subtract(1, 'day');
    const isBefore = node && node.speedTest ? moment(utcEnd).isBefore(node.speedTest.endedAt) : false;

    this.logging.info('getLowExtenderSpeed -------------------------', mNode, node, msgSummary);

    if (isBefore) {
      podSpeed = node.speedTest.download;
      this.logging.info('Speed test within last day', node.speedTest.endedAt);
    } else {
      if (mNode.wifiDownloadSpeed && mNode.wifiDownloadSpeed.average) {
        podSpeed = mNode.wifiDownloadSpeed.average;
        this.logging.info(
          'No recent speed test , using average',
          node && node.speedTest ? node.speedTest.endedAt : null
        );
      } else {
        this.logging.warn('Attempt to speed test with no last or average.');
      }
    }

    if (podSpeed) {
      this.logging.info('    podSpeed= ', podSpeed);
    }

    const model = node ? this.modelRef.get(node.model) : null;
    const broadbandEfficiency = mNode.broadbandEfficiency;
    const ispAvg = msgSummary.twentyFourHours.broadbandDownloadSpeed.average;

    this.logging.info('    broadbandEfficiency=', broadbandEfficiency);
    this.logging.info('    modelType=', model ? model.name : '');
    this.logging.info('    ispAvg=', ispAvg);

    alarmed = false;

    if (model) {
      if (model.name === 'Plume Pod') {
        alarmed = podSpeed < 30 && broadbandEfficiency < 0.5;
      } else {
        alarmed = podSpeed < 125 && broadbandEfficiency < 0.5;
      }
    }

    this.logging.info('    alarmed=', alarmed);

    return alarmed;
  }

  getBbeAlarm(mNode: any, msgSummary: any, node: any): boolean {
    let podSpeed: number;
    let alarmed: boolean;

    if (!mNode.broadbandEfficiency) {
      return false; // GW nodes do not have a broadbandEfficiency
    }
    // if there is a speed test reading with 24 hours, use that for podSpeed
    // if not then use average
    const utcEnd = moment(moment()).subtract(1, 'day');
    const isBefore = node && node.speedTest ? moment(utcEnd).isBefore(node.speedTest.endedAt) : false;

    this.logging.info('getBbeAlarm -------------------------', mNode, node, msgSummary);

    if (isBefore) {
      podSpeed = node.speedTest.download;
      this.logging.info('Speed test within last day', node.speedTest.endedAt);
    } else {
      if (mNode.wifiDownloadSpeed && mNode.wifiDownloadSpeed.average) {
        podSpeed = mNode.wifiDownloadSpeed.average;
        this.logging.info(
          'No recent speed test , using average',
          node && node.speedTest ? node.speedTest.endedAt : null
        );
      } else {
        this.logging.warn('Attempt to speed test with no last or average.');
      }
    }

    if (podSpeed) {
      this.logging.info('    podSpeed= ', podSpeed);
    }

    const model = node ? this.modelRef.get(node.model) : null;
    const broadbandEfficiency = mNode.broadbandEfficiency;
    const ispAvg = msgSummary.twentyFourHours.broadbandDownloadSpeed.average;

    this.logging.info('    broadbandEfficiency=', broadbandEfficiency);
    this.logging.info('    modelType=', model ? model.name : '');
    this.logging.info('    ispAvg=', ispAvg);

    alarmed = false;

    if (model) {
      if (model.name === 'Plume Pod') {
        if (ispAvg > 80) {
          alarmed = podSpeed < 70;
        } else {
          alarmed = broadbandEfficiency < 0.65;
        }
      } else {
        if (ispAvg > 300) {
          alarmed = podSpeed < 200;
        } else {
          alarmed = broadbandEfficiency < 0.65;
        }
      }
    }

    this.logging.info('    alarmed=', alarmed);

    return alarmed;
  }

  getDeviceQoe(device: any): boolean {
    if (device && device.health && device.health.status) {
      const permissions = this.plume.getPermissions();
      let alarmed = false;

      device.alerts.forEach((alert: any) => {
        if (
          alert.type === 'poorHealth' &&
          !alert.snoozed &&
          (device.health.status === 'poor' || permissions.qoeAlarms === true)
        ) {
          alarmed = true;
        }
      });

      this.logging.info('DEVICE   alerts: ', device.name, device.alerts, device.health.status, alarmed);

      return alarmed;
    } else {
      this.logging.debug('No Device Health found (rebooting or offline?)', device);

      return false;
    }
  }

  getPodQoe(node: INode): boolean {
    if (node && node.health && node.health.status) {
      let alarmed = false;

      node.alerts.forEach((alert: any) => {
        if (alert.type === 'poorHealth' && !alert.snoozed) {
          alarmed = true;
        }
      });

      this.logging.info('NODE     alerts: ', node.name, node.alerts, alarmed);

      return alarmed;
    } else {
      this.logging.warn('No Pod Health found (rebooting or offline?)', node);

      return false;
    }
  }

  getNetworkOffline(data: any): boolean {
    if (data.summary.gatewayConnectionState === 'connected') {
      return false;
    } else {
      return true;
    }
  }

  getStability(data: any, mode: string): boolean {
    if (mode === 'high') {
      const total = data.eaa ? data.total - data.eaa : data.total;

      if (total > 8) {
        return true;
      } else {
        return false;
      }
    }

    if (mode === 'failed') {
      if (data.failed > 4) {
        return true;
      } else {
        return false;
      }
    }

    return false;
  }

  getCoverage(data: any): boolean {
    if (data.alarmIds.length) {
      return true;
    } else {
      return false;
    }
  }

  getCoverageReport(data: any): boolean {
    const history = [];

    for (let i = 1; i <= 7; i++) {
      history.push({
        day: moment.utc().subtract(i, 'days').format('dd'),
        timestamp: moment.utc().subtract(i, 'days').format('YYYY-MM-DD'),
        alarm: null
      });
    }

    data.deviceAlarm.forEach((alarm: any) => {
      alarm.timestamp = moment.utc(alarm.timestamp).format('YYYY-MM-DD');
    });

    data.deviceAlarm.forEach((alarm: any) => {
      history.forEach((day, i) => {
        if (day.timestamp === alarm.timestamp) {
          history[i].alarm = alarm.value;
        }
      });
    });

    let alarmed = false;
    this.logging.log('coverage history=', history);

    history.splice(0, 1).forEach((day: any) => {
      if (day.alarm) {
        alarmed = true;
      }
    });

    this.logging.log('<alarmedService.getCoverageReport> ' + alarmed + '    history = ', history);

    return alarmed;
  }

  getHighEnvironmentCongestion(data: any): any {
    const UTILIZATION_ALARM = {
      '2.4G': 70,
      '5G': 50,
      '5GU': 50,
      '5GL': 50,
      '6G': 50
    };

    let alarmed = false;

    for (const band of Object.keys(data)) {
      if (band !== 'statsDateRange') {
        data[band] = [data[band].reduce((previous, current) => (previous.ts > current.ts ? previous : current), 0)];

        for (const value of data[band]) {
          if (parseFloat(value.avgutilization) > UTILIZATION_ALARM[band]) {
            value.alarmUtilization = true;
            alarmed = true;
          } else {
            value.alarmUtilization = false;
          }
        }
      }
    }

    if (alarmed) {
      return data;
    } else {
      return false;
    }
  }

  getNeighbors(data: any[]): boolean {
    if (data && data.length) {
      return true;
    } else {
      return false;
    }
  }

  getEthernetUplink(node: INode): boolean | string {
    let data = null;

    node.linkStates?.forEach((linkState) => {
      if (linkState.isUplink && linkState.linkSpeed < 1000) {
        data = {
          id: node.id,
          name: node.nickname || node.defaultName,
          model: node.model,
          ...linkState
        };
      }
    });

    if (data) {
      return data;
    } else {
      return false;
    }
  }
}
