import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { TroubleshootingService } from 'src/app/lib/services/troubleshooting.service';
import { LoggingService } from 'src/app/lib/services/logging.service';
import { Bar } from 'src/app/lib/d3/models/objects/bar';
import { MessageService } from 'src/app/lib/services/message.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import * as moment from 'moment';
import { Store } from '@ngrx/store';
import { selectNodeIcon } from 'src/app/store/lte/lte.selectors';

@Component({
  selector: 'inconsistentspeedalarm',
  templateUrl: './inconsistentspeedalarm.component.html',
  styleUrls: ['./inconsistentspeedalarm.component.scss']
})
export class InconsistentspeedalarmComponent implements OnInit, OnDestroy {
  @Input()
  alarm: any;
  @Input()
  selected: any;
  @Input()
  nodes: any;
  @Output()
  section: EventEmitter<string> = new EventEmitter<string>();

  loading: boolean = false;
  messageSubscription: any;

  stats: any = [
    {
      title: 'Chronic Coverage Alarm',
      subtitle: 'GW Only Customers',
      percent: 45,
      status: 'orange'
    },
    {
      title: 'Chronic Coverage Alarm',
      subtitle: 'Pod Customers',
      percent: 6,
      status: 'black'
    },
    {
      title: 'Alarm Reduction',
      subtitle: 'From Plume Cloud Intelligence',
      percent: 87,
      status: 'green'
    }
  ];
  barsPod: Bar[] = [];

  optimized: string = '';
  optimizeState: string = 'init';
  chartReady: boolean = false;
  rebootState: string = 'init';
  troubleshoot: boolean = false;
  expand: boolean = false;
  gateWayIcon$ = this.store.pipe(selectNodeIcon(''));

  constructor(
    private troubleShoot: TroubleshootingService,
    private logging: LoggingService,
    private message: MessageService,
    private mixpanel: MixpanelService,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.logging.info('<InconsistentspeedalarmComponent> ', this.alarm);
    this.messageHandler();
    this.getTestSeries();
  }

  messageHandler(): void {
    this.messageSubscription = this.message.getMessage().subscribe((message: any) => {
      this.logging.log(' RECV MESSAGE inconsistentspeedalarm : ', message);
      switch (message.source) {
        case 'log': {
          this.endFlow();
          break;
        }
        case 'reboot': {
          if (message.success) {
            this.rebootState = 'success';
            this.optimized = message.optimized;
          } else {
            this.rebootState = 'optimize';
            this.optimized = message.optimized;
          }
          break;
        }
        case 'optimize': {
          if (message.success) {
            this.rebootState = 'success';
          } else {
            this.rebootState = 'fail';
          }
          break;
        }
        case 'finish': {
          this.endFlow();
          break;
        }
      }
    });
  }

  startTroubleshooting(): void {
    this.troubleshoot = true;

    this.mixpanel.storeEvent('HEALTH_DIAGNOSTIC', {
      ACTION: 'Start Troubleshooting',
      ALARM: 'ISP Speed Warning'
    });
  }

  getISPresults(series: any): void {
    series.week.chart.forEach((data: any) => {
      const label = moment(data.timestamp).format('ddd');
      const value = Math.round(data.download);
      this.barsPod.push(new Bar(label, value));
    });

    this.chartReady = true;
  }

  getTestSeries(): void {
    this.troubleShoot.checkSpeedSeries().subscribe((response: any) => {
      this.getISPresults(response);
    });
  }

  select(alarm: any): void {
    this.logging.debug('<select> ', alarm);
  }

  endFlow(): void {
    this.optimized = '';
    this.optimizeState = 'init';
    this.chartReady = false;
    this.rebootState = 'init';
    this.troubleshoot = false;
    this.expand = false;
  }

  ngOnDestroy(): void {
    if (this.messageSubscription) {
      this.messageSubscription.unsubscribe();
    }
  }
}
