<ng-container *ngFor="let data of dataSet?.data">
  <svg:g
    class="container"
    (mouseenter)="hover(data)"
    (mouseleave)="hover(undefined)"
  >
    <svg:rect
      class="line"
      [attr.x]="data.xPos - 4"
      [attr.y]="data.yPos"
      width="8"
      [attr.height]="
        calculations.elementSize.height -
        calculations.elementSize.bottom -
        data.yPos
      "
      rx="4"
    />
    <svg:line
      class="verticalHoverLine"
      [attr.x1]="data.xPos"
      [attr.x2]="data.xPos"
      [attr.y1]="
        calculations.elementSize.height - calculations.elementSize.bottom
      "
      [attr.y2]="calculations.elementSize.top"
    />
  </svg:g>
</ng-container>
