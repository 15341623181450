import { scaleBand, scaleLinear } from 'd3';
import { Bar } from '../objects/bar';
import { Tick } from '../objects/tick';
import { MultiBar } from '../objects/multibar';

export class MultibarChart {
  private data: any[];
  private bars: Bar[];
  private width: number;
  private height: number;
  private xScale: any;
  private yScale: any;
  private margins: any = {
    top: 100,
    right: 80,
    bottom: 60,
    left: 80
  };

  constructor() {}

  calculateWidth(): number {
    return this.width - this.margins.left - this.margins.right;
  }

  calculateHeight(): number {
    return this.height - this.margins.top - this.margins.bottom;
  }

  prepareScales(): void {
    this.xScale = scaleBand().range([0, this.calculateWidth()]).padding(0.5);
    this.yScale = scaleLinear().range([this.calculateHeight(), 0]);
  }

  prepareDomains(): void {
    this.xScale.domain(this.data.map((d: any) => d.label));
    this.yScale
      .domain([
        Math.min(0, ...this.data.map((d: any) => Math.min(...(Object.values(d.values) as any)))),
        Math.max(0, ...this.data.map((d: any) => Math.max(...(Object.values(d.values) as any))))
      ])
      .nice(5);
  }

  calculateBars(): void {
    this.bars = [];

    this.data.forEach((multibar: MultiBar) => {
      const keys = Object.keys(multibar.values);

      keys.forEach((key: string, index: number, arr: any[]) => {
        const bar = new Bar(multibar.label, multibar.values[key]);
        bar.class = key;
        bar.x = this.xScale(multibar.label) + (this.xScale.bandwidth() / arr.length) * index;
        bar.y = this.yScale(multibar.values[key]);
        bar.width = (this.xScale.bandwidth() * 3) / 4;
        bar.height = this.calculateHeight() - this.yScale(multibar.values[key]);
        bar.rx = 3;
        bar.ry = 3;

        this.bars.push(bar);
      });
    });
  }

  xAxis(): Tick[] {
    return this.xScale
      .domain()
      .map(
        (tick: any) =>
          new Tick(
            'xaxis',
            tick,
            'translate(' +
              (this.xScale(tick) + this.xScale.bandwidth() / 2) +
              ',' +
              (this.calculateHeight() + 20) +
              ')',
            { y: 10, transform: 'translate(3,-15),rotate(45)' },
            false
          )
      );
  }

  yAxis(): Tick[] {
    return this.yScale
      .ticks(5)
      .map(
        (tick: any) =>
          new Tick('yaxis', tick * 100 + '%', 'translate(0, ' + this.yScale(tick) + ')', { x: -40, dy: 0.32 }, false)
      );
  }

  yLabel(label: string): Tick[] {
    return label
      .split('|')
      .map(
        (text: string, index: number) =>
          new Tick(
            'yaxis',
            text,
            'translate(0, ' + (this.yScale(0) + this.calculateHeight() / 5) + ')',
            { x: -40, dy: index * 1.1 + 0.32 },
            false
          )
      );
  }

  update(data: any[], width?: number, height?: number, margins?: any): Bar[] {
    this.data = data;

    if (width) {
      this.width = width;
    }
    if (height) {
      this.height = height;
    }
    if (margins) {
      this.margins = margins;
    }

    this.prepareScales();
    this.prepareDomains();

    this.calculateBars();

    return this.bars;
  }
}
