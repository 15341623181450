import { Component, EventEmitter, Output } from '@angular/core';
import { GraphCalculationsService } from '../graph-calculations/graph-calculations.service';
import {
  DataWithPosition,
  GraphDataSet,
  GraphDataSetComputed,
} from '../graph.interface';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[flm-graph-line-container]',
  templateUrl: './graph-line-container.component.html',
  styleUrls: ['./graph-line-container.component.scss'],
})
export class GraphLineContainerComponent {
  constructor(public calculations: GraphCalculationsService) {}
  @Output() pointHover = new EventEmitter<{
    point: DataWithPosition | undefined;
    dataSet: GraphDataSetComputed;
  }>();
  getChartLine(dataSetUndetermined: GraphDataSet): string {
    if (!('xConvertCoefficient' in dataSetUndetermined)) {
      return '';
    }
    const dataSet = dataSetUndetermined as GraphDataSetComputed;

    if (
      dataSet.data.some(
        (data) => !Number.isFinite(data.yPos) || Number.isNaN(data.yPos)
      )
    ) {
      return '';
    }

    return [
      ...dataSet.data
        .slice(0, 1)
        .map((point) => `M${point.xPos},${point.yPos}`),
      ...dataSet.data.slice(1).map((point) => `L${point.xPos},${point.yPos}`),
    ].join(' ');
  }
}
