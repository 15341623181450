export const languages = [
  { id: 'en', translation: 'english' },
  { id: 'de', translation: 'german' },
  { id: 'it', translation: 'italian' },
  { id: 'es', translation: 'spanish' },
  { id: 'ja', translation: 'japanese' },
  { id: 'pt', translation: 'portuguese' },
  { id: 'fr', translation: 'french' },
  { id: 'fr-CA', translation: 'french-canada' },
  { id: 'et', translation: 'estonian' }
];
