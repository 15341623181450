<div class="mainRow" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
  <icon name="icon-arrow-down" class="openArrow rotate90 size-0_7" [class.active]="filter.expand"></icon>
  <div class="text">{{ filter.translation | translate }}</div>
  <icon class="check" name="icon-check" [class.selected]="filter.state !== null"></icon>
</div>
<div class="state" *ngIf="filter.state !== null">{{ getStateName() | translate }}</div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="filter.expand"
  [cdkConnectedOverlayScrollStrategy]="scrollStrategy"
  [cdkConnectedOverlayPositions]="submenuPosition"
>
  <div class="dropdown listContainer">
    <div class="dropdown list">
      <ul>
        <li
          class="select"
          *ngFor="let state of filter.filterStates"
          (click)="setState($event, state)"
        >
          <span class="text">{{ state.translation | translate }} ({{ state.count }})</span>
          <icon class="check" name="icon-check" [class.selected]="state.value === filter.state"></icon>
        </li>
      </ul>
    </div>
  </div>
</ng-template>
