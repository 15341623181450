import { Injectable } from '@angular/core';
import { ICatalog, IModelRef } from 'src/app/lib/interfaces/interface';
import { environment } from 'src/environments/environment';
import { ThemeService } from './theme.service';

@Injectable()
export class ModelRefService {
  constructor(private theme: ThemeService) {}
  catalog: ICatalog = {};

  get(model: string): IModelRef {
    const skin = this.theme.getActiveTheme();

    const defaultIconObj = {
      name: model,
      icon: `/assets/icons/icon-unknown-${skin}.svg`
    };

    if (model && this.catalog[model]) {
      return {
        ...this.catalog[model],
        icon: environment.metasaurus.url + '/' + this.catalog[model].icons[skin]
      };
    } else {
      // 5g radio tower
      if (model === 'radiotower') {
        return { name: model, icon: `/assets/icons/icon-radio-tower-${skin}.svg` };
      }

      // Thread router
      if (model === 'threadRouter') {
        return { name: model, icon: `/assets/icons/icon-thread-router-${skin}.svg` };
      }

      return defaultIconObj;
    }
  }

  getGateways(): string[] {
    return [
      'VFSC_VOX25_ES',
      'VFSC_H500S_ES',
      'VFSC_ONTL3_ES',
      'VFSC_VOX30_IT',
      'HH2000',
      'HH3000',
      'HH4000',
      'HH4000-E',
      'TG1682G',
      'DPC3941T',
      'DPC3941',
      'DPC3939',
      'DPC3939T',
      'TG3482G',
      'CGM4140COM',
      'CGM4981COM',
      'TG2492LG',
      'TG3492LG',
      'TG3492MT',
      'TG3452',
      'DG3450',
      'CH7465CE',
      'CH7465LG',
      'CGA4233VDF',
      'CGA4233DE',
      'TG3442DE',
      'RAC2V1S',
      'RAC2V1K',
      'SAX1V1S',
      'SAX1V1R',
      'SAX1V1K',
      'SAC2V1K',
      'SAC2V1S',
      'SAC2V2S',
      'SAC2V1A',
      'SR201',
      'SR400AC',
      'TR4400-SCP',
      'QUANTA_YX3',
      'G3-ENG',
      'EMM1330',
      'HULK-WIFI6',
      'CODA-4582',
      'CODA-458X',
      'UBC1322',
      'VR-3071',
      'FAST3896UM',
      'FAST3896-3X3',
      'DG5000',
      '814-v6',
      '834-5',
      'CGA4233',
      'CGA4234',
      'CGA4236',
      'CGA4236TCH1',
      'PUMA7_CGR',
      'EX5510-B0',
      'FAST5670E',
      '854-v6'
    ];
  }
}
