<div class="grid">
  <div class="label">{{ 'customerGroups.partnerId' | translate }}:</div>
  <div class="value" *ngIf="!editPartnerId">
    <div class="partnerId" [class.empty]="((partnerId$ | async) || '').length === 0">
      {{ (partnerId$ | async) || ('customerGroups.noPartnerId' | translate) }}
    </div>
    <div class="commands" *ngIf="plume.isStrictAdminRole()">
      <icon name="icon-edit" tooltip="{{ 'customerGroups.edit' | translate }}" (click)="toggleSetPartnerId()"></icon>
    </div>
  </div>
  <div class="value" *ngIf="editPartnerId">
    <input
      class="partnerIdEditor"
      (keyup.enter)="updatePartnerId()"
      (keyup.escape)="toggleSetPartnerId()"
      [formControl]="partnerIdControl"
      placeholder="{{ 'customerGroups.addNewPartnerId' | translate }}"
      autofocus
    />
    <div class="commands" *ngIf="plume.isStrictAdminRole()">
      <icon
        name="icon-check"
        class="confirm"
        tooltip="{{ 'techdash.tooltip.set' | translate }}"
        (click)="updatePartnerId()"
      ></icon>
      <icon
        name="icon-close"
        class="size-0_8 cancel"
        tooltip="{{ 'techdash.tooltip.cancel' | translate }}"
        (click)="editPartnerId = false"
      ></icon>
    </div>
  </div>
</div>
<div class="grid" *ngIf="(partnerIdUpdateLoading$ | async) === false && (location$ | async)?.partnerName">
  <div class="label">{{ 'customerGroups.partnerName' | translate }}:</div>
  <div class="value">
    <div class="partnerId" [class.empty]="((partnerId$ | async) || '').length === 0">
      {{ (location$ | async)?.partnerName }}
    </div>
  </div>
</div>
<div class="divider"></div>
<div class="groups">
  <div class="label">{{ 'customerGroups.groups' | translate }}:</div>
  <div class="grid">
    <div class="label"></div>
    <div class="value list">
      <ng-container *ngIf="loadingGroups">
        <div class="item">{{ 'customerGroups.loading' | translate }}</div>
      </ng-container>
      <ng-container *ngIf="!loadingGroups && !groups.length">
        <div class="item">{{ 'customerGroups.noGroups' | translate }}</div>
      </ng-container>
      <ng-container *ngIf="!loadingGroups && groups.length">
        <div class="item" *ngFor="let group of groups">
          <div>{{ group.name }}</div>
          <div class="commands" *ngIf="plume.isStrictAdminRole()">
            <icon
              name="icon-close"
              class="size-0_8"
              tooltip="{{ 'customerGroups.removeGroup' | translate }}"
              (click)="removeGroup(group.id)"
            ></icon>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
<div class="divider" *ngIf="plume.isStrictAdminRole()"></div>
<div class="grid" *ngIf="plume.isStrictAdminRole()">
  <div class="label">{{ 'customerGroups.addGroup' | translate }}:</div>
  <div class="value withSelector">
    <drop-down-select
      class="selector medium"
      emptyText="{{ 'customerGroups.selectGroupToAdd' | translate }}"
      [values]="allGroups"
      [filterVisible]="true"
      [filterFunction]="searchGroups"
      [ownModel]="selected"
      [revealOnHover]="true"
      [multiSelect]="false"
      (ownModelChange)="selected = $event"
    ></drop-down-select>
    <div class="commands" *ngIf="plume.isStrictAdminRole()">
      <button class="action medium" (click)="addGroup()">
        {{ 'customerGroups.addGroup' | translate }}
      </button>
    </div>
  </div>
</div>
