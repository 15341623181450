<div class="row" id="conf-iptv">
  <div class="col-xs-12">
    <collapsiblebox [show]="expand" [collapsible]="true" (toggle)="toggleExpand()">
      <div header>{{ 'configurations.iptv.title' | translate }}</div>
      <div container *ngIf="expand">
        <div class="columns">
          <div class="column" *ngIf="iptvService">
            <div class="row">
              <div class="key">{{ 'configurations.iptv.name' | translate }}</div>
              <div class="value">
                {{ iptvService?.name }}
              </div>
            </div>

            <div class="row">
              <div class="key">{{ 'configurations.iptv.enable' | translate }}</div>
              <div class="value enabled">
                <ng-container *ngIf="iptvService.enable">
                  <div class="circle green"></div>
                  <div>{{ 'configurations.iptv.enabled' | translate }}</div>
                </ng-container>
                <ng-container *ngIf="!iptvService.enable">
                  <div class="circle red"></div>
                  <div>{{ 'configurations.iptv.disabled' | translate }}</div>
                </ng-container>
              </div>
            </div>

            <div class="row" *ngIf="iptvService?.serviceNetworkMode">
              <div class="key">{{ 'configurations.iptv.serviceNetworkMode' | translate }}</div>
              <div class="value">
                {{ iptvService?.serviceNetworkMode }}
              </div>
            </div>
          </div>

          <div class="column" *ngIf="!iptvService">
            {{ 'configurations.iptv.locationNotEnabledForIPTV' | translate }}
          </div>
        </div>
      </div>
    </collapsiblebox>
  </div>
</div>
