import { Component } from '@angular/core';
import { CustomerService } from 'src/app/lib/services/customer.service';

@Component({
  selector: 'sso-audit-trail',
  templateUrl: './sso-audit-trail.component.html',
  styleUrls: ['./sso-audit-trail.component.scss']
})
export class SsoAuditTrailComponent {
  audits$ = this.customerService.ssoAuditTrail$();
  constructor(private customerService: CustomerService) {}
}
