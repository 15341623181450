import { Component, EventEmitter, Output } from '@angular/core';
import { GraphCalculationsService } from '../graph-calculations/graph-calculations.service';
import { DataWithPosition, GraphDataSetComputed } from '../graph.interface';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[flm-graph-interval-bar-container]',
  templateUrl: './graph-interval-bar-container.component.html',
  styleUrls: ['./graph-interval-bar-container.component.scss'],
})
export class GraphIntervalBarContainerComponent {
  @Output() barHover = new EventEmitter<{
    bar: DataWithPosition | undefined;
    dataSet: GraphDataSetComputed;
  }>();
  id = Math.random().toString();
  constructor(public calculations: GraphCalculationsService) {}
}
