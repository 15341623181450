import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { DeepReadonly, ILocation } from 'src/app/lib/interfaces/interface';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { selectPipeLocationOnChange } from 'src/app/store/customer/customer.selectors';

@Component({
  selector: 'locationproducts',
  templateUrl: './locationproducts.component.html',
  styleUrls: ['./locationproducts.component.scss']
})
export class LocationProductsComponent {
  @Input()
  partnerId: string;

  @Input()
  locationId: string;

  @Input()
  isUprise: boolean;

  products$ = this.store.pipe(
    selectPipeLocationOnChange,
    map((data) => this.getProfileProducts(data))
  );

  constructor(public plume: PlumeService, private store: Store) {}

  getUpriseUrl() {
    const env = this.plume.getEnv();
    if (!this.isUprise || !this.partnerId || !this.locationId || !env?.upriseApplicationUrl) return;
    return `${env.upriseApplicationUrl}/partner/${this.partnerId}/location/${this.locationId}`;
  }

  openUprise() {
    if (this.getUpriseUrl()) window.open(this.getUpriseUrl());
  }

  getProfileProducts(location: DeepReadonly<ILocation>): any[] {
    const profiles = [];

    switch (location.profile) {
      case 'auto':
        profiles.push({ type: 'homepass', name: 'HOMEPASS' });
        break;
      case 'smallbusiness':
        profiles.push({ type: 'workpass', name: 'WORKPASS' });
        break;
      case 'property':
        profiles.push({ type: 'property', name: 'PROPERTY' });
    }

    if (location.flex) {
      profiles.push({ type: 'flex', name: '+ FLEX' });
    }

    if (location.uprise) {
      profiles.push({ type: 'uprise', name: '+ UPRISE', link: this.getUpriseUrl() });
    }

    return profiles;
  }
}
