<svg>
  <svg:g [attr.transform]="'translate(' + margins.left + ',' + margins.top + ')'">
    <svg:g [attr.class]="tick.class" [tickVisual]="tick" *ngFor="let tick of xAxis"></svg:g>
    <svg:g [attr.class]="tick.class" [tickVisual]="tick" *ngFor="let tick of yAxis"></svg:g>
    <svg:rect class="axis" [attr.x]="0" [attr.y]="-10" [attr.width]="1" [attr.height]="height + 10"></svg:rect>
    <svg:rect class="axis" [attr.x]="0" [attr.y]="height" [attr.width]="width" [attr.height]="1"></svg:rect>
    <svg:g *ngFor="let line of lines">
      <svg:path
        *ngFor="let fill of line.fills"
        [attr.d]="fill"
        [attr.fill]="line.series.color"
        [style.opacity]="0.5"
      ></svg:path>
      <svg:g
        *ngFor="let path of line.paths"
        [attr.class]="line.class"
        [pathVisual]="path"
        [style]="{ stroke: line.series.color, strokeWidth: 2 }"
      ></svg:g>
      <svg:circle
        *ngFor="let dot of line.dots"
        [class.show]="dot.show"
        [attr.class]="dot.class"
        [attr.fill]="dot.series.color"
        [attr.r]="dot.show ? dot.r : dot.r / 2"
        [attr.cx]="dot.cx"
        [attr.cy]="dot.cy"
      ></svg:circle>
    </svg:g>
  </svg:g>
</svg>
<div class="tooltips" *ngFor="let tooltip of tooltips">
  <div
    class="region"
    [ngStyle]="tooltip.region"
    (mouseenter)="showTooltip(tooltip, true)"
    (mouseleave)="showTooltip(tooltip, false)"
  >
    <div class="info" [ngStyle]="tooltip.style" *ngIf="tooltip.show">
      <div class="date">{{ tooltip.date | date: 'LLL' }}</div>
      <div class="data" *ngFor="let data of tooltip.data">
        <div class="circle" [style.backgroundColor]="data.color"></div>
        <div>{{ data.series | translate }}: {{ data.value }} Mbps</div>
      </div>
    </div>
  </div>
</div>
