<div #diagram class="diagram">
  <div #center class="center">
    <div #overall class="overall" *ngIf="!permissions?.uiFeatures.hideHealthOverallScore">
      <loadingindicator *ngIf="overallScore === null"></loadingindicator>
      <div
        class="content"
        *ngIf="overallScore !== null"
        tooltip="{{ 'health.networkStatus.overallScoreTooltip' | translate }}"
      >
        <div class="score">{{ overallScore }}</div>
        <div
          class="label"
          [class.red]="overallScore < 60"
          [class.amber]="overallScore >= 60 && overallScore < 75"
          [class.green]="overallScore >= 75"
        >
          {{ 'health.networkStatus.overallScore' | translate }}
        </div>
      </div>
    </div>
    <div
      #circle
      class="section"
      [class.alert]="status?.internet?.network"
      [class.warning]="status?.internet?.extenders?.length && !status?.internet?.network"
      [class.not-selected]="selected !== null && selected !== 'connection'"
      (click)="select('connection')"
    >
      <text-in-circle>
        <loadingindicator *ngIf="!status.internet"></loadingindicator>
        <div *ngIf="status.internet">
          <div class="caption fitFirst">{{ 'health.networkStatus.titleInternet' | translate }}</div>
          <div class="message">
            <div *ngIf="status?.internet?.network">
              {{ 'health.networkStatus.WiFiNetworkOffline' | translate }}
            </div>

            <div *ngIf="status?.internet?.extenders?.length">
              {{ 'health.networkStatus.WiFiExtendersOffline' | translate }}
            </div>

            <div *ngIf="!status?.internet?.network && !status?.internet?.extenders?.length">
              {{ 'health.networkStatus.WiFiNetworkOnline' | translate }}
            </div>
          </div>
        </div>
      </text-in-circle>
    </div>
    <div
      #circle
      class="section"
      [class.alert]="anyAlerts()"
      [class.warning]="status?.internet?.network"
      [class.disable]="status?.internet?.network"
      [class.not-selected]="selected !== null && selected !== 'speed'"
      (click)="select('speed')"
    >
      <text-in-circle>
        <loadingindicator *ngIf="!alerts?.networkspeed && !alerts?.extenderspeed"></loadingindicator>
        <div *ngIf="alerts?.networkspeed && alerts?.extenderspeed">
          <div class="caption fitFirst">{{ 'health.networkPerformance.networkSpeed' | translate }}</div>

          <div class="message">
            <div *ngIf="status?.internet?.network">
              {{ 'health.networkPerformance.networkOffline' | translate }}
            </div>
            <div *ngIf="!anyAlerts() && !status?.internet?.network">
              {{ 'health.networkPerformance.networkSpeedIsNormal' | translate }}
            </div>
            <div *ngIf="!status?.internet?.network && alerts?.deviation?.status === true">
              {{ 'health.networkPerformance.inconsistentIspSpeed' | translate }}
            </div>
            <div
              *ngIf="
                !status?.internet?.network &&
                alerts?.networkspeed &&
                alerts?.networkspeed?.length > 0 &&
                !alerts?.deviation?.status
              "
            >
              {{ 'health.networkPerformance.lowExtenderSpeed' | translate
              }}<span *ngIf="alerts?.extenderspeed?.length > 0">,</span>
            </div>
            <div *ngIf="!status?.internet?.network && alerts?.extenderspeed?.length > 0 && !alerts?.deviation?.status">
              {{ 'health.networkPerformance.lowExtenderBroadbandEfficiency' | translate }}
            </div>
          </div>
        </div>
      </text-in-circle>
    </div>
    <div
      #circle
      class="section"
      [class.alert]="alerts.deviceHealthAlert"
      [class.warning]="status?.internet?.network"
      [class.disable]="status?.internet?.network"
      [class.not-selected]="selected !== null && selected !== 'qoe-devices'"
      (click)="select('qoe-devices')"
    >
      <text-in-circle>
        <loadingindicator *ngIf="!alerts || alerts.deviceHealthAlert === null"></loadingindicator>
        <div *ngIf="alerts && alerts.deviceHealthAlert !== null">
          <div class="caption fitFirst">{{ 'health.networkPerformance.deviceQoe' | translate }}</div>
          <div class="message">
            <div *ngIf="status?.internet?.network">
              {{ 'health.networkPerformance.networkOffline' | translate }}
            </div>
            <div *ngIf="!status?.internet?.network && alerts.deviceHealthAlert">
              {{ 'health.networkPerformance.someDevicesPoorQoe' | translate }}
            </div>
            <div *ngIf="!status?.internet?.network && !alerts.deviceHealthAlert">
              {{ 'health.networkPerformance.qoeIsNormal' | translate }}
            </div>
          </div>
        </div>
      </text-in-circle>
    </div>
    <div
      #circle
      class="section"
      [class.alert]="status?.environment?.highEnvironmentCongestion || status?.environment?.neighborReports"
      [class.warning]="status?.internet?.network"
      [class.disable]="status?.internet?.network"
      [class.not-selected]="selected !== null && selected !== 'environment'"
      (click)="select('environment')"
    >
      <text-in-circle>
        <loadingindicator *ngIf="!status.environment"></loadingindicator>
        <div *ngIf="status.environment">
          <div class="caption fitFirst">{{ 'health.networkStatus.titleEnvironment' | translate }}</div>
          <div class="message">
            <div *ngIf="status?.internet?.network">
              {{ 'health.networkStatus.networkOffline' | translate }}
            </div>

            <div
              *ngIf="
                !status?.internet?.network &&
                !status?.environment?.highEnvironmentCongestion &&
                !status?.environment?.neighborReports
              "
            >
              {{ 'health.networkStatus.normalCongestion' | translate }}
            </div>
            <div *ngIf="!status?.internet?.network && status?.environment?.highEnvironmentCongestion">
              {{ 'health.networkStatus.highCongestion' | translate
              }}<span
                *ngIf="
                  status?.environment?.neighborReports?.evilTwins?.length ||
                  status?.environment?.neighborReports?.parallelNetworks?.length
                "
                >,</span
              >
            </div>
            <div *ngIf="!status?.internet?.network && status?.environment?.neighborReports?.evilTwins?.length">
              {{ 'health.networkStatus.evilTwins' | translate
              }}<span *ngIf="status?.environment?.neighborReports?.parallelNetworks?.length">,</span>
            </div>
            <div *ngIf="!status?.internet?.network && status?.environment?.neighborReports?.parallelNetworks?.length">
              {{ 'health.networkStatus.parallelNetworks' | translate }}
            </div>
          </div>
        </div>
      </text-in-circle>
    </div>
    <div
      #circle
      class="section"
      [class.alert]="status?.stability?.high || status?.stability?.failed"
      [class.warning]="status?.internet?.network"
      [class.disable]="status?.internet?.network"
      [class.not-selected]="selected !== null && selected !== 'stability'"
      (click)="select('stability')"
    >
      <text-in-circle>
        <loadingindicator *ngIf="!status.stability"></loadingindicator>
        <div *ngIf="status.stability">
          <div class="caption fitFirst">{{ 'health.networkStatus.titleStability' | translate }}</div>
          <div class="message">
            <div *ngIf="status?.internet?.network">
              {{ 'health.networkStatus.networkOffline' | translate }}
            </div>
            <div *ngIf="!status?.internet?.network && !status?.stability?.high && !status?.stability?.failed">
              {{ 'health.networkStatus.normalOptimizations' | translate }}
            </div>
            <div *ngIf="!status?.internet?.network && status?.stability?.high">
              {{ 'health.networkStatus.highOptimizations' | translate }}<span *ngIf="status?.stability?.failed">,</span>
            </div>
            <div *ngIf="!status?.internet?.network && status?.stability?.failed">
              {{ 'health.networkStatus.failedOptimizations' | translate }}
            </div>
          </div>
        </div>
      </text-in-circle>
    </div>
    <div
      #circle
      class="section"
      [class.alert]="alerts?.coverage === true"
      [class.warning]="status?.internet?.network"
      [class.disable]="status?.internet?.network"
      [class.not-selected]="selected !== null && selected !== 'coverage'"
      (click)="select('coverage')"
    >
      <text-in-circle>
        <div>
          <div class="caption fitFirst">{{ 'health.networkPerformance.wifiCoverage' | translate }}</div>
          <div class="message">
            <div *ngIf="status?.internet?.network">
              {{ 'health.networkPerformance.networkOffline' | translate }}
            </div>
            <div *ngIf="status?.internet?.network === null && alerts?.coverage === true">
              {{ 'health.networkPerformance.chronicPoorWifiCoverage' | translate }}
            </div>
            <div *ngIf="!status?.internet?.network && alerts?.coverage !== true">
              {{ 'health.networkPerformance.normalWifiCoverage' | translate }}
            </div>
          </div>
        </div>
      </text-in-circle>
    </div>
    <div
      #circle
      class="section"
      [class.alert]="alerts.podHealthAlert"
      [class.warning]="status?.internet?.network || (alerts.podEthernetAlert && !alerts.podHealthAlert)"
      [class.disable]="status?.internet?.network"
      [class.not-selected]="selected !== null && selected !== 'qoe-pods'"
      (click)="select('qoe-pods')"
    >
      <text-in-circle>
        <loadingindicator
          *ngIf="!alerts || alerts.podHealthAlert === null || alerts.podEthernetAlert === null"
        ></loadingindicator>
        <div *ngIf="alerts && alerts.podHealthAlert !== null && alerts.podEthernetAlert !== null">
          <div class="caption fitFirst">{{ 'health.networkPerformance.podQoe' | translate }}</div>
          <div class="message">
            <div *ngIf="status?.internet?.network">
              {{ 'health.networkPerformance.networkOffline' | translate }}
            </div>
            <div *ngIf="!status?.internet?.network && alerts.podHealthAlert">
              {{ 'health.networkPerformance.extendersPoorQoe' | translate }}
            </div>
            <div *ngIf="!status?.internet?.network && alerts.podEthernetAlert">
              {{ 'health.networkPerformance.extendersPoorEthernet' | translate }}
            </div>
            <div *ngIf="!status?.internet?.network && !alerts.podHealthAlert && !alerts.podEthernetAlert">
              {{ 'health.networkPerformance.qoeIsNormal' | translate }}
            </div>
          </div>
        </div>
      </text-in-circle>
    </div>
  </div>
</div>
