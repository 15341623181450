import { scaleLinear } from 'd3';
import { Bar } from '../objects/bar';
import { Tick } from '../objects/tick';

export class GroupsChart {
  private data: any[];
  private bars: Bar[];
  private width: number;
  private height: number;
  private xScale: any;
  private yScale: any;
  private margins: any = {
    top: 100,
    right: 80,
    bottom: 60,
    left: 80
  };

  constructor() {}

  calculateWidth(): number {
    return this.width - this.margins.left - this.margins.right;
  }

  calculateHeight(): number {
    return this.height - this.margins.top - this.margins.bottom;
  }

  getMargins(): any {
    return this.margins;
  }

  prepareScales(): void {
    this.xScale = scaleLinear().range([0, this.calculateWidth()]);
    this.yScale = scaleLinear().range([0, this.calculateHeight()]);
  }

  prepareDomains(): void {
    this.xScale.domain([0, Math.max(1, ...this.data.map((d: any) => d.count))]).nice();
    this.yScale.domain([0, 5]);
  }

  calculateBars(): void {
    this.bars = this.data.map((data: any, index: number, array: any[]) => {
      const bar = new Bar(data.label, data.count, 0, data);
      bar.x = -2;
      bar.y = this.yScale(index) + 5;
      bar.width = this.xScale(data.count) + 2;
      bar.height = this.calculateHeight() / array.length - 10;
      return bar;
    });
  }

  xAxis(): any[] {
    return this.xScale.ticks(5).map((tick: any) => new Tick(
        'xaxis',
        tick.toString(),
        'translate(' + this.xScale(tick) + ',' + this.calculateHeight() + ')',
        { y: 15 },
        false
      ));
  }

  yAxis(): any[] {
    const barHeight = this.calculateHeight() / this.data.length / 2;
    return this.data.map((data: any, index: number) => new Tick(
        'yaxis',
        'header.groupManagement.chart.' + data.label,
        'translate(-20,' + (this.yScale(index) + barHeight) + ')',
        { dy: 3 }
      ));
  }

  update(data: any[], width?: number, height?: number, margins?: any): Bar[] {
    this.data = data;

    if (width) {
      this.width = width;
    }
    if (height) {
      this.height = height;
    }
    if (margins) {
      this.margins = margins;
    }

    this.prepareScales();
    this.prepareDomains();

    this.calculateBars();

    return this.bars;
  }
}
