import { SimulationNodeDatum } from 'd3';

export class Node implements SimulationNodeDatum {
  index?: number;
  x?: number;
  y?: number;
  vx?: number;
  vy?: number;
  fx?: number | null;
  fy?: number | null;

  id: string;
  options: any;
  appendixes: any[];
  overlays?: any;

  constructor(id: string, options: any, appendixes: any[] = []) {
    this.id = id;
    this.options = options;
    this.appendixes = appendixes;
  }

  get r(): number {
    return this.options.r || 30;
  }

  get fontSize(): number {
    return this.options.fontSize || 0.7;
  }

  get color(): string {
    return this.options.color || '#999';
  }

  checkBounds(width: number, height: number): void {
    if (this.x > width / 2 - 50) {
      this.x = width / 2 - 50;
    }
    if (this.x < 50 - width / 2) {
      this.x = 50 - width / 2;
    }
    if (this.y > height / 2 - 50) {
      this.y = height / 2 - 50;
    }
    if (this.y < 50 - height / 2) {
      this.y = 50 - height / 2;
    }
  }
}
