<div class="header" [routerLink]="['..', 'device', device.mac]" [fragment]="timeValue">
  <icon class="size-2" [path]="getIconPath()"></icon>
  <div class="id">
    <div class="name" [tooltip]="device?.nickname || device?.name | macAddr: true" [onlyOverflowText]="true">
      {{ device?.nickname || device?.name | macAddr: true }}
    </div>
    <div class="mac" [tooltip]="device?.mac | macAddr" [onlyOverflowText]="true">{{ device?.mac | macAddr }}</div>
  </div>
  <div class="state" [class.online]="device?.connectionState === 'connected'"></div>
</div>

<div class="piePart" *ngIf="device?.trafficUsage && !device?.loading">
  <pie-chart-with-ring
    [(selectedItemIndex)]="selectedItemIndex"
    (outerCircleHover)="outerCircleHover($event)"
    [data]="device.trafficUsage"
  ></pie-chart-with-ring>
  <div>
    <grid-charts-details [data]="device.trafficUsage[selectedItemIndex]" [titleColor]="true"></grid-charts-details>
    <ng-container *ngFor="let legendItem of device?.trafficUsage[selectedItemIndex]?.outer">
      <div class="legend" *ngIf="legendItem.value > 0">
        <div class="pill" [style.background]="legendItem.color"></div>
        <div class="label" [style.color]="legendItem.color">{{ legendItem.title }}: {{ legendItem.value }}%</div>
      </div>
    </ng-container>
  </div>

  <div #outerCircleTooltipContainer tooltip class="hidden"></div>
</div>
<div class="emptyTraffic" *ngIf="!device?.trafficUsage && !device?.loading">
  {{ 'traffic.noDataAvailable' | translate }}
</div>
<div class="emptyTraffic" *ngIf="device?.loading">{{ 'traffic.loading' | translate }}</div>
