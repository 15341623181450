import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ApiService } from 'src/app/lib/services/api.service';
import { PlumeService } from 'src/app/lib/services/plume.service';

@Component({
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss']
})
export class ResetPasswordComponent {
  email: FormControl = new FormControl();
  loading: boolean = false;
  error: any = {
    show: false,
    message: ''
  };

  passwordButton: string = 'login.resetPassword';

  constructor(private api: ApiService, private plume: PlumeService) {}

  reset(): void {
    this.error.show = false;
    this.error.message = '';
    this.loading = true;
    const env = this.plume.getEnv();

    if (env.hostname !== null && this.email.value) {
      this.api
        .raw(
          'post',
          env.hostname + '/api/Customers/reset',
          { email: this.email.value },
          { headers: { 'Plume-Application-Name': 'PlumeCentral' } }
        )
        .subscribe(
          () => {
            this.email.setValue('');
            this.passwordButton = 'login.sent';
            this.loading = false;
            setTimeout(() => (this.passwordButton = 'login.resetPassword'), 5000);
          },
          (error: any) => {
            if (error.error.error.code === 'EMAIL_NOT_FOUND') {
              this.email.setValue('');
              this.passwordButton = 'login.sent';
              this.loading = false;
              setTimeout(() => (this.passwordButton = 'login.resetPassword'), 5000);
            } else {
              this.error.message = error.error.error.message;
              this.error.show = true;
              this.loading = false;
            }
          }
        );
    } else {
      this.error.message = 'login.error.emailRequired';
      this.error.show = true;
      this.loading = false;
    }
  }
}
