import { scaleBand, scaleLinear } from 'd3';
import { Bar } from '../objects/bar';
import { Tick } from '../objects/tick';

export class BarChart {
  private data: any[];
  private bars: Bar[];
  private width: number;
  private height: number;
  private xScale: any;
  private yScale: any;
  private margins: any = {
    top: 100,
    right: 80,
    bottom: 60,
    left: 80
  };

  constructor() {}

  calculateWidth(): number {
    return this.width - this.margins.left - this.margins.right;
  }

  calculateHeight(): number {
    return this.height - this.margins.top - this.margins.bottom;
  }

  prepareScales(): void {
    this.xScale = scaleBand()
      .range([0, this.calculateWidth()])
      .padding(0.7);
    this.yScale = scaleLinear().range([this.calculateHeight(), 0]);
  }

  prepareDomains(): void {
    this.xScale.domain(this.data.map((d: any) => d.label));
    this.yScale
      .domain([Math.min(0, ...this.data.map((d: any) => d.value)), Math.max(...this.data.map((d: any) => d.value))])
      .nice(5);
  }

  calculateBars(): void {
    this.bars = this.data.map((bar: Bar) => {
      bar.class = 'bar';
      bar.x = this.xScale(bar.label);
      bar.y = this.yScale(bar.value);
      bar.width = this.xScale.bandwidth();
      bar.height = this.calculateHeight() - this.yScale(bar.value);
      bar.rx = 4;
      bar.ry = 4;
      return bar;
    });
  }

  xAxis(): any[] {
    return this.xScale.domain().map((tick: any) => new Tick(
        'xaxis',
        tick,
        'translate(' + (this.xScale(tick) + this.xScale.bandwidth() / 2) + ',' + (this.calculateHeight() + 20) + ')',
        { y: 10 },
        false
      ));
  }

  yAxis(): any[] {
    return this.yScale.ticks(5).map((tick: any) => new Tick(
        'yaxis',
        this.converter(tick),
        'translate(0, ' + this.yScale(tick) + ')',
        { x: -20, dy: 0.32 },
        false
      ));
  }

  update(data: any[], width?: number, height?: number, margins?: any): Bar[] {
    this.data = data;

    if (width) {
      this.width = width;
    }
    if (height) {
      this.height = height;
    }
    if (margins) {
      this.margins = margins;
    }

    this.prepareScales();
    this.prepareDomains();

    this.calculateBars();

    return this.bars;
  }

  converter(value: number): string {
    const units = ['', 'k', 'M', 'B'];

    const tier = Math.log10(value) / 3 || 0;

    if (tier === 0) {
      return value.toString();
    }

    const prefix = units[tier];
    const scale = Math.pow(10, tier * 3);

    const scaled = value / scale;

    return parseFloat(scaled.toFixed(1)) + prefix;
  }
}
