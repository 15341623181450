import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { selectBaseUrl } from 'src/app/store/customer/customer.selectors';
import { ApiService } from './api.service';
import { IThreadTopology } from '../interfaces/interface';

@Injectable({ providedIn: 'root' })
export class ThreadService {
  constructor(private api: ApiService, private store: Store) {}

  getNetwork$(): Observable<IThreadTopology> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.get(`${baseUrl}/thread/graph`))
    );
  }

  setNetwork$(enable: boolean, threadInterface: string, reportingInterval: number): Observable<void> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) =>
        this.api.patch(`${baseUrl}/config/thread`, {
          enable,
          threadInterface,
          reportingInterval
        })
      )
    );
  }
}
