<svg #container>
  <svg:defs>
    <linearGradient id="barGradient" gradientTransform="rotate(90)">
      <stop class="bar-start" offset="5%" />
      <stop class="bar-stop" offset="95%" />
    </linearGradient>
    <linearGradient id="regionGradient" gradientTransform="rotate(90)">
      <stop class="region-start" offset="0%" />
      <stop class="region-stop" offset="100%" />
    </linearGradient>
  </svg:defs>
  <svg:g [attr.transform]="'translate(' + margins.left + ',' + margins.top + ')'" (mouseleave)="hideTooltip()">
    <svg:g [attr.class]="tick.class" [tickVisual]="tick" *ngFor="let tick of xAxis"></svg:g>
    <svg:g [attr.class]="tick.class" [tickVisual]="tick" *ngFor="let tick of yAxis"></svg:g>
    <svg:rect
      class="region"
      [attr.x]="bar.x"
      [attr.y]="0"
      [attr.width]="bar.width"
      [attr.height]="chart.calculateHeight()"
      (mouseenter)="showTooltip(bar)"
      (click)="selectEvents(bar)"
      *ngFor="let bar of bars"
      rx="5"
    ></svg:rect>
    <svg:rect
      class="bar"
      fill="url(#barGradient)"
      [attr.x]="bar.x + (3 * bar.width) / 8"
      [attr.y]="bar.y"
      [attr.width]="bar.width / 4"
      [attr.height]="bar.height"
      [attr.rx]="bar.width / 8"
      *ngFor="let bar of bars"
    ></svg:rect>
    <svg:rect
      class="axis"
      [attr.x]="0"
      [attr.y]="-10"
      [attr.width]="1"
      [attr.height]="chart.calculateHeight() + 10"
    ></svg:rect>
    <svg:rect
      class="axis"
      [attr.x]="0"
      [attr.y]="chart.calculateHeight()"
      [attr.width]="chart.calculateWidth()"
      [attr.height]="1"
    ></svg:rect>
    <svg:g class="nodata" *ngIf="nodata.show" [attr.transform]="'translate(' + nodata.x + ',' + nodata.y + ')'">
      <svg:rect x="-90" y="-18" width="180" height="40" rx="5" ry="5" fill="rgba(0, 0, 0, 0.35)"></svg:rect>
      <svg:text x="0" y="0" class="title">{{ 'timelines.recentevents.noDataTitle' | translate }}</svg:text>
      <svg:text x="0" y="0" dy="12">{{ 'timelines.recentevents.noDataMessage' | translate }}</svg:text>
    </svg:g>
  </svg:g>
</svg>
<div
  id="tooltip"
  class="tooltip"
  [class.show]="tooltip.show"
  [style.width.px]="tooltip.width"
  [style.top.px]="tooltip.marginTop"
  [style.left.px]="tooltip.left"
  [style.bottom.px]="tooltip.marginBottom"
  *ngFor="let tooltip of tooltips"
>
  <div class="tooltip-top" [style.top.px]="tooltip.top">{{ tooltip.time }}</div>
  <div class="tooltip-bottom" [style.bottom.px]="tooltip.bottom">
    {{ tooltip.count }} {{ 'timelines.recentevents.events' | translate }}
  </div>
</div>
