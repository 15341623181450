import { Directive, OnChanges, ElementRef, Input, OnDestroy } from '@angular/core';
import { IconService } from '../services/icon.service';

@Directive({
  selector: '[icon]'
})
export class IconDirective implements OnChanges, OnDestroy {
  @Input()
  icon: string = '';

  @Input()
  inline: boolean = false;

  @Input()
  width = 0;

  @Input()
  height = 0;

  subscription: any;

  constructor(private el: ElementRef, private icons: IconService) {}

  ngOnChanges(): void {
    this.embedIcon();
  }

  embedIcon(): void {
    this.subscription = this.icons.get(this.icon).subscribe((response: string) => {
      if (response) {
        if (this.inline) {
          this.inlineIcon(response);
        } else {
          this.el.nativeElement.innerHTML = response;
        }
      }
    });
  }

  inlineIcon(data: any): void {
    const parser = new DOMParser();
    const icon = parser.parseFromString(data, 'image/svg+xml').documentElement;

    icon.setAttribute('width', `${this.width}`);
    icon.setAttribute('height', `${this.height}`);

    this.el.nativeElement.innerHTML = '';
    this.el.nativeElement.appendChild(icon);
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
