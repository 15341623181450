import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { TreeData, TreeChart } from 'src/app/lib/d3/models/charts/tree.chart';
import { D3Service } from 'src/app/lib/d3/service';
import { QoeService } from 'src/app/lib/services/qoe.service';
import { ModelRefService } from 'src/app/lib/services/modelref.service';
import { IconService } from 'src/app/lib/services/icon.service';
import { GeneralHelper } from 'src/app/lib/helpers/general.helper';
import { combineLatest } from 'rxjs';
import { selectLocationQoE, selectLocationTopology } from 'src/app/store/polling/polling.selector';
import { selectPipeLocationOnChange } from 'src/app/store/customer/customer.selectors';
import { DeepReadonly, ILocation, ILocationQoe, ITopology } from 'src/app/lib/interfaces/interface';

@UntilDestroy()
@Component({
  selector: 'treechart',
  templateUrl: './treechart.component.html',
  styleUrls: ['./treechart.component.scss']
})
export class TreeChartVisualComponent implements OnInit {
  chart: TreeChart;
  nodeWidth: number = 0;
  width: number = 0;
  height: number = 0;
  viewbox: number[] = [0, 0, 0, 0];

  nodes: any = [];
  links: any = [];

  helper: GeneralHelper = new GeneralHelper();

  location: DeepReadonly<ILocation>;
  topology: ITopology;
  locationQoe: ILocationQoe;

  renderStyle: string = 'nodes';
  renderStyleItems = [
    {
      value: 'nodes',
      translation: 'charts.tree.nodes',
      selected: true
    },
    {
      value: 'top5',
      translation: 'charts.tree.top5',
      selected: false
    },
    {
      value: 'all',
      translation: 'charts.tree.all',
      selected: false
    }
  ];

  constructor(
    private d3: D3Service,
    private store: Store,
    private qoe: QoeService,
    private modelRef: ModelRefService,
    private iconRef: IconService
  ) {}

  ngOnInit(): void {
    this.chart = this.d3.generate('treechart');

    combineLatest([
      this.store.pipe(selectPipeLocationOnChange),
      this.store.select(selectLocationTopology),
      this.store.select(selectLocationQoE)
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([location, topology, locationQoe]) => {
        if (location && topology && locationQoe) {
          (document as any).fonts.ready.then(() => {
            this.location = location;
            this.topology = topology;
            this.locationQoe = locationQoe;

            this.initChart();
          });
        }
      });
  }

  setRenderStyle(style: string): void {
    this.renderStyle = style;
    this.initChart();
  }

  track(index: number, data: any): string {
    return data.id;
  }

  initChart(): void {
    this.chart.init(this.constructHierarchyTree(this.location, this.topology, this.locationQoe));
    this.update();
  }

  update(): void {
    const { width, height, viewbox } = this.chart.update();

    this.nodeWidth = this.chart.nodeWidth;
    this.width = width;
    this.height = height;
    this.viewbox = viewbox;

    this.nodes = this.chart.getNodes();
    this.links = this.chart.getLinks();
  }

  expand(id: string): void {
    this.chart.toggle(id);
    this.update();
  }

  getIcon(model: string): string {
    if (model === 'icon-globe') {
      return '/assets/icons/icon-globe.svg';
    } else {
      return this.modelRef.get(model).icon;
    }
  }

  constructHierarchyTree(location: DeepReadonly<ILocation>, topology: ITopology, qoe: ILocationQoe): TreeData {
    const isp = {
      id: 'isp',
      name: location.geoIp.ISP,
      icon: 'icon-globe',
      type: 'isp',
      children: null
    };

    const deviceTrafficList = [];

    const nodes: any = topology.vertices.map((node) => {
      const usageThreshold = 0.001;

      if (node.type === 'pod') {
        const qoeData = qoe.nodes.find((n) => n.id === node.id);
        const currentUsage = this.qoe.prepare(qoeData).currentUsage;
        const usage = currentUsage ? this.helper.formatBytes(currentUsage, 'MB', 2) : null;
        const traffic = currentUsage < usageThreshold ? 0 : currentUsage;

        return {
          id: node.id,
          name: node.label,
          icon: node.metadata.model,
          type: 'node',
          traffic,
          usage: usage && traffic ? usage.value + ' ' + usage.unit + '/s' : null,
          health: node.health?.status
        };
      } else {
        const qoeData = qoe.devices.find((n) => n.mac === node.id);
        const currentUsage = this.qoe.prepare(qoeData).currentUsage;
        const usage = currentUsage ? this.helper.formatBytes(currentUsage, 'MB', 2) : null;
        const traffic = currentUsage < usageThreshold ? 0 : currentUsage;

        deviceTrafficList.push({
          id: node.id,
          traffic
        });

        return {
          id: node.id,
          name: node.label,
          icon: this.iconRef.identify(node.metadata.iconV2) + '.svg',
          type: 'device',
          traffic,
          usage: usage ? usage.value + ' ' + usage.unit + '/s' : null
        };
      }
    });

    const notGatewayNodes = [];

    topology.edges.forEach((edge) => {
      notGatewayNodes.push(edge.target);

      const source = nodes.find((node) => node.id === edge.source) || {};
      const target = nodes.find((node) => node.id === edge.target) || {};

      if (source.children) {
        source.children.push(target);
      } else {
        source.children = [target];
      }
    });

    const tree = nodes.filter((node) => !notGatewayNodes.includes(node.id));

    tree.forEach((node) => {
      const traffic = node?.children?.reduce((accumulator, node) => accumulator + node.traffic, 0) || 0;
      const usage = traffic ? this.helper.formatBytes(traffic, 'MB', 2) : null;

      node.traffic = traffic;
      node.usage = usage ? usage.value + ' ' + usage.unit + '/s' : null;
      node.gateway = true;
    });

    if (tree.length) {
      isp.children = tree;
    }

    const top5 = deviceTrafficList
      .sort((a, b) => b.traffic - a.traffic)
      .map((device) => device.id)
      .slice(0, 5);

    return this.filter(isp, top5);
  }

  filter(data: TreeData, top5: string[]): TreeData {
    if (data.children) {
      data.children = data.children.filter((node) => {
        let keep = true;

        switch (this.renderStyle) {
          case 'nodes':
            keep = node.type === 'node';
            break;
          case 'top5':
            keep = (node.type === 'device' && top5.includes(node.id)) || node.type === 'node';
            break;
        }

        if (keep && node.children) {
          node = this.filter(node, top5);
        }

        return keep;
      });
    }

    return data;
  }
}
