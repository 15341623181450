import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'loadingindicator',
  templateUrl: './loadingindicator.component.html',
  styleUrls: ['./loadingindicator.component.scss']
})
export class LoadingIndicatorComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
