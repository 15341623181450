import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { selectLogpullHistory } from 'src/app/store/customer/customer.selectors';
import * as moment from 'moment';
import { updateLogpullHistory } from 'src/app/store/customer/customer.actions';

type LogpullEvent = {
  createdAt: string;
  eventData: {
    tooltipProperties: { key: string; value: string }[];
    eventTime: string;
    eventName: string;
  };
  details: {
    comment: string;
    eventAt: string;
  };
  link?: string;
  copyLink?: string;
  eventType: 'cluster' | string;
};

@UntilDestroy()
@Component({
  selector: 'logpulltimeline',
  templateUrl: './logpulltimeline.component.html',
  styleUrls: ['./logpulltimeline.component.scss']
})
export class LogpullTimelineComponent implements OnInit, OnChanges {
  @Input()
  active: boolean;
  eventDetails: LogpullEvent = null;
  dayView: { text: string } | null = null;
  allLogpullEvents: LogpullEvent[] = null;
  showLogPull: boolean = false;
  logpullEvents: LogpullEvent[] = null;
  today = moment().endOf('day').utc().toISOString();
  endDay = this.today;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private plume: PlumeService,
    private mixpanel: MixpanelService,
    private translate: TranslateService,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.store
      .select(selectLogpullHistory)
      .pipe(untilDestroyed(this))
      .subscribe((response) => {
        this.allLogpullEvents = response.map((event) => ({
          createdAt: event.createdAt,
          eventData: {
            tooltipProperties: [
              { key: 'timelines.logpull.eventAt', value: moment(event.createdAt).format('LT') },
              {
                key: 'timelines.logpull.comment',
                value: event.comment.length > 40 ? event.comment.slice(0, 37) + '...' : event.comment || '/'
              }
            ],
            eventTime: moment(event.createdAt).format('LT'),
            eventName: event.comment.length > 30 ? event.comment.slice(0, 27) + '...' : event.comment
          },
          details: {
            comment: event.comment,
            eventAt: moment(event.createdAt).format('L LT')
          },
          link: event.link,
          copyLink: event.copyLink,
          eventType: 'none'
        }));

        this.calculateLogpullEvents();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.active.currentValue) {
      this.store.dispatch(updateLogpullHistory());
    }
  }

  calculateLogpullEvents(): void {
    this.logpullEvents = this.allLogpullEvents.filter((event) =>
      moment(event.createdAt).utc().isBetween(moment(this.endDay).subtract(30, 'days'), this.endDay)
    );
  }

  toggleExpand(): void {
    const enabledModes = this.route.snapshot.params?.mode?.length ? this.route.snapshot.params.mode.split(',') : [];

    if (this.active) {
      this.dayView = null;
      this.eventDetails = null;
      this.mixpanel.storeEvent('TIMELINES_LOGPULL_EXPAND', { EXPANDED: false });
      this.router.navigate([
        'customer',
        this.plume.customerid,
        'location',
        this.plume.locationid,
        'timelines',
        enabledModes.filter((mode: string) => mode !== 'logpull').join(',')
      ]);
    } else {
      this.mixpanel.storeEvent('TIMELINES_LOGPULL_EXPAND', { EXPANDED: true });
      this.router.navigate([
        'customer',
        this.plume.customerid,
        'location',
        this.plume.locationid,
        'timelines',
        [...enabledModes, 'logpull'].join(',')
      ]);
    }
  }

  openLogpullModal(e: MouseEvent): void {
    e.stopPropagation();
    this.showLogPull = true;
    this.mixpanel.storeEvent('TIMELINES_LOGPULL_MANUAL_DIALOG');
  }

  closeLogpullModal(e: MouseEvent): void {
    e.stopPropagation();
    this.showLogPull = false;
  }

  previousDate(): void {
    this.endDay = moment(this.endDay).subtract(29, 'days').endOf('day').utc().toISOString();
    this.calculateLogpullEvents();
  }

  nextDate(): void {
    this.endDay = moment(this.endDay).add(29, 'days').endOf('day').utc().toISOString();
    this.calculateLogpullEvents();
  }

  changeView(event: { text: string } | null): void {
    this.dayView = event;
  }

  selectEvent(event: LogpullEvent): void {
    this.eventDetails = { ...event };
  }
}
