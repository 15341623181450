<div class="container-fluid">
  <div class="row">
    <div class="col-xs-12">
      <collapsiblebox mode="fill" [show]="expanded" [collapsible]="true" toggleSide="left" (toggle)="toggleExpand()">
        <div class="title" header>
          <div class="header">
            <div class="group">
              {{ 'health.networkInformation.topologyHistory.title' | translate }}
              <icon
                name="icon-info"
                tooltip="{{ 'health.networkInformation.topologyHistory.info' | translate }}"
              ></icon>
            </div>
            <div class="selected-day skip-toggle" (click)="selectEvents()" *ngIf="selectedBucket">
              <icon name="icon-close" class="size-0_6"></icon>
              {{ selectedBucket | date: 'L LT' }}
            </div>
          </div>
          <div class="selectors skip-toggle" *ngIf="expanded && !loading">
            <drop-down class="medium deviceSelector" [disabled]="filterDevicesDisabled">
              <ng-container *ngIf="!filterDevice">
                {{ 'health.networkInformation.topologyHistory.filterByDevice' | translate }}
              </ng-container>
              <ng-container *ngIf="filterDevice">
                {{ filterDevice.text }}
              </ng-container>
              <ng-container dropdown>
                <li (click)="toggleDeviceFilter(null)" *ngIf="filterDevice">
                  {{ 'health.networkInformation.topologyHistory.allDevices' | translate }}
                </li>
                <li (click)="toggleDeviceFilter(device)" *ngFor="let device of filterDevicesItems">
                  {{ device.text | macAddr: true }}
                  <span>{{ device.value | macAddr }}</span>
                </li>
              </ng-container>
            </drop-down>
            <drop-down-select
              class="medium"
              [values]="filterItems"
              [ownModel]="filters"
              (ownModelChange)="toggleFilter($event)"
              [multiSelect]="true"
              fixedHeader="{{ 'health.networkInformation.topologyHistory.filterBy' | translate }}"
            >
            </drop-down-select>
          </div>
        </div>
        <div class="content" *ngIf="expanded" container cdk-scrollable>
          <div class="loading" *ngIf="loading">
            <icon name="icon-spinner" class="size-4 spinner loading"></icon>
          </div>
          <ng-container *ngIf="!loading">
            <p>{{ 'timelines.recentevents.chartDescription' | translate }}</p>
            <recenteventschart [data]="chart" (eventsChange)="selectEvents($event)"></recenteventschart>
            <div class="header">
              <p *ngIf="selectedBucket">{{ 'timelines.recentevents.listDescriptionHour' | translate }}</p>
              <p *ngIf="!selectedBucket">{{ 'timelines.recentevents.listDescriptionAll' | translate }}</p>
              <button class="action medium" [class.disabled]="!events.length" (click)="downloadCSV()">
                {{ 'timelines.recentevents.exportCSV' | translate }}
              </button>
            </div>
            <div class="historyList" *ngIf="events.length">
              <div class="history" *ngFor="let history of events">
                <div class="date">{{ history.timestamp | date: 'L LTS' }}</div>
                <div class="events">
                  <div class="event" *ngFor="let event of history.events">
                    <ng-container *ngIf="event.type === 'connectionState'">
                      <span
                        class="pill node"
                        [class.noclick]="event.node.noclick"
                        [tooltip]="event.node.id"
                        (click)="event.node.noclick ? false : goto('nodes', event.node.id)"
                      >
                        {{ event.node.nickname || event.node.defaultName || event.node.id | translate }}
                      </span>
                      <span class="pill state" [ngClass]="event.val">
                        {{ 'health.networkInformation.topologyHistory.' + event.val | translate }}
                      </span>
                      <ng-container *ngIf="event.parentNode">
                        {{
                          'health.networkInformation.topologyHistory.' + (event.val === 'connected' ? 'to' : 'from')
                            | translate
                        }}
                        <span
                          class="pill node"
                          [class.noclick]="event.parentNode.noclick"
                          [tooltip]="event.parentNode.id"
                          (click)="event.parentNode.noclick ? false : goto('nodes', event.parentNode.id)"
                        >
                          {{
                            event.parentNode.nickname || event.parentNode.defaultName || event.parentNode.id | translate
                          }}
                        </span>
                        {{ 'health.networkInformation.topologyHistory.onChannel' | translate }}
                        <span
                          class="pill channel"
                          [style.background]="event.channelColor"
                          [style.color]="event.channelTextColor"
                          >{{ event.channel }}</span
                        >
                      </ng-container>
                      <ng-container *ngIf="!event.parentNode">
                        {{ 'health.networkInformation.topologyHistory.asGateway' | translate }}
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="event.type === 'parentId'">
                      <span
                        class="pill node"
                        [class.noclick]="event.node.noclick"
                        [tooltip]="event.node.id"
                        (click)="event.node.noclick ? false : goto('nodes', event.node.id)"
                      >
                        {{ event.node.nickname || event.node.defaultName || event.node.id | translate }}
                      </span>
                      <span class="pill state" [ngClass]="event.status">
                        {{ 'health.networkInformation.topologyHistory.' + event.status | translate }}
                      </span>
                      {{
                        'health.networkInformation.topologyHistory.' + (event.status === 'connected' ? 'to' : 'from')
                          | translate
                      }}
                      <span
                        class="pill node"
                        [class.noclick]="event.newNode.noclick"
                        [tooltip]="event.newNode.id"
                        (click)="event.newNode.noclick ? false : goto('nodes', event.newNode.id)"
                      >
                        {{ event.newNode.nickname || event.newNode.defaultName || event.newNode.id | translate }}
                      </span>
                      {{ 'health.networkInformation.topologyHistory.onChannel' | translate }}
                      <span
                        class="pill channel"
                        [style.background]="event.channelColor"
                        [style.color]="event.channelTextColor"
                        >{{ event.channel }}</span
                      >
                    </ng-container>
                    <ng-container *ngIf="event.type === 'channel'">
                      <span
                        class="pill node"
                        [class.noclick]="event.node.noclick"
                        [tooltip]="event.node.id"
                        (click)="event.node.noclick ? false : goto('nodes', event.node.id)"
                      >
                        {{ event.node.nickname || event.node.defaultName || event.node.id | translate }}
                      </span>
                      {{ 'health.networkInformation.topologyHistory.changedBackhaulChannelFrom' | translate }}
                      <span
                        class="pill channel"
                        [style.background]="event.oldValColor"
                        [style.color]="event.oldValTextColor"
                        >{{ event.oldVal }}</span
                      >
                      {{ 'health.networkInformation.topologyHistory.to' | translate }}
                      <span
                        class="pill channel"
                        [style.background]="event.valColor"
                        [style.color]="event.valTextColor"
                        >{{ event.val }}</span
                      >

                      {{ 'health.networkInformation.topologyHistory.destinationRadio' | translate }}
                      <span class="pill channel radio">{{ event.radio.freqBand }}</span>
                    </ng-container>
                    <ng-container *ngIf="event.type === 'devices'">
                      <ng-container *ngIf="event.op === 'add'">
                        <span
                          class="pill device"
                          [class.noclick]="event.device.noclick || plume.hidePersonalDetails()"
                          [tooltip]="event.device.mac | macAddr"
                          (click)="
                            event.device.noclick || plume.hidePersonalDetails()
                              ? false
                              : goto('devices', event.device.mac)
                          "
                        >
                          {{ event.device.name || event.device.mac | macAddr: true | translate }}
                        </span>
                        {{ 'health.networkInformation.topologyHistory.connectedTo' | translate }}
                        <span
                          class="pill node"
                          [class.noclick]="event.node.noclick"
                          [tooltip]="event.node.id"
                          (click)="event.node.noclick ? false : goto('nodes', event.node.id)"
                        >
                          {{ event.node.nickname || event.node.defaultName || event.node.id | translate }}
                        </span>
                        {{ 'health.networkInformation.topologyHistory.onChannel' | translate }}
                        <span
                          class="pill channel"
                          [style.background]="event.channelColor"
                          [style.color]="event.channelTextColor"
                          >{{ event.channel }}</span
                        >
                      </ng-container>
                      <ng-container *ngIf="event.op === 'delete'">
                        <span
                          class="pill device"
                          [class.noclick]="event.device.noclick || plume.hidePersonalDetails()"
                          [tooltip]="event.device.mac | macAddr"
                          (click)="
                            event.device.noclick || plume.hidePersonalDetails()
                              ? false
                              : goto('devices', event.device.mac)
                          "
                        >
                          {{ event.device.name || event.device.mac | macAddr: true | translate }}
                        </span>
                        {{ 'health.networkInformation.topologyHistory.disconnectedFrom' | translate }}
                        <span
                          class="pill node"
                          [class.noclick]="event.node.noclick"
                          [tooltip]="event.node.id"
                          (click)="event.node.noclick ? false : goto('nodes', event.node.id)"
                        >
                          {{ event.node.nickname || event.node.defaultName || event.node.id | translate }}
                        </span>
                        {{ 'health.networkInformation.topologyHistory.onChannel' | translate }}
                        <span
                          class="pill channel"
                          [style.background]="event.channelColor"
                          [style.color]="event.channelTextColor"
                          >{{ event.channel }}</span
                        >
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="event.type === 'ethernetdevices'">
                      <ng-container *ngIf="event.op === 'add'">
                        <span
                          class="pill device"
                          [class.noclick]="event.device.noclick || plume.hidePersonalDetails()"
                          [tooltip]="event.device.mac | macAddr"
                          (click)="
                            event.device.noclick || plume.hidePersonalDetails()
                              ? false
                              : goto('devices', event.device.mac)
                          "
                        >
                          {{ event.device.hostName || event.device.mac | macAddr: true | translate }}
                        </span>
                        {{ 'health.networkInformation.topologyHistory.connectedTo' | translate }}
                        <span
                          class="pill node"
                          [class.noclick]="event.node.noclick"
                          [tooltip]="event.node.id"
                          (click)="event.node.noclick ? false : goto('nodes', event.node.id)"
                        >
                          {{ event.node.nickname || event.node.defaultName || event.node.id | translate }}
                        </span>
                      </ng-container>
                      <ng-container *ngIf="event.op === 'delete'">
                        <span
                          class="pill device"
                          [class.noclick]="event.device.noclick || plume.hidePersonalDetails()"
                          [tooltip]="event.device.mac | macAddr"
                          (click)="
                            event.device.noclick || plume.hidePersonalDetails()
                              ? false
                              : goto('devices', event.device.mac)
                          "
                        >
                          {{ event.device.hostName || event.device.mac | macAddr: true | translate }}
                        </span>
                        {{ 'health.networkInformation.topologyHistory.disconnectedFrom' | translate }}
                        <span
                          class="pill node"
                          [class.noclick]="event.node.noclick"
                          [tooltip]="event.node.id"
                          (click)="event.node.noclick ? false : goto('nodes', event.node.id)"
                        >
                          {{ event.node.nickname || event.node.defaultName || event.node.id | translate }}
                        </span>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="event.type === 'optimization'">
                      {{ 'health.networkInformation.topologyHistory.optimizationRequest' | translate }}
                      <span class="pill" *ngFor="let trigger of event.triggers">{{ trigger }}</span>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
            <div class="historyList" *ngIf="!events.length">
              <div class="history">
                <div class="no-events">
                  {{ 'health.networkInformation.topologyHistory.noEvents' | translate }}
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </collapsiblebox>
    </div>
  </div>
</div>
