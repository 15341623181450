import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { Line } from 'src/app/lib/d3/models/objects/line';
import { Point } from 'src/app/lib/d3/models/objects/point';
import { Series } from 'src/app/lib/d3/models/objects/series';
import {
  HardwareHealthItemResponse,
  HardwareHealthNodeResponse,
  HardwareHealthRadioTempResponse,
  PSCpuUtil,
  PSMemUtil,
  SlideTogglerItems
} from 'src/app/lib/interfaces/interface';
import { IconService } from 'src/app/lib/services/icon.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { ModalService } from 'src/app/lib/services/modal.service';
import { ModelRefService } from 'src/app/lib/services/modelref.service';
import { NodeService } from 'src/app/lib/services/nodes.service';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { ToastService } from 'src/app/lib/services/toast.service';
import { TroubleshootingService } from 'src/app/lib/services/troubleshooting.service';
import { selectIsUpriseLocation, selectPartnerId } from 'src/app/store/customer/customer.selectors';
import { selectNodeIcon } from 'src/app/store/lte/lte.selectors';
import { pollingPull } from 'src/app/store/polling/polling.actions';

type IRadioTemp = 'FREQ_BAND_24' | 'FREQ_BAND_50' | 'FREQ_BAND_50_LOW' | 'FREQ_BAND_50_HIGH' | 'FREQ_BAND_60';

type HardwareHealthSnapshotItem = {
  timestamp: string;
  cpuUtilPct: string;
  memUsedPct: string;
  fanRpm: string;
  freqBand24: string;
  freqBand50: string;
  freqBand50low: string;
  freqBand50high: string;
  freqBand60: string;
  uptimeInHrs: number;
  psMemUtil: PSMemUtil[];
  psCpuUtil: PSCpuUtil[];
};

@Component({
  selector: 'node',
  templateUrl: './node.component.html',
  styleUrls: ['./node.component.scss']
})
export class NodeComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  node: any = {};

  @Output()
  delete = new EventEmitter<string>();

  @Output()
  openHardwareInfoModal = new EventEmitter<void>();

  @ViewChild('input')
  input: ElementRef;

  ui: string = '';
  permissions: any;
  subscription: any;
  name: FormControl = new FormControl();
  loading: boolean = false;
  ledMode: string = '';
  show5GModal: boolean = false;
  showQoeModal: boolean = false;
  showIPV6Modal: boolean = false;
  showPublicIPModal: boolean = false;
  showLedModeOption: boolean = true;
  showNodeRenameOption: boolean = true;
  showNodeRebootOption: boolean = true;
  showDeleteOption: boolean = true;
  showPuncturingModal: boolean = false;

  congestionFrequencyItems: any[] = [];
  congestionPeriodItems: any[] = [];
  congestionFrequency: string = '2.4G';
  congestionPeriod: number = 1;
  congestionStats: any = null;

  hardwareInfoPeriodItems: SlideTogglerItems<1 | 7> = [];
  latestHardwareSnapshot: HardwareHealthSnapshotItem = null;
  latestRadioTemp: string = null;
  hardwareHealthPeriod: number = 1;
  radioTempFrequencyItems: SlideTogglerItems<IRadioTemp> = [];
  radioTempFrequency: IRadioTemp = 'FREQ_BAND_24';
  selectedRadioTempData: any[] = [{ axis: 'left', data: [] }];
  showHardwareHealthOpenSync20Features: boolean = false;
  showHardwareHealthOpenSync54Features: boolean = false;
  showHardwareHealthOpenSync64Features: boolean = false;
  showHardwareHealth: boolean = false;
  processesForMemoryChartData: string[] = [];
  selectedProcessForMemoryChartData: string = '';
  processesForCpuChartData: string[] = [];
  selectedProcessForCpuChartData: string = '';

  bandwidthFrequencyItems: any[] = [];
  bandwidthPeriodItems: any[] = [];
  bandwidthFrequency: string = '2.4G';
  bandwidthPeriod: number = 1;
  bandwidthStats: any = null;
  moment = moment;
  gateWayIcon$ = this.store.pipe(selectNodeIcon(''));
  isDemo$ = this.store.select(selectPartnerId).pipe(map((partnerId) => partnerId === 'Lte-Plume-Demo'));
  isUprise$ = this.store.select(selectIsUpriseLocation);

  puncInfo: any = [];
  puncChan: any = [];
  historyDays = 7;

  demo5gWAN = {
    'Frequency Band': 71,
    'Connection Type': '5G NR',
    Bandwidth: '40MHz',
    'Current RSSI': -73.42,
    RSRP: -100.65,
    RSRQ: -9.44,
    SINR: 12.79,
    Carrier: 'T-Mobile',
    MMC: 310,
    MNC: 260,
    CellID: 16501311,
    TAC: 31891
  };

  constructor(
    private toast: ToastService,
    private router: Router,
    private plume: PlumeService,
    private troubleshoot: TroubleshootingService,
    private modal: ModalService,
    private mixpanel: MixpanelService,
    private modelRef: ModelRefService,
    private icons: IconService,
    private nodeService: NodeService,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.ui = this.plume.getUI();
    this.gateWayIcon$ = this.store.pipe(selectNodeIcon(this.node.id));

    this.subscription = this.plume.permissions.subscribe((data: any) => {
      this.permissions = data;
    });

    this.ledMode = 'locate';
    this.showLedModeOption = this.plume.isStrictSupportRole() || this.plume.isFlexRole() ? false : true;
    this.showNodeRenameOption = this.plume.isStrictSupportRole() || this.plume.isFlexRole() ? false : true;
    this.showDeleteOption = this.plume.isFlexRole() ? false : true;
    this.showNodeRebootOption =
      (!this.plume.cloudVersionAbove1_119() && this.plume.isStrictSupportRole()) || this.plume.isFlexRole()
        ? false
        : true;
    this.initiateSliders();
    this.preparePunctureInfo();
    this.showHardwareHealth =
      this.plume.cloudVersionAbove1_134() && this.permissions?.uiFeatures.showNodeHardwareHealth;
    if (this.node.openSyncVersion) {
      this.showHardwareHealthOpenSync20Features = this.plume.versionCompare(this.node.openSyncVersion, '2.0');
      this.showHardwareHealthOpenSync54Features = this.plume.versionCompare(this.node.openSyncVersion, '5.4');
      this.showHardwareHealthOpenSync64Features = this.plume.versionCompare(this.node.openSyncVersion, '6.4');
    }
  }

  getHMS(secs: number): { h: string; m: string; s: string } {
    const time = moment.utc(secs * 1000);

    return {
      h: `${time.format('HH')}`,
      m: `${time.format('mm')}`,
      s: `${time.format('ss')}`
    };
  }

  getIcon(model: string): string {
    return this.modelRef.get(model).icon;
  }

  getIconPath(device: any): string {
    if (device?.iconV3) {
      return this.icons.getV3Path(device.iconV3, 'small');
    }
    return this.icons.getPath(device.kind?.type?.iconV2 || device.iconV2);
  }

  ngOnChanges(changes: any): void {
    if (changes.node.currentValue && changes.node.currentValue.connectionState === 'disconnected') {
      this.loading = false;
    }
    if (changes.node?.previousValue) {
      this.node.hardwareHealthChart = changes.node.previousValue.hardwareHealthChart;
    }
  }

  initiateSliders(): void {
    this.congestionPeriodItems = [
      { value: 1, translation: 'nodes.node.slider.24h', selected: true },
      { value: 7, translation: 'nodes.node.slider.7days', selected: false }
    ];

    if ('2gChannel' in this.node || 'radioMac24' in this.node) {
      this.congestionFrequencyItems.push({
        value: '2.4G',
        translation: 'nodes.node.slider.24ghz',
        selected: true
      });
    }

    if ('5glChannel' in this.node || 'radioMac50L' in this.node) {
      this.congestionFrequencyItems.push({ value: '5GL', translation: 'nodes.node.slider.5GL', selected: false });
    }

    if ('5guChannel' in this.node || 'radioMac50U' in this.node) {
      this.congestionFrequencyItems.push({ value: '5GU', translation: 'nodes.node.slider.5GU', selected: false });
    }

    if ('5gChannel' in this.node || 'radioMac50' in this.node) {
      this.congestionFrequencyItems.push({ value: '5G', translation: 'nodes.node.slider.5G', selected: false });
    }

    if ('6gChannel' in this.node || 'radioMac60' in this.node) {
      this.congestionFrequencyItems.push({ value: '6G', translation: 'nodes.node.slider.6G', selected: false });
    }

    this.bandwidthPeriodItems = [
      { value: 1, translation: 'nodes.node.slider.24h', selected: true },
      { value: 7, translation: 'nodes.node.slider.7days', selected: false }
    ];

    this.bandwidthFrequencyItems = [
      ...(this.node.radioMac24 || '2gChannel' in this.node
        ? [{ value: '2.4G', translation: 'nodes.node.slider.24ghz', selected: true }]
        : []),
      ...(this.node.radioMac50 || '5gChannel' in this.node
        ? [{ value: '5G', translation: 'nodes.node.slider.5G', selected: false }]
        : []),
      ...(this.node.radioMac50L || '5glChannel' in this.node
        ? [{ value: '5GL', translation: 'nodes.node.slider.5GL', selected: false }]
        : []),
      ...(this.node.radioMac50U || '5guChannel' in this.node
        ? [{ value: '5GU', translation: 'nodes.node.slider.5GU', selected: false }]
        : []),
      ...(this.node.radioMac60 || '6gChannel' in this.node
        ? [{ value: '6G', translation: 'nodes.node.slider.6G', selected: false }]
        : [])
    ];

    this.bandwidthFrequency = this.bandwidthFrequencyItems[0]?.value;

    this.hardwareInfoPeriodItems = [
      { value: 1, translation: 'nodes.node.slider.24h', selected: true },
      { value: 7, translation: 'nodes.node.slider.7days', selected: false }
    ];

    this.radioTempFrequencyItems = [
      ...(this.node.radioMac24 || '2gChannel' in this.node
        ? [{ value: 'FREQ_BAND_24' as IRadioTemp, translation: 'nodes.node.hardwareHealth.freqBand24', selected: true }]
        : []),
      ...(this.node.radioMac50 || '5gChannel' in this.node
        ? [
            {
              value: 'FREQ_BAND_50' as IRadioTemp,
              translation: 'nodes.node.hardwareHealth.freqBand50',
              selected: false
            }
          ]
        : []),
      ...(this.node.radioMac50L || '5glChannel' in this.node
        ? [
            {
              value: 'FREQ_BAND_50_LOW' as IRadioTemp,
              translation: 'nodes.node.hardwareHealth.freqBand50Low',
              selected: false
            }
          ]
        : []),
      ...(this.node.radioMac50U || '5guChannel' in this.node
        ? [
            {
              value: 'FREQ_BAND_50_HIGH' as IRadioTemp,
              translation: 'nodes.node.hardwareHealth.freqBand50High',
              selected: false
            }
          ]
        : []),
      ...(this.node.radioMac60 || '6gChannel' in this.node
        ? [
            {
              value: 'FREQ_BAND_60' as IRadioTemp,
              translation: 'nodes.node.hardwareHealth.freqBand60',
              selected: false
            }
          ]
        : [])
    ];
  }

  copyLink(event: any): void {
    event.stopPropagation();
    const nodeURL =
      location.host +
      '/customer/' +
      this.plume.customerid +
      '/location/' +
      this.plume.locationid +
      '/nodes/' +
      this.node.id;

    try {
      navigator.clipboard.writeText(nodeURL);
      this.toast.success('nodes.node.linkCopied', 'nodes.node.success', {
        params: {
          name: this.node.nickname
        }
      });
    } catch (error) {}
  }

  toggle5GModal(state: boolean): void {
    this.show5GModal = state;
  }

  toggleQoeModal(state: boolean): void {
    this.showQoeModal = state;
  }

  toggleIPV6Modal(state: boolean): void {
    this.showIPV6Modal = state;
  }

  togglePublicIPModal(state: boolean): void {
    this.showPublicIPModal = state;
  }

  preparePunctureInfo(): void {
    if (this.node?.radioStats && this.node?.radioStats.length) {
      this.puncInfo = [];
      this.puncInfo['2.4G'] = this.node.radioStats.find((ch) => ch.freqBand === '2.4G')?.channelWidth;
      this.puncInfo['5G'] = this.node.radioStats.find((ch) => ch.freqBand === '5G')?.channelWidth;
      this.puncInfo['5GL'] = this.node.radioStats.find((ch) => ch.freqBand === '5GL')?.channelWidth;
      this.puncInfo['5GU'] = this.node.radioStats.find((ch) => ch.freqBand === '5GU')?.channelWidth;
      this.puncInfo['6G'] = this.node.radioStats.find((ch) => ch.freqBand === '6G')?.channelWidth;
      this.puncChan = [];
      this.puncChan.push({
        key: 'nodes.node.wifi2g',
        radio: '2.4G',
        puncturedChannels: this.node.radioStats.find((ch) => ch.freqBand === '2.4G')?.puncturedChannels
      });
      this.puncChan.push({
        key: 'nodes.node.wifi5g',
        radio: '5G',
        puncturedChannels: this.node.radioStats.find((ch) => ch.freqBand === '5G')?.puncturedChannels
      });
      this.puncChan.push({
        key: 'nodes.node.wifi5g1',
        radio: '5GL',
        puncturedChannels: this.node.radioStats.find((ch) => ch.freqBand === '5GL')?.puncturedChannels
      });
      this.puncChan.push({
        key: 'nodes.node.wifi5g2',
        radio: '5GU',
        puncturedChannels: this.node.radioStats.find((ch) => ch.freqBand === '5GU')?.puncturedChannels
      });
      this.puncChan.push({
        key: 'nodes.node.wifi6g',
        radio: '6G',
        puncturedChannels: this.node.radioStats.find((ch) => ch.freqBand === '6G')?.puncturedChannels
      });
    }
  }

  track(index: number, device: any): string {
    return device.mac;
  }

  redirectToDevice(device: any): void {
    if (!this.plume.hidePersonalDetails()) {
      this.router.navigate([
        'customer',
        this.plume.customerid,
        'location',
        this.plume.locationid,
        'devices',
        device.mac
      ]);
    }
  }

  gotoQoe(): void {
    this.router.navigate([
      'customer',
      this.plume.customerid,
      'location',
      this.plume.locationid,
      'qoe',
      'nodes',
      this.node.id
    ]);
  }

  toggleDeviceList(): void {
    this.node.deviceListOpen = !this.node.deviceListOpen;
    this.mixpanel.storeEvent('NODES_EXPAND_DEVICES', { NODE_ID: this.node.id, EXPANDED: this.node.deviceListOpen });
  }

  action(command: string, action: any): void {
    switch (command) {
      case 'congestionFrequency':
        this.congestionFrequency = action;
        break;
      case 'congestionPeriod':
        this.congestionPeriod = action;
        break;
      case 'hardwareHealthPeriod':
        this.hardwareHealthPeriod = action;
        break;
      case 'radioTempFrequency':
        this.radioTempFrequency = action;
        break;
      case 'bandwidthFrequency':
        this.bandwidthFrequency = action;
        break;
      case 'bandwidthPeriod':
        this.bandwidthPeriod = action;
        break;
    }

    if (command.indexOf('congestion') >= 0) {
      this.getCongestionGraphData();
    }

    if (command.indexOf('bandwidth') >= 0) {
      this.getBandwidthUsageData();
    }

    if (command === 'hardwareHealthPeriod') {
      this.getHardwareHealthGraphData();
    }
    if (command === 'radioTempFrequency') {
      this.getSelectedRadioTempChartData();
    }
  }

  toggleChannelCongestionGraph(): void {
    this.node.channelCongestionGraphOpen = !this.node.channelCongestionGraphOpen;
    this.mixpanel.storeEvent('NODES_EXPAND_CONGESTION_GRAPH', {
      NODE_ID: this.node.id,
      EXPANDED: this.node.channelCongestionGraphOpen
    });
    this.getCongestionGraphData();
  }

  getCongestionGraphData(): void {
    this.congestionStats = null;
    this.node.congestionChart = {
      interference: {
        series: {
          color: 'var(--chart-yellow)',
          text: 'congestionChartInterference',
          translation: 'nodes.node.channelCongestionGraph.congestionChartInterference'
        },
        axis: 'left',
        data: []
      },
      total: {
        series: {
          color: 'var(--chart-green)',
          text: 'congestionChartTotal',
          translation: 'nodes.node.channelCongestionGraph.congestionChartTotal'
        },
        axis: 'left',
        data: []
      }
    };

    this.nodeService
      .channelUtilization$(
        this.node.id,
        this.congestionFrequency,
        this.congestionPeriod === 1 ? 'hours' : 'days',
        this.congestionPeriod === 1 ? 24 : 7
      )
      .subscribe((response: any) => {
        const interference =
          response?.interference?.length > 0 ? this.fillEmpty(response.interference, this.congestionPeriod) : [];

        const total = response?.total?.length > 0 ? this.fillEmpty(response.total, this.congestionPeriod) : [];
        this.node.congestionChart.interference = new Line(
          new Series(
            'var(--chart-yellow)',
            'congestionChartInterference',
            'nodes.node.channelCongestionGraph.congestionChartInterference'
          ),
          'left',
          interference.map(
            (obj: any) =>
              new Point(
                new Date(obj.timestamp),
                obj.value !== null ? Math.round((obj.value + Number.EPSILON) * 100) / 100 : null
              )
          )
        );

        this.node.congestionChart.total = new Line(
          new Series(
            'var(--chart-green)',
            'congestionChartTotal',
            'nodes.node.channelCongestionGraph.congestionChartTotal'
          ),
          'left',
          total.map(
            (obj: any) =>
              new Point(
                new Date(obj.timestamp),
                obj.value !== null ? Math.round((obj.value + Number.EPSILON) * 100) / 100 : null
              )
          )
        );

        this.congestionStats =
          response.interference.length || response.total.length
            ? {
                interference: Math.round(this.statsCalc('mean', response.interference)) + '%',
                total: Math.round(this.statsCalc('mean', response.total)) + '%'
              }
            : null;
      });
  }

  toggleHardwareHealthGraph(): void {
    this.node.hardwareHealthGraphOpen = !this.node.hardwareHealthGraphOpen;

    this.mixpanel.storeEvent('NODES_EXPAND_HARDWARE_HEALTH_GRAPH', {
      NODE_ID: this.node.id,
      EXPANDED: this.node.hardwareHealthGraphOpen
    });
    if (this.node.hardwareHealthGraphOpen) {
      this.getHardwareHealthGraphData();
    }
  }

  clearHardwareHealthCharts(): void {
    this.node.hardwareHealthChart.cpuUtilPct = this.createLineChart([], 'var(--chart-yellow)', 'cpuUtil');
    this.node.hardwareHealthChart.memUsedPct = this.createLineChart([], 'var(--chart-yellow)', 'memUsed');
    this.node.hardwareHealthChart.fanRpm = this.createLineChart([], 'var(--chart-yellow)', 'fanRpm');
    this.node.hardwareHealthChart.uptime = this.createLineChart([], 'var(--chart-yellow)', 'uptime');
    this.node.hardwareHealthChart.freqBand24 = this.createLineChart([], 'var(--chart-yellow)', 'freqBand24');
    this.node.hardwareHealthChart.freqBand50 = this.createLineChart([], 'var(--chart-yellow)', 'freqBand50');
    this.node.hardwareHealthChart.freqBand60 = this.createLineChart([], 'var(--chart-yellow)', 'freqBand60');
    this.node.hardwareHealthChart.freqBand50low = this.createLineChart([], 'var(--chart-yellow)', 'freqBand50Low');
    this.node.hardwareHealthChart.freqBand50high = this.createLineChart([], 'var(--chart-yellow)', 'freqBand50High');
    this.getSelectedRadioTempChartData();
    this.node.hardwareHealthChart.psMemUtil = this.createLineChart([], 'var(--chart-yellow)', 'processMemoryUsed');
    this.node.hardwareHealthChart.psCpuUtil = this.createLineChart([], 'var(--chart-yellow)', 'processCpuUsed');
  }

  getHardwareHealthGraphData(): void {
    const { start, end } = this.getStartEndTime(this.hardwareHealthPeriod === 1 ? '24h' : '7d');
    this.latestHardwareSnapshot = null;
    this.latestRadioTemp = null;

    this.node.hardwareHealthChart = {
      cpuUtilPct: { axis: 'left', data: [] },
      memUsedPct: { axis: 'left', data: [] },
      radioTemp: { axis: 'left', data: [] },
      fanRpm: { axis: 'left', data: [] },
      freqBand50high: { axis: 'left', data: [] },
      freqBand24: { axis: 'left', data: [] },
      freqBand50low: { axis: 'left', data: [] },
      freqBand50: { axis: 'left', data: [] },
      freqBand60: { axis: 'left', data: [] },
      uptime: { axis: 'left', data: [] },
      psMemUtil: { axis: 'left', data: [] },
      psCpuUtil: { axis: 'left', data: [] }
    };

    this.selectedRadioTempData = [{ axis: 'left', data: [] }];

    this.nodeService.hardwareHealth$(start, end, this.hardwareHealthPeriod === 1 ? '15m' : '3h', 0).subscribe(
      (response: HardwareHealthNodeResponse[]) => {
        const targetNode = response.find((node: HardwareHealthNodeResponse) => node.node_id === this.node.id);
        if (targetNode) {
          const timeseries = targetNode.healthTimeseries.map((timeseries: HardwareHealthItemResponse) => ({
            timestamp: timeseries.endTs,
            uptimeInHrs: timeseries.uptime ? timeseries.uptime / 3600 : null,
            cpuUtilPct: timeseries.cpuUtilPct,
            memUsedPct: timeseries.memUsedPct,
            fanRpm: timeseries.fanRpm,
            freqBand24: timeseries.radioTemp?.find(
              (band: HardwareHealthRadioTempResponse) => band.freq_band === 'FREQ_BAND_24'
            )?.value,
            freqBand50low: timeseries.radioTemp?.find(
              (band: HardwareHealthRadioTempResponse) => band.freq_band === 'FREQ_BAND_50_LOW'
            )?.value,
            freqBand50high: timeseries.radioTemp?.find(
              (band: HardwareHealthRadioTempResponse) => band.freq_band === 'FREQ_BAND_50_HIGH'
            )?.value,
            freqBand50: timeseries.radioTemp?.find(
              (band: HardwareHealthRadioTempResponse) => band.freq_band === 'FREQ_BAND_50'
            )?.value,
            freqBand60: timeseries.radioTemp?.find(
              (band: HardwareHealthRadioTempResponse) => band.freq_band === 'FREQ_BAND_60'
            )?.value,
            psMemUtil: timeseries.psMemUtil,
            psCpuUtil: timeseries.psCpuUtil
          }));

          this.latestHardwareSnapshot = timeseries.length > 0 ? timeseries[timeseries.length - 1] : null;
          // TODO remove when server fixes duplicate null entries
          if (!this.latestHardwareSnapshot.cpuUtilPct) {
            this.latestHardwareSnapshot = timeseries.length >= 2 ? timeseries[timeseries.length - 2] : null;
          }

          this.processesForMemoryChartData = this.latestHardwareSnapshot.psMemUtil.map((process) => process.cmd);
          this.selectedProcessForMemoryChartData = this.processesForMemoryChartData[0];

          this.processesForCpuChartData = this.latestHardwareSnapshot.psCpuUtil.map((process) => process.cmd);
          this.selectedProcessForCpuChartData = this.processesForCpuChartData[0];

          // TODO filtering null entries due to server bug
          const filledCpuUtilPct = this.fillEmpty(
            timeseries
              .filter((timeEntry) => timeEntry.cpuUtilPct)
              .map((t) => ({ timestamp: t.timestamp, value: t.cpuUtilPct })),
            this.hardwareHealthPeriod
          );

          this.node.hardwareHealthChart.cpuUtilPct = this.createLineChart(
            filledCpuUtilPct,
            'var(--chart-yellow)',
            'cpuUtil'
          );

          const filledUptime = this.fillEmpty(
            timeseries
              .filter((timeEntry) => timeEntry.uptimeInHrs)
              .map((t) => ({ timestamp: t.timestamp, value: t.uptimeInHrs })),
            this.hardwareHealthPeriod
          );

          this.node.hardwareHealthChart.uptime = this.createLineChart(filledUptime, 'var(--chart-yellow)', 'uptime');

          const filledMemUsedPct = this.fillEmpty(
            timeseries
              .filter((timeEntry) => timeEntry.memUsedPct)
              .map((t) => ({ timestamp: t.timestamp, value: t.memUsedPct })),
            this.hardwareHealthPeriod
          );
          this.node.hardwareHealthChart.memUsedPct = this.createLineChart(
            filledMemUsedPct,
            'var(--chart-yellow)',
            'memUsed'
          );

          const filledRadioTempfreqBand50high = this.fillEmpty(
            timeseries
              .filter((timeEntry) => timeEntry.freqBand50high)
              .map((t) => ({ timestamp: t.timestamp, value: t.freqBand50high })),
            this.hardwareHealthPeriod
          );
          const filledRadioTempfreqBand24 = this.fillEmpty(
            timeseries
              .filter((timeEntry) => timeEntry.freqBand24)
              .map((t) => ({ timestamp: t.timestamp, value: t.freqBand24 })),
            this.hardwareHealthPeriod
          );
          const filledRadioTempFreqBand50low = this.fillEmpty(
            timeseries
              .filter((timeEntry) => timeEntry.freqBand50low)
              .map((t) => ({ timestamp: t.timestamp, value: t.freqBand50low })),
            this.hardwareHealthPeriod
          );
          this.node.hardwareHealthChart.freqBand50high = this.createLineChart(
            filledRadioTempfreqBand50high,
            'var(--chart-yellow)',
            'freqBand50High'
          );
          this.node.hardwareHealthChart.freqBand24 = this.createLineChart(
            filledRadioTempfreqBand24,
            'var(--chart-yellow)',
            'freqBand24'
          );
          this.node.hardwareHealthChart.freqBand50low = this.createLineChart(
            filledRadioTempFreqBand50low,
            'var(--chart-yellow)',
            'freqBand50Low'
          );

          const filledRadioTempFreqBand50 = this.fillEmpty(
            timeseries
              .filter((timeEntry) => timeEntry.freqBand50)
              .map((t) => ({ timestamp: t.timestamp, value: t.freqBand50 })),
            this.hardwareHealthPeriod
          );
          this.node.hardwareHealthChart.freqBand50 = this.createLineChart(
            filledRadioTempFreqBand50,
            'var(--chart-yellow)',
            'freqBand50'
          );

          const filledRadioTempFreqBand60 = this.fillEmpty(
            timeseries
              .filter((timeEntry) => timeEntry.freqBand60)
              .map((t) => ({ timestamp: t.timestamp, value: t.freqBand60 })),
            this.hardwareHealthPeriod
          );
          this.node.hardwareHealthChart.freqBand60 = this.createLineChart(
            filledRadioTempFreqBand60,
            'var(--chart-yellow)',
            'freqBand60'
          );

          this.getSelectedRadioTempChartData();

          const filledFanRpm = this.fillEmpty(
            timeseries
              .filter((timeEntry) => timeEntry.fanRpm)
              .map((t) => ({ timestamp: t.timestamp, value: t.fanRpm })),
            this.hardwareHealthPeriod
          );
          this.node.hardwareHealthChart.fanRpm = this.createLineChart(filledFanRpm, 'var(--chart-yellow)', 'fanRpm');

          this.node.hardwareHealthChart.psMemUtilSet = [];

          for (let counter = 0; counter < this.processesForMemoryChartData.length; counter++) {
            const processName = this.processesForMemoryChartData[counter];
            const psMemUtil = this.fillEmpty(
              timeseries
                .filter((timeEntry) => timeEntry.psMemUtil[0]?.util != null)
                .map((t) => ({
                  timestamp: t.timestamp,
                  value: t.psMemUtil.filter((process) => process.cmd === processName)?.[0]?.util
                })),
              this.hardwareHealthPeriod
            );

            this.node.hardwareHealthChart.psMemUtilSet[counter] = this.createLineChart(
              psMemUtil,
              'var(--chart-yellow)',
              'processMemoryUsed'
            );
          }

          this.node.hardwareHealthChart.psMemUtil = this.node.hardwareHealthChart.psMemUtilSet[0];

          this.node.hardwareHealthChart.psCpuUtilSet = [];

          for (let counter = 0; counter < this.processesForCpuChartData.length; counter++) {
            const processName = this.processesForCpuChartData[counter];
            const psCpuUtil = this.fillEmpty(
              timeseries
                .filter((timeEntry) => timeEntry.psCpuUtil[0]?.util != null)
                .map((t) => ({
                  timestamp: t.timestamp,
                  value: t.psCpuUtil.filter((process) => process.cmd === processName)?.[0]?.util
                })),
              this.hardwareHealthPeriod
            );

            this.node.hardwareHealthChart.psCpuUtilSet[counter] = this.createLineChart(
              psCpuUtil,
              'var(--chart-yellow)',
              'processCpuUtil'
            );
          }

          this.node.hardwareHealthChart.psCpuUtil = this.node.hardwareHealthChart.psCpuUtilSet[0];
        } else {
          this.clearHardwareHealthCharts();
        }
      },
      () => {
        this.clearHardwareHealthCharts();
      }
    );
  }

  fillEmpty(data: any[], period: number): any[] {
    const current = this.round(period === 1 ? 'minute' : 'hours', period === 1 ? 15 : 3, moment());
    const ticks = [];

    for (let i = 0; i < (period === 1 ? 97 : 57); i++) {
      const timestamp = current.utc().format('YYYY-MM-DD[T]HH:mm:ss.[000Z]');
      const value = data.find((tick: any) => tick.timestamp === timestamp)?.value || null;
      ticks.push({ timestamp, value });
      current.subtract(period === 1 ? 15 : 180, 'minutes');
    }

    return ticks;
  }

  selectProcessForMemoryChartData(processName: string): void {
    this.selectedProcessForMemoryChartData = processName;
    this.node.hardwareHealthChart.psMemUtil =
      this.node.hardwareHealthChart.psMemUtilSet[this.processesForMemoryChartData.indexOf(processName)];
  }

  selectProcessForCpuChartData(processName: string): void {
    this.selectedProcessForCpuChartData = processName;
    this.node.hardwareHealthChart.psCpuUtil =
      this.node.hardwareHealthChart.psCpuUtilSet[this.processesForCpuChartData.indexOf(processName)];
  }

  getSelectedRadioTempChartData(): void {
    switch (this.radioTempFrequency) {
      case 'FREQ_BAND_24':
        this.selectedRadioTempData = [this.node.hardwareHealthChart?.freqBand24];
        this.latestRadioTemp = this.latestHardwareSnapshot?.freqBand24 || null;
        break;
      case 'FREQ_BAND_50_LOW':
        this.selectedRadioTempData = [this.node.hardwareHealthChart?.freqBand50low];
        this.latestRadioTemp = this.latestHardwareSnapshot?.freqBand50low || null;
        break;
      case 'FREQ_BAND_50_HIGH':
        this.selectedRadioTempData = [this.node.hardwareHealthChart?.freqBand50high];
        this.latestRadioTemp = this.latestHardwareSnapshot?.freqBand50high || null;
        break;
      case 'FREQ_BAND_50':
        this.selectedRadioTempData = [this.node.hardwareHealthChart?.freqBand50];
        this.latestRadioTemp = this.latestHardwareSnapshot?.freqBand50 || null;
        break;
      case 'FREQ_BAND_60':
        this.selectedRadioTempData = [this.node.hardwareHealthChart?.freqBand60];
        this.latestRadioTemp = this.latestHardwareSnapshot?.freqBand60 || null;
        break;
      default:
        this.selectedRadioTempData = [];
    }
  }

  private createLineChart(data: any[], color: string, text: string): Line {
    return new Line(
      new Series(color, text, `nodes.node.hardwareHealth.${text}`),
      'left',
      data.map((obj: any) => {
        if (['freqBand50High', 'freqBand24', 'freqBand50Low', 'loadFifteen', 'loadFive', 'loadOne'].includes(text)) {
          return new Point(new Date(obj.timestamp), obj.value !== null ? obj.value : null);
        }

        return new Point(
          new Date(obj.timestamp),
          obj.value !== null
            ? obj.value % 1 !== 0
              ? Math.round((obj.value + Number.EPSILON) * 100) / 100
              : obj.value
            : null
        );
      })
    );
  }

  private getStartEndTime(value: '7d' | '24h'): { start: string; end: string } {
    return {
      start:
        value === '7d'
          ? moment().subtract(this.historyDays, 'days').toISOString()
          : moment().subtract(24, 'hours').toISOString(),
      end: moment().toISOString()
    };
  }

  round(mode: any, interval: number, moment: any): any {
    if (mode === 'minute') {
      return moment
        .utc()
        .clone()
        .minute(Math.floor(moment.minute() / interval) * interval)
        .second(0);
    } else {
      return moment
        .utc()
        .clone()
        .hours(Math.round(moment.hours() / interval) * interval)
        .minute(0)
        .second(0);
    }
  }

  toggleBandwidthUsageGraph(): void {
    this.node.bandwidthUsageGraphOpen = !this.node.bandwidthUsageGraphOpen;
    this.mixpanel.storeEvent('NODES_EXPAND_BANDWIDTH_GRAPH', {
      NODE_ID: this.node.id,
      EXPANDED: this.node.bandwidthUsageGraphOpen
    });
    this.getBandwidthUsageData();
  }

  getBandwidthUsageData(): void {
    this.bandwidthStats = null;
    this.node.bandwidthChart = {
      received: {
        series: {
          color: 'var(--chart-yellow)',
          text: 'bandwidthChartReceived',
          translation: 'nodes.node.bandwidthUsageGraph.bandwidthChartReceived'
        },
        axis: 'left',
        data: []
      },
      transmitted: {
        series: {
          color: 'var(--chart-green)',
          text: 'bandwidthChartTransmitted',
          translation: 'nodes.node.bandwidthUsageGraph.bandwidthChartTransmitted'
        },
        axis: 'left',
        data: []
      }
    };

    this.nodeService
      .bandwidth$(
        this.node.id,
        this.bandwidthFrequency,
        this.bandwidthPeriod === 1 ? 'hours' : 'days',
        this.bandwidthPeriod === 1 ? 24 : 7
      )
      .subscribe((response: any) => {
        this.node.bandwidthChart.received = new Line(
          new Series(
            'var(--chart-yellow)',
            'bandwidthChartReceived',
            'nodes.node.bandwidthUsageGraph.bandwidthChartReceived'
          ),
          'left',
          response.received.map(
            (obj: any) =>
              new Point(
                new Date(obj.timestamp),
                obj.value !== null ? Math.round((obj.value + Number.EPSILON) * 100) / 100 : null
              )
          )
        );

        this.node.bandwidthChart.transmitted = new Line(
          new Series(
            'var(--chart-green)',
            'bandwidthChartTransmitted',
            'nodes.node.bandwidthUsageGraph.bandwidthChartTransmitted'
          ),
          'left',
          response.transmitted.map(
            (obj: any) =>
              new Point(
                new Date(obj.timestamp),
                obj.value !== null ? Math.round((obj.value + Number.EPSILON) * 100) / 100 : null
              )
          )
        );

        this.bandwidthStats =
          response.received.length || response.transmitted.length
            ? {
                received: Math.round(this.statsCalc('sum', response.received)) + ' MB',
                transmitted: Math.round(this.statsCalc('sum', response.transmitted)) + ' MB'
              }
            : null;
      });
  }

  statsCalc(mode: string, data: any[]): number {
    let sum = 0;
    data.forEach((item: any) => (sum += item.value));

    if (mode === 'mean') {
      return sum / data.length;
    }

    if (mode === 'sum') {
      return sum;
    }

    return 0;
  }

  launchRename(): void {
    if (!this.node.isRename) {
      this.nodeService.setLedMode$(this.node.id, 'locate').subscribe(() => {
        this.mixpanel.storeEvent('NODES_RENAME_ENABLE', { NODE_ID: this.node.id });
        this.node.isRename = true;

        setTimeout(() => {
          this.input.nativeElement.focus();
        }, 100);
      });
    } else {
      this.node.isRename = false;
    }
  }

  confirmRename(): void {
    this.nodeService.setLedMode$(this.node.id, 'normal').subscribe(() => {
      const currentName = this.node.nickname || this.node.name;

      if (currentName !== this.name.value) {
        this.nodeService.rename$(this.node.id, this.name.value).subscribe(
          () => {
            this.mixpanel.storeEvent('NODES_RENAME_SUCCESS', {
              NODE_ID: this.node.id,
              OLD_NAME: currentName,
              NEW_NAME: this.name.value
            });

            this.node.nickname = this.name.value;
            this.node.isRename = false;
            this.name.reset();

            this.store.dispatch(pollingPull({ debugSource: 'node rename' }));

            this.toast.success('toast.nodeStrip.successNameMessage', 'toast.nodeStrip.successNameTitle');
          },
          (error: any) => {
            this.mixpanel.storeEvent('NODES_RENAME_ERROR', {
              NODE_ID: this.node.id,
              OLD_NAME: currentName,
              NEW_NAME: this.name.value,
              ERROR: error.error.error.message
            });

            this.node.isRename = false;
            this.name.reset();

            this.toast.error('toast.nodeStrip.errorNameMessage', 'toast.nodeStrip.errorNameTitle', {
              params: {
                error: error.error.error.message
              }
            });
          }
        );
      } else {
        this.node.isRename = false;
        this.name.reset();
      }
    });
  }

  cancelRename(): void {
    this.nodeService.setLedMode$(this.node.id, 'normal').subscribe(() => {
      this.mixpanel.storeEvent('NODES_RENAME_DISABLE', { NODE_ID: this.node.id });
      this.node.isRename = false;
      this.name.reset();
    });
  }

  reboot(): void {
    this.mixpanel.storeEvent('NODES_REBOOT_DIALOG', { NODE_ID: this.node.id });
    this.modal
      .showDialog('nodes.node.modal.messageReboot', 'nodes.node.modal.title', {
        buttons: [
          { style: 'tertiary light', value: 'nodes.node.modal.cancel' },
          { style: 'super-primary', value: 'nodes.node.modal.reboot' }
        ]
      })
      .subscribe((response: any) => {
        if (response.item?.value === 'nodes.node.modal.reboot') {
          this.loading = true;
          this.mixpanel.storeEvent('NODES_REBOOT_CONFIRM', { NODE_ID: this.node.id });
          this.troubleshoot.resetNode(this.node.id).subscribe();
        }
      });
  }

  setLedMode(mode: 'locate' | 'normal'): void {
    this.nodeService.setLedMode$(this.node.id, mode).subscribe(() => {
      this.mixpanel.storeEvent('NODES_LOCATE', { NODE_ID: this.node.id });
    });

    if (this.ledMode === 'normal') {
      this.ledMode = 'locate';
    } else {
      this.ledMode = 'normal';
    }
  }

  getUpriseUrl() {
    const env = this.plume.getEnv();
    if (!this.node.isUprise || !this.node.partnerId || !this.node.locationId || !env?.upriseApplicationUrl) return;
    return `${env.upriseApplicationUrl}/partner/${this.node.partnerId}/location/${this.node.locationId}`;
  }

  openUprise() {
    if (this.getUpriseUrl()) window.open(this.getUpriseUrl());
  }

  deleteNode(id: string): void {
    this.isUprise$
      .pipe(
        take(1),
        filter((isUprise) => !isUprise),
        switchMap(() => this.nodeService.deleteNode$(id, false, true))
      )
      .subscribe(
        () => {
          this.delete.emit(id);
          this.mixpanel.storeEvent('NODES_DELETE_NODE_SUCCESS', { NODE_ID: id });
          this.toast.success('toast.node.successNodeDeletedMessage', 'toast.node.successNodeDeletedTitle', {
            params: {
              id
            }
          });
        },
        (error: any) => {
          this.mixpanel.storeEvent('NODES_DELETE_NODE_ERROR', { NODE_ID: id, ERROR: error.error.error.message });
          this.toast.error(error.error.error.message, 'toast.node.errorFindNodeTitle');
        }
      );
  }

  launchDeleteNode(): void {
    this.isUprise$
      .pipe(
        take(1),
        filter((isUprise) => !isUprise),
        tap(() => this.mixpanel.storeEvent('NODES_DELETE_NODE_DIALOG', { NODE_ID: this.node.id })),
        switchMap(() =>
          this.modal.showDialog('nodes.node.modal.message', 'nodes.node.modal.title', {
            params: { name: this.node.nickname || this.node.defaultName },
            buttons: [
              { style: 'tertiary light', value: 'nodes.node.modal.cancel' },
              { style: 'super-primary', value: 'nodes.node.modal.confirm' }
            ]
          })
        )
      )
      .subscribe((response) => {
        if (response.item?.value === 'nodes.node.modal.confirm') {
          this.deleteNode(this.node.id);
        }
      });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
