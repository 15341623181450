import { Injectable } from '@angular/core';
import { BarChart } from './models/charts/bar.chart';
import { HistogramChart } from './models/charts/histogram.chart';
import { MultibarChart } from './models/charts/multibar.chart';
import { LineChart } from './models/charts/line.chart';
import { CurvedlineChart } from './models/charts/curvedline.chart';
import { ForceChart } from './models/charts/force.chart';
import { TreeChart } from './models/charts/tree.chart';
import { SpeedtestChart } from './models/charts/speedtest.chart';
import { SpeedresultsChart } from './models/charts/speedresults.chart';
import { QoeliveChart } from './models/charts/qoelive.chart';
import { NodeChart } from './models/charts/node.chart';
import { QoethroughputChart } from './models/charts/qoethroughput.chart';
import { SecurityChart } from './models/charts/security.chart';
import { TimelinesChart } from './models/charts/timelines.chart';
import { GroupsChart } from './models/charts/groups.chart';
import { RecentEventsChart } from './models/charts/recentevents.chart';

@Injectable()
export class D3Service {
  constructor() {}

  generate(type: string): any {
    switch (type) {
      case 'barchart':
        return new BarChart();
      case 'histogramchart':
        return new HistogramChart();
      case 'multibarchart':
        return new MultibarChart();
      case 'linechart':
        return new LineChart();
      case 'qoelivechart':
        return new QoeliveChart();
      case 'nodechart':
        return new NodeChart();
      case 'groupschart':
        return new GroupsChart();
      case 'qoethroughputchart':
        return new QoethroughputChart();
      case 'securitychart':
        return new SecurityChart();
      case 'curvedlinechart':
        return new CurvedlineChart();
      case 'forcechart':
        return new ForceChart();
      case 'treechart':
        return new TreeChart();
      case 'speedtestchart':
        return new SpeedtestChart();
      case 'speedresultschart':
        return new SpeedresultsChart();
      case 'timelineschart':
        return new TimelinesChart();
      case 'recenteventschart':
        return new RecentEventsChart();
      default:
        return new BarChart();
    }
  }
}
