<h1>{{ 'lte.title' | translate }}</h1>

<div class="ongoingOutage" *ngIf="outgoingOutageMin$ | async | cache as ongoingOutageTimeInMinutes">
  <span class="label">{{ 'lte.ongoingOutage' | translate }}:</span>
  <span class="value">{{ 'lte.ongoingOutageValue' | translate: { value: ongoingOutageTimeInMinutes } }}</span>
</div>
<div class="columns two">
  <div class="outage month">
    <div class="header">
      <div class="title">{{ 'lte.usageTitle' | translate }}</div>
      <div class="duration">{{ durationString((monthOutageInfo.outage$ | async)?.data, 'monthly') }}</div>
      <div class="unit">{{ 'lte.month' | translate }}</div>
    </div>
    <div class="navigator">
      <div class="position">{{ monthOutageInfo.endTime.format('MMM YYYY') }}</div>
      <icon name="icon-arrow-down" class="rotate90" (click)="outagePreviousMonth()"></icon>
      <icon
        name="icon-arrow-down"
        class="rotate270 right"
        [class.disabled]="!monthOutageInfo.nextAvailable"
        (click)="outageNextMonth()"
      ></icon>
    </div>
    <flm-graph [dataSet]="monthOutageInfo.dataSet" [borders]="{ top: 5, right: 10, bottom: 32, left: 45 }"></flm-graph>
  </div>
  <div class="outage year">
    <div class="header">
      <div class="title">{{ 'lte.outageTitle' | translate }}</div>
      <div class="duration">{{ durationString((yearOutageInfo.outage$ | async)?.data, 'yearly') }}</div>
      <div class="unit">{{ 'lte.year' | translate }}</div>
    </div>
    <div class="navigator">
      <div class="position">{{ yearOutageInfo.endTime.format('YYYY') }}</div>
      <icon name="icon-arrow-down" class="rotate90" (click)="outagePreviousYear()"></icon>
      <icon
        name="icon-arrow-down"
        class="rotate270 right"
        [class.disabled]="!yearOutageInfo.nextAvailable"
        (click)="outageNextYear()"
      ></icon>
    </div>
    <flm-graph [dataSet]="yearOutageInfo.dataSet" [borders]="{ top: 5, right: 10, bottom: 32, left: 45 }"></flm-graph>
  </div>
</div>

<div class="usageControls">
  <slidetoggler
    class="usageDurationToggle"
    [options]="usage.usageDurationList"
    (toggle)="usageDurationChange($event)"
  ></slidetoggler>
  <div class="position">
    {{ usage.endTime.format(this.usage.selectedUsageDuration === 'monthly' ? 'MMM YYYY' : 'YYYY') }}
  </div>
  <icon name="icon-arrow-down" class="rotate90" (click)="usagePrevious()"></icon>
  <icon
    name="icon-arrow-down"
    class="rotate270 right"
    [class.disabled]="!usage.nextAvailable"
    (click)="usageNext()"
  ></icon>
</div>

<div class="columns three">
  <div class="devicesCategory" *ngFor="let category of ['Laptop', 'Tablet', 'Smart Phone']">
    <div class="header">
      <div class="title">{{ 'lte.categoryTitle.' + category | translate }}</div>
      <div class="count">{{ getUsageByCategory(usage.usage$ | async, category)?.statistics?.deviceSum || 0 }}</div>
      <div class="usage">{{ round(getUsageByCategory(usage.usage$ | async, category)?.statistics?.usageSum) }}%</div>
    </div>
    <div
      class="list deviceList"
      *ngFor="let device of getUsageByCategory(usage.usage$ | async, category)?.devices"
      [routerLink]="plume.hidePersonalDetails() ? [] : ['../devices', device.mac]"
    >
      <icon class="size-2" [iconV2]="device.iconV2"></icon>
      <div class="data">
        <div class="name">{{ device.name | macAddr: true }}</div>
        <div class="progress"><div class="value" [style.width.%]="device.deviceUsagePercentage"></div></div>
        <div class="numericValue">{{ device.deviceUsagePercentage }}%</div>
      </div>
    </div>
  </div>
</div>
