import { scaleBand, scaleLinear } from 'd3';
import { Bar } from '../objects/bar';
import { Tick } from '../objects/tick';

export class SpeedresultsChart {
  private data: any[];
  private bars: Bar[];
  private width: number;
  private height: number;
  private xScale: any;
  private yScale: any;
  private margins: any = {
    top: 100,
    right: 80,
    bottom: 60,
    left: 80
  };

  constructor() {}

  calculateWidth(): number {
    return this.width - this.margins.left - this.margins.right;
  }

  calculateHeight(): number {
    return this.height - this.margins.top - this.margins.bottom;
  }

  prepareScales(): void {
    this.xScale = scaleLinear().range([0, this.calculateWidth()]);
    this.yScale = scaleBand()
      .range([this.calculateHeight(), 0])
      .padding(0.15);
  }

  prepareDomains(): void {
    const data = this.data.reduce((accumulator, current) => accumulator + current.value, 0);

    if (data) {
      this.xScale.domain([
        Math.min(0, ...this.data.map((d: any) => d.value)),
        Math.max(0, ...this.data.map((d: any) => d.value))
      ]);
    }

    this.yScale.domain(this.data.map((d: any) => d.label));
  }

  calculateBars(): void {
    this.bars = this.data.map((bar: any) => {
      bar.class = 'bar';
      bar.x = 0;
      bar.y = this.yScale(bar.label);
      bar.width = this.xScale(bar.value);
      bar.height = this.yScale.bandwidth();
      return bar;
    });
  }

  yAxis(): Tick[] {
    return this.yScale.domain().map((tick: any) => new Tick(
        'yaxis',
        tick,
        'translate(-60,' + (this.yScale(tick) + this.yScale.bandwidth() / 2) + ')',
        { y: 5 },
        true,
        { x1: 61, y1: -this.yScale.bandwidth() / 2, x2: 61, y2: this.yScale.bandwidth() / 2 }
      ));
  }

  update(data: any[], width?: number, height?: number, margins?: any): Bar[] {
    this.data = data;

    if (width) {
      this.width = width;
    }
    if (height) {
      this.height = height;
    }
    if (margins) {
      this.margins = margins;
    }

    this.prepareScales();
    this.prepareDomains();

    this.calculateBars();

    return this.bars;
  }
}
