import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TroubleshootingService } from 'src/app/lib/services/troubleshooting.service';
import { ToastService } from 'src/app/lib/services/toast.service';
import { MessageService } from 'src/app/lib/services/message.service';
import { LoggingService } from 'src/app/lib/services/logging.service';
import { selectLocationBbe } from 'src/app/store/polling/polling.selector';
import { Store } from '@ngrx/store';

@Component({
  selector: 'speedtestbubble',
  templateUrl: './speedTestBubble.component.html',
  styleUrls: ['../bubbles.scss']
})
export class SpeedTestBubbleComponent implements OnInit, OnDestroy {
  @Input()
  nodeid: string;
  @Input()
  optimization: string;

  stepState: string = 'init';
  optimized: string = '';
  showProgress: boolean = false; // show progress bar in polling
  speedtest: any = { download: 0, upload: 0 };
  testInterval: any;

  bbeSubscription: any;
  bbe: any;

  thresholds: any = {
    networkSpeed: {
      bbe: 0.5, // Low Extender Broadband Efficiency
      standardDeviation: 1, // Inconsistent ISP Speed
      extenderSpeedRatio: 0.5, // Low Extender Speed for PODS
      health: 2, // of 5
      extenderTestRatio: 0.5
    },
    deviceAndPodQoe: {
      podHealth: 2,
      deviceHealth: 2
    }
  };

  timeoutInterval: any;
  timeout: number = 60000;

  constructor(
    private toast: ToastService,
    private message: MessageService,
    private logging: LoggingService,
    private store: Store,
    private troubleshoot: TroubleshootingService
  ) {}

  ngOnInit(): void {}

  setTimeout(): void {
    this.clearTimeout();
    this.timeoutInterval = setInterval(() => {
      this.logging.error('Speed test timeout with no response!');
      this.toast.error('toast.speedTestBubble.errorMessage', 'toast.speedTestBubble.errorTitle', {
        disableTimeOut: true
      });
      this.showProgress = false;
      this.stepState = 'fail';
      this.clearTimeout();
    }, this.timeout);
  }

  clearTimeout(): void {
    if (this.timeoutInterval) {
      clearInterval(this.timeoutInterval);
    }
    if (this.testInterval) {
      clearInterval(this.testInterval);
    }
  }

  sendMessage(success: boolean): void {
    this.logging.log('SPEED TEST complete: ', success);
    this.message.sendMessage({ source: 'speed', success });
  }
  round(num: number): number {
    return Math.round(num);
  }

  doTestExtender(): void {
    this.logging.log('TRIGGER SPEED TEST' + this.nodeid);
    if (this.bbeSubscription) {
      this.bbeSubscription.unsubscribe();
    }
    this.bbeSubscription = this.store.select(selectLocationBbe).subscribe((response: any) => {
      if (response) {
        this.bbe = response;
        this.doTestLoop();
      }
    });
  }

  doTestLoop(): void {
    if (this.bbeSubscription) {
      this.bbeSubscription.unsubscribe();
    }
    if (this.stepState !== 'busy' && this.stepState !== 'finished') {
      const testStartTime = +new Date();

      this.troubleshoot.triggerNodeSpeedTest(this.nodeid).subscribe((response: any) => {
        this.setTimeout();

        this.logging.log('SPEED TEST BBE: ', response);
        this.stepState = 'busy';
        this.showProgress = true;

        clearInterval(this.testInterval);
        this.testInterval = setInterval(() => {
          this.troubleshoot.checkSpeedTestStatus(this.nodeid).subscribe((response: any) => {
            this.logging.log('SPEED TEST CHECK ', response);

            if (response.downloadSpeeds[0]) {
              const testTime = +new Date(response.downloadSpeeds[0].timestamp);
              if (testTime - testStartTime > 0) {
                this.clearTimeout();
                this.speedtest.download = response.downloadSpeeds[0].value;
                this.speedtest.upload = response.uploadSpeeds[0].value;
                const broadbandDownloadSpeed = this.bbe.summary.twentyFourHours.broadbandDownloadSpeed.average;
                const alarmRatio = this.speedtest.download / broadbandDownloadSpeed;
                clearInterval(this.testInterval);
                this.showProgress = false;

                this.logging.log(' speedtest.download: ', this.speedtest.download);
                this.logging.log(' broadbandDownloadSpeed: ', broadbandDownloadSpeed);
                this.logging.log(' alarmRatio: ', alarmRatio);
                this.logging.log(' thresholds: ', this.thresholds.networkSpeed.extenderTestRatio);

                if (alarmRatio < this.thresholds.networkSpeed.extenderTestRatio) {
                  // this.step = 'speedFixed';
                  this.stepState = 'finished';
                  if (this.testInterval) {
                    clearInterval(this.testInterval);
                  }
                  this.sendMessage(false);
                } else {
                  this.stepState = 'finished';
                  if (this.testInterval) {
                    clearInterval(this.testInterval);
                  }
                  this.sendMessage(true);
                }
              }
            }
          });
        }, 3000);
      });
    }
  }

  ngOnDestroy(): void {
    this.clearTimeout();

    if (this.bbeSubscription) {
      this.bbeSubscription.unsubscribe();
    }
  }
}
