export class ChannelHelper {
  colors: any = {
    0: '#CCCCCC',
    1: '#00BCD4',
    2: '#00BCD4',
    3: '#00BCD4',
    4: '#00BCD4',
    5: '#00BCD4',
    6: '#2196F3',
    7: '#3F51B5',
    8: '#3F51B5',
    9: '#CC9966',
    11: '#3F51B5',
    13: '#CC9966',
    17: '#CC9966',
    20: '#CC9966',
    21: '#CC9966',
    25: '#CC9966',
    29: '#CC9966',
    30: '#CC9966',
    33: '#CC9966',
    36: '#C93E93',
    37: '#C93E93',
    40: '#C93E93',
    41: '#C93E93',
    44: '#C93E93',
    45: '#C93E93',
    48: '#C93E93',
    49: '#C93E93',
    52: '#FF6293',
    53: '#FF6293',
    56: '#FF6293',
    57: '#FF6293',
    60: '#FF6293',
    61: '#FF6293',
    64: '#FF6293',
    65: '#FF6293',
    69: '#FF6293',
    73: '#FF6293',
    77: '#FF6293',
    81: '#FF6293',
    85: '#FF6293',
    89: '#FF6293',
    93: '#FF6293',
    97: '#FF6293',
    100: '#CCCC88',
    101: '#CCCC88',
    104: '#CCCC88',
    105: '#CCCC88',
    108: '#CCCC88',
    109: '#CCCC88',
    112: '#CCCC88',
    113: '#CCCC88',
    116: '#77EEFF',
    117: '#77EEFF',
    120: '#77EEFF',
    121: '#77EEFF',
    124: '#77EEFF',
    125: '#77EEFF',
    128: '#77EEFF',
    129: '#77EEFF',
    132: '#AB98FE',
    133: '#AB98FE',
    136: '#AB98FE',
    137: '#AB98FE',
    140: '#AB98FE',
    141: '#AB98FE',
    144: '#AB98FE',
    145: '#AB98FE',
    149: '#9C27B0',
    153: '#9C27B0',
    157: '#9C27B0',
    161: '#9C27B0',
    165: '#9C27B0',
    169: '#9C27B0',
    173: '#9C27B0',
    177: '#9C27B0',
    181: '#9C27B0',
    185: '#9C27B0',
    189: '#9C27B0',
    193: '#9C27B0',
    197: '#9C27B0',
    201: '#9C27B0',
    205: '#9C27B0',
    209: '#9C27B0',
    213: '#9C27B0',
    217: '#9C27B0',
    221: '#9C27B0',
    225: '#9C27B0',
    229: '#9C27B0',
    233: '#9C27B0'
  };

  getColor(channel: number): string {
    return this.colors[channel];
  }

  getContrastColor(hex: string): string {
    if (!hex) {
      return 'white';
    }
    const hexCode = hex.charAt(0) === '#' ? hex.substring(1, 6) : hex;
    const red = parseInt(hexCode.substring(0, 2), 16);
    const green = parseInt(hexCode.substring(2, 4), 16);
    const blue = parseInt(hexCode.substring(4, 6), 16);

    return red * 0.299 + green * 0.587 + blue * 0.114 > 150 ? 'black' : 'white';
  }
}
