import { Component, Input, ElementRef, OnChanges, AfterViewInit, OnDestroy } from '@angular/core';
import { D3Service } from 'src/app/lib/d3/service';
import { SpeedresultsChart } from 'src/app/lib/d3/models/charts/speedresults.chart';
import { Tick } from 'src/app/lib/d3/models/objects/tick';
import { Bar } from 'src/app/lib/d3/models/objects/bar';
import * as erdm from 'element-resize-detector';

@Component({
  selector: 'speedresultschart',
  templateUrl: './speedresults.component.html',
  styleUrls: ['./speedresults.component.scss']
})
export class SpeedresultsChartVisualComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input()
  title: string;
  @Input()
  data: Bar[];

  chart: SpeedresultsChart;
  bars: Bar[];
  yAxis: Tick[];
  margins: any = {
    top: 30,
    right: 20,
    bottom: 0,
    left: 60
  };

  erd: any = erdm({ strategy: 'scroll' });

  constructor(private d3: D3Service, private host: ElementRef) {
    this.chart = this.d3.generate('speedresultschart');
  }

  ngAfterViewInit(): void {
    this.erd.listenTo(this.host.nativeElement, () => {
      this.render();
    });
  }

  ngOnChanges(): void {
    this.render();
  }

  render(): void {
    this.bars = this.chart.update(
      this.data,
      this.host.nativeElement.clientWidth,
      this.host.nativeElement.clientHeight,
      this.margins
    );

    this.yAxis = this.chart.yAxis();
  }

  ngOnDestroy(): void {
    this.erd.uninstall(this.host.nativeElement);
  }
}
