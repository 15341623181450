<div class="row">
  <div class="col-xs-12">
    <div class="bubble alarm">
      <h3>{{ 'health.networkStatus.neighborReportAlarm.alarms' | translate }}:</h3>
      <div class="section" *ngIf="alarm?.evilTwins?.length">
        <p>{{ 'health.networkStatus.neighborReportAlarm.evilTwinHeading' | translate }}</p>
        <div class="recommendationMessage">
          {{ 'health.networkStatus.neighborReportAlarm.evilTwinExplaination' | translate }}
        </div>

        <div class="header">
          <div class="ssid">{{ 'health.networkStatus.neighborReportAlarm.ssid' | translate }}</div>
          <div class="mac">{{ 'health.networkStatus.neighborReportAlarm.neighborId' | translate }}</div>
          <div class="channel">{{ 'health.networkStatus.neighborReportAlarm.channel' | translate }}</div>
          <div class="channelWidth">{{ 'health.networkStatus.neighborReportAlarm.channelWidth' | translate }}</div>
          <div class="snr">{{ 'health.networkStatus.neighborReportAlarm.snr' | translate }}</div>
          <div class="freqBand">{{ 'health.networkStatus.neighborReportAlarm.freqBand' | translate }}</div>
        </div>
        <div class="neighbor" *ngFor="let evilTwin of alarm.evilTwins">
          <div class="ssid">{{ evilTwin.neighborSsid }}</div>
          <div class="mac">{{ evilTwin.neighborId }}</div>
          <div class="channel">{{ evilTwin.channel }}</div>
          <div class="channelWidth">{{ evilTwin.channelWidth }}</div>
          <div class="snr">{{ evilTwin.rssi }}</div>
          <div class="freqBand">{{ evilTwin.freqBand }}</div>
        </div>
        <div class="recommendationMessage">
          {{ 'health.networkStatus.neighborReportAlarm.evilTwinRecommendationMessage' | translate }}
        </div>
      </div>
      <div class="section" *ngIf="alarm?.parallelNetworks?.length">
        <p>{{ 'health.networkStatus.neighborReportAlarm.parallelHeading' | translate }}</p>
        <div class="recommendationMessage">
          {{ 'health.networkStatus.neighborReportAlarm.parallelNetworkExplaination' | translate }}
        </div>
        <div class="tableContainer">
          <div class="header">
            <div class="ssid">{{ 'health.networkStatus.neighborReportAlarm.ssid' | translate }}</div>
            <div class="mac">{{ 'health.networkStatus.neighborReportAlarm.neighborId' | translate }}</div>
            <div class="channel">{{ 'health.networkStatus.neighborReportAlarm.channel' | translate }}</div>
            <div class="channelWidth">{{ 'health.networkStatus.neighborReportAlarm.channelWidth' | translate }}</div>
            <div class="snr">{{ 'health.networkStatus.neighborReportAlarm.snr' | translate }}</div>
            <div class="freqBand">{{ 'health.networkStatus.neighborReportAlarm.freqBand' | translate }}</div>
          </div>
          <div class="neighbor" *ngFor="let parallelNetwork of alarm.parallelNetworks">
            <div class="ssid">
              {{ parallelNetwork.neighborSsid || 'health.networkStatus.neighborReportAlarm.hidden' | translate }}
            </div>
            <div class="mac">{{ parallelNetwork.neighborId }}</div>
            <div class="channel">{{ parallelNetwork.channel }}</div>
            <div class="channelWidth">{{ parallelNetwork.channelWidth }}</div>
            <div class="snr">{{ parallelNetwork.rssi }}</div>
            <div class="freqBand">{{ parallelNetwork.freqBand }}</div>
          </div>
        </div>
        <div class="recommendationMessage">
          {{ 'health.networkStatus.neighborReportAlarm.recommendationMessage' | translate }}
        </div>
      </div>
    </div>
    <div class="bubble list">
      <div class="title" (click)="showList = !showList">
        <h3>{{ 'health.networkStatus.neighborReportAlarm.neighborTableTitle' | translate }}</h3>
        <icon name="icon-arrow-down" class="size-0_8" [class.rotate180]="showList"></icon>
      </div>

      <div class="subtitle" *ngIf="hasTimestamp">
        {{ 'neighborsReport.lastReportDate' | translate: { timestamp: timestamp } }}
      </div>

      <neighborBubble
        *ngIf="showList"
        [neighbors]="alarm.neighbors"
        [markEvil]="true"
        [markParallel]="true"
      ></neighborBubble>
    </div>
  </div>
</div>
