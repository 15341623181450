import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'flm-graph-tool-tip',
  templateUrl: './graph-tool-tip.component.html',
  styleUrls: ['./graph-tool-tip.component.scss'],
})
export class GraphToolTipComponent {
  @Input() @HostBinding('style.left.px') posX = 0;
  @Input() @HostBinding('style.top.px') posY = 0;
}
