<drop-down #dropdown [headerTemplate]="header" [headerClickToggleOpen]="false" [disabled]="disabled">
  <ng-container dropdown>
    <ng-container *ngIf="dropDown?.openedState">
      <li
        *ngFor="let item of filteredItems; trackBy: trackBy"
        [class.disabled]="item.title"
        (click)="item.section && selectValue(item)"
      >
        {{ item.title ? ('configurations.' + item.title + '.title' | translate) : item.translation }}
      </li>
    </ng-container>
  </ng-container>
</drop-down>

<ng-template #header let-opened>
  <labeled-input [clearButton]="true">
    <div label>{{ label }}</div>
    <input [(ngModel)]="filtertext" (ngModelChange)="filterValues()" />
  </labeled-input>
</ng-template>
