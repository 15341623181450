import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IDevice, IDeviceTOS } from 'src/app/lib/interfaces/interface';
import { DeviceService } from 'src/app/lib/services/device.service';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { ToastService } from 'src/app/lib/services/toast.service';

@Component({
  selector: 'cloud-steering-modal',
  templateUrl: './cloud-steering-modal.component.html',
  styleUrls: ['./cloud-steering-modal.component.scss']
})
export class CloudSteeringModalComponent implements OnInit {
  @Input()
  device: IDevice = null;

  @Output()
  closeCloudSteeringModal = new EventEmitter<void>();

  // TOS = Throughput Optimized Steering
  deviceTOS: IDeviceTOS[] = null;
  deviceTOSLoading = true;

  constructor(public plume: PlumeService, private deviceService: DeviceService, private toast: ToastService) {}

  ngOnInit() {
    this.deviceTOSLoading = true;
    this.deviceService
      .getTOS$(this.device.mac)
      .pipe(
        catchError(() => {
          this.deviceTOSLoading = false;
          return of([]);
        })
      )
      .subscribe((tosData: IDeviceTOS[]) => {
        this.deviceTOS = tosData;
        this.deviceTOSLoading = false;
      });
  }

  resetTos() {
    this.deviceService
      .resetTOS$(this.device.mac)
      .pipe(catchError(() => of([])))
      .subscribe((tosResponse) => {
        if (tosResponse === 'OK') {
          this.toast.success(
            'health.networkInformation.modal.resetTOSSuccess',
            'health.networkInformation.modal.resetTOS',
            {}
          );
        } else {
          this.toast.error(
            'health.networkInformation.modal.resetTOSFailure',
            'health.networkInformation.modal.resetTOS',
            {}
          );
        }
        this.closeModal();
      });
  }

  closeModal() {
    this.closeCloudSteeringModal.emit();
  }
}
