<slidetoggler [options]="options" (toggle)="setMode($event)"></slidetoggler>
<div class="events audit-log">
  <ng-container *ngIf="(auditTrails$ | async) === null">
    <div class="loading">{{ 'loadingIndicator' | translate }}</div>
  </ng-container>
  <ng-container *ngIf="auditTrails$ | async as auditTrails">
    <div class="grid">
      <div class="header date">{{ 'auditlogs.date' | translate }}</div>
      <div class="header eventName">{{ 'auditlogs.event' | translate }}</div>
      <div class="header author">{{ 'auditlogs.author' | translate }}</div>
      <div class="header more"></div>
      <ng-container class="event" *ngFor="let event of auditTrails">
        <div class="date" [class.opened]="event.showMore">{{ event.createdAt }}</div>
        <div class="eventName" [class.opened]="event.showMore" [tooltip]="event.event" [onlyOverflowText]="true">
          {{ event.event }}
        </div>
        <div class="author" [class.opened]="event.showMore" [tooltip]="event.author" [onlyOverflowText]="true">
          {{ event.author }}
        </div>
        <div class="more" [class.opened]="event.showMore">
          <button class="action small" (click)="event.showMore = !event.showMore">
            <ng-container *ngIf="!event.showMore">
              {{ 'auditlogs.more' | translate }}
            </ng-container>
            <ng-container *ngIf="event.showMore">
              {{ 'auditlogs.hide' | translate }}
            </ng-container>
          </button>
        </div>
        <ng-container *ngIf="event.showMore">
          <div class="details">
            {{ 'auditlogs.details' | translate }}:
            <ngx-json-viewer [json]="event.details"></ngx-json-viewer>
          </div>
          <div class="detailsFiller"></div>
          <div class="detailsFiller"></div>
          <div class="detailsFiller"></div>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</div>
