<div class="content" *ngIf="(state$ | async) === 'loading'">
  <div class="bubble loading">
    <icon name="icon-loading"></icon>
    <div class="message">{{ 'captiveportal.preparingPortal' | translate }}</div>
  </div>
</div>

<div class="content" *ngIf="(state$ | async) === 'error'">
  <div class="bubble error">
    <h1>{{ 'captiveportal.errorTitle' | translate }}</h1>
    <p>{{ errorMessage$ | async | translate }}</p>
    <div class="logo"></div>
    <div class="close" (click)="close()">
      <div class="icon arrow"></div>
      {{ 'captiveportal.close' | translate }}
    </div>
  </div>
</div>

<div class="content" *ngIf="(state$ | async) === 'editor'">
  <captive-menu></captive-menu>
  <captive-preview></captive-preview>
</div>
