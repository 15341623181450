import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';

@Component({
  selector: 'range-slider',
  templateUrl: './range-slider.component.html',
  styleUrls: ['./range-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RangeSliderComponent implements OnInit, OnChanges {
  @Input() min = 0;
  @Input() max = 100;
  @Input() lower = 0;
  @Input() higher = 100;
  @Input() step = 1;

  steps: number[] = [];
  moving: 'lower' | 'higher' | 'both' | null = null;

  @Output() lowerChange = new EventEmitter<number>();
  @Output() higherChange = new EventEmitter<number>();

  constructor(private elm: ElementRef<HTMLDivElement>) {}

  ngOnInit(): void {
    this.setSteps();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.min || changes.max || changes.step) {
      this.setSteps();
    }
  }

  setSteps(): void {
    this.steps = [];
    for (let i = this.min; i < this.max; i += this.step) {
      this.steps.push(i);
    }
    this.steps.push(this.max);
  }

  mouseDown(type: 'lower' | 'higher'): void {
    const posLowerX = this.elm.nativeElement.querySelector('.dotLower').getBoundingClientRect().left;
    const posHigherX = this.elm.nativeElement.querySelector('.dotHigher').getBoundingClientRect().left;

    this.moving = type;
    if (posHigherX - posLowerX < 8) {
      // dots on same place, can determine direction yet
      this.moving = 'both';
    }
  }

  @HostListener('window:mouseup')
  @HostListener('window:touchend')
  mouseUp(): void {
    this.moving = null;
  }

  @HostListener('window:touchmove', ['$event.touches[0].clientX'])
  @HostListener('window:mousemove', ['$event.x'])
  mouseMove(event: number): void {
    if (!this.moving) {
      return;
    }
    const left = event - this.elm.nativeElement.getBoundingClientRect().left;

    if (this.moving === 'both') {
      const dotWidth = this.elm.nativeElement.querySelector('.dotLower').getBoundingClientRect().width;
      const diff =
        event - this.elm.nativeElement.querySelector('.dotLower').getBoundingClientRect().left - dotWidth / 2;

      // mouse not moved enoughs to determine direction
      if (Math.abs(diff) < dotWidth) {
        return;
      }

      if (diff > 0) {
        this.moving = 'higher';
      } else {
        this.moving = 'lower';
      }
    }

    const width = this.elm.nativeElement.getBoundingClientRect().width;
    let newValue = (left / width) * (this.max - this.min) + this.min;
    newValue = Math.max(Math.min(this.max, newValue), this.min);
    if (this.moving === 'lower') {
      newValue = Math.min(this.higher, newValue);
      this.lowerChange.emit(newValue);
    } else {
      newValue = Math.max(this.lower, newValue);
      this.higherChange.emit(newValue);
    }
  }
}
