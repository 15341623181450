import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { GraphCalculationsService } from './graph-calculations/graph-calculations.service';
import { DataSetGroup, DataWithPosition, GraphDataSetComputed, IntervalToolTipData } from './graph.interface';
import * as erdm from 'element-resize-detector';

@Component({
  selector: 'flm-graph',
  templateUrl: './graph.component.html',
  styleUrls: ['./graph.component.scss'],
  providers: [GraphCalculationsService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GraphComponent implements OnInit {
  @Input() public set borders(value: { bottom?: number; left?: number; top?: number; right?: number }) {
    this.calculations.elementSize = { ...this.calculations.elementSize, ...value };
    this.calculations.elementSizeRecalculate(this.elm.nativeElement);
  }
  @Input() public set dataSet(value: DataSetGroup[]) {
    this.calculations.setDatasets(value);
  }
  @Input() contrast = false;

  @Output() hover = new EventEmitter<
    | { point: DataWithPosition | undefined; dataSet: GraphDataSetComputed }
    | { bar: IntervalToolTipData | undefined; dataSet: GraphDataSetComputed }
  >();
  erd: any = erdm({ strategy: 'scroll' });
  toolTipDefinition?: {
    posX: number;
    posY: number;
    html: SafeHtml;
  };
  toolTipBarDefinition?: {
    posX: number;
    html: SafeHtml;
    posY: number | undefined;
  };
  resizeTimeOut?: number;

  constructor(
    private elm: ElementRef<HTMLElement>,
    public calculations: GraphCalculationsService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.calculations.elementSizeRecalculate(this.elm.nativeElement);
    this.erd.listenTo(this.elm.nativeElement, () => {
      this.onResize();
    });
  }

  @HostListener('window:resize')
  onResize(): void {
    if (this.resizeTimeOut) {
      window.cancelAnimationFrame(this.resizeTimeOut);
    }
    this.resizeTimeOut = window.requestAnimationFrame(() => {
      this.calculations.elementSizeRecalculate(this.elm.nativeElement);
      this.cdr.markForCheck();
      this.resizeTimeOut = undefined;
    });
  }

  pointHover(data: { point: DataWithPosition | undefined; dataSet: GraphDataSetComputed }): void {
    this.hover.emit(data);
    if (data.point) {
      this.toolTipDefinition = {
        posX: data.point.xPos,
        posY: data.point.yPos,
        html: data.dataSet.toolTipHtml?.(data.point) ?? data.dataSet.yValText?.(data.point.yVal) ?? `${data.point.yVal}`
      };
    } else {
      this.toolTipDefinition = undefined;
    }
  }

  barHover(
    data:
      | { bar: IntervalToolTipData | undefined; dataSet: GraphDataSetComputed }
      | { bar: DataWithPosition; dataSet: GraphDataSetComputed }
  ): void {
    this.hover.emit(data);
    if (data.bar) {
      const posY =
        'activeYIntervalIndex' in data.bar
          ? data.bar.yInterval[data.bar.activeYIntervalIndex].startPos -
            (data.bar.yInterval[data.bar.activeYIntervalIndex].startPos -
              data.bar.yInterval[data.bar.activeYIntervalIndex].endPos) /
              2
          : undefined;
      this.toolTipBarDefinition = {
        posX: data.bar.xPos,
        html: data.dataSet.toolTipHtml?.(data.bar) ?? data.dataSet.yValText?.(data.bar.yVal) ?? `${data.bar.yVal}`,
        posY
      };
    } else {
      this.toolTipBarDefinition = undefined;
    }
  }
}
