<box>
  <div header>{{ 'profiler.featureEngagements.title' | translate }}</div>
  <div container>
    <div class="group">
      <div class="item" *ngIf="featureEngagements.optimization">
        <icon [path]="featureEngagements.optimization.icon" class="big"></icon>
        <div class="enabled" *ngIf="featureEngagements.optimization.enabled">
          <icon name="icon-check" class="size-0_8"></icon>
        </div>
        <div class="label">
          <div class="text">
            {{ featureEngagements.optimization.label | translate: { count: '' } }}
            <icon name="icon-info" (click)="showDetails(featureEngagements.optimization)"></icon>
          </div>
        </div>
      </div>

      <div class="item" *ngIf="featureEngagements.steering">
        <icon [path]="featureEngagements.steering.icon" class="big"></icon>
        <div class="enabled" *ngIf="featureEngagements.steering.enabled">
          <icon name="icon-check" class="size-0_8"></icon>
        </div>
        <div class="label">
          <div class="text">
            {{ featureEngagements.steering.label | translate: { count: '' } }}
            <icon name="icon-info" (click)="showDetails(featureEngagements.steering)"></icon>
          </div>
        </div>
      </div>

      <div class="item" *ngIf="featureEngagements.speedTests">
        <icon [path]="featureEngagements.speedTests.icon" class="big"></icon>
        <div class="enabled" *ngIf="featureEngagements.speedTests.enabled">
          <icon name="icon-check" class="size-0_8"></icon>
        </div>
        <div class="label">
          <div class="text">
            {{ featureEngagements.speedTests.label | translate: { count: '' } }}
            <icon name="icon-info" (click)="showDetails(featureEngagements.speedTests)"></icon>
          </div>
        </div>
      </div>

      <div class="item" *ngIf="featureEngagements.appTime">
        <icon [path]="featureEngagements.appTime.icon" class="big"></icon>
        <div class="enabled" *ngIf="featureEngagements.appTime.enabled">
          <icon name="icon-check" class="size-0_8"></icon>
        </div>
        <div class="label">
          <div class="text">
            {{ featureEngagements.appTime.label | translate: { count: '' } }}
            <icon name="icon-info" (click)="showDetails(featureEngagements.appTime)"></icon>
          </div>
        </div>
      </div>

      <div class="item" *ngIf="featureEngagements.senseMotion">
        <icon [path]="featureEngagements.senseMotion.icon" class="big"></icon>
        <div class="enabled" *ngIf="(capabilities$ | async)?.wifiMotion?.capable && featureEngagements.senseMotion.enabled">
          <icon name="icon-check" class="size-0_8"></icon>
        </div>
        <div class="label">
          <div class="text">
            {{ featureEngagements.senseMotion.label | translate: { count: '' } }}
            <icon name="icon-info" (click)="showDetails(featureEngagements.senseMotion)"></icon>
          </div>
        </div>
      </div>

      <div class="item" *ngIf="featureEngagements.deviceFreeze">
        <icon [path]="featureEngagements.deviceFreeze.icon" class="big"></icon>
        <div class="enabled" *ngIf="featureEngagements.deviceFreeze.enabled">
          <icon name="icon-check" class="size-0_8"></icon>
        </div>
        <div class="label">
          <div class="text">
            {{ featureEngagements.deviceFreeze.label | translate: { count: '' } }}
            <icon name="icon-info" (click)="showDetails(featureEngagements.deviceFreeze)"></icon>
          </div>
        </div>
      </div>

      <div class="item" *ngIf="featureEngagements.peopleProfiles">
        <icon [path]="featureEngagements.peopleProfiles.icon" class="big"></icon>
        <div class="enabled" *ngIf="featureEngagements.peopleProfiles.enabled">
          <icon name="icon-check" class="size-0_8"></icon>
        </div>
        <div class="label">
          <div class="text">
            {{ featureEngagements.peopleProfiles.label | translate: { count: '' } }}
            <icon name="icon-info" (click)="showDetails(featureEngagements.peopleProfiles)"></icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</box>
