<div class="timeline">
  <div *ngFor="let timeline of timeline">
    <div class="connection {{ timeline.connection.status }}">
      <div class="line {{ timeline.connection.line }}"></div>
      <span>{{ timeline.connection.strength | translate }}</span>
      <span class="alarm" *ngIf="timeline.connection.alarm">
        {{ timeline.connection.alarm | translate }}
      </span>
    </div>
    <div class="node" *ngIf="timeline.node">
      <icon name="icon-pod" class="size-1_2"></icon>
      <span>{{ timeline.node.name }}</span>
    </div>
  </div>
</div>
