import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { selectBaseUrl } from 'src/app/store/customer/customer.selectors';
import { IReboot } from '../interfaces/interface';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class TimelinesService {
  constructor(private store: Store, private api: ApiService) {}

  reboots$(startAt: string, limit: number, endAt?: string): Observable<IReboot[]> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) =>
        this.api.get(
          `${baseUrl}/reboots?startAt=${startAt}&limit=${limit}&order=desc` + (endAt ? `&endAt=${endAt}` : ''),
          'reports'
        )
      )
    );
  }
}
