<div class="container-fluid">
  <div class="row">
    <div class="col-xs-12">
      <collapsiblebox mode="fill" [show]="active" [collapsible]="true" toggleSide="left" (toggle)="toggleExpand()">
        <div header>
          <div class="group">
            <div class="title">{{ 'timelines.reboot.title' | translate }}</div>
            <icon name="icon-info" tooltip="{{ 'timelines.reboot.tooltip' | translate }}" [maxWidth]="300"></icon>
          </div>

          <ng-container *ngrxLet="dataDefinition$ as dataDefinition">
            <div
              class="grow"
              data-test="headerInfoErrorTexts"
              *ngIf="active && (dataDefinition?.loadMoreVisible || dayView || dataDefinition?.tooManyReboots)"
            >
              <div class="tag skip-toggle" (click)="changeView(null)" *ngIf="dayView">
                <icon name="icon-close" class="size-0_5"></icon>
                {{ dayView.text }}
              </div>
              <div
                class="tag skip-toggle"
                [class.disabled]="dataDefinition?.loading"
                (click)="loadMore(dataDefinition?.events)"
                *ngIf="dataDefinition?.loadMoreVisible && active && !dayView && !dataDefinition?.tooManyReboots"
              >
                <icon name="icon-spinner" class="spinner loading" *ngIf="dataDefinition?.loading"></icon>
                <div class="content">{{ 'timelines.reboot.loadMore' | translate }}</div>
              </div>
              <div *ngIf="!dayView && dataDefinition?.tooManyReboots" class="errorText">
                {{ 'timelines.reboot.tooManyReboots' | translate }}
              </div>
            </div>
          </ng-container>

          <div class="legend" *ngIf="active">
            <div class="item" *ngFor="let item of legend">
              <svg viewBox="0 0 4 4">
                <svg:circle [ngClass]="item.color" cx="50%" cy="50%" r="1"></svg:circle>
              </svg>
              <div class="label">{{ item.text | translate }}</div>
            </div>
          </div>
        </div>

        <div container *ngrxLet="dataDefinition$ as dataDefinition">
          <timelineschart
            *ngIf="active"
            [events]="dataDefinition?.events"
            [dayView]="dayView"
            [eventsMenu]="true"
            [overlayMessage]="dataDefinition?.unknownError ? 'timelines.reboot.unknownError' : ''"
            (view)="changeView($event)"
            (event)="selectEvent($event)"
          ></timelineschart>
        </div>
      </collapsiblebox>

      <div class="row" *ngIf="active && eventDetails">
        <div class="col-xs-12">
          <div class="bubble">
            <div class="title">
              {{ 'timelines.reboot.eventTitle' | translate }}
            </div>
            <div class="details">
              <div class="table">
                <div class="status">
                  <div class="column">
                    <div class="row">
                      <div class="key">{{ 'timelines.reboot.nodeid' | translate }}</div>
                      <div class="value">{{ eventDetails.target }}</div>
                    </div>
                    <div class="row" data-test="detailsName" *ngIf="eventDetails.event?.name">
                      <div class="key">{{ 'timelines.reboot.name' | translate }}</div>
                      <div class="value">{{ eventDetails.event.name }}</div>
                    </div>
                    <div class="row">
                      <div class="key">{{ 'timelines.reboot.time' | translate }}</div>
                      <div class="value">{{ eventDetails.created | date: 'LLL' }}</div>
                    </div>
                    <div class="row">
                      <div class="key">{{ 'timelines.reboot.firmwareversion' | translate }}</div>
                      <div class="value">{{ eventDetails.firmwareversion | translate }}</div>
                    </div>
                  </div>
                  <div class="column">
                    <div class="row">
                      <div class="key">{{ 'timelines.reboot.type' | translate }}</div>
                      <div class="value">{{ eventDetails.status | translate }}</div>
                    </div>
                    <div class="row">
                      <div class="key">{{ 'timelines.reboot.reason' | translate }}</div>
                      <div class="value">{{ eventDetails.reason | translate }}</div>
                    </div>
                    <div class="row" *ngIf="eventDetails.processName">
                      <div class="key">{{ 'timelines.reboot.processName' | translate }}</div>
                      <div class="value">{{ eventDetails.processName | translate }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
