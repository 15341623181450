import { Component } from '@angular/core';
import { map } from 'rxjs/operators';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { SystemService } from 'src/app/lib/services/system.service';

@Component({
  selector: 'cloudstatus',
  templateUrl: './cloudstatus.component.html',
  styleUrls: ['./cloudstatus.component.scss']
})
export class CloudstatusComponent {
  statusPage$ = this.systemService.status$().pipe(map((res) => this.systemService.translateStatusPageResponse(res)));

  constructor(public plume: PlumeService, private mixpanel: MixpanelService, private systemService: SystemService) {}

  gotoStatuspage(): void {
    const statusPageURL = this.plume.getStatusPageURL(this.plume.getEnv().id);
    this.mixpanel.storeEvent('STATUSPAGE_OPEN');
    window.open(statusPageURL, '_blank');
  }
}
