import { createAction, props } from '@ngrx/store';
import { IIspSpeedTestConfiguration } from 'src/app/lib/interfaces/interface';

export const speedTestConfigure = createAction(
  '[Speed test] configure',
  props<{
    configuration: {
      enable: boolean;
      enableAllNodes: boolean;
    };
  }>()
);

export const speedTestConfigureFinished = createAction(
  '[Speed test] configure finished',
  props<{ value: IIspSpeedTestConfiguration }>()
);

export const speedTestConfigureError = createAction('[Speed test] configure error', props<{ error: any }>());
