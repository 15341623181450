import { Component } from '@angular/core';
import { GraphCalculationsService } from '../graph-calculations/graph-calculations.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[flm-graph-axis-y]',
  templateUrl: './graph-axis-y.component.html',
  styleUrls: ['./graph-axis-y.component.scss'],
})
export class GraphAxisYComponent {
  constructor(public calculations: GraphCalculationsService) {}
}
