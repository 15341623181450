<modal class="editFocus" mode="compact" (overlayAction)="closeModal()">
  <ng-container title>{{ 'devices.device.' + focusSchedule.mode + 'Title' | translate }}</ng-container>
  <ng-container content>
    <div class="wrapper">
      <div class="name">
        {{ 'devices.device.name' | translate }}: <input [formControl]="focusSchedule.name" type="text" />
      </div>
      <div class="schedules" *ngFor="let schedule of focusSchedule.schedules; let i = index">
        <div class="time">
          <div>
            {{ 'devices.device.from' | translate }}:
            <input [formControl]="schedule.startTime" type="text" placeholder="08:00" />
          </div>
          <div>
            {{ 'devices.device.to' | translate }}:
            <input [formControl]="schedule.endTime" type="text" placeholder="18:00" />
          </div>
          <icon name="icon-info" tooltip="{{ 'devices.device.timeFormatInfo' | translate }}"></icon>
        </div>
        <div class="days-title">{{ 'devices.device.selectDays' | translate }}:</div>
        <div class="days">
          <div
            class="day"
            [class.selected]="schedule.selectedDays.indexOf(day) >= 0"
            (click)="handleDayClick(day, i)"
            *ngFor="let day of [1, 2, 3, 4, 5, 6, 7]"
          >
            {{ processDay(day) }}
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container controls>
    <button class="tertiary light" (click)="closeModal()">
      {{ 'health.networkInformation.modal.close' | translate }}
    </button>

    <button
      class="super-primary"
      (click)="addFocusSchedule()"
      *ngIf="focusSchedule.mode === 'add'"
      [disabled]="focusRequestPending"
    >
      {{ 'health.networkInformation.modal.add' | translate }}
    </button>
    <button
      class="super-primary"
      (click)="updateFocusSchedule()"
      *ngIf="focusSchedule.mode === 'update'"
      [disabled]="focusRequestPending"
    >
      {{ 'health.networkInformation.modal.update' | translate }}
    </button>
  </ng-container>
</modal>
