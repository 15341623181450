import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ILocationAuthorizations } from 'src/app/lib/interfaces/interface';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { ToastService } from 'src/app/lib/services/toast.service';
import { nodesAuthorizationSet, podsAuthorizationSet } from 'src/app/store/customer-info/customer-info.actions';
import { selectPipeLocationAuthorizationsOnChangeOptimistic } from 'src/app/store/customer/customer.selectors';

@Component({
  selector: 'locationauthorization',
  templateUrl: './locationauthorization.component.html',
  styleUrls: ['./locationauthorization.component.scss']
})
export class LocationAuthorizationComponent {
  edit$ = new BehaviorSubject(false);
  editAll$ = new BehaviorSubject(false);
  noPodsSet$ = combineLatest([
    this.store.pipe(selectPipeLocationAuthorizationsOnChangeOptimistic),
    this.edit$,
    this.editAll$
  ]).pipe(
    tap(([authorizations, edit, editAll]) => {
      this.populate(authorizations, edit, editAll);
    }),
    map(([authorizations]) => !authorizations?.numNodesAuthorized?.length)
  );

  allPods = new FormControl(0);
  pods = [
    { model: 'Plume Pod v1.0', control: new FormControl(0) },
    { model: 'PP203X', control: new FormControl(0) },
    { model: 'PP213X', control: new FormControl(0) },
    { model: 'PP603X', control: new FormControl('') }
  ];

  constructor(public plume: PlumeService, private toast: ToastService, private store: Store) {}

  populate(authorizations: ILocationAuthorizations, edit: boolean, editAll: boolean): void {
    if (authorizations) {
      if (!editAll) {
        this.allPods.setValue(authorizations.numPodsAuthorized);
      }

      if (!edit) {
        this.pods.forEach((pod) => {
          const node = authorizations.numNodesAuthorized.find((node) => pod.model === node.model);

          if (pod.model === 'PP603X') {
            pod.control.setValue(node ? node.count : '');
          } else {
            pod.control.setValue(node ? node.count : 0);
          }
        });
      }
    } else {
      this.allPods.setValue(32);
    }
  }

  confirmEdit(): void {
    this.setNodeAuthorizations();
  }

  startEdit(): void {
    this.edit$.next(true);
  }

  cancelEdit(): void {
    this.edit$.next(false);
  }

  setNodeAuthorizations(): void {
    try {
      let sum = 0;
      const pods = this.pods
        .map((pod) => {
          if (pod.control.value?.toString().length) {
            const count = parseInt(pod.control.value, 10);
            if (isNaN(count)) {
              throw new Error('NUMBERS_ONLY');
            }
            sum += count;
            return { model: pod.model, count };
          } else {
            return undefined;
          }
        })
        .filter((pod) => pod !== undefined);

      if (sum < 0 || sum > 32) {
        throw new Error('NOT_WITHIN_RANGE');
      }

      this.store.dispatch(nodesAuthorizationSet({ nodes: pods }));
      this.cancelEdit();
    } catch (e) {
      if (e.message === 'NUMBERS_ONLY') {
        this.toast.warning('locationAuthorization.numbersMsg', 'locationAuthorization.numbersTitle');
      }

      if (e.message === 'NOT_WITHIN_RANGE') {
        this.toast.warning('locationAuthorization.rangeMsg', 'locationAuthorization.rangeTitle');
      }
    }
  }

  confirmEditAll(): void {
    this.setAuthorizations();
  }

  startEditAll(): void {
    this.editAll$.next(true);
  }

  cancelEditAll(): void {
    this.editAll$.next(false);
  }

  setAuthorizations(): void {
    try {
      const pods = parseInt(this.allPods.value, 10);
      if (pods === null) {
        throw new Error('NUMBERS_ONLY');
      }
      if (pods < 0 || pods > 32) {
        throw new Error('NOT_WITHIN_RANGE');
      }
      this.store.dispatch(podsAuthorizationSet({ count: pods }));
      this.cancelEditAll();
    } catch (e) {
      if (e.message === 'NUMBERS_ONLY') {
        this.toast.warning('locationAuthorization.numbersMsg', 'locationAuthorization.numbersTitle');
      }

      if (e.message === 'NOT_WITHIN_RANGE') {
        this.toast.warning('locationAuthorization.rangeMsg', 'locationAuthorization.rangeTitle');
      }
    }
  }
}
