<div class="row" id="conf-privacyandsecurity" *ngrxLet="dnsCategoryBlockingCapable$ as dnsCategoryBlockingCapable">
  <div class="col-xs-12">
    <collapsiblebox [show]="expand" [collapsible]="true" (toggle)="toggleExpand()">
      <div header>{{ 'configurations.privacyandsecurity.title' | translate }}</div>
      <div container *ngIf="expand">
        <div class="columns">
          <div class="column">
            <div class="row">
              <div class="key">{{ 'configurations.privacyandsecurity.appTime' | translate }}</div>
              <slider-toggle-mobile-aware
                [options]="appTimeItems$ | async"
                [editable]="plume.isSomeAdminRole()"
                (toggle)="updateAppTime($event)"
              ></slider-toggle-mobile-aware>
              <div *ngIf="(appTime$ | async)?.appliesToAllDevices">
                {{ 'configurations.privacyandsecurity.appliesToAllDevices' | translate }}
              </div>
              <div *ngIf="!(appTime$ | async)?.appliesToAllDevices">
                {{ 'configurations.privacyandsecurity.appliesToSomeDevices' | translate }}
              </div>
            </div>
            <div class="row">
              <div class="key">{{ 'configurations.privacyandsecurity.privateMode' | translate }}</div>
              <div class="toggler" (click)="updatePrivateMode()">
                <div class="checkbox">
                  <label
                    for="toggleChannel"
                    class="toggle"
                    [class.checked]="privateMode"
                    [class.disabled]="!plume.isSomeAdminRole()"
                  ></label>
                </div>
              </div>
              <div *ngIf="privateModeUpdatedDate">
                {{ 'configurations.privacyandsecurity.updatedAt' | translate }} {{ privateModeUpdatedDate }}
              </div>
            </div>
            <div class="row" *ngIf="someSecurityCapable$ | async">
              <div class="key">{{ 'configurations.privacyandsecurity.ipFlowLogging' | translate }}</div>
              <div class="toggler" (click)="updateIpFlowLogging()">
                <div class="checkbox">
                  <label
                    for="toggleChannel"
                    class="toggle"
                    [class.checked]="ipFlowLogging"
                    [class.disabled]="!plume.isSomeAdminRole()"
                  ></label>
                </div>
              </div>
              <div *ngIf="ipFlowLogging && ipFlowLoggingExpiry">
                {{ 'configurations.privacyandsecurity.expiresAt' | translate }} {{ ipFlowLoggingExpiry }}
              </div>
            </div>
            <ng-container *ngIf="dnsCategoryBlockingCapable">
              <div class="row">
                <div class="key">{{ 'configurations.privacyandsecurity.iotDeviceIpFlowstats' | translate }}</div>
                <slider-toggle-mobile-aware
                  [options]="ipIotDeviceFlowItems$ | async"
                  [editable]="plume.isStrictAdminRole() || (plume.cloudVersionAbove1_90() && plume.isSomeAdminRole())"
                  (toggle)="action('ipIotDeviceFlowStats', $event)"
                ></slider-toggle-mobile-aware>
              </div>
              <div class="row">
                <div class="key">{{ 'configurations.privacyandsecurity.screenDeviceIpFlowStats' | translate }}</div>
                <slider-toggle-mobile-aware
                  [options]="ipScreenDeviceFlowItems$ | async"
                  [editable]="plume.isStrictAdminRole() || (plume.cloudVersionAbove1_90() && plume.isSomeAdminRole())"
                  (toggle)="action('ipScreenDeviceFlowStats', $event)"
                ></slider-toggle-mobile-aware>
              </div>
              <div class="row">
                <div class="key">{{ 'configurations.privacyandsecurity.iotDeviceLanFlowstats' | translate }}</div>
                <slider-toggle-mobile-aware
                  [options]="lanIotDeviceFlowItems$ | async"
                  [editable]="plume.isStrictAdminRole() || (plume.cloudVersionAbove1_90() && plume.isSomeAdminRole())"
                  (toggle)="action('lanIotDeviceFlowStats', $event)"
                ></slider-toggle-mobile-aware>
              </div>
              <div class="row">
                <div class="key">{{ 'configurations.privacyandsecurity.interfaceStats' | translate }}</div>
                <slider-toggle-mobile-aware
                  [options]="interfaceStatsItems$ | async"
                  [editable]="plume.isStrictAdminRole() || (plume.cloudVersionAbove1_90() && plume.isSomeAdminRole())"
                  (toggle)="action('interfaceStats', $event)"
                ></slider-toggle-mobile-aware>
              </div>
              <div class="row">
                <div class="key">{{ 'configurations.privacyandsecurity.securityContentProvider' | translate }}</div>
                <slider-toggle-mobile-aware
                  [options]="preferredIntelligenceItems$ | async"
                  [editable]="plume.isSomeAdminRole()"
                  (toggle)="action('preferredIntelligence', $event)"
                ></slider-toggle-mobile-aware>
              </div>
              <div class="row">
                <div class="key">{{ 'configurations.privacyandsecurity.ipThreatIntelligence' | translate }}</div>
                <slider-toggle-mobile-aware
                  [options]="ipThreatIntelligenceItems$ | async"
                  [editable]="plume.isSomeAdminRole()"
                  (toggle)="action('ipThreatIntelligence', $event)"
                ></slider-toggle-mobile-aware>
              </div>
              <div class="row">
                <div class="key">{{ 'configurations.privacyandsecurity.webclassifierHealthStats' | translate }}</div>
                <slider-toggle-mobile-aware
                  [options]="wcHealthTelemetryItems$ | async"
                  [editable]="plume.isSomeAdminRole()"
                  (toggle)="action('wcHealthTelemetry', $event)"
                ></slider-toggle-mobile-aware>
              </div>
              <div class="row">
                <div class="key">{{ 'configurations.privacyandsecurity.webclassifierHeroStats' | translate }}</div>
                <slider-toggle-mobile-aware
                  [options]="wcHeroTelemetryItems$ | async"
                  [editable]="plume.isSomeAdminRole()"
                  (toggle)="action('wcHeroTelemetry', $event)"
                ></slider-toggle-mobile-aware>
              </div>
              <div class="row">
                <div class="key">{{ 'configurations.privacyandsecurity.ipThreatProtection' | translate }}</div>
                <slider-toggle-mobile-aware
                  [options]="ipThreatProtectItems$ | async"
                  [editable]="plume.isSomeAdminRole()"
                  (toggle)="action('ipThreatProtect', $event)"
                ></slider-toggle-mobile-aware>
              </div>
              <div class="row">
                <div class="key">{{ 'configurations.privacyandsecurity.dpiContentFiltering' | translate }}</div>
                <slider-toggle-mobile-aware
                  [options]="dpiContentFilteringItems$ | async"
                  [editable]="plume.isSomeAdminRole()"
                  (toggle)="action('dpiContentFiltering', $event)"
                ></slider-toggle-mobile-aware>
              </div>
              <div class="row">
                <div class="key">{{ 'configurations.privacyandsecurity.inlineDpi' | translate }}</div>
                <slider-toggle-mobile-aware
                  [options]="inlineDpiItems$ | async"
                  [editable]="plume.isSomeAdminRole()"
                  (toggle)="action('inlineDpi', $event)"
                ></slider-toggle-mobile-aware>
              </div>
              <div class="row">
                <div class="key">{{ 'configurations.privacyandsecurity.dosProtection' | translate }}</div>
                <slider-toggle-mobile-aware
                  [options]="dosProtectionItems$ | async"
                  [editable]="plume.isSomeAdminRole()"
                  (toggle)="action('dosProtection', $event)"
                ></slider-toggle-mobile-aware>
              </div>
              <div class="row">
                <div class="key">{{ 'configurations.privacyandsecurity.dosProtectionAction' | translate }}</div>
                <slider-toggle-mobile-aware
                  [options]="dosProtectionActionItems$ | async"
                  [editable]="plume.isSomeAdminRole()"
                  (toggle)="action('dosProtectionAction', $event)"
                ></slider-toggle-mobile-aware>
              </div>
              <div class="row">
                <div class="key">{{ 'configurations.privacyandsecurity.sniffingDns' | translate }}</div>
                <slider-toggle-mobile-aware
                  [options]="sniffingDnsItems"
                  [editable]="plume.isSomeAdminRole()"
                  (toggle)="action('sniffingDns', $event)"
                ></slider-toggle-mobile-aware>
              </div>
              <div class="row">
                <div class="key">{{ 'configurations.privacyandsecurity.sniffingHttp' | translate }}</div>
                <slider-toggle-mobile-aware
                  [options]="sniffingHttpItems"
                  [editable]="plume.isSomeAdminRole()"
                  (toggle)="action('sniffingHttp', $event)"
                ></slider-toggle-mobile-aware>
              </div>
              <div class="row">
                <div class="key">{{ 'configurations.privacyandsecurity.sniffingUpnp' | translate }}</div>
                <slider-toggle-mobile-aware
                  [options]="sniffingUpnpItems"
                  [editable]="plume.isSomeAdminRole()"
                  (toggle)="action('sniffingUpnp', $event)"
                ></slider-toggle-mobile-aware>
              </div>
              <div class="row">
                <div class="key">{{ 'configurations.privacyandsecurity.sniffingMdns' | translate }}</div>
                <slider-toggle-mobile-aware
                  [options]="sniffingMdnsItems"
                  [editable]="plume.isSomeAdminRole()"
                  (toggle)="action('sniffingMdns', $event)"
                ></slider-toggle-mobile-aware>
              </div>
              <div class="row" *ngIf="sipAlgCapable$ | async">
                <div class="key">{{ 'configurations.privacyandsecurity.sipAlg' | translate }}</div>
                <slider-toggle-mobile-aware
                  [options]="sipALgItems$ | async"
                  [editable]="plume.isSomeAdminRole() && (locationConfigLoading$ | async) === false"
                  (toggle)="setSipAlgMode($event)"
                ></slider-toggle-mobile-aware>
              </div>
            </ng-container>
            <div class="row" *ngIf="remoteConnection">
              <div class="key">{{ 'configurations.privacyandsecurity.unauthorizedInboundConnection' | translate }}</div>
              <slider-toggle-mobile-aware
                [options]="unauthorizedInboundConnectionItems"
                [editable]="plume.isSomeAdminRole()"
                (toggle)="action('unauthorizedInboundConnection', $event)"
              ></slider-toggle-mobile-aware>
            </div>
          </div>
        </div>
      </div>
    </collapsiblebox>
  </div>
</div>
