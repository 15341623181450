import { Component, OnInit } from '@angular/core';
import { PlumeService } from 'src/app/lib/services/plume.service';

@Component({
  templateUrl: './netops.component.html',
  styleUrls: ['./netops.component.scss']
})
export class NetopsComponent implements OnInit {
  constructor(public plume: PlumeService) {}

  ngOnInit(): void {}
}
