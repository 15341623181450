import { Component, OnChanges, Input } from '@angular/core';
import { NeighborsService } from '../../lib/services/neighbors.service';
import * as moment from 'moment';

@Component({
  selector: 'neighborsreport',
  templateUrl: './neighborsreport.component.html',
  styleUrls: ['./neighborsreport.component.scss']
})
export class NeighborsreportComponent implements OnChanges {
  showNeighborsReport: boolean = false;
  loadingNeighbors: boolean = false;
  neighborsReport: any[] = [];
  timestamp: string = '';
  hasTimestamp: boolean = false;

  @Input()
  triggerNeighborsReports: boolean = false;

  constructor(private neighbors: NeighborsService) {}

  ngOnChanges(changes: any): void {
    if (changes.triggerNeighborsReports && !changes.triggerNeighborsReports.firstChange) {
      this.getNeighborsReports();
    }
  }

  getNeighborsReports(): void {
    this.showNeighborsReport = true;
    this.loadingNeighbors = true;

    this.neighbors.getNeighbors((response: any) => {
      this.loadingNeighbors = false;
      this.neighborsReport = response.networks;
      if (response.timestamp) {
        this.timestamp = moment(response.timestamp).format('L LT');
        this.hasTimestamp = true;
      }
    });
  }
}
