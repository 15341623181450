import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { PlumeService } from './plume.service';
import { LoggingService } from './logging.service';
import { Subject } from 'rxjs';
import { NodeService } from './nodes.service';

export class HealthMessage {
  constructor(public message: any, public time: number) {}
}

@Injectable()
export class MessageSchedulerService {
  nodes: any[];
  useMock: boolean = true;
  reqTable: any[] = [];
  maxTableLength: number = 10;

  pingTime: number = 1000;
  pingInterval: any;

  message: Subject<any> = new Subject<any>();

  constructor(
    private api: ApiService,
    private nodeService: NodeService,
    private plume: PlumeService,
    private logging: LoggingService
  ) {
    this.logging.debug('<MessageSchedulerService> mock=', this.useMock);
    this.getNodes();
  }

  setMessage(value: any): void {
    this.message.next(value);
  }

  setRequest(reqType: string, scope: string, repeat: boolean, interval: number): void {
    const newMessage: any = {
      reqType,
      scope,
      repeat,
      interval,
      data: {},
      reqTime: 0
    };

    this.reqTable.push(newMessage);
    if (this.reqTable.length > this.maxTableLength) {
      this.logging.error('Request overflow ', this.reqTable.length, ' of ', this.maxTableLength);
    }

    this.procreqTable();
  }

  clearRequest(reqType: string, scope: string): boolean {
    for (let i = 0; i < this.reqTable.length; i++) {
      const req: any = this.reqTable[i];
      if (req.reqType === reqType && req.scope === scope) {
        this.reqTable.splice(i, 1);
        this.logging.log('clearRequest : this.reqTable: ', this.reqTable);
        return true;
      }
    }
    this.logging.log('clearRequest : NOT FOUND: ', this.reqTable);
    return false;
  }

  clearById(id: number): void {
    this.reqTable.splice(id, 1);
  }

  makeReq(req: any): void {
    switch (req.reqType) {
      case 'BBE': {
        this.getBbe(req);
        break;
      }
      case 'internetConnection': {
        this.getInternetConnection(req);
        break;
      }
      case 'PING': {
        this.logging.debug('<ping>');
        break;
      }
      case 'TEST': {
        this.logging.debug('<makeRequest> Test', req);
        break;
      }
      default: {
        this.logging.warn('makeReq ', req.reqType, ' not handled', req);
      }
    }
  }

  anyRepeat(): boolean {
    let anyRepeat = false;
    for (const req of this.reqTable) {
      if (req.repeat === true) {
        anyRepeat = true;
      }
    }
    return anyRepeat;
  }

  procreqTable(): void {
    const now: number = Date.now();
    for (let i = 0; i < this.reqTable.length; i++) {
      if (this.reqTable[i].reqTime === 0 || now > this.reqTable[i].reqTime + this.reqTable[i].interval) {
        this.makeReq(this.reqTable[i]);

        if (this.reqTable[i].repeat === true) {
          this.reqTable[i].reqTime = now;
        } else {
          this.clearById(i);
        }
      }
    }
  }

  broadcastMessage(reqObj: any): void {
    const now: number = Date.now();
    const req = new HealthMessage(reqObj, now);
    this.setMessage(req);
  }

  start(): void {
    this.logging.debug('<health.service> START');
    this.startPing();
    this.reqTable = [];
  }

  startPing(): void {
    if (!this.pingInterval) {
      this.pingInterval = setInterval(() => {
        this.procreqTable();
      }, this.pingTime);
    }
  }

  stop(): void {
    this.logging.debug('<health.service> STOP');
    this.stopPing();
    this.pingInterval = null;
    this.reqTable = [];
  }

  stopPing(): void {
    if (this.pingInterval) {
      clearInterval(this.pingInterval);
    }
  }

  getNodes(): void {
    if (this.useMock) {
      this.api.raw('get', '/assets/mocks/nodes.mock.json').subscribe((response: any) => {
        this.nodes = response.nodes;
        this.logging.debug('<health.getNodes> mock=', this.useMock, '     nodes=', this.nodes);
      });
    } else {
      this.nodeService.getAll$().subscribe((response: any) => {
        this.nodes = response.nodes;
        this.logging.debug('<health.getNodes> mock=', this.useMock, '     nodes=', this.nodes);
      });
    }
  }

  getInternetConnection(req: any): void {
    if (this.useMock) {
      this.api.raw('get', '/assets/mocks/internetConnection.mock.json').subscribe((response: any) => {
        req.data = response;
        this.broadcastMessage(req);
      });
    } else {
      this.api
        .get('/Customers/' + this.plume.customerid + '/locations/' + this.plume.locationid)
        .subscribe((response: any) => {
          req.data = response;
          this.broadcastMessage(req);
        });
    }
  }

  getBbe(req: any): void {
    if (this.useMock) {
      this.api.raw('get', '/assets/mocks/bbe.mock.json').subscribe((response: any) => {
        req.data = response;
        this.broadcastMessage(req);
      });
    } else {
      this.api
        .get(
          '/Customers/' + this.plume.customerid + '/locations/' + this.plume.locationid + '/broadbandEfficiencyAlert',
          'reports'
        )
        .subscribe((response: any) => {
          req.data = response;
          this.broadcastMessage(req);
        });
    }
  }
}
