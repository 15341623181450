import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { GeneralHelper } from 'src/app/lib/helpers/general.helper';
import { ISecondaryNetworks } from 'src/app/lib/interfaces/interface';
import { ApiService } from 'src/app/lib/services/api.service';
import { CustomerService } from 'src/app/lib/services/customer.service';
import { ModelRefService } from 'src/app/lib/services/modelref.service';
import { NodeService } from 'src/app/lib/services/nodes.service';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { SecondaryNetworksService } from 'src/app/lib/services/secondary-networks.service';
import { selectCustomer, selectWPA3Recommendation } from 'src/app/store/customer/customer.selectors';

@UntilDestroy()
@Component({
  selector: 'networkstatus',
  templateUrl: './networkstatus.component.html',
  styleUrls: ['./networkstatus.component.scss']
})
export class NetworkstatusComponent implements OnInit, OnDestroy {
  permissionsSubscription: any;
  networkSubscription: any;
  devicesOnline: any = [];
  devicesOffline: any = [];
  gateway: any = {};
  extenderModels: any = {};
  customer$ = this.store.select(selectCustomer);
  location: any = {};
  permissions: any;
  showNetworksettings: boolean = false;
  plumePods: boolean = true;
  flexSupportUser: boolean = false;
  mode: string = '';
  helper = new GeneralHelper();
  showMobileSignInHistory = false;
  WPA3Recommendation$ = this.store.select(selectWPA3Recommendation);
  network: {
    ssid: string | null;
    wpa2ssid: string | null;
    wpa2enabled: boolean;
    wpa3ssid: string | null;
    wpa3enabled: boolean;
  } = {
    ssid: null,
    wpa2ssid: null,
    wpa2enabled: false,
    wpa3ssid: null,
    wpa3enabled: false
  };
  secondaryNetworksData: ISecondaryNetworks;

  constructor(
    public plume: PlumeService,
    private api: ApiService,
    private modelRef: ModelRefService,
    private nodeService: NodeService,
    private store: Store,
    private customerService: CustomerService,
    private secondaryNetworks: SecondaryNetworksService
  ) {}

  ngOnInit(): void {
    this.init();

    this.permissionsSubscription = this.plume.permissions.subscribe((data: any) => {
      this.permissions = data;
    });

    this.secondaryNetworks
      .getSecondaryNetworks$()
      .pipe(untilDestroyed(this))
      .subscribe((response: ISecondaryNetworks) => {
        this.secondaryNetworksData = response;
      });

    this.customerService
      .getSSIDs$()
      .pipe(untilDestroyed(this))
      .subscribe((network) => {
        this.network = network;
      });
  }

  init(): void {
    this.nodeService.getAll$().subscribe((response: any) => {
      this.extenderModels = {};
      this.gateway = {};
      this.plumePods = true;

      if (response.nodes.length) {
        response.nodes.forEach((node: any) => {
          const modelName = this.modelRef.get(node.model).name;

          if (
            (this.modelRef.getGateways().indexOf(node.model) > -1 && node.connectionState === 'connected') ||
            this.helper.isGateway(node.id, response.nodes)
          ) {
            this.gateway[modelName] = this.gateway[modelName] ? this.gateway[modelName] + 1 : 1;

            if (this.plumePods && node.model.indexOf('PP') !== 0) {
              this.plumePods = false;
            }
          } else {
            if (this.extenderModels[modelName]) {
              this.extenderModels[modelName] = this.extenderModels[modelName] + 1;
            } else {
              this.extenderModels[modelName] = 1;
            }
          }
        });
      }

      if (this.plumePods && !Object.keys(this.gateway).length) {
        this.plumePods = false;
      }
    });

    this.api
      .get('/Customers/' + this.plume.customerid + '/locations/' + this.plume.locationid + '/devices')
      .subscribe((response: any) => {
        this.devicesOnline = [];
        this.devicesOffline = [];

        if (response.devices.length) {
          response.devices.forEach((device: any) => {
            if (device.connectionState === 'connected') {
              this.devicesOnline.push(device);
            } else {
              this.devicesOffline.push(device);
            }
          });
        }
      });

    this.api
      .get('/Customers/' + this.plume.customerid + '/locations/' + this.plume.locationid)
      .subscribe((location: any) => {
        this.location = location;
        this.flexSupportUser = this.plume.isFlexRole();
      });
  }

  openNetworkSettings(mode: string = null): void {
    this.mode = mode;
    this.showNetworksettings = true;
  }

  closeNetworkSettings(): void {
    this.showNetworksettings = false;
    this.secondaryNetworks
      .getSecondaryNetworks$()
      .pipe(untilDestroyed(this))
      .subscribe((response: ISecondaryNetworks) => {
        this.secondaryNetworksData = response;
      });
  }

  getDate(property: string): string {
    if (property) {
      return moment.utc(property).local().format('L LT').replace(' PM', '&nbsp;PM').replace(' AM', '&nbsp;AM');
    } else {
      return 'health.networkInformation.signInUnavailable';
    }
  }

  ngOnDestroy(): void {
    if (this.permissionsSubscription) {
      this.permissionsSubscription.unsubscribe();
    }
  }
}
