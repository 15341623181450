import { createAction, props } from '@ngrx/store';
import {
  IAppTime,
  ILocationBackhaul,
  ILocationDppConfiguration,
  ILocationSecurityConfiguration
} from 'src/app/lib/interfaces/interface';

export const utilitiesInit = createAction('[Config Utilities] Init');

export const steeringBandSteeringSet = createAction(
  '[Configuration Steering] band steering set',
  props<{ value: 'auto' | 'enable' | 'disable' }>()
);

export const profileSet = createAction('[Onboarding] profile set', props<{ profile: 'auto' | 'smallbusiness' }>());
export const profileSetFinished = createAction(
  '[Configuration Onboarding] profile set finished',
  props<{ profile: 'auto' | 'smallbusiness' }>()
);
export const profileSetError = createAction('[Onboarding] profile set error', props<{ error: any }>());

export const dppSet = createAction('[Onboarding] dpp set', props<{ dpp: 'auto' | 'enable' | 'disable' }>());
export const dppSetFinished = createAction(
  '[Configuration Onboarding] dpp set finished',
  props<{ dpp: 'auto' | 'enable' | 'disable' }>()
);
export const dppSetError = createAction('[Onboarding] dpp set error', props<{ error: any }>());
export const dppLoaded = createAction(
  '[Onboarding] dpp loaded',
  props<{ dppConfiguration: ILocationDppConfiguration }>()
);
export const backhaulSetFinished = createAction(
  '[Onboarding] backhaul set finished',
  props<{ backhaul: ILocationBackhaul }>()
);

export const configOptimizationsAutoModeEnable = createAction(
  '[Configuration Optimizations] optimizations auto mode enable',
  props<{ value: boolean }>()
);
export const configOptimizationsDfsModeSet = createAction(
  '[Configuration Optimizations] optimizations DFS mode set',
  props<{ value: 'auto' | 'enable' | 'disable' | 'demo' | 'HomeNonDFSChannels' | 'usDfs' }>()
);

export const configOptimizationsZeroWaitDfsModeSet = createAction(
  '[Configuration Optimizations] optimizations zeroWaitDfsMode mode set',
  props<{ value: 'auto' | 'enable' | 'disable' }>()
);
export const configOptimizationsZeroWaitDfsModeSetFinished = createAction(
  '[Configuration Optimizations] optimizations zeroWaitDfsMode mode set finished',
  props<{ value: 'auto' | 'enable' | 'disable' }>()
);
export const configOptimizationsZeroWaitDfsModeSetError = createAction(
  '[Configuration Optimizations] optimizations zeroWaitDfsMode mode set error',
  props<{ error: any }>()
);

export const configOptimizationsPrefer160MhzSet = createAction(
  '[Configuration Optimizations] optimizations Prefer160Mhz set',
  props<{ value: 'auto' | 'enable' | 'disable' }>()
);
export const configOptimizationsPrefer160MhzSetFinished = createAction(
  '[Configuration Optimizations] optimizations Prefer 160Mhz set finished',
  props<{ value: 'auto' | 'enable' | 'disable' }>()
);
export const configOptimizationsPrefer160MhzSetError = createAction(
  '[Configuration Optimizations] optimizations Prefer 160Mhz set error',
  props<{ error: any }>()
);

export const configOptimizationsMax24GhzChannelWidthSet = createAction(
  '[Configuration Optimizations] optimizations maxBandwidth maxBandwidth24g set',
  props<{ value: 'CHANNEL_BANDWIDTH_AUTO' | 'CHANNEL_BANDWIDTH_20' | 'CHANNEL_BANDWIDTH_40' }>()
);
export const configOptimizationsMax24GhzChannelWidthSetFinished = createAction(
  '[Configuration Optimizations] optimizations maxBandwidth maxBandwidth24g set finished',
  props<{ value: 'CHANNEL_BANDWIDTH_AUTO' | 'CHANNEL_BANDWIDTH_20' | 'CHANNEL_BANDWIDTH_40' }>()
);
export const configOptimizationsMax24GhzChannelWidthSetError = createAction(
  '[Configuration Optimizations] optimizations maxBandwidth maxBandwidth24g set error',
  props<{ error: any }>()
);

export const configOptimizationsMax5GhzChannelWidthSet = createAction(
  '[Configuration Optimizations] optimizations maxBandwidth maxBandwidth5g set',
  props<{ value: 'CHANNEL_BANDWIDTH_AUTO' | 'CHANNEL_BANDWIDTH_80' | 'CHANNEL_BANDWIDTH_160' }>()
);
export const configOptimizationsMax5GhzChannelWidthSetFinished = createAction(
  '[Configuration Optimizations] optimizations maxBandwidth maxBandwidth5g set finished',
  props<{ value: 'CHANNEL_BANDWIDTH_AUTO' | 'CHANNEL_BANDWIDTH_80' | 'CHANNEL_BANDWIDTH_160' }>()
);
export const configOptimizationsMax5GhzChannelWidthSetError = createAction(
  '[Configuration Optimizations] optimizations maxBandwidth maxBandwidth5g set error',
  props<{ error: any }>()
);

export const configOptimizationsMax6GhzChannelWidthSet = createAction(
  '[Configuration Optimizations] optimizations maxBandwidth maxBandwidth6g set',
  props<{
    value: 'CHANNEL_BANDWIDTH_AUTO' | 'CHANNEL_BANDWIDTH_80' | 'CHANNEL_BANDWIDTH_160' | 'CHANNEL_BANDWIDTH_320';
  }>()
);
export const configOptimizationsMax6GhzChannelWidthSetFinished = createAction(
  '[Configuration Optimizations] optimizations maxBandwidth maxBandwidth6g set finished',
  props<{
    value: 'CHANNEL_BANDWIDTH_AUTO' | 'CHANNEL_BANDWIDTH_80' | 'CHANNEL_BANDWIDTH_160' | 'CHANNEL_BANDWIDTH_320';
  }>()
);
export const configOptimizationsMax6GhzChannelWidthSetError = createAction(
  '[Configuration Optimizations] optimizations maxBandwidth maxBandwidth6g set error',
  props<{ error: any }>()
);

export const configOptimizationsUnii4ModeSet = createAction(
  '[Configuration Optimizations] optimizations UNII-4 Mode set',
  props<{ value: 'AUTO' | 'ENABLE' | 'DISABLE' }>()
);
export const configOptimizationsUnii4ModeSetFinished = createAction(
  '[Configuration Optimizations] optimizations UNII-4 Mode set finished',
  props<{ value: 'AUTO' | 'ENABLE' | 'DISABLE' }>()
);
export const configOptimizationsUnii4ModeSetError = createAction(
  '[Configuration Optimizations] optimizations UNII-4 Mode set error',
  props<{ error: any }>()
);

export const configOptimizationsHopPenaltyModeSet = createAction(
  '[Configuration Optimizations] optimizations Hop Penalty mode set',
  props<{ value: 'auto' | 'low' | 'medium' | 'high' }>()
);
export const configOptimizationsHopPenaltyModeSetFinished = createAction(
  '[Configuration Optimizations] optimizations Hop Penalty mode set finished',
  props<{ value: 'auto' | 'low' | 'medium' | 'high' }>()
);
export const configOptimizationsHopPenaltyModeSetError = createAction(
  '[Configuration Optimizations] optimizations Hop Penalty mode set error',
  props<{ error: any }>()
);

export const configOptimizationsPreCACSchedulerSet = createAction(
  '[Configuration Optimizations] optimizations PreCACScheduler set',
  props<{ value: 'auto' | 'enable' | 'disable' }>()
);
export const configOptimizationsPreCACSchedulerSetFinished = createAction(
  '[Configuration Optimizations] optimizations PreCACScheduler set finished',
  props<{ value: 'auto' | 'enable' | 'disable' }>()
);
export const configOptimizationsPreCACSchedulerSetError = createAction(
  '[Configuration Optimizations] optimizations PreCACScheduler set error',
  props<{ error: any }>()
);

export const configPaSAppTimeEnableSet = createAction(
  '[Configuration Privacy and security] appTime enable set',
  props<{ value: IAppTime }>()
);
export const configPaSAppTimeEnableSetFinished = createAction(
  '[Configuration Privacy and security] appTime enable set finished',
  props<{ value: boolean }>()
);
export const configPaSAppTimeEnableSetError = createAction(
  '[Configuration Privacy and security] appTime enable set error',
  props<{ error: any }>()
);
export const configRefreshAppTime = createAction('[Configuration Privacy and security] Refresh appTime');

export const configPaSPreferredIntelligenceSet = createAction(
  '[Configuration Privacy and security] preferred intelligence set',
  props<{ value: 'auto' | 'webpulse' | 'brightcloud' | 'gatekeeper' }>()
);
export const configPaSPreferredIntelligenceSetFinished = createAction(
  '[Configuration Privacy and security] preferred intelligence set finished',
  props<{ securityConfiguration: ILocationSecurityConfiguration }>()
);
export const configPaSPreferredIntelligenceSetError = createAction(
  '[Configuration Privacy and security] preferred intelligence set error',
  props<{ error: any }>()
);

export const configPaSIpThreatIntelligenceSet = createAction(
  '[Configuration Privacy and security] IpThreat intelligence set',
  props<{ value: 'auto' | 'webpulse' | 'gatekeeper' }>()
);
export const configPaSIpThreatIntelligenceSetFinished = createAction(
  '[Configuration Privacy and security] IpThreat intelligence set finished',
  props<{ securityConfiguration: ILocationSecurityConfiguration }>()
);
export const configPaSIpThreatIntelligenceSetError = createAction(
  '[Configuration Privacy and security] IpThreat intelligence set error',
  props<{ error: any }>()
);

export const configPaSWcHealthTelemetrySet = createAction(
  '[Configuration Privacy and security] web classifier health telemetry set',
  props<{ value: 'auto' | 'enable' | 'disable' }>()
);
export const configPaSWcHealthTelemetrySetFinished = createAction(
  '[Configuration Privacy and security] web classifier health telemetry set finished',
  props<{ securityConfiguration: ILocationSecurityConfiguration }>()
);
export const configPaSWcHealthTelemetrySetError = createAction(
  '[Configuration Privacy and security] web classifier health telemetry set error',
  props<{ error: any }>()
);

export const configPaSWcHeroTelemetrySet = createAction(
  '[Configuration Privacy and security] web classifier hero telemetry set',
  props<{ value: 'auto' | 'enable' | 'disable' }>()
);
export const configPaSWcHeroTelemetrySetFinished = createAction(
  '[Configuration Privacy and security] web classifier hero telemetry set finished',
  props<{ securityConfiguration: ILocationSecurityConfiguration }>()
);
export const configPaSWcHeroTelemetrySetError = createAction(
  '[Configuration Privacy and security] web classifier hero telemetry set error',
  props<{ error: any }>()
);

export const configPaSIpThreatProtectSet = createAction(
  '[Configuration Privacy and security] IpThreat Protect set',
  props<{ value: 'auto' | 'enable' | 'disable' }>()
);
export const configPaSIpThreatProtectSetFinished = createAction(
  '[Configuration Privacy and security] IpThreat Protect set finished',
  props<{ securityConfiguration: ILocationSecurityConfiguration }>()
);
export const configPaSIpThreatProtectSetError = createAction(
  '[Configuration Privacy and security] IpThreat Protect set error',
  props<{ error: any }>()
);

export const configPaSInlineDpiSet = createAction(
  '[Configuration Privacy and security] Inline Dpi set',
  props<{ value: 'auto' | 'enable' | 'disable' }>()
);
export const configPaSInlineDpiSetFinished = createAction(
  '[Configuration Privacy and security] Inline Dpi set finished',
  props<{ securityConfiguration: ILocationSecurityConfiguration }>()
);
export const configPaSInlineDpiSetError = createAction(
  '[Configuration Privacy and security] Inline Dpi set error',
  props<{ error: any }>()
);

export const configPaSDosProtectionSet = createAction(
  '[Configuration Privacy and security] Dos Protection set',
  props<{ value: 'auto' | 'enable' | 'disable' }>()
);
export const configPaSDosProtectionSetFinished = createAction(
  '[Configuration Privacy and security] Dos Protection set finished',
  props<{ securityConfiguration: ILocationSecurityConfiguration }>()
);
export const configPaSDosProtectionSetError = createAction(
  '[Configuration Privacy and security] Dos Protection set error',
  props<{ error: any }>()
);

export const configPaSDosProtectionActionSet = createAction(
  '[Configuration Privacy and security] Dos Protection Action set',
  props<{ value: 'auto' | 'observe' | 'block' }>()
);
export const configPaSDosProtectionActionSetFinished = createAction(
  '[Configuration Privacy and security] Dos Protection Action set finished',
  props<{ securityConfiguration: ILocationSecurityConfiguration }>()
);
export const configPaSDosProtectionActionSetError = createAction(
  '[Configuration Privacy and security] Dos Protection Action set error',
  props<{ error: any }>()
);

export const configPaSDpiContentFilteringSet = createAction(
  '[Configuration Privacy and security] Dpi Content Filtering set',
  props<{ value: 'auto' | 'enable' | 'disable' }>()
);
export const configPaSDpiContentFilteringSetFinished = createAction(
  '[Configuration Privacy and security] Dpi Content Filtering set finished',
  props<{ securityConfiguration: ILocationSecurityConfiguration }>()
);
export const configPaSDpiContentFilteringSetError = createAction(
  '[Configuration Privacy and security] Dpi Content Filtering set error',
  props<{ error: any }>()
);

export const configPaSFlowStatsSet = createAction(
  '[Configuration Privacy and security] FlowStats set',
  props<{ value: { [key: string]: 'auto' | 'enable' | 'disable' }; msgId: string }>()
);
export const configPaSFlowStatsSetFinished = createAction(
  '[Configuration Privacy and security] FlowStats set finished',
  props<{ msgId: string }>()
);
export const configPaSFlowStatsSetError = createAction(
  '[Configuration Privacy and security] FlowStats set error',
  props<{ error: any; msgId: string }>()
);
export const configReloadData = createAction('[Configuration Firmware] Reload data');

export const subscriptionServiceLevelChanged = createAction(
  '[Configuration Subscription] Service level changed',
  props<{ value: 'fullService' | 'basicService' | 'noService' | 'reset' }>()
);
