<div class="row" id="conf-dns">
  <div class="col-xs-12">
    <collapsiblebox [show]="expand" [collapsible]="true" (toggle)="toggleExpand()">
      <div header>{{ 'configurations.dns.title' | translate }}</div>
      <div container *ngIf="expand">
        <div class="columns" *ngIf="(dnsServersCapable$ | async) === true; else notCapable">
          <div class="column">
            <div class="row">
              <div class="key">{{ 'configurations.dns.primaryDns' | translate }}</div>
              <div class="value" *ngIf="(capabilities$ | async)?.dnsServers?.capable">
                <div class="output" [class.hide]="editPrimaryDns">
                  {{ primaryDns.value ? primaryDns.value : ('configurations.dns.setDNS' | translate) }}
                  <icon
                    name="icon-edit"
                    [class.disabled]="!plume.isSomeAdminRole()"
                    (click)="enablePrimaryDnsEdit()"
                    *ngIf="!editPrimaryDns"
                  ></icon>
                </div>
                <div class="editable" [class.hide]="!editPrimaryDns">
                  <input
                    (keyup.enter)="confirmPrimaryDnsEdit()"
                    (keyup.escape)="cancelPrimaryDnsEdit()"
                    [formControl]="primaryDns"
                    type="text"
                    placeholder="{{ 'configurations.dns.primaryDns' | translate }}..."
                    autofocus
                  />
                  <div class="controls" *ngIf="editPrimaryDns">
                    <icon name="icon-check" class="confirm" (click)="confirmPrimaryDnsEdit()"></icon>
                    <icon name="icon-close" class="size-0_8 cancel" (click)="cancelPrimaryDnsEdit()"></icon>
                  </div>
                </div>
              </div>
              <div class="value" *ngIf="!(capabilities$ | async)?.dnsServers?.capable">
                {{ 'configurations.dns.notCapable' | translate }}
              </div>
            </div>
            <div class="row">
              <div class="key">{{ 'configurations.dns.secondaryDns' | translate }}</div>
              <div class="value" *ngIf="(capabilities$ | async)?.dnsServers?.capable">
                <div class="output" [class.hide]="editSecondaryDns">
                  {{ secondaryDns.value ? secondaryDns.value : ('configurations.dns.setDNS' | translate) }}
                  <icon
                    name="icon-edit"
                    [class.disabled]="!plume.isSomeAdminRole()"
                    (click)="enableSecondaryDnsEdit()"
                    *ngIf="!editSecondaryDns"
                  ></icon>
                </div>
                <div class="editable" *ngIf="plume.isSomeAdminRole()" [class.hide]="!editSecondaryDns">
                  <input
                    (keyup.enter)="confirmSecondaryDnsEdit()"
                    (keyup.escape)="cancelSecondaryDnsEdit()"
                    [formControl]="secondaryDns"
                    type="text"
                    placeholder="{{ 'configurations.dns.secondaryDns' | translate }}..."
                    autofocus
                  />
                  <div class="controls" *ngIf="editSecondaryDns">
                    <icon name="icon-check" class="confirm" (click)="confirmSecondaryDnsEdit()"></icon>
                    <icon name="icon-close" class="size-0_8 cancel" (click)="cancelSecondaryDnsEdit()"></icon>
                  </div>
                </div>
                <div class="input-underline" [class.show]="editSecondaryDns"></div>
              </div>
              <div class="value" *ngIf="!(capabilities$ | async)?.dnsServers?.capable">
                {{ 'configurations.dns.notCapable' | translate }}
              </div>
            </div>
            <div class="row">
              <div class="key">{{ 'configurations.dns.dnsSource' | translate }}</div>
              <div class="value" *ngIf="dns.enabled">
                <span>
                  {{ 'configurations.dns.dnsSourceEnabled' | translate }}
                </span>
                <button class="action medium" (click)="updateDnsServer('', '')" [disabled]="!plume.isSomeAdminRole()">
                  {{ 'configurations.dns.clear' | translate }}
                </button>
              </div>
              <div class="value" *ngIf="!dns.enabled">
                {{ 'configurations.dns.dnsSourceNotEnabled' | translate }}
              </div>
            </div>
            <div class="row top">
              <div class="key">{{ 'configurations.dns.dnsData' | translate }}</div>
              <div class="value">
                <div class="data-wrap">
                  <div class="selector" [class.collapsed]="!dataExpand" (click)="toggleDataExpand()">
                    {{ 'configurations.dns.object' | translate }}
                    <icon name="icon-arrow-down" class="size-0_8" [class.rotate180]="dataExpand"></icon>
                  </div>
                  <ng-container *ngIf="dataExpand">
                    <ngx-json-viewer [json]="dns"></ngx-json-viewer>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ng-template #notCapable>
          <div class="value">
            {{ 'configurations.dns.notCapable' | translate }}
          </div>
        </ng-template>
      </div>
    </collapsiblebox>
  </div>
</div>
