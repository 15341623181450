import { Component, Input, ElementRef, OnChanges, AfterViewInit, OnDestroy } from '@angular/core';
import { D3Service } from 'src/app/lib/d3/service';
import { SpeedtestChart } from 'src/app/lib/d3/models/charts/speedtest.chart';
import { Tick } from 'src/app/lib/d3/models/objects/tick';
import { Bar } from 'src/app/lib/d3/models/objects/bar';
import * as erdm from 'element-resize-detector';

@Component({
  selector: 'speedtestchart',
  templateUrl: './speedtest.component.html',
  styleUrls: ['./speedtest.component.scss']
})
export class SpeedtestChartVisualComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input()
  id: string;

  @Input()
  data: Bar[];

  @Input()
  xrange: any;

  showTimeout: any;
  tooltipTimeout: any;
  tooltip: any = { show: false, x: 0, y: 0 };
  nodata: any = { show: false, x: 0, y: 0 };
  chart: SpeedtestChart;
  bars: Bar[];
  xAxis: Tick[];
  yAxisGrid: Tick[];
  yAxisLabels: Tick[];
  margins: any = {
    top: 25,
    right: 0,
    bottom: 35,
    left: 0
  };

  erd: any = erdm({ strategy: 'scroll' });

  constructor(private d3: D3Service, private host: ElementRef) {
    this.chart = this.d3.generate('speedtestchart');
  }

  ngAfterViewInit(): void {
    this.erd.listenTo(this.host.nativeElement, () => {
      this.render();
    });
  }

  ngOnChanges(): void {
    this.render();
  }

  render(): void {
    this.bars = this.chart.update(
      this.data,
      this.host.nativeElement.clientWidth,
      this.host.nativeElement.clientHeight,
      this.margins,
      this.xrange
    );

    this.xAxis = this.chart.xAxis();
    this.yAxisGrid = this.chart.yAxisGrid();
    this.yAxisLabels = this.chart.yAxisLabels();

    this.nodata = {
      show: !this.data.length,
      x: this.chart.calculateWidth() / 2,
      y: this.chart.calculateHeight() / 2
    };
  }

  showTooltip(bar: Bar): void {
    this.tooltip.left = bar.x + bar.width / 2;
    this.tooltip.bottom = bar.height + this.chart.getMargins().bottom + 10;
    this.tooltip.tests = bar.options.map((option) => {
      const details = [];

      if (option.testType) {
        details.push(option.testType);
      }

      if (option.serverName) {
        details.push(option.serverName);
      }

      if (option.serverId) {
        details.push(option.serverId);
      }

      option.speedDetails = details.length ? '(' + details.join(', ') + ')' : '';

      return option;
    });

    clearTimeout(this.tooltipTimeout);
    clearTimeout(this.showTimeout);

    this.tooltipTimeout = setTimeout(() => {
      const tooltipPosition = document.getElementById('tooltip-' + this.id).getBoundingClientRect();
      const canvasPosition = document.getElementById('speedtest-' + this.id).getBoundingClientRect();

      if (tooltipPosition.left <= canvasPosition.left) {
        this.tooltip.left = bar.x + bar.width / 2 + (canvasPosition.left - tooltipPosition.left);
      }

      if (tooltipPosition.left + tooltipPosition.width >= canvasPosition.left + canvasPosition.width) {
        this.tooltip.left =
          bar.x +
          bar.width / 2 -
          (tooltipPosition.left + tooltipPosition.width - (canvasPosition.left + canvasPosition.width));
      }

      this.showTimeout = setTimeout(() => {
        this.tooltip.show = true;
      }, 10);
    }, 10);
  }

  hideTooltip(): void {
    this.tooltip.show = false;
    clearTimeout(this.tooltipTimeout);
    clearTimeout(this.showTimeout);
  }

  ngOnDestroy(): void {
    this.erd.uninstall(this.host.nativeElement);

    clearTimeout(this.tooltipTimeout);
    clearTimeout(this.showTimeout);
  }
}
