<div class="row" id="conf-wan">
  <div class="col-xs-12">
    <collapsiblebox [show]="expand" [collapsible]="true" (toggle)="toggleExpand()">
      <div header>{{ 'configurations.wan.title' | translate }}</div>
      <div container *ngIf="expand">
        <div class="columns">
          <div class="column">
            <div class="network">
              <div class="row">
                <div class="key">{{ 'configurations.wan.networkMode' | translate }}</div>
                <div class="value">
                  <slider-toggle-mobile-aware
                    [options]="networkModeItems"
                    [editable]="(networkModeEditable$ | async) && ((capabilities$ | async)?.networkMode?.capable )"
                    (toggle)="action('networkMode', $event)"
                  ></slider-toggle-mobile-aware>
                </div>
              </div>

              <div class="row">
                <div class="key">{{ 'configurations.wan.ethernetLan' | translate }}</div>
                <div class="value">
                  <slider-toggle-mobile-aware
                    [options]="ethernetLanItems"
                    [editable]="plume.isSomeAdminRole()"
                    (toggle)="action('ethernetLan', $event)"
                  ></slider-toggle-mobile-aware>
                </div>
              </div>

              <div class="row">
                <div class="key">{{ 'configurations.wan.upnp' | translate }}</div>
                <div class="value">
                  <slider-toggle-mobile-aware
                    [options]="upnpItems"
                    [editable]="plume.isSomeAdminRole() && (capabilities$ | async)?.upnp?.capable"
                    (toggle)="action('upnp', $event)"
                  ></slider-toggle-mobile-aware>
                </div>
              </div>

              <div class="row">
                <div class="key">{{ 'configurations.wan.persistConfigurationOnGateway' | translate }}</div>
                <div class="value">
                  <slider-toggle-mobile-aware
                    [options]="persistConfigurationItems"
                    [editable]="
                      plume.isSomeAdminRole() && (capabilities$ | async)?.persistConfigurationOnGateway?.capable
                    "
                    (toggle)="action('persistConfiguration', $event)"
                  ></slider-toggle-mobile-aware>
                </div>
              </div>
            </div>

            <ng-container *ngIf="(plume.permissions | async)?.uiFeatures?.wanAdvancedConfiguration">
              <h3 (click)="advancedOpen = !advancedOpen">
                {{ 'configurations.wan.advanced' | translate }}
                <icon name="icon-arrow-down" [class.rotate180]="advancedOpen"></icon>
              </h3>
              <div class="content" *ngIf="advancedOpen">
                <div class="row message">
                  {{ 'configurations.wan.message' | translate }}
                </div>

                <div class="inner-section" *ngIf="bluetooth">
                  <div class="row">
                    <div class="header">{{ 'configurations.wan.bluetoothLE' | translate }}</div>
                  </div>
                  <div class="row">
                    <div class="key">
                      {{ 'configurations.wan.configureNode' | translate }}
                    </div>
                    <button class="action medium" (click)="showBLE = true">
                      {{ 'configurations.wan.settings' | translate }}
                    </button>
                    <icon
                      name="icon-info"
                      tooltip="Experimental feature! Requires Chrome browser v85 or higher and proximity to node."
                    ></icon>
                  </div>
                </div>

                <div class="inner-section">
                  <div class="row">
                    <div class="header value">
                      <div class="data-wrap">
                        <div class="selector" [class.collapsed]="!dataExpand" (click)="dataExpand = !dataExpand">
                          {{ 'configurations.wan.data' | translate }}
                          <icon name="icon-arrow-down" class="size-0_8" [class.rotate180]="dataExpand"></icon>
                        </div>
                        <ng-container *ngIf="dataExpand">
                          <ng-container *ngIf="wanNodes$ | async as wanNodes; else noWanNodes">
                            <ngx-json-viewer [json]="wanNodes"></ngx-json-viewer>
                          </ng-container>
                          <ng-template #noWanNodes> {{ 'configurations.wan.noWanNodes' | translate }} </ng-template>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>

                <wan-configuration
                  *ngIf="(plume.permissions | async)?.uiFeatures?.wanConfiguration"
                ></wan-configuration>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </collapsiblebox>
  </div>
</div>

<modal (overlayAction)="showBLE = $event" *ngIf="showBLE">
  <ng-container title>Bluetooth LE</ng-container>
  <ng-container content>
    <ble #BLE></ble>
  </ng-container>
  <ng-container controls>
    <button class="tertiary light" (click)="closeBLE()">Close</button>
  </ng-container>
</modal>
