<div class="chart" #chartElm>
  <div
    class="tick"
    #elm
    (mouseenter)="mouseEnter(elm)"
    [class.hasBusy]="tick.busy"
    *ngFor="let tick of data?.ticks; let isLast = last"
  >
    <div class="busy" [style.background]="tick.busy" *ngIf="tick.busy"></div>
    <div class="value" [style.background]="tick.color" [style.borderColor]="tick.border"></div>
    <div class="time" [style.left.px]="tooltipOverflowMove">
      {{ tick.time }}
    </div>

    <div
      [style.left.px]="tooltipOverflowMove"
      class="infoContainer"
      *ngIf="!isLast || (isLast && status !== 'connected')"
    >
      <ng-container
        *ngTemplateOutlet="stateTemplate || defaultStateTemplate; context: { $implicit: tick }"
      ></ng-container>
    </div>
    <div [style.left.px]="tooltipOverflowMove" class="infoContainer" *ngIf="isLast && status === 'connected'">
      <ng-container *ngTemplateOutlet="stateTemplate || lastStateTemplate; context: { $implicit: tick }"></ng-container>
    </div>
  </div>
</div>

<div class="axis">
  <div *ngFor="let tick of data?.axis">{{ tick | translate }}</div>
</div>

<ng-template #defaultStateTemplate let-tick>
  <div class="line-tick-chart-info">
    {{ tick.state ? (translatePrefix + tick.state | translate) : hasOfflines ? ('qoe.offline' | translate) : 0 }}
  </div>
</ng-template>

<ng-template #lastStateTemplate let-tick>
  <div class="line-tick-chart-info">
    {{ tick.state ? (translatePrefix + tick.state | translate) : hasOfflines ? ('qoe.processing' | translate) : 0 }}
  </div>
</ng-template>
