import { Pipe, PipeTransform } from '@angular/core';
import { IDevice } from '../interfaces/interface';

@Pipe({ name: 'deviceNameFromMobilizeUrl' })
export class DeviceNameFromMobilizeUrl implements PipeTransform {
  transform(url: string, allDevices: IDevice[]): string {
    // URL looks like this https://preprod-pugrd21dev-logs-spsremote-com.s3.amazonaws.com/65d7c234b954ef000a7823dd.1728677426.a8442c84-92be-4d9e-a469-cb497515be7a?AWSAccessKeyId=REMOVED>&Expires=1729868199&Signature=REMOVED
    const uuid = url.split('/').pop().split('?').shift().split('.')?.[2];
    const foundDevice = uuid ? allDevices.find((device) => uuid === device.ohp?.uuid) : undefined;
    return foundDevice ? foundDevice.nickname || foundDevice.name : url;
  }
}
