<div class="title">{{ 'health.networkInformation.networkConfiguration' | translate }}</div>
<ng-container *ngIf="loading">
  <div class="loading">{{ 'health.networkInformation.loading' | translate }}</div>
</ng-container>
<ng-container *ngIf="!loading">
  <div class="notonboarded" *ngIf="networkError">
    {{ 'health.networkInformation.onboarding' | translate }}
  </div>

  <div class="section">
    <div class="table">
      <div class="row" *ngIf="showNetworkEnabled">
        <div class="cell">
          <div class="key">{{ 'health.networkInformation.network' | translate }}:</div>
        </div>
        <div class="cell stretch">
          <div class="value">
            <slidetoggler
              [options]="networkEnabledItems"
              [editable]="networkTogglerEnabled"
              (toggle)="changeNetworkEnabled($event)"
            ></slidetoggler>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="!permissions?.uiFeatures?.wpaProtocolFromVapStatus && wpa3Capable$ | async">
        <div class="cell">
          <div class="key">{{ 'health.networkInformation.encryption' | translate }}:</div>
        </div>
        <div class="cell stretch">
          <div class="value">
            <slidetoggler
              [options]="encryptionItems"
              [editable]="encryptionEnabled"
              (toggle)="changeEncryption($event)"
            ></slidetoggler>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="!networkError && (WPA3Recommendation$ | async) as WPA3Recommendation">
        <div class="cell">
          <div class="key">{{ 'health.networkInformation.recommendations.label' | translate }}:</div>
        </div>
        <div class="cell">
          <div class="value">
            {{ 'health.networkInformation.recommendations.' + WPA3Recommendation | translate }}
            <button
              class="action medium"
              (click)="ssidModal = true"
              [disabled]="isGroupSupportTechnician()"
              *ngIf="checkIfRecommendationCanBeExecuted(WPA3Recommendation)"
            >
              {{ 'health.networkInformation.recommendations.execute' | translate }}
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="cell">
          <div class="key">{{ 'health.networkInformation.ssid' | translate }}:</div>
        </div>
        <div class="cell stretch">
          <div class="value editable" *ngIf="!editSsid">
            <div class="text">{{ network.ssid || ('health.networkInformation.na' | translate) }}</div>
            <div class="controls">
              <icon
                name="icon-info"
                class="size-0_8 info"
                tooltip="{{ 'health.networkInformation.encryption' | translate }}: {{
                  helper.wpaMode(network?.wpaMode) | translate
                }}"
                *ngIf="network.ssid && !permissions?.uiFeatures?.wpaProtocolFromVapStatus"
              ></icon>
              <icon
                name="icon-info"
                class="size-0_8 info"
                tooltip="{{ 'health.networkInformation.encryptionInPodsAndNodes' | translate }}"
                *ngIf="permissions?.uiFeatures?.wpaProtocolFromVapStatus"
              ></icon>
              <icon
                name="icon-edit"
                tooltip="{{ 'techdash.tooltip.editSsid' | translate }}"
                (click)="toggleEditSsid()"
                *ngIf="
                  permissions?.uiFeatures.editSSID && !editSsid && (reducedMode$ | async) === false && networkEnabled
                "
              ></icon>
            </div>
          </div>
          <div class="value editable" *ngIf="editSsid">
            <div class="form">
              <input
                (keyup.enter)="updateWifiNetworkSSID()"
                (keyup.escape)="toggleEditSsid()"
                [formControl]="ssidFormControl"
                autofocus
              />
              <div class="validation" *ngIf="!validateSsid()">
                {{ 'techdash.infoArea.ssidValidation' | translate }}
              </div>
            </div>
            <div class="edit" *ngIf="editSsid">
              <icon
                name="icon-check"
                class="confirm"
                tooltip="{{ 'techdash.tooltip.set' | translate }}"
                (click)="updateWifiNetworkSSID()"
              ></icon>
              <icon
                name="icon-close"
                class="size-0_8 cancel"
                tooltip="{{ 'techdash.tooltip.cancel' | translate }}"
                (click)="toggleEditSsid()"
              ></icon>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="network.ssid">
        <div class="cell">
          <div class="key">{{ 'health.networkInformation.password' | translate }}:</div>
        </div>
        <div class="cell space stretch">
          <div class="value editable" *ngIf="!editPassword">
            <div class="text">**********</div>
            <icon
              name="icon-edit"
              tooltip="{{ 'techdash.tooltip.editPassword' | translate }}"
              (click)="toggleEditPassword()"
              *ngIf="
                permissions?.uiFeatures.editPSK && !editPassword && (reducedMode$ | async) === false && networkEnabled
              "
            ></icon>
          </div>
          <div class="value editable" *ngIf="editPassword">
            <labeled-input>
              <input
                (keyup.enter)="updateWifiPassword()"
                (keyup.escape)="toggleEditPassword()"
                [formControl]="passwordFormControl"
                type="password"
                autocomplete="new-password"
                autofocus
              />
              <div info error class="small" *ngIf="!validatePassword()">
                {{ 'techdash.infoArea.passwordValidation' | translate }}
              </div>
            </labeled-input>
            <div class="edit" *ngIf="editPassword">
              <icon
                name="icon-check"
                class="confirm"
                tooltip="{{ 'techdash.tooltip.set' | translate }}"
                (click)="updateWifiPassword()"
              ></icon>
              <icon
                name="icon-close"
                class="size-0_8 cancel"
                tooltip="{{ 'techdash.tooltip.cancel' | translate }}"
                (click)="toggleEditPassword()"
              ></icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <collapsiblebox
    mode="bare"
    class="advanced"
    toggleSide="right"
    [collapsible]="true"
    [show]="advancedOptions"
    *ngIf="
      !networkError &&
      (isHomePass$ | async) === true &&
      encryptionMode !== 'sae-mixed' &&
      (capabilities$ | async)?.multiPasswordSSID.capable &&
      networkEnabled &&
      mode !== 'secondary'
    "
  >
    <ng-container header>{{ 'health.networkInformation.advancedOptions' | translate }}</ng-container>
    <ng-container container>
      <div class="title small">
        {{ 'health.networkInformation.modal.title' | translate }}
      </div>
      <homepass [network]="network" (networks)="getNetwork()"></homepass>
    </ng-container>
    <ng-container container *ngIf="(isUprise$ | async) === true">
      <secondarynetworks></secondarynetworks>
    </ng-container>
  </collapsiblebox>

  <collapsiblebox
    mode="bare"
    class="advanced"
    toggleSide="right"
    [collapsible]="true"
    [show]="advancedOptions"
    *ngIf="!networkError && (isHomePass$ | async) === false && networkEnabled && mode !== 'secondary'"
  >
    <ng-container header>{{ 'health.networkInformation.advancedOptions' | translate }}</ng-container>
    <ng-container container>
      <secondarynetworks></secondarynetworks>
    </ng-container>
  </collapsiblebox>
</ng-container>

<modal class="ssidModal" mode="compact" (overlayAction)="ssidModal = $event" *ngIf="ssidModal">
  <ng-container title *ngIf="(WPA3Recommendation$ | async) === 'addWpa2Ssid'">
    {{ 'health.networkInformation.recommendations.titleWpa2ssid' | translate }}
  </ng-container>
  <ng-container title *ngIf="(WPA3Recommendation$ | async) === 'addWpa3Ssid'">
    {{ 'health.networkInformation.recommendations.titleWpa3ssid' | translate }}
  </ng-container>
  <ng-container content>
    <labeled-input>
      <input
        [formControl]="wpaFormControl"
        type="text"
        placeholder="{{ 'health.networkInformation.recommendations.inputSsid' | translate }}"
        autofocus
      />
      <div info error class="small" *ngIf="!validateWpa() && wpaFormControl.touched">
        {{ 'techdash.infoArea.ssidValidation' | translate }}
      </div>
    </labeled-input>
  </ng-container>
  <ng-container controls>
    <button class="tertiary light" (click)="ssidModal = false">
      {{ 'health.networkInformation.recommendations.close' | translate }}
    </button>
    <button class="super-primary light" (click)="executeRecommendation()">
      {{ 'health.networkInformation.recommendations.add' | translate }}
    </button>
  </ng-container>
</modal>
