import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PageScrollService } from 'ngx-page-scroll-core';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { CustomerService } from 'src/app/lib/services/customer.service';
import { ProfilerService } from 'src/app/lib/services/profiler.service';
import { combineLatest } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectAppTime, selectPipeLocationOnChange } from 'src/app/store/customer/customer.selectors';
import { filter, take } from 'rxjs/operators';
import { selectCapabilities } from 'src/app/store/customer/capabilities.selector';
import {
  IAppFacade,
  IAppTime,
  IDevice,
  ILocation,
  IPerson,
  IWanStatsDaily,
  IWanStatsMonthly,
  IWanStatsWeekly,
  IWifiMotion,
  IWifiNetwork
} from 'src/app/lib/interfaces/interface';
import { SecurityPolicyService } from 'src/app/lib/services/security-policy.service';
import { selectDevices } from 'src/app/store/polling/polling.selector';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  templateUrl: './profiler.component.html',
  styleUrls: ['./profiler.component.scss']
})
export class ProfilerComponent implements OnInit {
  wanStatsDaily: IWanStatsDaily = null;
  wanStatsWeekly: IWanStatsWeekly = null;
  wanStatsMonthly: IWanStatsMonthly = null;
  allDevices: IDevice[] = null;
  hourlyCounts: number[][] = null;
  wifiNetwork: IWifiNetwork = null;
  privateMode: boolean = null;
  persons: IPerson[] = null;
  wifiMotion: IWifiMotion = null;
  appFacade: IAppFacade = null;
  appTime: IAppTime = null;
  reset: number = 0;
  capabilities$ = this.store.select(selectCapabilities);
  location$ = this.store.pipe(selectPipeLocationOnChange);

  constructor(
    private router: Router,
    private plume: PlumeService,
    private mixpanel: MixpanelService,
    private pagescroll: PageScrollService,
    private securityPolicyService: SecurityPolicyService,
    private store: Store,
    private customer: CustomerService,
    private profilerService: ProfilerService
  ) {}

  ngOnInit(): void {
    this.mixpanel.storeEvent('PROFILER_SCREEN');

    combineLatest([this.store.pipe(selectPipeLocationOnChange), this.capabilities$])
      .pipe(
        filter(([location, capabilities]) => !!location && !!capabilities),
        take(1)
      )
      .subscribe(([location, capabilities]) => {
        this.init(capabilities.dnsCategoryBlocking.capable, location.profile);
      });
  }

  init(dnsCategoryBlockingCapable: boolean, profile: ILocation['profile']): void {
    this.wanStatsDaily = null;
    this.wanStatsWeekly = null;
    this.wanStatsMonthly = null;
    this.allDevices = null;
    this.hourlyCounts = null;
    this.wifiNetwork = null;
    this.privateMode = null;
    this.persons = null;
    this.wifiMotion = null;
    this.appFacade = null;
    this.appTime = null;
    this.reset = Date.now();

    if (profile !== 'auto') {
      this.router.navigate(['customer', this.plume.customerid, 'location', this.plume.locationid], {
        state: {
          replaceUrl: true // profiler should be replaced with next url in history
        }
      });
    }

    this.store
      .select(selectDevices)
      .pipe(untilDestroyed(this))
      .subscribe((devices) => {
        this.allDevices = devices;
      });

    this.store
      .select(selectAppTime)
      .pipe(untilDestroyed(this))
      .subscribe((appTime) => {
        this.appTime = appTime;
      });

    this.customer.getWifiNetwork$().subscribe(
      (response) => {
        this.wifiNetwork = response.wifiNetwork;
        this.privateMode = response.wifiNetwork?.privateMode || false;
      },
      () => {
        console.error('Unable to get WiFi network: possibly new network');
        this.wifiNetwork = null;
        this.privateMode = false;
      }
    );

    this.profilerService.appFacadeDashboard$().subscribe(
      (response) => {
        this.appFacade = response;
      },
      () => {
        this.appFacade = null;
      }
    );

    this.profilerService.persons$().subscribe(
      (response) => {
        this.persons = response;
      },
      () => {
        this.persons = [];
      }
    );

    this.profilerService.wifiMotion$().subscribe(
      (response) => {
        this.wifiMotion = response;
      },
      () => {
        this.wifiMotion = null;
      }
    );

    this.profilerService.wanStatsDaily$().subscribe(
      (response) => {
        this.wanStatsDaily = response;
      },
      () => {
        this.wanStatsDaily = null;
      }
    );

    this.profilerService.wanStatsWeekly$().subscribe(
      (response) => {
        this.wanStatsWeekly = response;
      },
      () => {
        this.wanStatsWeekly = null;
      }
    );

    this.profilerService.wanStatsMonthly$().subscribe(
      (response) => {
        this.wanStatsMonthly = response;
      },
      () => {
        this.wanStatsMonthly = null;
      }
    );

    if (dnsCategoryBlockingCapable) {
      this.securityPolicyService.hourlyBlockedCounts$().subscribe(
        (response) => {
          this.hourlyCounts = response;
        },
        () => {
          this.hourlyCounts = null;
        }
      );
    }
  }

  scrollTo(selector: string): void {
    setTimeout(() => {
      const view = document.getElementById('customer-view');

      this.pagescroll.scroll({
        document: window.document,
        scrollTarget: selector,
        scrollViews: [view],
        scrollOffset: 10
      });
    }, 100);
  }
}
