import { createAction, props } from '@ngrx/store';

export const threadNetworkSet = createAction(
  '[Configuration][ThreadNetwork] Set network',
  props<{ enable: boolean; threadInterface: string; reportingInterval: number }>()
);

export const threadNetworkSetFinished = createAction('[Configuration][ThreadNetwork] Set network finished');

export const blankAction = createAction('[Configuration][ThreadNetwork] Blank action');
