export class Bar {
  class?: string;
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  rx?: number;
  ry?: number;
  clip?: string;

  label: string;
  value: number;
  count: number;
  options: any;

  constructor(label: any, value: any, count: number = null, options: any = {}) {
    this.label = label;
    this.value = value;
    this.count = count;
    this.options = options;
  }
}
