import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ToastService } from 'src/app/lib/services/toast.service';
import { ThreadService } from 'src/app/lib/services/thread.service';
import { blankAction, threadNetworkSet, threadNetworkSetFinished } from './threadnetwork.actions';
import { customerLocationConfigAndStateReload } from '../customer/customer.actions';

@Injectable()
export class ThreadNetworkEffects {
  setProperty$ = createEffect(() =>
    this.actions$.pipe(
      ofType(threadNetworkSet),
      switchMap(({ enable, threadInterface, reportingInterval }) =>
        this.thread.setNetwork$(enable, threadInterface, reportingInterval).pipe(
          map(() => {
            this.store.dispatch(
              customerLocationConfigAndStateReload({
                actionType: '[Configuration][ThreadNetwork] Thread config patched'
              })
            );
            return threadNetworkSetFinished();
          }),
          catchError((error) => {
            switch (error.error.error.message) {
              case 'Value is not a number.':
                this.toast.error(
                  'configurations.threadnetwork.intervalNotNumberMsg',
                  'configurations.threadnetwork.error'
                );
                break;
              case 'should NOT be shorter than 1 characters':
                this.toast.error(
                  'configurations.threadnetwork.interfaceTooShortMsg',
                  'configurations.threadnetwork.error'
                );
                break;
              default:
                this.toast.error(error.error.error.message, error.error.error.name);
            }

            return of(blankAction());
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private toast: ToastService,
    private thread: ThreadService
  ) {}
}
