<div class="tabs" *ngIf="(profile$ | async) !== 'property'">
  <div class="tab" [class.selected]="mode === 'home'" (click)="toggleMode('home')">
    {{ 'devicegroup.secure' | translate }}
  </div>
  <div class="tab" [class.selected]="mode === 'fronthaul'" (click)="toggleMode('fronthaul')">
    {{ 'devicegroup.employee' | translate }}
  </div>
</div>
<div class="section" *ngIf="mode == 'home'">
  <ng-container *ngIf="secureDisabled">
    <div class="empty">{{ 'devicegroup.noSecureNetworks' | translate }}</div>
  </ng-container>
  <ng-container *ngIf="!secureDisabled">
    <div class="group-list">
      <div class="title">{{ 'devicegroup.groups' | translate }}:</div>
      <ng-container *ngIf="secure.loading">{{ 'devicegroup.loading' | translate }}</ng-container>
      <ng-container *ngIf="!secure.loading">
        <div class="group">
          <ng-container *ngFor="let group of secure.groups">
            <div class="name" *ngIf="!group.edit">{{ group?.name }}</div>
            <div class="name" *ngIf="group.edit">
              <input [formControl]="group.editName" type="text" placeholder="{{ group.name }}" />
            </div>
            <div class="devices" *ngIf="!group.edit">
              {{ 'devicegroup.devices' | translate }}: {{ group?.assignedDevices.length }}
              <icon
                name="icon-info"
                class="size-0_8"
                [maxWidth]="320"
                [tooltip]="group"
                [template]="tooltipDevicesTemplate"
              ></icon>
            </div>
            <div class="devices" *ngIf="group.edit">
              <drop-down-select
                class="selector small"
                emptyText="{{ 'devicegroup.devices' | translate }}: 0"
                headerPrefix="{{ 'devicegroup.devices' | translate }}:"
                listMinWidth="asHeader"
                [listMaxWidth]="300"
                [rowTemplate]="deviceTemplate"
                [values]="group.updateDevices.list"
                [(ownModel)]="group.updateDevices.editSelected"
                [multiSelect]="true"
                [filterVisible]="false"
              ></drop-down-select>
            </div>
            <div class="shares" *ngIf="!group.edit">
              {{ 'devicegroup.shares' | translate }}: {{ group?.groupShares.length }}
              <icon
                name="icon-info"
                class="size-0_8"
                [maxWidth]="320"
                [tooltip]="group"
                [template]="tooltipSharesTemplate"
              ></icon>
            </div>
            <div class="shares" *ngIf="group.edit">
              <drop-down-select
                class="selector small"
                emptyText="{{ 'devicegroup.shares' | translate }}: 0"
                headerPrefix="{{ 'devicegroup.shares' | translate }}:"
                listMinWidth="asHeader"
                [listMaxWidth]="300"
                [values]="group.updateShares.list"
                [ownModel]="group.updateShares.selected"
                (ownModelChange)="group.updateShares.selected = $event"
                [multiSelect]="true"
                [filterVisible]="false"
              ></drop-down-select>
            </div>
            <div class="controls">
              <icon
                name="icon-check"
                class="confirm"
                tooltip="{{ 'devicegroup.save' | translate }}"
                (click)="editGroup(group)"
                *ngIf="group.edit"
                [class.disabled]="isInvalidRole"
              ></icon>
              <icon
                name="icon-close"
                class="size-0_8 cancel"
                tooltip="{{ 'devicegroup.cancel' | translate }}"
                (click)="cancelEdit(group, 'secure')"
                *ngIf="group.edit"
                [class.disabled]="isInvalidRole"
              ></icon>
              <icon
                name="icon-edit"
                tooltip="{{ 'devicegroup.edit' | translate }}"
                (click)="enableEdit(group)"
                *ngIf="!group.edit"
                [class.disabled]="isInvalidRole"
              ></icon>
              <icon
                name="icon-close"
                class="size-0_8"
                tooltip="{{ 'devicegroup.delete' | translate }}"
                (click)="deleteGroup(group)"
                [class.disabled]="isInvalidRole"
                *ngIf="!group.edit"
              ></icon>
            </div>
          </ng-container>
        </div>
        <div class="pagination" *ngIf="secure?.allGroups?.length > secure?.limit">
          <button class="action" (click)="action('previous', 'secure')" [class.disabled]="secure?.offset === 0">
            &lt;
          </button>
          <div class="label">
            {{ secure?.offset + 1 }} -
            {{
              secure?.offset + secure?.limit > secure?.allGroups?.length
                ? secure?.allGroups?.length
                : secure?.offset + secure?.limit
            }}
            of {{ secure?.allGroups?.length }}
          </div>
          <button
            class="action"
            (click)="action('next', 'secure')"
            [class.disabled]="secure?.offset + secure?.limit >= secure?.allGroups.length"
          >
            &gt;
          </button>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <div class="create-new" *ngIf="!secure.loading">
    <div class="title" [class.disabled]="isInvalidRole">{{ 'devicegroup.createNewGroup' | translate }}</div>
    <div class="new-group" [class.disabled]="isInvalidRole">
      <input [formControl]="secure.groupName" type="text" placeholder="{{ 'devicegroup.newGroupName' | translate }}" />
      <drop-down-select
        class="selector small devices"
        emptyText="{{ 'devicegroup.selectedDevices' | translate }}: 0"
        headerPrefix="{{ 'devicegroup.selectedDevices' | translate }}:"
        [disabled]="!secure.devices.list.length"
        [rowTemplate]="deviceTemplate"
        [values]="secure.devices.list"
        [ownModel]="secure.devices.selected"
        (ownModelChange)="secure.devices.selected = $event"
        [multiSelect]="true"
        [filterVisible]="false"
      ></drop-down-select>
      <button class="action medium" [disabled]="isInvalidRole" (click)="addGroup()">
        {{ 'devicegroup.addGroup' | translate }}
      </button>
    </div>
  </div>
</div>
<div class="section" *ngIf="mode == 'fronthaul'">
  <ng-container *ngIf="employeeDisabled">
    <div class="empty">{{ 'devicegroup.noEmployeeNetworks' | translate }}</div>
  </ng-container>
  <ng-container *ngIf="!employeeDisabled">
    <div class="group-list">
      <div class="title">{{ 'devicegroup.groups' | translate }}:</div>
      <ng-container *ngIf="employee.loading">{{ 'devicegroup.loading' | translate }}</ng-container>
      <ng-container *ngIf="!employee.loading">
        <div class="group">
          <ng-container *ngFor="let group of employee.groups">
            <div class="name" *ngIf="!group.edit">{{ group?.name }}</div>
            <div class="name" *ngIf="group.edit">
              <input [formControl]="group.editName" type="text" placeholder="{{ group.name }}" />
            </div>
            <div class="devices" *ngIf="!group.edit">
              {{ 'devicegroup.devices' | translate }}: {{ group?.assignedDevices.length }}
              <icon
                name="icon-info"
                class="size-0_8"
                [maxWidth]="320"
                [tooltip]="group"
                [template]="tooltipDevicesTemplate"
              ></icon>
            </div>
            <div class="devices" *ngIf="group.edit">
              <drop-down-select
                class="selector small"
                emptyText="{{ 'devicegroup.devices' | translate }}: 0"
                headerPrefix="{{ 'devicegroup.devices' | translate }}:"
                listMinWidth="asHeader"
                [listMaxWidth]="300"
                [rowTemplate]="deviceTemplate"
                [values]="group.updateDevices.list"
                [(ownModel)]="group.updateDevices.editSelected"
                [multiSelect]="true"
                [filterVisible]="false"
              ></drop-down-select>
            </div>
            <div class="shares" *ngIf="!group.edit">
              {{ 'devicegroup.shares' | translate }}: {{ group?.groupShares.length }}
              <icon
                name="icon-info"
                class="size-0_8"
                [maxWidth]="320"
                [tooltip]="group"
                [template]="tooltipSharesTemplate"
              ></icon>
            </div>
            <div class="shares" *ngIf="group.edit">
              <drop-down-select
                class="selector small"
                emptyText="{{ 'devicegroup.shares' | translate }}: 0"
                headerPrefix="{{ 'devicegroup.shares' | translate }}:"
                listMinWidth="asHeader"
                [listMaxWidth]="300"
                [values]="group.updateShares.list"
                [ownModel]="group.updateShares.selected"
                (ownModelChange)="group.updateShares.selected = $event"
                [multiSelect]="true"
                [filterVisible]="false"
              ></drop-down-select>
            </div>
            <div class="controls">
              <icon
                name="icon-check"
                class="confirm"
                tooltip="{{ 'devicegroup.save' | translate }}"
                (click)="editGroup(group)"
                *ngIf="group.edit"
                [class.disabled]="isInvalidRole"
              ></icon>
              <icon
                name="icon-close"
                class="size-0_8 cancel"
                tooltip="{{ 'devicegroup.cancel' | translate }}"
                (click)="cancelEdit(group, 'employee')"
                *ngIf="group.edit"
                [class.disabled]="isInvalidRole"
              ></icon>
              <icon
                name="icon-edit"
                tooltip="{{ 'devicegroup.edit' | translate }}"
                (click)="enableEdit(group)"
                *ngIf="!group.edit"
                [class.disabled]="isInvalidRole"
              ></icon>
              <icon
                name="icon-close"
                class="size-0_8"
                tooltip="{{ 'devicegroup.delete' | translate }}"
                (click)="deleteGroup(group)"
                *ngIf="!group.edit"
                [class.disabled]="isInvalidRole"
              ></icon>
            </div>
          </ng-container>
        </div>
        <div class="pagination" *ngIf="employee?.allGroups?.length > employee?.limit">
          <button class="action" (click)="action('previous', 'employee')" [class.disabled]="employee?.offset === 0">
            &lt;
          </button>
          <div class="label">
            {{ employee?.offset + 1 }} -
            {{
              employee?.offset + employee?.limit > employee?.allGroups?.length
                ? employee?.allGroups?.length
                : employee?.offset + employee?.limit
            }}
            of {{ employee?.allGroups?.length }}
          </div>
          <button
            class="action"
            (click)="action('next', 'employee')"
            [class.disabled]="employee?.offset + employee?.limit >= employee?.allGroups.length"
          >
            &gt;
          </button>
        </div>
      </ng-container>
    </div>
    <div class="create-new" *ngIf="!employee.loading">
      <div class="title" [class.disabled]="isInvalidRole">{{ 'devicegroup.createNewGroup' | translate }}</div>
      <div class="new-group" [class.disabled]="isInvalidRole">
        <input
          [formControl]="employee.groupName"
          type="text"
          placeholder="{{ 'devicegroup.newGroupName' | translate }}"
        />
        <drop-down-select
          class="selector small devices"
          emptyText="{{ 'devicegroup.selectedDevices' | translate }}: 0"
          headerPrefix="{{ 'devicegroup.selectedDevices' | translate }}:"
          [disabled]="!employee.devices.list.length || isInvalidRole"
          [rowTemplate]="deviceTemplate"
          [values]="employee.devices.list"
          [ownModel]="employee.devices.selected"
          (ownModelChange)="employee.devices.selected = $event"
          [multiSelect]="true"
          [filterVisible]="false"
        ></drop-down-select>
        <button class="action medium" (click)="addGroup()">{{ 'devicegroup.addGroup' | translate }}</button>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #deviceTemplate let-value>
  <div class="ellipsis">{{ value.object.name | macAddr: true }}</div>
  <div class="mac">{{ value.object.mac | macAddr }}</div>
</ng-template>

<ng-template #tooltipDevicesTemplate let-group>
  <ng-container *ngIf="group.assignedDevices.length">
    <div class="item" *ngFor="let device of group.assignedDevices">
      {{ device.name | macAddr: true }}
    </div>
  </ng-container>
  <ng-container *ngIf="!group.assignedDevices.length">
    <div class="item">{{ 'devicegroup.noDevicesAssigned' | translate }}</div>
  </ng-container>
</ng-template>

<ng-template #tooltipSharesTemplate let-group>
  <ng-container *ngIf="group.groupShares.length">
    <div class="item" *ngFor="let share of group.groupShares">{{ getGroupName(share) }}</div>
  </ng-container>
  <ng-container *ngIf="!group.groupShares.length">
    <div class="item">{{ 'devicegroup.noGroupShares' | translate }}</div>
  </ng-container>
</ng-template>
