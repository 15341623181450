import { createAction, props } from '@ngrx/store';
import { ILocationIpv6 } from 'src/app/lib/interfaces/interface';

export const IPv6ModeSet = createAction(
  '[Configuration][IPv6] IPv6 mode set',
  props<{ mode: 'auto' | 'enable' | 'disable' }>()
);
export const IPv6ModeSetFinished = createAction(
  '[Configuration][IPv6] IPv6 mode set finished',
  props<{ ipv6: ILocationIpv6 }>()
);
export const IPv6ModeSetError = createAction('[Configuration][IPv6] IPv6 mode set error', props<{ error: any }>());

export const IPv6AddressingConfigSet = createAction(
  '[Configuration][IPv6] IPv6 addressing config set',
  props<{ addressingConfig: 'auto' | 'slaac' | 'statelessDhcpv6' | 'statefulDhcpv6' | 'all' }>()
);
export const IPv6AddressingConfigSetFinished = createAction(
  '[Configuration][IPv6] IPv6 addressing config set finished',
  props<{ ipv6: ILocationIpv6 }>()
);
export const IPv6AddressingConfigSetError = createAction(
  '[Configuration][IPv6] IPv6 addressing config set error',
  props<{ error: any }>()
);

export const IPv6PartialStateSet = createAction(
  '[Configuration][IPv6] IPv6 partial state set',
  props<{ partialState: Partial<ILocationIpv6> }>()
);
export const IPv6PartialStateSetFinished = createAction(
  '[Configuration][IPv6] IPv6 partial state set finished',
  props<{ ipv6: ILocationIpv6 }>()
);
export const IPv6PartialStateSetError = createAction(
  '[Configuration][IPv6] IPv6 partial state set error',
  props<{ error: any }>()
);
