<div class="toast-container top-right">
  <div class="toast" [ngClass]="[toast.type, toast.state]" (click)="dismiss(toast)" *ngFor="let toast of toasts">
    <icon name="icon-check" class="size-2" *ngIf="toast.type == 'success'"></icon>
    <icon name="icon-info" class="size-2" *ngIf="toast.type == 'warning'"></icon>
    <icon name="icon-close" class="size-2" *ngIf="toast.type == 'error'"></icon>
    <div class="data">
      <div class="title" *ngIf="toast.title">
        {{ toast.title | translate: toast.options.params }}
        <div class="timer" *ngIf="toast.options.titleTimer" [countdown]="toast.options.titleTimer"></div>
      </div>
      <div class="message" *ngIf="toast.message">{{ toast.message | translate: toast.options.params }}</div>
    </div>
    <icon name="icon-close" class="size-0_5 close"></icon>
  </div>
</div>
