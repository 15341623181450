<div class="products">
  <div>
    <span *ngFor="let product of products$ | async" [ngClass]="product.type">
      {{ product.name }}
    </span>
  </div>
  <button *ngIf="getUpriseUrl()" (click)="openUprise()">
    <icon name="icon-external"></icon>
  </button>
</div>
