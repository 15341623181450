import { Component, ElementRef, Input, OnChanges, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { ApiService } from 'src/app/lib/services/api.service';
import { QoethreadService } from 'src/app/lib/webworker/qoethread.service';
import { IconService } from 'src/app/lib/services/icon.service';
import { QoeService } from 'src/app/lib/services/qoe.service';
import { Point } from 'src/app/lib/d3/models/objects/point';
import { Line } from 'src/app/lib/d3/models/objects/line';
import { Series } from 'src/app/lib/d3/models/objects/series';
import { QoeHelper } from 'src/app/lib/helpers/qoe.helper';
import { selectNodes } from 'src/app/store/polling/polling.selector';
import { switchMap, take } from 'rxjs/operators';
import { IPowerState } from 'src/app/lib/interfaces/interface';
import { selectPowerManagementState } from 'src/app/store/customer/customer.selectors';
import * as moment from 'moment';
import { selectQoe5gReports } from 'src/app/store/qoeCellular/qoeCellular.selector';

@UntilDestroy()
@Component({
  selector: 'qoestrip',
  templateUrl: './qoestrip.component.html',
  styleUrls: ['./qoestrip.component.scss']
})
export class QoestripComponent implements OnInit, OnChanges {
  @Input() data: any;
  @Input() mode: '5g' | 'node' | 'device' = 'node';
  @Input() type: 'node' | 'device' = 'node';

  expanded: boolean = false;
  iconURL: string = '';
  ui: string = '';
  chartModes: any[] = [];
  rssiModeItems: any[] = [];
  rssiMode: string = '';
  hideStrips: string[] = [];

  dataConsumption: any = {
    graph: [],
    series: [],
    maxValue: 100
  };

  nodeCharts: any = {
    rssi: false,
    snr: false,
    weightedQoeScore: false,
    effPhyrateRx: false,
    effPhyrateTx: false,
    weightedPrrRx: false,
    weightedPrrTx: false,
    avgRxAirTime: false,
    avgTxAirTime: false,
    channelUtilization: false,
    busyRatio: false,
    connectivityBasedScore: false,
    lpmRatio: false,
    actualThroughput: false,
    estimatedThroughput: false,
    speedTestLatency: false,
    signalBar: false
  };

  powerPermission: boolean = false;
  powerState: IPowerState = null;
  powerCapable: boolean = false;
  powerMode: string = '';
  constructor(
    private plume: PlumeService,
    private mixpanel: MixpanelService,
    private api: ApiService,
    private thread: QoethreadService,
    private icons: IconService,
    private translate: TranslateService,
    private store: Store,
    private qoe: QoeService,
    public elm: ElementRef<HTMLDivElement>
  ) {}

  ngOnInit(): void {
    this.ui = this.plume.getUI();
    this.setRssiMode('static');
    this.initQoeChart();

    if (this.data.expand) {
      this.toggleExpand();
    }
    this.plume.permissions.pipe(untilDestroyed(this)).subscribe((data: any) => {
      this.powerPermission = data.uiFeatures?.powerManagement || false;
    });

    this.store
      .select(selectPowerManagementState)
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        this.powerCapable = data.capable;
      });
  }

  ngOnChanges(): void {
    this.getIconPath();
  }

  setRssiMode(mode: any): void {
    if (mode) {
      this.rssiMode = mode;
    }

    this.rssiModeItems = [
      {
        value: 'static',
        translation: 'qoe.charts.staticNoiseFloor',
        selected: this.rssiMode === 'static' ? true : false
      },
      {
        value: 'dynamic',
        translation: 'qoe.charts.dynamicNoiseFloor',
        selected: this.rssiMode === 'dynamic' ? true : false
      }
    ];
  }

  getIconPath(): void {
    if (this.data.type === 'pod') {
      this.iconURL = this.data.icon;
    } else if (this.mode === '5g') {
      this.iconURL = this.data.icon;
    } else {
      if (this.data.iconV3) {
        this.iconURL = this.icons.getV3Path(this.data.iconV3, 'small');
      } else {
        this.iconURL = this.icons.getPath(this.data.iconV2);
      }
    }
  }

  initQoeChart(): void {
    const qoe = new QoeHelper();
    qoe.setLocale(moment.locale());

    this.data.charts = qoe.qoeHorizontalCharts(this.data.qoeMetrics);
    this.data.loading = false;

    this.initChartToggler();
  }

  initChartToggler(): void {
    this.chartModes = [
      { value: '24h', translation: '24h', selected: this.data.chartMode === '24h' ? true : false },
      { value: '7d', translation: '7d', selected: this.data.chartMode === '7d' ? true : false }
    ];
  }

  get15minData(): void {
    (this.data.type === 'pod'
      ? this.qoe.nodeMetrics$(this.data.id, 'days', this.data.chartMode === '24h' ? 1 : 7)
      : this.qoe.deviceMetrics$(this.data.id, 'days&limit=' + (this.data.chartMode === '24h' ? 1 : 7))
    ).subscribe((response) => {
      const translations = {
        rssi: this.translate.instant('charts.qoe.rssi'),
        snr: this.translate.instant('charts.qoe.snr'),
        weightedQoeScore: this.translate.instant('charts.qoe.weightedQoeScore'),
        effPhyrateRx: this.translate.instant('charts.qoe.effPhyrateRx'),
        effPhyrateTx: this.translate.instant('charts.qoe.effPhyrateTx'),
        weightedPrrRx: this.translate.instant('charts.qoe.weightedPrrRx'),
        weightedPrrTx: this.translate.instant('charts.qoe.weightedPrrTx'),
        avgRxAirTime: this.translate.instant('charts.qoe.avgRxAirTime'),
        avgTxAirTime: this.translate.instant('charts.qoe.avgTxAirTime'),
        connectivityBasedScore: this.translate.instant('charts.qoe.connectivityBasedScore'),
        channelUtilization: this.translate.instant('charts.qoe.channelUtilization'),
        busyRatio: this.translate.instant('charts.qoe.busyRatio'),
        lpmRatio: this.translate.instant('charts.qoe.lpmRatio')
      };

      this.store
        .select(selectNodes)
        .pipe(
          take(1),
          switchMap((nodes) =>
            this.thread.register(this.data.id, {
              ui: this.ui,
              qoeMetrics: response,
              nodes,
              chartMode: this.data.chartMode,
              translations,
              mode: this.data.mode,
              locale: moment.locale()
            })
          )
        )
        .subscribe((charts: any) => {
          this.data.charts = charts;
          this.data.loading = false;

          if (!this.data.charts) {
            this.expanded = false;
          }

          this.initChartToggler();

          Object.keys(this.nodeCharts).forEach((chart: string, index: number) => {
            setTimeout(() => {
              this.nodeCharts[chart] = true;
            }, index * 500);
          });
        });
    });
  }

  private getStartEndTime(value: '7d' | '24h'): { start: string; end: string } {
    return {
      start:
        value === '7d'
          ? moment().add(-7, 'days').startOf('day').toISOString()
          : moment().add(-23, 'hours').startOf('hours').toISOString(),
      end: value === '7d' ? moment().add(-1, 'days').endOf('day').toISOString() : moment().toISOString()
    };
  }

  get15minQoe5gReports(selectedQoeData: any): void {
    const { start, end } = this.getStartEndTime(this.data.chartMode === '24h' ? '24h' : '7d');
    this.qoe.get5gQoeMetrics$(start, end).subscribe((response: any[]) => {
      const pcidMap: { [nodeId: string]: { [pcid: string]: any } } = {};
      const translations = {
        weightedQoeScore: this.translate.instant('charts.qoe.weightedQoeScore'),
        actualThroughput: this.translate.instant('charts.qoe.actualThroughput'),
        estimatedThroughput: this.translate.instant('charts.qoe.estimatedThroughput'),
        speedTestLatency: this.translate.instant('charts.qoe.speedTestLatency'),
        signalBars: this.translate.instant('qoe.signalBars')
      };
      const targetPcid = selectedQoeData.id;

      response.forEach((report) => {
        const nodeId = report.node_id;
        const pcid = report.pcid;

        if (pcid !== targetPcid) {
          return;
        }

        const timestamp = new Date(report.end_ts).getTime(); // Convert to milliseconds

        if (!pcidMap[nodeId]) {
          pcidMap[nodeId] = {};
        }

        if (!pcidMap[nodeId][pcid]) {
          pcidMap[nodeId][pcid] = {
            weightedQoeScore: [],
            actualThroughput: [],
            estimatedThroughput: [],
            speedTestLatency: [],
            signalBars: []
          };
        }

        pcidMap[nodeId][pcid].weightedQoeScore.push({
          timestamp,
          value: report.qoe_score
        });
        pcidMap[nodeId][pcid].actualThroughput.push({
          timestamp,
          value: report.actual_throughput
        });
        pcidMap[nodeId][pcid].estimatedThroughput.push({
          timestamp,
          value: report.estimated_throughput
        });
        pcidMap[nodeId][pcid].speedTestLatency.push({
          timestamp,
          value: report.speedtest_latency
        });
        pcidMap[nodeId][pcid].signalBars.push({
          timestamp,
          value: report.signal_bars
        });
      });
      // You can now use pcidMap as needed in your component
      this.store
        .select(selectQoe5gReports)
        .pipe(
          take(1),
          switchMap((qoe5gReports) =>
            this.thread.register(this.data.id, {
              ui: this.ui,
              qoeMetrics: pcidMap,
              qoe5gReports,
              chartMode: this.data.chartMode,
              translations,
              mode: this.data.mode,
              locale: moment.locale()
            })
          )
        )
        .subscribe((charts: any) => {
          this.data.charts = charts;
          this.data.loading = false;

          if (!this.data.charts) {
            this.expanded = false;
          }
          this.initChartToggler();

          Object.keys(this.nodeCharts).forEach((chart: string, index: number) => {
            setTimeout(() => {
              this.nodeCharts[chart] = true;
            }, index * 500);
          });
        });
    });
  }

  isShowChart(id: string): boolean {
    return !this.hideStrips.includes(id);
  }

  hideGraph(id: string): void {
    if (this.hideStrips.includes(id)) {
      this.hideStrips = this.hideStrips.filter((sid) => sid !== id);
    } else {
      this.hideStrips.push(id);
    }
  }

  toggleExpand(): void {
    this.expanded = !this.expanded;

    this.mixpanel.storeEvent('QOE_CHANGE_EXPAND', {
      EXPANDED: this.expanded,
      QOE_TYPE: this.mode === '5g' ? '5g' : this.data.type === 'pod' ? 'node' : 'device',
      LIVE_MODE: this.data.liveMode,
      ID: this.data.id
    });
    if (this.expanded && this.mode === '5g') {
      this.get15minQoe5gReports(this.data);
    } else if ((this.expanded && this.data.type === 'pod') || this.data.type === 'device') {
      this.get15minData();
    } else {
      this.nodeCharts = {
        rssi: false,
        snr: false,
        weightedQoeScore: false,
        effPhyrateRx: false,
        effPhyrateTx: false,
        weightedPrrRx: false,
        weightedPrrTx: false,
        avgRxAirTime: false,
        avgTxAirTime: false,
        channelUtilization: false,
        busyRatio: false,
        connectivityBasedScore: false,
        lpmRatio: false,
        actualThroughput: false,
        estimatedThroughput: false,
        speedTestLatency: false,
        signalBars: false
      };
    }

    if (this.expanded && this.data.type === 'device' && this.ui === 'noc') {
      this.getDataConsumptionGraphData();
    }
  }

  toggleChartMode(mode: '24h' | '7d', data2: any): void {
    this.data.chartMode = mode;
    this.mixpanel.storeEvent('QOE_CHANGE_HISTORY_GRANULARITY', {
      HISTORY_MODE: this.data.chartMode,
      QOE_TYPE: this.mode === '5g' ? '5g' : this.data.type === 'pod' ? 'node' : 'device',
      ID: this.data.id
    });

    if (this.data.type === 'pod' || this.data.type === 'device') {
      this.get15minData();
    } else if (this.mode === '5g') {
      this.get15minQoe5gReports(data2);
    }
    if (this.expanded && this.data.type === 'device' && this.ui === 'noc') {
      this.getDataConsumptionGraphData();
    }
  }

  getDataConsumptionGraphData(): void {
    this.dataConsumption.graph = [
      {
        series: {
          color: 'var(--chart-green)',
          text: '',
          translation: ''
        },
        axis: 'left',
        data: []
      }
    ];

    this.dataConsumption.series = this.dataConsumption.graph.map((dataObj: any) => dataObj.series);

    const chartType = this.data.chartMode === '24h' ? 'dailyChart' : 'weeklyChart';

    this.api
      .get(
        '/Customers/' +
          this.plume.customerid +
          '/locations/' +
          this.plume.locationid +
          '/devices/' +
          this.data.id +
          '?include=chartsData&include=bandwidthData',
        'api'
      )
      .subscribe((response: any) => {
        this.dataConsumption.graph = [];

        this.dataConsumptionLine(response[chartType], 'total');
        this.dataConsumptionLine(response[chartType], 'download');
        this.dataConsumptionLine(response[chartType], 'upload');

        this.dataConsumption.series = this.dataConsumption.graph.map((dataObj: any) => dataObj.series);
        this.dataConsumption.maxValue = this.findMaxValue(response[chartType]) / 1000000 || 100;
      });
  }

  dataConsumptionLine(dataArray: any[], key: string): void {
    const points = dataArray.map(
      (obj: any) => new Point(new Date(obj.timestamp).toString(), Math.round(obj[key] / 10000) / 100)
    );

    const line = new Line(
      new Series(
        key === 'download' ? 'rgb(94, 189, 62)' : key === 'upload' ? 'rgb(255, 197, 0)' : 'rgb(98, 105, 255)',
        key,
        'qoe.dataConsumption.' + key
      ),
      'left',
      points
    );

    this.dataConsumption.graph.push(line);
  }

  findMaxValue(array: any[]): number {
    return Math.max.apply(
      Math,
      array.map((o: any) => Math.max(o.download, o.upload, o.total))
    );
  }

  track(index: number, tick: any): any {
    return tick.timestamp;
  }
}
