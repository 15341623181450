import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Node } from 'src/app/lib/d3/models/objects/node';
import { ChannelHelper } from 'src/app/lib/helpers/channel.helper';
import { MacAddrPipe } from 'src/app/lib/pipes/mac-addr.pipe';
import { IconService } from 'src/app/lib/services/icon.service';
import { ModelRefService } from 'src/app/lib/services/modelref.service';
import { PlumeService } from 'src/app/lib/services/plume.service';

@Component({
  selector: '[nodeVisual]',
  templateUrl: './node.component.html',
  styleUrls: ['./node.component.scss']
})
export class NodeVisualComponent implements OnChanges, OnInit {
  labelWidth: number = 0;
  ui: string;
  radios: any[] = [];
  showTimeout: any;
  tooltip: any = {
    show: false,
    mac: null,
    id: null,
    name: null,
    type: null
  };

  @Input()
  nodeVisual: Node;

  @Input()
  showSerial: boolean;

  @Input()
  showRadios: boolean;

  @Input()
  vpn: any;

  @Output()
  tooltipState: any = new EventEmitter();

  @HostListener('mouseenter')
  @HostListener('touchstart')
  showTooltip(): void {
    if (this.nodeVisual?.options?.id?.indexOf('vpn') < 0) {
      this.showTimeout = setTimeout(() => {
        this.tooltip.show = true;

        if (this.nodeVisual.options.metadata.threadNetwork) {
          this.tooltipState.emit({
            show: this.tooltip.show,
            values: [
              { property: 'name', value: this.tooltip.name },
              { property: 'id', value: this.tooltip.id ? this.tooltip.id : this.tooltip.mac }
            ]
          });
        } else {
          if (this.nodeVisual.options.metadata.neighborMode5G) {
            this.tooltipState.emit({
              show: this.tooltip.show,
              values: [
                ...(this.nodeVisual.options.type === 'pod' ? [{ property: 'id', value: this.tooltip.id }] : []),
                { property: 'name', value: this.tooltip.name },
                ...(this.nodeVisual.options.metadata?.connectionType
                  ? [{ property: 'connectionType', value: this.nodeVisual.options.metadata?.connectionType }]
                  : []),
                ...(this.nodeVisual.options.metadata?.operator_name
                  ? [
                      {
                        property: 'operator_name',
                        value: this.nodeVisual.options.metadata?.operator_name
                      }
                    ]
                  : []),
                ...(this.nodeVisual.options.metadata?.RSSI
                  ? [
                      {
                        property: 'RSSI',
                        value: `${this.nodeVisual.options.metadata?.RSSI} ${this.translate.instant('dBm')}`
                      }
                    ]
                  : []),
                ...(this.nodeVisual.options.metadata?.RSRP
                  ? [
                      {
                        property: 'RSRP',
                        value: `${this.nodeVisual.options.metadata?.RSRP} ${this.translate.instant('dBm')}`
                      }
                    ]
                  : []),
                ...(this.nodeVisual.options.metadata?.RSRQ
                  ? [
                      {
                        property: 'RSRQ',
                        value: `${this.nodeVisual.options.metadata?.RSRQ} ${this.translate.instant('dB')}`
                      }
                    ]
                  : []),
                ...(this.nodeVisual.options.metadata?.SINR
                  ? [
                      {
                        property: 'SINR',
                        value: `${this.nodeVisual.options.metadata?.SINR} ${this.translate.instant('dB')}`
                      }
                    ]
                  : []),
                ...(this.nodeVisual.options.metadata?.freq_band
                  ? [{ property: 'freq_band', value: this.nodeVisual.options.metadata?.freq_band }]
                  : []),
                ...(this.nodeVisual.options.metadata?.bandwidth
                  ? [{ property: 'bandwidth', value: this.nodeVisual.options.metadata?.bandwidth }]
                  : []),
                ...(this.nodeVisual.options.metadata?.mnc
                  ? [
                      {
                        property: 'mnc',
                        value: this.nodeVisual.options.metadata?.mnc
                      }
                    ]
                  : []),
                ...(this.nodeVisual.options.metadata?.mcc
                  ? [
                      {
                        property: 'mcc',
                        value: this.nodeVisual.options.metadata?.mcc
                      }
                    ]
                  : []),
                ...(this.nodeVisual.options.metadata?.estimated_throughput
                  ? [
                      {
                        property: 'estimated_throughput',
                        value: `${this.nodeVisual.options.metadata?.estimated_throughput} ${this.translate.instant(
                          'Mbps'
                        )}`
                      }
                    ]
                  : [])
              ]
            });
          } else {
            this.tooltipState.emit({
              show: this.tooltip.show,
              values: [
                ...(this.tooltip.name
                  ? [{ property: 'name', value: this.macAddrPipe.transform(this.tooltip.name, true) }]
                  : []),
                ...(this.tooltip.mac ? [{ property: 'mac', value: this.macAddrPipe.transform(this.tooltip.mac) }] : []),
                ...(this.tooltip.id ? [{ property: 'id', value: this.tooltip.id }] : []),
                ...(this.tooltip.type ? [{ property: 'type', value: this.tooltip.type }] : [])
              ]
            });
          }
        }
      }, 1000);
    }
  }

  @HostListener('mouseleave')
  @HostListener('touchend')
  hideTooltip(): void {
    clearTimeout(this.showTimeout);

    if (this.tooltip.show && this.nodeVisual.options.id.indexOf('vpn') < 0) {
      setTimeout(() => {
        this.tooltip.show = false;

        if (this.nodeVisual.options.metadata.threadNetwork) {
          this.tooltipState.emit({
            show: this.tooltip.show,
            values: [
              { property: 'name', value: this.tooltip.name },
              { property: 'id', value: this.tooltip.id ? this.tooltip.id : this.tooltip.mac }
            ]
          });
        } else {
          if (this.nodeVisual.options.metadata.neighborMode5G) {
            this.tooltipState.emit({
              show: this.tooltip.show,
              values: [
                ...(this.nodeVisual.options.type === 'pod' ? [{ property: 'id', value: this.tooltip.id }] : []),
                { property: 'name', value: this.tooltip.name },
                ...(this.nodeVisual.options.metadata?.connectionType
                  ? [{ property: 'connectionType', value: this.nodeVisual.options.metadata?.connectionType }]
                  : []),
                ...(this.nodeVisual.options.metadata?.operator_name
                  ? [
                      {
                        property: 'operator_name',
                        value: this.nodeVisual.options.metadata?.operator_name
                      }
                    ]
                  : []),
                ...(this.nodeVisual.options.metadata?.RSSI
                  ? [
                      {
                        property: 'RSSI',
                        value: `${this.nodeVisual.options.metadata?.RSSI} ${this.translate.instant('dBm')}`
                      }
                    ]
                  : []),
                ...(this.nodeVisual.options.metadata?.RSRP
                  ? [
                      {
                        property: 'RSRP',
                        value: `${this.nodeVisual.options.metadata?.RSRP} ${this.translate.instant('dBm')}`
                      }
                    ]
                  : []),
                ...(this.nodeVisual.options.metadata?.RSRQ
                  ? [
                      {
                        property: 'RSRQ',
                        value: `${this.nodeVisual.options.metadata?.RSRQ} ${this.translate.instant('dB')}`
                      }
                    ]
                  : []),
                ...(this.nodeVisual.options.metadata?.SINR
                  ? [
                      {
                        property: 'SINR',
                        value: `${this.nodeVisual.options.metadata?.SINR} ${this.translate.instant('dB')}`
                      }
                    ]
                  : []),
                ...(this.nodeVisual.options.metadata?.freq_band
                  ? [{ property: 'freq_band', value: this.nodeVisual.options.metadata?.freq_band }]
                  : []),
                ...(this.nodeVisual.options.metadata?.bandwidth
                  ? [{ property: 'bandwidth', value: this.nodeVisual.options.metadata?.bandwidth }]
                  : []),
                ...(this.nodeVisual.options.metadata?.mnc
                  ? [
                      {
                        property: 'mnc',
                        value: this.nodeVisual.options.metadata?.mnc
                      }
                    ]
                  : []),
                ...(this.nodeVisual.options.metadata?.mcc
                  ? [
                      {
                        property: 'mcc',
                        value: this.nodeVisual.options.metadata?.mcc
                      }
                    ]
                  : []),
                ...(this.nodeVisual.options.metadata?.estimated_throughput
                  ? [
                      {
                        property: 'estimated_throughput',
                        value: `${this.nodeVisual.options.metadata?.estimated_throughput} ${this.translate.instant(
                          'Mbps'
                        )}`
                      }
                    ]
                  : [])
              ]
            });
          } else {
            this.tooltipState.emit({
              show: this.tooltip.show,
              values: [
                ...(this.tooltip.name
                  ? [{ property: 'name', value: this.macAddrPipe.transform(this.tooltip.name, true) }]
                  : []),
                ...(this.tooltip.mac ? [{ property: 'mac', value: this.macAddrPipe.transform(this.tooltip.mac) }] : []),
                ...(this.tooltip.id ? [{ property: 'id', value: this.tooltip.id }] : []),
                ...(this.tooltip.type ? [{ property: 'type', value: this.tooltip.type }] : [])
              ]
            });
          }
        }
      }, 300);
    }
  }

  constructor(
    private plume: PlumeService,
    private modelRef: ModelRefService,
    private icons: IconService,
    private macAddrPipe: MacAddrPipe,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.ui = this.plume.getUI();
  }

  ngOnChanges(): void {
    this.setTooltip();
    this.labelWidth = this.getTextWidth(
      this.showSerial ? this.nodeVisual.options.id : this.nodeVisual.options.label,
      "300 12px 'Open sans'"
    );
  }

  setTooltip(): void {
    this.tooltip = {
      mac: null,
      id: null,
      name: null,
      type: null
    };

    this.tooltip.name = this.nodeVisual.options.label;

    if (this.nodeVisual.options.type === 'device') {
      this.tooltip.mac = this.nodeVisual.options.id;
      this.tooltip.type = this.nodeVisual.options.category;
    } else {
      this.tooltip.id = this.nodeVisual.options.id;
      this.tooltip.type = this.modelRef.get(this.nodeVisual.options.metadata?.model).name;
    }
  }

  getChannelColor(channel: number): any {
    const ui = this.plume.getUI();

    if (ui === 'noc') {
      const helper = new ChannelHelper();
      return { fill: helper.getColor(channel) };
    } else {
      return {};
    }
  }

  status(): string {
    if (this.nodeVisual.options) {
      if (this.nodeVisual.options.health) {
        return this.nodeVisual.options.health.status;
      }
    }
    return '';
  }

  icon(): string {
    if (this.nodeVisual.options.metadata) {
      if (this.nodeVisual.options.type === 'pod') {
        return this.nodeVisual.options.metadata.model
          ? this.modelRef.get(this.nodeVisual.options.metadata.model).icon
          : this.modelRef.get('unknown').icon;
      }

      if (this.nodeVisual.options.type === 'device') {
        if (this.nodeVisual.options.metadata.iconV3) {
          return this.icons.getV3Path(this.nodeVisual.options.metadata.iconV3, 'small');
        } else if (this.nodeVisual.options.metadata.iconV2) {
          return this.icons.getPath(this.nodeVisual.options.metadata.iconV2);
        } else {
          return this.icons.getPath('unknown');
        }
      }
    } else {
      return this.icons.getPath('unknown');
    }
  }

  getTextWidth(text: string, font: any): number {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = font;
    return context.measureText(text).width;
  }

  calculateMloRssiOverlayYPosition(numberOfLinks: number) {
    if (numberOfLinks === 1) return -7;
    if (numberOfLinks === 2) return -17;
    if (numberOfLinks === 3) return -25;
  }

  calculateMloRssiOverlayTextYPosition(i: number, numberOfLinks: number) {
    if (numberOfLinks === 1) return 6;

    if (numberOfLinks === 2) {
      if (i === 0) return 12;
      if (i === 1) return -4;
    }

    if (numberOfLinks === 3) {
      if (i === 0) return -10;
      if (i === 1) return 6;
      if (i === 2) return 21;
    }
  }

  position(vertical: string = 'center', horizontal: string = 'center'): string {
    const radius = 8;
    let offsetx = 0;
    let offsety = 0;
    let x = 0;
    let y = 0;

    switch (horizontal) {
      case 'left':
        offsetx = radius * -2 - radius / 2;
        break;
      case 'right':
        offsetx = radius * 2 + radius / 2;
        break;
      default:
        offsetx = 0;
    }

    switch (vertical) {
      case 'top':
        offsety = radius * -2 - radius / 2;
        break;
      case 'bottom':
        offsety = radius * 2 + radius / 2;
        break;
      default:
        offsety = 0;
    }

    if (this.nodeVisual.options.type === 'pod') {
      x = 16 - radius + offsetx;
      y = 16 - radius + offsety;
    }

    if (this.nodeVisual.options.type === 'device') {
      x = 45 - radius + offsetx;
      y = 45 - radius + offsety;
    }

    return 'translate(' + x + ',' + y + ')';
  }
}
