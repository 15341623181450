<div class="container-fluid">
  <ng-container *ngIf="onboarded == 'uncomplete' && ui != 'noc'">
    <spacer>{{ 'completeOnboarding' | translate }}</spacer>
  </ng-container>
  <ng-container *ngIf="onboarded == 'complete' || ui == 'noc'">
    <spacer>{{ 'speedtests.speedtests' | translate }}</spacer>

    <speedtestcontrols></speedtestcontrols>

    <div *ngIf="!nodes.length">
      <spacer>{{ message | translate }}</spacer>
    </div>
    <div *ngIf="nodes.length">
      <speedtestnode [node]="node" *ngFor="let node of nodes; trackBy: track"></speedtestnode>
    </div>
  </ng-container>
</div>
