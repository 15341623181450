<box>
  <div header class="timelineHeader">
    <div>{{ 'aisecurity.chart.timeline' | translate }}</div>
    <div class="tag" (click)="changeView(null, true)" *ngIf="dayView">
      <icon name="icon-close" class="size-0_5"></icon>
      {{ dayView.text }}
    </div>
    <div class="legend">
      <div
        class="type secureandprotect"
        [class.dimm]="(filterEnabled && filterType != 'secureAndProtect') || events === null"
        (click)="filterEvents('secureAndProtect')"
      >
        <svg><svg:circle cx="6" cy="6" r="5"></svg:circle></svg>
        <div>{{ 'aisecurity.chart.threat' | translate }}</div>
      </div>
      <div
        class="type adblocking"
        [class.dimm]="(filterEnabled && filterType != 'adBlocking') || events === null"
        (click)="filterEvents('adBlocking')"
      >
        <svg><svg:circle cx="6" cy="6" r="5"></svg:circle></svg>
        <div>{{ 'aisecurity.chart.adBlock' | translate }}</div>
      </div>
      <div
        class="type content"
        [class.dimm]="(filterEnabled && filterType != 'content') || events === null"
        (click)="filterEvents('content')"
      >
        <svg><svg:circle cx="6" cy="6" r="5"></svg:circle></svg>
        <div>{{ 'aisecurity.chart.content' | translate }}</div>
      </div>
      <div
        class="type iotprotect"
        [class.dimm]="(filterEnabled && filterType != 'iotProtect') || events === null"
        (click)="filterEvents('iotProtect')"
      >
        <svg><svg:circle cx="6" cy="6" r="5"></svg:circle></svg>
        <div>{{ 'aisecurity.chart.iot' | translate }}</div>
      </div>
    </div>
  </div>
  <div container #canvas>
    <div class="loading" [class.show]="!events || !events.length">
      <div *ngIf="!events" class="text">{{ 'aisecurity.chart.loadingEvents' | translate }}</div>
      <div *ngIf="events && !events.length" class="text">{{ 'aisecurity.chart.noEvents' | translate }}</div>
    </div>
    <svg>
      <defs>
        <linearGradient id="gradient" x1="0" x2="0" y1="0" y2="1">
          <stop class="stop1" offset="0%" />
          <stop class="stop2" offset="100%" />
        </linearGradient>
        <filter id="shadow" x="-10%" y="-1%" width="120%" height="110%">
          <feGaussianBlur in="SourceAlpha" stdDeviation="1" result="blur" />
          <feOffset in="blur" dx="0" dy="1" result="offsetBlur" />
          <feFlood flood-color="#000000" flood-opacity="0.2" result="offsetColor" />
          <feComposite in="offsetColor" in2="offsetBlur" operator="in" result="offsetComposite" />
          <feBlend in="SourceGraphic" in2="offsetComposite" mode="normal" />
        </filter>
      </defs>
      <svg:g [attr.transform]="'translate(' + margins.left + ',' + margins.top + ')'">
        <svg:text
          [attr.x]="axis.x"
          [attr.y]="axis.y - 10"
          [attr.dx]="axis.dx"
          [attr.text-anchor]="axis.anchor"
          *ngFor="let axis of xAxis"
        >
          {{ axis.text | translate }}
        </svg:text>
        <svg:text
          [attr.x]="axis.x"
          [attr.y]="axis.y"
          [attr.dy]="axis.dy"
          [attr.text-anchor]="axis.anchor"
          *ngFor="let axis of yAxis"
        >
          {{ axis.text | translate }}
        </svg:text>
        <svg:rect
          class="border"
          x="0"
          y="-5"
          [attr.width]="chart.calculateWidth() || 0"
          [attr.height]="chart.calculateHeight() + 10 || 0"
        ></svg:rect>
        <svg:g
          *ngFor="let day of data"
          class="day"
          [class.noclick]="dayView"
          (mouseenter)="positionTooltipDay(day, true)"
          (mouseleave)="positionTooltipDay(day, false)"
          (click)="changeView(day)"
        >
          <svg:rect
            class="region"
            [attr.x]="day.x"
            [attr.y]="day.y - 5"
            [attr.width]="day.width"
            [attr.height]="day.height + 10"
          ></svg:rect>
          <svg:line
            class="grid"
            [attr.x1]="day.center"
            [attr.y1]="day.y - 5"
            [attr.x2]="day.center"
            [attr.y2]="day.height + 5"
          ></svg:line>
          <svg:circle
            *ngFor="let event of day.events"
            class="event"
            [ngClass]="event.type"
            [class.bigger]="event.hover"
            [attr.cx]="day.center"
            [attr.cy]="event.y"
            [attr.r]="event.hover ? 10 : 5"
            (mouseenter)="positionTooltipEvent(day, event, true)"
            (mouseleave)="positionTooltipEvent(day, event, false)"
            (click)="positionTooltipEvent(day, event, false)"
          />
        </svg:g>
      </svg:g>
    </svg>
    <div id="tooltip-day" class="tooltip-day" [class.show]="tooltipDay?.show" [ngStyle]="tooltipDay?.style">
      {{ tooltipDay?.text }}
    </div>
    <div id="tooltip-event" class="tooltip-event" [class.show]="tooltipEvent?.show" [ngStyle]="tooltipEvent?.style">
      <div>{{ tooltipEvent?.data?.time }}</div>
      <div class="table">
        <ng-container *ngIf="tooltipEvent?.data?.eventType">
          <div>{{ 'aisecurity.chart.type' | translate }}:</div>
          <div>{{ 'aisecurity.profiles.' + tooltipEvent?.data?.eventType | translate }}</div>
        </ng-container>
        <ng-container *ngIf="tooltipEvent?.data?.riskScore">
          <div>{{ 'aisecurity.chart.risk' | translate }}:</div>
          <div>{{ tooltipEvent?.data?.riskScore }}</div>
        </ng-container>
        <ng-container *ngIf="tooltipEvent?.data?.source">
          <div>{{ 'aisecurity.chart.source' | translate }}:</div>
          <div>{{ tooltipEvent?.data?.source }}</div>
        </ng-container>
        <ng-container *ngIf="tooltipEvent?.data?.device?.name">
          <div>{{ 'aisecurity.chart.device' | translate }}:</div>
          <div>{{ tooltipEvent?.data?.device?.name }}</div>
        </ng-container>
      </div>
    </div>
  </div>
</box>
