import * as moment from 'moment';
import { Bar } from 'src/app/lib/d3/models/objects/bar';

export function generateBars(
  speedArray: { timestamp: string; value: number }[],
  barType: string = 'download',
  range: {
    start: string;
    end: string;
    mode: '7d' | '24h';
  }
): { bars: Bar[]; stats: { recent: number; max: number; min: number } } {
  const barMap = {};
  const bars = [];
  let stats = { recent: 0, max: 0, min: 0 };

  if (speedArray.length) {
    stats = {
      recent: speedArray[0].value,
      max: speedArray[0].value,
      min: speedArray[0].value
    };
    const buckets = [];

    const bucketSize = range.mode === '24h' ? 1 : 3;
    const first = moment(range.start).startOf('hour');
    const last = moment(range.end).endOf('hour');
    const bucket = bucketSize * 60 * 60 * 1000;
    const diff = last.valueOf() - first.valueOf();

    for (let i = 0; i < diff / bucket; i++) {
      buckets.push({
        start: first.clone().add(bucketSize * i, 'hours'),
        end: first.clone().add(bucketSize * (i + 1), 'hours')
      });
    }

    speedArray.forEach((test: any) => {
      test.options = {
        timestamp: {
          mode: range.mode,
          time: moment(test.timestamp).format('LT'),
          date: moment(test.timestamp).format('L')
        },
        value: test.value,
        type: barType,
        serverId: test.serverId,
        serverName: test.serverName,
        testType: test.testType
      };

      test.timestamp = moment(test.timestamp).startOf('hour').valueOf();

      for (const bucket of buckets) {
        if (moment(test.timestamp).startOf('hour').isBetween(bucket.start, bucket.end, null, '[)')) {
          barMap[bucket.start.valueOf()] = {
            value: barMap[bucket.start.valueOf()] ? barMap[bucket.start.valueOf()].value + test.value : test.value,
            count: barMap[bucket.start.valueOf()] ? barMap[bucket.start.valueOf()].count + 1 : 1,
            options: barMap[bucket.start.valueOf()]
              ? [...barMap[bucket.start.valueOf()].options, test.options]
              : [test.options]
          };

          break;
        }
      }

      if (stats.max < test.value) {
        stats.max = test.value;
      }
      if (stats.min > test.value) {
        stats.min = test.value;
      }
    });

    for (const key in barMap) {
      if (barMap.hasOwnProperty(key)) {
        const newBar = new Bar(
          Number(key),
          barMap[key].value / barMap[key].count,
          barMap[key].count,
          barMap[key].options
        );
        bars.push(newBar);
      }
    }
  }
  return {
    bars,
    stats
  };
}
