<modal mode="compact" (overlayAction)="closeModal()">
  <ng-container title>{{ 'devices.device.deviceDetails' | translate }}</ng-container>
  <ng-container content>
    <div class="actions">
      <button class="action small" (click)="restartSniffing()">
        {{ 'devices.device.restartSniffing' | translate }}
      </button>
      <button class="action small" (click)="restartClassify()">
        {{ 'devices.device.restartClassify' | translate }}
      </button>
    </div>
    <div class="list" *ngFor="let deviceDetail of device.kind.type | keyvalue">
      <div class="key">{{ '' + deviceDetail.key | translate }}</div>
      <div class="value">{{ deviceDetail.value }}</div>
    </div>
    <div class="list deviceDetails">
      <div class="key">{{ 'devices.device.rawDeviceTyping' | translate }}</div>
      <div class="value">
        <div class="data-wrap">
          <div class="selector" [class.collapsed]="!deviceTypeDataExpand" (click)="toggleDataExpand()">
            {{ 'devices.device.object' | translate }}
            <icon name="icon-arrow-down" class="size-0_6" [class.rotate180]="deviceTypeDataExpand"></icon>
          </div>
          <div class="raw" *ngIf="deviceTypeDataExpand">
            <ngx-json-viewer [expanded]="false" [json]="deviceTypeData"></ngx-json-viewer>
          </div>
        </div>
      </div>
    </div>

    <div class="list deviceDetails" *ngIf="device.clientReportedDeviceType">
      <div class="key">{{ 'devices.device.clientDeviceTyping' | translate }}</div>
      <div class="value">
        <div class="data-wrap">
          <div
            class="selector"
            [class.collapsed]="!clientReportedDeviceTypeDataExpand"
            (click)="toggleClientReportedDataExpand()"
          >
            {{ 'devices.device.object' | translate }}
            <icon name="icon-arrow-down" class="size-0_6" [class.rotate180]="clientReportedDeviceTypeDataExpand"></icon>
          </div>
          <div class="raw" *ngIf="clientReportedDeviceTypeDataExpand">
            <ngx-json-viewer [expanded]="false" [json]="device.clientReportedDeviceType"></ngx-json-viewer>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container controls>
    <button class="tertiary light" (click)="closeModal()">
      {{ 'health.networkInformation.modal.close' | translate }}
    </button>
  </ng-container>
</modal>
