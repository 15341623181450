<div cdkOverlayOrigin #trigger="cdkOverlayOrigin" (click)="!disabled && headerClickToggleOpen && toggleOpen()">
  <ng-container *ngTemplateOutlet="headerTemplate || defaultHeaderTemplate; context: { $implicit: openedState }">
  </ng-container>
</div>
<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="openedState"
  [cdkConnectedOverlayScrollStrategy]="scrollStrategy"
  (detach)="overlayDetached()"
>
  <div class="dropdown listContainer">
    <ul
      #list
      *ngIf="openedState"
      class="dropdown list {{ classes }}"
      (click)="listClicked($event)"
      [style.width.px]="
        listMaxWidth
          ? null
          : listWidth === 'asHeader'
          ? trigger.elementRef.nativeElement.getBoundingClientRect().width
          : listWidth
      "
      [style.minWidth.px]="
        listMinWidth === 'asHeader' ? trigger.elementRef.nativeElement.getBoundingClientRect().width : listMinWidth
      "
      [style.maxWidth.px]="listMaxWidth"
    >
      <ng-content select="[dropdown]"></ng-content>
    </ul>
  </div>
</ng-template>

<ng-template #defaultHeaderTemplate let-opened>
  <button class="header {{ forceState }}" role="listbox" [class.disabled]="disabled">
    <div class="content"><ng-content></ng-content></div>
    <icon name="icon-arrow-down" [class.rotate180]="opened" class="size-0_8"></icon>
  </button>
</ng-template>
