import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class ToolbarService {
  event: Subject<any> = new Subject<any>();

  dock(): void {
    this.event.next({ dock: true });
  }

  resetModals(): void {
    Object.keys(sessionStorage).forEach((key: string) => {
      if (key.indexOf('modal') >= 0) {
        sessionStorage.removeItem(key);
      }
    });
  }
}
