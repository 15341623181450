<modal mode="compact" (overlayAction)="closeModal()">
  <ng-container title>{{ 'nodes.node.hardwareInfo' | translate }}</ng-container>
  <ng-container content>
    <div class="hardwareDetails">
      <div class="list" *ngFor="let attribute of hardwareInfo">
        <div class="key" *ngIf="attribute.value">{{ attribute.key | translate }}</div>
        <div class="value" *ngIf="attribute.value">{{ attribute.value }}</div>
      </div>
    </div>
    <div class="capabilities" *ngIf="capabilities?.length">
      <div class="capabilitiesTitle">{{ 'nodes.node.capabilities' | translate }}</div>
      <div class="list" *ngFor="let attribute of capabilities">
        <div class="key" *ngIf="attribute.value !== undefined">{{ attribute.key | translate }}</div>
        <div class="capability" *ngIf="attribute.value !== undefined">
          <ng-container *ngIf="attribute.value">
            <div class="circle green"></div>
            <div>{{ 'configurations.capabilities.capable' | translate }}</div>
          </ng-container>
          <ng-container *ngIf="!attribute.value">
            <div class="circle red"></div>
            <div>{{ 'configurations.capabilities.notCapable' | translate }}</div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="noMacWarning" *ngIf="!node?.mac?.length">
      {{ 'nodes.node.noMacWarning' | translate }}
    </div>
  </ng-container>
  <ng-container controls>
    <button class="tertiary light" (click)="closeModal()">
      {{ 'health.networkInformation.modal.close' | translate }}
    </button>
  </ng-container>
</modal>
