export const server = {
  lang: {
    production: 'https://s3-us-west-2.amazonaws.com/plume-central/translations/plume-central/production/',
    staging: 'https://s3-us-west-2.amazonaws.com/plume-central/translations/plume-central/staging/'
  },
  endpoint: {
    auth: '/auth',
    awlan: '/awlan',
    api: '/api',
    notifications: '/notifications',
    reports: '/reports',
    provisioning: '/provisioning',
    explorer: '/explorer',
    netops: '/netops',
    cloudMatrix: ''
  }
};
