<svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
  <g flm-graph-axis-x></g>
  <g flm-graph-axis-y></g>
  <g flm-graph-bar-container (barHover)="barHover($event)"></g>
  <g flm-graph-interval-bar-container (barHover)="barHover($event)"></g>
  <g flm-graph-segmented-interval-bar-container (barHover)="barHover($event)"></g>
  <g flm-graph-line-container (pointHover)="pointHover($event)"></g>
  <g flm-graph-stacked-bar-container></g>
</svg>
<flm-graph-tool-tip *ngIf="toolTipDefinition?.html" [posX]="toolTipDefinition.posX" [posY]="toolTipDefinition.posY"
  ><div [innerHtml]="toolTipDefinition.html"></div
></flm-graph-tool-tip>
<flm-graph-tool-tip-bar
  [contrast]="contrast"
  *ngIf="toolTipBarDefinition?.html"
  [posX]="toolTipBarDefinition.posX"
  [posY]="toolTipBarDefinition.posY"
  ><div [innerHtml]="toolTipBarDefinition.html"></div
></flm-graph-tool-tip-bar>
