<svg:g [attr.transform]="tickVisual.transform">
  <svg:text
    #text
    [attr.x]="tickVisual.text.x"
    [attr.y]="tickVisual.text.y"
    [attr.dx]="tickVisual.text.dx"
    [attr.dy]="tickVisual.text.dy"
    [attr.transform]="tickVisual.text.transform"
  >
    {{ tickVisual.value | translate }}
  </svg:text>
  <svg:line
    *ngIf="tickVisual.showgrid"
    [attr.x1]="tickVisual.grid.x1"
    [attr.y1]="tickVisual.grid.y1"
    [attr.x2]="tickVisual.grid.x2"
    [attr.y2]="tickVisual.grid.y2"
  ></svg:line>
</svg:g>
