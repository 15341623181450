import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { IDevice } from 'src/app/lib/interfaces/interface';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { ToastService } from 'src/app/lib/services/toast.service';
import { TroubleshootingService } from 'src/app/lib/services/troubleshooting.service';
import { selectNodes } from 'src/app/store/polling/polling.selector';

@Component({
  selector: 'steer-modal',
  templateUrl: './steer-modal.component.html',
  styleUrls: ['./steer-modal.component.scss']
})
export class SteerModalComponent implements OnInit {
  @Input()
  device: IDevice = null;

  steer = {
    targetOptions: [],
    showDeviceTypes: false,
    showNodes: false,
    showBands: false,
    nodes$: null,
    bands: ['2.4G', '5GL', '5GU', '5G', '6G'],
    deviceTypes: [
      'reset',
      'Windows',
      'cisco/linksysrouter',
      'default',
      'default802dot11kv',
      'macbook',
      'roku',
      'sonos',
      'windows',
      'xi5',
      'xi6'
    ],
    current: {
      deviceType: null,
      target: false,
      node: {
        defaultName: null,
        nickname: null,
        id: null
      },
      band: null
    }
  };

  @Output()
  closeSteerModal = new EventEmitter<void>();

  constructor(
    private store: Store,
    private troubleshoot: TroubleshootingService,
    private toast: ToastService,
    private mixpanel: MixpanelService
  ) {}

  ngOnInit(): void {
    this.setSteerTarget(false);

    this.steer.current.deviceType = this.device?.clientSteering?.steeringClass
      ? this.device.clientSteering.steeringClass
      : this.steer.deviceTypes[0];

    this.steer.nodes$ = this.store
      .select(selectNodes)
      .pipe(map((nodes) => nodes.filter((node: any) => node.connectionState === 'connected')));
  }

  selectDeviceType(deviceType: string) {
    this.steer.current.deviceType = deviceType;

    this.troubleshoot.steerClientDevice(this.device.mac, deviceType).subscribe(() => {
      this.toast.success('toast.deviceType.message', 'toast.deviceType.title');
      this.mixpanel.storeEvent('DEVICES_STEER_DEVICE_TYPE_CONFIRM', {
        DEVICE_MAC: this.device.mac,
        PARENT_NODE: this.device.leafToRoot && this.device.leafToRoot[0].id,
        DEVICE_FREQ_BAND: this.device.freqBand,
        STEERING_CLASS: deviceType
      });
    });
  }

  selectSteerOption(type: 'node' | 'band', value: any) {
    this.steer.current[type] = value;
  }

  setSteerTarget(value: boolean) {
    this.steer.targetOptions = [
      { value: true, translation: 'devices.device.steer.targetYes', selected: value },
      { value: false, translation: 'devices.device.steer.targetNo', selected: !value }
    ];
    this.steer.current.target = value;
  }

  steerDevice() {
    if (this.steer.current.target) {
      this.troubleshoot
        .steerDevice(
          this.device.mac,
          this.device.leafToRoot[0].id,
          this.device.freqBand,
          this.steer.current.node.id,
          this.steer.current.band
        )
        .subscribe(() => {
          this.toast.success('toast.steer.message', 'toast.steer.title');
          this.mixpanel.storeEvent('DEVICES_STEER_CONFIRM', {
            DEVICE_MAC: this.device.mac,
            PARENT_NODE: this.device.leafToRoot[0].id,
            DEVICE_FREQ_BAND: this.device.freqBand,
            NEW_PARENT_NODE: this.steer.current.node.id,
            NEW_DEVICE_FREQ_BAND: this.steer.current.band
          });
        });
    } else {
      this.troubleshoot
        .resetDevice(this.device.mac, this.device.leafToRoot[0].id, this.device.freqBand)
        .subscribe(() => {
          this.toast.success('toast.steer.message', 'toast.steer.title');
          this.mixpanel.storeEvent('DEVICES_STEER_CONFIRM', {
            DEVICE_MAC: this.device.mac,
            PARENT_NODE: this.device.leafToRoot[0].id,
            DEVICE_FREQ_BAND: this.device.freqBand
          });
        });
    }
    this.closeModal();
  }

  isSteerDisabled(): boolean {
    if (this.steer.current.target) {
      return !this.steer.current.node?.id || !this.steer.current.band;
    } else {
      return false;
    }
  }

  closeModal() {
    this.closeSteerModal.emit();
  }
}
