import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CustomerService } from 'src/app/lib/services/customer.service';
import { ToastService } from 'src/app/lib/services/toast.service';
import {
  configOptimizationsHopPenaltyModeSet,
  configOptimizationsHopPenaltyModeSetError,
  configOptimizationsHopPenaltyModeSetFinished,
  configOptimizationsMax24GhzChannelWidthSet,
  configOptimizationsMax24GhzChannelWidthSetError,
  configOptimizationsMax24GhzChannelWidthSetFinished,
  configOptimizationsMax5GhzChannelWidthSet,
  configOptimizationsMax5GhzChannelWidthSetError,
  configOptimizationsMax5GhzChannelWidthSetFinished,
  configOptimizationsMax6GhzChannelWidthSet,
  configOptimizationsMax6GhzChannelWidthSetError,
  configOptimizationsMax6GhzChannelWidthSetFinished,
  configOptimizationsPreCACSchedulerSet,
  configOptimizationsPreCACSchedulerSetError,
  configOptimizationsPreCACSchedulerSetFinished,
  configOptimizationsPrefer160MhzSet,
  configOptimizationsPrefer160MhzSetError,
  configOptimizationsPrefer160MhzSetFinished,
  configOptimizationsUnii4ModeSet,
  configOptimizationsUnii4ModeSetError,
  configOptimizationsUnii4ModeSetFinished,
  configOptimizationsZeroWaitDfsModeSet,
  configOptimizationsZeroWaitDfsModeSetError,
  configOptimizationsZeroWaitDfsModeSetFinished,
  configPaSAppTimeEnableSet,
  configPaSAppTimeEnableSetError,
  configPaSAppTimeEnableSetFinished,
  configPaSDosProtectionActionSet,
  configPaSDosProtectionActionSetError,
  configPaSDosProtectionActionSetFinished,
  configPaSDosProtectionSet,
  configPaSDosProtectionSetError,
  configPaSDosProtectionSetFinished,
  configPaSDpiContentFilteringSet,
  configPaSDpiContentFilteringSetError,
  configPaSDpiContentFilteringSetFinished,
  configPaSFlowStatsSet,
  configPaSFlowStatsSetError,
  configPaSFlowStatsSetFinished,
  configPaSInlineDpiSet,
  configPaSInlineDpiSetError,
  configPaSInlineDpiSetFinished,
  configPaSIpThreatIntelligenceSet,
  configPaSIpThreatIntelligenceSetError,
  configPaSIpThreatIntelligenceSetFinished,
  configPaSIpThreatProtectSet,
  configPaSIpThreatProtectSetError,
  configPaSIpThreatProtectSetFinished,
  configPaSPreferredIntelligenceSet,
  configPaSPreferredIntelligenceSetError,
  configPaSPreferredIntelligenceSetFinished,
  configPaSWcHealthTelemetrySet,
  configPaSWcHealthTelemetrySetError,
  configPaSWcHealthTelemetrySetFinished,
  configPaSWcHeroTelemetrySet,
  configPaSWcHeroTelemetrySetError,
  configPaSWcHeroTelemetrySetFinished
} from './config.actions';
import { Store } from '@ngrx/store';
import { updateDevicesList } from '../customer/customer.actions';

@Injectable()
export class ConfigEffects {
  configOptimizationsZeroWaitDfsModeSet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(configOptimizationsZeroWaitDfsModeSet),
      switchMap(({ value }) =>
        this.customer.setOptimizationsZeroWaitDfsMode$(value).pipe(
          map(() => configOptimizationsZeroWaitDfsModeSetFinished({ value })),
          catchError((error) => {
            this.toast.error(error.error.error.message, error.error.error.name);
            return of(configOptimizationsZeroWaitDfsModeSetError({ error }));
          })
        )
      )
    )
  );

  configOptimizationsPrefer160MhzSet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(configOptimizationsPrefer160MhzSet),
      switchMap(({ value }) =>
        this.customer.setOptimizationsPrefer160MHz$(value).pipe(
          map(() => configOptimizationsPrefer160MhzSetFinished({ value })),
          catchError((error) => {
            this.toast.error(error.error.error.message, error.error.error.name);
            return of(configOptimizationsPrefer160MhzSetError({ error }));
          })
        )
      )
    )
  );

  configOptimizationsMax24GhzChannelWidthSet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(configOptimizationsMax24GhzChannelWidthSet),
      switchMap(({ value }) =>
        this.customer.setOptimizationsMax24GhzChannelWidth$(value).pipe(
          map(() => configOptimizationsMax24GhzChannelWidthSetFinished({ value })),
          catchError((error) => {
            this.toast.error(error.error.error.message, error.error.error.name);
            return of(configOptimizationsMax24GhzChannelWidthSetError({ error }));
          })
        )
      )
    )
  );

  configOptimizationsMax5GhzChannelWidthSet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(configOptimizationsMax5GhzChannelWidthSet),
      switchMap(({ value }) =>
        this.customer.setOptimizationsMax5GhzChannelWidth$(value).pipe(
          map(() => configOptimizationsMax5GhzChannelWidthSetFinished({ value })),
          catchError((error) => {
            this.toast.error(error.error.error.message, error.error.error.name);
            return of(configOptimizationsMax5GhzChannelWidthSetError({ error }));
          })
        )
      )
    )
  );

  configOptimizationsMax6GhzChannelWidthSet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(configOptimizationsMax6GhzChannelWidthSet),
      switchMap(({ value }) =>
        this.customer.setOptimizationsMax6GhzChannelWidth$(value).pipe(
          map(() => configOptimizationsMax6GhzChannelWidthSetFinished({ value })),
          catchError((error) => {
            this.toast.error(error.error.error.message, error.error.error.name);
            return of(configOptimizationsMax6GhzChannelWidthSetError({ error }));
          })
        )
      )
    )
  );

  configOptimizationsUnii4ModeSet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(configOptimizationsUnii4ModeSet),
      switchMap(({ value }) =>
        this.customer.setUnii4Mode$(value).pipe(
          map(() => configOptimizationsUnii4ModeSetFinished({ value })),
          catchError((error) => {
            this.toast.error(error.error.error.message, error.error.error.name);
            return of(configOptimizationsUnii4ModeSetError({ error }));
          })
        )
      )
    )
  );

  configOptimizationsHopPenaltyModeSet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(configOptimizationsHopPenaltyModeSet),
      switchMap(({ value }) =>
        this.customer.setHopPenaltyMode$(value).pipe(
          map(() => configOptimizationsHopPenaltyModeSetFinished({ value })),
          catchError((error) => {
            this.toast.error(error.error.error.message, error.error.error.name);
            return of(configOptimizationsHopPenaltyModeSetError({ error }));
          })
        )
      )
    )
  );

  configOptimizationsPreCACSchedulerSet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(configOptimizationsPreCACSchedulerSet),
      switchMap(({ value }) =>
        this.customer.setOptimizationsPreCACScheduler$(value).pipe(
          map(() => configOptimizationsPreCACSchedulerSetFinished({ value })),
          catchError((error) => {
            this.toast.error(error.error.error.message, error.error.error.name);
            return of(configOptimizationsPreCACSchedulerSetError({ error }));
          })
        )
      )
    )
  );

  configPaSAppTimeEnableSet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(configPaSAppTimeEnableSet),
      switchMap(({ value }) =>
        this.customer.setAppTime$(value).pipe(
          map((value) => {
            this.store.dispatch(updateDevicesList());
            return configPaSAppTimeEnableSetFinished({ value });
          }),
          catchError((error) => {
            this.toast.error(error.error.error.message, 'header.failed');
            return of(configPaSAppTimeEnableSetError({ error }));
          })
        )
      )
    )
  );

  configPaSPreferredIntelligenceSet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(configPaSPreferredIntelligenceSet),
      switchMap(({ value }) =>
        this.customer.setSecurityConfigurationPreferredIntelligence$(value).pipe(
          map((securityConfiguration) => configPaSPreferredIntelligenceSetFinished({ securityConfiguration })),
          catchError((error) => {
            this.toast.error(error.error.error.message, 'header.failed');
            return of(configPaSPreferredIntelligenceSetError({ error }));
          })
        )
      )
    )
  );

  configPaSIpThreatIntelligenceSet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(configPaSIpThreatIntelligenceSet),
      switchMap(({ value }) =>
        this.customer.setSecurityConfigurationIpThreatIntelligence$(value).pipe(
          map((securityConfiguration) => configPaSIpThreatIntelligenceSetFinished({ securityConfiguration })),
          catchError((error) => {
            this.toast.error(error.error.error.message, 'header.failed');
            return of(configPaSIpThreatIntelligenceSetError({ error }));
          })
        )
      )
    )
  );

  configPaSWcHealthTelemetrySet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(configPaSWcHealthTelemetrySet),
      switchMap(({ value }) =>
        this.customer.setSecurityConfigurationWcHealthTelemetry$(value).pipe(
          map((securityConfiguration) => configPaSWcHealthTelemetrySetFinished({ securityConfiguration })),
          catchError((error) => {
            this.toast.error(error.error.error.message, 'header.failed');
            return of(configPaSWcHealthTelemetrySetError({ error }));
          })
        )
      )
    )
  );

  configPaSWcHeroTelemetrySet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(configPaSWcHeroTelemetrySet),
      switchMap(({ value }) =>
        this.customer.setSecurityConfigurationWcHeroTelemetry$(value).pipe(
          map((securityConfiguration) => configPaSWcHeroTelemetrySetFinished({ securityConfiguration })),
          catchError((error) => {
            this.toast.error(error.error.error.message, 'header.failed');
            return of(configPaSWcHeroTelemetrySetError({ error }));
          })
        )
      )
    )
  );

  configPaSIpThreatProtectSet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(configPaSIpThreatProtectSet),
      switchMap(({ value }) =>
        this.customer.setSecurityConfigurationIpThreatProtect$(value).pipe(
          map((securityConfiguration) => configPaSIpThreatProtectSetFinished({ securityConfiguration })),
          catchError((error) => {
            this.toast.error(error.error.error.message, 'header.failed');
            return of(configPaSIpThreatProtectSetError({ error }));
          })
        )
      )
    )
  );

  configPaSInlineDpiSet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(configPaSInlineDpiSet),
      switchMap(({ value }) =>
        this.customer.setSecurityConfigurationInlineDpi$(value).pipe(
          map((securityConfiguration) => configPaSInlineDpiSetFinished({ securityConfiguration })),
          catchError((error) => {
            this.toast.error(error.error.error.message, 'header.failed');
            return of(configPaSInlineDpiSetError({ error }));
          })
        )
      )
    )
  );

  configPaSDosProtectionSet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(configPaSDosProtectionSet),
      switchMap(({ value }) =>
        this.customer.setSecurityConfigurationDosProtection$(value).pipe(
          map((securityConfiguration) => configPaSDosProtectionSetFinished({ securityConfiguration })),
          catchError((error) => {
            this.toast.error(error.error.error.message, 'header.failed');
            return of(configPaSDosProtectionSetError({ error }));
          })
        )
      )
    )
  );

  configPaSDosProtectionActionSet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(configPaSDosProtectionActionSet),
      switchMap(({ value }) =>
        this.customer.setSecurityConfigurationDosProtectionAction$(value).pipe(
          map((securityConfiguration) => configPaSDosProtectionActionSetFinished({ securityConfiguration })),
          catchError((error) => {
            this.toast.error(error.error.error.message, 'header.failed');
            return of(configPaSDosProtectionActionSetError({ error }));
          })
        )
      )
    )
  );

  configPaSDpiContentFilteringSet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(configPaSDpiContentFilteringSet),
      switchMap(({ value }) =>
        this.customer.setSecurityConfigurationDpiContentFiltering$(value).pipe(
          map((securityConfiguration) => configPaSDpiContentFilteringSetFinished({ securityConfiguration })),
          catchError((error) => {
            this.toast.error(error.error.error.message, 'header.failed');
            return of(configPaSDpiContentFilteringSetError({ error }));
          })
        )
      )
    )
  );

  configPaSFlowStatsSet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(configPaSFlowStatsSet),
      switchMap(({ value, msgId }) =>
        this.customer.setFlowStats$(value).pipe(
          map(() => configPaSFlowStatsSetFinished({ msgId })),
          catchError((error) => {
            this.toast.error(error.error.error.message, 'header.failed');
            return of(configPaSFlowStatsSetError({ error, msgId }));
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private toast: ToastService,
    private customer: CustomerService,
    private store: Store
  ) {}
}
