import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { take, tap } from 'rxjs/operators';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { selectCustomerIdentification } from 'src/app/store/customer/customer.selectors';
import { IThriveDevice, IRingUser, IRingWatchers, ThriveModes } from './thrive.interfaces';
import { ringWatchers, ringUser } from './thrive.mock';

@Component({
  templateUrl: './thrive.component.html',
  styleUrls: ['./thrive.component.scss']
})
export class ThriveComponent implements OnInit, OnDestroy {
  ring: IThriveDevice = {
    name: "Veronica's Ring",
    id: 'Enterprise SSIDname',
    firmware: 'V134.34',
    phone: 'iPhone 14 Max Pro',
    version: 'IoS V2.4, updated 11/11/2022',
    status: 'Active',
    battery: 80,
    lastConnectAttempt: '00:00:00',
    lastError: 'Ring connect - ring could not be registered to cloud 11/11/2022, 00:00:00'
  };
  showLogModal: boolean = false;
  showActivityLogModal: boolean = false;
  showActiveWatchersModal: boolean = false;
  showInvitedWatchersModal: boolean = false;
  mode: string;

  ringUser: IRingUser = ringUser;
  ringWatchers: IRingWatchers = ringWatchers;

  constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private mixpanel: MixpanelService
  ) {}

  ngOnInit(): void {
    this.mixpanel.storeEvent('THRIVE_SCREEN');

    this.route.params.subscribe((params: any) => {
      if (params && params.mode) {
        this.mode = params.mode;
      }
    });
  }

  changeTab(mode: ThriveModes): void {
    this.mode = mode;

    this.store
      .select(selectCustomerIdentification)
      .pipe(
        take(1),
        tap((data) => {
          this.router.navigate(['customer', data.customerid, 'location', data.locationid, 'thrive', this.mode], {
            replaceUrl: true
          });
        })
      )
      .subscribe();
  }

  toggleLogModal(): void {
    this.showLogModal = !this.showLogModal;
  }

  toggleActivityLogModal(): void {
    this.showActivityLogModal = !this.showActivityLogModal;
  }

  toggleActiveWatchersModal(): void {
    this.showActiveWatchersModal = !this.showActiveWatchersModal;
  }

  toggleInvitedWatchersModal(): void {
    this.showInvitedWatchersModal = !this.showInvitedWatchersModal;
  }

  ngOnDestroy(): void {}
}
