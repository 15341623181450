<header placeholder="header.searchCustomers"></header>
<div class="container-fluid">
  <icon [path]="logo" class="logo"></icon>
  <div class="pattern" [style.background-image]="'url(' + pattern + ')'"></div>
  <div class="wrapper" [class.open]="openStats" *ngIf="stats">
    <div class="tab desktop" (click)="openStats = !openStats">
      {{ 'landing.statistics' | translate }}
      <icon name="icon-arrow-down" class="size-0_8" [class.rotate180]="openStats"></icon>
    </div>
    <div class="tab mobile" (click)="openStatsDialog = true">
      {{ 'landing.statistics' | translate }}
      <icon name="icon-arrow-down" class="size-0_8"></icon>
    </div>
    <div class="stats" *ngIf="openStats">
      <ng-container *ngTemplateOutlet="chartsContainer"> </ng-container>
    </div>
  </div>
</div>

<modal mode="normal" (overlayAction)="openStatsDialog = false" *ngIf="openStatsDialog">
  <ng-container title>{{ 'landing.statistics' | translate }}</ng-container>
  <ng-container content> <ng-container *ngTemplateOutlet="chartsContainer"> </ng-container> </ng-container>
  <ng-container controls>
    <button class="tertiary light medium" (click)="openStatsDialog = false">
      {{ 'header.versionModal.close' | translate }}
    </button>
  </ng-container>
</modal>

<ng-template #chartsContainer>
  <div class="graph-wrap" *ngIf="charts.deviceHappiness">
    <div class="options-wrap">
      {{ 'landing.deviceHappiness' | translate }}
      <slidetoggler
        [options]="charts.deviceHappinessToggler"
        (toggle)="action('deviceHappiness', $event)"
      ></slidetoggler>
    </div>
    <nodechart
      [series]="[charts.deviceHappiness.total.series, charts.deviceHappiness.alarmed.series]"
      [data]="[charts.deviceHappiness.total, charts.deviceHappiness.alarmed]"
      [scale]="{ symbol: '' }"
      [period]="charts.deviceHappinessPeriod"
      [margins]="chartMargins"
    ></nodechart>
  </div>
  <div class="graph-wrap" *ngIf="charts.optimizationStats">
    <div class="options-wrap">
      {{ 'landing.optimizationStats' | translate }}
      <slidetoggler
        [options]="charts.optimizationStatsToggler"
        (toggle)="action('optimizationStats', $event)"
      ></slidetoggler>
    </div>
    <nodechart
      [series]="[charts.optimizationStats.succeeded.series, charts.optimizationStats.failed.series]"
      [data]="[charts.optimizationStats.succeeded, charts.optimizationStats.failed]"
      [scale]="{ symbol: '' }"
      [period]="charts.optimizationStatsPeriod"
      [margins]="chartMargins"
    ></nodechart>
  </div>
  <div class="graph-wrap" *ngIf="charts.bandSteeringStats">
    <div class="options-wrap">
      {{ 'landing.bandSteeringStats' | translate }}
      <slidetoggler
        [options]="charts.bandSteeringStatsToggler"
        (toggle)="action('bandSteeringStats', $event)"
      ></slidetoggler>
    </div>
    <nodechart
      [series]="[charts.bandSteeringStats.succeeded.series, charts.bandSteeringStats.failed.series]"
      [data]="[charts.bandSteeringStats.succeeded, charts.bandSteeringStats.failed]"
      [scale]="{ symbol: '' }"
      [period]="charts.bandSteeringStatsPeriod"
      [margins]="chartMargins"
    ></nodechart>
  </div>
  <div class="graph-wrap" *ngIf="charts.clientSteeringStats">
    <div class="options-wrap">
      {{ 'landing.clientSteeringStats' | translate }}
      <slidetoggler
        [options]="charts.clientSteeringStatsToggler"
        (toggle)="action('clientSteeringStats', $event)"
      ></slidetoggler>
    </div>
    <nodechart
      [series]="[charts.clientSteeringStats.succeeded.series, charts.clientSteeringStats.failed.series]"
      [data]="[charts.clientSteeringStats.succeeded, charts.clientSteeringStats.failed]"
      [scale]="{ symbol: '' }"
      [period]="charts.clientSteeringStatsPeriod"
      [margins]="chartMargins"
    ></nodechart>
  </div>
  <div class="graph-wrap" *ngIf="charts.alarms">
    <div class="options-wrap">
      {{ 'landing.alarms' | translate }}
      <slidetoggler [options]="charts.alarmsToggler" (toggle)="action('alarms', $event)"></slidetoggler>
    </div>
    <nodechart
      [series]="[charts.alarms.coverage.series]"
      [data]="[charts.alarms.coverage]"
      [scale]="{ symbol: '' }"
      [period]="charts.alarmsPeriod"
      [margins]="chartMargins"
    ></nodechart>
  </div>
</ng-template>
