<div class="container-fluid">
  <div class="row">
    <div class="col-xs-12">
      <collapsiblebox mode="fill" [show]="expanded" [collapsible]="true" toggleSide="left" (toggle)="toggleExpand()">
        <div header>
          <div class="group">
            <div class="title">{{ 'timelines.firmware.title' | translate }}</div>
            <icon name="icon-info" tooltip="{{ 'timelines.firmware.tooltip' | translate }}" [maxWidth]="300"></icon>
          </div>

          <div class="grow" *ngIf="dayView">
            <div class="tag skip-toggle" (click)="changeView(null)">
              <icon name="icon-close" class="size-0_5"></icon>
              {{ dayView.text }}
            </div>
          </div>

          <div class="tooMany" *ngIf="expanded && !dayView && firmwareEvents?.length >= maxEvents">
            <div>{{ 'timelines.optimizer.tooMany' | translate }}</div>
          </div>

          <div class="legend" *ngIf="expanded">
            <div class="item" *ngFor="let item of legend">
              <svg viewBox="0 0 4 4">
                <svg:circle [ngClass]="item.color" cx="50%" cy="50%" r="1"></svg:circle>
              </svg>
              <div class="label">{{ item.text | translate }}</div>
            </div>
          </div>
        </div>

        <div container *ngIf="expanded">
          <timelineschart
            [events]="firmwareEvents"
            [dayView]="dayView"
            [eventsMenu]="true"
            [endDay]="endDay === today ? null : endDay"
            (view)="changeView($event)"
            (event)="selectEvent($event)"
          ></timelineschart>
          <ng-container *ngIf="firmwareEvents !== null">
            <div class="timeButtons" *ngIf="!dayView">
              <div
                class="pill"
                [tooltip]="'configurations.firmware.previousMonth' | translate"
                (click)="previousDate()"
              >
                &lt;
              </div>
              <div
                class="pill"
                [tooltip]="'configurations.firmware.nextMonth' | translate"
                [class.disabled]="today === endDay"
                (click)="nextDate()"
              >
                &gt;
              </div>
            </div>
          </ng-container>
        </div>
      </collapsiblebox>
    </div>
  </div>
  <div class="row" *ngIf="expanded && eventDetails">
    <div class="col-xs-12">
      <div class="bubble">
        <div class="title">
          {{ 'configurations.firmware.eventTitle' | translate }}
        </div>
        <div class="details">
          <div class="table">
            <div class="status">
              <div class="column">
                <div class="row">
                  <div class="key">{{ 'configurations.firmware.target' | translate }}</div>
                  <div class="value">{{ eventDetails.target }}</div>
                </div>
                <div class="row">
                  <div class="key">{{ 'configurations.firmware.createdAt' | translate }}</div>
                  <div class="value">{{ eventDetails.created | date: 'LLL' }}</div>
                </div>
              </div>
              <div class="column">
                <div class="row">
                  <div class="key">{{ 'configurations.firmware.status' | translate }}</div>
                  <div class="value">{{ eventDetails.status | translate }}</div>
                </div>
                <div class="row">
                  <div class="key">{{ 'configurations.firmware.updatedAt' | translate }}</div>
                  <div class="value">{{ eventDetails.updated | date: 'LLL' }}</div>
                </div>
              </div>
            </div>
            <div class="headers" *ngIf="eventDetails.nodes.length">
              <div class="node">{{ 'configurations.firmware.nodeId' | translate }}</div>
              <div class="original">{{ 'configurations.firmware.originalFirmware' | translate }}</div>
              <div class="final">{{ 'configurations.firmware.updatedTo' | translate }}</div>
            </div>
            <div class="data" *ngFor="let node of eventDetails.nodes">
              <div class="node">{{ node.id }}</div>
              <div class="original">
                <div class="firmware">
                  {{
                    node.initFirmware.length ? node.initFirmware : ('configurations.firmware.noFirmwareSet' | translate)
                  }}
                </div>
                <div class="state">{{ node.initState | translate }}</div>
              </div>
              <div class="final">
                <div class="firmware">
                  {{
                    node.finalFirmware.length ? node.finalFirmware : ('configurations.firmware.noChange' | translate)
                  }}
                </div>
                <div class="state">
                  {{ node.finalState | translate }}
                  <icon
                    name="icon-info"
                    [tooltip]="node.errorDescription"
                    *ngIf="node.finalState.toUpperCase() === 'FAILED' || node.finalState.toUpperCase() === 'ERROR'"
                  ></icon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
