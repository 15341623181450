import { Series } from './series';

export class Dot {
  class: string;
  series: Series;
  cx?: number;
  cy?: number;
  r?: number;
  show?: boolean;
  label?: string;

  constructor(series: Series, r?: number, cx?: number, cy?: number, show?: boolean, label?: string) {
    this.class = 'dot ' + series.text;
    this.series = series;
    this.r = r;
    this.cx = cx;
    this.cy = cy;
    this.show = show;
    this.label = label;
  }
}
