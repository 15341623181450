import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'component-clearer',
  templateUrl: './component-clearer.component.html',
  styleUrls: ['./component-clearer.component.scss']
})
export class ComponentClearerComponent implements OnInit {
  constructor() {
  }

  ngOnInit(): void {}
}
