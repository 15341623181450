import { createAction, props } from '@ngrx/store';
import { ICustomer, ILocationAuthorizations, ILocationSteering } from 'src/app/lib/interfaces/interface';

export const partnerIdSet = createAction('[Customer Info - Groups] Partner ID set', props<{ partnerId: string }>());
export const partnerIdSaved = createAction(
  '[Customer Info - Groups] Partner ID saved',
  props<{ customer: ICustomer }>()
);
export const partnerIdSavedError = createAction(
  '[Customer Info - Groups] Partner ID saved error',
  props<{ error: any }>()
);

export const customerSetFromEmailVerified = createAction(
  '[Customer Info] Customer set from email verified',
  props<{ customer: ICustomer }>()
);
export const customerSetFromRename = createAction(
  '[Customer Info] Customer set from rename',
  props<{ customer: ICustomer }>()
);

export const optimizationsAutoModeEnable = createAction(
  '[Customer Info] optimizations auto mode enable',
  props<{ value: boolean }>()
);
export const optimizationsAutoModeEnableFinished = createAction(
  '[Customer Effects] optimizations auto mode enable finished',
  props<{ value: boolean }>()
);
export const optimizationsAutoModeEnableError = createAction(
  '[Customer Effects] optimizations auto mode enable error',
  props<{ error: any }>()
);

export const optimizationsDfsModeSet = createAction(
  '[Customer Info] optimizations DFS mode set',
  props<{ value: 'auto' | 'enable' | 'disable' | 'demo' | 'HomeNonDFSChannels' | 'usDfs' }>()
);
export const optimizationsDfsModeSetFinished = createAction('[Customer Info] optimizations DFS mode set finished');
export const optimizationsDfsModeSetError = createAction(
  '[Customer Info] optimizations DFS mode set error',
  props<{ error: any }>()
);

export const bandSteeringSet = createAction(
  '[Customer Info] band steering set',
  props<{ value: 'auto' | 'enable' | 'disable' }>()
);
export const bandSteeringSetFinished = createAction(
  '[Customer Info] band steering set finished',
  props<ILocationSteering>()
);
export const bandSteeringSetError = createAction('[Customer Info] band steering set error', props<{ error: any }>());

export const serviceLevelSet = createAction(
  '[Customer Info] service level set',
  props<{ value: 'fullService' | 'basicService' | 'noService' | 'reset' }>()
);
export const serviceLevelSetFinished = createAction(
  '[Customer Info] service level set finished',
  props<{ steering: { auto: boolean; status: string } }>()
);
export const serviceLevelSetError = createAction('[Customer Info] service level set error', props<{ error: any }>());

export const WifiModeEnable = createAction('[Customer Info] wifi mode enable', props<{ value: boolean }>());
export const WifiModeEnableFinished = createAction('[Customer Info] wifi mode finished', props<{ enabled: boolean }>());
export const WifiModeEnableError = createAction('[Customer Info] wifi mode error', props<{ error: any }>());

export const controlModeSet = createAction(
  '[Customer Info] control mode set',
  props<{ value: 'full' | 'monitor' | 'reduced' | 'battery' }>()
);
export const controlModeSetFinished = createAction('[Customer Info] control set finished');
export const controlModeSetError = createAction('[Customer Info] control set error', props<{ error: any }>());

export const networkModeSet = createAction(
  '[Customer Info] network mode set',
  props<{ value: 'router' | 'bridge' | 'auto' }>()
);

export const networkModeSetWan = createAction(
  '[Wan] network mode set',
  props<{ value: 'router' | 'bridge' | 'auto' }>()
);

export const networkModeSetFinished = createAction(
  '[Customer Info] network set finished',
  props<{ value: 'router' | 'bridge' | 'auto' }>()
);
export const networkModeSetError = createAction('[Customer Info] network set error', props<{ error: any }>());

export const serviceIdSet = createAction('[Customer Info] service id set', props<{ value: string }>());
export const serviceIdSetFinished = createAction('[Customer Info] service id finished');
export const serviceIdSetError = createAction('[Customer Info] service id error', props<{ error: any }>());

export const locationNameSet = createAction('[Customer Info] location name set', props<{ value: string }>());
export const locationNameSetFinished = createAction('[Customer Info] location name finished');
export const locationNameSetError = createAction('[Customer Info] location name error', props<{ error: any }>());

export const archiveCurrentLocation = createAction('[Customer Info] archive current location');
export const archiveCurrentLocationFinished = createAction('[Customer Info] archive current location finished');
export const archiveCurrentLocationFinishedWithLocationRedirect = createAction(
  '[Customer Info] archive current location finished, redirect to another location',
  props<{ newLocation: string }>()
);
export const archiveCurrentLocationError = createAction(
  '[Customer Info] archive current location error',
  props<{ error: any }>()
);

export const addNewLocation = createAction(
  '[Customer Info] add new location',
  props<{ name: string; profile: string }>()
);
export const addNewLocationFinished = createAction(
  '[Customer Info] add new location finished',
  props<{ newLocation: string }>()
);
export const addNewLocationError = createAction('[Customer Info] add new location error', props<{ error: any }>());

export const nodesAuthorizationSet = createAction(
  '[Location authorizations] nodes authorization set',
  props<{
    nodes: {
      model: string;
      count: number;
    }[];
  }>()
);
export const nodesAuthorizationSetFinished = createAction(
  '[Location authorizations] nodes authorization finished',
  props<{
    value: ILocationAuthorizations;
  }>()
);
export const nodesAuthorizationSetError = createAction(
  '[Location authorizations] nodes authorization error',
  props<{ error: any }>()
);

export const podsAuthorizationSet = createAction(
  '[Location authorizations] pods authorization set',
  props<{ count: number }>()
);
export const podsAuthorizationSetFinished = createAction(
  '[Location authorizations] pods authorization finished',
  props<{
    value: ILocationAuthorizations;
  }>()
);
export const podsAuthorizationSetError = createAction(
  '[Location authorizations] pods authorization error',
  props<{ error: any }>()
);
