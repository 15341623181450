import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IDevice } from 'src/app/lib/interfaces/interface';

@Component({
  selector: 'drop-down-device',
  templateUrl: './drop-down-device.component.html',
  styleUrls: ['./drop-down-device.component.scss']
})
export class DropDownDeviceComponent {
  @Input() selectedMac: string;
  @Input() disabled = false;
  @Input() set devices(value: IDevice[]) {
    if (!value) {
      this.cachedDevices = {};
      this.selectableDevices = [];
      return;
    }
    this.cachedDevices = value.reduce((acc, val) => ({ ...acc, [val.mac]: val }), {} as { [key: string]: IDevice });
    this.selectableDevices = value.map((device) => ({
      text: device.nickname || device.name || device.mac,
      value: device.mac,
      object: device
    }));
  }

  @Output() selectedMacChange = new EventEmitter<string>();

  selectableDevices: { text: string; value: string; object: IDevice }[] = [];
  cachedDevices: { [key: string]: IDevice } = {};
}
