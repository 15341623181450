import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, delay, filter, map, mapTo, pairwise, switchMap, take, tap, timeout } from 'rxjs/operators';
import {
  customerClose,
  customerChange,
  customerLocationConfigAndStateLoaded,
  samKnowsModeSet,
  samKnowsModeSetFinished,
  samKnowsModeSetError,
  customerLocationConfigAndStateLoadedError,
  sipAlgModeSet,
  sipAlgModeSetFinished,
  sipAlgModeSetError,
  customerLoaded,
  customerLoadedError,
  customerLocationLoaded,
  customerLocationLoadedError,
  customerCapabilitiesLoaded,
  customerCapabilitiesLoadedError,
  nodesChanged,
  customerLocationConfigAndStateReload,
  customerReload,
  locationReload,
  customerLocationListLoaded,
  customerLocationListLoadedError,
  setPrimarySecondaryNetworks,
  getPrimarySecondaryNetworks,
  primarySecondaryNetworksLoaded,
  setPrimarySecondaryNetworksLoaded,
  wifiPasswordUpdateLoaded,
  wifiPasswordUpdateLoadedError,
  setPrimarySecondaryNetworksLoadedError,
  appQoeModeSet,
  appQoeModeSetFinished,
  appQoeModeSetError,
  getAppPrioritization,
  appPrioritizationLoaded,
  appPrioritizationLoadedError,
  setAppPrioritization,
  setAppPrioritizationLoadedError,
  toggleAppPrioritization,
  toggleAppPrioritizationLoadedError,
  deleteAppPrioritizationCustomSettings,
  deleteAppPrioritizationCustomSettingsError,
  appPrioritizationMonitoringLoaded,
  appPrioritizationMonitoringLoadedError,
  getAppPrioritizationMonitoring,
  getSecondaryNetworks,
  secondaryNetworksLoaded,
  updateLogpullHistory,
  logpullHistoryLoaded,
  logpullHistoryLoadedError,
  mobilizeProblemsHistoryLoaded,
  mobilizeProblemsHistoryLoadedError,
  updateMobilizeProblemsHistory,
  appTimeLoaded,
  appTimeLoadedError,
  toggleAppPrioritizationV2,
  setAppPrioritizationV2,
  getAppPrioritizationV2,
  appPrioritizationV2Loaded,
  appPrioritizationV2LoadedError,
  toggleAppPrioritizationV2LoadedError,
  setAppPrioritizationV2LoadedError,
  puncturingModeSetFinished,
  puncturingModeSet,
  puncturingModeSetError
} from '../customer/customer.actions';
import { Store } from '@ngrx/store';
import {
  selectCustomer,
  selectCustomerIdentification,
  selectCustomerStateObject,
  selectPipeLocationOnChange,
  selectLocationConfig,
  selectLocationStateObject,
  selectLocationList
} from '../customer/customer.selectors';
import { customerSelected, locationChangedMobile } from '../header/header.actions';
import { locationChanged } from '../side-menu/side-menu.actions';
import { locationChanged as locationChangedTechnician } from '../technician/technician.actions';
import { Router } from '@angular/router';
import { combineLatest, iif, Observable, of } from 'rxjs';
import { ToastService } from 'src/app/lib/services/toast.service';
import { PollingService } from 'src/app/lib/services/polling.service';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { CustomerService } from 'src/app/lib/services/customer.service';
import { NodeService } from 'src/app/lib/services/nodes.service';
import { lteConfigurationOpened, lteReloadConfigAndState } from '../lte/lte.actions';
import {
  backhaulSetFinished,
  configOptimizationsHopPenaltyModeSetFinished,
  configOptimizationsMax24GhzChannelWidthSetFinished,
  configOptimizationsMax5GhzChannelWidthSetFinished,
  configOptimizationsMax6GhzChannelWidthSetFinished,
  configOptimizationsPreCACSchedulerSetFinished,
  configOptimizationsPrefer160MhzSetFinished,
  configOptimizationsUnii4ModeSetFinished,
  configOptimizationsZeroWaitDfsModeSetFinished,
  configPaSAppTimeEnableSetFinished,
  configPaSDosProtectionActionSetFinished,
  configPaSDosProtectionSetFinished,
  configPaSDpiContentFilteringSetFinished,
  configPaSFlowStatsSetFinished,
  configPaSInlineDpiSetFinished,
  configPaSIpThreatIntelligenceSetFinished,
  configPaSPreferredIntelligenceSetFinished,
  configPaSWcHealthTelemetrySetFinished,
  configPaSWcHeroTelemetrySetFinished,
  configRefreshAppTime,
  configReloadData,
  dppLoaded,
  dppSet,
  dppSetError,
  dppSetFinished,
  profileSet,
  profileSetError,
  profileSetFinished,
  subscriptionServiceLevelChanged,
  utilitiesInit
} from '../configview/config.actions';
import { TypedAction } from '@ngrx/store/src/models';
import { pollingIntervalPull, pollingPull, topologyChanged } from '../polling/polling.actions';
import {
  addNewLocationFinished,
  archiveCurrentLocationFinishedWithLocationRedirect,
  locationNameSetFinished,
  optimizationsAutoModeEnableFinished,
  optimizationsDfsModeSetFinished,
  partnerIdSaved,
  serviceIdSetFinished
} from '../customer-info/customer-info.actions';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { TrafficService } from 'src/app/lib/services/traffic.service';
import { SecondaryNetworksService } from 'src/app/lib/services/secondary-networks.service';
import { GuidedTroubleShootingPubnubService } from 'src/app/lib/services/guidedTroubleshooting.service';

@Injectable()
export class CustomerEffects {
  readonly reloadLocationConfigAndStateDelayActions = [
    samKnowsModeSetFinished,
    appQoeModeSetFinished,
    sipAlgModeSetFinished,
    lteReloadConfigAndState
  ];
  locationChanged$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          locationChangedMobile,
          customerSelected,
          addNewLocationFinished,
          locationChanged,
          locationChangedTechnician,
          archiveCurrentLocationFinishedWithLocationRedirect
        ),
        switchMap((action) =>
          combineLatest([of(action), this.store.select(selectCustomerIdentification).pipe(take(1))])
        ),
        tap(([action, customer]) => {
          this.reloadCustomerChildComponents(
            'newCustomer' in action ? action.newCustomer : customer.customerid,
            'newLocation' in action ? action.newLocation : customer.locationid,
            this.locationUrl(this.router.url),
            !customer.locationid
          );
        })
      ),
    { dispatch: false }
  );

  customerChange$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(customerChange),
        tap((customer) => {
          this.toast.dismissAll();
          this.polling.stop();
          this.guidedTroubleshoot.stop();
          this.plume.customerid = customer.customerid;
          this.plume.locationid = customer.locationid;
          this.mixpanel.setLocation(customer.customerid, customer.locationid);
          this.polling.start();
        })
      ),
    {
      dispatch: false
    }
  );

  loadCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customerChange),
      switchMap(({ customerid }) =>
        this.customer.getCustomer(customerid).pipe(
          tap((customer) => (this.plume.customerid = customer.id)),
          map((customer) => customerLoaded({ customer })),
          catchError((error) => {
            this.router.navigate(['/']).then(() => {
              this.toast.error('toast.customer.customerNotExistMessage', 'toast.customer.customerNotExistTitle', {
                disableTimeOut: true,
                params: {
                  id: customerid
                }
              });
            });
            return of(customerLoadedError({ error }));
          })
        )
      )
    )
  );

  reloadCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customerReload),
      switchMap(() => this.store.select(selectCustomerIdentification).pipe(take(1))),
      switchMap(({ customerid }) =>
        this.customer.getCustomer(customerid).pipe(
          tap((customer) => (this.plume.customerid = customer.id)),
          map((customer) => customerLoaded({ customer })),
          catchError((error) => of(customerLoadedError({ error })))
        )
      )
    )
  );

  loadLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customerChange),
      switchMap(({ customerid, locationid }) =>
        this.customer.getLocation(customerid, locationid).pipe(
          map((location) => customerLocationLoaded({ location })),
          tap((location) => {
            this.plume.disableNodePolling = location.location.groupIds.includes('fakeGroup');
            if (this.plume.disableNodePolling) {
              console.log('disabling Polling of nodes');
            }
          }),
          catchError((error) => {
            const currentUrl = window.location.href;
            return this.store.select(selectCustomer).pipe(
              filter((customer) => !!customer),
              timeout(10000),
              take(1),
              map(() => {
                throw new Error('');
              }), // if customer loaded or after timeout goto '/' on location load error
              catchError(() => {
                if (currentUrl === window.location.href && currentUrl.includes(locationid)) {
                  this.router.navigate(['/']).then(() => {
                    this.toast.error('toast.customer.locationNotExistMessage', 'toast.customer.locationNotExistTitle', {
                      disableTimeOut: true,
                      params: {
                        id: locationid
                      }
                    });
                  });
                }
                return of(customerLocationLoadedError({ error }));
              })
            ) as Observable<
              {
                error: any;
              } & TypedAction<'[Customer] Customer Location loading error'>
            >;
          })
        )
      )
    )
  );

  reloadLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(locationReload),
      switchMap(() => this.store.select(selectCustomerIdentification).pipe(take(1))),
      switchMap(({ customerid, locationid }) =>
        this.customer.getLocation(customerid, locationid).pipe(
          tap((location) => (this.plume.locationid = location.id)),
          map((location) => customerLocationLoaded({ location })),
          catchError((error) => of(customerLocationLoadedError({ error })))
        )
      )
    )
  );

  afterLocationLoad$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(customerChange),
        tap(() => {
          this.store.dispatch(getPrimarySecondaryNetworks());
        })
      ),
    { dispatch: false }
  );

  loadLocationList$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(customerChange),
        switchMap(({ customerid }) =>
          combineLatest([of(customerid), this.store.select(selectLocationList)]).pipe(take(1))
        ),
        switchMap(([customerid, locationList]) =>
          iif(
            () => !!locationList,
            of(true),
            this.customer.getLocationList$(customerid).pipe(
              tap((locationList) => this.store.dispatch(customerLocationListLoaded({ locationList }))),
              catchError((error) => {
                this.store.dispatch(customerLocationListLoadedError({ error }));
                return of(false);
              })
            )
          )
        )
      ),
    { dispatch: false }
  );

  loadLocationListOnChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addNewLocationFinished, archiveCurrentLocationFinishedWithLocationRedirect, locationNameSetFinished),
      switchMap(() => this.store.select(selectCustomerIdentification).pipe(take(1))),
      switchMap(({ customerid }) =>
        this.customer.getLocationList$(customerid).pipe(
          map((locationList) => customerLocationListLoaded({ locationList })),
          catchError((error) => of(customerLocationListLoadedError({ error })))
        )
      )
    )
  );

  loadLocationConfigAndState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customerChange, customerLocationConfigAndStateReload, configOptimizationsUnii4ModeSetFinished),
      switchMap(() =>
        this.customer.getLocationConfigAndState().pipe(
          map((configAndState) => customerLocationConfigAndStateLoaded({ configAndState })),
          catchError((error) => {
            this.showErrorIfLocationExists('customer.locationConfigAndStateLoadError', 'customer.error');
            return of(customerLocationConfigAndStateLoadedError({ error }));
          })
        )
      )
    )
  );

  reloadLocationConfigAndStateIfNeeded$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(nodesChanged, lteConfigurationOpened, ...this.reloadLocationConfigAndStateDelayActions),
        switchMap((action) =>
          iif(
            () =>
              this.reloadLocationConfigAndStateDelayActions
                .map((action) => action.type as string)
                .includes(action.type as string),
            of(action.type).pipe(delay(2000)),
            of(action.type)
          )
        ),
        // do not load config and state if it is already loading or it was loaded in last 200ms
        switchMap((actionType) =>
          combineLatest([of(actionType), this.store.select(selectLocationConfig).pipe(take(1))])
        ),
        tap(([actionType, config]) => {
          if (config.state !== 'loading' && config.data.loadedTime < new Date().valueOf() - 200) {
            this.store.dispatch(customerLocationConfigAndStateReload({ actionType }));
          }
        })
      ),
    { dispatch: false }
  );

  reloadCustomerIfNeeded$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(utilitiesInit),
        // do not load customer if it is already loading or it was loaded in last 200ms
        switchMap((action) =>
          combineLatest([of(action.type), this.store.select(selectCustomerStateObject).pipe(take(1))])
        ),
        tap(([actionType, config]) => {
          if (config.state !== 'loading' && config.data?.loadedTime < new Date().valueOf() - 200) {
            this.store.dispatch(customerReload({ actionType }));
          }
        })
      ),
    { dispatch: false }
  );

  reloadLocationIfNeeded$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          pollingPull,
          pollingIntervalPull,
          partnerIdSaved,
          optimizationsDfsModeSetFinished,
          serviceIdSetFinished,
          locationNameSetFinished,
          backhaulSetFinished,
          subscriptionServiceLevelChanged,
          optimizationsAutoModeEnableFinished,
          configOptimizationsZeroWaitDfsModeSetFinished,
          configOptimizationsPrefer160MhzSetFinished,
          configOptimizationsMax24GhzChannelWidthSetFinished,
          configOptimizationsMax5GhzChannelWidthSetFinished,
          configOptimizationsMax6GhzChannelWidthSetFinished,
          configOptimizationsHopPenaltyModeSetFinished,
          configOptimizationsPreCACSchedulerSetFinished,
          configPaSFlowStatsSetFinished,
          configPaSPreferredIntelligenceSetFinished,
          configPaSIpThreatIntelligenceSetFinished,
          configPaSWcHealthTelemetrySetFinished,
          configPaSWcHeroTelemetrySetFinished,
          configPaSInlineDpiSetFinished,
          configPaSDosProtectionSetFinished,
          configPaSDosProtectionActionSetFinished,
          configPaSDpiContentFilteringSetFinished,
          configPaSFlowStatsSetFinished,
          configReloadData
        ),
        // do not load customer if it is already loading or it was loaded in last 200ms
        switchMap((action) =>
          combineLatest([of(action.type), this.store.select(selectLocationStateObject).pipe(take(1))])
        ),
        tap(([actionType, location]) => {
          if (location.state !== 'loading' && location.data?.loadedTime < new Date().valueOf() - 200) {
            this.store.dispatch(locationReload({ actionType }));
          }
        })
      ),
    { dispatch: false }
  );

  customerClose$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(customerClose),
        tap(() => {
          this.toast.dismissAll();
          this.polling.stop();
          this.guidedTroubleshoot.stop();
          this.plume.clearCustomer();
        })
      ),
    { dispatch: false }
  );

  samKnowsModeSet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(samKnowsModeSet),
      switchMap(({ mode }) =>
        this.customer.setSamKnows(mode).pipe(
          mapTo(samKnowsModeSetFinished()),
          catchError((error) => {
            this.showErrorIfLocationExists(
              'configurations.agentintegrations.samKnowsModeChangeError',
              'customer.error'
            );
            return of(samKnowsModeSetError({ error }));
          })
        )
      )
    )
  );

  appQoeModeSet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appQoeModeSet),
      switchMap(({ mode }) =>
        this.customer.setAppQoe(mode).pipe(
          mapTo(appQoeModeSetFinished()),
          catchError((error) => {
            this.showErrorIfLocationExists('configurations.agentintegrations.appQoeModeChangeError', 'customer.error');
            return of(appQoeModeSetError({ error }));
          })
        )
      )
    )
  );

  sipAlgModeSet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sipAlgModeSet),
      switchMap(({ mode }) =>
        this.customer.setSipAlg(mode).pipe(
          mapTo(sipAlgModeSetFinished()),
          catchError((error) => {
            this.showErrorIfLocationExists('configurations.privacyandsecurity.sipAlgModeChangeError', 'customer.error');
            return of(sipAlgModeSetError({ error }));
          })
        )
      )
    )
  );

  puncturingModeSet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(puncturingModeSet),
      switchMap(({ mode }) =>
        this.customer.setPuncturing$(mode).pipe(
          mapTo(puncturingModeSetFinished()),
          catchError((error) => of(puncturingModeSetError({ error })))
        )
      )
    )
  );

  profileSet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(profileSet),
      switchMap(({ profile }) =>
        this.customer.setProfile$(profile).pipe(
          map(() => profileSetFinished({ profile })),
          catchError((error) => {
            this.toast.error(error.error.error.message, error.error.error.name);
            return of(profileSetError({ error }));
          })
        )
      )
    )
  );

  dppSet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dppSet),
      switchMap(({ dpp }) =>
        this.customer.setDpp$(dpp).pipe(
          map(() => dppSetFinished({ dpp })),
          catchError((error) => of(dppSetError({ error })))
        )
      )
    )
  );

  dppSetFinished$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dppSetFinished),
      delay(1000),
      switchMap(() => this.customer.getDpp$().pipe(map((dppConfiguration) => dppLoaded({ dppConfiguration }))))
    )
  );

  getAppTime$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customerChange, configPaSAppTimeEnableSetFinished, configRefreshAppTime),
      switchMap(() =>
        this.customer.getAppTime$().pipe(
          map((appTime) => appTimeLoaded({ appTime })),
          catchError((error) => of(appTimeLoadedError({ error })))
        )
      )
    )
  );

  getCapabilities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        customerChange,
        nodesChanged,
        setPrimarySecondaryNetworksLoaded,
        wifiPasswordUpdateLoaded,
        wifiPasswordUpdateLoadedError
      ),
      switchMap(() =>
        this.customer.getCapabilities().pipe(
          map((capabilities) => customerCapabilitiesLoaded({ capabilities })),
          catchError((error) => {
            this.showErrorIfLocationExists('customer.customerCapabilitiesLoadedError', 'customer.error');
            return of(customerCapabilitiesLoadedError({ error }));
          })
        )
      )
    )
  );

  getNodeChanges$ = createEffect(() =>
    this.actions$.pipe(
      ofType(topologyChanged),
      pairwise(),
      map(([oldTopologyAction, newTopologyAction]) =>
        this.nodes.compareTopologyNodes(oldTopologyAction.topology, newTopologyAction.topology)
      ),
      filter(
        (changedNodes) =>
          changedNodes.added.length > 0 ||
          changedNodes.removed.length > 0 ||
          changedNodes.connectionStateChanged.length > 0
      ),
      map((changedNodes) => nodesChanged(changedNodes))
    )
  );

  getSecondaryNetworks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSecondaryNetworks),
      switchMap(() =>
        this.secondaryNetworks.getSecondaryNetworks$().pipe(
          map((secondaryNetworks) => secondaryNetworksLoaded({ options: secondaryNetworks })),
          catchError(() => of(secondaryNetworksLoaded({ options: null })))
        )
      )
    )
  );

  getPrimarySecondaryNetworks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPrimarySecondaryNetworks, setPrimarySecondaryNetworksLoaded),
      switchMap(() =>
        this.customer.getPrimarySecondaryNetworks$().pipe(
          map((primarySecondaryNetworks) => primarySecondaryNetworksLoaded({ options: primarySecondaryNetworks })),
          catchError(() => of(primarySecondaryNetworksLoaded({ options: null })))
        )
      )
    )
  );

  setPrimarySecondaryNetworks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setPrimarySecondaryNetworks),
      switchMap(({ options }) =>
        this.customer.setPrimarySecondaryNetworks$(options).pipe(
          map(() => setPrimarySecondaryNetworksLoaded()),
          catchError((error) => {
            this.toast.error(error.error.error.message, 'health.networkInformation.toast.error');
            return of(setPrimarySecondaryNetworksLoadedError());
          })
        )
      )
    )
  );

  getAppPrioritizationMonitoring$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAppPrioritizationMonitoring),
      switchMap(({ monitoring }) =>
        this.traffic.monitoring$(monitoring).pipe(
          map((monitoring) => appPrioritizationMonitoringLoaded({ monitoring })),
          catchError((error) => {
            this.toast.error(error.error?.error?.message, 'health.networkInformation.toast.error');
            return of(appPrioritizationMonitoringLoadedError());
          })
        )
      )
    )
  );

  getAppPrioritization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAppPrioritization),
      switchMap(() =>
        this.traffic.get$().pipe(
          map((appPrioritization) => appPrioritizationLoaded({ appPrioritization })),
          catchError((error) => {
            this.toast.error(error.error?.error?.message, 'health.networkInformation.toast.error');
            return of(appPrioritizationLoadedError());
          })
        )
      )
    )
  );

  setAppPrioritization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setAppPrioritization),
      switchMap(({ appPrioritization }) =>
        this.traffic.set$(appPrioritization).pipe(
          map((appPrioritization) => appPrioritizationLoaded({ appPrioritization })),
          catchError((error) => {
            this.toast.error(error.error.error.message, 'health.networkInformation.toast.error');
            return of(setAppPrioritizationLoadedError());
          })
        )
      )
    )
  );

  deleteAppPrioritizationCustomSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteAppPrioritizationCustomSettings),
      switchMap(({ appPrioritization }) =>
        this.traffic.delete$().pipe(
          map(() => setAppPrioritization({ appPrioritization })),
          catchError((error) => {
            this.toast.error(error.error.error.message, 'health.networkInformation.toast.error');
            return of(deleteAppPrioritizationCustomSettingsError());
          })
        )
      )
    )
  );

  toggleAppPrioritization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(toggleAppPrioritization),
      switchMap(({ enabled, mode }) =>
        this.traffic.toggle$(enabled, mode).pipe(
          map((appPrioritization) => appPrioritizationLoaded({ appPrioritization })),
          catchError((error) => {
            this.toast.error(error.error.error.message, 'health.networkInformation.toast.error');
            return of(toggleAppPrioritizationLoadedError());
          })
        )
      )
    )
  );

  getAppPrioritizationV2$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAppPrioritizationV2),
      switchMap(() =>
        this.traffic.getV2$().pipe(
          map((appPrioritizationV2) => appPrioritizationV2Loaded({ appPrioritizationV2 })),
          catchError((error) => {
            this.toast.error(error.error?.error?.message, 'health.networkInformation.toast.error');
            return of(appPrioritizationV2LoadedError());
          })
        )
      )
    )
  );

  setAppPrioritizationV2$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setAppPrioritizationV2),
      switchMap(({ appPrioritizationV2 }) =>
        this.traffic.setV2$(appPrioritizationV2).pipe(
          map((appPrioritizationV2) => appPrioritizationV2Loaded({ appPrioritizationV2 })),
          catchError((error) => {
            this.toast.error(error.error.error.message, 'health.networkInformation.toast.error');
            return of(setAppPrioritizationV2LoadedError());
          })
        )
      )
    )
  );

  toggleAppPrioritizationV2$ = createEffect(() =>
    this.actions$.pipe(
      ofType(toggleAppPrioritizationV2),
      switchMap(({ mode }) =>
        this.traffic.toggleV2$(mode).pipe(
          map((appPrioritizationV2) => appPrioritizationV2Loaded({ appPrioritizationV2 })),
          catchError((error) => {
            this.toast.error(error.error.error.message, 'health.networkInformation.toast.error');
            return of(toggleAppPrioritizationV2LoadedError());
          })
        )
      )
    )
  );

  updateLogpullHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateLogpullHistory),
      switchMap(() =>
        this.customer.logPullHistory$(['comment', 'createdAt', 'id'], 'createdAt DESC').pipe(
          map((logpullHistory) => logpullHistoryLoaded({ logpullHistory })),
          catchError((error) => of(logpullHistoryLoadedError({ error })))
        )
      )
    )
  );

  updateMobilizeProblemsHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateMobilizeProblemsHistory),
      switchMap(() =>
        this.customer.mobilizeProblemsHistory$().pipe(
          map((mobilizeProblemsHistory) => mobilizeProblemsHistoryLoaded({ mobilizeProblemsHistory })),
          catchError((error) => of(mobilizeProblemsHistoryLoadedError({ error })))
        )
      )
    )
  );

  private showErrorIfLocationExists(errorMsg: string, errorTitle: string): void {
    // if location object is loaded in 10sec then show error msg, otherwise forgot error msg
    const currentUrl = window.location.href;
    this.store
      .pipe(
        selectPipeLocationOnChange,
        filter((location) => !!location),
        filter((location) => currentUrl.includes(`location/${location.id}/`)), // don't show error if customer changed
        timeout(10000),
        take(1)
      )
      .subscribe(() => {
        this.toast.error(errorMsg, errorTitle);
      });
  }

  private locationUrl(fullUrl: string): string {
    return fullUrl.replace(/^\/customer\/[^\/]+\/location\/[^\/]+\//, '');
  }

  private reloadCustomerChildComponents(
    customerId: string,
    locationId: string,
    locationUrl: string,
    skipCleaner: boolean
  ): void {
    if (skipCleaner) {
      this.router.navigateByUrl(['/', 'customer', customerId, 'location', locationId, locationUrl].join('/'), {
        skipLocationChange: false
      });
      return;
    }
    this.router
      .navigate(['customer', customerId, 'location', locationId, 'component-clearer'], {
        skipLocationChange: true
      })
      .then(() => {
        this.router.navigateByUrl(['/', 'customer', customerId, 'location', locationId, locationUrl].join('/'), {
          skipLocationChange: false
        });
      });
  }

  constructor(
    private actions$: Actions,
    private store: Store,
    private router: Router,
    private toast: ToastService,
    private polling: PollingService,
    private guidedTroubleshoot: GuidedTroubleShootingPubnubService,
    private plume: PlumeService,
    private customer: CustomerService,
    private secondaryNetworks: SecondaryNetworksService,
    private nodes: NodeService,
    private mixpanel: MixpanelService,
    private traffic: TrafficService
  ) {}
}
