<header placeholder="header.searchCustomers"></header>
<div class="container-fluid">
  <ng-container *ngIf="plume.isStrictAdminRole()">
    <collapsiblebox
      mode="bare"
      toggleSide="right"
      [showDivider]="false"
      [show]="expand"
      [collapsible]="true"
      (toggle)="expand = !expand"
    >
      <div header>{{ 'header.groupManagement.addGrouptitle' | translate }}</div>
    </collapsiblebox>
    <div class="grid" *ngIf="expand">
      <div class="label">{{ 'header.groupManagement.name' | translate }}:</div>
      <input
        [formControl]="groupName"
        type="text"
        placeholder="{{ 'header.groupManagement.placeholders.name' | translate }}"
        autocomplete="none"
        autoreadonly
      />
      <div class="label">{{ 'header.groupManagement.description' | translate }}:</div>
      <input
        [formControl]="groupDescription"
        type="text"
        placeholder="{{ 'header.groupManagement.placeholders.description' | translate }}"
        autocomplete="none"
        autoreadonly
      />
      <div class="controls">
        <button class="action" (click)="confirmNewGroup()">{{ 'header.groupManagement.addGroup' | translate }}</button>
        <button class="action" (click)="clearAddGroupFields()">
          {{ 'header.groupManagement.cancel' | translate }}
        </button>
      </div>
    </div>
  </ng-container>
  <div class="title">
    {{ 'header.groupManagement.title' | translate }}
    <div class="filter">
      <input
        type="text"
        [formControl]="groupsFilter"
        placeholder="{{ 'header.groupManagement.placeholders.search' | translate }}"
        (ngModelChange)="filter()"
      />
      <icon
        name="icon-close"
        class="size-0_8"
        tooltip="{{ 'header.groupManagement.clear' | translate }}"
        (click)="clearFilter()"
      ></icon>
    </div>
  </div>
  <div class="groupListContainer">
    <div class="headers">
      <div class="name">{{ 'header.groupManagement.name' | translate }}</div>
      <div class="description">{{ 'header.groupManagement.description' | translate }}</div>
      <div class="id">{{ 'header.groupManagement.id' | translate }}</div>
      <div class="go">{{ 'header.groupManagement.recentCustomers' | translate }}</div>
    </div>
    <div class="grouplist" *ngFor="let group of groupList">
      <div class="name">{{ group.name }}</div>
      <div class="description">{{ group.description }}</div>
      <div class="id">{{ group.id }}</div>
      <div class="go">
        <button class="action small" (click)="selectGroup(group)">
          {{ 'header.groupManagement.view' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
