import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  arc as d3Arc,
  pie as d3Pie,
  select as d3Select,
  scaleOrdinal as d3ScaleOrdinal,
  interpolate as d3Interpolate
} from 'd3';

@Component({
  selector: 'pie-chart-with-ring',
  templateUrl: './pie-chart-with-ring.component.html',
  styleUrls: ['./pie-chart-with-ring.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PieChartWithRingComponent implements OnInit, OnChanges {
  @Input() selectedItemIndex = 0;
  @Output() selectedItemIndexChange = new EventEmitter<number>();
  @Output() outerCircleHover = new EventEmitter<{
    value: number;
    color: string;
    title?: string;
    path: SVGPathElement;
  } | null>();
  @Input() data: {
    value: number;
    color: string;
    outer: { value: number; color: string; title?: string }[];
  }[] = [];

  moveOutLength = 4;
  outerPieThickness = 9;

  constructor(private elm: ElementRef) {}

  ngOnInit(): void {
    const svg = d3Select(this.elm.nativeElement).select('svg');
    const width = Number.parseFloat(svg.attr('width'));
    const height = Number.parseFloat(svg.attr('height'));
    svg
      .append('g')
      .attr('class', 'innerPie')
      .attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ')');
    svg
      .append('g')
      .attr('class', 'outerPie')
      .attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ')');
    this.renderInnerPie();
    this.renderOuterPie();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.selectedItemIndex && !changes?.selectedItemIndex?.firstChange) {
      this.renderInnerPie();
      this.renderOuterPie();
    }

    if (changes?.data && !changes?.data?.firstChange) {
      this.renderInnerPie();
      this.renderOuterPie();
    }
  }

  private outerPieRadius(): number {
    const svg = d3Select(this.elm.nativeElement).select('svg');
    const width = Number.parseFloat(svg.attr('width'));
    const height = Number.parseFloat(svg.attr('height'));
    return Math.min(width, height) / 2;
  }

  private innerPieRadius(): number {
    return this.outerPieRadius() * 0.7 - this.moveOutLength;
  }

  private renderInnerPie(): void {
    const radius = this.innerPieRadius();
    const color = d3ScaleOrdinal(this.data?.map((item) => item.color) ?? []);
    const pie = d3Pie();
    const arc = d3Arc().innerRadius(0).outerRadius(radius);
    const svgArcs = d3Select(this.elm.nativeElement)
      .select('svg')
      .select('.innerPie')
      .selectAll('path')
      .data(pie(this.data?.map((item) => item.value) ?? []));
    svgArcs
      .enter()
      .append('path')
      .merge(svgArcs as any)
      .attr('class', 'innerPiePart')
      .attr('fill', (_, i) => color('' + i))
      .transition('itemGrowShrink')
      .duration(250)
      .attr('opacity', (_, i) => (this.selectedItemIndex === i ? '1' : '0.3'))
      .attr('transform', (_, i) => (i === this.selectedItemIndex ? 'scale(1.05)' : 'scale(1.0)'))
      .attrTween('d', function (a): any {
        const i = d3Interpolate((this as any)._current, a);
        (this as any)._current = i(0);
        return (t) => arc(i(t) as any);
      })
      .selection()
      .on('mouseover', (_, i) => {
        if (this.selectedItemIndex !== i) {
          this.selectedItemIndexChange.emit(i);
        }
      });
    svgArcs.exit().remove();
  }

  private renderOuterPie(): void {
    const thisClass = this;
    const outerPieData =
      !this.data || !this.data.length || this.data?.length <= this.selectedItemIndex || this.selectedItemIndex < 0
        ? []
        : this.data[this.selectedItemIndex].outer;
    const radius = this.outerPieRadius();
    const color = d3ScaleOrdinal(outerPieData.map((item) => item.color));
    const pie = d3Pie();
    const arc = d3Arc()
      .innerRadius(radius - this.outerPieThickness)
      .outerRadius(radius);
    const svgArcs = d3Select(this.elm.nativeElement)
      .select('svg')
      .select('.outerPie')
      .selectAll('path')
      .data(pie(outerPieData.map((item) => item.value)));
    svgArcs
      .enter()
      .append('path')
      .merge(svgArcs as any)
      .attr('class', 'outerPiePart')
      .attr('fill', (d, i) => color('' + i))
      .transition('outerItemGrowShrink')
      .duration(250)
      .attrTween('d', function (a): any {
        const i = d3Interpolate((this as any)._current, a);
        (this as any)._current = i(0);
        return (t: any) => arc(i(t) as any);
      })
      .selection()
      .on('mouseover', function (_, i): void {
        d3Select(this).transition('hoverOpacity').duration(100).attr('opacity', '.85');
        thisClass.outerCircleHover.emit({ ...thisClass.data[thisClass.selectedItemIndex].outer[i], path: this });
      })
      .on('mouseout', function (): void {
        d3Select(this).transition('hoverOpacity').duration(100).attr('opacity', '1');
        thisClass.outerCircleHover.emit(null);
      });
    svgArcs.exit().remove();
  }
}
