import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, UrlTree } from '@angular/router';
import { AuthService } from './auth.service';
import { PlumeService } from './plume.service';
import { FirebaseService } from './firebase.service';
import { combineLatest, iif, Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import {
  selectCustomerIdentification,
  selectIsFlexLocation,
  selectPipeLocationOnChange
} from 'src/app/store/customer/customer.selectors';
import { selectCapabilities } from 'src/app/store/customer/capabilities.selector';
import { RouterEffects } from 'src/app/store/router/router.effects';
import { selectNodes } from 'src/app/store/polling/polling.selector';

@Injectable()
export class RoleService implements CanActivate {
  constructor(
    private router: Router,
    private auth: AuthService,
    private plume: PlumeService,
    private store: Store,
    private firebase: FirebaseService
  ) {
    if (this.auth.isAuthenticated()) {
      this.auth.authWarning();
    }
  }

  canActivate(route: ActivatedRouteSnapshot): boolean | Observable<boolean | UrlTree> {
    const permissions = this.plume.getPermissions();
    const routename = route.data.name ? route.data.name : '';
    const requireUser = !!route.data.requireUser;

    if (!permissions) {
      const user = this.plume.getUser();
      const role = this.plume.getRole();
      const env = this.plume.getEnv();
      const subdomain = env && env.subdomain ? env.subdomain : null;

      return this.check(
        this.firebase.calculatePermissions(this.firebase.snapshot.config, role, subdomain, user),
        routename,
        route,
        requireUser
      );
    } else {
      return this.check(permissions, routename, route, requireUser);
    }
  }

  check(
    permissions: any,
    routename: string,
    route: ActivatedRouteSnapshot,
    requireUser: boolean
  ): Observable<true | UrlTree> | false {
    if (this.auth.isAuthenticated()) {
      return this.checkRoutePermissions(permissions, routename, route).pipe(
        switchMap((res) =>
          iif(
            () => requireUser,
            this.store.select(selectCustomerIdentification).pipe(
              map((storeCustomer) => ({
                storeCustomer,
                urlCustomer: RouterEffects.getCustomerFromURL((route.root as any)._routerState.url)
              })),
              filter(
                ({ storeCustomer, urlCustomer }) =>
                  !!storeCustomer.customerid &&
                  !!storeCustomer.locationid &&
                  storeCustomer.customerid === urlCustomer.customerid &&
                  storeCustomer.locationid === urlCustomer.locationid
              ),
              map(() => res)
            ),
            of(res)
          )
        ),
        catchError(() => of(false)),
        map((permissionResult) => permissionResult || this.checkReroutePermissions(permissions, route))
      );
    } else {
      const { idpId, idpValue } = route.queryParams;

      if (idpId && idpValue) {
        this.plume.saveObject('idp', { id: idpId, value: idpValue }, true, true);
      }

      this.plume.saveObject('referral', window.location.pathname + window.location.hash, false);
      this.router.navigate(['/login']);

      return false;
    }
  }

  checkRoutePermissions(permissions: any, routename: string, route: any): Observable<boolean> {
    const ui = this.plume.getUI();
    const isFlexAdmin = this.plume.isFlexRole();
    const isUpriseProperyManager = this.plume.isUpriseProperyManager();

    if (permissions) {
      switch (routename) {
        case 'routeCustomer':
          // component request to navigate to default component and delete itself from navigation history
          const replaceUrl = this.router.getCurrentNavigation()?.extras?.state?.replaceUrl;
          if (ui === 'noc') {
            this.router.navigate(
              ['customer', route.params.customerid, 'location', route.params.locationid, 'topology'],
              { replaceUrl }
            );
          } else {
            this.router.navigate(['customer', route.params.customerid, 'location', route.params.locationid, 'health'], {
              replaceUrl
            });
          }
          return of(true);
        case 'landing':
          if (permissions.siteAccess) {
            return of(true);
          }
          break;
        case 'health':
          if (permissions.siteAccess && (permissions.healthAccess || permissions.centralAccess) && !isFlexAdmin) {
            return of(true);
          }
          break;
        case 'profiler':
          if (
            permissions.siteAccess &&
            permissions.centralAccess &&
            permissions.profilerAccess &&
            ui === 'noc' &&
            !isFlexAdmin
          ) {
            return of(true);
          }
          break;
        case 'nodes':
          if (permissions.siteAccess && permissions.centralAccess) {
            return of(true);
          }
          break;
        case 'devices':
          if (permissions.siteAccess && permissions.centralAccess) {
            return of(true);
          }
          break;
        case 'topology':
          if (permissions.siteAccess && permissions.centralAccess) {
            return of(true);
          }
          break;
        case 'qoe':
          if (permissions.siteAccess && permissions.centralAccess && permissions.qoeAccess) {
            return of(true);
          }
          break;
        case 'thrive':
          if (permissions.siteAccess && permissions.centralAccess && permissions.thriveAccess) {
            return of(true);
          }
          break;
        case 'traffic':
          if (permissions.siteAccess && permissions.centralAccess && permissions.trafficClassAccess && !isFlexAdmin) {
            return combineLatest([
              this.store.pipe(selectPipeLocationOnChange),
              this.store.select(selectCapabilities),
              this.store.select(selectNodes)
            ]).pipe(
              filter(([location, capabilities, nodes]) => !!location && !!capabilities && !!nodes),
              map(
                ([location, capabilities, nodes]) =>
                  location?.profile !== 'property' &&
                  (capabilities?.AppPrioritization?.capable || capabilities?.AppQoE?.capable) &&
                  !!nodes.length
              ),
              take(1)
            );
          }
          break;
        case 'lte':
          if (permissions.siteAccess && permissions.centralAccess && permissions.uiFeatures.lte && !isFlexAdmin) {
            return this.store.select(selectCapabilities).pipe(
              filter((capabilities) => !!capabilities),
              map((capabilities) => capabilities.lte.capable)
            );
          }
          break;
        case 'flex':
          if (permissions.siteAccess && permissions.centralAccess) {
            return this.store
              .pipe(selectPipeLocationOnChange) // wait until location is loaded to get flex flag and partnerId
              .pipe(switchMap(() => this.store.select(selectIsFlexLocation)));
          }
          break;
        case 'speedtests':
          if (permissions.siteAccess && permissions.centralAccess && permissions.speedTestsAccess && !isFlexAdmin) {
            return of(true);
          }
          break;
        case 'timelines':
          if (permissions.siteAccess && permissions.centralAccess && permissions.timelinesAccess && !isFlexAdmin) {
            return of(true);
          }
          break;
        case 'security':
          if (permissions.siteAccess && permissions.centralAccess && permissions.aiSecurityAccess && !isFlexAdmin) {
            return of(true);
          }
          break;
        case 'motion':
          if (permissions.siteAccess && permissions.centralAccess && permissions.plumeMotionAccess && !isFlexAdmin) {
            return of(true);
          }
          break;
        case 'technician':
          if (permissions.siteAccess && permissions.technicianDashboardAccess && !isFlexAdmin) {
            return of(true);
          }
          break;
        case 'groups':
          if (permissions.siteAccess && ui === 'noc' && !isFlexAdmin) {
            return of(true);
          }
          break;
        case 'explorer':
          if (permissions.siteAccess && ui === 'noc' && !isFlexAdmin) {
            return of(true);
          }
          break;
        case 'netops':
          if (permissions.siteAccess && ui === 'noc' && !isFlexAdmin) {
            return of(true);
          }
          break;
        case 'locationRouter':
          if (permissions.siteAccess) {
            return of(true);
          }
          break;
        case 'configuration':
          if (
            permissions.siteAccess &&
            permissions.configurationsAccess &&
            ui === 'noc' &&
            !isFlexAdmin &&
            !isUpriseProperyManager
          ) {
            return of(true);
          }
          break;
        case 'captiveportal':
          return of(true);
      }
    }

    return of(false);
  }

  checkReroutePermissions(permissions: any, route: any): UrlTree {
    const customerid = route.parent.params.customerid ? route.parent.params.customerid : this.plume.customerid;
    const locationid = route.parent.params.locationid ? route.parent.params.locationid : this.plume.locationid;
    const isFlexAdmin = this.plume.isFlexRole();

    if (customerid && locationid && permissions) {
      if (this.plume.getUI() === 'noc' || isFlexAdmin) {
        return this.router.parseUrl(['customer', customerid, 'location', locationid, 'topology'].join('/'));
      } else {
        if (permissions.centralAccess) {
          return this.router.parseUrl(['customer', customerid, 'location', locationid, 'health'].join('/'));
        }

        if (permissions.technicianDashboardAccess) {
          return this.router.parseUrl(['customer', customerid, 'location', locationid, 'technician'].join('/'));
        }
      }
    } else {
      return this.router.parseUrl('/');
    }
  }
}
