import { Pipe, PipeTransform } from '@angular/core';
import { PlumeService } from '../services/plume.service';

@Pipe({
  name: 'macAddr'
})
export class MacAddrPipe implements PipeTransform {
  constructor(private plume: PlumeService) {}

  transform(value?: string, onlyWhenValid = false): string {
    if (onlyWhenValid && !value?.match(/^(([a-fA-F0-9]{2}):){5}([a-fA-F0-9]{2})$/)) {
      return value;
    }
    if (this.plume.hidePersonalDetails() && value) {
      return `**:**${value?.toUpperCase()?.substring(5)}`;
    }
    return value?.toUpperCase();
  }
}
