import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IDevice } from 'src/app/lib/interfaces/interface';
import { DeviceService } from 'src/app/lib/services/device.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { ToastService } from 'src/app/lib/services/toast.service';

@Component({
  selector: 'device-details-modal',
  templateUrl: './device-details-modal.component.html',
  styleUrls: ['./device-details-modal.component.scss']
})
export class DeviceDetailsModalComponent implements OnInit {
  @Input()
  device: IDevice = null;

  @Output()
  closeDeviceDetailsModal = new EventEmitter<void>();

  deviceTypeData: object = null;
  deviceTypeDataExpand: boolean = false;
  clientReportedDeviceTypeDataExpand: boolean = false;

  constructor(private deviceService: DeviceService, private mixpanel: MixpanelService, private toast: ToastService) {}

  ngOnInit() {
    this.deviceService.typeDetail$(this.device.mac).subscribe((deviceTypeResponse) => {
      this.deviceTypeData = deviceTypeResponse;
    });
  }

  restartClassify() {
    this.deviceService.restartClassify$(this.device.mac).subscribe(
      () => {
        this.mixpanel.storeEvent('DEVICES_TYPE_RECLASSIFY', { DEVICE_MAC: this.device.mac });
        this.toast.success('devices.device.successReclassifyMessage', 'devices.device.successReclassifyTitle', {
          params: {
            device: this.device.name
          }
        });
      },
      (error) => {
        this.toast.error('devices.device.errorReclassifyMessage', 'devices.device.errorReclassifyTitle', {
          params: {
            error: error.error.error.message
          }
        });
      }
    );
  }

  restartSniffing() {
    this.deviceService.restartSniffing$(this.device.mac).subscribe(
      () => {
        this.mixpanel.storeEvent('DEVICES_TYPE_RESNIFF', { DEVICE_MAC: this.device.mac });
        this.toast.success('devices.device.successResniffMessage', 'devices.device.successResniffTitle', {
          params: {
            device: this.device.name
          }
        });
      },
      (error) => {
        this.toast.error('devices.device.errorResniffMessage', 'devices.device.errorResniffTitle', {
          params: {
            error: error.error.error.message
          }
        });
      }
    );
  }

  toggleDataExpand() {
    this.deviceTypeDataExpand = !this.deviceTypeDataExpand;
  }

  toggleClientReportedDataExpand() {
    this.clientReportedDeviceTypeDataExpand = !this.clientReportedDeviceTypeDataExpand;
  }

  closeModal() {
    this.closeDeviceDetailsModal.emit();
  }
}
