import { AfterContentChecked, Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'text-in-circle',
  templateUrl: './text-in-circle.component.html',
  styleUrls: ['./text-in-circle.component.scss']
})
export class TextInCircleComponent implements AfterContentChecked {
  resizeTimeOut?: number;
  resizeTimerTimeOut = 0;
  originalHtml = '';

  @Input()
  debugBg = false;

  @Input()
  centerText = true;

  @ViewChild('content')
  content: ElementRef<HTMLDivElement>;

  @ViewChild('original')
  original: ElementRef<HTMLDivElement>;

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    if (this.resizeTimeOut) {
      window.cancelAnimationFrame(this.resizeTimeOut);
    }

    this.resizeTimeOut = window.requestAnimationFrame(() => {
      clearTimeout(this.resizeTimerTimeOut);

      this.resizeTimerTimeOut = setTimeout(() => {
        this.adjustTextToCircle();
      }, 300) as unknown as number;
    });
  }

  constructor(private elm: ElementRef<HTMLDivElement>) {}

  ngAfterContentChecked(): void {
    if (this.original && this.content && this.originalHtml !== this.original.nativeElement.innerHTML) {
      this.originalHtml = this.original.nativeElement.innerHTML;
      this.adjustTextToCircle();
    }
  }

  private adjustTextToCircle(): void {
    const marginsPercent = getComputedStyle(this.content.nativeElement).getPropertyValue('--shapeMargin');
    const containerHeight =
      ((100 - Number.parseFloat(marginsPercent) * 2) * this.elm.nativeElement.getBoundingClientRect().height) / 100;

    this.content.nativeElement.innerHTML = this.original.nativeElement.innerHTML;

    if (this.elementBottomPosition(this.content.nativeElement) > containerHeight) {
      this.overflowHideWithEllipsis(containerHeight);
    } else if (this.centerText) {
      this.centerCircleText(containerHeight);
    }
  }

  private elementBottomPosition(elm: Element): number {
    return (
      elm.getBoundingClientRect().height +
      (elm.getBoundingClientRect().top - this.elm.nativeElement.getBoundingClientRect().top)
    );
  }

  private centerCircleText(containerHeight: number): void {
    // move to center
    let marginTop = (containerHeight - this.content.nativeElement.getBoundingClientRect().height) / 2;

    const marginElement = document.createElement('span');
    marginElement.innerHTML = '&nbsp;<br>';
    marginElement.style.fontSize = '1px';
    marginElement.style.lineHeight = `${marginTop}px`;

    this.content.nativeElement.prepend(marginElement);

    // lines width changed, move to center again
    const currentTopOffset =
      marginElement.getBoundingClientRect().top - this.elm.nativeElement.getBoundingClientRect().top;

    marginTop = (containerHeight - (this.content.nativeElement.getBoundingClientRect().height - currentTopOffset)) / 2;
    marginElement.style.lineHeight = `${marginTop}px`;

    // too small can do some positioning harm, removing
    if (marginTop < 5) {
      this.content.nativeElement.removeChild(marginElement);
    }
  }

  private overflowHideWithEllipsis(containerHeight: number): void {
    while (
      this.elementBottomPosition(this.content.nativeElement) > containerHeight &&
      this.content.nativeElement.textContent.length > 3
    ) {
      let overflowedItem: Element = this.content.nativeElement;
      const fitFirstItems = this.content.nativeElement.querySelectorAll('.fitFirst');

      for (let i = 0; i < fitFirstItems.length; i++) {
        if (this.elementBottomPosition(fitFirstItems.item(i)) > containerHeight) {
          overflowedItem = fitFirstItems.item(i);
          break;
        }
      }

      this.removeLastCharacter(overflowedItem);
    }
  }

  private removeLastCharacter(elm: ChildNode): void {
    const lastElm = elm.childNodes[elm.childNodes.length - 1];

    if (!lastElm.textContent || lastElm.textContent === '…') {
      elm.removeChild(lastElm);
      return;
    }

    if (lastElm.childNodes?.length > 0) {
      this.removeLastCharacter(lastElm);
    } else {
      if (!lastElm.textContent.endsWith('…')) {
        lastElm.textContent += '…';
      }

      lastElm.textContent = lastElm.textContent.slice(0, -2) + '…';
    }
  }
}
