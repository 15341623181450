import {
  Component,
  OnInit,
  AfterViewInit,
  Input,
  ViewChildren,
  QueryList,
  Output,
  EventEmitter,
  OnChanges,
  OnDestroy,
  ElementRef
} from '@angular/core';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { ModalService } from 'src/app/lib/services/modal.service';
import { SlideTogglerItems } from 'src/app/lib/interfaces/interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as erdm from 'element-resize-detector';

@UntilDestroy()
@Component({
  selector: 'slidetoggler',
  templateUrl: './slidetoggler.component.html',
  styleUrls: ['./slidetoggler.component.scss']
})
export class SlidetogglerComponent<T> implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input()
  options: SlideTogglerItems<T>;

  @Input()
  editable: boolean = true;

  @Input()
  confirmChange: { title?: string; message?: string; confirmButton?: string; cancelButton?: string } = null;

  @Output()
  toggle = new EventEmitter<T>();

  @ViewChildren('items')
  items: QueryList<ElementRef<HTMLDivElement>>;

  left: number = 0;
  width: number = 0;
  debouncerTimeout: number;
  animate: boolean = false;
  hideSlider: boolean = false;
  erd = erdm({ strategy: 'scroll' });

  constructor(private plume: PlumeService, private elm: ElementRef, private modal: ModalService) {}

  ngOnInit(): void {
    this.plume.toggler.pipe(untilDestroyed(this)).subscribe(() => {
      setTimeout(() => this.selectOption(), 100);
    });
  }

  ngAfterViewInit(): void {
    this.erd.listenTo(this.elm.nativeElement, () => {
      this.debounce(() => this.selectOption());
    });

    setTimeout(() => this.selectOption(), 100);
  }

  ngOnChanges(): void {
    setTimeout(() => this.selectOption(), 100);
  }

  debounce(fn: () => void): void {
    if (this.debouncerTimeout) {
      window.cancelAnimationFrame(this.debouncerTimeout);
    }

    this.debouncerTimeout = window.requestAnimationFrame(fn);
  }

  selectOption(): void {
    if (this.items && this.options) {
      const options = {
        count: 0,
        items: this.items.toArray()
      };

      this.options?.forEach((option, index) => {
        if (option.selected) {
          this.left = options.items[index].nativeElement.offsetLeft - 1;
          this.width = options.items[index].nativeElement.offsetWidth;
          options.count++;
        }
      });

      this.hideSlider = !options.count;
    }
  }

  changeOption(option: SlideTogglerItems<T>[0], event: MouseEvent): void {
    event.stopPropagation();

    const change = () => {
      this.animate = true;

      this.options?.forEach((option) => {
        option.selected = false;
      });

      option.selected = true;

      this.toggle.emit(option.value);
      this.selectOption();

      setTimeout(() => {
        this.animate = false;
      }, 250);
    };

    if (this.editable) {
      if (this.confirmChange) {
        this.modal
          .showDialog(
            this.confirmChange.message || 'slidetoggler.confirmMessage',
            this.confirmChange.title || 'slidetoggler.confirmTitle',
            {
              buttons: [
                { style: 'tertiary light', value: this.confirmChange.cancelButton || 'slidetoggler.confirmCancel' },
                { style: 'super-primary', value: this.confirmChange.confirmButton || 'slidetoggler.confirmOk' }
              ]
            }
          )
          .subscribe((response) => {
            if (response.item.style === 'super-primary') {
              change();
            }
          });
      } else {
        change();
      }
    }
  }

  ngOnDestroy(): void {
    if (this.debouncerTimeout) {
      clearTimeout(this.debouncerTimeout);
    }

    this.erd.uninstall(this.elm.nativeElement);
  }
}
