import {
  HttpBackend,
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateLoader, TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ICatalog } from './interfaces/interface';
import { AuthService } from './services/auth.service';
import { CriticalService } from './services/critical.service';
import { DeviceRefService } from './services/deviceref.service';
import { FirebaseService } from './services/firebase.service';
import { MixpanelService } from './services/mixpanel.service';
import { ModelRefService } from './services/modelref.service';
import { ToastService } from './services/toast.service';

@Injectable()
export class PlumeHttpInterceptor implements HttpInterceptor {
  constructor(
    private auth: AuthService,
    private critical: CriticalService,
    private toast: ToastService,
    private mixpanel: MixpanelService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headers = request.headers;

    headers = headers.set('Cache-Control', 'no-cache');
    headers = headers.set('Pragma', 'no-cache');
    headers = headers.set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT');
    headers = headers.set('Plume-Application-Name', 'PlumeCentral');

    if (!navigator.onLine) {
      this.critical.setNetworkDown(true);
    } else {
      this.critical.setNetworkDown(false);
    }

    const req = request.clone({ headers: this.auth.getHeaders(headers), params: this.auth.getParams(request.params) });

    return next.handle(req).pipe(
      tap(
        () => {},
        (error: any) => {
          if (error instanceof HttpErrorResponse && error.status !== 404) {
            this.mixpanel.storeEvent('INTERCEPTOR', {
              url: req.method + ' ' + req.urlWithParams,
              error: error.error?.error ? error.error.error : error?.error ? error.error : error
            });
          }

          if (error instanceof HttpErrorResponse && error.status === 401) {
            if (this.auth.isAuthenticated()) {
              this.auth.logout({ error: 'intercepted401' });
            }
          }

          if (error instanceof HttpErrorResponse && error.status === 503) {
            this.toast.singleton('toast503', 'warning', '503.message', '503.notFound', {
              disableTimeOut: false
            });
          }
        }
      )
    );
  }
}

export class TranslateHttpLoader implements TranslateLoader {
  constructor(private http: HttpClient, private handler: HttpBackend, public token: any) {}

  public getTranslation(lang: string): any {
    this.http = new HttpClient(this.handler);
    return this.http.get(`/assets/i18n/${lang.replace('-', '_')}.json?${this.token}`);
  }
}

export function HttpLoaderFactory(http: HttpClient, handler: HttpBackend): any {
  return new TranslateHttpLoader(http, handler, environment.ENV === 'Production' ? environment.version : Date.now());
}

export function EnvironmentFactory(
  http: HttpClient,
  handler: HttpBackend,
  translate: TranslateService,
  auth: AuthService
): any {
  http = new HttpClient(handler);

  return () =>
    new Promise((resolve: any) => {
      const initLang = () => {
        translate.currentLoader = HttpLoaderFactory(http, handler);

        const language = auth.sanitizeLanguage(localStorage.getItem('lang'));

        translate.setDefaultLang('en');
        translate.use(language).subscribe(
          () => {
            resolve();
          },
          () => {
            resolve();
          }
        );
      };

      http
        .get('/env')
        .toPromise()
        .then((response: any) => {
          environment.ENV = response;
          initLang();
        })
        .catch(() => {
          environment.ENV = 'Local';
          initLang();
        });
    });
}

export function FirebaseFactory(firebase: FirebaseService, http: HttpClient, handler: HttpBackend): any {
  return () =>
    new Promise((resolve: any) => {
      firebase.fallback(() => {
        http = new HttpClient(handler);

        http
          .get(firebase.fallbackUrl)
          .toPromise()
          .then((response: any) => {
            firebase.snapshot = response[firebase.getDeployment()];
            resolve();
          })
          .catch(() => {
            firebase.snapshot = null;
            resolve();
          });
      });

      if (environment.firebase.enabled) {
        firebase.authenticate().then(() => {
          firebase.root
            .once('value')
            .then((data: any) => {
              firebase.cancelFallback();
              firebase.snapshot = data.val();
              resolve();
            })
            .catch(() => {
              firebase.snapshot = null;
              resolve();
            });
        });
      }
    });
}

export function MetasaurusFactory(
  modelRef: ModelRefService,
  auth: AuthService,
  http: HttpClient,
  handler: HttpBackend
): any {
  return () =>
    new Promise((resolve: any) => {
      http = new HttpClient(handler);

      const env = auth.getEnvFromUrl();
      if (env?.metasaurusUrl) {
        environment.metasaurus.url = env.metasaurusUrl;
      }
      http
        .get(environment.metasaurus.url + environment.metasaurus.prefix + environment.metasaurus.catalog)
        .toPromise()
        .then((catalog) => {
          modelRef.catalog = catalog as ICatalog;
          resolve();
        })
        .catch(() => {
          http
            .get(environment.metasaurus.fallback)
            .toPromise()
            .then((catalog) => {
              modelRef.catalog = catalog as ICatalog;
              resolve();
            })
            .catch(() => {
              resolve();
            });
        });
    });
}

export function MetasaurusDeviceFactory(
  deviceRef: DeviceRefService,
  auth: AuthService,
  http: HttpClient,
  handler: HttpBackend
): any {
  return () =>
    new Promise((resolve: any) => {
      http = new HttpClient(handler);

      const env = auth.getEnvFromUrl();
      if (env?.metasaurusUrl) {
        environment.metasaurus.url = env.metasaurusUrl;
      }
      http
        .get(environment.metasaurus.url + environment.metasaurus.devicePrefix + environment.metasaurus.catalog)
        .toPromise()
        .then((catalog: string[]) => {
          deviceRef.setCatalog(catalog);
          resolve();
        })
        .catch(() => {
          resolve();
        });
    });
}
