import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { Observable, combineLatest } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { selectBaseUrl } from 'src/app/store/customer/customer.selectors';
import {
  ISecondaryNetworkCaptivePortal,
  ISecondaryNetworkFronthaul,
  ISecondaryNetworks
} from '../interfaces/interface';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class SecondaryNetworksService {
  constructor(private api: ApiService, private store: Store) {}

  getFronthaul$(customer?: { customerId: string; locationId: string }): Observable<ISecondaryNetworkFronthaul[]> {
    return this.store.select(selectBaseUrl(customer)).pipe(
      take(1),
      switchMap((baseUrl) => this.api.get(`${baseUrl}/secondaryNetworks/fronthauls`))
    );
  }

  editFronthaul$(
    id: string,
    data: Partial<ISecondaryNetworkFronthaul>,
    customer?: { customerId: string; locationId: string }
  ): Observable<ISecondaryNetworkFronthaul> {
    return this.store.select(selectBaseUrl(customer)).pipe(
      take(1),
      switchMap((baseUrl) => this.api.patch(`${baseUrl}/secondaryNetworks/fronthauls/${id}`, data))
    );
  }

  addFronthaul$(
    data: Omit<ISecondaryNetworkFronthaul, 'vapType' | 'accessZone'> & { encryptionKey: string; purgatory: boolean },
    customer?: { customerId: string; locationId: string }
  ): Observable<ISecondaryNetworkFronthaul> {
    return this.store.select(selectBaseUrl(customer)).pipe(
      take(1),
      switchMap((baseUrl) => this.api.post(`${baseUrl}/secondaryNetworks/fronthauls/`, data))
    );
  }

  deleteFronthaul$(id: string): Observable<undefined> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.delete(`${baseUrl}/secondaryNetworks/fronthauls/${id}`))
    );
  }

  getCaptivePortalNetwork$(): Observable<ISecondaryNetworkCaptivePortal[]> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.get(`${baseUrl}/secondaryNetworks/captivePortals`))
    );
  }

  editCaptivePortalNetwork$(
    id: string,
    data: Partial<ISecondaryNetworkCaptivePortal>
  ): Observable<ISecondaryNetworkCaptivePortal> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.patch(`${baseUrl}/secondaryNetworks/captivePortals/${id}`, data))
    );
  }

  addCaptivePortalNetwork$(ssid: string, enable: boolean = true): Observable<ISecondaryNetworkCaptivePortal> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) =>
        this.api.post(`${baseUrl}/secondaryNetworks/captivePortals`, {
          networkId: 'guest',
          enable,
          ssid
        })
      )
    );
  }

  deleteCaptivePortalNetwork$(id: string): Observable<undefined> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.delete(`${baseUrl}/secondaryNetworks/captivePortals/${id}`))
    );
  }

  getCaptivePortalAuthorizedClients$(): Observable<{ mac: string }[]> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.get(`${baseUrl}/secondaryNetworks/captivePortals/guest/authorizedClients`))
    );
  }

  setCaptivePortalAuthorizedGuestDevice$(mac: string, mode: boolean): Observable<unknown> {
    if (mode) {
      return this.store.select(selectBaseUrl()).pipe(
        take(1),
        switchMap((baseUrl) =>
          this.api.post(`${baseUrl}/secondaryNetworks/captivePortals/guest/authorizedClients`, {
            mac,
            expireAt: moment().add(1, 'days').toISOString()
          })
        )
      );
    } else {
      return this.store.select(selectBaseUrl()).pipe(
        take(1),
        switchMap((baseUrl) =>
          this.api.delete(`${baseUrl}/secondaryNetworks/captivePortals/guest/authorizedClients/${mac}`)
        )
      );
    }
  }

  getSecondaryNetworks$(): Observable<ISecondaryNetworks> {
    return combineLatest([this.getFronthaul$(), this.getCaptivePortalNetwork$()]).pipe(
      map(([fronthauls, captivePortals]) => {
        const employeeFronthaulIndex = fronthauls.findIndex((fronthaul) => fronthaul.networkId === 'employee');
        const secondaryFronthaulIndex = fronthauls.findIndex((fronthaul) => fronthaul.networkId === 'secondary');
        return {
          guest: {
            enable: captivePortals[0]?.enable || false,
            networkId: captivePortals[0]?.networkId || '',
            ssid: captivePortals[0]?.ssid || '',
            vapType: captivePortals[0]?.vapType || '',
            wpaMode: captivePortals[0]?.wpaMode || ''
          },
          employee: {
            accessZone: fronthauls[employeeFronthaulIndex]?.accessZone || '',
            enable: fronthauls[employeeFronthaulIndex]?.enable || false,
            networkId: fronthauls[employeeFronthaulIndex]?.networkId || '',
            ssid: fronthauls[employeeFronthaulIndex]?.ssid || '',
            vapType: fronthauls[employeeFronthaulIndex]?.vapType || '',
            wpaMode: fronthauls[employeeFronthaulIndex]?.wpaMode || ''
          },
          secondary: {
            accessZone: fronthauls[secondaryFronthaulIndex]?.accessZone || '',
            enable: fronthauls[secondaryFronthaulIndex]?.enable || false,
            networkId: fronthauls[secondaryFronthaulIndex]?.networkId || '',
            ssid: fronthauls[secondaryFronthaulIndex]?.ssid || '',
            vapType: fronthauls[secondaryFronthaulIndex]?.vapType || '',
            wpaMode: fronthauls[secondaryFronthaulIndex]?.wpaMode || ''
          }
        };
      })
    );
  }
}
