<div class="mainLine"></div>
<div
  class="mainLineMiddle"
  [style.left.%]="((lower - min) / (max - min)) * 100"
  [style.width.%]="((higher - lower) / (max - min)) * 100"
></div>
<div
  class="dotLower"
  [style.left.%]="((lower - min) / (max - min)) * 100"
  (mousedown)="mouseDown('lower')"
  (touchstart)="mouseDown('lower')"
  [class.moving]="moving === 'lower'"
></div>
<div
  class="dotHigher"
  [style.left.%]="((higher - min) / (max - min)) * 100"
  (mousedown)="mouseDown('higher')"
  (touchstart)="mouseDown('higher')"
  [class.moving]="moving === 'higher'"
></div>

<div *ngFor="let stepValue of steps" class="step" [style.left.%]="((stepValue - min) / (max - min)) * 100">
  {{ stepValue }}
</div>
