import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, of } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { CustomerService } from 'src/app/lib/services/customer.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { ToastService } from 'src/app/lib/services/toast.service';
import {
  configOptimizationsAutoModeEnable,
  configOptimizationsDfsModeSet,
  steeringBandSteeringSet
} from '../configview/config.actions';
import { selectCustomerIdentification, selectLocationList } from '../customer/customer.selectors';
import {
  addNewLocation,
  addNewLocationError,
  addNewLocationFinished,
  archiveCurrentLocation,
  archiveCurrentLocationError,
  archiveCurrentLocationFinished,
  archiveCurrentLocationFinishedWithLocationRedirect,
  bandSteeringSet,
  bandSteeringSetError,
  bandSteeringSetFinished,
  controlModeSet,
  controlModeSetError,
  controlModeSetFinished,
  locationNameSet,
  locationNameSetError,
  locationNameSetFinished,
  networkModeSet,
  networkModeSetError,
  networkModeSetFinished,
  networkModeSetWan,
  nodesAuthorizationSet,
  nodesAuthorizationSetError,
  nodesAuthorizationSetFinished,
  optimizationsAutoModeEnable,
  optimizationsAutoModeEnableError,
  optimizationsAutoModeEnableFinished,
  optimizationsDfsModeSet,
  optimizationsDfsModeSetError,
  optimizationsDfsModeSetFinished,
  partnerIdSaved,
  partnerIdSavedError,
  partnerIdSet,
  podsAuthorizationSet,
  podsAuthorizationSetError,
  podsAuthorizationSetFinished,
  serviceIdSet,
  serviceIdSetError,
  serviceIdSetFinished,
  serviceLevelSet,
  serviceLevelSetError,
  serviceLevelSetFinished,
  WifiModeEnable,
  WifiModeEnableError,
  WifiModeEnableFinished
} from './customer-info.actions';

@Injectable()
export class CustomerInfoEffects {
  partnerIdSet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(partnerIdSet),
      switchMap(({ partnerId }) =>
        this.customer.setPartnerId(partnerId).pipe(
          map((customer) => partnerIdSaved({ customer })),
          catchError((error) => {
            this.toast.error(error.error.error.message, error.error.error.name);
            return of(partnerIdSavedError({ error }));
          })
        )
      )
    )
  );

  EnableOptimizationsAutoMode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(optimizationsAutoModeEnable, configOptimizationsAutoModeEnable),
      switchMap(({ value }) =>
        this.customer.EnableOptimizationsAutoMode$(value).pipe(
          map(() => optimizationsAutoModeEnableFinished({ value })),
          catchError((error) => {
            this.toast.error(error.error.error.message, error.error.error.name);
            return of(optimizationsAutoModeEnableError({ error }));
          })
        )
      )
    )
  );

  setOptimizationsDfsMode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(optimizationsDfsModeSet, configOptimizationsDfsModeSet),
      switchMap(({ value }) =>
        this.customer.setOptimizationsDfsMode$(value).pipe(
          map(() => optimizationsDfsModeSetFinished()),
          catchError((error) => {
            this.toast.error(error.error.error.message, error.error.error.name);
            return of(optimizationsDfsModeSetError({ error }));
          })
        )
      )
    )
  );

  bandSteeringSet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(bandSteeringSet, steeringBandSteeringSet),
      switchMap(({ value }) =>
        this.customer.setBandSteering$(value).pipe(
          map((steering) => bandSteeringSetFinished(steering)),
          catchError((error) => {
            this.toast.error(error.error.error.message, error.error.error.name);
            return of(bandSteeringSetError({ error }));
          })
        )
      )
    )
  );

  serviceLevelSet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(serviceLevelSet),
      switchMap(({ value }) =>
        this.customer.setServiceLevel$(value).pipe(
          map((response) => serviceLevelSetFinished({ steering: response })),
          catchError((error) => {
            this.toast.error(error.error.error.message, error.error.error.name);
            return of(serviceLevelSetError({ error }));
          })
        )
      )
    )
  );

  WifiModeEnable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WifiModeEnable),
      switchMap(({ value }) =>
        this.customer.enableWifiNetwork$(value).pipe(
          map((response) => WifiModeEnableFinished(response)),
          catchError((error) => of(WifiModeEnableError({ error })))
        )
      )
    )
  );

  controlModeSet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(controlModeSet),
      switchMap(({ value }) =>
        this.customer.setControlMode$(value).pipe(
          map(() => controlModeSetFinished()),
          catchError((error) => of(controlModeSetError({ error })))
        )
      )
    )
  );

  networkModeSet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(networkModeSet, networkModeSetWan),
      switchMap(({ value }) =>
        this.customer.setNetworkMode$(value).pipe(
          map((response) => {
            this.toast.success('toast.customerinfo.networkModeMsg', 'toast.customerinfo.networkModeTitle', {
              params: { mode: this.translate.instant(response) }
            });
            return networkModeSetFinished({ value: response });
          }),
          catchError((error) => {
            this.toast.error('toast.customerinfo.networkModeMsgFail', 'toast.customerinfo.networkModeTitleFail', {
              disableTimeOut: true,
              params: {
                error: error.error.error.message
              }
            });
            return of(networkModeSetError({ error }));
          })
        )
      )
    )
  );

  serviceIdSet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(serviceIdSet),
      switchMap(({ value }) =>
        this.customer.setServiceId$(value).pipe(
          map(() => {
            this.toast.success('toast.customerinfo.successServiceIdMsg', 'toast.customerinfo.successServiceIdTitle');
            return serviceIdSetFinished();
          }),
          catchError((error) => {
            this.toast.error('toast.customerinfo.errorServiceIdMsg', 'toast.customerinfo.errorServiceIdTitle', {
              disableTimeOut: true,
              params: {
                error: error.error.error.message
              }
            });
            return of(serviceIdSetError({ error }));
          })
        )
      )
    )
  );

  locationNameSet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(locationNameSet),
      switchMap(({ value }) =>
        this.customer.setLocationName$(value).pipe(
          map(() => {
            this.toast.success(
              'toast.customerinfo.successLocationNameMsg',
              'toast.customerinfo.successLocationNameTitle'
            );
            return locationNameSetFinished();
          }),
          catchError((error) => {
            this.toast.error('toast.customerinfo.errorLocationNameMsg', 'toast.customerinfo.errorLocationNameTitle', {
              disableTimeOut: true,
              params: {
                error: error.error.error.message
              }
            });
            return of(locationNameSetError({ error }));
          })
        )
      )
    )
  );

  nodesAuthorizationSet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(nodesAuthorizationSet),
      switchMap(({ nodes }) =>
        this.customer.setNodesAuthorizations$(nodes).pipe(
          map((value) => {
            this.mixpanel.storeEvent('LOCATION_AUTHORIZATION_SET_NODES');
            return nodesAuthorizationSetFinished({ value });
          }),
          catchError((error) => {
            this.toast.warning(error.error.error.message, error.error.error.name);
            return of(nodesAuthorizationSetError({ error }));
          })
        )
      )
    )
  );

  podsAuthorizationSet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(podsAuthorizationSet),
      switchMap(({ count }) =>
        this.customer.setPodsAuthorizations$(count).pipe(
          map((value) => {
            this.mixpanel.storeEvent('LOCATION_AUTHORIZATION_SET_TOTAL');
            return podsAuthorizationSetFinished({ value });
          }),
          catchError((error) => {
            this.toast.warning(error.error.error.message, error.error.error.name);
            return of(podsAuthorizationSetError({ error }));
          })
        )
      )
    )
  );

  archiveCurrentLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(archiveCurrentLocation),
      switchMap(() =>
        this.customer.archiveCurrentLocation$().pipe(
          switchMap(() =>
            combineLatest([
              this.store.select(selectLocationList),
              this.store.select(selectCustomerIdentification)
            ]).pipe(take(1))
          ),
          map(([locations, { locationid }]) => {
            setTimeout(() => {
              this.toast.success('customerinfo.modal.toastMsg', 'customerinfo.modal.toastTitle');
            }, 0);
            const currentLocations = locations.filter((item) => item.id !== locationid);
            if (currentLocations.length > 0) {
              return archiveCurrentLocationFinishedWithLocationRedirect({ newLocation: currentLocations[0].id });
            } else {
              this.router.navigate(['/']);
            }

            return archiveCurrentLocationFinished();
          }),
          catchError((error) => of(archiveCurrentLocationError({ error })))
        )
      )
    )
  );

  addNewLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addNewLocation),
      switchMap(({ name, profile }) =>
        this.customer.addNewLocation$(name, profile).pipe(
          map((location) => {
            setTimeout(() => {
              this.toast.success('customerinfo.newLocation.toastMsg', 'customerinfo.newLocation.toastTitle');
            }, 0);
            return addNewLocationFinished({ newLocation: location.id });
          }),
          catchError((error) => of(addNewLocationError({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private router: Router,
    private toast: ToastService,
    private customer: CustomerService,
    private translate: TranslateService,
    private mixpanel: MixpanelService
  ) {}
}
