import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { INode, ITopology, ITopologyPod } from '../interfaces/interface';
import { ApiService } from './api.service';
import { PlumeService } from './plume.service';

@Injectable({ providedIn: 'root' })
export class NodeService {
  constructor(private plume: PlumeService, private api: ApiService) {}

  create$(serialNumber: string): Observable<any> {
    return this.api.post(this.baseUrl(), { serialNumber }, 'api', { observe: 'response' });
  }

  getAll$(): Observable<{ nodes: INode[] }> {
    return this.api.get(this.baseUrl(), 'api');
  }

  delete$(preservePackIDs: boolean): Observable<any> {
    return this.api.delete(`${this.baseUrl()}/?preservePackId=${preservePackIDs}`, 'api');
  }

  deleteNode$(nodeId: string, preservePackId: boolean, forceUnclaim: boolean): Observable<any> {
    return this.api.delete(`${this.nodeUrl(nodeId)}?preservePackId=${preservePackId}`, 'api', {
      preservePackId,
      forceUnclaim
    });
  }

  rename$(nodeId: string, nickname: string): Observable<any> {
    return this.api.put(`${this.nodeUrl(nodeId)}`, { nickname });
  }

  setBleMode$(nodeId: string, mode: 'on' | 'off' | 'wps' | 'connectable'): Observable<any> {
    return this.api.put(`${this.nodeUrl(nodeId)}/bleMode`, { mode });
  }

  setLedMode$(nodeId: string, mode: 'locate' | 'normal'): Observable<any> {
    return this.api.put(`${this.nodeUrl(nodeId)}/ledMode`, { mode });
  }

  getBlePairingPin$(nodeId: string, token: string): Observable<any> {
    return this.api.get(`${this.nodeUrl(nodeId)}/blePairingPin?token=${token}`);
  }

  rebootNode$(nodeId: string, delay: number): Observable<any> {
    return this.api.put(`${this.nodeUrl(nodeId)}/reboot`, { delay });
  }

  triggerSpeedTest$(nodeId: string, testType: 'OOKLA'): Observable<any> {
    return this.api.post(`${this.nodeUrl(nodeId)}/speedTest`, { testType });
  }

  speedTestResults$(nodeId: string, granularity: 'hours' | 'days', limit: number): Observable<any> {
    return this.api.get(`${this.nodeUrl(nodeId)}/speedTestResults?granularity=${granularity}&limit=${limit}`);
  }

  wanStatsLiveModeStream$(startTime: string): Observable<any> {
    return this.api.get(`${this.baseUrl()}/wanStatsLiveModeStream?startTime=${startTime}`, 'reports');
  }

  channelUtilization$(
    nodeId: string,
    congestionFrequency: string,
    granularity: 'hours' | 'days',
    limit: number
  ): Observable<any> {
    return this.api.get(
      `${this.nodeUrl(
        nodeId
      )}/radio/${congestionFrequency}/channelUtilization?granularity=${granularity}&limit=${limit}`,
      'reports'
    );
  }

  bandwidth$(
    nodeId: string,
    bandwidthFrequency: string,
    granularity: 'hours' | 'days',
    limit: number
  ): Observable<any> {
    return this.api.get(
      `${this.nodeUrl(nodeId)}/radio/${bandwidthFrequency}/bandwidth?granularity=${granularity}&limit=${limit}`,
      'reports'
    );
  }

  hardwareHealth$(
    startDate?: string,
    endDate?: string,
    granularity?: '15m' | '3h',
    timezoneOffset?: number
  ): Observable<any> {
    return this.api.get(
      `${this.baseUrl()}/health?&startDate=${startDate}&endDate=${endDate}&granularity=${granularity}&timezoneOffset=${timezoneOffset}`,
      'reports'
    );
  }

  compareTopologyNodes(
    oldTopology: ITopology,
    newTopology: ITopology
  ): {
    added: string[];
    removed: string[];
    connectionStateChanged: string[];
  } {
    const oldNodes = oldTopology.vertices.filter((x) => x.type === 'pod') as ITopologyPod[];
    const newNodes = newTopology.vertices.filter((x) => x.type === 'pod') as ITopologyPod[];

    const diff = {
      added: [] as string[],
      removed: [] as string[],
      connectionStateChanged: [] as string[]
    };
    oldNodes.forEach((oldNode) => {
      if (!newNodes.find((newNode) => newNode.id === oldNode.id)) {
        diff.removed.push(oldNode.id);
      }
    });

    newNodes.forEach((newNode) => {
      const oldNode = oldNodes.find((oldNode) => newNode.id === oldNode.id);
      if (!oldNode) {
        diff.added.push(newNode.id);
      } else if (oldNode.connectionState !== newNode.connectionState) {
        diff.connectionStateChanged.push(newNode.id);
      }
    });

    return diff;
  }

  private baseUrl(): string {
    return `/Customers/${this.plume.customerid}/locations/${this.plume.locationid}/nodes`;
  }

  private nodeUrl(nodeId: string): string {
    return `${this.baseUrl()}/${nodeId}`;
  }
}
