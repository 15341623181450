import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'health-ball',
  templateUrl: './health-ball.component.html',
  styleUrls: ['./health-ball.component.scss']
})
export class HealthBallComponent implements OnChanges {
  @Input() value = 0;
  constructor() {}

  ngOnChanges(): void {
    if (!this.value) {
      this.value = 0;
    } else if (this.value > 100) {
      this.value = 100;
    } else if (this.value < 0) {
      this.value = 0;
    }
  }
}
