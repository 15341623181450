<div class="togglerGroup">
  <slidetoggler [options]="timeItems$ | async" (toggle)="changeTime($event)"></slidetoggler>
  <div class="switch">
    <ng-container *ngrxLet="showNonRealtime$ as showNonRealtime">
      <ng-container>{{ 'traffic.showNonRealtime' | translate }}:</ng-container>
      <div class="toggler" (click)="showNonRealtime$.next(!showNonRealtime)">
        <div class="checkbox">
          <label for="toggleNonRealtime" class="toggle" [class.checked]="showNonRealtime"></label>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<div class="search-input">
  <icon name="icon-search"></icon>
  <input type="text" placeholder="{{ 'traffic.searchDevice' | translate }}" #search />
</div>

<div class="devicesBox" *ngIf="devices$ | async as devices">
  <traffic-pie-monitor-device
    *ngFor="
      let device of devices | filterArrayBy: { filtertext: search.value, properties: ['nickanme', 'name'] };
      trackBy: deviceTrackBy
    "
    [device]="device"
    [timeValue]="selectedTime$ | async"
  ></traffic-pie-monitor-device>
  <div
    class="no-devices"
    *ngIf="(devices | filterArrayBy: { filtertext: search.value, properties: ['nickanme', 'name'] })?.length < 1"
  >
    {{ 'traffic.noDevicesFound' | translate }}
  </div>
</div>
