import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'neighborBubble',
  templateUrl: './neighborBubble.component.html',
  styleUrls: ['./neighborBubble.component.scss']
})
export class NeighborBubbleComponent implements OnChanges, OnInit {
  PARALLEL_SNR_HIGHERTHRESHOLD: number = 150;
  @Input()
  neighbors: any;

  @Input()
  markEvil: boolean;

  @Input()
  markParallel: boolean;

  sort: string;
  headers: any;
  list: any[];

  isGreaterThanThreshold: boolean = false;
  constructor() {
    this.initHeaders();
  }
  ngOnInit(): void {
    this.isGreaterThanThreshold = this.neighbors.some(
      (neighbor: any) => neighbor.rssi > this.PARALLEL_SNR_HIGHERTHRESHOLD
    );
  }
  ngOnChanges(changes: any): void {
    if (changes.neighbors) {
      this.list = [...this.neighbors];
      this.changeSort();
    }
  }

  initHeaders(): void {
    this.sort = 'rssi';
    this.headers = [
      {
        key: 'neighborSsid',
        value: 'health.networkStatus.neighborReportAlarm.ssid',
        desc: false,
        isNumber: false
      },
      {
        key: 'neighborId',
        value: 'health.networkStatus.neighborReportAlarm.neighborId',
        desc: false,
        isNumber: false
      },
      {
        key: 'channel',
        value: 'health.networkStatus.neighborReportAlarm.channel',
        desc: false,
        isNumber: false
      },
      {
        key: 'channelWidth',
        value: 'health.networkStatus.neighborReportAlarm.channelWidth',
        desc: false,
        isNumber: true
      },
      {
        key: 'rssi',
        value: 'health.networkStatus.neighborReportAlarm.snr',
        desc: false,
        isNumber: false
      },
      {
        key: 'freqBand',
        value: 'health.networkStatus.neighborReportAlarm.freqBand',
        desc: false,
        isNumber: false
      }
    ];
  }

  changeSort(key: string = null): void {
    if (key) {
      if (this.sort === key) {
        this.headers.forEach((header: any) => (header.key === key ? (header.desc = !header.desc) : null));
      }

      this.sort = key;
    }

    for (const header of this.headers) {
      if (header.key === this.sort) {
        if (header.isNumber) {
          if (header.desc) {
            this.list.sort((a: any, b: any) => Number(a[header.key]) === Number(b[header.key]) ? 0 : Number(a[header.key]) < Number(b[header.key]) ? -1 : 1);
          } else {
            this.list.sort((a: any, b: any) => Number(a[header.key]) === Number(b[header.key]) ? 0 : Number(a[header.key]) > Number(b[header.key]) ? -1 : 1);
          }
        } else {
          if (header.desc) {
            this.list.sort((a: any, b: any) => a[header.key] === b[header.key] ? 0 : a[header.key] < b[header.key] ? -1 : 1);
          } else {
            this.list.sort((a: any, b: any) => a[header.key] === b[header.key] ? 0 : a[header.key] > b[header.key] ? -1 : 1);
          }
        }
      }
    }
  }
}
