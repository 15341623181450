<div class="content">
  <div class="title">
    {{ 'health.networkInformation.modal.secondarynetworks' | translate }}
  </div>

  <ng-container *ngIf="(isProperty$ | async) === true && numNetworks === 1">
    <ng-container *ngTemplateOutlet="guest"></ng-container>
  </ng-container>

  <ng-container *ngIf="((isProperty$ | async) === true || (isHomePass$ | async) === true) && numNetworks === 1">
    <ng-container *ngTemplateOutlet="iot"></ng-container>
  </ng-container>

  <tabs
    name="secondaryNetworks"
    (tabChange)="change()"
    *ngIf="((isProperty$ | async) === false && (isHomePass$ | async) === false) || numNetworks > 1"
  >
    <tab id="employees" *ngIf="(isWorkPass$ | async) === true" label="health.networkInformation.modal.employees">
      <ng-container *ngTemplateOutlet="employee"></ng-container>
    </tab>
    <tab id="guests" label="health.networkInformation.modal.sbGuests">
      <ng-container *ngTemplateOutlet="guest"></ng-container>
    </tab>

    <tab id="iot" label="health.networkInformation.modal.iotNetwork" *ngIf="isIoT === true">
      <ng-container *ngTemplateOutlet="iot"></ng-container>
    </tab>
  </tabs>
</div>

<modal mode="compact" (overlayAction)="showLimitBW = $event" *ngIf="showLimitBW">
  <ng-container title>{{ 'health.networkInformation.modal.limitBWSettings' | translate }}</ng-container>
  <ng-container content>
    <div class="warning" *ngIf="!limitBWSetting?.bandwidthLimit?.enabled">
      {{ 'health.networkInformation.modal.limitWarning' | translate }}
    </div>
    <div class="table">
      <div class="row">
        <div class="cell">
          <div class="label">{{ 'health.networkInformation.modal.limitBW' | translate }}</div>
        </div>
        <div class="cell">
          <div class="value">
            <slidetoggler [options]="guestLimitItems" (toggle)="updateLimitBWType($event)"></slidetoggler>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="limitBWSetting.bandwidthLimit.enabled">
        <div class="cell">
          <div class="label">{{ 'health.networkInformation.modal.rateLimit' | translate }}</div>
        </div>
        <div class="cell">
          <div class="value">
            <span class="number" *ngIf="!editingRateLimit">{{ limitBWSetting.bandwidthLimit.download }}</span>
            <span *ngIf="!editingRateLimit">
              {{ 'health.networkInformation.modal.' + limitBWSetting.bandwidthLimit.type | translate }}
            </span>
            <input
              type="text"
              [formControl]="guestRateLimitFormControl"
              placeholder="{{ limitBWSetting.bandwidthLimit.download }}"
              (keyup.enter)="updateRateLimit()"
              (keyup.escape)="updateRateLimit()"
              *ngIf="editingRateLimit"
            />
            <icon
              name="icon-edit"
              tooltip="{{ 'health.networkInformation.modal.edit' | translate }}"
              (click)="enableRateLimits()"
              *ngIf="!editingRateLimit"
            ></icon>
            <div class="actions" *ngIf="editingRateLimit">
              <icon
                name="icon-check"
                class="confirm"
                tooltip="{{ 'health.networkInformation.modal.confirm' | translate }}"
                (click)="updateRateLimit()"
              ></icon>
              <icon
                name="icon-close"
                class="size-0_8 cancel"
                tooltip="{{ 'health.networkInformation.modal.cancel' | translate }}"
                (click)="cancelRateLimits()"
              ></icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container controls>
    <button class="tertiary light" (click)="toggleShowLimitBW(false)">
      {{ 'health.networkInformation.modal.cancel' | translate }}
    </button>
    <button class="super-primary" (click)="storeLimitBW()">
      {{ 'health.networkInformation.modal.confirm' | translate }}
    </button>
  </ng-container>
</modal>

<ng-template #employee>
  <div class="scroll-container">
    <table *ngIf="!loadingEmployee && smallbusiness.employees.length">
      <tr class="headers employee" [class.wpa3capable]="wpa3capable$ | async">
        <td class="ssid">{{ 'health.networkInformation.modal.ssid' | translate }}</td>
        <td class="id">{{ 'health.networkInformation.modal.id' | translate }}</td>
        <td class="password">{{ 'health.networkInformation.modal.password' | translate }}</td>
        <td class="wpa" *ngIf="isWPA3 || allowSecondaryNetworkFronthaulWpaModeOpenEnabled">
          {{ 'health.networkInformation.encryption' | translate }}
        </td>
        <td class="actions"></td>
      </tr>
      <tr
        class="secondaryNetwork employee"
        [class.wpa3capable]="wpa3capable$ | async"
        *ngFor="let employee of smallbusiness.employees"
      >
        <td class="ssid">
          <div class="container">
            <ng-container *ngIf="!employee.editing.ssid">{{ employee.ssid }}</ng-container>
            <input
              class="enabled"
              [class.error]="employee.error"
              [formControl]="employee.ssidInput"
              type="text"
              *ngIf="employee.editing.ssid"
            />
            <icon
              name="icon-edit"
              tooltip="{{ 'health.networkInformation.modal.edit' | translate }}"
              (click)="employee.editing.ssid = true"
              *ngIf="
                !employee.editing.ssid &&
                !employee.editing.password &&
                (!plume.isSomeGroupSupportRole() || supportTechnicianCanEdit)
              "
            ></icon>
          </div>
        </td>
        <td class="id">{{ employee.id }}</td>
        <td class="password">
          <div class="container" *ngIf="employee.wpaMode !== 'open'">
            <span *ngIf="!employee.editing.password">***********</span>
            <icon
              name="icon-edit"
              tooltip="{{ 'health.networkInformation.modal.edit' | translate }}"
              (click)="employee.editing.password = true"
              *ngIf="
                !employee.editing.password &&
                !employee.editing.ssid &&
                (!plume.isSomeGroupSupportRole() || supportTechnicianCanEdit)
              "
            ></icon>
            <input
              class="enabled"
              [class.error]="employee.error"
              [formControl]="employee.password"
              type="password"
              placeholder="{{ 'health.networkInformation.modal.password' | translate }}"
              *ngIf="employee.editing.password"
            />
          </div>
        </td>
        <td class="wpa" *ngIf="employee.wpaItems.length > 0">
          <slidetoggler [options]="employee.wpaItems" (toggle)="changeWpaMode(employee, $event)"></slidetoggler>
        </td>

        <td class="actions" *ngIf="employee.editing.ssid || employee.editing.password">
          <div class="container">
            <icon
              name="icon-check"
              class="confirm"
              tooltip="{{ 'health.networkInformation.modal.confirm' | translate }}"
              (click)="editEmployee(employee)"
            ></icon>
            <icon
              name="icon-close"
              class="size-0_8 cancel"
              tooltip="{{ 'health.networkInformation.modal.cancel' | translate }}"
              (click)="cancelEdit('employee', employee)"
            ></icon>
          </div>
        </td>
        <td
          class="actions"
          *ngIf="
            !employee.editing.ssid &&
            !employee.editing.password &&
            (plume.isSomeAdminRole() || supportTechnicianCanEdit)
          "
        >
          <div class="container">
            <icon
              name="icon-info"
              class="size-0_8"
              tooltip="{{ 'health.networkInformation.encryption' | translate }}: {{
                helper.wpaMode(employee?.wpaMode) | translate
              }}"
            ></icon>
            <icon
              name="icon-close"
              class="size-0_8"
              tooltip="{{ 'health.networkInformation.modal.delete' | translate }}"
              *ngIf="!supportTechnicianCanEdit"
              (click)="confirmDeleteEmployee(employee)"
            ></icon>
          </div>
        </td>
      </tr>
    </table>
  </div>
  <ng-container
    *ngIf="!plume.isSomeAdminRole() && !supportTechnicianCanEdit && !loadingEmployee && !smallbusiness.employees.length"
  >
    <div class="title">
      {{ 'health.networkInformation.modal.noEmployeeNetworks' | translate }}
    </div>
  </ng-container>
  <ng-container
    *ngIf="(plume.isSomeAdminRole() || supportTechnicianCanEdit) && !loadingEmployee && !smallbusiness.employees.length"
  >
    <div class="title">
      {{ 'health.networkInformation.modal.addEmployee' | translate }}
    </div>
    <div class="scroll-container">
      <table>
        <tr class="add">
          <td class="ssid">
            <div class="container">
              {{ 'health.networkInformation.modal.ssid' | translate }}:
              <input
                class="enabled"
                type="text"
                [formControl]="employeeSsidFormControl"
                autocomplete="none"
                autoreadonly
              />
            </div>
          </td>
          <td class="password">
            <div class="container">
              {{ 'health.networkInformation.modal.password' | translate }}:
              <input
                class="enabled"
                type="password"
                [formControl]="employeePasswordFormControl"
                autocomplete="none"
                autoreadonly
              />
            </div>
          </td>
          <td class="wpa" *ngIf="wpa3capable$ | async">
            <slidetoggler
              [options]="encryptionItems"
              tooltip="{{ 'health.networkInformation.encryption' | translate }}"
            ></slidetoggler>
          </td>
          <td class="actions">
            <div class="container">
              <button class="action small" (click)="addEmployeeKey()">
                {{ 'health.networkInformation.modal.addNetwork' | translate }}
              </button>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </ng-container>
  <ng-container *ngIf="loadingEmployee">{{ 'health.networkInformation.modal.loading' | translate }}</ng-container>
</ng-template>

<ng-template #iot>
  <table>
    <tr class="headers">
      <td class="iotSSID">{{ 'health.networkInformation.modal.ssid' | translate }}</td>
      <td class="iotData">{{ 'health.networkInformation.modal.id' | translate }}</td>
    </tr>
    <tr *ngFor="let net of IOT">
      <td class="iotSSID">
        {{ net.ssid }}
      </td>
      <td class="iotData">
        {{ net.networkId }}
      </td>
    </tr>
  </table>
</ng-template>

<ng-template #guest>
  <div class="scroll-container">
    <table *ngIf="!loadingGuest && smallbusiness.guests.length">
      <tr class="headers guest" [class.session]="smallbusiness.guests[0]?.sessionTimeLimitSec">
        <td class="ssid">{{ 'health.networkInformation.modal.ssid' | translate }}</td>
        <td class="id">{{ 'health.networkInformation.modal.id' | translate }}</td>
        <td class="enabled">{{ 'health.networkInformation.modal.enabled' | translate }}</td>
        <td class="limit">{{ 'health.networkInformation.modal.limit' | translate }}</td>
        <td class="session" *ngIf="smallbusiness.guests[0]?.sessionTimeLimitSec">
          {{ 'health.networkInformation.modal.session' | translate }}
        </td>
        <td class="actions"></td>
      </tr>
      <tr
        class="secondaryNetwork guest"
        [class.session]="smallbusiness.guests[0]?.sessionTimeLimitSec"
        *ngFor="let guest of smallbusiness.guests"
      >
        <td class="ssid">
          <div class="container">
            <ng-container *ngIf="!guest.editing">{{ guest.ssid }}</ng-container>
            <input
              class="enabled"
              [class.error]="guest.error"
              [formControl]="guest.ssidInput"
              type="text"
              *ngIf="guest.editing"
            />
            <icon
              name="icon-edit"
              tooltip="{{ 'health.networkInformation.modal.edit' | translate }}"
              (click)="guest.editing = true"
              *ngIf="!guest.editing && (!plume.isSomeGroupSupportRole() || supportTechnicianCanEdit)"
            ></icon>
          </div>
        </td>
        <td class="id">{{ guest.id }}</td>
        <td class="active">
          <slidetoggler
            [options]="guest.enabledItems"
            [editable]="plume.isSomeAdminRole() || supportTechnicianCanEdit"
            (toggle)="changeEnabled('guest', $event)"
          ></slidetoggler>
        </td>
        <td class="limit">
          <div class="container">
            {{ guest.limitEnabled | translate }}
            <icon
              name="icon-wrench"
              tooltip="{{ 'health.networkInformation.modal.configure' | translate }}"
              (click)="toggleShowLimitBW(true, guest)"
              *ngIf="plume.isSomeAdminRole() || supportTechnicianCanEdit"
            ></icon>
          </div>
        </td>
        <td class="session" *ngIf="getSessionTime(guest.sessionTimeLimitSec) as session">
          <ng-container *ngIf="session.days && session.hours">
            {{ 'health.networkInformation.modal.daysHours' | translate: { days: session.days, hours: session.hours } }}
          </ng-container>
          <ng-container *ngIf="session.days">
            {{ 'health.networkInformation.modal.days' | translate: { days: session.days } }}
          </ng-container>
          <ng-container *ngIf="session.hours">
            {{ 'health.networkInformation.modal.hours' | translate: { hours: session.hours } }}
          </ng-container>
        </td>
        <td class="actions" *ngIf="guest.editing">
          <div class="container">
            <icon
              name="icon-check"
              class="confirm"
              tooltip="{{ 'health.networkInformation.modal.confirm' | translate }}"
              (click)="editGuest(guest)"
            ></icon>
            <icon
              name="icon-close"
              class="size-0_8 cancel"
              tooltip="{{ 'health.networkInformation.modal.cancel' | translate }}"
              (click)="cancelEdit('guest', guest)"
            ></icon>
          </div>
        </td>
        <td class="actions" *ngIf="!guest.editing">
          <div class="container">
            <icon
              name="icon-close"
              class="size-0_8"
              tooltip="{{ 'health.networkInformation.modal.delete' | translate }}"
              (click)="confirmDeleteGuest(guest)"
              *ngIf="plume.isSomeAdminRole()"
            ></icon>
            <icon
              name="icon-external"
              class="size-1_2"
              tooltip="{{ 'health.networkInformation.modal.workpass' | translate }}"
              (click)="openWorkpass(guest)"
              *ngIf="plume.isSomeAdminRole() || plume.isUpriseProperyManager()"
            ></icon>
          </div>
        </td>
      </tr>
    </table>
  </div>
  <ng-container
    *ngIf="!plume.isSomeAdminRole() && !supportTechnicianCanEdit && !loadingGuest && !smallbusiness.guests.length"
  >
    <div class="title">
      {{ 'health.networkInformation.modal.noGuestNetworks' | translate }}
    </div>
  </ng-container>
  <ng-container
    *ngIf="(plume.isSomeAdminRole() || supportTechnicianCanEdit) && !loadingGuest && !smallbusiness.guests.length"
  >
    <div class="title">
      {{ 'health.networkInformation.modal.addGuest' | translate }}
    </div>
    <div class="scroll-container">
      <table>
        <tr class="add">
          <td class="ssid">
            <div class="container">
              {{ 'health.networkInformation.modal.ssid' | translate }}:
              <input class="enabled" type="text" [formControl]="guestSsidFormControl" />
            </div>
          </td>
          <td class="active">
            <div class="container">
              {{ 'health.networkInformation.modal.enabled' | translate }}:
              <slidetoggler [options]="guestEnabledItems"></slidetoggler>
            </div>
          </td>
          <td class="actions">
            <div class="container">
              <button class="action small" (click)="addGuestKey()">
                {{ 'health.networkInformation.modal.addNetwork' | translate }}
              </button>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </ng-container>
  <ng-container *ngIf="loadingGuest">{{ 'health.networkInformation.modal.loading' | translate }}</ng-container>
</ng-template>
