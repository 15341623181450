import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'collapsiblebox',
  templateUrl: './collapsiblebox.component.html',
  styleUrls: ['./collapsiblebox.component.scss']
})
export class CollapsibleBoxComponent {
  @Input()
  collapsible: boolean = false;

  @Input()
  toggleSide: 'left' | 'right' = 'left';

  @Input()
  mode: 'fill' | 'bare' = 'fill';

  @Input()
  showDivider: boolean = true;

  @Input()
  showHeader: boolean = true;

  @Input()
  show: boolean = true;

  @Input() scrollOnOpen = false;

  @Output()
  toggle: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private element: ElementRef<HTMLDivElement>) {}

  toggleShow(event: MouseEvent): void {
    if (this.collapsible && !this.checkSkip(event.target as HTMLElement)) {
      this.show = !this.show;
      this.toggle.next(this.show);
      if (this.scrollOnOpen && this.show) {
        setTimeout(() => {
          this.element.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }, 100);
      }
    }
  }

  checkSkip(element: HTMLElement): boolean {
    while (element && element !== this.element.nativeElement) {
      if (element.classList && element.classList.contains('skip-toggle')) {
        return true;
      }

      element = element.parentElement;
    }

    return false;
  }
}
