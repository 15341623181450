import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { CustomerService } from 'src/app/lib/services/customer.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { ModalService } from 'src/app/lib/services/modal.service';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { ToastService } from 'src/app/lib/services/toast.service';
import { selectPipeLocationOnChange } from 'src/app/store/customer/customer.selectors';

@UntilDestroy()
@Component({
  selector: 'mobile',
  templateUrl: './mobile.component.html',
  styleUrls: ['./mobile.component.scss']
})
export class MobileComponent implements OnInit, OnChanges {
  expand: boolean = false;

  isUtilizingFocuses$ = this.store
    .pipe(selectPipeLocationOnChange)
    .pipe(map((location) => location.isUtilizingFocuses));

  @Input()
  open: number = 0;

  @Output()
  toggle = new EventEmitter();

  @Output()
  filter = new EventEmitter();

  @Output()
  clearFilter = new EventEmitter<{ section: string }>();

  constructor(
    private mixpanel: MixpanelService,
    private translate: TranslateService,
    private store: Store,
    public plume: PlumeService,
    private customerService: CustomerService,
    private toast: ToastService,
    private modal: ModalService
  ) {}

  ngOnInit(): void {
    this.registerFilter();
  }

  ngOnChanges(changes: any): void {
    this.expand = changes.open.currentValue;
  }

  toggleExpand(): void {
    this.toggle.emit(!this.expand);

    if (this.expand) {
      this.mixpanel.storeEvent('CONFIGURATION_MOBILE_SCREEN');
    }
  }

  registerFilter(): void {
    this.clearFilter.emit({ section: 'mobile' });

    this.translate
      .get('configurations.mobile.focusEnabled')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'mobile', property: 'focusEnabled', translation: translated })
      );

    this.translate
      .get('configurations.mobile.revertToHomePass2')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'mobile', property: 'revertToHomePass2', translation: translated })
      );
  }

  showRevertModal(): void {
    this.modal
      .showDialog('configurations.mobile.revertDialogMessage', 'configurations.mobile.revertToHomePass2', {
        buttons: [
          { style: 'tertiary light', value: 'configurations.utilities.modal.cancel' },
          { style: 'super-primary', value: 'configurations.mobile.revertDialogOk' }
        ]
      })
      .subscribe((response: any) => {
        if (response.item.value === 'configurations.mobile.revertDialogOk') {
          this.confirmRevertFocusLocation();
        }
      });
  }

  confirmRevertFocusLocation(): void {
    this.customerService.revertFocusLocation$().subscribe(
      () => {
        this.mixpanel.storeEvent('CONFIGURATION_MOBILE_REVERT_LOCATION');
      },
      (error: any) => {
        this.mixpanel.storeEvent('CONFIGURATION_MOBILE_REVERT_LOCATION_FAILURE');
        this.toast.error(error.error.error.message, 'header.failed');
      }
    );
  }
}
