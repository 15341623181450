<div class="toolbar" #toolbar>
  <div
    #panes
    *ngFor="let item of menuitems"
    class="toolbar-item"
    [class.selected]="item.selected"
    [class.hide]="item.hiden"
    [attr.id]="item.id"
    (click)="itemSelect(item)"
  >
    <div class="toolbar-title">
      {{ item.title | translate }}
      <span *ngIf="item.subtitle"> ({{ item.subtitle | translate }}) </span>
    </div>
    <icon name="icon-arrow-down" class="size-0_6" [class.rotate180]="item.selected"></icon>
  </div>
  <div class="toolbar-controls" [class.hide]="!anySelected()" (click)="closeAll()">
    <icon name="icon-close" class="size-0_5"></icon>
  </div>
</div>
