<drop-down
  [disabled]="disabled"
  [closeOnClickValue]="!multiSelect"
  [class.small]="elRef.nativeElement.classList.contains('small')"
  [class.medium]="elRef.nativeElement.classList.contains('medium')"
  [headerTemplate]="headerTemplate"
  [listWidth]="listWidth"
  [listMaxWidth]="listMaxWidth"
  [listMinWidth]="listMinWidth"
  (opened)="setFilter(''); focusFilter(); opened.emit($event)"
>
  {{ headerText() }}
  <ng-container dropdown>
    <div class="filter" (click)="focusFilter()" *ngIf="filterVisibleValue()">
      <icon name="icon-search"></icon>
      <input
        #filterInput
        [(ngModel)]="filterText"
        (ngModelChange)="filter($event)"
        [placeholder]="filterPlaceHolder | translate"
      />
      <icon name="icon-close" class="closeIcon no-close" (click)="setFilter('')"></icon>
    </div>
    <div class="noMatch" *ngIf="filterVisibleValue() && filteredValues.length === 0">
      {{ 'components.dropdown.noMatchingResult' | translate }}
    </div>

    <li
      class="select"
      *ngFor="let value of filteredValues"
      (click)="selectValue(value.value)"
      (mouseenter)="mouseEnter($event)"
      (mouseleave)="mouseLeave($event)"
    >
      <div class="text">
        <span class="value">
          <ng-container *ngTemplateOutlet="rowTemplate || valueTemplate; context: { $implicit: value }"> </ng-container>
        </span>
      </div>
      <icon
        *ngIf="multiSelect"
        class="check"
        name="icon-check"
        [class.selected]="ownModel?.includes(value.value)"
      ></icon>
    </li>
  </ng-container>
</drop-down>

<ng-template #valueTemplate let-value>{{ valueToText(value.value) }}</ng-template>
