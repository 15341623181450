<box>
  <div header>
    {{ 'profiler.mostActiveDevices.title' | translate }}
    <slidetoggler *ngIf="!loading" [options]="items" (toggle)="toggle($event)"></slidetoggler>
  </div>
  <div container>
    <div class="noDevices" *ngIf="!loading && !mostActiveDevices.length">
      {{ 'profiler.mostActiveDevices.noDevices' | translate }}
    </div>
    <div class="group" *ngIf="!loading && mostActiveDevices.length">
      <div class="item" *ngFor="let device of mostActiveDevices">
        <icon [path]="device.icon" class="big" (click)="open(device)"></icon>
        <div class="text">
          <span class="name" (click)="open(device)">{{ device.name | macAddr: true }}</span>
        </div>
        <div class="data">
          <div class="download" [class.active]="mode === 'download'">
            <icon name="icon-arrow-down" class="size-0_6"></icon>
            {{ device.download.value }} {{ device.download.unit }}
          </div>
          <div class="upload" [class.active]="mode === 'upload'">
            <icon name="icon-arrow-up" class="size-0_6"></icon>
            {{ device.upload.value }} {{ device.upload.unit }}
          </div>
          <div class="percent">
            <div class="box" [ngClass]="device[mode + 'PercentColor']">
              {{ device[mode + 'Percent'] | number: '1.0-2' }}%
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="loading" *ngIf="loading">{{ 'loadingIndicator' | translate }}</div>
  </div>
</box>
