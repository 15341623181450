import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { PlumeService } from './plume.service';
import { FirebaseService } from './firebase.service';
import { NodeService } from './nodes.service';
import { DeepReadonly, ILocation, ISecondaryNetworks } from '../interfaces/interface';
import { combineLatest } from 'rxjs';
import { selectPipeLocationOnChange } from 'src/app/store/customer/customer.selectors';
import { take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { SecondaryNetworksService } from './secondary-networks.service';
import { CustomerService } from './customer.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable()
export class NeighborsService {
  PARALLEL_SNR_THRESHOLD: number = 45;
  PARALLEL_SNR_HIGHERTHRESHOLD: number = 150;
  networks: any[] = [];
  locationId: string = '';
  ssid: any = '';
  nodes: any = {};
  rules: any[] = [];
  allSsids: any[];
  network: {
    ssid: string | null;
    wpa2ssid: string | null;
    wpa2enabled: boolean;
    wpa3ssid: string | null;
    wpa3enabled: boolean;
  } = {
    ssid: null,
    wpa2ssid: null,
    wpa2enabled: false,
    wpa3ssid: null,
    wpa3enabled: false
  };
  secondaryNetworksData: ISecondaryNetworks;

  constructor(
    private api: ApiService,
    private plume: PlumeService,
    private secondaryNetworks: SecondaryNetworksService,
    private nodeService: NodeService,
    private firebase: FirebaseService,
    private store: Store,
    private customerService: CustomerService
  ) {}

  reset(): void {
    this.networks = [];
    this.ssid = '';
    this.nodes = {};
  }

  initNetworks(networks: any[], location: DeepReadonly<ILocation>, nodes: any[], rules: any[]): any[] {
    if (this.locationId !== location.id) {
      this.reset();

      this.locationId = location.id;
      this.networks = networks;
      this.ssid = location.ssid;

      nodes.forEach((node: any) => (this.nodes[node.id] = true));
    }

    this.rules = rules;
    this.customerService.getSSIDs$().subscribe((network) => {
      this.network = network;
    });

    return this.networks;
  }

  getNeighbors(callback: any): any {
    const neighborExceptions = this.firebase.snapshot.rules.neighborExceptions;
    const rules = [];

    this.secondaryNetworks
      .getSecondaryNetworks$()
      .pipe(untilDestroyed(this))
      .subscribe((response: any) => {
        this.secondaryNetworksData = response;
      });

    if (neighborExceptions) {
      Object.keys(neighborExceptions).forEach((group: string) => {
        if (neighborExceptions[group].exact != null && neighborExceptions[group].rules) {
          neighborExceptions[group].rules.forEach((rule: string) => {
            rules.push({
              exact: neighborExceptions[group].exact,
              value: rule
            });
          });
        }
      });
    }

    const endpoint = 'reports';
    const neighborReportsUrl =
      '/Customers/' +
      this.plume.customerid +
      '/locations/' +
      this.plume.locationid +
      '/neighborReport?order=desc&limit=1&deep=true';
    combineLatest([
      this.store.pipe(selectPipeLocationOnChange).pipe(take(1)),
      this.api.get(neighborReportsUrl, endpoint)
    ]).subscribe(
      ([location, response]) => {
        this.nodeService.getAll$().subscribe(
          (nodesResponse: any) => {
            if (response && (response.neighbors || (response.length && response[0].neighbors))) {
              const timestamp: string = response.timestamp || null;
              const networks = this.initNetworks(
                response.neighbors || response[0].neighbors,
                location,
                nodesResponse.nodes ? nodesResponse.nodes : [],
                rules
              );
              const evilTwins = this.findEvilTwins();
              const parallelNetworks = this.findParallelNetworks();
              const hasTwins = evilTwins && evilTwins.length;
              const hasParallel = parallelNetworks && parallelNetworks.length;

              callback({ timestamp, networks, evilTwins, parallelNetworks, hasTwins, hasParallel });
            } else {
              callback({ networks: [], evilTwins: [], parallelNetworks: [], hasTwins: false, hasParallel: false });
            }
          },
          () => {
            callback({ networks: [], evilTwins: [], parallelNetworks: [], hasTwins: false, hasParallel: false });
          }
        );
      },
      () => {
        callback({ networks: [], evilTwins: [], parallelNetworks: [], hasTwins: false, hasParallel: false });
      }
    );
  }

  findEvilTwins(): any[] {
    const evilTwins = [];
    this.allSsids = [
      this.secondaryNetworksData?.guest?.ssid,
      this.secondaryNetworksData?.employee?.ssid,
      this.network.wpa2ssid,
      this.network.wpa3ssid
    ].filter((ssid) => ssid);

    this.networks.forEach((network: any) => {
      network.isEvil = false;
      if (
        (network.neighborSsid === this.ssid || this.allSsids.includes(network.neighborSsid)) &&
        !this.nodes[network.neighborId] &&
        !network.isPlume
      ) {
        network.isEvil = true;
        evilTwins.push(network);
      }
    });

    return evilTwins;
  }

  findParallelNetworks(): any[] {
    const parallelNetworks = [];

    this.networks.forEach((network: any) => {
      network.isParallel = false;

      if (
        network.neighborSsid !== this.ssid &&
        network.rssi >= this.PARALLEL_SNR_THRESHOLD &&
        network.rssi <= this.PARALLEL_SNR_HIGHERTHRESHOLD &&
        !this.nodes[network.neighborId] &&
        !network.isPlume &&
        network.neighborSsid.length > 0
      ) {
        let exception = false;

        this.rules.forEach((rule: any) => {
          if (rule.exact && rule.value === network.neighborSsid) {
            exception = true;
          }

          if (!rule.exact && network.neighborSsid.toLowerCase().indexOf(rule.value.toLowerCase()) !== -1) {
            exception = true;
          }
        });

        if (!exception) {
          network.isParallel = true;
          parallelNetworks.push(network);
        }
      }
    });

    return parallelNetworks;
  }
}
