<modal mode="compact" (overlayAction)="closeModal()">
  <ng-container title>{{ 'devices.device.prioritize.header' | translate }}</ng-container>
  <ng-container content>
    <div class="list space prioritization">
      <div class="key">{{ 'devices.device.prioritize.trafficPriority' | translate }}</div>
      <div class="value">
        <drop-down class="selector medium" [disabled]="!canEditDeviceQos">
          {{
            'devices.device.prioritize.priorityTypes.' + (priority.current?.priorityType || priority.priorityTypes[0])
              | translate
          }}
          <ng-container dropdown>
            <li (click)="selectDevicePriority(priorityType)" *ngFor="let priorityType of priority.priorityTypes">
              {{ 'devices.device.prioritize.priorityTypes.' + priorityType | translate }}
            </li>
          </ng-container>
        </drop-down>
      </div>
    </div>
  </ng-container>
  <ng-container controls>
    <button class="tertiary light" (click)="closeModal()">
      {{ 'health.networkInformation.modal.close' | translate }}
    </button>
    <button class="super-primary" (click)="setDevicePriority()" *ngIf="canEditDeviceQos">
      {{ 'devices.device.prioritize.setPriority' | translate }}
    </button>
  </ng-container>
</modal>
