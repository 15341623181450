import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { catchError, map, shareReplay, take, tap } from 'rxjs/operators';
import { INodeTaskStatus } from 'src/app/lib/interfaces/interface';
import { CustomerService } from 'src/app/lib/services/customer.service';
import { ModelRefService } from 'src/app/lib/services/modelref.service';
import { ToastService } from 'src/app/lib/services/toast.service';
import { selectNodes } from 'src/app/store/polling/polling.selector';

@Component({
  selector: 'taskstatuses',
  templateUrl: './taskstatuses.component.html',
  styleUrls: ['./taskstatuses.component.scss']
})
export class TaskStatusesComponent {
  loading: boolean = true;
  isError: boolean = false;
  open = {};
  nodeTaskStatuses$: Observable<INodeTaskStatus[]> = combineLatest([
    this.store.select(selectNodes).pipe(take(1)),
    this.customerService.locationTaskStatuses$()
  ]).pipe(
    shareReplay(),
    tap(() => (this.loading = false)),
    map(([nodes, tasks]) =>
      Object.keys(tasks).map((nodeid: string) => {
        const node = nodes.find((node) => node.id === nodeid);
        const model = this.modelRef.get(node.model);

        return {
          id: nodeid,
          name: node.nickname || node.defaultName,
          model: model.name,
          icon: model.icon,
          taskCount: tasks[nodeid].length,
          taskStatuses: tasks[nodeid].filter((task) => task.status !== 'Succeeded')
        };
      })
    ),
    catchError(() => {
      this.isError = true;
      return [];
    })
  );

  constructor(
    private store: Store,
    private customerService: CustomerService,
    private modelRef: ModelRefService,
    private toast: ToastService
  ) {}
}
