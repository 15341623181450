import { createSelector, select } from '@ngrx/store';
import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppState } from '../reducers';

export const selectLteInUse = (state: AppState) => state.lte.currentState.data?.lteServiceState === 'LTE in use';
export const selectLteNode = (state: AppState) => state.customer.locationConfigState.data?.state?.lte?.nodeId?.value;
export const selectLteImei = (state: AppState) => state.lte.hwInfo.data?.imei;
export const selectLteIccid = (state: AppState) => state.lte.hwInfo.data?.iccid;
export const selectNodeIcon = (nodeId: string) =>
  pipe(
    select(
      createSelector(selectLteInUse, selectLteNode, (inUse, lteNodeId) => [inUse, lteNodeId] as [boolean, string])
    ),
    map(([lteInUse, activeNodeId]) =>
      lteInUse && activeNodeId === nodeId ? 'assets/icons/icon-lte.svg' : 'assets/icons/icon-globe.svg'
    )
  );
export const selectLteSupportedObject = (state: AppState) => state.lte.supported.data;
export const selectLteSignalStrength = (state: AppState) => state.lte.signalStrength.data;
export const selectLteSecondaryNetworks = (state: AppState) => state.lte.networkConfiguration.data?.secondaryNetworks;
export const selectLteForced = (state: AppState) => state.lte.currentState.data?.lteState?.connectionState?.forced;
export const selectLteLinkState = (state: AppState) => state.lte.currentState.data?.lteState?.linkState;
export const selectLteApn = (state: AppState) => state.lte.currentState.data?.lteState?.configurationState?.apn;
export const selectLteServiceState = (state: AppState) =>
  state.lte.currentState.data?.lteServiceState === 'Enabled' ||
  state.lte.currentState.data?.lteServiceState === 'LTE in use';
export const selectLteServiceConfig = (state: AppState) => state.lte.currentState.data?.lteServiceConfig === 'Enabled';
export const selectLteServiceGeoIp = (state: AppState) => state.lte.currentState.data?.geoIp;
export const selectLtePersistance = (state: AppState) => ({
  config: state.lte.configuration.data?.persist,
  state: state.lte.currentState.data?.lteState?.configurationState?.persist
});
