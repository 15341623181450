import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  ViewChild
} from '@angular/core';
import * as d3 from 'd3';
import { D3ZoomEvent } from 'd3';
import { IQoeLiveChartScale } from '../../../models/charts/qoelive.chart';
import { Line } from '../../../models/objects/line';
import { Series } from '../../../models/objects/series';

@Component({
  selector: 'qoe-live-chart-zoom',
  templateUrl: './qoe-live-chart-zoom.component.html',
  styleUrls: ['./qoe-live-chart-zoom.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QoeLiveChartZoomComponent implements AfterViewInit {
  @Input() scale?: IQoeLiveChartScale;
  @ViewChild('chartElm') chartElm!: ElementRef<SVGElement>;
  @ViewChild('zoomRect') zoomRect!: ElementRef<SVGGElement>;
  @ViewChild('axisY') axisY!: ElementRef<SVGGElement>;
  @ViewChild('axisX') axisX!: ElementRef<SVGGElement>;

  id = Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, '')
    .substring(0, 5);
  margin = { top: 10, right: 80, bottom: 60, left: 100 };
  width = 100;
  height = 0;
  domainWidth = 24 * 60 * 60 * 1000; // 24 hours
  zoomRation = this.domainWidth / 60 / 1000 / 10; // max zoom - 10 minutes
  zoomTransform = { k: 0, x: 0, y: 0 };
  xAxisZoomScale?: d3.ScaleTime<number, number>;
  xAxisScale?: d3.ScaleTime<number, number>;
  yAxisScale?: d3.ScaleLinear<number, number>;
  xAxis?: d3.Axis<Date | d3.NumberValue>;
  zoom = d3.zoom().scaleExtent([1, this.zoomRation]);
  series: Series[];
  toolTip = {
    show: false,
    index: -1,
    xPos: 0,
    dataAvailable: false
  };
  dataSource: (Line & {
    alarmAwareDots: {
      x: number;
      y: number;
      alarm: boolean;
    }[];
  })[] = [];
  bisectDate = d3.bisector((d: { timestamp: number; value: number }) => d.timestamp).left;

  @Input()
  public set data(data: Line[]) {
    this.dataSource = data.map((line) => ({
      ...line,
      alarmAwareDots: []
    }));

    if (!this.chartElm?.nativeElement || !(this.dataSource?.length > 0)) {
      return;
    }

    this.series = data.map((dataLine) => dataLine.series);
    this.repaint();
  }

  constructor(private readonly cdRef: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.zoom(d3.select(this.zoomRect.nativeElement as Element));
    this.data = this.dataSource;
    this.zoom.transform(
      d3.select(this.zoomRect.nativeElement as Element),
      d3.zoomIdentity.translate(-(this.zoomRation - 1) * this.width, 0).scale(this.zoomRation)
    );
  }

  reloadToolTipIndex(event: MouseEvent): void {
    const dataItems = this.dataSource?.[0].allData ?? [];

    if (!dataItems || dataItems.length === 0) {
      this.toolTip.index = -1;
      return;
    }

    this.toolTip.xPos = event.clientX - (event.target as Element).getBoundingClientRect().left;
    const x0 = this.xAxisZoomScale?.invert(this.toolTip.xPos);
    let i = this.bisectDate(dataItems, x0, 1);

    if (!i) {
      this.toolTip.index = -1;
      return;
    }

    const d0 = dataItems[i - 1];
    const d1 = dataItems[i];

    if (x0.getTime() < d0.timestamp - 30000) {
      this.toolTip.index = -1;
      return;
    }

    // i = x0?.getTime() - d0.timestamp > d1.timestamp - x0.getTime() ? i : i - 1; // for tooltip starting in middle of 2 points
    i = !d1 || x0?.getTime() < d1.timestamp - 30000 ? i - 1 : i; // tooltip for this point starts 30s before this point, else previous data is used for tooltip

    if (Math.abs(this.toolTip.xPos - this.xAxisZoomScale(dataItems[i].timestamp)) > 5) {
      this.toolTip.index = -1;
      return;
    }

    this.toolTip.index = i;

    this.toolTip.dataAvailable = this.dataSource.reduce(
      (acc, line) => acc || line.allData[i].value === 0 || !!line.allData[i].value,
      false
    );
  }

  safari14fix(): void {
    // do nothing, but if (mousewheel) is not in html then mousewheel is not working on safari 14
  }

  @HostListener('window:resize')
  private repaint(): void {
    const svgViewport = d3.select(this.chartElm.nativeElement);
    const svgWidth = +svgViewport.attr('width') || svgViewport.node()?.clientWidth || 1;
    const svgHeight = +svgViewport.attr('height') || svgViewport.node()?.clientHeight || 1;
    const futureWidth = svgWidth - this.margin.left - this.margin.right;

    this.zoomTransform.x *= futureWidth / this.width; // to resize don't drop zoom position
    this.width = futureWidth;
    this.height = svgHeight - this.margin.top - this.margin.bottom;

    const mostRecentDate =
      this.dataSource[0]?.allData?.[this.dataSource[0].allData.length - 1]?.timestamp ?? Date.now();

    this.xAxisScale = d3
      .scaleTime()
      .domain([mostRecentDate - this.domainWidth, mostRecentDate])
      .range([0, this.width]);

    const domainMax = this.scale?.range?.[1] ?? this.maxDataSourceValue();

    this.yAxisScale = d3
      .scaleLinear()
      .domain([this.scale?.range?.[0] ?? 0, domainMax > 0 ? domainMax * 1.1 : domainMax * 0.9])
      .range([this.height, 0]);

    this.paintAxis();
    this.reloadZoom();
  }

  private maxDataSourceValue(): number {
    const maxValue = this.dataSource.reduce(
      (accDS, line) =>
        Math.max(
          accDS,
          line.allData?.reduce((accPoint, point) => Math.max(accPoint, point.value), Number.MIN_VALUE) ??
            Number.MIN_VALUE
        ),
      Number.MIN_VALUE
    );

    return maxValue === Number.MIN_VALUE ? 1 : maxValue;
  }

  private paintAxis(): void {
    const yAxisContainer = d3.select(this.axisY.nativeElement);

    this.xAxis = d3
      .axisTop(this.xAxisScale)
      .tickSize(this.height)
      .tickSizeOuter(0)
      .tickPadding(-this.height - 20);

    const yAxis = d3
      .axisLeft(this.yAxisScale)
      .tickSize(-this.width)
      .tickSizeOuter(0)
      .tickPadding(10)
      .ticks(5)
      .tickFormat((value) => `${value} ${this.scale?.symbol}`);

    yAxis(yAxisContainer);
  }

  private reloadZoom(): void {
    this.zoom
      .extent([
        [0, 0],
        [this.width, 100]
      ])
      .translateExtent([
        [0, 0],
        [this.width, 100]
      ]);

    this.zoom.transform(
      d3.select(this.zoomRect.nativeElement as Element),
      d3.zoomIdentity.translate(this.zoomTransform.x, 0).scale(this.zoomTransform.k)
    );

    this.zoom.on('zoom', () => {
      this.zoomTransform = d3.event.transform;
      this.xAxisZoomScale = (d3.event as D3ZoomEvent<SVGElement, unknown>).transform.rescaleX(this.xAxisScale);

      const xAxisContainer = d3.select(this.axisX.nativeElement);

      this.xAxis?.scale(this.xAxisZoomScale)(xAxisContainer);
      this.dataSource = this.dataSource.map((lineDef) => ({
        ...lineDef,
        fill:
          d3
            .area<{ timestamp: number; value: number }>()
            .x((d) => this.xAxisZoomScale?.(d.timestamp) ?? 0)
            .y0(this.height)
            .y1((d) => this.yAxisScale?.(d.value) ?? 0)(this.lineHideNull(lineDef)) ?? '',
        d:
          d3
            .line<{ timestamp: number; value: number }>()
            .x((d) => this.xAxisZoomScale?.(d.timestamp) ?? 0)
            .y((d) => this.yAxisScale?.(d.value) ?? 0)(this.lineHideNull(lineDef)) ?? ''
      }));

      this.toolTip.show = false;
      this.reloadPoints();
      this.cdRef.detectChanges();
    });
  }

  private lineHideNull(line: Line): { timestamp: number; value: number }[] {
    const underVisibleChart = -200; // there will never be value lover than -200

    return (
      line.allData?.reduce(
        (acc, item) => {
          if (item.value === null) {
            if (acc.length === 0) {
              // first item is null, just don't start to paint line yet
              return [];
            }
            if (acc[acc.length - 1]?.value === underVisibleChart) {
              // already under chart, don't do more point on same position
              return acc;
            }
            // first null data, go under chart with horizontal line
            item = { timestamp: acc[acc.length - 1].timestamp, value: underVisibleChart };
          } else if (acc[acc.length - 1]?.value === underVisibleChart) {
            // previous point under chart, go back to visible part with horizontal line
            return [...acc, { timestamp: item.timestamp, value: underVisibleChart }, item];
          }
          return [...acc, item];
        },
        [] as {
          timestamp: number;
          value: number;
        }[]
      ) ?? []
    );
  }

  private reloadPoints(): void {
    if (this.zoomTransform.k < 50) {
      this.dataSource = this.dataSource.map((line) => ({
        ...line,
        alarmAwareDots: []
      }));
      return;
    }

    const startDate = this.xAxisZoomScale?.invert(0).getTime() ?? 0;
    const endDate = this.xAxisZoomScale?.invert(this.width).getTime() ?? 0;

    this.dataSource = this.dataSource.map((line) => ({
      ...line,
      alarmAwareDots:
        line.allData
          ?.filter(
            (point) => point.timestamp >= startDate && point.timestamp <= endDate && (point.value || point.value === 0)
          )
          .map((point) => ({
            x: this.xAxisZoomScale(point.timestamp),
            y: this.yAxisScale(point.value),
            alarm:
              (this.scale?.above && this.scale?.thresholdValue < point.value) ||
              (!this.scale?.above && this.scale?.thresholdValue > point.value)
          })) ?? []
    }));
  }
}
