import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { selectSingleCustomerId } from 'src/app/store/customer/customer.selectors';
import { IFilteredGroup, IGroup, IRecentCustomer } from '../interfaces/groups.interface';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class GroupService {
  constructor(private api: ApiService, private store: Store) {}

  getGroups$(filter: { order: 'name ASC'; limit: number; offset: number }): Observable<IFilteredGroup[]> {
    return this.api.get(`/Groups?filter=${JSON.stringify(filter)}`);
  }

  getGroup$(id: string): Observable<IGroup> {
    return this.api.get(`/Groups/${id}`);
  }

  getGroupCustomerCount$(id: string): Observable<{ count: number }> {
    return this.api.get(`/Groups/${id}/customers/count`);
  }

  getRecentCustomers$(id: string): Observable<IRecentCustomer[]> {
    return this.api.get(`/Groups/${id}/customers/recent`);
  }

  editGroup$(id: string, data: Partial<IGroup>): Observable<IGroup> {
    return this.api.patch(`/Groups/${id}`, data);
  }

  removeGroup$(id: string): Observable<{ count: number }> {
    return this.api.delete(`/Groups/${id}`);
  }

  addCustomerRelation$(
    groupId: string
  ): Observable<{ createdAt: string; customerId: string; groupId: string; id: string }> {
    return this.store.pipe(
      selectSingleCustomerId,
      switchMap((customerId) => this.api.put(`/Groups/${groupId}/customers/rel/${customerId}`, {}))
    );
  }

  removeCustomerRelation$(groupId: string): Observable<undefined> {
    return this.store.pipe(
      selectSingleCustomerId,
      switchMap((customerId) => this.api.delete(`/Groups/${groupId}/customers/rel/${customerId}`))
    );
  }
}
