import { Component, Input } from '@angular/core';

@Component({
  selector: '[pathVisual]',
  templateUrl: './path.component.html',
  styleUrls: ['./path.component.scss']
})
export class PathVisualComponent {
  @Input()
  pathVisual: any;

  @Input()
  style: any = {};

  constructor() {}
}
