<div class="row" id="conf-subscription">
  <div class="col-xs-12">
    <collapsiblebox [show]="expand" [collapsible]="true" (toggle)="toggleExpand()">
      <div header>
        <div class="title">
          {{ 'configurations.subscription.title' | translate }}
          <icon name="icon-spinner" class="size-1_5 spinner" [class.loading]="loading"></icon>
        </div>
      </div>
      <div container *ngIf="expand">
        <div class="columns">
          <div class="column">
            <div class="row">
              <div class="key">{{ 'configurations.subscription.servicelevel' | translate }}</div>
              <div class="value">
                <slider-toggle-mobile-aware
                  [options]="serviceLevelSwitches"
                  [editable]="plume.isSomeAdminRole() && !membership?.ratePlans?.length && !isUprise"
                  (toggle)="action('servicelevel', $event)"
                ></slider-toggle-mobile-aware>
              </div>
            </div>

            <div class="row" *ngIf="membership?.ratePlans?.length">
              <div class="key">{{ 'configurations.subscription.rateplan' | translate }}</div>
              <div class="value">
                <slider-toggle-mobile-aware
                  [options]="ratePlanSwitches"
                  [editable]="ratePlanSwitchesEditable"
                  (toggle)="action('ratePlan', $event)"
                ></slider-toggle-mobile-aware>
              </div>
            </div>

            <div class="row">
              <div class="key">{{ 'configurations.subscription.status' | translate }}</div>
              <div class="value">
                {{ membership.status | translate }}
              </div>
            </div>
            <div class="row">
              <div class="key">{{ 'configurations.subscription.rateplanchargeid' | translate }}</div>
              <div class="value">
                {{ membership.ratePlanChargeId || 'N/A' }}
              </div>
            </div>
            <div class="row">
              <div class="key">{{ 'configurations.subscription.term' | translate }}</div>
              <div class="value">
                {{ membership.term || 'N/A' }}
              </div>
            </div>
            <div class="row">
              <div class="key">{{ 'configurations.subscription.effective' | translate }}</div>
              <div class="value">
                {{ formatDate(membership.effectiveAt) || 'N/A' }}
              </div>
            </div>
            <div class="row">
              <div class="key">{{ 'configurations.subscription.expires' | translate }}</div>
              <div class="value">
                {{ formatDate(membership.expiresAt) || 'N/A' }}
              </div>
            </div>
            <div class="row top">
              <div class="key">{{ 'configurations.subscription.authorizations' | translate }}</div>
              <div class="value">
                <locationauthorization></locationauthorization>
              </div>
            </div>
          </div>
        </div>
      </div>
    </collapsiblebox>
  </div>
</div>
