<div class="row" [class.dim]="selected !== device?.id && selected !== null">
  <div class="col-xs-12">
    <box class="wider" [class.selected]="expand">
      <div header (click)="expand = !expand">
        <span>
          <icon name="icon-arrow-down" [class.rotate180]="expand"></icon>
          {{ 'health.networkPerformance.deviceHealthAlarm.deviceHealthAlarm' | translate }}
        </span>
        <div class="health" *ngIf="!expand">
          <span class="txt">{{ 'health.networkPerformance.deviceHealthAlarm.healthRating' | translate }}:</span>
          <health-line [value]="device.health?.score" [status]="device.health?.status"></health-line>
          <span class="description" [ngClass]="'t-' + device?.health?.status" *ngIf="device?.health?.status">
            {{ device?.health?.status | translate }}
          </span>
        </div>
      </div>
      <div container>
        <div class="data">
          <div class="column">
            <icon *ngIf="!device?.iconV3" class="size-2" [path]="getIconPath()"></icon>
            <div class="icon-container" *ngIf="device?.iconV3">
              <icon [path]="getIconV3Path()" class="size-2"></icon>
            </div>
            <div class="name">
              <span class="title">{{ device?.nickname || device?.name }}</span>
              <span class="type">{{ device?.kind?.type?.brand }}</span>
            </div>
          </div>
          <div class="column mobile" [class.show]="expand">
            <button
              [class.selected]="resetActive"
              [class.busy]="resetActive"
              (click)="handleButton('Reset Device Connection')"
            >
              <span *ngIf="!resetActive">
                {{ 'health.networkPerformance.deviceHealthAlarm.resetDeviceConnection' | translate }}
              </span>
              <span *ngIf="resetActive">
                {{ 'health.networkPerformance.deviceHealthAlarm.resetInProgress' | translate }}
              </span>
              <div *ngIf="resetActive" class="progressbar"><div class="indeterminate"></div></div>
            </button>
          </div>
        </div>
        <div class="details" [class.show]="expand">
          <div class="column">
            <leaftoroot [device]="device" [nodes]="nodes?.nodes"></leaftoroot>
            <button
              [class.selected]="resetActive"
              [class.busy]="resetActive"
              (click)="handleButton('Reset Device Connection')"
            >
              <div *ngIf="!resetActive">
                {{ 'health.networkPerformance.deviceHealthAlarm.resetDeviceConnection' | translate }}
              </div>
              <div *ngIf="resetActive">
                {{ 'health.networkPerformance.deviceHealthAlarm.resetInProgress' | translate }}
              </div>
              <div *ngIf="resetActive" class="progressbar"><div class="indeterminate"></div></div>
            </button>
          </div>
          <div class="column wide">
            <div class="info">
              <div class="key">{{ 'health.networkPerformance.deviceHealthAlarm.healthRating' | translate }}</div>
              <div class="value">
                <div class="group">
                  <health-line [value]="device.health?.score" [status]="device.health?.status"></health-line>
                  <div [ngClass]="'t-' + device?.health?.status" *ngIf="device?.health?.status">
                    {{ device?.health?.status | translate }}
                  </div>
                </div>
              </div>
            </div>
            <div class="info" *ngIf="device?.alerts?.length != 0">
              <div class="key">{{ 'health.networkPerformance.deviceHealthAlarm.deviceHealthAlarm' | translate }}</div>
              <div class="value">
                {{ 'health.networkPerformance.deviceHealthAlarm.lowHealthAlarm' | translate }}
              </div>
            </div>
            <div class="info">
              <div class="key">{{ 'health.networkPerformance.deviceHealthAlarm.rssi' | translate }}</div>
              <div class="value">
                <div class="group">
                  <health-dots [rssiOrQoe]="device"></health-dots>
                  <div class="num">{{ device?.rssi | number: '1.0-2' }} dBm</div>
                </div>
              </div>
            </div>
            <div class="info">
              <div class="key">{{ 'health.networkPerformance.deviceHealthAlarm.interference' | translate }}</div>
              <div class="value">
                <div class="group">
                  <health-ball [value]="device?.interference"></health-ball>
                  <div class="num">{{ device?.interference | number: '1.0-2' }}%</div>
                </div>
              </div>
            </div>
            <div class="info" *ngIf="device?.medium == 'wifi' && device?.freqBand && device?.channel">
              <div class="key">{{ 'health.networkPerformance.deviceHealthAlarm.currentConnection' | translate }}</div>
              <div class="value">{{ device?.freqBand }}, Ch {{ device?.channel }}</div>
            </div>

            <div class="info">
              <div class="key">{{ 'health.networkPerformance.deviceHealthAlarm.category' | translate }}</div>
              <div class="value">{{ device?.kind?.category | titlecase }}</div>
            </div>

            <div class="info">
              <div class="key">{{ 'health.networkPerformance.deviceHealthAlarm.bandsSupported' | translate }}</div>
              <div class="value">
                <span *ngIf="!device?.capabilities?.radio24 && !device?.capabilities?.radio50">
                  {{ 'health.networkPerformance.deviceHealthAlarm.none' | translate }}
                </span>
                <span *ngIf="device?.capabilities?.radio24 && !device?.capabilities?.radio50">2.4 GHz</span>
                <span *ngIf="!device?.capabilities?.radio24 && device?.capabilities?.radio50">5 GHz</span>
                <span *ngIf="device?.capabilities?.radio24 && device?.capabilities?.radio50">2.4 GHz & 5 GHz</span>
              </div>
            </div>
          </div>
        </div>
        <div class="device-history" *ngIf="expand">
          <div class="header" (click)="toggleHistory()">
            <icon name="icon-arrow-down" class="size-0_8" [class.rotate180]="deviceHistory"></icon>
            <div>{{ 'health.networkPerformance.deviceHealthAlarm.viewHistory' | translate }}</div>
          </div>
          <div class="details" [class.show]="deviceHistory">
            <div class="graph rssi24">
              <div class="caption">{{ 'health.networkPerformance.deviceHealthAlarm.historyRssi' | translate }}</div>
              <div class="history" *ngIf="device.historyDevice">
                <div class="chart">
                  <div
                    class="tick"
                    [style.background]="tick.color"
                    [style.borderColor]="tick.border"
                    *ngFor="let tick of device.historyDevice"
                  >
                    <div class="time">{{ tick.time }}</div>
                    <div class="info">{{ tick.state }}</div>
                  </div>
                </div>
                <div class="axis">
                  <div class="start">{{ axis24 }}</div>
                  <div class="middle">{{ axis12 }}</div>
                  <div class="end">{{ axisNow | translate }}</div>
                </div>
              </div>
              <div class="history" *ngIf="!device.historyDevice">
                {{ 'health.networkPerformance.deviceHealthAlarm.loading' | translate }}
              </div>
            </div>
            <div class="graph busyness">
              <div class="caption">{{ 'health.networkPerformance.deviceHealthAlarm.historyBusyness' | translate }}</div>
              <div class="history" *ngIf="device.busyness">
                <div class="chart">
                  <div
                    class="tick"
                    [style.backgroundColor]="tick.color"
                    [style.borderColor]="tick.border"
                    *ngFor="let tick of device.busyness"
                  >
                    <div class="time">{{ tick.time }}</div>
                    <div class="info">{{ tick.bw }}</div>
                  </div>
                </div>
                <div class="axis">
                  <div class="start">{{ axis24 }}</div>
                  <div class="middle">{{ axis12 }}</div>
                  <div class="end">{{ axisNow | translate }}</div>
                </div>
              </div>
              <div class="history" *ngIf="!device.busyness">
                {{ 'health.networkPerformance.deviceHealthAlarm.loading' | translate }}
              </div>
            </div>
            <div class="graph deviceOnline">
              <div class="caption">{{ 'health.networkPerformance.deviceHealthAlarm.historyOnline' | translate }}</div>
              <div class="history" *ngIf="device.nodeConnections">
                <div class="chart">
                  <div
                    class="tick"
                    [style.backgroundColor]="tick.color"
                    [style.borderColor]="tick.border"
                    *ngFor="let tick of device.nodeConnections"
                  >
                    <div class="time">{{ tick.time }}</div>
                    <div class="info">{{ tick.name }}</div>
                  </div>
                </div>
                <div class="axis">
                  <div class="start">{{ axis24 }}</div>
                  <div class="middle">{{ axis12 }}</div>
                  <div class="end">{{ axisNow | translate }}</div>
                </div>
              </div>
              <div class="history" *ngIf="!device.nodeConnections">
                {{ 'health.networkPerformance.deviceHealthAlarm.loading' | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </box>
  </div>
</div>
