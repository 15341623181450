import { Component, OnInit, OnDestroy } from '@angular/core';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { GeneralHelper } from 'src/app/lib/helpers/general.helper';
import { NodeService } from 'src/app/lib/services/nodes.service';
import { selectLocationInternet } from 'src/app/store/polling/polling.selector';
import { Store } from '@ngrx/store';

@Component({
  templateUrl: './speedtests.component.html',
  styleUrls: ['./speedtests.component.scss']
})
export class SpeedtestsComponent implements OnInit, OnDestroy {
  onboardingSubscription: any;

  nodes: any = [];
  message: string;
  onboarded: string = null;
  ui: string = '';
  helper: GeneralHelper = new GeneralHelper();

  constructor(
    private plume: PlumeService,
    private mixpanel: MixpanelService,
    private nodeService: NodeService,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.mixpanel.storeEvent('SPEED_TEST_SCREEN');

    this.init();
    this.ui = this.plume.getUI();
    this.onboardingSubscription = this.store.select(selectLocationInternet).subscribe((response: any) => {
      if (response) {
        this.isOnboarded(response);
      }
    });
  }

  init(): void {
    this.message = 'speedtests.messageLoading';
    this.nodeService.getAll$().subscribe((response: any) => {
      const nodes = [];

      if (response.nodes.length) {
        response.nodes.forEach((node: any) => {
          node.icon = this.detectIcon(node.backhaulType);
          node.isGateway = this.helper.isGateway(node.id, response.nodes);

          if (node.model === 'VFSC_VOX30_IT') {
            node.disableSpeedTest = true;
          }

          nodes.push(node);
        });
      }

      this.sort(nodes);
    });
  }

  track(index: number, node: any): string {
    return node.id;
  }

  isOnboarded(response: any): void {
    try {
      const permissions = this.plume.getPermissions();

      if (permissions.uiFeatures.overrideOnboarded) {
        this.onboarded = 'complete';
      } else {
        if (['OnboardingComplete', 'PodsAdded'].includes(response.summary.onboardingStatus)) {
          this.onboarded = 'complete';
        } else {
          this.onboarded = 'uncomplete';
        }
      }
    } catch (err) {
      this.onboarded = 'uncomplete';
    }
  }

  sort(nodes: any[]): void {
    this.nodes = [];

    const online = [];
    const offline = [];

    if (nodes.length) {
      nodes.forEach((node: any) => {
        if (node.connectionState === 'connected') {
          online.push(node);
        } else {
          offline.push(node);
        }
      });

      online.sort((node: any) => (node.isGateway ? -1 : 1));
      offline.sort((node: any) => (node.isGateway ? -1 : 1));

      this.nodes = [...online, ...offline];
    } else {
      this.message = 'speedtests.messageNoPods';
    }
  }

  detectIcon(type: string): string {
    switch (type) {
      case 'ethernet':
        return 'super-pod';
      case 'wifi':
        return 'pod';
      default:
        return 'router';
    }
  }

  ngOnDestroy(): void {
    if (this.onboardingSubscription) {
      this.onboardingSubscription.unsubscribe();
    }
  }
}
