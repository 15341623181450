import { Component, Input } from '@angular/core';
import { Series } from 'src/app/lib/d3/models/objects/series';

@Component({
  selector: 'seriesVisual',
  templateUrl: './series.component.html',
  styleUrls: ['./series.component.scss']
})
export class SeriesVisualComponent {
  @Input()
  series: Series[];

  @Input()
  threshold: string;

  @Input()
  alarm: string;

  constructor() {}
}
