<div class="timelines" #canvas>
  <div class="loading" [class.show]="!events || eventCount === 0 || overlayMessage">
    <div *ngIf="!overlayMessage && !events" class="text">{{ 'aisecurity.chart.loadingEvents' | translate }}</div>
    <div *ngIf="!overlayMessage && events && eventCount === 0" class="text">
      {{ 'aisecurity.chart.noEvents' | translate }}
    </div>
    <div *ngIf="overlayMessage" class="text">{{ overlayMessage | translate }}</div>
  </div>
  <svg>
    <defs>
      <linearGradient id="gradient" x1="0" x2="0" y1="0" y2="1">
        <stop class="stop1" offset="0%" />
        <stop class="stop2" offset="100%" />
      </linearGradient>
      <filter id="shadow" x="-10%" y="-1%" width="120%" height="110%">
        <feGaussianBlur in="SourceAlpha" stdDeviation="1" result="blur" />
        <feOffset in="blur" dx="0" dy="1" result="offsetBlur" />
        <feFlood flood-color="#000000" flood-opacity="0.2" result="offsetColor" />
        <feComposite in="offsetColor" in2="offsetBlur" operator="in" result="offsetComposite" />
        <feBlend in="SourceGraphic" in2="offsetComposite" mode="normal" />
      </filter>
    </defs>
    <svg:g [attr.transform]="'translate(' + margins.left + ',' + margins.top + ')'">
      <svg:text
        [attr.x]="axis.x"
        [attr.y]="axis.y - 10"
        [attr.dx]="axis.dx"
        [attr.text-anchor]="axis.anchor"
        *ngFor="let axis of xAxis"
      >
        {{ axis.text | translate }}
      </svg:text>
      <svg:text
        [attr.x]="axis.x"
        [attr.y]="axis.y"
        [attr.dy]="axis.dy"
        [attr.text-anchor]="axis.anchor"
        *ngFor="let axis of yAxis"
      >
        {{ axis.text | translate }}
      </svg:text>
      <svg:rect
        class="border"
        x="0"
        y="-5"
        [attr.width]="chart.calculateWidth() || 0"
        [attr.height]="chart.calculateHeight() + 10 || 0"
      ></svg:rect>
      <svg:g
        *ngFor="let day of data"
        class="day"
        [class.hover]="day.hover"
        [class.noclick]="dayView"
        (mouseenter)="positionTooltipDay(day, true)"
        (mouseleave)="positionTooltipDay(day, false)"
        (click)="changeView(day, $event)"
      >
        <svg:rect
          class="region"
          [attr.x]="day.x"
          [attr.y]="day.y - 5"
          [attr.width]="day.width"
          [attr.height]="day.height + 10"
        ></svg:rect>
        <svg:line
          class="grid"
          [attr.x1]="day.center"
          [attr.y1]="day.y - 5"
          [attr.x2]="day.center"
          [attr.y2]="day.height + 5"
        ></svg:line>
        <svg:circle
          *ngFor="let event of day.events"
          class="event"
          [ngClass]="event.type"
          [attr.cx]="day.center"
          [attr.cy]="event.y"
          [attr.r]="event.type == 'cluster' ? (event.hover ? 13 : 10) : event.hover ? 8 : 5"
          (mouseenter)="positionTooltipEvent(day, event, true)"
          (mouseleave)="positionTooltipEvent(day, event, false)"
          (click)="event.type == 'cluster' ? showClusterEventList($event, event, day) : selectEvent(event, $event)"
        />
        <svg:rect
          class="placeholder"
          [attr.x]="day.x"
          [attr.y]="day.height + 5"
          [attr.width]="day.width"
          [attr.height]="margins.bottom - 5"
        ></svg:rect>
        <svg:g class="menu" (click)="showEventList($event, day)">
          <svg:rect
            [attr.x]="day.x"
            [attr.y]="day.height + 5"
            [attr.width]="day.width"
            [attr.height]="margins.bottom - 5"
          ></svg:rect>
          <svg:g
            *ngIf="day.allEvents.length === 1"
            class="icon"
            icon="assets/icons/icon-info.svg"
            [inline]="true"
            [width]="(day.width > margins.bottom - 5 ? (margins.bottom - 5) / 2 : day.width / 2)"
            [height]="(day.width > margins.bottom - 5 ? (margins.bottom - 5) / 2 : day.width / 2)"
            [attr.transform]="
              'translate(' +
              (day.x + day.width / 2 - (day.width > margins.bottom - 5 ? (margins.bottom - 5) / 4 : day.width / 4)) +
              ', ' +
              (day.height +
                5 +
                (margins.bottom - 5) / 2 -
                (day.width > margins.bottom - 5 ? (margins.bottom - 5) / 4 : day.width / 4)) +
              ')'
            "
          ></svg:g>
          <svg:g
            *ngIf="day.allEvents.length > 1"
            class="icon"
            icon="assets/icons/icon-more.svg"
            [inline]="true"
            [width]="(day.width > margins.bottom - 5 ? (margins.bottom - 5) / 2 : day.width / 2)"
            [height]="(day.width > margins.bottom - 5 ? (margins.bottom - 5) / 2 : day.width / 2)"
            [attr.transform]="
              'translate(' +
              (day.x + day.width / 2 - (day.width > margins.bottom - 5 ? (margins.bottom - 5) / 4 : day.width / 4)) +
              ', ' +
              (day.height +
                5 +
                (margins.bottom - 5) / 2 -
                (day.width > margins.bottom - 5 ? (margins.bottom - 5) / 4 : day.width / 4)) +
              ')'
            "
          ></svg:g>
        </svg:g>
      </svg:g>
    </svg:g>
  </svg>
  <div id="tooltip-day" class="tooltip-day" [class.show]="tooltipDay.show" [ngStyle]="tooltipDay.style">
    {{ tooltipDay.text }}
  </div>
  <div
    id="tooltip-event"
    class="tooltip-event"
    [class.show]="tooltipEvent.show && tooltipEvent?.data?.eventType != 'cluster'"
    [ngStyle]="tooltipEvent.style"
  >
    <div *ngFor="let property of tooltipEvent?.data?.eventData?.tooltipProperties">
      {{ property.key | translate }}: <b>{{ property.value | translate }}</b>
    </div>
  </div>
  <div
    id="tooltip-cluster"
    class="tooltip-event"
    [class.show]="tooltipEvent.show && tooltipEvent.data?.eventType == 'cluster'"
    [ngStyle]="tooltipEvent.style"
  >
    <div>
      {{ 'timelines.chart.to' | translate: { start: tooltipEvent?.data?.time, end: tooltipEvent?.data?.endTime } }}
    </div>
    <div>{{ tooltipEvent?.data?.clusterSize }} {{ 'timelines.chart.events' | translate }}</div>
  </div>
  <drop-down-icon class="small" #eventDropDown [ngStyle]="eventsList.style">
    <li
      class="rowWithCircle"
      #eventDropDownItem
      *ngFor="let event of eventsList?.data?.allEvents"
      (click)="selectEvent(event)"
    >
      <div class="circle event" [ngClass]="event?.eventType"></div>
      <div>{{ event?.eventData?.eventTime }} - {{ event?.eventData?.eventName | translate }}</div>
    </li>
  </drop-down-icon>
</div>
