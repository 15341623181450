import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';
import { IDevice, ITrafficClassStatsTotal } from 'src/app/lib/interfaces/interface';
import { LoggingService } from 'src/app/lib/services/logging.service';
import { SpeedConverterService } from 'src/app/lib/services/speed-converter.service';
import { ToastService } from 'src/app/lib/services/toast.service';
import { TrafficService } from 'src/app/lib/services/traffic.service';
import { selectDevices } from 'src/app/store/polling/polling.selector';
import { IDeviceWithTrafficUsage } from './traffic-pie-monitor-device/traffic-pie-monitor-device.component';

@Component({
  selector: 'traffic-pie-monitor',
  templateUrl: './traffic-pie-monitor.component.html',
  styleUrls: ['./traffic-pie-monitor.component.scss']
})
export class TrafficPieMonitorComponent {
  selectedTime$ = new BehaviorSubject<'15m' | '24h' | '7d' | '30d'>('30d');
  showNonRealtime$ = new BehaviorSubject<boolean>(true);
  timeItems$ = this.selectedTime$.pipe(
    map((selectedValue) =>
      [
        { value: '15m' as const, translation: 'traffic.m15' },
        { value: '24h' as const, translation: 'traffic.h24' },
        { value: '7d' as const, translation: 'traffic.d7' },
        { value: '30d' as const, translation: 'traffic.d30' }
      ].map((item) => ({ ...item, selected: item.value === selectedValue }))
    )
  );

  devices$ = this.trafficUsageDevices$();

  constructor(
    private trafficService: TrafficService,
    private store: Store,
    private toast: ToastService,
    private log: LoggingService,
    private speedConverter: SpeedConverterService,
    private translate: TranslateService
  ) {}

  changeTime(event: '15m' | '24h' | '7d' | '30d'): void {
    this.selectedTime$.next(event);
  }

  deviceTrackBy(_: number, item: any): string {
    return item.mac;
  }

  private timeBefore(time: moment.Moment, value: '15m' | '24h' | '7d' | '30d'): moment.Moment {
    switch (value) {
      case '15m':
        return time.add(-15, 'minutes');
      case '24h':
        return time.add(-24, 'hours');
      case '7d':
        return time.add(-7, 'days');
      case '30d':
        return time.add(-30, 'days');
    }
  }

  private trafficUsageDevices$(): Observable<IDeviceWithTrafficUsage[]> {
    return combineLatest([
      this.store.select(selectDevices),
      this.selectedTime$.pipe(
        distinctUntilChanged(),
        switchMap((time) =>
          this.trafficService
            .trafficClassStats$('total', this.timeBefore(moment(), time).toISOString(), moment().toISOString())
            .pipe(
              startWith({ loading: true as const }),
              catchError((error) => {
                this.log.error(error?.error?.error?.message ?? 'API error', [error]);
                this.toast.error('timelines.reboot.unknownError', 'customer.error');
                return of({ granularity: 'total', startTime: '', devices: [] } as ITrafficClassStatsTotal);
              })
            )
        )
      ),
      this.showNonRealtime$.pipe(distinctUntilChanged())
    ]).pipe(
      map(([devices, data, showNonRealtime]) =>
        devices?.map((device) => this.trafficUsageDevice(data, device, showNonRealtime))
      ),
      map((devices) =>
        devices?.sort((a, b) => {
          if (a.trafficUsage && !b.trafficUsage) {
            return -1;
          }
          if (!a.trafficUsage && b.trafficUsage) {
            return 1;
          }
          if (a.connectionState === 'connected' && b.connectionState !== 'connected') {
            return -1;
          }
          if (a.connectionState !== 'connected' && b.connectionState === 'connected') {
            return 1;
          }
          const nameA = (a.nickname || a.name).toUpperCase();
          const nameB = (b.nickname || b.name).toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        })
      )
    );
  }

  private trafficUsageDevice(
    rawData: ITrafficClassStatsTotal | { loading: boolean },
    device: IDevice,
    showNonRealtime: boolean
  ): IDeviceWithTrafficUsage {
    if ('loading' in rawData) {
      return { ...device, loading: true };
    }

    const data = { ...rawData };

    if (!showNonRealtime) {
      data.devices = data.devices.map((device) => ({
          ...device,
          trafficClasses: device.trafficClasses.filter((trafficClass) => trafficClass.name !== 'other')
        }));
    }

    const trafficClasses = data.devices.find((trafficDevice) => trafficDevice.mac === device.mac)?.trafficClasses;

    const totalTraffic = this.sum(
      trafficClasses?.map((trafficClass) =>
        trafficClass.stats?.inNum?.length && trafficClass.stats?.outNum?.length
          ? trafficClass.stats?.inNum[0] + trafficClass.stats?.outNum[0]
          : 0
      ) ?? []
    );

    if (!trafficClasses?.length || !totalTraffic) {
      return device;
    }

    const trafficUsage = trafficClasses.map((trafficClass) => {
      const legend = this.trafficService.legend.find(
        (legend) => legend.id === trafficClass.name || legend.alternativeId === trafficClass.name
      );

      const percent =
        ((trafficClass.stats?.inNum?.length && trafficClass.stats?.outNum?.length
          ? trafficClass.stats?.inNum?.[0] + trafficClass.stats?.outNum?.[0]
          : 0) /
          totalTraffic) *
        100;

      return {
        value: percent,
        color: legend?.color,
        title: legend?.label,
        totalUsage: this.speedConverter.formatValue(
          this.speedConverter.convertToOptimalStorageValue(
            trafficClass.stats?.inNum?.[0] + trafficClass.stats?.outNum?.[0],
            'B',
            1
          ),
          'short'
        ),
        percentage: Math.round(percent),
        qoeScore: trafficClass.stats?.score?.[0],
        outer: [
          {
            value: Math.round((100 * trafficClass.stats.good?.[0]) / trafficClass.stats.sampleCount[0]) || 0,
            color: '#17E3AE',
            title: this.translate.instant('traffic.good')
          },
          {
            value: Math.round((100 * trafficClass.stats?.unstable?.[0]) / trafficClass.stats?.sampleCount[0]) || 0,
            color: '#FFC500',
            title: this.translate.instant('traffic.moderate')
          },
          {
            value: Math.round((100 * trafficClass.stats?.bad?.[0]) / trafficClass.stats?.sampleCount[0]) || 0,
            color: '#FA1478',
            title: this.translate.instant('traffic.bad')
          }
        ]
      };
    });

    return { ...device, trafficUsage };
  }

  private sum(arr: number[]): number {
    return arr.reduce((partialSum, a) => partialSum + a, 0);
  }
}
