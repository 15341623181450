<div class="table">
  <div class="header">
    <div class="headers" [ngClass]="header.key" *ngFor="let header of headers">
      <div class="text">
        {{ header.value | translate }}
      </div>
    </div>
  </div>
  <div class="scroll-area">
    <div class="locations" *ngFor="let location of list">
      <div class="locationID">{{ location.id }}</div>
      <div class="activeVersionMatrixId">{{ location.activeVersionMatrixId }}</div>
      <div class="shard">{{ location.shard }}</div>
      <div class="groups">{{ location.groups?.join(', ') }}</div>
      <div class="models">{{ location.nodeModels?.join(', ') }}</div>
    </div>
  </div>
</div>
