import { Component, EventEmitter, Input, Output, OnChanges } from '@angular/core';

@Component({
  selector: 'filterdropdown',
  templateUrl: './filterdropdown.component.html',
  styleUrls: ['./filterdropdown.component.scss']
})
export class FilterdropdownComponent implements OnChanges {
  @Input()
  filters: any;

  @Input()
  objects: any;

  @Output()
  update: any = new EventEmitter<string>();

  @Output()
  selectedFilters = new EventEmitter<any>();

  filterOptionsArray: any[] = [];
  filterto: any[] = [];

  constructor() {}

  ngOnChanges(): void {
    setTimeout(() => {
      this.updateFilter();
    }, 50);
  }

  initFilters(event: any): void {
    this.filters.forEach((filter: any) => {
      if (event === filter) {
        filter.expand = event.expand;
      } else {
        filter.expand = false;
      }
    });
  }

  updateFilter(): void {
    this.filters.sort((a, b) => a.order - b.order);

    const devices = this.objects.filter((obj: any) => {
      let include: boolean = true;

      this.filters.forEach((filter: any) => {
        const res = filter.key.split('.');
        let val: any;
        let otherCount = 0;

        if (res.length === 1) {
          val = obj[res[0]];
        } else if (res.length === 2) {
          val = obj[res[0]][res[1]];
        } else if (res.length === 3) {
          val = obj[res[0]][res[1]][res[2]];
        }

        if (filter.type === 'tristate') {
          if (filter.state !== -1 && filter.state !== null) {
            include = include && filter.state === val;
          }

          if (filter.state === -1) {
            filter.filterStates.forEach((state: any) => {
              if (state.value === val) {
                otherCount++;
              }
            });

            include = !otherCount;
          }
        }
      });

      return include;
    });

    this.update.emit(devices);

    this.selectedFilters.emit(this.filters.filter((filter: any) => filter.state !== null));
  }

  disableFilter(option: any): void {
    this.filters.forEach((filter: any) => {
      if (filter.option === option) {
        filter.state = null;
      }
    });

    this.updateFilter();
  }
}
