import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChildren,
  QueryList,
  ElementRef,
  AfterViewInit,
  ViewChild,
  HostListener,
  OnInit
} from '@angular/core';
import { PlumeService } from 'src/app/lib/services/plume.service';

@Component({
  selector: 'networkperformance',
  templateUrl: './networkperformance.component.html',
  styleUrls: ['./networkperformance.component.scss']
})
export class NetworkperformanceComponent implements OnInit, AfterViewInit {
  @Input()
  status: any;

  @Input()
  selected: any;

  @Input()
  alerts: any;

  @Input()
  overallScore: number = 0;

  @Output()
  section: EventEmitter<string> = new EventEmitter<string>();

  @ViewChildren('circle') circles: QueryList<ElementRef>;
  @ViewChild('center') center: ElementRef;
  @ViewChild('diagram') diagram: ElementRef;

  updateSubscription: any;
  permissions: any;

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    if (window.innerWidth > 1024) {
      const angle = 360 / this.circles.length;
      const offset = this.diagram.nativeElement.offsetWidth;
      const size = offset / 4;

      this.diagram.nativeElement.style.height = offset + 'px';
      this.center.nativeElement.style.width = size + 'px';
      this.center.nativeElement.style.height = size + 'px';

      this.circles.forEach((circle: ElementRef, index: number) => {
        circle.nativeElement.style.height = size + 'px';
        circle.nativeElement.style.width = size + 'px';

        const y = Math.sin(angle * index * Math.PI) * (offset / 3);
        const x = Math.cos(angle * index * Math.PI) * (offset / 3);

        circle.nativeElement.style.top = y + 'px';
        circle.nativeElement.style.left = x + 'px';
      });
    } else {
      this.diagram.nativeElement.style.height = '';
      this.center.nativeElement.style.width = '';
      this.center.nativeElement.style.height = '';

      this.circles.forEach((circle: ElementRef) => {
        circle.nativeElement.style.height = '';
        circle.nativeElement.style.width = '';
        circle.nativeElement.style.top = '';
        circle.nativeElement.style.left = '';
      });
    }
  }

  constructor(private plume: PlumeService) {}

  ngOnInit(): void {
    this.permissions = this.plume.getPermissions();
  }

  ngAfterViewInit(): void {
    this.onResize();
  }

  select(type: string): void {
    this.section.emit(type);
  }

  anyAlerts(): boolean {
    if (
      (this.alerts.extenderspeed && this.alerts.extenderspeed.length > 0) ||
      (this.alerts.extenderspeed && this.alerts.networkspeed.length > 0) ||
      (this.alerts.deviation && this.alerts.deviation.status === true)
    ) {
      return true;
    } else {
      return false;
    }
  }
}
