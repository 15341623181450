import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { SlideTogglerItems } from 'src/app/lib/interfaces/interface';

@Component({
  selector: 'slider-toggle-mobile-aware',
  templateUrl: './slider-toggle-mobile-aware.component.html',
  styleUrls: ['./slider-toggle-mobile-aware.component.scss']
})
export class SliderToggleMobileAwareComponent<T> implements OnChanges {
  @Input()
  options: SlideTogglerItems<T> = [];

  @Input()
  editable: boolean = true;

  @Input()
  confirmChange: { title?: string; message?: string; confirmButton?: string; cancelButton?: string } = null;

  @Output()
  toggle = new EventEmitter<T>();

  selectedOption: SlideTogglerItems<T>[0] = null;
  markedOption: SlideTogglerItems<T>[0] = null;

  @HostBinding('class')
  mode: 'slidetoggler' | 'dropdown' = null;

  @ViewChild('slidetoggler', { read: ElementRef })
  slidetoggler: ElementRef<HTMLDivElement>;

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    if (!this.slidetoggler) {
      this.mode = 'dropdown';
      return;
    }

    const component = this.elm.nativeElement;
    const toggler = this.slidetoggler.nativeElement;

    toggler.parentElement.style.maxWidth = toggler.getBoundingClientRect().width + 'px';

    if (toggler.getBoundingClientRect().width > component.getBoundingClientRect().width) {
      this.mode = 'dropdown';
    } else {
      this.mode = 'slidetoggler';
    }
  }

  constructor(private elm: ElementRef<HTMLDivElement>) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options?.currentValue?.length) {
      const options = changes.options.currentValue;

      this.selectedOption = options.find((option) => option.selected);
      this.markedOption = options.find((option) => option.marked);

      setTimeout(() => {
        this.onResize();
      }, 50);
    }
  }

  select(option: SlideTogglerItems<T>[0]): void {
    this.options.forEach((opt) => (opt.selected = false));

    option.selected = true;

    this.selectedOption = option;

    this.toggle.emit(option.value);
  }
}
