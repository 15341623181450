<modal mode="compact" (overlayAction)="closeModal()">
  <ng-container title>
    {{ 'devices.device.steer.deviceTOSModalTitle' | translate }}
    <ng-container>
      <button class="action small resetTos" (click)="resetTos()" *ngIf="plume.isSomeAdminRole()">
        {{ 'health.networkInformation.modal.resetTOS' | translate }}
      </button>
    </ng-container>
  </ng-container>

  <ng-container content>
    <div class="loading-content" *ngIf="deviceTOSLoading">
      <icon name="icon-spinner" class="size-2 spinner loading"></icon>
    </div>
    <ngx-json-viewer [expanded]="true" [json]="deviceTOS" *ngIf="!deviceTOSLoading"></ngx-json-viewer>
  </ng-container>
  <ng-container controls>
    <button class="tertiary light" (click)="closeModal()">
      {{ 'health.networkInformation.modal.close' | translate }}
    </button>
  </ng-container>
</modal>
