import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DataWithPosition, GraphDataSetComputed } from '../graph.interface';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[flm-graph-line]',
  templateUrl: './graph-line.component.html',
  styleUrls: ['./graph-line.component.scss'],
})
export class GraphLineComponent {
  @Input() dataSet?: GraphDataSetComputed;
  @Output() pointHover = new EventEmitter<DataWithPosition | undefined>();

  isNumber(num: number): boolean {
    return !Number.isNaN(num) && Number.isFinite(num);
  }

  hover(data: DataWithPosition | undefined): void {
    this.pointHover.emit(data);
  }
}
