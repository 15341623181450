<spacer>{{ 'netops.supportTools' | translate }}</spacer>
<div class="container-fluid">
  <div class="row">
    <div class="col-xs-12">
      <div class="title">{{ 'netops.support.logPull' | translate }}</div>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12">
      <div class="row">
        <div class="col-xs-2 key">{{ 'netops.support.inputLogPullURL' | translate }}:</div>
        <div class="col-xs-10 value">
          <div class="pill-hollow">
            <input
              [formControl]="logPullFormControl"
              type="text"
              placeholder="{{ 'netops.support.url' | translate }}"
            />
          </div>
          <button class="action medium" [class.disabled]="loadingLogPull" (click)="downloadLogPull()">
            {{ 'netops.support.download' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
