import { Injectable } from '@angular/core';
import {
  Axis,
  DataSetGroup,
  DataSetGroupComputed,
  DataWithPosition,
  GraphDataSet,
  GraphDataSetWithCoefficients,
  XAxis,
  XAxisStep,
  YAxis,
  YAxisStep
} from '../graph.interface';
/*
 * All calculations needed for line, bar, and stacked bar graph
 *
 * @export
 * @class GraphCalculationsService
 */
@Injectable({
  providedIn: 'root'
})
export class GraphCalculationsService {
  /*
   * groups of dataset calculated, cached
   *
   * @type {DataSetGroupComputed[]}
   * @memberof GraphCalculationsService
   */
  groupedDatasets: DataSetGroupComputed[] = [];
  /*
   * size of graph
   *
   * @type {{
   *     width: number; graph width including padding
   *     height: number; graph height including padding
   *     bottom: number; bottom padding of active line drawing area (without axis and labels)
   *     left: number; left padding of active line drawing area (without axis and labels)
   *     right: number; right padding of active line drawing area (without axis and labels)
   *     top: number; top padding of active line drawing area (without axis and labels)
   *   }}
   * @memberof GraphCalculationsService
   */
  elementSize: {
    width: number;
    height: number;
    bottom: number;
    left: number;
    right: number;
    top: number;
  } = { width: 0, height: 0, bottom: 100, left: 100, top: 100, right: 100 };

  /*
   * computed axis
   *
   * @type {Axis}
   * @memberof GraphCalculationsService
   */
  axis: Axis = {
    xAxis: [],
    yAxis: []
  };

  /*
   * set dataset and calculate all data
   *
   * @param {DataSetGroup[]} datasetGroup
   * @memberof GraphCalculationsService
   */
  setDatasets(datasetGroup: DataSetGroup[]): void {
    this.groupedDatasets = datasetGroup
      ?.map((group) => this.autoScaleGroupByY(group))
      .map((group) => ({
        ...group,
        dataSets: group.dataSets
          .map((dataSet) => ({
            ...dataSet,
            xConvertCoefficient: this.getXConvertCoefficient(dataSet),
            yConvertCoefficient: this.getYConvertCoefficient(dataSet),
            hoverColor: dataSet.hoverColor || this.increaseBrightness(dataSet.color, 10)
          }))
          .map((dataSet) => ({
            ...dataSet,
            data: dataSet.data.map((data) => {
              const result = {
                xVal: data.xVal,
                yVal: data.yVal,
                xPos: this.getXValPos(data.xVal, dataSet),
                yPos: this.getYValPos(data.yVal, dataSet)
              } as DataWithPosition;
              if (data.yInterval) {
                result.yInterval = this.calculateInterval(data.yInterval, dataSet);
              }
              return result;
            })
          }))
      }))
      .map((group) => this.calculateStack(group));
    this.axis = { xAxis: this.xAxisPositions(), yAxis: this.yAxisPositions() };
  }

  /*
   * recalculate all data
   *
   * @return {*}  {void}
   * @memberof GraphCalculationsService
   */
  recalculate(): void {
    if (this.elementSize.width === 0 || this.elementSize.height === 0) {
      return;
    }
    this.setDatasets(this.groupedDatasets);
  }

  /*
   * recalculate with new element with and height
   * get height and width from element automatically
   *
   * @param {HTMLElement} element
   * @memberof GraphCalculationsService
   */
  elementSizeRecalculate(element: HTMLElement): void {
    const computedStyles = getComputedStyle(element);

    const paddingX = (parseFloat(computedStyles.paddingLeft) || 0) + (parseFloat(computedStyles.paddingRight) || 0);
    const paddingY = (parseFloat(computedStyles.paddingTop) || 0) + (parseFloat(computedStyles.paddingBottom) || 0);

    const borderX =
      (parseFloat(computedStyles.borderLeftWidth) || 0) + (parseFloat(computedStyles.borderRightWidth) || 0);
    const borderY =
      (parseFloat(computedStyles.borderTopWidth) || 0) + (parseFloat(computedStyles.borderBottomWidth) || 0);
    const rect = element.getBoundingClientRect();
    this.elementSize.width = rect.width - paddingX - borderX;
    this.elementSize.height = rect.height - paddingY - borderY;
    this.recalculate();
  }

  /*
   * helper that returns rgb color that is brighter than original (any css color type accepted) by percent
   *
   * @param {string} originalColor
   * @param {number} percent
   * @return {*}  {string}
   * @memberof GraphCalculationsService
   */
  increaseBrightness(originalColor: string, percent: number): string {
    const ctx = document.createElement('canvas').getContext('2d');
    if (!ctx) {
      return originalColor;
    }
    ctx.fillStyle = originalColor;
    ctx.fillRect(0, 0, 1, 1);
    const color = ctx.getImageData(0, 0, 1, 1);
    const r = color.data[0] + Math.floor((percent / 100) * 255);
    const g = color.data[1] + Math.floor((percent / 100) * 255);
    const b = color.data[2] + Math.floor((percent / 100) * 255);

    return `rgb(${r},${g},${b})`;
  }

  /*
   * calculate interval data position in PX if required. For segmentedIntervalBar also set new
   * start if it would start on previous interval and end if interval would be shorter than 10px
   *
   * @private
   * @param data yInterval
   * @param {DataSetGroupComputed} datasetGroup
   * @return {*}  {yInterval with position}
   * @memberof GraphCalculationsService
   */
  private calculateInterval(
    data: GraphDataSet['data'][0]['yInterval'],
    dataSet: GraphDataSetWithCoefficients
  ): DataWithPosition['yInterval'] {
    const segmentMinSize = 10;
    const directMap = data
      ?.map((interval) => ({
        ...interval,
        startPos: this.getYValPos(interval.start, dataSet),
        endPos: this.getYValPos(interval.end, dataSet)
      }))
      ?.sort((a, b) => b.startPos - a.startPos);

    if (dataSet.type !== 'segmentedIntervalBar') {
      return directMap;
    }
    const minValueSupported = directMap.map((data, index, array) => {
      if (index > 0) {
        data.startPos = array[index - 1].endPos < data.startPos ? array[index - 1].endPos : data.startPos;
      }
      data.endPos = data.startPos - data.endPos < segmentMinSize ? data.startPos - segmentMinSize : data.endPos;
      return data;
    });
    if (dataSet.shrunkYIntervalOnOverflow) {
      return this.shrunkIntervalToFitMaxY(minValueSupported, segmentMinSize);
    }
    return minValueSupported;
  }

  shrunkIntervalToFitMaxY(data: DataWithPosition['yInterval'], segmentMinSize: number): DataWithPosition['yInterval'] {
    let overflowBy = this.elementSize.top - data[data.length - 1]?.endPos; // data overflowed max value by in px
    let maxDeep = 20; // safety catch is shrink is not possible due to many small items
    while (overflowBy > 0 && maxDeep > 0) {
      maxDeep--;
      const decreaseItemBy = Math.ceil(
        // every segment that can be decreased should be decrease by
        overflowBy / data.filter((item) => item.startPos - item.endPos > segmentMinSize).length
      );
      data = data.reduce(
        (acc, item) => {
          let shrunkenPosDiff = acc.currentPosDiff;
          if (item.startPos - item.endPos > segmentMinSize) {
            shrunkenPosDiff += Math.min(item.startPos - item.endPos - segmentMinSize, decreaseItemBy);
          }

          return {
            currentPosDiff: shrunkenPosDiff,
            data: [
              ...acc.data,
              { ...item, startPos: item.startPos + acc.currentPosDiff, endPos: item.endPos + shrunkenPosDiff }
            ]
          };
        },
        { currentPosDiff: 0, data: [] }
      ).data;
      overflowBy = this.elementSize.top - data[data.length - 1].endPos;
    }
    return data;
  }

  /*
   * calculate stack data if required. Inside group on all x value calculate
   * stack offset on dataset with other data sources within this group
   *
   * @private
   * @param {DataSetGroupComputed} datasetGroup
   * @return {*}  {DataSetGroupComputed}
   * @memberof GraphCalculationsService
   */
  private calculateStack(datasetGroup: DataSetGroupComputed): DataSetGroupComputed {
    if (!datasetGroup.calculateStack) {
      return datasetGroup;
    }
    for (let dsIndex = 0; dsIndex < datasetGroup.dataSets.length; dsIndex++) {
      const dataSet = datasetGroup.dataSets[dsIndex];
      const previousDataSet = datasetGroup.dataSets[dsIndex - 1];
      for (let dataIndex = 0; dataIndex < dataSet.data.length; dataIndex++) {
        const data = dataSet.data[dataIndex];
        const prevData = previousDataSet?.data[dataIndex];
        data.yPosStackedStart = prevData?.yPosStackedEnd ?? this.getYValPos(dataSet.yStart || 0, dataSet);
        data.yPosStackedEnd = this.getYValPos(this.getYValFromPos(data.yPosStackedStart, dataSet) + data.yVal, dataSet);
      }
    }
    return datasetGroup;
  }

  // #region common
  /*
   * return coefficient that is used for converting x axis value to px value
   *
   * @private
   * @param {GraphDataSet} dataSet
   * @return {*}  {number}
   * @memberof GraphCalculationsService
   */
  private getXConvertCoefficient(dataSet: GraphDataSet): number {
    return (
      (this.elementSize.width - this.elementSize.right - this.elementSize.left) /
      ((dataSet?.xEnd || 0) - (dataSet?.xStart || 0))
    );
  }

  /*
   * return coefficient that is used for converting y axis value to px value
   *
   * @private
   * @param {GraphDataSet} dataSet
   * @return {*}  {number}
   * @memberof GraphCalculationsService
   */
  private getYConvertCoefficient(dataSet: GraphDataSet): number {
    return (
      (this.elementSize.height - this.elementSize.bottom - this.elementSize.top) /
      ((dataSet?.yEnd || 0) - (dataSet?.yStart || 0))
    );
  }

  /*
   * return `x value position in px` from `x axis value`
   *
   * @private
   * @param {number} xVal
   * @param {GraphDataSetWithCoefficients} dataSet
   * @return {*}  {number}
   * @memberof GraphCalculationsService
   */
  private getXValPos(xVal: number, dataSet: GraphDataSetWithCoefficients): number {
    return (xVal - (dataSet.xStart || 0)) * dataSet.xConvertCoefficient + this.elementSize.left;
  }

  /*
   * return `y value position in px` from `y axis value`
   *
   * @private
   * @param {number} yVal
   * @param {GraphDataSetWithCoefficients} dataSet
   * @return {*}  {number}
   * @memberof GraphCalculationsService
   */
  private getYValPos(yVal: number, dataSet: GraphDataSetWithCoefficients): number {
    return (
      this.elementSize.height - this.elementSize.bottom - (yVal - (dataSet.yStart || 0)) * dataSet.yConvertCoefficient
    );
  }

  /*
   * return `y axis value` from `y value position in px`
   *
   * @private
   * @param {number} yVal
   * @param {GraphDataSetWithCoefficients} dataSet
   * @return {*}  {number}
   * @memberof GraphCalculationsService
   */
  private getYValFromPos(yPos: number, dataSet: GraphDataSetWithCoefficients): number {
    return (
      (dataSet.yStart || 0) + (this.elementSize.height - this.elementSize.bottom - yPos) / dataSet.yConvertCoefficient
    );
  }
  // #endregion common

  // #region auto scale Y
  /*
   * auto scale all data sources in group on y axis
   *
   * @private
   * @param {DataSetGroup} group
   * @return {*}  {DataSetGroup}
   * @memberof GraphCalculationsService
   */
  private autoScaleGroupByY(group: DataSetGroup): DataSetGroup {
    if (!group.autoScale || this.elementSize.height === 0) {
      return group;
    }
    const minMax = this.getDataMinMax(group);
    const yStep = this.getYStepSize(minMax, group.autoScaleBase1024);
    const yStepStart = this.getYStepStart(minMax.min, yStep);

    return {
      ...group,
      dataSets: group.dataSets.map((ds) => ({
        ...ds,
        yStart: yStepStart,
        yEnd: Math.ceil(minMax.max / yStep) * yStep,
        yLabelStep: yStep,
        yLabelStart: yStepStart
      }))
    };
  }

  /*
   * get Y axis start
   *
   * @private
   * @param {number} min
   * @param {number} step
   * @return {*}  {number}
   * @memberof GraphCalculationsService
   */
  private getYStepStart(min: number, step: number): number {
    return Math.floor(min / step) * step;
  }

  /*
   * calculate step on Y axis
   * @param minMax
   * @returns
   */
  private getYStepSize(minMax: { min: number; max: number }, base1024: boolean): number {
    const stepMinPx = 50;
    const height = Math.max(this.elementSize.height - this.elementSize.top - this.elementSize.bottom, 1);
    const maxStepCount = Math.ceil(height / stepMinPx) - 1; // step min is not in those steps
    const nearSearchValue = (minMax.max - minMax.min) / maxStepCount;
    const maxStepSize = base1024 ? this.toNearest1024Base(nearSearchValue) : this.toNearest(nearSearchValue);
    return maxStepSize;
  }

  /*
   * round step size to predefined step sizes
   *
   * @private
   * @param {number} value
   * @return {*}  {number}
   * @memberof GraphCalculationsService
   */
  private toNearest(value: number): number {
    const values = [1, 1.5, 2, 2.5, 3, 5, 7.5, 10];
    if (value >= 10) {
      return this.toNearest(value / 10) * 10;
    }
    for (let i = values.length - 1; i > 0; i--) {
      const middleValue = (values[i] + values[i - 1]) / 2;
      if (value >= middleValue) {
        return values[i];
      }
    }
    return 1;
  }

  /*
   * round step size to predefined step sizes for data usage charts
   *
   * @private
   * @param {number} value
   * @return {*}  {number}
   * @memberof GraphCalculationsService
   */
  private toNearest1024Base(value: number): number {
    const values = [1, 1.5, 2, 2.5, 3, 5, 7.5, 10, 15, 20, 25, 30, 50, 75, 100, 150, 200, 250, 300, 500, 750, 1000];
    if (value >= 1024) {
      return this.toNearest(value / 1024) * 1024;
    }
    for (let i = values.length - 1; i > 0; i--) {
      const middleValue = (values[i] + values[i - 1]) / 2;
      if (value >= middleValue) {
        return values[i];
      }
    }
    return 1;
  }

  /*
   * get minimum and maximum Y values in data set group
   *
   * @private
   * @param {DataSetGroup} group
   * @return {*}  {{ min: number; max: number }}
   * @memberof GraphCalculationsService
   */
  private getDataMinMax(group: DataSetGroup): { min: number; max: number } {
    const allYValues = this.allYValues(group);
    if (group.startFromZero) {
      allYValues.push(0);
    }

    return { min: Math.min(...allYValues), max: Math.max(...allYValues) };
  }

  /*
   * get all Y values in group
   *
   * @private
   * @param {DataSetGroup} group
   * @return {*}  {number[]}
   * @memberof GraphCalculationsService
   */
  private allYValues(group: DataSetGroup): number[] {
    if (group.calculateStack) {
      return Object.values(
        group.dataSets
          .reduce(
            // get all data objects
            (acc, val) => [...acc, ...val.data],
            [] as {
              xVal: number;
              yVal: number;
            }[]
          )
          .reduce(
            // for all X get sum of Y values
            (acc, val) => ({
              ...acc,
              [val.xVal]: (acc[val.xVal] ?? 0) + (val.yVal ?? 0)
            }),
            {} as { [key: number]: number }
          )
      );
    } else {
      return group.dataSets
        .map((dataSet) => dataSet.data.map((d) => d.yVal))
        .reduce((acc, val) => [...acc, ...val], []);
    }
  }
  // #endregion auto scale Y

  // #region xAxis
  /*
   * calculate x axis
   *
   * @private
   * @return {*}  {XAxis[]}
   * @memberof GraphCalculationsService
   */
  private xAxisPositions(): XAxis[] {
    return this.groupedDatasets
      ?.map((group) =>
        group.dataSets
          .filter((dataSet) => dataSet.xLabelStep !== 0 && 'xConvertCoefficient' in dataSet)
          .map((dataSet) => ({
            y: this.elementSize.height - this.elementSize.bottom,
            startX: this.elementSize.left,
            endX: this.elementSize.width - this.elementSize.right,
            yEnd: this.xAxisStepVisualization(dataSet.xStepVisualization),
            yClosing: dataSet.xAxisCloseBar ? this.elementSize.top : undefined,
            xTextPosition: dataSet.xTextPosition,
            steps: this.xAxisStepPositions(dataSet as GraphDataSetWithCoefficients),
            xTextStepOverflow: dataSet.xTextStepOverflow !== false,
            xTextMultiline: dataSet.xTextMultiline
          }))
      )
      .reduce((acc, val) => [...acc, ...val], []);
  }

  /*
   * calculate markers and labels on x axis
   *
   * @private
   * @param {GraphDataSetWithCoefficients} dataSet
   * @return {*}  {XAxisStep[]}
   * @memberof GraphCalculationsService
   */
  private xAxisStepPositions(dataSet: GraphDataSetWithCoefficients): XAxisStep[] {
    const steps: XAxisStep[] = [];

    for (let stepVal = dataSet.xLabelStart || 0; stepVal <= (dataSet.xEnd || 0); stepVal += dataSet.xLabelStep || 1) {
      steps.push(this.xAxisStepPosition(stepVal, dataSet));
    }
    return steps;
  }

  /*
   * calculate one marker and label on x axis
   *
   * @private
   * @param {number} stepValue
   * @param {GraphDataSetWithCoefficients} dataSet
   * @return {*}  {XAxisStep}
   * @memberof GraphCalculationsService
   */
  private xAxisStepPosition(stepValue: number, dataSet: GraphDataSetWithCoefficients): XAxisStep {
    const pos = this.getXValPos(stepValue, dataSet);
    const posNext = this.getXValPos(stepValue + (dataSet.xLabelStep ?? 1), dataSet);
    return {
      value: stepValue,
      xPos: pos,
      text: dataSet.xStepVisualization === 'none' ? '' : dataSet.xValText?.(stepValue) || '',
      xPosText: dataSet.xTextPosition === 'start' || dataSet.xTextPosition === 'forceStart' ? pos : (pos + posNext) / 2
    };
  }

  /*
   * Step marker end in px, based on visualization type
   *
   * @private
   * @param {('line' | 'labelOnly' | 'marker' | 'none')} val
   * @return {*}  {number}
   * @memberof GraphCalculationsService
   */
  private xAxisStepVisualization(val: 'line' | 'labelOnly' | 'marker' | 'none'): number {
    if (val === 'line') {
      return this.elementSize.top;
    }
    if (val === 'marker') {
      return this.elementSize.height - this.elementSize.bottom + 5;
    }
    return this.elementSize.height - this.elementSize.bottom;
  }
  // #endregion xAxis

  // #region yAxis
  /*
   * calculate y axis
   *
   * @private
   * @return {*}  {YAxis[]}
   * @memberof GraphCalculationsService
   */
  private yAxisPositions(): YAxis[] {
    return this.groupedDatasets
      ?.map((group) =>
        group.dataSets
          .filter((dataSet) => dataSet.yLabelStep !== 0 && 'yConvertCoefficient' in dataSet)
          .map((dataSet) => ({
            x: this.elementSize.left,
            startY: this.elementSize.height - this.elementSize.bottom,
            endY: this.elementSize.top,
            xEnd: this.yAxisStepVisualization(dataSet.yStepVisualization),
            xClosing: dataSet.yAxisCloseBar ? this.elementSize.width - this.elementSize.right : undefined,
            steps: this.yAxisStepPositions(dataSet as GraphDataSetWithCoefficients)
          }))
      )
      .reduce((acc, val) => [...acc, ...val], []);
  }

  /*
   * calculate markers and labels on y axis
   *
   * @private
   * @param {GraphDataSetWithCoefficients} dataSet
   * @return {*}  {YAxisStep[]}
   * @memberof GraphCalculationsService
   */
  private yAxisStepPositions(dataSet: GraphDataSetWithCoefficients): YAxisStep[] {
    const steps: YAxisStep[] = [];

    for (let stepVal = dataSet.yLabelStart || 0; stepVal <= (dataSet.yEnd || 0); stepVal += dataSet.yLabelStep || 1) {
      steps.push(this.yAxisStepPosition(stepVal, dataSet));
    }
    return steps;
  }

  /*
   *  calculate one marker and label on y axis
   *
   * @private
   * @param {number} stepValue
   * @param {GraphDataSetWithCoefficients} dataSet
   * @return {*}  {YAxisStep}
   * @memberof GraphCalculationsService
   */
  private yAxisStepPosition(stepValue: number, dataSet: GraphDataSetWithCoefficients): YAxisStep {
    const pos = this.getYValPos(stepValue, dataSet);
    const posNext = this.getYValPos(stepValue + (dataSet.yLabelStep ?? 1), dataSet);
    return {
      value: stepValue,
      yPos: pos,
      text: dataSet.yStepVisualization === 'none' ? '' : dataSet.yValText?.(stepValue) || '',
      yPosText: dataSet.yTextPosition === 'start' ? pos : (pos + posNext) / 2
    };
  }

  /*
   * Step marker end in px, based on visualization type
   *
   * @private
   * @param {('line' | 'labelOnly' | 'marker' | 'none')} val
   * @return {*}  {number}
   * @memberof GraphCalculationsService
   */
  private yAxisStepVisualization(val: 'line' | 'labelOnly' | 'marker' | 'none'): number {
    if (val === 'line') {
      return this.elementSize.width - this.elementSize.right;
    }
    if (val === 'marker') {
      return this.elementSize.left - 5;
    }
    return this.elementSize.left;
  }
  // #endregion yAxis
}
