import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import {
  selectLocationConfig,
  selectLocationConfigState,
  selectLocationConfigStateLoading
} from 'src/app/store/customer/customer.selectors';
import { selectCapabilities } from 'src/app/store/customer/capabilities.selector';
import { autoModes, SlideTogglerItems } from 'src/app/lib/interfaces/interface';
import { appQoeModeSet, samKnowsModeSet } from 'src/app/store/customer/customer.actions';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'agentintegrations',
  templateUrl: './agentintegrations.component.html',
  styleUrls: ['./agentintegrations.component.scss']
})
export class AgentIntegrationsComponent implements OnInit, OnChanges {
  expand: boolean = false;
  configObservable$ = this.store.select(selectLocationConfigState);

  samKnowsCapable$ = this.store.select(selectCapabilities).pipe(map((capability) => capability?.samKnows?.capable));
  samKnowsOptions$ = this.mapSamKnowsOptions$();

  appQoeCapable$ = this.store.select(selectCapabilities).pipe(map((capability) => capability?.AppQoE?.capable));
  appQoeOptions$ = this.mapAppQoeOptions$();

  reloading$ = this.store.select(selectLocationConfigStateLoading);

  confirmAppQoeChange = {
    message: 'configurations.agentintegrations.appQoeDialogMessage',
    confirmButton: 'configurations.agentintegrations.appQoeDialogOk'
  };

  @Input()
  open: number = 0;

  @Output()
  toggle = new EventEmitter();

  @Output()
  filter = new EventEmitter();

  @Output()
  clearFilter = new EventEmitter<{ section: string }>();

  constructor(
    private mixpanel: MixpanelService,
    private translate: TranslateService,
    private store: Store,
    public plume: PlumeService
  ) {}

  ngOnInit(): void {
    this.registerFilter();
  }

  ngOnChanges(changes: any): void {
    this.expand = changes.open.currentValue;
  }

  toggleExpand(): void {
    this.toggle.emit(!this.expand);

    if (this.expand) {
      this.mixpanel.storeEvent('CONFIGURATION_AGENTINTEGRATIONS_SCREEN');
    }
  }

  registerFilter(): void {
    this.clearFilter.emit({ section: 'agentintegrations' });

    combineLatest([this.samKnowsCapable$, this.appQoeCapable$])
      .pipe(untilDestroyed(this))
      .subscribe(([samKnowsCapable, appQoeCapable]) => {
        this.clearFilter.emit({ section: 'agentintegrations' });
        if (samKnowsCapable) {
          this.translate
            .get('configurations.agentintegrations.samKnows')
            .subscribe((translated: string) =>
              this.filter.emit({ section: 'agentintegrations', property: 'samKnows', translation: translated })
            );
        }
        if (appQoeCapable) {
          this.translate
            .get('configurations.agentintegrations.appQoe')
            .subscribe((translated: string) =>
              this.filter.emit({ section: 'agentintegrations', property: 'appQoe', translation: translated })
            );
        }
      });
  }

  updateSamKnows(mode: autoModes): void {
    this.store.dispatch(samKnowsModeSet({ mode }));
  }

  updateAppQoe(mode: autoModes): void {
    this.store.dispatch(appQoeModeSet({ mode }));
  }

  private mapSamKnowsOptions$(): Observable<SlideTogglerItems<autoModes>> {
    return this.store.select(selectLocationConfig).pipe(
      map((config) => [
        { value: 'AUTO', translation: 'auto', selected: config?.data?.config?.samKnows?.mode === 'AUTO' },
        {
          value: 'ENABLE',
          translation: 'enabled',
          marked: config?.data?.state?.samKnows?.enable,
          selected: config?.data?.config?.samKnows?.mode === 'ENABLE'
        },
        {
          value: 'DISABLE',
          translation: 'disabled',
          marked: !config?.data?.state?.samKnows?.enable,
          selected: config?.data?.config?.samKnows?.mode === 'DISABLE'
        }
      ])
    );
  }

  private mapAppQoeOptions$(): Observable<SlideTogglerItems<autoModes>> {
    return this.store.select(selectLocationConfig).pipe(
      map((config) => [
        { value: 'AUTO', translation: 'auto', selected: config?.data?.config?.appTime?.appQoe === 'AUTO' },
        {
          value: 'ENABLE',
          translation: 'enabled',
          marked: config?.data?.state?.appTime?.appQoe,
          selected: config?.data?.config?.appTime?.appQoe === 'ENABLE'
        },
        {
          value: 'DISABLE',
          translation: 'disabled',
          marked: !config?.data?.state?.appTime?.appQoe,
          selected: config?.data?.config?.appTime?.appQoe === 'DISABLE'
        }
      ])
    );
  }
}
