import { Injectable } from '@angular/core';

@Injectable()
export class DeviceRefService {
  constructor() {}
  catalog: string[] = [];

  getCatalog(): string[] {
    return this.catalog;
  }
  setCatalog(cat: string[]): void {
    this.catalog = cat;
  }
}
