<div class="row">
  <div class="col-xs-12">
    <box class="wider selected">
      <div header>{{ 'health.networkPerformance.coverageAlarm.wifiCoverage' | translate }}</div>
      <div container>
        <div class="details">
          <span (click)="expand = !expand">
            <icon name="icon-arrow-down" class="size-0_8" [class.rotate180]="expand"></icon>
            {{ alarms.length }} {{ 'health.networkPerformance.coverageAlarm.devicesEffectedByAlarm' | translate }}
          </span>
          <div class="history">
            <div class="caption">{{ 'health.networkPerformance.coverageAlarm.weekAlarmHistory' | translate }}</div>
            <div class="chart">
              <div class="day {{ 'alarm-' + day.alarm }}" *ngFor="let day of history">{{ day.day }}</div>
            </div>
          </div>
        </div>

        <div class="list" [class.show]="expand" *ngIf="!alarms.length">
          <div class="noalarms">{{ 'health.networkPerformance.coverageAlarm.noAlarmsToday' | translate }}</div>
        </div>

        <div class="list" [class.show]="expand" *ngIf="alarms.length">
          <div class="device" *ngFor="let device of alarms">
            <div class="column">
              <icon *ngIf="!device.iconPathV3" class="size-2" [path]="device.iconPath"></icon>
              <div class="icon-container" *ngIf="device.iconPathV3">
                <icon [path]="device.iconPathV3" class="size-2"></icon>
              </div>
              <div class="name">
                <span class="title" (click)="!plume.hidePersonalDetails() && goto(device.mac)">{{
                  device.nickanme || device.name | macAddr: true
                }}</span>
                <span class="type">{{ device.kind.category }}</span>
              </div>
            </div>
            <div class="column">
              <div class="caption">{{ 'health.networkPerformance.coverageAlarm.dayAlarmHistory' | translate }}</div>
              <div class="history">
                <div class="chart">
                  <div class="tick" [class.error]="tick.error" *ngFor="let tick of device.history">
                    <div class="time">{{ tick.time }}</div>
                    <div class="state">
                      {{
                        (tick.error
                          ? 'health.networkPerformance.coverageAlarm.inAlarm'
                          : 'health.networkPerformance.coverageAlarm.noAlarm'
                        ) | translate
                      }}
                    </div>
                  </div>
                </div>
                <div class="axis">
                  <div class="start">{{ axis48 }}</div>
                  <div class="middle">{{ axis24 }}</div>
                  <div class="end">{{ axisNow | translate }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </box>
    <div class="wrapper show">
      <informbubble [finishbutton]="true" [nocheck]="true" [hasextenders]="nodes.ext"></informbubble>
    </div>
  </div>
</div>
