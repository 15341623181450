<modal *ngIf="showLogPull">
  <ng-container title>{{ 'customerinfo.logpull.title' | translate }}</ng-container>
  <ng-container content>
    <div class="label" *ngIf="!logpullComplete">{{ 'customerinfo.logpull.message' | translate }}</div>
    <div class="label" *ngIf="logpullComplete">{{ 'customerinfo.logpull.messageComplete' | translate }}</div>
    <div class="value" *ngIf="logpullComplete">
      <div class="logpull-urlContainer">
        <div class="url">{{ logpullResponse }}</div>
        <button class="action small" (click)="copied(logpullResponse)">
          {{ 'customerinfo.logpullHistory.copyLink' | translate }}
        </button>
      </div>
    </div>

    <div class="logpull-form" *ngIf="!logpullComplete">
      <div class="input-wrap">
        <input
          type="text"
          class="enabled"
          placeholder="{{ 'customerinfo.logpull.requestMessage' | translate }}"
          [formControl]="messageFormControl"
        />
      </div>
      <button class="action medium" (click)="performLogpull()">
        {{ 'customerinfo.logpull.submit' | translate }}
      </button>
    </div>
  </ng-container>
  <ng-container controls>
    <button class="tertiary light" (click)="closeLogpull()">
      {{ 'customerinfo.logpull.close' | translate }}
    </button>
  </ng-container>
</modal>
